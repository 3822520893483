import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link} from "react-router-dom";
import {Icon , Dropdown} from 'semantic-ui-react';
import i18next from "i18next";
import Swiper from 'react-id-swiper';
import {
    DEFAULT_CURRENCY_SYMBOL,
    LOCAL_STORAGE,
    SESSION_STORAGE,
    TRANSFER_THRESHOLD
} from "../../../utils/constants/variables";
import {
    addDeliveryStore,
    arrayGroupByCount,
    getBarcodeList,
    getBasketData,
    getBasketProductsBySizes,
    getLangPrefix,
    getLocalStorageObject,
    getUserFromToken
} from "../../../helpers/helpers";
import Photo from "../../common/product/photo";
import Price from "../../common/product/price";
import {getMenu} from "../../../layouts/main/header/redux/selectors";
import {AddToDeliveryCartActionCreators} from "./../redux/actions";
import BlackWindow from "../../common/blackWindow";
import {getDeliveryBasket} from "./../redux/selectors";
import {getStoreClient} from "../../clients/redux/selectors";
import {replaceComma} from "../../../helpers/helpers";
import closeIcon from "../../../assets/img/close.svg"
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import AutocompleteDropdown from "../../common/autocompleteDropdown";
import {getStores, getStoresForm} from "../../storeFormSidePanel/redux/selectors";
import {getCreatedStatus} from "../../transfers/redux/selectors";
import {getAccountResource} from "../../login/redux/selectors";
import './deleveryBasket.scss';

interface Props {
    account: any,
    handleBasketToggle?: any,
    menuList: any,
    deliveryBasket: any,
    basketName?: string,
    history? :any,
    storeClient?: any,
    stores:any,
    createdStatus: string,
    storeTransferReasons: object,
}

class DeliveryBasketPopUp extends React.Component<Props & typeof AddToDeliveryCartActionCreators> {
    state = {
        showConfirmPopup: false,
        status:"",
        selectedStoreTransferReasons:"DEP",
        selectedStoreType:""
    }
    componentDidMount() {
        this.props.storesFormStart();
        this.props.getDeliveryBasketStart(LOCAL_STORAGE.deliveryBasket)
        this.fetchStocks();
        this.props.storesStart();
    }
    componentDidUpdate(prevProps, prevState): void {
        if(prevProps.createdStatus !== this.props.createdStatus){
            this.setState({status: this.props.createdStatus})
        }
        if(this.props.deliveryBasket.store && prevProps.deliveryBasket.store &&
            this.props.deliveryBasket.store.storeCode !== prevProps.deliveryBasket.store.storeCode  )
        {
            this.fetchStocks();

        }
    }

    swiperParams = {
        spaceBetween: 0,
        autoplay: false,
        shouldSwiperUpdate: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    }

    fetchStocks = () => {
        const basket = getLocalStorageObject(LOCAL_STORAGE.deliveryBasket);
        const prod = getBasketProductsBySizes(basket);
        const {store: {storeCode = "", storeType = ""} = {}} = basket;
        let barcodeList:any=[]
        if(prod && prod.length && storeCode){
            prod.forEach(product => {barcodeList.push(product.productReference)})
            this.props.getBasketStocksStart(barcodeList, storeCode, storeType,LOCAL_STORAGE.deliveryBasket)
            this.setState({selectedStoreType:storeType})
        }
    }

    getOptions = (count = 0) => {
        const maxCount = count > 20 ? 20 : count;
        const options: any[] = [];
        for (let i = 0; i <= maxCount; i++) {
            options.push({key: i, value: i, text: i.toString()});
        }

        return options
    }

    handleBasketToggle = (show) => {
        this.props.handleBasketToggle(show);
    };
    handleChangeQuantity = (e,{name,value}) => {
        const ids = name.split("/");
        this.props.addtocartStart(LOCAL_STORAGE.deliveryBasket , ids[0], {size: {quantity: value, productId: ids[1]}}, true);
    };
    handleRemoveItem = (productId, sizeElementProductId) => {
        this.props.removeBasketStart(LOCAL_STORAGE.deliveryBasket, productId, sizeElementProductId)
    };
    toggleRemovePopup = () => {
        this.setState({showConfirmPopup: !this.state.showConfirmPopup})
    }
    handleCreateTransfer= () => {
        const {deliveryBasket, account} = this.props;
        const {selectedStoreTransferReasons,selectedStoreType} =this.state;
        const user = getUserFromToken()
            const body ={
                associateCodeRecv: user && user.code ,
                productQty: getBarcodeList(LOCAL_STORAGE.deliveryBasket).totalQTY,
                storeCodeRecv: account.storeCode || sessionStorage.getItem(SESSION_STORAGE.storeCode),
                storeCodeSend:  deliveryBasket['store'] && deliveryBasket['store'].storeCode,
                storeTransferProducts: [
                    ...getBarcodeList(LOCAL_STORAGE.deliveryBasket).barcodeList
                ],
                storeTransferReasonCode: selectedStoreTransferReasons,
                storeTransferStatusCode: "REQ"
            }
            this.props.createTransferStart({body,basket:'true',storeType:selectedStoreType})
            this.toggleRemovePopup()


    }

    handleChangeBasketStore = (value) => {
        const {stores} = this.props;
        const store = stores.find(item => item.storeCode === value)
        if(store){
            if(addDeliveryStore(store)){
                this.props.getDeliveryBasketStart(LOCAL_STORAGE.deliveryBasket)
                this.setState({selectedStoreType:store.storeType})
            }
        }
    }

    getConfirmPopUpContent = () => {
        try {
            let text = "";
            getBasketProductsBySizes(this.props.deliveryBasket).forEach(({parentID = {}, quantity, size}) => {
                const product = parentID ? this.props.deliveryBasket[parentID] || {} : {}
                text += `<div class="delivery-basket-confirm-pop--up-content"><div>${product.name || ''}</div><div> ${size || ''}</div><div>${quantity || ''}</div></div>`
            })
            return text;
        } catch (error) {
            return ""
        }
    }


    render() {
        const {deliveryBasket, stores, storeTransferReasons} = this.props;
        const {showConfirmPopup,status,selectedStoreTransferReasons} = this.state;
        const basketData = getBasketData(deliveryBasket);
        const {store: {storeCode = "", storeType = ""} = {}} = deliveryBasket
        const {totalQuantity, price} = basketData;
        const isDisabled = TRANSFER_THRESHOLD > price || !(selectedStoreTransferReasons && storeCode);

        return (
            <>
                <BlackWindow type="page" handleClick={() => this.handleBasketToggle(false)}/>
                <div className={`basket-popup active activeBasket deleveryBasket`}>
                    <div className="basket-popup-content">
                        <div className="basket-popup-shop-title">
                            <div className="shoppingBox">
                                <Icon  name='truck' />
                                <h4 className="shop-title">
                                    <span className="shop-title--qty">{totalQuantity}</span>
                                    {totalQuantity < 2 ? i18next.t('Terminal.transferBasket.2') : i18next.t('Terminal.transferBasket.1')}
                                </h4>
                            </div>
                            <img src={closeIcon}  onClick={() => this.handleBasketToggle(false)}/>
                        </div>


                        <div className="articles-content">

                            <div className="articles-content--display">
                                <h4 className={`articles-content-title ${totalQuantity< 0 ? "revert":""}`}>
                                    {totalQuantity} {totalQuantity < 2 ? i18next.t('product.basket.23') : i18next.t('product.basket.12')}
                                </h4>
                                <h4 className={`articles-content-price ${price< 0 ? "revert":""}`}>
                                    {i18next.t('product.parure.5')} : <span>{replaceComma(price) + DEFAULT_CURRENCY_SYMBOL}</span>
                                </h4>
                            </div>
                            <div className='deleveryBasket--storeSelector'>
                                <AutocompleteDropdown
                                    data={stores}
                                    value={storeCode}
                                    search={true}
                                    disabledOption={storeCode}
                                    keyName="code"
                                    textName={['name','address','phone']}
                                    valueName="storeCode"
                                    placeholder={ i18next.t('Terminal.transferBasket.3')}
                                    classN={"deleveryBasket--storeSelector--dropDown"}
                                    onChange={(value) =>this.handleChangeBasketStore(value)}
                                    optionClass={{action: "currentValue.storeType === 'W'", name: "blueText"}}
                                />
                            </div>
                            <div className='deleveryBasket--storeSelector'>
                                <AutocompleteDropdown
                                    data={storeTransferReasons}
                                    value={selectedStoreTransferReasons}
                                    search={true}
                                    keyName="code"
                                    textName={['reason']}
                                    valueName="code"
                                    placeholder=""
                                    classN={"deleveryBasket--storeSelector--dropDown"}
                                    onChange={(value) =>this.setState({selectedStoreTransferReasons:value})}
                                />
                            </div>
                            <button className={`articles-content-button  w-100 ${isDisabled? "disabled": ""}`}
                                    disabled={isDisabled}
                                    onClick={this.toggleRemovePopup}>{i18next.t('Terminal.transferBasket.4',{TRANSFER_THRESHOLD,DEFAULT_CURRENCY_SYMBOL})}
                            </button>
                            {status && (
                                <div
                                    className={
                                        status === 'success'
                                            ? 'successMessage'
                                            : 'errorMessage'
                                    }
                                >
                                    {status === 'success'
                                        ? i18next.t('Terminal.transferBasket.6')
                                        : i18next.t('Terminal.transferBasket.7')}
                                </div>
                            )}

                        </div>

                        <div className="product-content">
                            {deliveryBasket && Object.keys(deliveryBasket).length ? 
                                <Swiper {...this.swiperParams}>
                                    {
                                        arrayGroupByCount(getBasketProductsBySizes(deliveryBasket), 4).map((items: any, idx) => {
                                        return (
                                            <div key={idx} className="basket-slider">
                                                {
                                                    items.map((sizeElement, i) => {
                                                        const { parentID } = sizeElement;
                                                        const product = parentID ? deliveryBasket[parentID] || {} : {};
                                                        const stock = sizeElement.stock || (storeType === "W" ? sizeElement.webInventory : 0)
                                                        return (
                                                            <div className="product-content-item" key={i}>
                                                                <div className="product-content-item-img">
                                                                    <Link onClick={() => this.handleBasketToggle(false)}
                                                                        to={encodeURIComponent(`${getLangPrefix()}${product.canonicalMenu}/${product.canonicallUrl}`)}>
                                                                        {product.photo ? <Photo src={product.photo} discountDetail={""}/> :""}
                                                                    </Link>
                                                                </div>
                                                                <div className="product-content-item-body">
                                                                    <div className="product-content-item-body-title">
                                                                        <h4 className="product-content-item-body-title-text">
                                                                            <span className="title">{product.name}</span>
                                                                        </h4>
                                                                        <div className="product-content-item-body--info--size">{sizeElement.size}</div>
                                                                    </div>
                                                                    <div className="product-content-item-body--info">
                                                                        <div className="product-content-item-body--info--stock">Stock: <span className="number"> {stock} </span></div>
                                                                        <div className="product-content-item-body-footer">
                                                                            <div className="product-content-item-body-footer-price">
                                                                                <Price startP={sizeElement.startingPrice}
                                                                                    discount={sizeElement.discountDetail}
                                                                                    newP={sizeElement.listPriceAfterDiscount}
                                                                                    quantity={sizeElement.quantity}
                                                                                    isPromo={sizeElement.isPromo}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-content-item-body-footer">
                                                                        <div className="product-content-item-body-footer-select">
                                                                            <p className="text">{i18next.t('product.basket.summary.2')}</p>
                                                                            <Dropdown  
                                                                                compact
                                                                                selection
                                                                                closeOnChange
                                                                                options={this.getOptions(stock)}
                                                                                value={sizeElement.quantity}
                                                                                name={`${parentID}/${sizeElement.productId}`}
                                                                                onChange={this.handleChangeQuantity} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-content-item-body-title">
                                                                        <Icon name="trash alternate" onClick={() => this.handleRemoveItem(parentID, sizeElement.productId)}>
                                                                        </Icon>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        })
                                                }
                                            </div>
                                        )})
                                    }
                                </Swiper>
                            : ""
                            }
                        </div>
                    </div>
                </div>
                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={this.toggleRemovePopup}
                        handleConfirm={this.handleCreateTransfer}
                        text={i18next.t('Terminal.transferBasket.5')}
                        additionText={this.getConfirmPopUpContent()}
                        classN='delivery-basket-confirm-pop--up'
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { storeTransferReasons = [] } = getStoresForm(state);
    const account = getAccountResource(state);
    const { storeDetails: { storeType = '' } = {} } = account;
    const stores = getStores(state) || [];

    return {
        storeTransferReasons,
        account,
        stores:
            storeType === 'T'
                ? stores.filter((item) => item.storeType === 'T')
                : stores.filter(
                      (item) =>
                          item.storeType !== 'T' && item.storeType !== 'W',
                  ),
        menuList: getMenu(state),
        deliveryBasket: getDeliveryBasket(state),
        storeClient: getStoreClient(state),
        createdStatus: getCreatedStatus(state),
    };
};

const mapDispatchToProps = (dispatch): typeof AddToDeliveryCartActionCreators => {
    return bindActionCreators(AddToDeliveryCartActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryBasketPopUp);
