import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18next from "i18next";
import { HomeActionCreators } from "../../home/redux/actions";
import { TerminalControllerActionCreators } from "../redux/actions";
import { ReportsActionCreators } from "./../../reports/redux/actions";
import BlackWindow from "./../../../modules/common/blackWindow";
import closeIcon from "./../../../assets/img/close_black.svg"
import { getTerminalIsClosed, getTerminalIsOpen, getTerminalLoading, getTerminalStatus, getTerminalError, getIsTerminalOpen } from "../redux/selectors";
import { getAccountResource } from "../../login/redux/selectors";
import { getStoresForm } from "../../storeFormSidePanel/redux/selectors";
import { getExpensesArray, getUserFromToken, islastWorkDayOfMonth, isValidClientToken, replaceCommaToDecimal } from "../../../helpers/helpers";
import './fermeturePopUp.scss'
import { LOCAL_STORAGE, PAYMENT_CODES } from "../../../utils/constants/variables";
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import TerminalStep3 from "./components/terminaStep3";
import TerminalSteps from "./components/terminaSteps"
import TerminalStep1 from "./components/terminaStep1";
import TerminalStep2 from "./components/terminaStep2";
import { getPaymentsReportData, paymentsReportLoading } from "../../reports/redux/selectors";
import OpenTerminal from "./components/openTerminal";
import Clock from "./../../common/clock";
import InfoPopUp from "../../common/infoPopUp";
import PrintNullBelegBtn from "../../nullBelegBtn/container/printNullBelegBtn";
import { getNullBelegStatus } from "../../nullBelegBtn/redux/selectors";

interface Props {
    handleClose?: any,
    isOpen: boolean,
    isTerminalOpen: boolean,
    status: string,
    account: any,
    isClosed: boolean,
    isLoading: boolean,
    loadingReport: boolean,
    storesForm: object,
    paymentsReportData: any,
    terminalError: string
    nullBelegStatus: boolean
}

interface State {
    wallet: any,
    walletPieces: any,
    altWallet: any,
    altWalletPieces: any,
    total: number,
    altTotal: number,
    totalPrice: number,
    altTotalPrice: number,
    comment: string,
    storeEventId: number,
    storeTemperatureId: number,
    storeWeatherId: number,
    showConfirmPopup: boolean,
    body: any,
    counterClients: number,
    steps: object,
    openSteps: object,
    activeStep: number,
    cashDeposit: number,
    checkDeposit: number | string,
    amexDeposit: number | string,
    creditCardDeposit: number | string,
    giftCardDeposit: number | string,
    altCashDeposit: number,
    altCheckDeposit: number | string,
    altAmexDeposit: number | string,
    altCreditCardDeposit: number | string,
    altGiftCardDeposit: number | string,
    cashSafe: number | string,
    altCashSafe: number | string,
    checkSafe: number | string,
    altCheckSafe: number | string,
    altGiftSafe: number | string,
    giftSafe: number | string,
    customEspeces: number | string,
    altCustomEspeces: number | string,
    cashSafeMovedCashDeposit: number | string,
    altCashSafeMovedCashDeposit: number | string,
    wireDeposit: number | string,
    altWireDeposit: number | string,
    showInfoPopup: boolean,
    nextStep3: boolean,
    step2DisabledButtons: string[],
    cashInSafe: number,
    altCashInSafe: number,
}

class FermeturePopUp extends React.Component<Props & typeof TerminalControllerActionCreators, typeof HomeActionCreators, typeof ReportsActionCreators> {

    state: State = {
        wallet: [
            { key: "0", value: 0, price: 500 },
            { key: "1", value: 0, price: 200 },
            { key: "2", value: 0, price: 100 },
            { key: "3", value: 0, price: 50 },
            { key: "4", value: 0, price: 20 },
            { key: "5", value: 0, price: 10 },
            { key: "6", value: 0, price: 5 },
        ],
        walletPieces: [
            { key: "7", value: 0, price: 2 },
            { key: "8", value: 0, price: 1 },
            { key: "9", value: 0, price: 0.50 },
            { key: "10", value: 0, price: 0.20 },
            { key: "11", value: 0, price: 0.10 },
            { key: "12", value: 0, price: 0.05 },
            { key: "13", value: 0, price: 0.02 },
            { key: "14", value: 0, price: 0.01 },
        ],
        altWallet: [
            { key: "0", value: 0, price: 500 },
            { key: "1", value: 0, price: 200 },
            { key: "2", value: 0, price: 100 },
            { key: "3", value: 0, price: 50 },
            { key: "4", value: 0, price: 20 },
            { key: "5", value: 0, price: 10 },
            { key: "6", value: 0, price: 5 },
        ],
        altWalletPieces: [
            { key: "7", value: 0, price: 2 },
            { key: "8", value: 0, price: 1 },
            { key: "9", value: 0, price: 0.50 },
            { key: "10", value: 0, price: 0.20 },
            { key: "11", value: 0, price: 0.10 },
            { key: "12", value: 0, price: 0.05 },
            { key: "13", value: 0, price: 0.02 },
            { key: "14", value: 0, price: 0.01 },
        ],
        total: 0,
        altTotal: 0,
        totalPrice: 0,
        altTotalPrice: 0,
        comment: "",
        storeEventId: 0,
        storeTemperatureId: 0,
        storeWeatherId: 0,
        showConfirmPopup: false,
        body: {},
        counterClients: 0,
        steps: {
            "1": false,
            "2": false,
            "3": false,
        },
        openSteps: {
            "1": false,
            "2": false,
        },
        activeStep: 1,
        cashDeposit: 0,
        checkDeposit: 0,
        amexDeposit: 0,
        creditCardDeposit: 0,
        giftCardDeposit: 0,
        altCashDeposit: 0,
        altCheckDeposit: 0,
        altAmexDeposit: 0,
        altCreditCardDeposit: 0,
        altGiftCardDeposit: 0,
        cashSafe: 0,
        altCashSafe: 0,
        checkSafe: 0,
        altCheckSafe: 0,
        altGiftSafe: 0,
        giftSafe: 0,
        customEspeces: 0,
        altCustomEspeces: 0,
        cashSafeMovedCashDeposit: 0,
        altCashSafeMovedCashDeposit: 0,
        wireDeposit: 0,
        altWireDeposit: 0,
        showInfoPopup: false,
        nextStep3: true,
        step2DisabledButtons: [],
        cashInSafe: 0,
        altCashInSafe: 0,
    };

    componentDidMount(): void {
        if (this.props.account.currency) {
            this.props.paymentsReportStart(this.props.account.currency)
        }
        if (this.props.account.altCurrency) {
            this.props.paymentsReportStart(this.props.account.altCurrency)
        }
        if (this.props.isTerminalOpen) {
            localStorage.isDetailSuccess === 'true' && this.props.storesFormStart();
            this.setState({ counterClients: Number(localStorage.getItem(LOCAL_STORAGE.clientCounter)) || 0 });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.props.terminalReuquest()
        }
        if (!prevProps.isClosed && this.props.isClosed) {
            this.props.terminalReuquest()
        }
        if (prevState.activeStep === 1 && this.state.activeStep === 2) {
            this.setState({ ...this.getSafeData() })
        }
        if (prevState.activeStep === 2 && this.state.activeStep === 3) {
            const { paymentsReportData, account: { altCurrency, currency, openingAmount, altOpeningAmount } } = this.props;
            const {
                cashDeposit, altCashDeposit, customEspeces,
                altCustomEspeces, cashSafeMovedCashDeposit,
                altCashSafeMovedCashDeposit, cashInSafe, altCashInSafe
            } = this.state;

            const getTotal = (currency, openingAmount, cashDeposit, cashSafeMovedCashDeposit, customEspeces, cashInSafe) => {
                const { paymentAmount = 0 } = this.getCashPayment(currency);
                const deposit = +replaceCommaToDecimal(cashDeposit) - +cashSafeMovedCashDeposit

                return customEspeces
                    ? +customEspeces.toString().replace(/,/g, ".")
                    : cashInSafe
                        ? openingAmount + paymentAmount - cashInSafe
                        : openingAmount + paymentAmount - deposit;
            }

            const total = getTotal(currency, openingAmount, cashDeposit, cashSafeMovedCashDeposit, customEspeces, cashInSafe);
            const altTotal = getTotal(altCurrency, altOpeningAmount, altCashDeposit, altCashSafeMovedCashDeposit, altCustomEspeces, altCashInSafe);
            
            this.setState({ total, altTotal });
        }
        if (prevState.activeStep === 2 && this.state.activeStep < 2) {
            this.setState({
                total: 0,
                altTotal: 0,
                cashDeposit: 0,
                checkDeposit: 0,
                amexDeposit: 0,
                creditCardDeposit: 0,
                giftCardDeposit: 0,
                altCashDeposit: 0,
                altCheckDeposit: 0,
                altAmexDeposit: 0,
                altCreditCardDeposit: 0,
                altGiftCardDeposit: 0,
                altWireDeposit: 0,
                wireDeposit: 0,
                ...this.getSafeData()
            })
        }
    }

    getCashPayment = (currency) => {
        const { paymentsReportData } = this.props;
        return paymentsReportData[currency] &&
        Array.isArray(paymentsReportData[currency].latestDayPayment)
            ? paymentsReportData[currency].latestDayPayment.find(
                  (i) => i.paymentMethodCode === PAYMENT_CODES.c5,
              ) || {}
            : {};
    };

    getSafeData = () => {
        const { paymentsReportData, account: { altCurrency, currency } } = this.props;
        let cashSafe = 0, checkSafe = 0, giftSafe = 0, altCashSafe = 0, altCheckSafe = 0, altGiftSafe = 0

        if (paymentsReportData[currency] && paymentsReportData[currency].previousDaySafe && paymentsReportData[currency].previousDaySafe) {
            cashSafe = paymentsReportData[currency].previousDaySafe.cashSafe || 0;
            checkSafe = paymentsReportData[currency].previousDaySafe.checkSafe || 0;
            giftSafe = paymentsReportData[currency].previousDaySafe.giftSafe || 0;
        }

        if (paymentsReportData[altCurrency] && paymentsReportData[altCurrency].previousDaySafe && paymentsReportData[currency].previousDaySafe) {
            altCashSafe = paymentsReportData[altCurrency].previousDaySafe.cashSafe || 0;
            altCheckSafe = paymentsReportData[altCurrency].previousDaySafe.checkSafe || 0;
            altGiftSafe = paymentsReportData[altCurrency].previousDaySafe.giftSafe || 0;
        }

        return { cashSafe, altCashSafe, checkSafe, altCheckSafe, altGiftSafe, giftSafe };
    }
    handleChangeStep = (step, action) => {
        if (step === 1 && action === "next") {
            this.setState({ activeStep: 2 })
            if (this.state.storeEventId > 0 && this.state.storeTemperatureId > 0 && this.state.storeWeatherId > 0 && step === 1) {
                this.setState(prevState => ({
                    steps: {
                        ...prevState.steps,
                        "1": true
                    }
                }))
            }
        }

        if (step === 2 && action === "next") {
            if(this.state.nextStep3){
                this.setState({showInfoPopup: true});
                return;
            }
            this.setState(prevState => ({
                steps: {
                    ...prevState.steps,
                    "2": true
                }, activeStep: 3
            }))
        }
        if (step === 2 && action === "prev") {
            this.setState(prevState => ({
                steps: {
                    ...prevState.steps,
                    "2": false
                }, activeStep: 1,
                step2DisabledButtons: [],
                nextStep3: true
            }))
        }
        if (step === 3 && action === "prev") {
            this.setState({ activeStep: 2 })
        }
    }
    handelChangeState = (data) => {
        if (data && Object.keys(data).length) {
            Object.keys(data).forEach(item => {
                this.setState({ [item]: data[item] })
            })
        }
    }
    handleBasketToggle = () => {
        this.props.handleClose()
    };

    handleChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }
    handleSubmiteOpenTerminal = (deposits = {}) => {
        const { wallet, walletPieces, altWallet, altWalletPieces, comment, totalPrice, altTotalPrice, total, altTotal } = this.state;
        const { account = {} } = this.props;
        const { currency, altCurrency } = account;
        const { cashSafe, altCashSafe, checkSafe, altCheckSafe, altGiftSafe, giftSafe } = this.getSafeData()
        const wallerObj = {}
        const altWalletObj = {}
        const body = {};
        for (const item of wallet) {
            wallerObj[`quantity${item.price}`] = parseInt(item.value);
        }
        for (const item of walletPieces) {
            wallerObj[`quantity${item.price}`] = parseFloat(item.value);
        }
        wallerObj["amount"] = totalPrice;
        wallerObj["currency"] = currency;
        if (deposits[currency]) {
            wallerObj["amexDeposit"] = 0;
            wallerObj["cashDeposit"] = 0;
            wallerObj["checkDeposit"] = 0;
            wallerObj["creditCardDeposit"] = 0;
            wallerObj["cashSafe"] = cashSafe;
            wallerObj["checkSafe"] = checkSafe;
            wallerObj["giftSafe"] = giftSafe;
        }
        wallerObj['cashDifference'] = totalPrice - +total;

        body['drawers'] = [{ ...wallerObj }]
        if (altCurrency) {
            for (const item of altWallet) {
                altWalletObj[`quantity${item.price}`] = parseInt(item.value);
            }
            for (const item of altWalletPieces) {
                altWalletObj[`quantity${item.price}`] = parseFloat(item.value);
            }
            altWalletObj["amount"] = altTotalPrice;
            altWalletObj["currency"] = altCurrency;
            if (deposits[altCurrency]) {
                altWalletObj["amexDeposit"] = 0;
                altWalletObj["cashDeposit"] = 0;
                altWalletObj["checkDeposit"] = 0;
                altWalletObj["creditCardDeposit"] = 0;
                altWalletObj["cashSafe"] = altCashSafe;
                altWalletObj["checkSafe"] = altCheckSafe;
                altWalletObj["giftSafe"] = altGiftSafe;
            }
            altWalletObj['cashDifference'] = altTotalPrice - +altTotal;
            body['drawers'] = [...body['drawers'], { ...altWalletObj }]
        }

        body["commentOpening"] = comment ? comment.toUpperCase() : "";
        this.props.terminalOpenStart(body);
    }

    handleSubmitCloseTerminal = () => {
        const {
            wallet, walletPieces, altWallet, altWalletPieces, comment, totalPrice, storeEventId, storeWeatherId, storeTemperatureId, counterClients, cashDeposit,
            checkDeposit, amexDeposit, creditCardDeposit, giftCardDeposit, altCashDeposit, altCheckDeposit, altAmexDeposit, altCreditCardDeposit,
            altGiftCardDeposit, altTotalPrice, cashSafe, checkSafe, altCashSafe, altCheckSafe, giftSafe, altGiftSafe, altTotal, total,
            altWireDeposit, wireDeposit
        } = this.state;
        const { account } = this.props;
        const isAltWallet = account && account.altCurrency;
        const wallerObj = {}
        const altWalletObj = {}
        const body = {};
        for (const item of wallet) {
            wallerObj[`quantity${item.price}`] = parseInt(item.value);
        }
        for (const item of walletPieces) {
            wallerObj[`quantity${item.price}`] = parseFloat(item.value);
        }
        wallerObj["amount"] = totalPrice;
        wallerObj["cashDeposit"] = +replaceCommaToDecimal(cashDeposit);
        wallerObj["checkDeposit"] = +replaceCommaToDecimal(checkDeposit);
        wallerObj["amexDeposit"] = +replaceCommaToDecimal(amexDeposit);
        wallerObj["creditCardDeposit"] = +replaceCommaToDecimal(creditCardDeposit);
        wallerObj["wireDeposit"] = +replaceCommaToDecimal(wireDeposit);
        wallerObj["giftCardDeposit"] = +replaceCommaToDecimal(giftCardDeposit);
        wallerObj["currency"] = account && account.currency;
        wallerObj["cashSafe"] = +replaceCommaToDecimal(cashSafe);
        wallerObj["checkSafe"] = +replaceCommaToDecimal(checkSafe);
        wallerObj["giftSafe"] = +replaceCommaToDecimal(giftSafe);
        wallerObj['cashDifference'] = totalPrice - +total;
        body['drawers'] = [{ ...wallerObj }]
        if (isAltWallet) {
            for (const item of altWallet) {
                altWalletObj[`quantity${item.price}`] = parseInt(item.value);
            }
            for (const item of altWalletPieces) {
                altWalletObj[`quantity${item.price}`] = parseFloat(item.value);
            }
            altWalletObj["amount"] = altTotalPrice;
            altWalletObj["cashDeposit"] = +replaceCommaToDecimal(altCashDeposit);
            altWalletObj["checkDeposit"] = +replaceCommaToDecimal(altCheckDeposit);
            altWalletObj["amexDeposit"] = +replaceCommaToDecimal(altAmexDeposit);
            altWalletObj["creditCardDeposit"] = +replaceCommaToDecimal(altCreditCardDeposit);
            altWalletObj["altWireDeposit"] = +replaceCommaToDecimal(altWireDeposit);
            altWalletObj["giftCardDeposit"] = +replaceCommaToDecimal(altGiftCardDeposit);
            altWalletObj["currency"] = isAltWallet;
            altWalletObj["cashSafe"] = +replaceCommaToDecimal(altCashSafe);
            altWalletObj["checkSafe"] = +replaceCommaToDecimal(altCheckSafe);
            altWalletObj["giftSafe"] = +replaceCommaToDecimal(altGiftSafe);
            altWalletObj['cashDifference'] = altTotalPrice - +altTotal;
            body['drawers'] = [...body['drawers'], { ...altWalletObj }]
        }

        if (this.props.isTerminalOpen) {
            if (!(storeEventId && storeWeatherId && storeTemperatureId)) {
                return;
            }
            body["commentClosing"] = comment ? comment.toUpperCase() : "";
            body["counterClients"] = +counterClients || null;
            body["storeEventId"] = storeEventId;
            body["storeWeatherId"] = storeWeatherId;
            body["storeTemperatureId"] = storeTemperatureId;
            body["expenses"] = getExpensesArray();
            this.setState({ body, showConfirmPopup: true })
        }

    }
    isDisabledCloseBtn = () => {
        const { storeEventId, storeWeatherId, storeTemperatureId, totalPrice, altTotalPrice, altTotal, total } = this.state;
        const { account } = this.props;
        const altWallet = account && account.altCurrencySymbol;
        if (altWallet) {
            if ((storeEventId && storeWeatherId && storeTemperatureId) &&
                (altTotalPrice !== 0 && altTotal === altTotalPrice) &&
                (totalPrice !== 0 && +total === totalPrice)) {

                return true
            } else {
                return false
            }
        } else {
            if (totalPrice !== 0 && +total === totalPrice) {
                return true
            } else {
                return false
            }
        }
    }
    isDisabledOpenBtn = () => {
        const { totalPrice, altTotalPrice, altTotal, total } = this.state;
        const { account } = this.props;
        const altWallet = account && account.altCurrencySymbol;
        if (altWallet) {
            if ((altTotalPrice !== 0 && +altTotal === altTotalPrice) && (totalPrice !== 0 && +total === totalPrice)) {
                return true
            } else {
                return false
            }
        } else {
            if (totalPrice !== 0 && +total === totalPrice) {
                return true
            } else {
                return false
            }
        }

    }

    isDisabledNextBtn = () => {
        const { storeEventId, storeTemperatureId, storeWeatherId, activeStep } = this.state
        if (activeStep === 3) {
            return true
        }
        
        if (activeStep === 1 && !(storeEventId && storeTemperatureId && storeWeatherId)) {
            return true
        }

        if(activeStep === 2 && this.props.nullBelegStatus){
            return true
        }

        return false
    }

    render(): React.ReactNode {
        const {
            comment, showConfirmPopup, counterClients, steps, activeStep,
            cashDeposit, checkDeposit, amexDeposit, creditCardDeposit, giftCardDeposit, altCashDeposit,
            altCheckDeposit, altAmexDeposit, altCreditCardDeposit, altGiftCardDeposit, altWallet,
            wallet, walletPieces, altWalletPieces, storeEventId, storeTemperatureId, storeWeatherId,
            total, altTotal, cashSafe, altCashSafe, checkSafe, altCheckSafe, altGiftSafe, giftSafe,
            customEspeces, altCustomEspeces, altWireDeposit, wireDeposit, showInfoPopup, step2DisabledButtons,
            totalPrice
        } = this.state;
        const { account, status, isLoading, storesForm = {}, paymentsReportData, terminalError, isTerminalOpen, dispatch } = this.props;
        const { storeEvents = [], storeWeathers = [], storeTemperatures = [] } = storesForm;
        const isDisabled = isTerminalOpen ? this.isDisabledCloseBtn() : this.isDisabledOpenBtn();
        const user = getUserFromToken();

        return (
            <>
                <BlackWindow type="page" handleClick={this.handleBasketToggle} />

                <div className={`fermeturePopUp ${(!isTerminalOpen && account.altCurrencySymbol) || ((activeStep === 3 || activeStep === 2) && account.altCurrencySymbol) ? "terminal-open" : ""}`}>
                    <div className="fermeturePopUp--header">
                        <img src={closeIcon} onClick={this.handleBasketToggle} alt="PHOTO" />
                    </div>
                    <div className="fermeturePopUp--body">
                        <div className="page-sub-menu-section-popup fermeturePopUp--body--userInfo">
                            <div className="page-sub-menu-section-popup--content container">
                                <ul className="terminal-info-popup">
                                    <li>
                                        <Clock /> |
                                        {account.storeDetails ? ` ${account.storeDetails.name} | ${account.storeDetails.storeType} | ` : ""}
                                        {account.terminalCode ? ` ${account.terminalCode} | ` : ""}
                                        {user.fname || ""}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="fermeturePopUp--body-form-content">
                            {
                                isTerminalOpen ?
                                    <div className="w-100">
                                        <TerminalSteps steps={steps} activeStep={activeStep} />
                                        <div>
                                            {activeStep === 1 &&
                                                <TerminalStep1
                                                    handelChangeState={this.handelChangeState}
                                                    storeEvents={storeEvents}
                                                    storeWeathers={storeWeathers}
                                                    storeTemperatures={storeTemperatures}
                                                    storeEventId={storeEventId}
                                                    storeTemperatureId={storeTemperatureId}
                                                    storeWeatherId={storeWeatherId}
                                                />
                                            }
                                            {activeStep === 2 &&
                                                <TerminalStep2
                                                    handelChangeWallet={this.handelChangeState}
                                                    cashDeposit={cashDeposit}
                                                    checkDeposit={checkDeposit}
                                                    amexDeposit={amexDeposit}
                                                    creditCardDeposit={creditCardDeposit}
                                                    giftCardDeposit={giftCardDeposit}
                                                    altCashDeposit={altCashDeposit}
                                                    altCheckDeposit={altCheckDeposit}
                                                    altAmexDeposit={altAmexDeposit}
                                                    altCreditCardDeposit={altCreditCardDeposit}
                                                    altGiftCardDeposit={altGiftCardDeposit}
                                                    paymentsReportData={paymentsReportData}
                                                    details={account}
                                                    cashSafe={cashSafe}
                                                    altCashSafe={altCashSafe}
                                                    checkSafe={checkSafe}
                                                    altCheckSafe={altCheckSafe}
                                                    giftSafe={giftSafe}
                                                    altGiftSafe={altGiftSafe}
                                                    customEspeces={customEspeces}
                                                    altCustomEspeces={altCustomEspeces}
                                                    altWireDeposit={altWireDeposit}
                                                    wireDeposit={wireDeposit}
                                                    step2DisabledButtons={step2DisabledButtons}
                                                    setStep2DisabledButtons={
                                                        (buttonCode: string) => this.setState(prev => ({step2DisabledButtons: [...prev.step2DisabledButtons, buttonCode]}))
                                                    }
                                                    setNextStep3={(status: boolean = false) => this.setState({nextStep3: status})}
                                                />
                                            }
                                            {activeStep === 3 &&
                                                <TerminalStep3
                                                    handelChangeWallet={this.handelChangeState}
                                                    details={account}
                                                    wallet={wallet}
                                                    walletPieces={walletPieces}
                                                    altWallet={altWallet}
                                                    altWalletPieces={altWalletPieces}
                                                    isTerminalOpen={false}
                                                    counterClients={counterClients}
                                                    comment={comment}
                                                    isLoading={isLoading}
                                                    isDisabled={isDisabled}
                                                    isStoreIds={!!(storeEventId && storeWeatherId && storeTemperatureId)}
                                                    submit={this.handleSubmitCloseTerminal}
                                                    status={status}
                                                    terminalError={terminalError}
                                                    paymentsReportData={paymentsReportData}
                                                    total={total}
                                                    altTotal={altTotal}
                                                    totalPrice={totalPrice}
                                                    dispatch={this.props.dispatch}
                                                />
                                            }

                                        </div>

                                        {activeStep === 1 ? <PrintNullBelegBtn visible={islastWorkDayOfMonth(new Date())} /> : ""}

                                        <div className="terminalClose--Controller">
                                            <button className={`btn_1 w-100 ${activeStep === 1 ? "disabled" : ""}`}
                                                disabled={activeStep === 1}
                                                onClick={() => this.handleChangeStep(activeStep, "prev")}>
                                                {i18next.t('Terminal.terminalClose.prev')}
                                            </button>
                                            <button className={`btn_1 w-100 ${this.isDisabledNextBtn() ? "disabled" : ""}`}
                                                disabled={this.isDisabledNextBtn()}
                                                onClick={() => this.handleChangeStep(activeStep, "next")}>
                                                {i18next.t('Terminal.terminalClose.next')}
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <OpenTerminal
                                        handelChangeState={this.handelChangeState}
                                        details={account}
                                        wallet={wallet}
                                        walletPieces={walletPieces}
                                        altWallet={altWallet}
                                        altWalletPieces={altWalletPieces}
                                        isTerminalOpen={true}
                                        counterClients={counterClients}
                                        comment={comment}
                                        isLoading={isLoading}
                                        isDisabled={isDisabled}
                                        handleSubmiteOpenTerminal={this.handleSubmiteOpenTerminal}
                                        status={status}
                                        paymentsReportData={paymentsReportData}
                                        loading={this.props.loadingReport}
                                        terminalError={terminalError}
                                        dispatch={this.props.dispatch}
                                        totalPrice={totalPrice}
                                    />
                            }

                        </div>
                    </div>

                </div>
                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showConfirmPopup: false })
                        }
                        handleConfirm={() =>
                            this.props.terminalCloseStart(this.state.body)
                        }
                        text={+counterClients === 0 ? i18next.t('Terminal.confirm.17') : ''}
                        additionText={i18next.t('Terminal.confirm.9')}
                    />
                )}
                <InfoPopUp
                    open={showInfoPopup}
                    classN="errorMessage"
                    message={i18next.t('alertMessage.11')}
                    handleClose={() => this.setState({showInfoPopup: false})}
                />
            </>
        );
    }
}
const mapStateToProps = (state: any): Props => ({
    account: getAccountResource(state),
    isOpen: getTerminalIsOpen(state),
    isTerminalOpen: getIsTerminalOpen(state),
    isClosed: getTerminalIsClosed(state),
    isLoading: getTerminalLoading(state),
    status: getTerminalStatus(state),
    storesForm: getStoresForm(state),
    paymentsReportData: getPaymentsReportData(state),
    loadingReport: paymentsReportLoading(state),
    terminalError: getTerminalError(state),
    nullBelegStatus: getNullBelegStatus(state)
});

const mapDispatchToProps = (dispatch): typeof TerminalControllerActionCreators & typeof ReportsActionCreators => {
    return bindActionCreators(Object.assign({}, TerminalControllerActionCreators, ReportsActionCreators, {dispatch}), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FermeturePopUp);
