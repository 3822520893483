import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { getDateTimestamp, getTotalSum } from '../../../../helpers/helpers';
import CustomBtn from '../../../common/customBtn';
import PdfTable from '../../../pdfGenerator/pdfTableBtn';
import ImportBarcodesBtn from '../../../common/importBarcodesBtn';

interface Props {
    handleUpload: (...res: any) => void,
    handleAjouter: () => void,
    disableFilBtn?: boolean,
    disabledAjouterBtn?: boolean,
    storData?: any,
    storeDetails: any,
    pdfData: any,
}

export default function(props : Props) {
    const { 
        handleUpload, handleAjouter, storeDetails, pdfData,
        disableFilBtn = false, storData = {}, disabledAjouterBtn = false
    } = props;

    const [pdfContent, setPdfContent] = useState<object[]>([]);
    const [pdfHeader, setPdfHeader] = useState<object[]>([]);

    useEffect( ()=> {
        setPdfContent(getPdfData(pdfData))
    }, [pdfData]);

    useEffect( ()=> {
        setPdfHeader(getPdfHeader(storData))
    }, [storData]);

    const columnsPdf : any = [
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: "name",
            width:"22%",
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: "barcode",
            width: "15%",
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: "size",
            width:"10%",
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.recvStoreStock'),
            selector: "recvStoreStock",
            width:"14%",
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.sendStoreStock'),
            selector: "sendStoreStock",
            width:"14%",
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.Qty'),
            selector: "quantity",
            width:"9%",
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.Expedited'),
            selector: "qtySend",
            width:"8%",
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.Controlled'),
            selector: "qtyRecv",
            width:"8%",
        },
    ];

    const getPdfData = (data = []) => {
        if(!Array.isArray(data)) return [];

        return data.filter((el: any) => el.barcode).map(
            (item: any) => (
                {
                    name: item.name,
                    size: item.size,
                    barcode: item.barcode,
                    qtySend: item.qtySend,
                    qtyRecv: item.qtyRecv,
                    quantity: item.quantity,
                    recvStoreStock: item.recvStoreStock,
                    sendStoreStock: item.sendStoreStock,
                }
            )
        );
    }

    const getPdfHeader = (storData) => {
        return [
            [i18next.t('Terminal.Receipt.Transfer.header')],
            [getDateTimestamp(Date.now()), `${storData.storeTransferCode} (${getDateTimestamp(storData.createdDate)})`],
            [i18next.t('Terminal.Receipt.Deliveries.cartons'), storData.totalBox],
            [i18next.t('Terminal.dashboards.references'), storData.products && storData.products.length],
            [i18next.t('Terminal.Receipt.Transfer.Qty'), getTotalSum(storData.products, "quantity")],
            [i18next.t('Terminal.Receipt.Transfer.reasonCode'), storData.storeTransferReason],
            [],
            [i18next.t('Terminal.Receipt.Transfer.sendName'), i18next.t('Terminal.Receipt.Transfer.signature')],
            [storData.storeNameSend],
            [storData.associateFirstNameSend],
            [storData.storeAddrSend],
            [],
            [i18next.t('Terminal.Receipt.Transfer.recvName'), i18next.t('Terminal.Receipt.Transfer.signature')],
            [storData.storeNameRecv],
            [storData.associateFirstNameRecv],
            [storData.storeAddrRecv]
        ]
    }

    return (
        <div className="transfer--pdf--btn">
            <div className='mr-1'>
                <ImportBarcodesBtn
                    handleUpload={handleUpload}
                    disabled={disableFilBtn}
                />
            </div>
            
            <CustomBtn
                loading={false}
                classN="btn_1 W-100"
                handleClick={handleAjouter}
                disabled={disabledAjouterBtn}
            >
                {i18next.t('Terminal.dashboards.ajouter')}
            </CustomBtn>

            <PdfTable
                disabled={false}
                classN="btn_1 blue_btn"
                columns={columnsPdf}
                data={pdfContent}
                header={pdfHeader}
            >
                {i18next.t('buttons.29')}
            </PdfTable>
        </div>
    )
}
