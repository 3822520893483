import React from "react";
import '../product.scss'
import ProductInfo from "./productInfo";
import Complect from "./complect";
import Details from "./details";

interface Props {
    handleBasketToggle?: any,
    handleGetWebStock?: any,
    handleDeliverytToggle?: any,
    data: any,
    webInventory: number,
    account?:any,
    selectedSize?: any,
}

interface State {
}

class ProductS extends React.Component<Props> {
    state: State = {};

    private divRef = React.createRef<HTMLDivElement>();
    public refDescription = React.createRef<HTMLDivElement>();

    scrollToDescription = () => {
        window.scrollTo(0, this.refDescription.current!.offsetTop - this.refDescription.current!.offsetHeight);
    }
    scrollTocompositeDataRef = () => {
        window.scrollTo(0, this.divRef.current!.offsetTop - 150);
    }

    render() {
        const {data, handleGetWebStock,webInventory,account} = this.props;
        const compositeData = data && Object.keys(data).length && data.compositeData;

        return (
            <div className="products 222">
                <ProductInfo 
                    handleBasketToggle={this.props.handleBasketToggle}
                    handleDeliverytToggle={this.props.handleDeliverytToggle}
                    data={data}
                    handleScrollToDescription={this.scrollTocompositeDataRef}
                    handleGetWebStock={handleGetWebStock}
                    webInventory={webInventory}
                    account={account}
                    selectedSize={this.props.selectedSize}
                />

                <div>
                    {data.compositeData && Object.keys(data.compositeData).length ?
                        <div ref={this.divRef}>
                            <Complect 
                                handleBasketToggle={this.props.handleBasketToggle} 
                                data={data}
                                handleScrollToDescription={this.scrollToDescription}
                                handleDeliverytToggle={this.props.handleDeliverytToggle}
                            />
                            <div className="pure-complect-composite">
                                <div className="container">
                                    <div className="pure-complect-content">
                                        <div className="pure-complect-about">
                                            <h4>{data.compositeData.name}</h4>
                                            <div
                                                dangerouslySetInnerHTML={{__html: data.compositeData.description}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </div>
                <div>
                    <Details refDescription={this.refDescription}
                             data={compositeData && Object.keys(compositeData).length ? compositeData : data}/>
                </div>
            </div>
        )
    }
}

export default ProductS;

