import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import i18next from "i18next";
import {StoreLocationActionCreators} from "../redux/actions";
import {getUserStore} from "../redux/selectors"
import LocationSearchInput from "../../locationSearchInput/container/locationSearchInput";
import {getLocationSearch} from "../../locationSearchInput/redux/selectors";
import {getOpeningHours, formatOpeningHours, formatPhone} from "../../../helpers/helpers";
import BlackWindow from "../../common/blackWindow";
import closeIcon from "./../../../assets/img/close.svg";
import {STORE_DELIVERY} from "../../../utils/constants/variables";
import "./storeLocation.scss"

interface Props {
    toggleStoreLocator?: any,
    locationSearchData?: any,
    userSetLocation?: any,
    storeLocationStart?: any,
    userStore?: any
}

interface State {
    showStoreLocator: boolean,
    showStoreCreated: boolean,
}

class StoreLocator extends React.Component<Props & State> {
    state: State = {
        showStoreLocator: false,
        showStoreCreated: false
    };

    handleClickClose = () => {
        this.setState({showStoreCreated: true});
        return setTimeout(this.props.toggleStoreLocator, 1000);
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.userStore !== this.props.userStore) {
            this.handleClickClose()
        }
    };

    render() {
        const {showStoreCreated} = this.state;
        const {locationSearchData,userStore} = this.props;
        return (
            <div className="storeLocator">
                <BlackWindow type="page" handleClick={this.props.toggleStoreLocator}/>
                <div className="storeLocator-content container">
                    <div className="storeLocator-content-head">
                        <h4 className="storeLocator-content-head-title">{i18next.t('locator.3')}</h4>
                        <img src={closeIcon} className="x" onClick={this.props.toggleStoreLocator}/>
                    </div>
                    <div className="storeLocator-wrapper">
                        <div className="storeLocator-wrapper-head">
                            <h4 className="storeLocator-title">{i18next.t('locator.6')}</h4>
                            <div className="search-content">
                                <div className="dflex justify-content-between">
                                    <LocationSearchInput classN="search-btn" showButton={true}/>
                                </div>
                            </div>
                        </div>

                        <div className="storeLocator-wrapper-body">
                            <h4 className="storeLocator-title">
                                {i18next.t('locator.7')}
                            </h4>

                            {
                                locationSearchData && locationSearchData.length ? locationSearchData.map((item) => {
                                    return (
                                        <div className="storeLocator-wrapper-item" key={item.storeId}>
                                            <div className="storeLocator-wrapper-item-content">
                                                <h4 className="storeLocator-wrapper-item-content-title">
                                                    {item.name}
                                                </h4>
                                                <div
                                                    className="dflex align-items-start flex-wrap storeLocator-wrapper-item-content-block">
                                                    <div>
                                                        <p>{item.address}</p>
                                                        <p>{item.zip}</p>
                                                        <p>Tél: {formatPhone(item.phone)}</p>
                                                        {
                                                            STORE_DELIVERY === "1" ? <div>
                                                                <button className="location-adres-item-btn"
                                                                        onClick={() => this.props.storeLocationStart(item)}>{i18next.t('locator.1')}</button>
                                                                {item.storeId === userStore.storeId && showStoreCreated &&
                                                                <p id="selected-store">{i18next.t('locator.5')}</p>}
                                                            </div> : ""
                                                        }
                                                    </div>
                                                    <div className="timeZoneBox">
                                                        {
                                                            getOpeningHours(item.openingHours) &&
                                                            getOpeningHours(item.openingHours).length ?
                                                                getOpeningHours(item.openingHours).map((hours, i) => {
                                                                    return (
                                                                        <div className='TimeBox' key={i}>
                                                                            <div>{` ${i18next.t(`weekDays.${i}`)}:`}</div>
                                                                            <div>{`${formatOpeningHours(hours)}`}</div>
                                                                        </div>
                                                                    )
                                                                }) : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    )
                                }) : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state: any): Props & typeof StoreLocationActionCreators => {
    return {
        locationSearchData: getLocationSearch(state),
        userStore: getUserStore(state)
    }
};
const mapDispatchToProps = (dispatch): typeof StoreLocationActionCreators => {
    return bindActionCreators(StoreLocationActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreLocator);