import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getStoreAssociatesSelector, getStoreAssociatesLoadingSelector } from '../../stocks/redux/selectors';
import { StockActionCreators } from '../../stocks/redux/actions';
import TableComponent from '../../datatable/container/datatable';
import PopUp from '../../popUp/popUp';
import AdminContent from './AdminContent';
import { getLangPrefix, isTerminalAdmin } from '../../../helpers/helpers';
import { Redirect } from 'react-router-dom';

interface IProps {
    adminPageLoadingStart: (status: boolean) => void;
    getStoreAssociatesStart: () => void;
    isLoading: boolean;
    storeAssociates: any;
}

const AdminDashboard: React.FC<IProps> = ({
    adminPageLoadingStart,
    getStoreAssociatesStart,
    isLoading,
    storeAssociates
}) => {
    const [isEditAssociateVisible, setIsEditAssociateVisible] = useState<boolean>(false);
    const [selectedAssociate, setSelectedAssociate] = useState<any>(null);
    useEffect(() => {
        getStoreAssociatesStart();
    }, [])

    const columns = [
        {
            name: i18next.t('Terminal.dashboards.id'),
            selector: 'id',
            sortable: true,
            searchable: true,
            center: true,
            width: '12%',
            isUnique: true
        },
        {
            name: 'Date',
            selector: 'createDate',
            sortable: true,
            searchable: true,
            center: true,
            width: '15%',
            cell: (row) => row.createDate.slice(0, row.createDate.indexOf(' '))
        },
        {
            name: i18next.t('Terminal.dashboards.storeCode'),
            selector: 'associateCode',
            sortable: true,
            searchable: true,
            center: true,
            width: '25%',
        },
        {
            name: i18next.t('Terminal.dashboards.adminName'),
            selector: 'size',
            searchable: false,
            sortable: true,
            center: true,
            width: '27%',
            cell: (row) => `${row.associateFirstName} ${row.associateLastName}`
        },
        {
            name: i18next.t('Terminal.dashboards.priority'),
            selector: 'priority',
            sortable: true,
            searchable: true,
            center: true,
            width: '11%',
        },
        {
            name: i18next.t('Terminal.dashboards.active'),
            selector: 'active',
            sortable: true,
            searchable: true,
            center: true,
            width: '10%',
            cell: (row) => (
                <div
                    className={`${
                        row.active ? 'color--green' : 'color--red'
                    }`}
                >
                    {row.active ? 'Y' : 'N'}
                </div>
            ),
        },
        {
            selector: 'recurring',
            notShow: true,
        },
        {
            selector: 'associateFirstName',
            notShow: true,
        },
        {
            selector: 'associateLastName',
            notShow: true,
        },
        {
            selector: 'associateEmail',
            notShow: true
        },
        {
            selector: 'associatePhone',
            notShow: true
        },
        {
            selector: 'photoUrl',
            notShow: true
        },
        {
            selector: 'terminalAdmin',
            notShow: true
        }
    ];

    const associateList = useMemo(
        () =>
            (storeAssociates || []).reduce((acc, item) => {
                acc.push(item);

                return acc;
            }, []),
        [storeAssociates],
    );

    const getAssociates = () => {
        getStoreAssociatesStart();
    }

    const toggleEditAssociatePopup = () => {
        setIsEditAssociateVisible(!isEditAssociateVisible);
    }

    const openAssociateEdit = (data) => {
        setSelectedAssociate(data);
        toggleEditAssociatePopup();
    }

    if (!isTerminalAdmin()) {
        return <Redirect to={`${window.location.origin}/${getLangPrefix()}`} />
    }

    return (
        <div>
            <TableComponent
                tableData={associateList}
                isLoading={isLoading}
                openPopUp={openAssociateEdit}
                columns={columns}
                reloadDashboard={getAssociates}
                keyField={'admin'}
                selectedRow={1}
                buttons={['adminButtons']}
                isService={true}
                selectableRows={true}
                searchPosition={'bottom'}
            ></TableComponent>

            {isEditAssociateVisible && (
                <PopUp handleClose={toggleEditAssociatePopup}>
                    <AdminContent
                        closePopup={toggleEditAssociatePopup}
                        selectedAssociate={selectedAssociate}
                    />
                </PopUp>
            )}
        </div>
    );
};

const mapStateToProps = (state: any): any => ({
    isLoading: getStoreAssociatesLoadingSelector(state),
    storeAssociates: getStoreAssociatesSelector(state),
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
