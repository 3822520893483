import {createReducer} from 'reduxsauce';
import {livraisonClientTypes} from './actions';

export interface ILivraisonClientState {
    livraisonClientData?: any,
    livraisonClientSingleData?:any,
    isLoading: boolean,
    isBookLoading: boolean,
    shippingResult: any,
    bookResult: any,
    error: any,
}

export const livraisonClientContent: ILivraisonClientState = {
    livraisonClientData: [],
    livraisonClientSingleData:{},
    isLoading: false,
    isBookLoading: false,
    shippingResult: {},
    bookResult: {},
    error: null
};

export const getLivraisonClientData = (state) => {
    return {...state, isLoading: true};
};

export const LivraisonClientSuccess = (state, action) => {
    return {...state, livraisonClientData: action.data, isLoading: false};
};

export const LivraisonClientFailure = (state, action) => {
    return {...state, error: action.error, livraisonClientData: [], isLoading: false};
};

export const getLivraisonClientSingleData = (state) => {
    return {...state};
};

export const LivraisonClientSingleSuccess = (state, action) => {
    return {...state, livraisonClientSingleData: action.data};
};

export const LivraisonClientSingleFailure = (state, action) => {
    return {...state, error: action.error, livraisonClientSingleData: {}};
};

export const createLivraisonClientShipping = (state) => {
    return {...state, isLoading: true};
};

export const createLivraisonClientShippingSuccess = (state, action) => {
    return {...state, shippingResult: action.data, isLoading: false};
};

export const createLivraisonClientShippingFailure = (state, action) => {
    return {...state, error: action.error, shippingResult: {}, isLoading: false};
};

export const createLivraisonClientBook = (state) => {
    return {...state, isBookLoading: true};
};

export const createLivraisonClientBookSuccess = (state, action) => {
    return {...state, bookResult: action.data, isBookLoading: false};
};

export const createLivraisonClientBookFailure = (state, action) => {
    return {...state, error: action.error, bookResult: {}, isBookLoading: false};
};

export const emptyLivraisonClientBook = (state) => {
    return {...state, bookResult: {}, isBookLoading: false};
};

export const annulerErrorSuccess = (state) => {
    return {...state, error: null};
};

export const handlers = {
    [livraisonClientTypes.LIVRAISON_CLIENT_START]: getLivraisonClientData,
    [livraisonClientTypes.LIVRAISON_CLIENT_SUCCESS]: LivraisonClientSuccess,
    [livraisonClientTypes.LIVRAISON_CLIENT_FAILURE]: LivraisonClientFailure,
    [livraisonClientTypes.LIVRAISON_CLIENT_SINGLE_START]: getLivraisonClientSingleData,
    [livraisonClientTypes.LIVRAISON_CLIENT_SINGLE_SUCCESS]: LivraisonClientSingleSuccess,
    [livraisonClientTypes.LIVRAISON_CLIENT_SINGLE_FAILURE]: LivraisonClientSingleFailure,
    [livraisonClientTypes.LIVRAISON_CLIENT_SHIPPING_START]: createLivraisonClientShipping,
    [livraisonClientTypes.LIVRAISON_CLIENT_SHIPPING_SUCCESS]: createLivraisonClientShippingSuccess,
    [livraisonClientTypes.LIVRAISON_CLIENT_SHIPPING_FAILURE]: createLivraisonClientShippingFailure,
    [livraisonClientTypes.LIVRAISON_CLIENT_BOOK_START]: createLivraisonClientBook,
    [livraisonClientTypes.LIVRAISON_CLIENT_BOOK_SUCCESS]: createLivraisonClientBookSuccess,
    [livraisonClientTypes.LIVRAISON_CLIENT_BOOK_FAILURE]: createLivraisonClientBookFailure,
    [livraisonClientTypes.LIVRAISON_CLIENT_EMPTY_BOOK_START]: emptyLivraisonClientBook,
    [livraisonClientTypes.ANNULER_ERROR_SUCCESS]: annulerErrorSuccess

};

export default createReducer(livraisonClientContent,handlers);
