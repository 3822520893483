import React, { Component } from 'react';
import i18next from 'i18next';
import { DeliveriesActionCreators } from '../../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomBtn from '../../../common/customBtn';
import { Input, Table, Dropdown } from "semantic-ui-react";
import { getBarcodeList, getDeliveries, getLoading, getCreatedStatus, getBarcodeByColisLoading } from '../../redux/selectors';
import { LOGISTIC_STATUS_CODE } from '../../../../utils/constants/variables';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import closeIcon from "../../../../assets/img/close_black.svg"

interface Props {
    barcodeLsit: any,
    isLoading: boolean,
    deliveries: [],
    openDeliveryDetails?: Function;
    simpleValidation?: boolean;
    status: string;
    closeColispopup?: () => void;
    updateQty?: (number) => void;
    parcels?: [];
    isBarcodeByColisLoading: boolean;
}

interface State {
    parcelNumber: string;
    isBarcodeListExist: boolean;
    showConfirmPopup: boolean;
    showResponseMessage: boolean;
    message: string;
    isConfirmationLoading: boolean;
}
class ColisPopup extends Component<
    Props & typeof DeliveriesActionCreators,
    State
> {
    state: State = {
        parcelNumber: '',
        isBarcodeListExist: Boolean(this.props.barcodeLsit.length),
        showConfirmPopup: false,
        showResponseMessage: false,
        message: i18next.t('alertMessage.2'),
        isConfirmationLoading: false,
    };

    componentDidMount() {
        this.showTable()
    }

    componentDidUpdate(prevProps) {
        const { status } = this.props;
        this.showTable()
        if (status !== '' && !this.state.showResponseMessage && prevProps.status !== status) {
            this.setState({
                showResponseMessage: true,
                message: status === 'success' ? i18next.t('alertMessage.2') : i18next.t('alertMessage.1'),
                isConfirmationLoading: false
            });
        }
    }

    showTable = () => {
        if (this.props.barcodeLsit.length && this.state.isBarcodeListExist !== true) {
            this.setState({ isBarcodeListExist: true });
        } else if (!this.props.barcodeLsit.length && this.state.isBarcodeListExist !== false) {
            this.setState({ isBarcodeListExist: false });
        }
    }

    getBarcodes = () => {
        const { parcelNumber } = this.state;
        this.props.getBarcodeByColisStart(parcelNumber)
    }

    validateBarcode = () => {
        const { parcelNumber } = this.state;
        parcelNumber && +parcelNumber && this.props.updateQty(parcelNumber);
    }

    handleChange = (target: any) => {
        const number = target && target.value && target.value.trim() || '';
        this.setState({
            parcelNumber: number
        });
    }

    openDeliveryDetails = (slipNumber: number) => {
        const storeDeliveryId = (
            this.props.deliveries.find((delivery) => delivery.slipNumber === slipNumber) || { storeDeliveryId: null }
        ).storeDeliveryId
        this.props.openDeliveryDetails({ storeDeliveryId });
    }

    isValidationDisabled = (): boolean => {
        const { barcodeLsit } = this.props;
        if (barcodeLsit.length === 0) return true;
        const notPro = barcodeLsit.find((item) => item.logisticStatus !== LOGISTIC_STATUS_CODE.PRO);
        return notPro || barcodeLsit.lenght === 0 ? true : false;
    }

    createProductList = (list) => {
        const { parcelNumber } = this.state;
        return list.reduce((acc, item) => {
            const qtyChecked = item.parcelNumber === parcelNumber ? item.totalQtyDelivered : item.totalQtyChecked;
            acc.storeDeliveryProducts.push({
                parcelNumber: item.parcelNumber,
                barcode: item.barcode,
                qtyDelivered: item.totalQtyDelivered,
                qtyChecked,
                qtyDefect: item.totalQtyDefect || '',
                comment: item.comment,
                name: item.name,
                size: item.size,
            })
            acc.totalQtyChecked += qtyChecked;
            return acc;
        }, { storeDeliveryProducts: [], totalQtyChecked: 0 })
    }

    createStoreDelivery = () => {
        const { barcodeLsit = [], deliveries } = this.props;
        const delivery = deliveries.find((delivery) => delivery.storeDeliveryId === barcodeLsit[0].storeDeliveryId);
        const { storeDeliveryProducts, totalQtyChecked } = this.createProductList(delivery.products);
        const data = {
            logisticStatus: LOGISTIC_STATUS_CODE.PRO,
            storeDeliveryId: delivery.storeDeliveryId,
            slipNumber: delivery.slipNumber,
            comment: delivery.comment,
            slipDate: delivery.slipDate,
            totalBoxChecked: delivery.totalBoxChecked || 1,
            totalQtyChecked: totalQtyChecked,
            totalBoxQuantity: delivery.totalBoxQuantity,
            storeDeliveryProducts: storeDeliveryProducts,
        }

        this.props.createDeliveriesStart(data);
        this.setState({ showConfirmPopup: false, isConfirmationLoading: true })
    }

    closeResponseModal = () => {
        const { message } = this.state;
        this.setState({ showResponseMessage: false })
        if (message === i18next.t('alertMessage.2')) {
            this.props.emptyBarcodeByColisSuccess();
            this.setState({ parcelNumber: '' })
        }
    }
    
    render() {
        const { parcelNumber, isBarcodeListExist, isConfirmationLoading } = this.state;
        const { barcodeLsit, simpleValidation, closeColispopup, parcels = [], isBarcodeByColisLoading } = this.props;
        const { showConfirmPopup, showResponseMessage, message } = this.state;
        const isValidateColisDisabled = this.isValidationDisabled()

        return (
            <>
                <div className="colisPopup">
                    {simpleValidation &&
                        <div className="colisPopUp--header">
                            <img src={closeIcon} onClick={() => closeColispopup()} alt="PHOTO" />
                        </div>
                    }
                    <div className="popUp--body set-data-content">
                        <div className="content">
                            <div className="w-50 colis-searchbar-container">
                                <label className="colis-label color--lightBlue">
                                    {i18next.t('Terminal.dashboards.colisNumber')}
                                </label>
                                {parcels.length && simpleValidation ? (
                                    <Dropdown
                                        onChange={(e, target) => this.handleChange(target)}
                                        options={parcels.map((number, index) => ({
                                            key: index,
                                            value: number,
                                            text: number
                                        }))}
                                        selection
                                        value={parcelNumber}
                                        className="ml-05 w-100 parcels-dropdown"
                                    />
                                    ) : (
                                        <Input
                                            placeholder='Colis'
                                            type="number"
                                            value={parcelNumber}
                                            onChange={({ target }) => this.handleChange(target)}
                                            autoFocus
                                        />
                                    )
                                }
                                <CustomBtn
                                    classN="btn_1 green_btn w-100"
                                    loading={isBarcodeByColisLoading}
                                    disabled={!Boolean(parcelNumber)}
                                    handleClick={simpleValidation ? this.validateBarcode : this.getBarcodes}
                                >
                                    {i18next.t('Terminal.Block3.2')}
                                </CustomBtn>
                                {!simpleValidation &&
                                    <div>sssss</div>
                                }
                            </div>
                            {isBarcodeListExist && !simpleValidation &&
                                <div className="w-100 mt-2">
                                    <Table celled selectable className="productInfoBlock--infoBox--table collaps">
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.Receipt.Deliveries.slipNumber')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.Receipt.Transfer.barcode')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.code')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.Name')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.Size')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.Receipt.Deliveries.qtyDelivered')}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {barcodeLsit.map((barcodeData, index) => (
                                                <Table.Row key={index}>
                                                    <Table.Cell
                                                        onClick={() => this.openDeliveryDetails(barcodeData.slipNumber)}
                                                        className="productInfoBlock--infoBox--table--description cursor"
                                                    >
                                                        {barcodeData.slipNumber}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {barcodeData.barcode}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {barcodeData.altReference}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {barcodeData.productNameLevel1}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {barcodeData.size}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {barcodeData.qtyDelivered}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            }
                            {showConfirmPopup &&
                                <ConfirmPopup
                                    handleCancel={() => {
                                        this.setState({ showConfirmPopup: false });
                                    }}
                                    handleConfirm={this.createStoreDelivery}
                                    text={i18next.t('Terminal.modal.2')}
                                />
                            }
                            {showResponseMessage &&
                                <ConfirmPopup
                                    handleCancel={() =>
                                        this.setState({ showResponseMessage: false })
                                    }
                                    handleConfirm={() => this.closeResponseModal()}
                                    text={message}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    barcodeLsit: getBarcodeList(state),
    isLoading: getLoading(state),
    deliveries: getDeliveries(state),
    status: getCreatedStatus(state),
    isBarcodeByColisLoading: getBarcodeByColisLoading(state)
});

const mapDispatchToProps = (dispatch): typeof DeliveriesActionCreators => {
    return bindActionCreators(DeliveriesActionCreators, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ColisPopup);
