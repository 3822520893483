import React, { useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppointmentsActionCreators } from '../redux/actions';
import { Input, TextArea } from "semantic-ui-react";
import i18next from "i18next";
import { appointmentFormData } from "../../../utils/constants/formsState";

interface Props {
    selectedAppointment: any;
    closePopup: () => void;
}

const AppointmentDetails: React.FC<Props> = ({
    selectedAppointment
}) => {
    const [formData, setFormData] = useState<any>(appointmentFormData(selectedAppointment))


    const getFormDataByName = (name) => {
        const result = formData[name] || "";

        return result;
    }

    return (
        <div>
            <div className="popUp--body--card--body--between">
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('AssociateService.brandId')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description`}
                        name="brandId"
                        value={getFormDataByName("brandServiceCalendarId")}
                        autoComplete="off"
                        disabled
                    />
                </div>
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.ordine')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description`}
                        name="orderNumber"
                        value={getFormDataByName("orderNumber")}
                        autoComplete="off"
                        disabled
                    />
                </div>
            </div>
            <div className="popUp--body--card--body--between">
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.operator')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description`}
                        name="brandId"
                        value={getFormDataByName("associateFirstName")}
                        autoComplete="off"
                        disabled
                    />
                </div>
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.service')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description`}
                        name="brandId"
                        value={getFormDataByName("subject")}
                        autoComplete="off"
                        disabled
                    />
                </div>
            </div>
            <div className="popUp--body--card--body--between">
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.startDate')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description`}
                        name="brandId"
                        value={getFormDataByName("startTime")}
                        autoComplete="off"
                        disabled
                    />
                </div>
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.endDate')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description`}
                        name="brandId"
                        value={getFormDataByName("endTime")}
                        autoComplete="off"
                        disabled
                    />
                </div>
            </div>
            <div className="popUp--body--card--body--data">
                <div className="popUp--body--card--body--data--header">
                    {i18next.t('product.details.2')}
                </div>
                <TextArea
                    className={`popUp--body--card--body--data--description bold disabled textarea-height`}
                    name="brandId"
                    value={getFormDataByName("description")}
                    autoComplete="off"
                    disabled
                />
            </div>
            <div className="popUp--body--card--body--data">
                <div className="popUp--body--card--body--data--header">
                    {i18next.t('Terminal.dashboards.location')}
                </div>
                <TextArea
                    className={`popUp--body--card--body--data--description bold disabled textarea-height`}
                    name="brandId"
                    value={getFormDataByName("location")}
                    autoComplete="off"
                    disabled
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any): any => ({
    // isLoading: getAppointmentLoading(state),
    // appointmentData: getAppointmentData(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(AppointmentsActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);