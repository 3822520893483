import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { AddToCartActionCreators } from '../../../addToCart/redux/actions';
import { getBasket } from '../../../addToCart/redux/selectors';
import {
    getActiveBasketName,
    getLocalStorageObject,
    getSousTotal,
    changeSousTotal,
    getPercentFormat,
    removeBasketCoupon,
    priceThreeDigitSets,
    getBasketData,
    sortBasketByDate,
    replaceDote,
    getBasketProductsBySizes,
    getBasketCoupon,
    getItemProductTotalPrice,
    getAbbreviatedCurrencySymbol,
    getBasketItemProduct,
    isValidSousTotalDiscountValue,
    getLocalFormItem,
} from '../../../../helpers/helpers';
import Photo from '../../../common/product/photo';
import Price from '../../../common/product/price';
import PercentPopUp from '../../../percentPopUp/container/percentPopUp';
import { DEFAULT_CURRENCY_SYMBOL, PRODUCT_ITEM_TYPES, PRODUCT_MAX_QUANTITY } from '../../../../utils/constants/variables';
import { getAccountResource } from '../../../login/redux/selectors';
import { Icon } from 'semantic-ui-react';
import ReturnSidePanel from './returnSidePanel';
import { getBasketGifts } from '../../../bonsCadeaux/redux/selectors';

interface Props {
    basket: any;
    value: string | number;
    accountResource: object;
    handleChangeCalculatorValue: () => void;
    activeAction: string;
    isSousTotal: boolean;
    scrollRef: any;
    scrollRefDiv: any;
    handleActiveField: () => void;
    scrollCount: number;
    activeField: string;
    itemsName: any[];
    setActiveField: () => void;
    paperReceipt: any,
    fetchStocks: () => void;
    gifts: any[]
}

interface State {
    showPercentPopUp: boolean,
    showReturnSidePanel: boolean,
    activeBasketName: string
}

class SummaryBasket extends Component<Props & typeof AddToCartActionCreators, State> {
    state: State = {
        showPercentPopUp: false,
        showReturnSidePanel: false,
        activeBasketName: ''
    };
    componentDidMount(){
        this.setState({activeBasketName: getActiveBasketName()});
        this.props.fetchStocks()
    }

    componentDidUpdate(prevProps, prevState) {
        const { value, activeAction, activeField, scrollCount, basket } = this.props;

        if (activeAction === 'sousTotal' && value && value === 'SetSousTotal' && value !== prevProps.value) {
            getSousTotal(scrollCount);
        }

        if (activeAction === 'sousTotal' && value && value === 'RemoveSousTotal' && value !== prevProps.value) {
            changeSousTotal('remove', scrollCount);
        }
        if (activeAction === 'remboursement' && value && value === 'RevertProduct' && value !== prevProps.value && activeField) {
            const ids = activeField.split('/');
            const data = getBasketItemProduct(basket, ids[1]);
            if(data && data.startingPrice <=0){
                this.handleChangeValue('startingPrice', -1);
            }else {
                this.props.handleChangeCalculatorValue('');
            }
        }
        if (activeAction && value && value !== prevProps.value && activeField) {
            const ids = activeField.split('/');
            if (activeAction === 'quantity') {
                if (value.toString() === "0") {
                    changeSousTotal('removeIndex', scrollCount);
                    this.handleRemoveItem(ids[0], +ids[1]);
                } else {
                    this.props.addtocartStart(
                        getActiveBasketName(),
                        ids[0],
                        {
                            size: {
                                quantity: value,
                                productId: ids[1],
                            },
                        },
                        true,
                    );
                    changeSousTotal('update', scrollCount);
                    this.props.handleChangeCalculatorValue('');
                    this.props.getBasketStart(getActiveBasketName())
                }
            } else if (activeAction === 'price' && +value !== 0) {
                let product: any = {};
                if(basket[ids[0]] && basket[ids[0]].sizes){
                    product = basket[ids[0]].sizes.find(item => item.productId === +ids[1]) || {}
                }
                const size = {
                    startingPrice: +value, 
                    productId: ids[1],
                    oldPrice: product.oldPrice
                };
                this.props.addtocartStart(getActiveBasketName(), ids[0], { size });
                changeSousTotal('update', 0);
                this.props.handleChangeCalculatorValue('');
                
            } else if (['reductionPercent', 'reductionPercentAll', 'reductionMoney'].includes(activeAction)) {
                
                if(this.props.isSousTotal && activeAction === 'reductionMoney' && !isValidSousTotalDiscountValue(basket, parseFloat(value), this.props.scrollCount)) {
                    return;
                }
                
                if(activeAction === 'reductionMoney' && !this.props.isSousTotal){
                    const { promoListPriceAfterDiscount, startingPrice } = getBasketItemProduct(basket, ids[1]);
                    const price = promoListPriceAfterDiscount || startingPrice;
                    if(parseFloat(value) > price) return;
                }
                
                if (parseFloat(value)) {
                    this.setState({ showPercentPopUp: true });
                } else {
                    this.handleChangeValue('discountDetail', '0');
                }
            } else if(activeAction === 'remboursement') {
                const data = getBasketItemProduct(basket, ids[1]);
                if(data && data.startingPrice >=0 && value !== 'RevertProduct'){
                    const {itemType, parentID, productId} = data
                    if(itemType === PRODUCT_ITEM_TYPES.GFC) {
                        const {id, code} = getLocalFormItem('returnReasonStoreList', 'code', 'CAR')
                        this.props.addtocartStart(getActiveBasketName(), parentID, {
                            size: {
                                startingPrice: value,
                                productId,
                                returnReasonStore: {id, code}
                            },
                        })
                        this.props.handleChangeCalculatorValue('');
                    }else{
                        this.setState({showReturnSidePanel: true})
                    }
                }else {
                    this.props.handleChangeCalculatorValue('');
                }
            } else {
                this.handleChangeValue(activeAction, value);
            }
        }
        if(this.state.activeBasketName !== getActiveBasketName()){
            this.props.fetchStocks();
            this.setState({activeBasketName: getActiveBasketName()})
        }
    }

    handleChangeValue(actionName, value) {
        const ids = this.props.activeField.split('/');
        this.props.addtocartStart(getActiveBasketName(), ids[0], {
            size: {
                [actionName]: value,
                productId: ids[1],
            },
        });
        changeSousTotal('update', 0);
        this.props.handleChangeCalculatorValue('');
    }

    handleRemoveItem = (productId, sizeElementProductId) => {
        const {ticketNumber} = getBasketData(this.props.basket);
        const itemsName = [...this.props.itemsName];
        const index = itemsName.indexOf(`${productId}/${sizeElementProductId}`);
        if(ticketNumber) return;
        if (index > -1) {
            itemsName.splice(index, 1);
            changeSousTotal("removeIndex",index);
            this.props.handleChangeCalculatorValue('')
        }
        this.props.removeBasketStart(
            this.props.basketName || getActiveBasketName(),
            productId,
            sizeElementProductId,
        );

        if (this.props.activeField === `${productId}/${sizeElementProductId}`) {
            const activeField = itemsName.length
                ? itemsName[index] || itemsName[itemsName.length - 1]
                : '';
            const scroll=itemsName.length ? itemsName[index] ? index : itemsName.length - 1 : 0;
            this.props.setActiveField(activeField, itemsName,scroll);
        }
    };

    closePercentPopUp = () => {
        this.setState({ showPercentPopUp: false });
        this.props.handleChangeCalculatorValue('');
    };
    removeCoupons = (discountCode) => {
        removeBasketCoupon(discountCode);
        this.props.getBasketStart(getActiveBasketName())
    };
    render() {
        const { basket, activeAction, isSousTotal, activeField, scrollCount, gifts } = this.props;
        const coupons= getBasketCoupon();
        const { showPercentPopUp, showReturnSidePanel } = this.state;
        let sousTotal = getLocalStorageObject(getActiveBasketName()).sousTotal || [];
        const options: any[] = [];
        for (let i = 1; i <= PRODUCT_MAX_QUANTITY; i++) {
            options.push({ value: i.toString(), text: i.toString() });
        }
        const currency = getAbbreviatedCurrencySymbol(DEFAULT_CURRENCY_SYMBOL);
        return (
            <>
                {
                    showReturnSidePanel &&
                        <ReturnSidePanel
                            handleClose={() => {
                                this.setState({showReturnSidePanel: false});
                                this.props.handleChangeCalculatorValue('')
                            }}
                            addToCart={this.props.addtocartStart}
                            value={this.props.value}
                            activeField={activeField}
                        />
                }
                {showPercentPopUp ? (
                    <PercentPopUp
                        handleClose={this.closePercentPopUp}
                        activeField={activeField}
                        addToCart={this.props.addtocartStart}
                        resetCalculator={this.props.handleChangeCalculatorValue}
                        activeAction={activeAction}
                        sousTotal={
                            sousTotal[
                                sousTotal.findIndex(
                                    (item) => item.position === scrollCount,
                                )
                            ]
                        }
                        isSousTotal={isSousTotal}
                        value={this.props.value}
                        basket={basket}
                    />
                ) : (
                    ''
                )}
                <div className="summary">
                    <div className="summery-content">
                        <div className="summary-header dflex align-items-center">
                            <h4 className="title">
                                {i18next.t('Terminal.Receipt.Sale.Product')}
                            </h4>
                            <h4 className="title">
                                {i18next.t('Terminal.Receipt.Sale.Qty')}
                            </h4>
                            <h4 className="title">
                                {i18next.t('Terminal.Receipt.Sale.unitPrice')}
                            </h4>
                            <h4 className="title">
                                {i18next.t('Terminal.Receipt.Sale.listPrice')}
                            </h4>
                            <h4 className="title">
                                {i18next.t('Terminal.Receipt.Sale.SubTotal')}
                            </h4>
                        </div>
                        {
                            coupons && coupons.length ?
                                coupons.map(({ discountCode, couponType, value, valid }) => {
                                    if(valid){return (
                                        <div className="summary-header dflex align-items-center summary--gift--info" key = {discountCode}>
                                            <h4 className="title">{discountCode}</h4>
                                            <h4 className="title">1</h4>
                                            <h4 className="title"> </h4>
                                            <h4 className={`title revert`}>
                                                {couponType.toUpperCase() === "PERCENT"
                                                    ? getPercentFormat( -value)
                                                    : priceThreeDigitSets( -value)}
                                            </h4>
                                            <h4 className="title"> </h4>
                                            <h4 className="title trash">
                                                <Icon name="trash alternate" onClick={() => this.removeCoupons(discountCode)}/>
                                            </h4>
                                        </div>
                                    )}else {return ""}
                                })
                                : ""
                        }
                        {

                            gifts && gifts.length ?
                                gifts.map(({ storeGiftCode, percent, amount, active }) => {
                                    return (
                                        <div className="summary-header dflex align-items-center summary--gift--info" key = {storeGiftCode}>
                                            <h4 className="title">{storeGiftCode}</h4>
                                            <h4 className="title">1</h4>
                                            <h4 className="title"> </h4>
                                            <h4 className={`title ${active ? "revert" :""}`}>
                                                {percent
                                                    ? getPercentFormat(active ? -percent : percent)
                                                    : priceThreeDigitSets( active ? -amount : amount)}
                                            </h4>
                                            <h4 className="title"> </h4>
                                            <h4 className="title trash">
                                                <Icon
                                                    name="trash alternate"
                                                    onClick={() => this.props.removeBasketGiftsStart(storeGiftCode)}
                                                />
                                            </h4>
                                        </div>
                                    )
                                })
                                : ""
                        }
                        <div className="products" ref={this.props.scrollRef}>
                            <div
                                className="summaryProducts"
                                ref={this.props.scrollRefDiv}
                            >
                                {getBasketProductsBySizes(basket).sort((a,b)=>sortBasketByDate(a,b)).map(
                                    (item: any, index) => {
                                      const sousTotalVal = sousTotal.findIndex((item) => item.position === index );
                                      return (
                                          <div
                                              className={`summary-item dflex align-items-center ${`${item.parentID}/${item.productId}` === activeField ? 'active' : ''}`}
                                              key={item.productId}
                                          >
                                              <div
                                                  className="summary-item-about dflex align-items-stretch"
                                                  onClick={this.props.handleActiveField(`${item.parentID}/${item.productId}`, index )}
                                              >
                                                  <div className="summary-item-img">
                                                      {basket[item.parentID].photo ?
                                                          <Photo
                                                              src={basket[item.parentID].photo}
                                                              discountDetail={''}
                                                          /> : ""
                                                      }
                                                  </div>
                                                  <div className="summary-item-about-content dflex flex-column justify-content-between">
                                                      <h4 className="title">
                                                          {basket[item.parentID].name}
                                                      </h4>
                                                      <span className="barcode">
                                                          {item.productReference || ''}
                                                      </span>
                                                      <div className="summary-item-about-text">
                                                          <p>
                                                              {i18next.t('product.product.17',)}{' '}:{' '}
                                                              {item.size}
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div
                                                  className="dflex align-items-center summary-item-price"
                                                  onClick={this.props.handleActiveField(`${item.parentID}/${item.productId}`, index )}
                                              >
                                                  <div className="select-wrapper">
                                                      <div className={`default-qty summary-box ${item.startingPrice < 0 ? 'revert ' : ''}`}>
                                                          {item.startingPrice < 0 ? replaceDote(-item.quantity) : replaceDote(item.quantity)}
                                                          {item.returnReasonStore && item.returnReasonStore.reason ? <div className="revert">{item.returnReasonStore.reason.slice(0,19)}</div> :
                                                              item.stock || item.stock ===0 ?<div className={`default-qty--stocks ${ item.stock>0? "green" :"red"}`}>{i18next.t("Terminal.dashboards.stock")} : {item.stock}</div>:""}
                                                      </div>
                                                  </div>
                                                  <div className={`summary-box ${item.startingPrice < 0 ? 'revert ' : ''}`}>
                                                      <Price
                                                          startP={item.startingPrice}
                                                          newP={item.listPriceAfterDiscount}
                                                          percent={item.promoDiscountDetail || ''}
                                                          quantity={1}
                                                          discount=""
                                                          currency={currency}
                                                      />
                                                  </div>
                                                  <div className={` summary-box ${item.startingPrice < 0 ? 'revert ' : ''}`}>
                                                      {
                                                        <Price
                                                            startP={item.taxFreeTotalPrice || item.startingPrice}
                                                            discount={parseFloat(item.discountDetail) ? item.discountDetail : ''}
                                                            newP={getItemProductTotalPrice(item)}
                                                            isOneQty={true}
                                                            quantity={item.taxFreeTotalPrice ? 1 : item.quantity}
                                                            isSale={!!(item.subTotalDiscount && Object.keys(item.subTotalDiscount).length)}
                                                            currency={currency}
                                                        />
                                                      }
                                                      {
                                                          parseFloat(item.discountDetail) && item.specialDiscountId && item.specialDiscountId.specialDiscount ?
                                                              <span className="discount-code">
                                                                  {`${item.discountDetail} ${item.specialDiscountId.specialDiscount}`}
                                                              </span>
                                                              : ""
                                                      }
                                                      {
                                                         item.subTotalDiscount && parseFloat(item.subTotalDiscount.discountDetail) && item.subTotalDiscount.specialDiscountId && item.subTotalDiscount.specialDiscountId.specialDiscount ?
                                                              <span className="discount-code">
                                                                  {`${item.subTotalDiscount.discountDetail} ${item.subTotalDiscount.specialDiscountId.specialDiscount}`}
                                                              </span>
                                                              : ''
                                                      }
                                                  </div>
                                                  <div className={`summary-box sousTotal ${item.startingPrice < 0 ? 'revert ' : ''}`}>
                                                      {sousTotal[sousTotalVal] &&
                                                      Object.keys(sousTotal[sousTotalVal]).length ? (
                                                          <>
                                                              <Price
                                                                  startP={sousTotal[sousTotalVal].value}
                                                                  newP={sousTotal[sousTotalVal].discountPrice}
                                                                  quantity={1}
                                                                  isSale={true}
                                                                  discount={''}
                                                                  currency={currency}
                                                              />
                                                          </>
                                                      ) : (
                                                          ''
                                                      )}
                                                  </div>
                                              </div>
                                              <div className="summary-item-trash">
                                                  {!basket.ticket || !basket.ticket.ticketNumber  ?
                                                      <Icon
                                                          name="trash alternate"
                                                          onClick={() => this.handleRemoveItem(item.parentID, item.productId)}
                                                      /> : ""
                                                  }
                                              </div>
                                          </div>
                                      );
                                    }
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    basket: getBasket(state),
    accountResource: getAccountResource(state),
    gifts: getBasketGifts(state)
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators(AddToCartActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBasket);
