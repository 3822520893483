import {createReducer} from 'reduxsauce';
import {MenuTypes} from './actions';
import {clientCounter} from "../../../../helpers/helpers";

export interface IMenuState {
    menu: any,
    searching: any,
    error: string,
    language: string,
    activeMenuKey: string,
    breadcrumb: any,
    clientCounter:number,
    notificationCounters: object,
    isDisconnected: boolean,
    tssDetails: object,
    tssError: string,
    clockDate: boolean
}

export const menuR: IMenuState = {
    menu: [],
    searching: {},
    error: "",
    language: "fr",
    activeMenuKey: "",
    breadcrumb: {},
    clientCounter: 0,
    notificationCounters:{},
    isDisconnected: false,
    tssDetails: {},
    tssError: "",
    clockDate: false
};

export const menu = (state) => {
    return {...state};
};

export const menuSuccess = (state, action) => {
    return {...state, menu: action.data};
};

export const menuFailure = (state, action) => {
    return {...state, error: action.error, menu: {}};
};

export const searching = (state) => {
    return {...state };
};


export const language = (state, action) => {
    localStorage.setItem("language", action.lang);
    return {...state, language: action.lang};
};

export const activeMenu = (state, action) => {
    return {...state, activeMenuKey: action.key};
}

export const setBreadcrumb = (state, action) => {
    return {...state, breadcrumb: action.data}
}
export const getClientCount = (state, action) => {
    return {...state, clientCounter: clientCounter(action.count)};
}

export const notificationCountersSuccess = (state, action) => {
    return {...state, notificationCounters : action.data};
};

export const notificationCountersFailure = (state, action) => {
    return {...state, error: action.error};
};

export const networkDetector = (state, action) => {
    return {...state, isDisconnected: action.status};
};

export const fiscalTssDetailsSuccess = (state, action) => {
    return {...state, tssDetails: action.data}
};

export const fiscalTssDetailsFailure = (state, action) => {
    return {...state, tssError: action.error}
};

export const changeClockStart = (state, action) => {
    return {...state, clockDate: action.data}
}

export const handlers = {
    [MenuTypes.MENU_START]: menu,
    [MenuTypes.MENU_SUCCESS]: menuSuccess,
    [MenuTypes.MENU_FAILURE]: menuFailure,
    [MenuTypes.SEARCHING_START]: searching,
    [MenuTypes.LANGUAGE_START]: language,
    [MenuTypes.ACTIVE_MENU_START]: activeMenu,
    [MenuTypes.SET_BREADCRUMB_START]: setBreadcrumb,
    [MenuTypes.CLIENT_COUNTER_START]: getClientCount,
    [MenuTypes.NOTIFICATION_COUNTERS_SUCCESS]: notificationCountersSuccess,
    [MenuTypes.NOTIFICATION_COUNTERS_FAILURE]: notificationCountersFailure,
    [MenuTypes.NETWORK_DETECTOR_START]: networkDetector,
    [MenuTypes.FISCAL_TSS_DETAILS_SUCCESS]: fiscalTssDetailsSuccess,
    [MenuTypes.FISCAL_TSS_DETAILS_FAILURE]: fiscalTssDetailsFailure,
    [MenuTypes.CLOCK_START]: changeClockStart,
};

export default createReducer(menuR, handlers);
