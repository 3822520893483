import React, {Fragment} from "react"
import {MENU_TYPE} from "../../../../../../../utils/constants/variables";
import SubMenu from "./submenu";
import './mobile-menu.scss';
import {Flag, Icon} from 'semantic-ui-react'
import i18next from "i18next";
import Language from "../../../language";

interface Props {
    menu: any,
    activeMenuItem: number,
    handleActive: any,
    handleToggleMenu: any
}

export default class MenuMobile extends React.Component<Props> {

    handleActive = (index) => {
        this.props.handleActive(index, true)
    };
    render () {
        const {menu, activeMenuItem} = this.props;
        return (
            <>
                <div className="mobile-menu dflex align-items-start">
                    <div className="mobile-menu-content">
                        <div className="mobile-menu-body">

                                { activeMenuItem === -1 &&
                                <div className="mobile-menu-item">
                                    <ul className="" >
                                        { menu.length &&
                                        menu.map((item, i) => (
                                            item.menuType === MENU_TYPE && <li key={item.id} className={i === activeMenuItem ? 'active dflex justify-content-between align-items-center' : 'dflex justify-content-between align-items-center'} onClick={this.handleActive.bind(this, i)}>{item.label} <Icon name="angle right"></Icon></li>
                                        ))
                                        }
                                    </ul>
                                </div>
                                }



                                { activeMenuItem !== -1 &&
                                <div className="mobile-menu-item-content">
                                    <SubMenu menuItem={menu[activeMenuItem]} handleToggleMenu={this.props.handleToggleMenu} handleClick={this.handleActive}/>
                                </div>
                                }



                        </div>
                    </div>
                    <div className="mobile-menu-close" onClick={this.props.handleToggleMenu}>
                        <span></span>
                        <span></span>
                    </div>

                </div>
            </>

        );
    }
}