import {ProductTypes, ProductActionCreators} from "./actions";
import {takeLatest, put, call, all, select} from "redux-saga/effects";
import axios from 'axios';
import { PRODUCTS_CATALOGUE_URL, PRODUCT_CANONICAL_URL, PRODUCT_CARE_URL, PRODUCT_COMPOSITION_URL} from "../../../utils/constants/service";
import {getApiPrefix, getLocalFormData} from "../../../helpers/helpers"
import {BRAND_ID, DEFAULT_CURRENCY, COUNTRIES, LOCAL_STORAGE} from "../../../utils/constants/variables";
import {getAccountResource} from "../../login/redux/selectors";
import { getProducts } from "../../products/redux/selectors";

export function* product(params) {
    try {
        const details = yield select(getAccountResource);
        const {storeDetails: {country, promotionCluster}} = details;
        const { catalogue } = yield select(getProducts) || {};
        let content = catalogue && catalogue.content || [];

        if (!content.length) {
            const { brandLanguages } = getLocalFormData();
            const language = brandLanguages.find((lan) => lan.brandId === details.brandApp) || {};
            const selectedItem = JSON.parse(localStorage[LOCAL_STORAGE.selectedItem]);

            const data = yield call(() => axios.post(`${PRODUCTS_CATALOGUE_URL}?page=0&sort=ranking,asc&size=20`,{}, {
                headers: {
                    ...(selectedItem.catalog && {catalog: selectedItem.catalog}),
                    ...(selectedItem.productType && {productType: selectedItem.productType}),
                    ...(selectedItem.productModelId && {productModelId: selectedItem.productModelId}),
                    ...(selectedItem.productCategoryId && {productCategoryId: selectedItem.productCategoryId}),
                    currency: DEFAULT_CURRENCY,
                    language: language.altLanguage || getApiPrefix(),
                    country: country,
                    promoCluster: promotionCluster
                }
            }).then(res => res.data))
            content = data && data.catalogue && data.catalogue.content || [];
        }

        const selectedPoroduct = content.find((item) => item.productId === params.id || item.canonicalUrl === params.id) || {};

        let headers = {
            headers: {
                brand: selectedPoroduct.brandId || BRAND_ID,
                currency: DEFAULT_CURRENCY,
                language: selectedPoroduct.language || getApiPrefix(),
                country: country,
                promoCluster: promotionCluster,
            }
        };

        const fetcher = () => {
            return axios.get(`${PRODUCT_CANONICAL_URL}${params.id}`, headers)
                .then(res => res.data )
        }

        let product = yield call(fetcher);

        if(BRAND_ID === 5 && (!product || !product.productId)){

            if(COUNTRIES[1].includes(country)){
                headers = {...headers, headers: {...headers.headers, country: "IT", language: getApiPrefix("IT")}}
                product = yield call(fetcher);
                if(!product || !product.productId){
                    headers = {...headers, headers: {...headers.headers, country: "FR", language: getApiPrefix("FR")}}
                    product = yield call(fetcher);
                }
            }else {
                if(COUNTRIES[0].includes(country)) {
                    headers = {...headers, headers: {...headers.headers, country: "IT", language: getApiPrefix("IT")}}
                }

                if(country === COUNTRIES[2]) {
                    headers = {...headers, headers: {...headers.headers, country: "FR", language: getApiPrefix("FR")}}
                }
                product = yield call(fetcher);
            }
        }

        if (product.compositeCanonicalUrlForSingle) {
            yield put(ProductActionCreators.productCompositeStart(product.compositeCanonicalUrlForSingle, headers));
        }

        yield put(ProductActionCreators.productSuccess(product));

    } catch (e) {
        yield put(ProductActionCreators.productFailure(e.message));
    }
}

export function* productComposite(params) {
    try {
        const details = yield select(getAccountResource);
        const headers = params.headers || {
            headers: {
                brand: BRAND_ID,
                currency: DEFAULT_CURRENCY,
                language: getApiPrefix(),
                country: details.storeDetails.country,
                promoCluster: details.storeDetails.promotionCluster,
            }
        };

        const data = yield call(() => {
            return axios.get(`${PRODUCT_CANONICAL_URL}${params.id}`, headers)
                .then(res => res.data )
        });
        yield put(ProductActionCreators.productCompositeSuccess(data));

    } catch (e) {
        yield put(ProductActionCreators.productCompositeFailure(e.message));
    }
}

export function* care(params) {
    try {
        const details = yield select(getAccountResource);
        const headers = {
            headers: {
                brand: BRAND_ID,
                currency: DEFAULT_CURRENCY,
                language: getApiPrefix(),
                country:details.storeDetails.country,
                storeType:details.storeDetails.storeType
            }
        };
        const care = yield call(() => {
            return axios.get(`${PRODUCT_CARE_URL}${params.productId}`, headers)
                .then(res =>  res.data)
        });
        yield put(ProductActionCreators.careSuccess(care));
    } catch (e) {
        yield put(ProductActionCreators.careFailure(e.message));
    }
}

export function* composition(params) {
    try {
        const details = yield select(getAccountResource);
        const headers = {
            headers: {
                brand: BRAND_ID,
                currency: DEFAULT_CURRENCY,
                language: getApiPrefix(),
                country:details.storeDetails.country,
                storeType:details.storeDetails.storeType
            }
        };
            const data = yield call(() => {
                return axios.get(`${PRODUCT_COMPOSITION_URL}${params.productId}`, headers)
                    .then(async(res) => {
                        return res.data;
                    })
            });

        yield put(ProductActionCreators.compositionSuccess(data));
    } catch (e) {
        yield put(ProductActionCreators.compositionFailure(e.message));
    }
}

export function* productSaga() {
    yield takeLatest(ProductTypes.PRODUCT_START, product);
    yield takeLatest(ProductTypes.PRODUCT_COMPOSITE_START, productComposite);
}

export function* productCareSaga() {
    yield takeLatest(ProductTypes.CARE_START, care);
}

export function* productCompositionSaga() {
    yield takeLatest(ProductTypes.COMPOSITION_START, composition);
}

export default productSaga;
