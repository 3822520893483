import { createReducer } from 'reduxsauce';
import { AdminTypes } from './actions';

export interface IAdminState {
    isLoading: boolean;
    associate: any;
    isGetAssociateServiceLoading: boolean;
    associateServiceData: any;
    isCreateAssociateServiceLoading: any
}

export const adminR: IAdminState = {
    isLoading: false,
    associate: null,
    isGetAssociateServiceLoading: false,
    associateServiceData: null,
    isCreateAssociateServiceLoading: false
};

export const associateStart = (state) => {
    return { ...state, isLoading: true }
};

export const associateSuccess = (state, action) => {
    return { ...state, associate: action.data, isLoading: false }
};

export const associateFailure = (state) => {
    return { ...state, associate: null, isLoading: false }
};

export const getAssociateServiceStart = (state) => {
    return { ...state, isGetAssociateServiceLoading: true }
};

export const getAssociateServiceSuccess = (state, action) => {
    return { ...state, associateServiceData: action.data, isGetAssociateServiceLoading: false }
};

export const getAssociateServiceFailure = (state) => {
    return { ...state, associateServiceData: null, isGetAssociateServiceLoading: false }
};

export const createAssociateServiceStart = (state) => {
    return { ...state, isCreateAssociateServiceLoading: true }
}

export const createAssociateServiceSuccess = (state) => {
    return { ...state, isCreateAssociateServiceLoading: false }
}

export const createAssociateServiceFailure = (state) => {
    return { ...state, isCreateAssociateServiceLoading: false }
}

export const handlers = {
    [AdminTypes.CREATE_ASSOCIATE_START]: associateStart,
    [AdminTypes.CREATE_ASSOCIATE_SUCCESS]: associateSuccess,
    [AdminTypes.CREATE_ASSOCIATE_FAILURE]: associateFailure,
    [AdminTypes.GET_ASSOCIATE_CALENDAR_SERVICE_START]: getAssociateServiceStart,
    [AdminTypes.GET_ASSOCIATE_CALENDAR_SERVICE_SUCCESS]: getAssociateServiceSuccess,
    [AdminTypes.GET_ASSOCIATE_CALENDAR_SERVICE_FAILURE]: getAssociateServiceFailure,
    [AdminTypes.CREATE_ASSOCIATE_SERVICE_START]: createAssociateServiceStart,
    [AdminTypes.CREATE_ASSOCIATE_SERVICE_SUCCESS]: createAssociateServiceSuccess,
    [AdminTypes.CREATE_ASSOCIATE_SERVICE_FAILURE]: createAssociateServiceFailure
};

export default createReducer(adminR, handlers);
