import React, { useState, useEffect } from 'react';
import { Dropdown, Input, Table, TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import i18next from 'i18next';

import { getDateTimestamp, getLocalFormData, getUserFromToken } from '../../../../helpers/helpers';
import { Address, ShipmentOrder, ShippingParcel, ShippingRequest, ShippmentParams } from '../../../../shared/models/shipModel';
import { ORDER_STATUS_CODE, TRANSFER_STATUS_CODE } from '../../../../utils/constants/variables';
import { getLivraisonClientData } from '../../redux/selectors';
import { livraisonClientActionCreators } from '../../redux/actions';
import { DatatableActionCreators } from '../../../datatable/redux/actions';
import { bindActionCreators } from 'redux';
import { getAccountResource } from '../../../login/redux/selectors';
import { updateOrderLoading } from '../../../datatable/redux/selectors';
import CustomBtn from '../../../common/customBtn';
import trackIcon from "../../../../assets/img/track.png"
import pdfIcon from "../../../../assets/img/pdf.png"
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import PopUp from './../../../popUp/popUp';
import BookSidePanel from '../bookPickupPanel'
import { TransfersActionCreators } from '../../../transfers/redux/actions';
import { getBookLoading, getShipmentLoading, getTransferBookResult, getTransferShipmentError } from '../../../transfers/redux/selectors';

interface Props {
    isLoading: boolean;
    isBookLoading: boolean;
    livraisonClientSingleData: any;
    details: any;
    bookResult: any;
    transferBookResult: any;
    isShipmentLoading: boolean;
    isTransferBookLoading: boolean;
    shipmentError: boolean;
    closePopup?: any;
    itemData?: any;
    type?: string;
    livraisonClientShippingStart?: (data) => {};
    updateOrderStatusStart?: (data, status, generateOrderFile, stroreTransferBody) => {};
    livraisonClientBookStart?: (data) => {};
    createStoreTransferShipmentStart?: (data) => {};
    createStoreTransferBookStart?: (data) => {};
    livraisonClientBookFailure?: (data) => {};
    emptyTransferMessage?: (data) => {};
    emptyTransferShipmentErrorStart?: () => {};
    annulerErrorSuccess?:() => {};
    error: any;
    orderLoading: boolean;
}

function CarrierTab({
    itemData,
    isLoading,
    orderLoading,
    isBookLoading,
    livraisonClientSingleData,
    details,
    isShipmentLoading,
    isTransferBookLoading,
    bookResult,
    transferBookResult,
    shipmentError,
    closePopup,
    type = "",
    livraisonClientShippingStart,
    updateOrderStatusStart,
    livraisonClientBookStart,
    createStoreTransferShipmentStart,
    createStoreTransferBookStart,
    emptyTransferMessage,
    livraisonClientBookFailure,
    emptyTransferShipmentErrorStart,
    annulerErrorSuccess,
    error
}: Props) {
    const { carriers = []} = getLocalFormData();
    const defaultCarrier = carriers.find((carrier) => carrier.defaultCarrier) || {};

    const [showLoginConfirme, setShowLoginConfirme] = useState<boolean>(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
    const [showBookPanel, setShowBookPanel] = useState<boolean>(false);
    const [showClosePopup, setShowClosePopup] = useState<boolean>(false);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const [selectedShipment, setSelectedShipment] = useState<any>({});
    const [parcels, setParcels] = useState<ShippingParcel[]>([]);
    const [formData, setFormData] = useState({
        selectedTransport: defaultCarrier.id,
        selectedService: defaultCarrier.service,
        shippedQuantity: 1,
        comment: '',
    })

    const getParcelNameByKey = (key: string) => {
        switch(key) {
            case 'length':
                return i18next.t('Carrier.length');
            case 'width':
                return i18next.t('Carrier.width');
            case 'height':
                return i18next.t('Carrier.height');
            case 'weight':
                return i18next.t('Carrier.weight');
        }
    }

    const getCarrierById = (id: number | null = null) => {
        return carriers.find((carrier) => id ? carrier.id === id : carrier.defaultCarrier) || {};
    }

    const getQuantityOptions = () => {
        return Array(4).fill(1).map((item, index) => ({
            key: index,
            value: index + 1,
            text: index + 1,
        }))
    }

    const getParcels = (length) => {
        return Array(length).fill(1).map(() => new ShippingParcel());
    }

    const openTracking = (shipment) => {
        const { trackingUrl } = getCarrierById(shipment.carrierId);
        const url = trackingUrl + shipment.packageNumber;
        window.open(url, '_blank');
    }

    const openPdf = (url: string) => {
        if(url && url.trim()) {
            window.open(url, '_blank');
        }
    }

    const isOrderStatusShi = () => {
        return Boolean(
            itemData && itemData.orderStatus && itemData.orderStatus.code === ORDER_STATUS_CODE.SHI
        ) || itemData.isButtonsDisabled
    }

    const openPickUp = (shipment) => {
        if(!isOrderStatusShi()) {
            const carrier = getCarrierById(shipment.carrierId);
            if(!carrier.pickup) {
                window.open(carrier.pickupUrl, '_blank');
            } else {
                setSelectedShipment(shipment);
                setShowBookPanel(true);
            }
        }
    }

    const getAddresses = (addressType: string) => {
        const { shippingAddress } = livraisonClientSingleData;
        const { storeDetails } = details;
        const { storeSend, storeRecv } = itemData;

        switch(addressType) {
            case "from":
                return Address.createAddressData(
                   type === "transfers" ? storeSend : storeDetails
                );
            case "to":
                return Address.createAddressData(
                    type === "transfers" ? storeRecv : shippingAddress
                );
            default:
                return new Address()
        }
    }

    const handleShipmentAction = () => {
        setShowLoginConfirme(false);
        const {
            selectedTransport,
            selectedService,
            comment,
            shippedQuantity
        } = formData;
        const tansport = getCarrierById(+selectedTransport);
        const toAddress = getAddresses("to");
        const fromAddress = getAddresses("from");
        const params = new ShippmentParams(
            toAddress,
            fromAddress,
            parcels,
            `${itemData.totalSales} ${itemData.currency}`,
            `${itemData.orderId || itemData.storeTransferId}`,
            itemData.orderLines && itemData.orderLines.length && itemData.orderLines[0].productNameLevel1 || itemData.productName || "",
            0,
            itemData.currency,
            0,
            itemData.currency,
            0,
            tansport.name,
            selectedService,
            +tansport.identifier,
            "",
            "",
            "DAP",
            "",
            "",
            comment,
            false
        );
        const shipmentData = new ShippingRequest("Ship", params);
        const orderBody = new ShipmentOrder(
            "",
            tansport.id,
            comment,
            "",
            itemData.orderId || itemData.storeTransferId,
            "",
            0,
            0,
            new Date().getTime(),
            +shippedQuantity,
            0,
            parcels[0].weight
        );
        if(type === "transfers") {
            orderBody.storeTransferCode = itemData.storeTransferCode;
            createStoreTransferShipmentStart
                && createStoreTransferShipmentStart({ shipmentData, orderBody, updateStatus: true });
        } else {
            livraisonClientShippingStart
                && livraisonClientShippingStart({ shipmentData, orderBody });
        }
    }

    const startRequest = () => {
        updateOrderStatusStart && updateOrderStatusStart(
            itemData.orderId,
            ORDER_STATUS_CODE.SHI,
            undefined,
            transferBody(),
        )
        setShowConfirmPopup(false);
    }

    const onChange = ({ name, value }: any) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const changeParcelValue = ({ target }, index: number) => {
        const { name = '', value = '' } = target;
        if(target && target.value && target.value[0] === "0"){
            target.value = "";
        }
        const data = [...parcels];
        data[index][name] = +value;
        setParcels(data);
    }

    const handleChangeQuantity = (data) => {
        onChange(data);
        setParcels(getParcels(data.value));
    }

    const closePopupError = () => {
        emptyTransferShipmentErrorStart
            && emptyTransferShipmentErrorStart()

        setShowErrorPopup(false)
        annulerErrorSuccess && annulerErrorSuccess();
    }

    useEffect(() => {
        let unmounted = false;
        if(!unmounted) {
            setParcels(getParcels(1))
        }
        return () => {
            unmounted = true;
        }
    }, [])

    useEffect(() => {

        let unmounted = false;
        if(!unmounted) {
            setShowErrorPopup(shipmentError);
        }

        if(error){
            setShowErrorPopup(true)
        }

        return () => {
            unmounted = true;
        }
    }, [shipmentError, error])

    const transferBody = () => {
        const user =  getUserFromToken();
        const productQty = itemData.totalQuantity
        const storeCodeSend = details.storeCode
        const storeTransferProducts = itemData.orderLines.reduce((acc, item) => {
            if(item.barcode){
                const obj = {
                    barcode: item.barcode,
                    qtyRecv: item.orderQty,
                    qtySend: item.orderQty,
                    quantity: item.orderQty,
                }
                acc.push(obj)
            }
            return acc
        }, [])

        return {
            associateCodeRecv: user.code,
            associateCodeSend: user.code,
            commentRecv: `${itemData.orderId}`,
            commentSend: `${itemData.orderId}`,
            deleted: false,
            productQty,
            storeCodeSend,
            storeTransferProducts,
            storeTransferReasonCode: "WEB",
            storeTransferStatusCode: TRANSFER_STATUS_CODE.SHI,
            totalBox: 1
        }
    }
    return (
        <div className="">
            <div className="popUp--body--card--body set-data-content">
                <div className="content">
                    <div className="popUp--body--card--body--data">
                        <div className='popUp--body--card--body--data w-50 mr-1'>
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.dashboards.transporteur',
                                )}
                            </div>
                            <div className="popUp--body--card--body--data--description">
                                <Dropdown
                                    name="selectedTransport"
                                    className="w-100 carrier-dropdown bold-text"
                                    compact
                                    selection
                                    closeOnChange
                                    options={carriers.map((carrier) => ({
                                        key: carrier.id,
                                        value: carrier.id,
                                        text: carrier.name
                                    }))}
                                    value={formData.selectedTransport}
                                    onChange={(event, data) => onChange({
                                        name: data.name,
                                        value: data.value
                                    })}
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data w-50">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.dashboards.service',
                                )}
                            </div>
                            <div className="popUp--body--card--body--data--description">
                                <Dropdown
                                    name="selectedService"
                                    className="w-100 carrier-dropdown bold-text carrier-text-area"
                                    compact
                                    selection
                                    closeOnChange
                                    options={carriers.map((carrier) => ({
                                        key: carrier.id,
                                        value: carrier.service,
                                        text: carrier.service
                                    }))}
                                    value={formData.selectedService}
                                    onChange={(event, data) => onChange({
                                        name: data.name,
                                        value: data.value
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t(
                                'Terminal.dashboards.shippedQuantity',
                            )}
                        </div>
                        {getCarrierById(+formData.selectedTransport).multiparcel ?
                            <div className="popUp--body--card--body--data--description">
                                <Dropdown
                                    name="shippedQuantity"
                                    className="w-100 carrier-dropdown bold-text carrier-text-area"
                                    compact
                                    selection
                                    closeOnChange
                                    options={getQuantityOptions()}
                                    value={+formData.shippedQuantity}
                                    onChange={(event, data) => handleChangeQuantity(data)}
                                />
                            </div> : <Input
                                disabled={false}
                                className='popUp--body--card--body--data--description color_black'
                                name="shippedQuantity"
                                type="number"
                                min="0"
                                value={formData.shippedQuantity}
                                onChange={(event) => onChange(event.target)}
                            />
                        }
                    </div>
                    <div className="popUp--body--card--body--data flex-col">
                        {parcels.map((parcel, index) => (
                            <div key={index} className="popUp--body--card--body--data--description d-flex">
                                {Object.entries(parcel).map(([key, value], entrieIndex) => (
                                    <div key={entrieIndex} className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {getParcelNameByKey(key)}
                                        </div>
                                        <Input
                                            key={index}
                                            name={key}
                                            error={!formData.shippedQuantity}
                                            type="number"
                                            disabled={false}
                                            className="popUp--body--card--body--data--description mr-1 color_black"
                                            value={value}
                                            placeholder={'test'}
                                            onChange={(event) => changeParcelValue(event, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t(
                                'Terminal.dashboards.comments',
                            )}
                        </div>
                        <TextArea
                            className="popUp--body--card--body--data--description bold-text"
                            value={formData.comment}
                            name="comment"
                            onChange={(event) => onChange(event.target)}
                        />
                    </div>
                </div>
            </div>
            <div className='popUp--body--card--body--data--description d-flex'>
                <CustomBtn
                    classN={`btn_1 w-50 blue_btn mt-2`}
                    handleClick={() => setShowLoginConfirme(true)}
                    loading={isLoading || isShipmentLoading}
                    disabled={isOrderStatusShi()}
                >
                    {i18next.t('Terminal.login.5')}
                </CustomBtn>
                {type !== "transfers" ?
                    <CustomBtn
                        classN={`btn_1 w-50 green_btn mt-2`}
                        handleClick={() => setShowConfirmPopup(true)}
                        loading={orderLoading}
                        disabled={isOrderStatusShi()}
                    >
                        {i18next.t('buttons.3')}
                    </CustomBtn> : <CustomBtn
                        classN="btn_1 w-50 mt-2"
                        handleClick={() => setShowClosePopup(true)}
                        disabled={false}
                    >
                        {i18next.t('buttons.40')}
                    </CustomBtn>
                }
            </div>
            <Table celled selectable className="productInfoBlock--infoBox--table">
                <Table.Header>
                    <Table.Row>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.date')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.service')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.packageNumber')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.confirmationNumber')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.shippedQuantity')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.collectionNumber')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.order')}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t('Terminal.dashboards.label')}
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {itemData && itemData.orderShipments && itemData.orderShipments.map((shipment, index) => (
                        <Table.Row key={index}>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {getDateTimestamp(shipment.shipmentDate)}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {shipment.carrierService}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                <span
                                    className='cursor main-link'
                                    onClick={() => openTracking(shipment)}
                                >
                                    {shipment.packageNumber}
                                </span>
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {shipment.shippingConfirmation}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {shipment.shippedQuantity}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {shipment.pickupConfirmation}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                <img
                                    src={trackIcon}
                                    className={`${isOrderStatusShi() ? '' : 'cursor'} carrier-icon`}
                                    onClick={() => openPickUp(shipment)}
                                    alt="truck icon"
                                />
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                <img
                                    src={pdfIcon}
                                    className='cursor carrier-icon'
                                    onClick={() => openPdf(shipment.labelUrl)}
                                    alt="pdf icon"
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {showLoginConfirme && (
                <ConfirmPopup
                    handleCancel={() => setShowLoginConfirme(false)}
                    handleConfirm={handleShipmentAction}
                    text={i18next.t('Terminal.modal.8')}
                />
            )}
            {showConfirmPopup && (
                <ConfirmPopup
                    handleCancel={() => setShowConfirmPopup(false)}
                    handleConfirm={startRequest}
                    text={i18next.t('Terminal.confirm.7', {
                        orderId: itemData.orderId,
                    })}
                    title={i18next.t('buttons.3')}
                />
            )}
            {showClosePopup && (
                <ConfirmPopup
                    handleCancel={() => setShowClosePopup(false)}
                    handleConfirm={closePopup}
                    text={i18next.t('Terminal.confirm.23', {
                        orderId: itemData.orderId,
                    })}
                    title={i18next.t('buttons.3')}
                />
            )}
            {showErrorPopup &&
                <ConfirmPopup
                    classN='message-error'
                    handleCancel={closePopupError}
                    handleConfirm={closePopupError}
                    text={i18next.t('Terminal.transferBasket.7')}
                    title={i18next.t('Terminal.modal.title')}
                />
            }
            {showBookPanel &&
                <PopUp classN={type === "transfers" ? "book-panel" : ''} handleClose={() => setShowBookPanel(false)}>
                    <BookSidePanel
                        fromAddress={getAddresses("from")}
                        toAddress={getAddresses("to")}
                        selectedShipment={selectedShipment}
                        selectedCarrier={getCarrierById(selectedShipment.carrierId)}
                        isBookLoading={type !== "transfers" ? isBookLoading : isTransferBookLoading}
                        createBook={
                            type !== "transfers" ? livraisonClientBookStart : createStoreTransferBookStart
                        }
                        bookResult={{
                            ...bookResult,
                            ...transferBookResult
                        }}
                        emptyMessage={
                            type === "transfers"
                                ? emptyTransferMessage
                                : livraisonClientBookFailure

                        }
                    />
                </PopUp>
            }
        </div>
    )
}

const mapStateToProps = (state: any): Props => {
    const { isLoading, isBookLoading, livraisonClientSingleData, bookResult, shi, error } =
        getLivraisonClientData(state);
    return {
        transferBookResult: getTransferBookResult(state),
        isTransferBookLoading: getBookLoading(state),
        bookResult,
        livraisonClientSingleData,
        isLoading,
        orderLoading: updateOrderLoading(state),
        isBookLoading,
        details: getAccountResource(state),
        isShipmentLoading: getShipmentLoading(state),
        shipmentError: getTransferShipmentError(state),
        error
    };
};

const mapDispatchToProps = (dispatch): typeof livraisonClientActionCreators => {
    return bindActionCreators({
        ...livraisonClientActionCreators,
        ...DatatableActionCreators,
        createStoreTransferShipmentStart: TransfersActionCreators.createStoreTransferShipmentStart,
        createStoreTransferBookStart: TransfersActionCreators.createStoreTransferBookStart,
        emptyTransferMessage: TransfersActionCreators.createStoreTransferBookFailure,
        emptyTransferShipmentErrorStart: TransfersActionCreators.emptyTransferShipmentErrorStart,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CarrierTab);