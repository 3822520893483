import {takeLatest, put, call, select, all} from "redux-saga/effects";
import {salesTypes, SalesActionCreators} from "./actions";
import { StoreGiftsActionCreators } from "../../bonsCadeaux/redux/actions";
import {fetchPaperReceipt, fetchSale, fetchSalesList, getPaperReceiptData } from "../../../utils/constants/service";
import { DEFAULT_CURRENCY, IS_FISCAL_PRINTER, RECEIPT_TYPE, TICKET_TYPE } from '../../../utils/constants/variables';
import { getFiscalTssDetails } from '../../../layouts/main/header/redux/selectors';
import { getAccountResource } from "../../login/redux/selectors";

export function* getSalesData(params) {
    const body = params.data;
    const accountResource = yield select(getAccountResource);

    const brendId = (
        accountResource &&
        accountResource.storeDetails &&
        accountResource.storeDetails.brandId &&
        accountResource.storeDetails.brandId
    );

    try {
        const data = yield call(() => fetchSalesList(body));
        yield put(SalesActionCreators.salesSuccess(data));
    } catch (e) {
        yield put(SalesActionCreators.salesFailure(e.message));
    }
}

export function* getSingleSalesData(params) {
    const { currency = DEFAULT_CURRENCY, id, storeCode } = params;

    try {
        const data = yield call(() =>fetchSale(id, currency, storeCode));
        yield put(SalesActionCreators.singleSalesSuccess(data));
    } catch (e) {
        yield put(SalesActionCreators.singleSalesFailure(e.message));
    }
};

export function* getPaperReceipt(params) {
    const {ticketNumber = "", receiptType, currency, isTicketDelete} = params.data;

    try {
        const data = yield call(() => fetchPaperReceipt(ticketNumber, receiptType, currency));
        const storeGiftCode = data.storeGift && data.storeGift.storeGiftCode
            || data.storeGifts && data.storeGifts.length && data.storeGifts[0].storeGiftCode

        if (storeGiftCode){
            yield put(SalesActionCreators.singleStoreGiftsStart({storeGiftCode: storeGiftCode, type:'sale'}));
        }
        data.isTicketDelete = isTicketDelete;
        yield put(SalesActionCreators.paperReceiptSuccess(data));
    } catch (e) {
        yield put(SalesActionCreators.paperReceiptFailure(e.message));
    }
};

export function* printTicket(params) {
    const {ticketNumber = "", receiptType, type, isPay, productsQty} = params.data;

    try {

        const data = yield call(() => getPaperReceiptData(ticketNumber, receiptType));

        yield put(SalesActionCreators.printTicketSuccess(data));

        if(data.storeSaleFiscal) {
            const { timeFormat, algorithm, publicKey } = yield select(getFiscalTssDetails)
            data.storeSaleFiscal = { ...data.storeSaleFiscal, timeFormat, algorithm, publicKey }
        }

        if(type === TICKET_TYPE.gift && productsQty && Array.isArray(data.storeSaleLineItemTicketList)) {
            data.storeSaleLineItemTicketList = data.storeSaleLineItemTicketList.filter(i=> 0 !== +productsQty[i.productId]).map(item => {
                if(+productsQty[item.productId]) {
                    item.orderQuantity = +productsQty[item.productId]
                }
                return item
            })
        }

        if(isPay){
            const giftData = data.storeGifts && Array.isArray(data.storeGifts) ? data.storeGifts : [];
            const actions: any = [];

            giftData.forEach(gift => {
                const { credit, storeGiftCode } = gift;
                if(storeGiftCode) {
                    const receiptType = credit ? RECEIPT_TYPE.credit : RECEIPT_TYPE.gift
                    actions.push(put(StoreGiftsActionCreators.printStoreGiftsStart({receiptType, storeGiftCode})))
                }
            });

            if(actions.length) {
                yield all(actions);
            }
        }

    } catch (e) {
        yield put(SalesActionCreators.printTicketFailure(e.message));
    }
}

export function* salesSaga() {
    yield takeLatest(salesTypes.SALES_START, getSalesData);
    yield takeLatest(salesTypes.SINGLE_SALES_START, getSingleSalesData);
    yield takeLatest(salesTypes.PAPER_RECEIPT_START, getPaperReceipt);
    yield takeLatest(salesTypes.PRINT_TICKET_START, printTicket);
}

export default salesSaga;
