import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Table } from 'semantic-ui-react';
import i18next from 'i18next';
import {
    getLoadingPaperReceipt,
    getPaperReceipt,
    getSales,
} from './../redux/selectors';
import { SalesActionCreators } from './../redux/actions';
import TableComponent from '../../datatable/container/datatable';
import PopUp from '../../popUp/popUp';
import UpdateFiscalTransaction from './compinents/updateFiscalTransaction';
import {
    getCurrency,
    getProductDiscount,
    getProductPriceAfterDiscount,
    replaceComma,
    getStoreGiftValue,
    getDateTimestampByTime,
    getDateTimestamp,
    fixNumber,
    addPaperReceiptProduct,
    destroyActiveBasket,
    redirectClientPage,
    isTaxesCountry,
    getTax,
    getOptionSelection,
} from '../../../helpers/helpers';
import {
    DEFAULT_CURRENCY,
    DEFAULT_CURRENCY_SYMBOL,
    RECEIPT_TYPE,
    setStoreData,
    STORE_COUNTRIES,
    STORE_COUNTRY,
} from '../../../utils/constants/variables';
import { getAccountResource } from '../../login/redux/selectors';
import { getMenu } from '../../../layouts/main/header/redux/selectors';
import CustomBtn from '../../common/customBtn';
import AutocompleteDropdown from '../../common/autocompleteDropdown';
import { getStoresForm } from '../../storeFormSidePanel/redux/selectors';
import { updatePaymentMethod } from '../../../utils/constants/service';
import { getIsTerminalOpen } from '../../fermeturePopUp/redux/selectors';
import AppointmentService from '../../Appointments/container/AppointmentService';
import { getSingleCalendar } from '../../Appointments/redux/selectors';
import { AppointmentsActionCreators } from '../../Appointments/redux/actions';
import AppointmentClient from '../../Appointments/container/AppointmentClient';

interface Props {
    salesData?: any;
    singleSalesData?: any;
    isLoading: boolean;
    details: object;
    menu: any;
    paymentMethods?: any[];
    isTerminalOpen: boolean;
    isLoadingPaperReceipt: boolean;
    paperReceipt: any;
    singleCalendarId: any;
}

interface State {
    openPopUp: boolean;
    changePaymentMethod: boolean;
    ticketNumber: string;
    oldPaymentCode: string;
    newPaymentCode: string;
    updatedStatus: string;
    updatedLoading: boolean;
    isCurrentDate: boolean;
    currency: string;
    storeCode: string | number;
}

class Sales extends Component<Props & typeof SalesActionCreators> {
    state: State = {
        openPopUp: false,
        changePaymentMethod: false,
        ticketNumber: '',
        oldPaymentCode: '',
        newPaymentCode: '',
        updatedStatus: '',
        updatedLoading: false,
        isCurrentDate: false,
        currency: DEFAULT_CURRENCY,
        storeCode: '',
    };

    getCsvData = () => {
        const headers = [
            {
                label: i18next.t('Terminal.dashboards.terminalCode'),
                key: 'terminalCode',
            },
            { label: i18next.t('Terminal.dashboards.date'), key: 'saleDate' },
            {
                label: i18next.t('Terminal.dashboards.ticketNumber'),
                key: 'ticketNumber',
            },
            {
                label: i18next.t('Terminal.dashboards.currency'),
                key: 'currency',
            },
            { label: i18next.t('Terminal.dashboards.total'), key: 'saleTotal' },
            { label: i18next.t('Terminal.dashboards.tax'), key: 'saleTax' },
            { label: i18next.t('Terminal.dashboards.due'), key: 'paymentDue' },
            {
                label: i18next.t('Terminal.dashboards.quantity'),
                key: 'saleQuantity',
            },
        ];

        const data = this.props.salesData.map((item) => ({
            terminalCode: this.props.details.terminalCode,
            saleDate: getDateTimestampByTime(item.saleDate),
            ticketNumber: item.ticketNumber,
            currency: DEFAULT_CURRENCY,
            saleTotal: item.saleTotal,
            saleTax: item.saleTax,
            paymentDue: item.paymentDue,
            saleQuantity: item.saleQuantity,
        }));

        return { headers, data, name: 'sales.csv' };
    };

    columns: any = [
        {
            name: i18next.t('Terminal.dashboards.date'),
            selector: 'saleDate',
            sortable: true,
            searchable: false,
            center: true,
            datapicker: true,
            width: '23%',
        },
        {
            name: i18next.t('Terminal.dashboards.ticketNumber'),
            selector: 'ticketNumber',
            sortable: true,
            searchable: true,
            center: true,
            isUnique: true,
            width: '19%',
        },

        {
            name: i18next.t('Terminal.dashboards.ordine'),
            selector: 'webOrderNumber',
            sortable: true,
            searchable: true,
            center: true,
            isUnique: true,
            width: '19%',
        },

        {
            name: i18next.t('Terminal.dashboards.quantity'),
            selector: 'saleQuantity',
            sortable: true,
            searchable: false,
            center: true,
            width: '5%',
        },
        {
            name: i18next.t('Terminal.dashboards.total'),
            selector: 'saleTotal',
            sortable: true,
            searchable: false,
            center: true,
            width: '20%',
            cell: (row) => <>{row.saleTotal || '0,00' + getCurrency()}</>,
        },
        {
            name: i18next.t('Terminal.dashboards.client'),
            selector: 'clientLastName',
            sortable: true,
            searchable: false,
            center: true,
            width: '14%',
        },
        // {
        //     name: i18next.t('Terminal.dashboards.invoiceNumber'),
        //     selector: 'invoiceNumber',
        //     notShow: false,
        //     searchable: false,
        //     width: '9%',
        //     center: true,
        //     sortable: true,
        //     cell: (row) => (
        //         <div
        //             className={`${
        //                 row.invoiceNumber ? 'color--green' : 'color--red'
        //             }`}
        //         >
        //             {row.invoiceNumber ? 'Y' : 'N'}
        //         </div>
        //     ),
        // },
        // {
        //     name: i18next.t('Terminal.dashboards.payment'),
        //     selector: 'paymentMethod',
        //     sortable: true,
        //     searchable: false,
        //     center: true,
        //     width: '13%',
        // },
        // {
        //     name: i18next.t('Terminal.Receipt.prePayment.Credit'),
        //     selector: 'paymentDue',
        //     searchable: false,
        //     width: '8%',
        //     center: true,
        //     sortable: true,
        //     cell: (row) => (
        //         <div
        //             className={`${
        //                 row.paymentDue ? 'color--green' : 'color--red'
        //             }`}
        //         >
        //             {row.paymentDue ? 'Y' : 'N'}
        //         </div>
        //     ),
        // },
        {
            selector: 'date',
            notShow: true,
        },
        {
            selector: 'currency',
            notShow: true,
        },
        {
            selector: 'storeClientId',
            notShow: true,
        },
        {
            selector: 'storeCode',
            notShow: true,
        },
        {
            selector: 'zeroValueReceipt',
            notShow: true,
        },
        {
            selector: 'webOrderNumber',
            notShow: true,
        }
    ];

    conditionalRowStyles: any[] = [
        {
            when: (row) =>
                row.saleDate &&
                row.saleDate.includes(getDateTimestamp(Date.now())),
            style: {
                color: 'blue',
            },
        },
    ];

    handleRedirectClients = () => {
        const { singleSalesData: { clientId = null } = {}, menu } = this.props;
        redirectClientPage(clientId, menu);
        this.handleClosePopUp();
    };

    itemDescription = (item: any = {}) => {
        const {
            singleSalesData: {
                storeGifts = [],
                zeroValueReceipt = undefined,
            } = {},
        } = this.props;
        if (item.barcode && item.barcode[0] === 'G') {
            const { credit = true } =
                storeGifts.find((el) => el.storeGiftCode === item.barcode) ||
                {};
            if (credit) {
                return i18next.t('Terminal.Receipt.Gift.Avoir');
            }
            return i18next.t('Terminal.Receipt.Gift.carteCadeau');
        }

        if (zeroValueReceipt === true) {
            return i18next.t('Terminal.dashboards.nullBeleg');
        }

        return item.productName;
    };

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.sale'),
            render: () => {
                const { singleSalesData } = this.props;
                const { ticketNumber } = this.state;

                return (
                    <Tab.Pane attached={false}>
                        {singleSalesData &&
                        Object.keys(singleSalesData).length ? (
                            <div>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table collaps"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.ticketId',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.ordine',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.operator',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.client',
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {ticketNumber}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {singleSalesData.webOrderNumber}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {
                                                    singleSalesData.associateFirstName
                                                }
                                            </Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description cursor"
                                                onClick={
                                                    this.handleRedirectClients
                                                }
                                            >
                                                {`${
                                                    singleSalesData.clientFirstName ||
                                                    ''
                                                } ${
                                                    singleSalesData.clientLastName ||
                                                    ''
                                                }`}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                {singleSalesData.quoteNumber && (
                                    <Table
                                        celled
                                        selectable
                                        className="productInfoBlock--infoBox--table collaps"
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t(
                                                        'Terminal.dashboards.quote',
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {
                                                        singleSalesData.quoteNumber
                                                    }
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                )}

                                {singleSalesData &&
                                (singleSalesData.storeGifts ||
                                    (singleSalesData.webDiscount &&
                                        Object.keys(
                                            singleSalesData.webDiscount,
                                        ))) ? (
                                    <Table
                                        celled
                                        selectable
                                        className="productInfoBlock--infoBox--table collaps"
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t(
                                                        'Terminal.Receipt.zReport.storeGiftAmountTotal',
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t(
                                                        'Terminal.dashboards.saleAmount',
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {singleSalesData.storeGifts &&
                                                singleSalesData.storeGifts.map(
                                                    (storeGift) => {
                                                        return (
                                                            <Table.Row
                                                                key={
                                                                    storeGift.storeGiftCode
                                                                }
                                                            >
                                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                    {
                                                                        storeGift.storeGiftCode
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                    {getStoreGiftValue(
                                                                        storeGift,
                                                                    )}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    },
                                                )}
                                            {singleSalesData.webDiscount &&
                                                Object.keys(
                                                    singleSalesData.webDiscount,
                                                ).length && (
                                                    <Table.Row>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {
                                                                singleSalesData
                                                                    .webDiscount
                                                                    .discountCode
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {singleSalesData
                                                                .webDiscount
                                                                .couponType ===
                                                            'PERCENT'
                                                                ? `${fixNumber(
                                                                      singleSalesData
                                                                          .webDiscount
                                                                          .value,
                                                                  )} %`
                                                                : `${replaceComma(
                                                                      singleSalesData
                                                                          .webDiscount
                                                                          .value,
                                                                  )} ${getCurrency(
                                                                      singleSalesData
                                                                          .webDiscount
                                                                          .currency,
                                                                  )}`}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    ''
                                )}

                                {singleSalesData.transactionNumber &&
                                singleSalesData.referenceTicket ? (
                                    <div>
                                        <h2>
                                            {i18next.t(
                                                'Terminal.dashboards.sale',
                                            )}
                                        </h2>
                                        <Table
                                            celled
                                            selectable
                                            className="productInfoBlock--infoBox--table collaps"
                                        >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.dashboards.fiscalReceipt',
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.Receipt.Sale.referenceTicket',
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {
                                                            singleSalesData.transactionNumber
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {
                                                            singleSalesData.referenceTicket
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {singleSalesData.storeSaleLineItems &&
                                singleSalesData.storeSaleLineItems.length ? (
                                    <div>
                                        <h2>
                                            {i18next.t(
                                                'Terminal.dashboards.sale',
                                            )}
                                        </h2>
                                        <Table
                                            celled
                                            selectable
                                            className="productInfoBlock--infoBox--table collaps"
                                        >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.dashboards.Name',
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.dashboards.Size',
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {singleSalesData
                                                            .storeSaleLineItems[0]
                                                            .outletPrice
                                                            ? `${i18next.t(
                                                                  'Terminal.dashboards.outletPrice',
                                                              )} ${DEFAULT_CURRENCY_SYMBOL}`
                                                            : i18next.t(
                                                                  'Terminal.dashboards.prix',
                                                                  {
                                                                      DEFAULT_CURRENCY_SYMBOL,
                                                                  },
                                                              )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {singleSalesData
                                                            .storeSaleLineItems[0]
                                                            .priceChange
                                                            ? i18next.t(
                                                                  'Terminal.dashboards.augmentation',
                                                              )
                                                            : i18next.t(
                                                                  'Terminal.dashboards.reduction',
                                                              )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.Receipt.Sale.listPriceAfterDiscount',
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.dashboards.unit',
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                        {i18next.t(
                                                            'Terminal.dashboards.quantity',
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {singleSalesData.storeSaleLineItems.map(
                                                    (item, index) => (
                                                        <Table.Row key={index}>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {this.itemDescription(
                                                                    item,
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {item.size}
                                                            </Table.Cell>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {item.outletPrice
                                                                    ? replaceComma(
                                                                          item.outletPrice,
                                                                      ) +
                                                                      getCurrency(
                                                                          item.currency,
                                                                      )
                                                                    : replaceComma(
                                                                          item.listPrice ||
                                                                              item.listPriceAfterDiscount,
                                                                      ) +
                                                                      getCurrency(
                                                                          item.currency,
                                                                      )}
                                                            </Table.Cell>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {getProductDiscount(
                                                                    item,
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {item.totalPrice
                                                                    ? replaceComma(
                                                                          item.totalPrice,
                                                                      ) +
                                                                      getCurrency(
                                                                          item.currency,
                                                                      )
                                                                    : item.quantity <
                                                                          0 &&
                                                                      !item.giftCard
                                                                    ? replaceComma(
                                                                          -(
                                                                              item.listPrice -
                                                                              item.specialDiscountDetail
                                                                          ),
                                                                      ) +
                                                                      getCurrency(
                                                                          item.currency,
                                                                      )
                                                                    : getProductPriceAfterDiscount(
                                                                          item,
                                                                      )}
                                                            </Table.Cell>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {item.unit}
                                                            </Table.Cell>
                                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                                {item.quantity}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ),
                                                )}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.optionTab'),
            render: () => {
                const { singleSalesData } = this.props;
                const { options = [] } = singleSalesData;
                // const { storeCode, ticketNumber, currency } = this.state;

                return (
                    <div>
                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table collaps"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.option',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'buttons.10',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.comment',
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {options && options.map((option, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title font-22">
                                            {option.option}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {getOptionSelection(option)}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {option.comment}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                )
            }
        },
        {
            menuItem: i18next.t('Terminal.dashboards.service'),
            render: () => {
                const { singleCalendarId, singleSalesData } = this.props;
                return (
                    <>
                        {singleCalendarId ? (
                            <AppointmentService
                                closePopup={() => {}}
                                selectedAppointment={{
                                    ...singleCalendarId,
                                    associateFirstName: singleSalesData.associateFirstName
                                }}
                            />
                        ) : null}
                    </>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.clientLastName'),
            render: () => {
                const { singleSalesData } = this.props;

                return (
                    <>
                        {singleSalesData ? (
                            <AppointmentClient
                                closePopup={() => {}}
                                selectedAppointment={{storeClientId: singleSalesData.clientId}}
                            />
                        ) : null}
                    </>
                );
            }
        },
    ];

    componentDidMount() {
        const { location = {}, history, details } = this.props;
        this.props.salesStart({});
        if (location.state && location.state.ticketNumber) {
            this.handleSaleDetails(location.state);
            history.replace({ ...history.location, state: undefined });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.updatedStatus !== this.state.updatedStatus) {
            if (this.state.updatedStatus === 'success') {
                this.setState({
                    updatedStatus: '',
                    changePaymentMethod: false,
                });
            }
        }
        if (
            this.state.openPopUp &&
            prevProps.isLoadingPaperReceipt &&
            !this.props.isLoadingPaperReceipt &&
            this.props.paperReceipt &&
            this.props.paperReceipt.ticketNumber
        ) {
            destroyActiveBasket();
            if (
                addPaperReceiptProduct(
                    this.props.paperReceipt,
                    undefined,
                    undefined,
                    this.props.paperReceipt.paymentAmount >= 0,
                    true
                )
            ) {
                const { currency, currencySymbol } = this.props.paperReceipt;
                if (DEFAULT_CURRENCY !== currency) {
                    setStoreData(currency, currencySymbol);
                    this.props.getCurrentDaySaleStart();
                }
                const paymentTermCode = this.props.singleSalesData
                    && this.props.singleSalesData.storeSalePaymentTicketList
                    && this.props.singleSalesData.storeSalePaymentTicketList[0];
                this.props.history.push({
                    pathname: '/',
                    state: { showPaymentSidePanel: true, paymentTermCode },
                });
            }
        }

        if (this.props.singleSalesData !== prevProps.singleSalesData) {
            const { webOrderNumber = '' } = this.props.singleSalesData || {};
            this.props.getServiceCalendarIdStart(webOrderNumber);
        }
    }

    isDeleteDisabled() {
        const { singleSalesData = {} } = this.props;
        return Boolean(
            singleSalesData
            && singleSalesData.storeSaleLineItems.find((item) => item.quantity < 0)
            || singleSalesData
            && singleSalesData.storeGifts
            && singleSalesData.storeGifts.length
        );
    }

    handleClosePopUp = () => {
        this.setState({ openPopUp: false });
    };

    handleSaleDetails = (data) => {
        const { ticketNumber, currency, saleDate, storeCode } = data;
        this.props.singleSalesStart(ticketNumber, currency, storeCode);
        this.setState({
            openPopUp: true,
            ticketNumber,
            isCurrentDate:
                saleDate && saleDate.includes(getDateTimestamp(Date.now())),
            currency,
            storeCode,
        });
    };
    handleOpenPaymentMethod = (currentMethod) => {
        this.props.storesFormStart();
        this.setState({
            changePaymentMethod: true,
            oldPaymentCode: currentMethod.paymentMethodCode,
            newPaymentCode: '',
        });
    };

    handleClosePaymentMethod = () => {
        this.setState({
            changePaymentMethod: false,
            oldPaymentCode: '',
            newPaymentCode: '',
            updatedStatus: '',
        });
    };

    handleChangePaymentMethod = () => {
        const {
            oldPaymentCode = '',
            newPaymentCode,
            ticketNumber,
            currency,
            storeCode,
        } = this.state;
        const newCode = !newPaymentCode ? oldPaymentCode : newPaymentCode;

        this.setState({ updatedLoading: true });

        updatePaymentMethod(ticketNumber, oldPaymentCode, newCode)
            .then((r) => {
                this.setState({
                    updatedStatus: 'success',
                    oldPaymentCode: newPaymentCode,
                    newPaymentCode: '',
                    updatedLoading: false,
                });
                this.props.singleSalesStart(ticketNumber, currency, storeCode);
            })
            .catch((e) => {
                this.setState({
                    updatedStatus: 'failed',
                    updatedLoading: false,
                });
            });
    };
    render() {
        const { salesData, isLoading, paymentMethods } = this.props;
        const {
            openPopUp,
            changePaymentMethod,
            oldPaymentCode,
            newPaymentCode,
            updatedStatus,
            updatedLoading,
        } = this.state;
        return (
            <div className="container">
                <div className="salesContent">
                    <TableComponent
                        tableData={salesData}
                        isLoading={isLoading}
                        openPopUp={this.handleSaleDetails}
                        columns={this.columns}
                        datepicker={true}
                        csvData={this.getCsvData()}
                        buttons={['salesButtons']}
                        reloadDashboard={() => this.props.salesStart({})}
                        keyField={'sales'}
                        conditionalRowStyles={this.conditionalRowStyles}
                    />
                </div>

                {openPopUp ? (
                    <PopUp
                        handleClose={this.handleClosePopUp}
                        classN="salesContent"
                    >
                        <div>
                            <Tab menu={{ pointing: true }} panes={this.panes} />
                        </div>
                    </PopUp>
                ) : (
                    ''
                )}
                {changePaymentMethod ? (
                    <PopUp
                        handleClose={this.handleClosePaymentMethod}
                        classN="changePaymentMethod"
                    >
                        <div>
                            <div className="parent-for-comment">
                                <div className="popUp--body--card--body set-data-content">
                                    <div className="content">
                                        <div className="popUp--body--card--body">
                                            <div className="popUp--body--card--body--data--header w-100 blueText">
                                                {i18next.t(
                                                    'Terminal.dashboards.paymentMethodCode',
                                                )}
                                            </div>
                                            <AutocompleteDropdown
                                                data={
                                                    paymentMethods &&
                                                    paymentMethods.filter(
                                                        (item) =>
                                                            item.paymentMethodType ===
                                                            'P',
                                                    )
                                                }
                                                search={true}
                                                value={
                                                    newPaymentCode ||
                                                    oldPaymentCode
                                                }
                                                classN="popUp--body--card--body--data--description w-100"
                                                keyName="name"
                                                textName={['name']}
                                                valueName="code"
                                                onChange={(val) =>
                                                    this.setState({
                                                        newPaymentCode: val,
                                                    })
                                                }
                                            />
                                            <CustomBtn
                                                classN={`btn_1 w-100 green_btn`}
                                                handleClick={() =>
                                                    this.handleChangePaymentMethod()
                                                }
                                                disabled={false}
                                                loading={updatedLoading}
                                            >
                                                {i18next.t(`buttons.33`)}
                                            </CustomBtn>

                                            {updatedStatus && (
                                                <div
                                                    className={
                                                        updatedStatus ===
                                                        'success'
                                                            ? 'successMessage'
                                                            : 'errorMessage'
                                                    }
                                                >
                                                    {updatedStatus === 'success'
                                                        ? i18next.t(
                                                              'alertMessage.2',
                                                          )
                                                        : i18next.t(
                                                              'alertMessage.1',
                                                          )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PopUp>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { salesData, singleSalesData, isLoading } = getSales(state);
    const { paymentMethods = [] } = getStoresForm(state);
    return {
        salesData,
        singleSalesData,
        isLoading,
        paymentMethods,
        details: getAccountResource(state),
        menu: getMenu(state),
        isTerminalOpen: getIsTerminalOpen(state),
        paperReceipt: getPaperReceipt(state),
        isLoadingPaperReceipt: getLoadingPaperReceipt(state),
        singleCalendarId: getSingleCalendar(state)
    };
};

const mapDispatchToProps = (dispatch): typeof SalesActionCreators => {
    return bindActionCreators({...SalesActionCreators, ...AppointmentsActionCreators}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
