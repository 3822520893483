import React, { Component } from 'react';
import i18next from 'i18next';
import { debounced200, getTableData } from '../../../../helpers/helpers';
import { ALPHABET } from '../../../../utils/constants/variables';

interface Props {
    tableData?: any;
    columns?: any;
    handleSearch?: any;
    handleResetSearch?: any;
    searchValue?: any;
    searchPosition?: string;
}
interface State {
    searchValue: string;
}

export default class TableSearch extends Component<Props> {
    state: State = {
        searchValue: '',
    };

    handleReset = () => {
        this.props.handleResetSearch();
        this.setState({ searchValue: '' });
    };

    handleChange = (e) => {
        const { value } = e.target;
        this.setState({ searchValue: value }, () =>
            debounced200(this.handleSearchItem),
        );
    };

    handleClick = (e) => {
        const { value } = e.target;
        this.setState(
            (prev: State) => ({ searchValue: prev.searchValue + value }),
            () => debounced200(this.handleSearchItem),
        );
    };

    handleSearchItem = () => {
        const { tableData, columns, handleSearch } = this.props;
        const { searchValue } = this.state;
        const searchable = columns.filter((item) => item.searchable);

        if (!searchable.length) return;

        const res = getTableData(tableData, columns).filter((item) => {
            let str = '';
            searchable.forEach((el) => {
                if (item[el.selector]) {
                    str += `${item[el.selector]} `;
                }
            });

            let isFind = true;
            searchValue.split(' ').forEach((elem) => {
                isFind =
                    str.toLowerCase().search(elem.toLowerCase()) > -1 && isFind;
            });
            return isFind;
        });

        handleSearch(res, searchValue);
    };

    render() {
        const { searchValue } = this.state;
        const { searchPosition } = this.props;
        return (
            <div className={`datatable--search ${searchPosition === 'bottom' ? 'flex-col align-end' : ''}`}>
                 {searchPosition === 'default' && (
                    <>
                        <div className="datatable--search--box">
                            <div className="ui icon input">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    onChange={this.handleChange}
                                    value={searchValue}
                                />
                                <i className="inverted circular search link icon iconSearch"></i>
                            </div>
                            <div className="datatable--search--box--btn">
                                <button
                                    className="btn_1 w-100"
                                    onClick={this.handleReset}
                                >
                                    {i18next.t('buttons.36')}
                                </button>
                            </div>
                        </div>
                        <div className="datatable--search--btnBox">
                            {ALPHABET.map((item) => (
                                <button
                                    key={item}
                                    className="btn_1"
                                    value={item}
                                    onClick={this.handleClick}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </>
                )}
                {searchPosition === 'bottom' && (
                    <div className="datatable--search--bottom">
                        <div className="ui icon input">
                            <input
                                type="text"
                                placeholder="Search..."
                                onChange={this.handleChange}
                                value={searchValue}
                            />
                            <i className="inverted circular search link icon iconSearch"></i>
                        </div>
                        <div className="datatable--search--bottom--btn">
                            <button
                                className="btn_1 w-100"
                                onClick={this.handleReset}
                            >
                                {i18next.t('buttons.36')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
