import React from "react"
import {Link} from "react-router-dom";
import {BASE_API_GATEWAY} from "../../../../../../../utils/constants/service";
import {Icon} from "semantic-ui-react";
import i18next from "i18next";
import {getLangPrefix} from "../../../../../../../helpers/helpers";

interface Props {
    menuItem: any,
    handleClick: any,
    handleToggleMenu: any
}

export default class SubMenu extends React.Component<Props> {

    handleClick = (index) => {
        this.props.handleClick(index, true)
    };
    render () {
        const {menuItem} = this.props;
        const menuItemVisuals = (menuItem && menuItem.children) ? menuItem.children.filter((item) => item.visuals) : false;
        const menuItemTexts = menuItem ? menuItem.children.filter((item) => !item.visuals) : false;
        const hasChildren = (menuItemTexts && menuItemTexts.length) ? menuItemTexts.filter((item) => item.children ) : false;
        const LANGUAGE_PREFIX = getLangPrefix();
        return (
            <React.Fragment>
                <div className="mobile-menu-item-content-head dflex align-items-center justify-content-between">
                    <div className="dflex align-items-center justify-content-between" onClick={() => this.handleClick(-1)}>
                        <Icon name="angle left"></Icon>
                        <p>{i18next.t('common.return')}</p>
                    </div>

                    <h4>{menuItem.label}</h4>
                </div>
                <div className="linge-de-lit">
                    {menuItemTexts.length &&
                    !hasChildren.length ?
                        <div className="decoration">
                            <ul className="homewear-list">
                                {
                                    menuItemTexts.map((item) => <li key={item.id}>
                                        <Link to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`} onClick={this.props.handleToggleMenu} >{item.label}</Link>
                                    </li>)
                                }
                            </ul>
                        </div>
                        :
                        menuItemTexts.map((item, i) => (
                            <div className="linge-de-lit-item" key={item.id}>
                                {
                                    <div>
                                        <h4 className="linge-de-lit-item-title">
                                            {item.label}
                                        </h4>
                                        {item.children && item.children.length &&
                                        <ul>
                                            {
                                                item.children.map((el) => el ?
                                                    <li key={el.id}>
                                                        <Link to={`${LANGUAGE_PREFIX}${el.canonicalUrl}`}  onClick={this.props.handleToggleMenu} >{el.label}</Link>
                                                    </li> : "")
                                            }
                                        </ul>
                                        }
                                    </div>
                                }
                            </div>
                        ))
                    }
                    {
                        //TODO update according to response
                        <div className="linge-de-lit-content">
                            {
                                menuItemVisuals.map((item) => (
                                    <div className="linge-de-lit-item" key={item.id}>
                                        <div className="linge-de-lit-item-img">
                                            <img src={`${BASE_API_GATEWAY}${item.visuals[0].photo}`}
                                                 alt="luxury img"/>
                                            <div className="linge-de-lit-item-img-content">
                                                <h4 className="linge-de-lit-item-title">
                                                    {item.label}
                                                </h4>
                                                {item.visuals && item.visuals[0] &&
                                                <Link to={item.visuals[0].menuHref ? `${LANGUAGE_PREFIX}${item.visuals[0].menuHref}` : "/"} onClick={this.props.handleToggleMenu} >{item.visuals[0].name}</Link>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}