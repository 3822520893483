import {createReducer} from 'reduxsauce';
import {GeoLocationTypes} from './actions';

export interface IGeoLocationState {
    geolocation: any
}

export const geoLocation: IGeoLocationState = {
    geolocation: {}
};

export const getGeoData = (state) => {
    return {...state};
};

export const geolocationSuccess = (state, action) => {
    return {...state, geolocation: action.data};
};

export const geolocationFailure = (state, action) => {
    return {...state, error: action.error, geolocation: {}};
};

export const handlers = {
    [GeoLocationTypes.GEOLOCATION_START]: getGeoData,
    [GeoLocationTypes.GEOLOCATION_SUCCESS]: geolocationSuccess,
    [GeoLocationTypes.GEOLOCATION_FAILURE]: geolocationFailure
};

export default createReducer(geoLocation,handlers);