import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {StoresFormActionCreators} from '../../../../../modules/storeFormSidePanel/redux/actions';
import {getLoading, getStoreTicketSubjects} from '../../../../../modules/storeFormSidePanel/redux/selectors';
import { getUserFromToken } from "../../../../../helpers/helpers";
import {BRAND_ID} from '../../../../../utils/constants/variables'
import {getAccountResource} from "../../../../../modules/login/redux/selectors";
import PopUp from "../../../../../modules/popUp/popUp";
import {Loader, TextArea} from "semantic-ui-react";
import AutocompleteDropdown from "../../../../../modules/common/autocompleteDropdown";
import {createTicket} from "../../../../../utils/constants/service";

interface Props {
    ticketSubjects: any,
    isLoading: boolean,
    handleClose?: void,
    account: any,
}

interface State {
    selectedDiscount: any,
    selectedSubject: string,
    message: string,
    loadingSubmit: boolean,
    errorMsg: string,
    successMsg: string,
}

class CreateTicket extends React.Component<Props & typeof StoresFormActionCreators> {

    state: State = {
        selectedDiscount: {},
        selectedSubject: "",
        message: "",
        loadingSubmit: false,
        errorMsg: "",
        successMsg: "",
    };

    componentDidMount(): void {
        this.props.storesFormStart()
    }

    handleClose = () => {
        this.props.handleClose()
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleSubmitInfo = async () => {
        try {
            this.setState({loadingSubmit: true, errorMsg :"",successMsg:""});
            const {account: {storeDetails = {}} = {}} = this.props,
                {message, selectedSubject} = this.state,
                body = {
                    brandId: BRAND_ID,
                    contactEmail: storeDetails.email,
                    contactName: getUserFromToken().fname,
                    contactPhone: storeDetails.phone,
                    country: storeDetails.country,
                    language: storeDetails.language,
                    storeId: storeDetails.storeId,
                    ticketMessage: message,
                    ticketMessageFrom: "C",
                    ticketSubjectCode: selectedSubject,

                },
                data = await createTicket(body);
            if(data) {
                this.setState({errorMsg: "",successMsg :i18next.t('alertMessage.2')})
                this.setState({loadingSubmit: false});
            }else {
                this.setState({loadingSubmit: false});
                this.setState({errorMsg: i18next.t('alertMessage.1'),successMsg :""})
            }

        } catch (e) {
            this.setState({loadingSubmit: false})
            this.setState({errorMsg: i18next.t('alertMessage.1'),successMsg :""})
        }
    }

    render(): React.ReactNode {
        const {isLoading, ticketSubjects, handleClose} = this.props;
        const {selectedSubject, message, loadingSubmit, errorMsg , successMsg} = this.state;
        return (
            <PopUp loading={isLoading} handleClose={handleClose}>
                <div className="popUp--body--card--body set-data-content">
                    <div className="content">
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('createTicket.subject')}
                            </div>
                            <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                <AutocompleteDropdown
                                    data={ticketSubjects.filter(subject => subject.brandId === BRAND_ID)}
                                    search={true}
                                    value={selectedSubject}
                                    classN="popUp--body--card--body--data--description"
                                    keyName="code"
                                    textName={['subject']}
                                    valueName="code"
                                    onChange={(val) => this.setState({selectedSubject: val})}
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('createTicket.message')}
                            </div>
                            <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                <TextArea className="popUp--body--card--body--data--description textarea"
                                          style={{minHeight: 100}}
                                          name="message"
                                          value={message}
                                          onChange={this.handleChange}
                                          autoComplete="off"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="extra content">
                    <div className="ui two buttons">
                        {
                            loadingSubmit ?
                                <button className="btn_1 w-100 blue_btn withoutHover">
                                    <Loader active inline='centered'/>
                                </button>
                                :
                                <button className={`btn_1 w-100 blue_btn withoutHover `}
                                        onClick={this.handleSubmitInfo}>
                                    {i18next.t('buttons.27')}
                                </button>
                        }
                        <div className="btn_1 w-100" onClick={this.props.handleClose}>
                            {i18next.t('buttons.11')}
                        </div>
                    </div>
                    {
                        errorMsg && <p className="errorMessage">{errorMsg}</p>
                    }
                    {
                        successMsg && <p className="successMessage">{successMsg}</p>
                    }
                </div>

            </PopUp>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    ticketSubjects: getStoreTicketSubjects(state),
    isLoading: getLoading(state),
    account: getAccountResource(state),
});

const mapDispatchToProps = (dispatch): typeof StoresFormActionCreators => {
    return bindActionCreators(StoresFormActionCreators, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
