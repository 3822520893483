import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import i18next from 'i18next';
import { Input, Loader } from 'semantic-ui-react';
import DayPicker from '../../../common/dayPicker';
import AutocompleteDropdown from '../../../common/autocompleteDropdown';
import {
    getPercentFormat,
    addDays,
    priceThreeDigitSets,
    arrayOfObjectsFilterCaseInsensitive,
    getLocalFormData,
} from '../../../../helpers/helpers';
import { getClients, getLoading } from '../../../clients/redux/selectors';
import { StoreGiftsActionCreators } from '../../redux/actions';
import { getCreatedStatus, getCreateGiftLoading } from '../../redux/selectors';
import {
    DEFAULT_CURRENCY,
    DEFAULT_CURRENCY_SYMBOL,
    GIFT_DURATION,
    RECEIPT_TYPE,
} from '../../../../utils/constants/variables';
import { fetchSingleStoreGift } from '../../../../utils/constants/service';
import CustomBtn from '../../../common/customBtn';
import { getAccountResource } from '../../../login/redux/selectors';

interface Props {
    data: any;
    clients: any;
    isLoadingClients: boolean;
    createdStatus: string;
    isCreateLoading: boolean;
    handleClosePopUp: any;
    account: any;
}

interface State {
    body: {
        active: boolean,
        amount: any,
        percent: string,
        barcode: string,
        startDate: number,
        endDate: number,
        redemptionDate: any,
        storeClientId: any,
        credit: boolean,
        currency: string,
        qrCode: any,
        storeGiftId: any,
    };
    isSetAmount: boolean;
    isSetPercent: boolean;
    status: string;
    clientsData: any;
    singleGift: any;
    bonusPriceData: {text: string, value: number}[]
}

class BonsCadeauxPopUp extends Component<
    Props & typeof StoreGiftsActionCreators,
    State
> {
    state: State = {
        body: {
            active: false,
            amount: '',
            percent: '',
            barcode: '',
            startDate: new Date().getTime(),
            endDate: addDays(new Date(), GIFT_DURATION).getTime(),
            redemptionDate: null,
            storeClientId: null,
            credit: false,
            currency: DEFAULT_CURRENCY,
            qrCode: null,
            storeGiftId: null,
        },
        isSetAmount: false,
        isSetPercent: false,
        status: '',
        clientsData: [],
        singleGift: {},
        bonusPriceData: [],
    };

    componentDidMount(): void {
        this.props.clientStart();
        if (this.props.data.storeGiftId) {
            const { data } = this.props;
            const {
                amount,
                percent,
                barcode,
                startDate,
                endDate,
                redemptionDate,
                clientId,
                currency,
                qrCode,
                storeGiftId,
                active,
                credit,
                storeGiftCode,
            } = data;
            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    active: active || false,
                    credit: credit || false,
                    amount: amount || '',
                    percent: percent || '',
                    barcode: barcode || '',
                    startDate: startDate || new Date().getTime(),
                    endDate: endDate || new Date().getTime(),
                    redemptionDate: redemptionDate || null,
                    currency: currency || '',
                    qrCode: qrCode || '',
                    storeGiftId,
                },
            }));

            fetchSingleStoreGift(storeGiftCode)
                .then((res) => this.setState((prevState: State) => ({
                    singleGift: res,
                    body: {
                        ...prevState.body,
                        storeClientId: res.storeClientId
                    }
                })))
                .catch((e) => this.setState({ singleGift: {} }));
        }
        this.setBonusPriceData()
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.isLoadingClients && !this.props.isLoadingClients) {
            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    active:
                        data && Object.keys(data).length ? data.active : false,
                    credit:
                        data && Object.keys(data).length ? data.credit : false,
                },
                clientsData: this.props.clients,
            }));
        }

        if (
            this.props.createdStatus &&
            prevProps.createdStatus !== this.props.createdStatus
        ) {
            if (this.props.createdStatus === 'success') {
                this.props.handleClosePopUp();
            } else {
                this.setState({ status: this.props.createdStatus });
            }
        }
    }

    handleChange = ({ target }) => {
        const { name, value } = target;
        let val = value;
        if (name && name.search(/date/i) > -1) {
            val = new Date(value).getTime();
        }
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                [name]: val,
            },
        }));
    };

    handleChangeDate = (date, name) => {
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                [name]: date ? new Date(date).getTime() : null,
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { body } = this.state;
        const { percent, amount, storeClientId } = body;
        let reqBody = { ...body, amount: Math.abs(amount) || '' };
        reqBody['active'] = percent ? true : body.active;

        if (body.credit && !storeClientId) {
            this.setState({ status: 'failedUser' });
            return;
        }

        if (percent || amount) {
            this.props.createStoreGiftStart(reqBody);
        }
    };

    handleScan = (barcode) => {
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                barcode,
            },
        }));
    };

    printGift = () => {
        const { data = {} } = this.props;
        const { credit, storeGiftCode } = data;
        const receiptType = credit ? RECEIPT_TYPE.credit : RECEIPT_TYPE.gift;
        this.props.printStoreGiftsStart({ receiptType, storeGiftCode });
    };

    setBonusPriceData = () => {
        const { giftCards } = getLocalFormData();
        const data: { text: string, value: number }[] = [];
        const brandId = this.props.account && this.props.account.storeDetails ? this.props.account.storeDetails.brandId : null;
        Array.isArray(giftCards) && giftCards.map((gift) => {
            if(!data.find((dataItem: {text: string, value: number}) => dataItem.value === gift.amount) && gift.brandId === brandId) {
                data.push({
                    text: `${gift.amount} ${DEFAULT_CURRENCY_SYMBOL}`,
                    value: gift.amount
                })
            }
        })
        this.setState({ bonusPriceData: [...data.sort((prevData, nextData) => prevData.value - nextData.value)] })
    }

    render() {
        const { data, isCreateLoading, account } = this.props;
        const {
            body,
            isSetPercent,
            clientsData,
            status,
            isSetAmount,
            singleGift,
        } = this.state;
        const isPrint =
            data && data.storeGiftCode && account && account.printer;

        return (
            <form onSubmit={this.handleSubmit} className="bonsPopUp">
                <div className="popUp--body set-data-content">
                    <div className="popUp--body--card--body">
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header"></div>
                            <div className="popUp--body--card--body--data--description ui buttons">
                                <div
                                    className={`btn_1 w-100 ${
                                        body.credit ? 'active' : ''
                                    }`}
                                    onClick={() =>
                                        body.storeGiftId
                                            ? null
                                            : this.setState(
                                                  (prevState: State) => ({
                                                      body: {
                                                          ...prevState.body,
                                                          percent: '',
                                                          amount: '',
                                                          credit: true,
                                                          active: true,
                                                      },
                                                  }),
                                              )
                                    }
                                >
                                    {i18next.t('Terminal.Receipt.Gift.Avoir')}
                                </div>
                                <div
                                    className={`btn_1 w-100 ${
                                        !body.credit ? 'active' : ''
                                    } ${
                                        body.storeGiftId ? 'withoutHover' : ''
                                    }`}
                                    onClick={() =>
                                        body.storeGiftId
                                            ? null
                                            : this.setState(
                                                  (prevState: State) => ({
                                                      body: {
                                                          ...prevState.body,
                                                          percent: '',
                                                          amount: '',
                                                          credit: false,
                                                          active: false,
                                                      },
                                                  }),
                                              )
                                    }
                                >
                                    {i18next.t(
                                        'Terminal.Receipt.Gift.carteCadeau',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header disabled">
                                {i18next.t('Terminal.dashboards.id')}
                            </div>
                            <div
                                className={
                                    'popUp--body--card--body--data--description disabled textBox'
                                }
                            >
                                {data.storeGiftId}
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header disabled">
                                {i18next.t('Terminal.dashboards.clientCode')}
                            </div>
                            <div
                                className={
                                    'popUp--body--card--body--data--description disabled textBox'
                                }
                            >
                                {data.storeGiftCode}
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header disabled">
                                {i18next.t(
                                    'Terminal.Receipt.Sale.referenceTicket',
                                )}
                            </div>
                            <div
                                className={
                                    'popUp--body--card--body--data--description disabled textBox'
                                }
                            >
                                {singleGift.ticketNumber}
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.barcode')}
                            </div>
                            <BarcodeReader onScan={this.handleScan} />
                            <div
                                className={
                                    'popUp--body--card--body--data--description disabled textBox'
                                }
                            >
                                {body.barcode}
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div
                                className={`popUp--body--card--body--data--header ${
                                    body.percent ? ' disabled' : ''
                                }`}
                            >
                                {i18next.t('Terminal.dashboards.Price', {
                                    DEFAULT_CURRENCY_SYMBOL,
                                })}
                            </div>
                            {body.storeGiftId ? (
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {body.amount +
                                        ' ' +
                                        DEFAULT_CURRENCY_SYMBOL}
                                </div>
                            ) : body.credit ? (
                                <Input
                                    error={data.active}
                                    className={`popUp--body--card--body--data--description `}
                                    name="amount"
                                    type={
                                        isSetAmount && !body.percent
                                            ? 'number'
                                            : 'text'
                                    }
                                    value={
                                        isSetAmount && !body.percent
                                            ? body.amount
                                            : priceThreeDigitSets(body.amount)
                                    }
                                    onChange={this.handleChange}
                                    onFocus={() =>
                                        this.setState({ isSetAmount: true })
                                    }
                                    onBlur={() =>
                                        this.setState({ isSetAmount: false })
                                    }
                                />
                            ) : (
                                <AutocompleteDropdown
                                    data={this.state.bonusPriceData}
                                    value={body.amount}
                                    search={true}
                                    keyName="Bonus"
                                    textName={['text']}
                                    valueName="value"
                                    disabled={!!body.percent}
                                    classN={`popUp--body--card--body--data--description ${
                                        !!body.percent ? 'disabled' : ''
                                    }`}
                                    onChange={(val) =>
                                        this.setState((prevState: State) => ({
                                            body: {
                                                ...prevState.body,
                                                amount: val,
                                            },
                                        }))
                                    }
                                />
                            )}
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div
                                className={`popUp--body--card--body--data--header${
                                    body.credit || !!body.amount
                                        ? ' disabled'
                                        : ''
                                }`}
                            >
                                {i18next.t('Terminal.dashboards.percent')}
                            </div>
                            <Input
                                error={data.active}
                                className={`popUp--body--card--body--data--description ${
                                    !!body.credit || !!body.amount
                                        ? 'disabled'
                                        : ''
                                }`}
                                name="percent"
                                disabled={!!body.credit || !!body.amount}
                                autoComplete="off"
                                type={
                                    isSetPercent && !body.amount
                                        ? 'number'
                                        : 'text'
                                }
                                value={
                                    isSetPercent && !body.amount
                                        ? body.percent
                                        : getPercentFormat(body.percent)
                                }
                                onChange={this.handleChange}
                                onFocus={() =>
                                    this.setState({ isSetPercent: true })
                                }
                                onBlur={() =>
                                    this.setState({ isSetPercent: false })
                                }
                            />
                        </div>

                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.startDate')}
                            </div>
                            <div className="popUp--body--card--body--data--description">
                                <DayPicker
                                    value={body.startDate}
                                    onChange={(date) =>
                                        this.handleChangeDate(date, 'startDate')
                                    }
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.endDate')}
                            </div>
                            <div className="popUp--body--card--body--data--description">
                                <DayPicker
                                    value={body.endDate}
                                    onChange={(date) =>
                                        this.handleChangeDate(date, 'endDate')
                                    }
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            {this.props.data.storeGiftId ? (
                                <>
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.redemptionDate',
                                        )}
                                    </div>
                                    <div className="popUp--body--card--body--data--description">
                                        <DayPicker
                                            value={body.redemptionDate}
                                            resetIcon={!!body.redemptionDate}
                                            onChange={(date) =>
                                                this.handleChangeDate(
                                                    date,
                                                    'redemptionDate',
                                                )
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="popUp--body--card--body--data--header disabled">
                                        {i18next.t(
                                            'Terminal.dashboards.redemptionDate',
                                        )}
                                    </div>
                                    <div className="popUp--body--card--body--data--description"></div>
                                </>
                            )}
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                Client
                            </div>
                            <AutocompleteDropdown
                                data={clientsData}
                                value={body.storeClientId}
                                search={(data, search) =>
                                    arrayOfObjectsFilterCaseInsensitive(
                                        data,
                                        search,
                                        'text',
                                    )
                                }
                                keyName="createdDate"
                                textName={[
                                    'firstName',
                                    'lastName',
                                    'city',
                                    'phone',
                                ]}
                                valueName="clientId"
                                classN={
                                    'popUp--body--card--body--data--description'
                                }
                                placeholder="Add Client"
                                onChange={(val) =>
                                    this.setState((prevState: State) => ({
                                        body: {
                                            ...prevState.body,
                                            storeClientId: val,
                                        },
                                    }))
                                }
                                separate={['', ',', ',', '']}
                            />
                        </div>
                    </div>
                    <div className="extra content">
                        <div className="ui two buttons">
                            {isCreateLoading ? (
                                <div className="btn_1 w-100 blue_btn withoutHover">
                                    <Loader active inline="centered" />
                                </div>
                            ) : (
                                <button
                                    className="btn_1 w-100 blue_btn withoutHover"
                                    onClick={this.handleSubmit}
                                >
                                    {i18next.t('Terminal.dashboards.edit')}
                                </button>
                            )}
                            <div
                                className="btn_1 w-100"
                                onClick={this.props.handleClosePopUp}
                            >
                                {i18next.t('buttons.40')}
                            </div>
                            <CustomBtn
                                classN={`btn_1 w-100 green_btn`}
                                disabled={!isPrint}
                                handleClick={this.printGift}
                            >
                                {i18next.t('buttons.22')}
                            </CustomBtn>
                        </div>
                    </div>
                    {status && (
                        <div
                            className={
                                status === 'success'
                                    ? 'successMessage'
                                    : 'errorMessage'
                            }
                        >
                            {status === 'success'
                                ? i18next.t('alertMessage.2')
                                : status === 'failedUser'
                                ? i18next.t('Terminal.quote.1')
                                : i18next.t('alertMessage.1')}
                        </div>
                    )}
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state: any) => ({
    clients: getClients(state),
    isLoadingClients: getLoading(state),
    createdStatus: getCreatedStatus(state),
    isCreateLoading: getCreateGiftLoading(state),
    account: getAccountResource(state),
});

const mapDispatchToProps = (dispatch): typeof StoreGiftsActionCreators => {
    return bindActionCreators(StoreGiftsActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BonsCadeauxPopUp);
