import React, { useRef, useEffect, useState } from 'react';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FileInput from '../../../common/fileInput/fileInput';
import CustomBtn from '../../../common/customBtn';
import { StockActionCreators } from '../../redux/actions';
import { getIsUplaodLoading, getUploadResult } from '../../redux/selectors';
import { getAccountResource } from '../../../login/redux/selectors';
import InfoPopUp from '../../../common/infoPopUp';
import { PopupData } from '../../../../shared/models/popupData';

interface Props {
	uploadStoreInventoryStart: (body: FormData) => void;
    uploadStoreInventoryInitial: () => void;
	isUploadLoading: boolean;
	handleClose: () => void;
    details: any;
    uploadResult: boolean | null;
}

const UploadInventoryPanel: React.FC<Props> = ({
	uploadStoreInventoryStart,
    uploadStoreInventoryInitial,
	isUploadLoading,
    details,
    uploadResult
}) => {
    const [infoPopupData, setInfoPopupData] = useState<PopupData>({
        isPopupVisible: false,
        message: ''
    });
	const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        return () => {
            uploadStoreInventoryInitial();
        }
    }, []);

    const onFileLoad = (event) => {
        const { result = '' } = event.target;

        if (result.match(/[<>]/g)) {
            toggleInfoPopup(i18next.t('Terminal.transferBasket.7'))
            return;
        }

        uploadFile();
    }

	const handleSubmitProductFile = () => {
		const file = fileRef.current && fileRef.current.files && fileRef.current.files[0]
		if (!file) {
			return;
		}
        const reader = new FileReader();
        reader.onload = onFileLoad;
        reader.readAsText(file);
	};

    const uploadFile = () => {
        const file = fileRef.current && fileRef.current.files && fileRef.current.files[0];
        const formData = new FormData();

		formData.append("brandId", details && details.brandApp || 0);
		formData.append("country", details && details.storeDetails && details.storeDetails.country || '');
		file && formData.append("file", file);

		uploadStoreInventoryStart(formData);
    }

    const toggleInfoPopup = (message: string = '') => {
        setInfoPopupData({
            isPopupVisible: !infoPopupData.isPopupVisible,
            message,
        })
    }

	return (
		<div>
			<div className="content">
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.file')}:
                    </div>
                    <div className={"popUp--body--card--body--data--description  clienttab input"}>
                        <FileInput
                            name="file"
                            id="fileInput"
                            inputRef={fileRef}
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--data--description d-flex justify-end">
                    <CustomBtn
                        disabled={false}
                        classN="btn_1 w-100"
                        handleClick={handleSubmitProductFile}
                        loading={isUploadLoading}
                    >
                        {i18next.t('Terminal.dashboards.apply')}
                    </CustomBtn>
                </div>
                {uploadResult !== null && (
                    <div
                        className={
                            uploadResult === true
                                ? 'successMessage'
                                : 'errorMessage'
                        }
                    >
                        {uploadResult === true
                            ? i18next.t('alertMessage.2')
                            : i18next.t('alertMessage.1')}
                    </div>
                )}
            </div>
            <InfoPopUp
                open={infoPopupData.isPopupVisible}
                classN="message-error"
                message={infoPopupData.message}
                handleClose={() => toggleInfoPopup()}
            />
		</div>
	)
}
const mapStateToProps = (state: any): any => ({
  isUploadLoading: getIsUplaodLoading(state),
  details: getAccountResource(state),
  uploadResult: getUploadResult(state)
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
  return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadInventoryPanel);