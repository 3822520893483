import { createActions } from 'reduxsauce';

export const { Types: salesTypes, Creators: SalesActionCreators } =
    createActions({
        salesStart: ['data'],
        salesSuccess: ['data'],
        salesFailure: ['error'],
        singleSalesStart: ['id', 'currency', 'storeCode'],
        singleSalesSuccess: ['data'],
        singleSalesFailure: ['error'],
        paperReceiptStart: ['data'],
        paperReceiptSuccess: ['data'],
        paperReceiptFailure: ['error'],
        getBasketStart: ['name'],
        printTicketStart: ['data'],
        printTicketSuccess: ['data'],
        printTicketFailure: ['error'],
        singleStoreGiftsStart: ['gift'],
        printReportStart: ['reportType', 'currency'],
        storesFormStart: [null],
        getCurrentDaySaleStart: null,
        singleSalesAnnuller: null
    });
