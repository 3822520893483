import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import {
    getStock,
    getStockBarcodeList,
    getStockNegativeBarcodeList,
} from '../../redux/selectors';
import PdfTable from '../../../pdfGenerator/pdfTableBtn';
import { getCurrency, isTerminalAdmin, priceThreeDigitSets, replaceComma } from '../../../../helpers/helpers';
import { getAccountResource } from '../../../login/redux/selectors';
import CustomBtn from '../../../common/customBtn';
import PopUp from '../../../popUp/popUp';
import BuyService from './buyService';
import ImportProducts from './importProduct';

interface Props {
    stockData: any;
    data: any;
    storeCode: string;
    selectedRows: any[];
    details: any;
    barcodesList: object[];
    negativeBarcodesList: object[];
    isService?: boolean;
}

interface State {
    isServiceSidepanelViible: boolean;
    isImportServiceVisible: boolean;
}

class ExportStockButtons extends Component<Props & State> {
    state: State = {
        isServiceSidepanelViible: false,
        isImportServiceVisible: false
    };
    columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.Category'),
            selector: 'category',
            width: '16%',
        },
        {
            name: i18next.t('Terminal.dashboards.model'),
            selector: 'model',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'productName',
            width: '27%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '12%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.stock'),
            selector: 'stock',
            width: '6%',
        },
        {
            name: i18next.t('product.labels.9'),
            selector: 'listPrice',
            width: '9%',
        },
        {
            name: i18next.t('product.labels.5'),
            selector: 'outletPrice',
            width: '6%',
        },
        {
            name: i18next.t('product.labels.6'),
            selector: 'promoListPrice',
            width: '6%',
        },
    ];

    serviceColumnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.partner'),
            selector: 'brand',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.dashboards.service'),
            selector: 'productName',
            width: '17%',
        },
        {
            name: i18next.t('Terminal.dashboards.detail'),
            selector: 'barcodeName',
            width: '17%',
        },
        {
            name: i18next.t('Terminal.dashboards.surface'),
            selector: 'size',
            width: '10%',
        },
        {
            name: i18next.t('product.product.22'),
            selector: 'recurring',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.dashboards.durationTitle'),
            selector: 'duration',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.dashboards.productGroup'),
            selector: 'productGroup',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.dashboards.obligatory'),
            selector: 'mandatory',
            width: '10%',
        },
        {
            name: i18next.t('product.labels.9'),
            selector: 'listPrice',
            width: '10%'
        }
    ]

    getPdfData = (negative) => {
        const { selectedRows, stockData, barcodesList, negativeBarcodesList } =
            this.props;
        if (Array.isArray(selectedRows) && selectedRows.length) {
            return selectedRows.map((product) => {
                const price = priceThreeDigitSets(
                    product.promoListPrice ||
                        product.outletPrice ||
                        product.listPrice,
                    product.currency,
                );
                return { ...product, price };
            });
        }

        if (!Array.isArray(stockData) || !stockData.length) return [];

        let barcodes = negative
            ? negativeBarcodesList
            : barcodesList.filter((item: any) => item.stock > 0);

        if (this.props.isService) {
            barcodes = barcodes.filter((item: any) => item.service);
        }

        return barcodes.map((item: any) => {
            const price = priceThreeDigitSets(
                item.promoListPrice || item.outletPrice || item.listPrice,
                item.currency,
            );

            const listPrice =  item.barcodeList.reduce((acc, barcode) => {
                acc += barcode.listPrice ? barcode.listPrice : 0;
                return acc;
            }, 0) || ""

            const outletPrice =  item.barcodeList.reduce((acc, barcode) => {
                acc += barcode.outletPrice ? barcode.outletPrice : 0;
                return acc;
            }, 0) || ""

            const promoListPrice =  item.barcodeList.reduce((acc, barcode) => {
                acc += barcode.promoListPrice ? barcode.promoListPrice : 0;
                return acc;
            }, 0) || ""

            const listPriceFormated = listPrice ? replaceComma(listPrice) + " " + getCurrency(item.currency) : ""
            const outletPriceFormated = outletPrice ? replaceComma(outletPrice) + " " + getCurrency(item.currency) : ""
            const promoListPriceFormated = promoListPrice ? replaceComma(promoListPrice) + " " + getCurrency(item.currency) : ""
            if (this.props.isService) {
                return {
                    brand: item.collection || item.brand,
                    productName: item.productName,
                    barcodeName: item.barcodeName,
                    size: item.size,
                    listPrice: listPriceFormated,
                    recurring: item.recurring ? "Y" : "N",
                    duration: item.duration,
                    productGroup: item.productGroup,
                    mandatory: item.mandatory ? "Y" : "N",
                };
            }

            return {
                category: item.category,
                model: item.model,
                productName: item.productName,
                barcode: item.barcode,
                stock: item.stock,
                size: item.size,
                productId: item.productId,
                listPrice: listPriceFormated,
                outletPrice: outletPriceFormated,
                promoListPrice: promoListPriceFormated
            };
        });
    };

    getCsvData = () => {
        const { selectedRows, barcodesList } = this.props;
        let stocks = Array.isArray(selectedRows) ? selectedRows : [];

        if (!stocks.length) {
            stocks = barcodesList;
        }

        if (this.props.isService) {
            stocks = stocks.filter((item: any) => item.service);
        }

        const headers = [
            { label: i18next.t('Terminal.dashboards.barcode'), key: 'barcode' },
            { label: i18next.t('Terminal.dashboards.brand'), key: 'brand' },
            {
                label: i18next.t('Terminal.dashboards.Category'),
                key: 'category',
            },
            { label: i18next.t('Terminal.dashboards.model'), key: 'model' },
            {
                label: i18next.t('Terminal.dashboards.Name'),
                key: 'productName',
            },
            { label: i18next.t('Terminal.dashboards.Size'), key: 'size' },
            { label: i18next.t('Terminal.dashboards.stock'), key: 'stock' },
            {
                label: i18next.t('Terminal.dashboards.promotion'),
                key: 'promotion',
            },
            {
                label: i18next.t('product.labels.9'),
                key: 'listPrice',
            },
            {
                label: i18next.t('product.labels.5'),
                key: 'outletPrice',
            },
            {
                label: i18next.t('product.labels.6'),
                key: 'promoListPrice',
            },
        ];

        const data = stocks.filter(el => el.stock).map((item) => ({
            barcode: item.barcode,
            brand: item.brand,
            category: item.category,
            model: item.model,
            productName: item.productName,
            size: item.size,
            stock: item.stock,
            promotion: item.promotion,
            listPrice: item.listPrice,
            outletPrice: item.outletPrice,
            promoListPrice: item.promoListPrice,
        }));

        return { headers, data, name: 'stocks.csv' };
    };

    toggleServiceSidePanel = () => {
        this.setState({ isServiceSidepanelViible: !this.state.isServiceSidepanelViible })
    }

    toggleImportProductSidePanel = () => {
        const { isImportServiceVisible } = this.state;
        this.setState({isImportServiceVisible: !isImportServiceVisible});
    }

    render() {
        const { isService, selectedRows, details: { storeDetails = {} } = {} } =
            this.props;
        const storeName = Object.keys(storeDetails) && storeDetails.name;
        const { data, headers, name } = this.getCsvData();
        const { isServiceSidepanelViible, isImportServiceVisible } = this.state;
        const isAdmin = isTerminalAdmin();
        return (
            <>
                <PdfTable
                    disabled={false}
                    classN="btn_1 w-100 blue_btn"
                    columns={isService ? this.serviceColumnsPdf : this.columnsPdf}
                    data={this.getPdfData(!selectedRows)}
                    storeName={storeName}
                >
                    {i18next.t('buttons.22')}
                </PdfTable>
                <CSVLink
                    filename={name}
                    className="order0 btn_1 w-100 orange_btn"
                    data={data}
                    headers={headers}
                    separator={';'}
                >
                    {i18next.t('Terminal.dashboards.exporter')}
                </CSVLink>
                <CustomBtn
                    classN="btn_1 w-100 red_btn"
                    handleClick={this.toggleServiceSidePanel}
                    disabled={!isAdmin}
                    order={-1}       
                >
                    {i18next.t('buttons.55')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    handleClick={this.toggleImportProductSidePanel}
                    disabled={!isAdmin || true}
                >
                    {i18next.t('buttons.57')}
                </CustomBtn>
                {isServiceSidepanelViible && (
                    <PopUp handleClose={this.toggleServiceSidePanel}>
                        <BuyService />
                    </PopUp>
                )}

                {isImportServiceVisible && (
                    <PopUp
                        handleClose={this.toggleImportProductSidePanel}
                        classN="exportStock import-stock-products"
                    >
                        <ImportProducts
                            isService
                        />
                    </PopUp>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    stockData: getStock(state),
    details: getAccountResource(state),
    negativeBarcodesList: getStockNegativeBarcodeList(state),
    barcodesList: getStockBarcodeList(state),
});

export default connect(mapStateToProps, null)(ExportStockButtons);
