import {
    AddToDeliveryCartTypes,
    AddToDeliveryCartActionCreators,
} from './actions';
import axios from 'axios';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import {
    getActiveBasketName,
    getApiPrefix,
    getLocalStorageObject,
    getStocksOfBasket,
} from '../../../helpers/helpers';
import {
    BARCODE_PRODUCTS,
    getAxiosHeaders,
} from '../../../utils/constants/service';
import { DEFAULT_CURRENCY } from '../../../utils/constants/variables';
import { getAccountResource } from '../../login/redux/selectors';
import { AddToCartActionCreators } from '../../addToCart/redux/actions';

export function* basket(params) {
    const { name } = params;
    try {
        const data = yield call(() => {
            return getLocalStorageObject(name);
        });
        yield put(
            AddToDeliveryCartActionCreators.getDeliveryBasketSuccess(data),
        );
    } catch (e) {
        yield put(
            AddToDeliveryCartActionCreators.addtodeliverycartFailure(e.message),
        );
    }
}

export function* getBasketStocks(params) {
    try {
        const { storeDetails } = yield select(getAccountResource);
        const {
            barcodeData,
            storeCode,
            storeType = storeDetails.storeType,
            basket,
        } = params;
        const data = yield all(
            barcodeData.map((item) =>
                call(() => {
                    return axios.get(
                        `${BARCODE_PRODUCTS}/${item}?language=${getApiPrefix()}&currency=${DEFAULT_CURRENCY}&storeCode=${storeCode}&country=${
                            storeDetails.country
                        }&promoCluster=${storeDetails.promotionCluster}`,
                        getAxiosHeaders(),
                    );
                }),
            ),
        );

        if (basket === getActiveBasketName()) {
            yield put(
                AddToCartActionCreators.addtocartSuccess({
                    basket: getStocksOfBasket(
                        data.map((i) => i.data),
                        storeCode,
                        basket,
                    ),
                }),
            );
        } else {
            yield put(
                AddToDeliveryCartActionCreators.getDeliveryBasketSuccess(
                    getStocksOfBasket(
                        data.map((i) => i.data),
                        storeCode,
                        basket,
                        storeType,
                    ),
                ),
            );
        }
    } catch (e) {
        yield put(
            AddToDeliveryCartActionCreators.addtodeliverycartFailure(e.message),
        );
    }
}

export function* addToDeliveryCartSaga() {
    yield takeLatest(AddToDeliveryCartTypes.GET_DELIVERY_BASKET_START, basket);
    yield takeLatest(
        AddToDeliveryCartTypes.GET_BASKET_STOCKS_START,
        getBasketStocks,
    );
}

export default addToDeliveryCartSaga;
