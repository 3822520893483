import React, { useState } from 'react';
import i18next from 'i18next';
import { Input } from 'semantic-ui-react';
import CustomBtn from '../../../common/customBtn';
import PopUp from '../../../popUp/popUp';

interface Props {
    handleClose: () => void;
    handleSubmit: (val) => void;
}

export default function ({ handleClose, handleSubmit }: Props) {
    const [value, setValue] = useState('');
    const onChange = ({ target }) => {
        if (target.value.length <= 20) {
            setValue(target.value);
        }
    };
    return (
        <PopUp classN="zone-popup" handleClose={handleClose}>
            <div>
                <p>{i18next.t('Terminal.Receipt.Deliveries.zone')}</p>
                <Input className='w-100' name="zone" value={value} onChange={onChange} />
                <CustomBtn
                    disabled={false}
                    classN="btn_1 w-100 green_btn"
                    handleClick={() => handleSubmit(value)}
                >
                    {i18next.t('Terminal.Block3.2')}
                </CustomBtn>
            </div>
        </PopUp>
    );
}
