import { StoreGiftsTypes, StoreGiftsActionCreators } from "./actions";
import { takeLatest, put, call, takeEvery, select } from "redux-saga/effects";
import axios from 'axios';
import { STORE_GIFTS_URL, CREATE_STORE_GIFT_URL, SINGLE_STORE_GIFTS_URL, STORE_PAPER_GIFT_URL, getAxiosHeaders } from "../../../utils/constants/service";
import {
    addGiftToBasket,
    changeBasketClientId,
    getActiveBasketClientId,
    getApiPrefix,
    removeBasketGifts,
} from '../../../helpers/helpers';
import { AddToCartActionCreators } from "../../addToCart/redux/actions";
import { RECEIPT_TYPE, ORDER_STATUS_CODE, LOGISTIC_STATUS_CODE } from "../../../utils/constants/variables";
import { getAccountResource } from "../../login/redux/selectors";
import { ReturnActionCreators } from "../../return/redux/actions";

export function* getStoreGifts() {
    try {
        const data = yield call(() => {
            return axios.get(STORE_GIFTS_URL, getAxiosHeaders())
                .then(res => res.data)
        });
        yield put(StoreGiftsActionCreators.storeGiftsSuccess(data));
    } catch (e) {
        yield put(StoreGiftsActionCreators.storeGiftsFailure(e.message));
    }
}

export function* createStoreGift(params){
    const { body, isPay } = params;

    try {
        const { data, status } = yield call(() => {
            return axios.post(CREATE_STORE_GIFT_URL, body, getAxiosHeaders())
                .then(res => res)
        });
        if (status === 200) {
            yield put(ReturnActionCreators.updateReturnStart({
                language: getApiPrefix(),
                returnStatusCode: ORDER_STATUS_CODE.PAI,
                ticketId: body.ticketId,
                ticketStatusCode: LOGISTIC_STATUS_CODE.CLO
            }));
    }
        yield put(StoreGiftsActionCreators.createStoreGiftSuccess(data));
        if(!isPay){
            yield put(StoreGiftsActionCreators.storeGiftsStart());
        }

        const { credit, storeGiftCode } = data;
        if(storeGiftCode) {
            const receiptType = credit ? RECEIPT_TYPE.credit : RECEIPT_TYPE.gift
            yield put(StoreGiftsActionCreators.printStoreGiftsStart({receiptType, storeGiftCode}));
        }

    } catch (e) {
        yield put(StoreGiftsActionCreators.createStoreGiftFailure(e.message));
    }
}

export function* getSingleStoreGifts(params){
    const {storeGiftCode = '', type} =params.gift

    if(!storeGiftCode || typeof storeGiftCode !== 'string') return;

    try {
        const data = yield call(() => {
            return axios.get(`${SINGLE_STORE_GIFTS_URL}${storeGiftCode.toUpperCase()}`, getAxiosHeaders())
                .then(res => res.data)
        });

        const basketClientId = getActiveBasketClientId();

        if(data.clientCode && !basketClientId) {
            changeBasketClientId(data.storeClientId)
        }

        if(type==='sale') {
            if (data.storeClientId !== basketClientId) {
                addGiftToBasket(data)
                yield put(StoreGiftsActionCreators.basketStoreGiftsStart());
                yield put(StoreGiftsActionCreators.getBasketStart());
                yield put(StoreGiftsActionCreators.storeGiftsStart());
            }
        }
        yield put(StoreGiftsActionCreators.singleStoreGiftsSuccess(data));
    } catch (e) {
        yield put(StoreGiftsActionCreators.singleStoreGiftsFailure(e.message));
    }
}

export function* printGift(params){
    const {data = {}} = params;
    const {storeGiftCode, receiptType} = data;
    const { printer } = yield select(getAccountResource);

    if(!printer) return;

    try {
        const data = yield call(() => {
            return axios.get(`${STORE_PAPER_GIFT_URL}?storeGiftCode=${storeGiftCode}&receiptType=${receiptType}`, getAxiosHeaders())
                .then(res => res.data)
        });

        yield put(StoreGiftsActionCreators.printStoreGiftsSuccess(data));
    } catch (e) {
        yield put(StoreGiftsActionCreators.printStoreGiftsFailure(e.message));
    }
}

export function* removeGifts({storeGiftCode}) {
    try {
        yield call(() => removeBasketGifts(storeGiftCode));
        yield put(StoreGiftsActionCreators.removeBasketGiftsSuccess());
        yield put(AddToCartActionCreators.getBasketStart());
    } catch (e) {
        yield put(StoreGiftsActionCreators.singleStoreGiftsFailure(e.message));
    }
}

export function* storeGiftsSaga() {
    yield takeLatest(StoreGiftsTypes.STORE_GIFTS_START, getStoreGifts);
    yield takeLatest(StoreGiftsTypes.CREATE_STORE_GIFT_START, createStoreGift);
    yield takeLatest(StoreGiftsTypes.SINGLE_STORE_GIFTS_START, getSingleStoreGifts);
    yield takeEvery(StoreGiftsTypes.PRINT_STORE_GIFTS_START, printGift);
    yield takeLatest(StoreGiftsTypes.REMOVE_BASKET_GIFTS_START, removeGifts);
}

export default storeGiftsSaga;
