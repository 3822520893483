import {createActions} from 'reduxsauce';

export const {Types: stockTypes, Creators: StockActionCreators} = createActions ({
    stockStart: null,
    stockSuccess: ['data'],
    stockFailure: ['error'],
    stockBarcodesListStart: ['param'],
    singleStockStart:["id"],
    singleStockSuccess:["data"],
    singleStockFailure:["error"],
    barcodeProductStart: ['barcode'],
    barcodeProductSuccess: ['data'],
    barcodeProductFailure: ['error'],
    getBarcodeProductStart: ['data'],
    createStockStart: ['body'],
    createStockSuccess: ['data'],
    createStockFailure: ['error'],
    productSoldStart:["id"],
    productSoldSuccess:["data"],
    productSoldFailure:["error"],
    storesStart: [null],
    isStockDashboardStart: ['data'],
    stockCountersStart: null,
    stockCountersSuccess: ['data'],
    stockCountersFailure: ['error'],
    printDataStart:['data', 'action'],
    addtocartStart: ["name", "key", "value", "setAsQuantity"],
    resetCreatedStockStatus: [null],
    getProductDeliveryStart: ["barcode"],
    getProductDeliverySuccess: ["data"],
    getProductDeliveryFailure: ["error"],
    getStockProductsStart: null,
    getStockProductsSuccess: ["data"],
    getStockProductsFailure: ["error"],
    importStockProductsStart: ["body"],
    importStockProductsSuccess: ["data"],
    importStockProductsFailure: ["error"],
    importProductDetailsStart: ["id"],
    importProductDetailsSuccess: ["data"],
    importProductDetailsFailure: ["error"],
    updateProductStockStart: ["barcode", "stock", "productId"],
    updateProductStockSuccess: ["data"],
    updateProductStockFailure: ["error"],
    updateProductPriceStart: ["body", "productId"],
    updateProductPriceSuccess: ["data"],
    updateProductPriceFailure: ["error"],
    uploadStoreInventoryStart: ["body"],
    uploadStoreInventorySuccess: ["data"],
    uploadStoreInventoryFailure: ["error"],
    uploadStoreInventoryInitial: null,
    getAssociateServiceStart: null,
    getAssociateServiceSuccess: ["data"],
    getAssociateServiceFailure: ["error"],
    adminPageLoadingStart: ["status"],
    addServicesStart: ["body"],
    addServicesSuccess: ["data"],
    addServicesFailure: ["error"],
    addServicesStatusReset: null,
    getStoreAssociatesStart: null,
    getStoreAssociatesSuccess: ["data"],
    getStoreAssociatesFailure: ["error"]
});
