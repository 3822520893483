import { call, put, takeLatest } from 'redux-saga/effects';
import { createAssociateBrandService, createStoreAssociate, getAssociateBrandService } from '../../../utils/constants/service';
import { StockActionCreators } from '../../stocks/redux/actions';
import { AdminActionCreators, AdminTypes } from './actions';

export function* createAssociate(params) {
    try {
        const { body, callback } = params;

        const data = yield call(() => createStoreAssociate(body));
        yield put(AdminActionCreators.createAssociateSuccess(data));
        yield put(StockActionCreators.getStoreAssociatesStart());
        callback && callback();
    } catch (error) {
        yield put(AdminActionCreators.createAssociateFailure(error));
    }
}

export function* getAssociateService(params) {
    try {
        const { body } = params;

        const data = yield call(() => getAssociateBrandService(body));
        yield put(AdminActionCreators.getAssociateCalendarServiceSuccess(data));
    } catch (error) {
        yield put(AdminActionCreators.getAssociateCalendarServiceFailure(error));
    }
}

export function* createAssociateService(params) {
    const { body, callback = () => {} } = params;
    try {

        const data = yield call(() => createAssociateBrandService(body));
        yield put(AdminActionCreators.createAssociateServiceSuccess(data));
        callback(true);
    } catch (error) {
        yield put(AdminActionCreators.createAssociateServiceFailure(error));
        callback(false);
    }
}

export function* adminSaga() {
    yield takeLatest(AdminTypes.CREATE_ASSOCIATE_START, createAssociate);
    yield takeLatest(AdminTypes.GET_ASSOCIATE_CALENDAR_SERVICE_START, getAssociateService);
    yield takeLatest(AdminTypes.CREATE_ASSOCIATE_SERVICE_START, createAssociateService);
}

export default adminSaga;