import React, {Component} from 'react';
import { Router } from 'react-router-dom';
import {Helmet} from "react-helmet";
import {withTranslation, WithTranslation} from "react-i18next";
import i18next from 'i18next';

import './app.scss';
import AppRoutes from './routes';
import ErrorBoundary from './shared/error/error-boundary';
import ScrollToTop from 'react-router-scroll-top'
import {getLang} from "./helpers/helpers";
import history from "./helpers/history";

declare global {
    interface Window {
        recaptchaOptions: any;
    }
}

interface IProps extends WithTranslation {
    search?: string,
    loading?: boolean,
    t: i18next.TFunction
}

class App extends Component<IProps & WithTranslation> {

    render() {
        return (
            <Router history={history}>
                <ScrollToTop>
                    <div className="app-container">
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>Waveum</title>
                            <html lang={getLang()}/>
                        </Helmet>
                        <ErrorBoundary>
                            <AppRoutes/>
                        </ErrorBoundary>
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

export default withTranslation('common')(App);
