import React from 'react';

import './style.scss'

interface IProps extends React.HTMLProps<HTMLButtonElement> {
    classN?: string;
}

const BtnRounded: React.FC<IProps> = ({ children, classN = '', ...rest }) => {
    return (
        <button
            className={`rounded-btn ${classN}`}
            {...rest}
        >{children}</button>
    )
}

export default BtnRounded;