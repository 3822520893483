import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import i18next from 'i18next';
import { Input, Table, TextArea } from 'semantic-ui-react';
import { InventoryActionCreators } from '../../redux/actions';
import {
    getCreatedId,
    getCreatedStatus,
    getCreateLoading,
    getSingleInventory,
} from '../../redux/selectors';
import InfoPopUp from '../../../common/infoPopUp';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import {
    arrayOfObjectGroupByKey,
    delay,
    fixedInHundredth,
    fixNumber,
    getScannerMotorolaBarcode,
    getTotalSum,
    removeDuplicatesElementArrayOfObjects,
    sortArrayOfObject,
    toTimestamp,
} from '../../../../helpers/helpers';
import DayPicker from '../../../common/dayPicker';
import CustomBtn from '../../../common/customBtn';
import { getProductDataByBarcode } from '../../../../utils/constants/service';
import { getAccountResource } from '../../../login/redux/selectors';
import {
    LOGISTIC_STATUS_CODE,
    STORE_COUNTRIES,
    STORE_COUNTRY,
} from '../../../../utils/constants/variables';
import ImportBarcodesBtn from '../../../common/importBarcodesBtn';
import ExportUnknownBarcodes from '../../../common/exportUnknownBarcodes';
import SearchBox from '../../../common/searchBox';
import FermerBtn from '../../../common/fermerBtn';
import ZoneSidePanel from './zoneSidePanel';
import Pagination from '../../../common/pagination';
import InventoryPdfButtons from './inventoryPdfButtons';
import { CSVLink } from "react-csv";

interface Props {
    isCreateLoading: boolean;
    createdStatus: string;
    singleInventory?: any;
    handleClosePopUp: () => void;
    inventory?: any;
    details: any;
    createdId: string | number | null;
}

interface State {
    isNew: boolean;
    isOpen: boolean;
    showConfirmPopup: boolean;
    status: string;
    stocks: object;
    totalQty: string | number;
    totalStocks: number;
    positiveTotalStocks: number;
    body: {
        storeInventoryRequestId: string | number | null;
        requestDate: any;
        comment: string;
        zone?: string;
        storeInvertoryProducts: any[];
    };
    loadingBarcodes: boolean;
    scanedBarcode: string;
    isEcart: boolean;
    unknownBarcodes: any[];
    showResetPopUp: boolean;
    newItem: any;
    searchLoading: boolean;
    showZoneSidePanel: boolean;
    resetPagination: boolean;
    currentPage: number;
    invertoryProducts: any;
    sorted: 'ascending' | 'descending' | undefined;
    sortedColumn: string;
    totalZones: any;
    code: string;
}

class CreateInventory extends Component<
    Props & typeof InventoryActionCreators,
    State
> {
    initialNewItem = {
        barcode: '',
        comment: '',
        qtyInventory: '',
    };

    state: State = {
        isNew: true,
        isOpen: false,
        showConfirmPopup: false,
        status: '',
        stocks: {},
        totalQty: 0,
        totalStocks: 0,
        positiveTotalStocks: 0,
        body: {
            storeInventoryRequestId: null,
            requestDate: new Date().getTime(),
            comment: '',
            zone: '',
            storeInvertoryProducts: [],
        },
        loadingBarcodes: false,
        scanedBarcode: '',
        isEcart: false,
        unknownBarcodes: [],
        showResetPopUp: false,
        newItem: this.initialNewItem,
        searchLoading: false,
        showZoneSidePanel: false,
        resetPagination: false,
        currentPage: 1,
        invertoryProducts: [],
        sorted: undefined,
        sortedColumn: '',
        totalZones: {},
        code: ""
    };

    perPage: number = 20;
    unknownBarcodes: any = [];
    importedBarcodes: any = [];

    barcodeInputRef: React.RefObject<any> = React.createRef();

    columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '13%',
            type: '',
            // type:
            //     this.props.inventory &&
            //     this.props.inventory.fullInventory === false
            //         ? 'image'
            //         : '',
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '21%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.storeChecked'),
            selector: 'qtyInventory',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.stockDefect'),
            selector: 'stockDefect',
            width: '6%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.zone'),
            selector: 'zone',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.totalZone'),
            selector: 'totalZone',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.stock'),
            selector: 'stocks',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.dashboards.ecart'),
            selector: 'ecart',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.comment'),
            selector: 'comment',
            width: '10%',
        },
    ];

    columnsEcartPdf: any = this.columnsPdf.map((item) => {
        if (item.selector === 'barcode') {
            return { ...item, type: 'image' };
        }
        return item;
    });

    componentDidMount(): void {
        if (this.props.inventory && Object.keys(this.props.inventory).length) {
            const { singleInventory, inventory } = this.props;
            const {
                requestDate,
                comment,
                storeInventoryRequestId,
                fullInventory,
            } = inventory;

            const storeInvertoryProducts: any[] = [];
            const stocks: object = {};
            if (Array.isArray(singleInventory)) {
                singleInventory.forEach((item) => {
                    storeInvertoryProducts.push({
                        barcode: item.barcode,
                        qtyInventory: item.stockChecked || 0,
                        name: item.productName,
                        comment: item.comment,
                        stockDefect: item.stockDefect,
                        stockRecondition: item.stockRecondition,
                        size: item.size,
                        stockInitial: item.stockInitial,
                        zone: item.zone,
                    });
                    stocks[item.barcode] = item.stock;
                });
            }

            let totalStocks = 0;
            let positiveTotalStocks = 0;
            const data = removeDuplicatesElementArrayOfObjects(
                singleInventory,
                'barcode',
            );
            data.forEach((item) => {
                totalStocks += item.stockInitial;
                if (item.stockInitial > 0) {
                    positiveTotalStocks += item.stockInitial;
                }
            });
            totalStocks = fixNumber(totalStocks);
            positiveTotalStocks = fixNumber(positiveTotalStocks);

            const body = {
                storeInventoryRequestId,
                requestDate: toTimestamp(requestDate),
                comment,
                storeInvertoryProducts,
            };

            this.setState({
                isNew: false,
                body,
                stocks,
                totalStocks,
                positiveTotalStocks,
                invertoryProducts: storeInvertoryProducts,
                totalZones: this.getTotalZones(storeInvertoryProducts),
                totalQty: +fixedInHundredth(
                    getTotalSum(body.storeInvertoryProducts, 'qtyInventory'),
                ),
            });
        }
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.createdStatus !== this.props.createdStatus) {
            this.setState({ status: this.props.createdStatus });
            if (this.props.createdId) {
                this.handleResetData();
            }
        }
        if (
            this.props.createdId &&
            prevProps.createdId !== this.props.createdId
        ) {
            this.handleChangeState(
                'storeInventoryRequestId',
                this.props.createdId,
            );
        }
    }

    handleDayChange = (selectedDay) => {
        this.handleChangeState('requestDate', Date.parse(selectedDay));
    };

    handleChangeState = (name, value) => {
        this.setState((prevState) => ({
            body: {
                ...prevState.body,
                [name]: value,
            },
        }));
    };

    handleChangeInput = (e) => {
        this.handleChangeState(e.target.name, e.target.value);
    };

    handleBlur = ({ target: { name } }, val = '') => {
        const storeDeliveryProducts =
            this.state.body.storeInvertoryProducts.map((item) => {
                if (item.barcode === name) {
                    item.qtyInventory = +item.qtyInventory;
                }
                return item;
            });

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts,
            },
        }));
    };

    onFocus = ({ target: { name, value } }) => {
        if (value === '0') {
            const storeDeliveryProducts =
                this.state.body.storeInvertoryProducts.map((item) => {
                    const [barcode, zone] = this.getItemName(name);
                    if (item.barcode === barcode && item.zone === zone) {
                        item.qtyInventory = '';
                    }
                    return item;
                });

            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeDeliveryProducts,
                },
            }));
        }
    };

    handleChangeNewItem = (name, value) => {
        this.setState((prev) => ({
            newItem: { ...prev.newItem, [name]: value },
        }));
    };

    getCsvData = () => {
        const headers = [
            {
                label: i18next.t('Terminal.dashboards.barcode'),
                key: 'barcode',
            },
            {
                label: i18next.t('Terminal.dashboards.Name'),
                key: 'productName',
            },
            {
                label: i18next.t('Terminal.dashboards.Size'),
                key: 'size',
            },
            {
                label: i18next.t('Terminal.dashboards.storeChecked'),
                key: 'qtyInventory',
            },
            {
                label: i18next.t('Terminal.dashboards.stockDefect'),
                key: 'stockDefect',
            },
            {
                label: i18next.t('Terminal.Receipt.Deliveries.zone'),
                key: 'zone',
            },
            {
                label: i18next.t('Terminal.Receipt.Deliveries.totalZone'),
                key: 'totalZone',
            },
            {
                label: i18next.t('Terminal.Receipt.Deliveries.stock'),
                key: 'stock',
            },
            {
                label: i18next.t('Terminal.Receipt.Deliveries.initial'),
                key: 'stockInitial',
            },
            {
                label: i18next.t('Terminal.dashboards.ecart'),
                key: 'ecart',
            },
            {
                label: i18next.t('Terminal.Receipt.Deliveries.comment'),
                key: 'comment',
            },
        ]

            const { singleInventory } = this.props;
            const { totalZones, stocks } = this.state;

            const data = singleInventory.map((item) => {
                const valueQty = fixedInHundredth(
                    this.getItemEcart(item),
                );
                const valueQtyToPrint = valueQty && valueQty > 0
                    ? `+${valueQty}`
                    : valueQty &&
                      valueQty < 0
                    ? `${valueQty}`
                    : ''

                return {
                    barcode: item.barcode,
                    productName: item.productName,
                    size: item.size,
                    qtyInventory: item.stockChecked,
                    stockDefect: item.stockDefect || 0,
                    zone: item.zone,
                    totalZone: totalZones[item.barcode] || 0,
                    stock: stocks[item.barcode],
                    stockInitial: item.stockInitial,
                    ecart: valueQtyToPrint,
                    comment: item.comment,
                }
            })

        return { headers, data, name: 'inventory.csv' };
    };

    handleChangeQty = ({ target }) => {
        const { body } = this.state;
        let totalZones = { ...this.state.totalZones };
        let { name, value } = target;
        const regexp = /^\d+(\.\d{1,2})?$/;
        if (value && !regexp.test(value)) {
            return;
        }

        if (value.length > 12) value = '';

        if (name === 'newItem') {
            this.handleChangeNewItem('qtyInventory', value);
            return;
        }

        const [barcode, zone] = this.getItemName(name);

        const storeInvertoryProducts = body.storeInvertoryProducts.map(
            (item) => {
                if (item.barcode === barcode && item.zone === zone) {
                    totalZones[barcode] =
                        totalZones[barcode] + (value - item.qtyInventory);
                    item.qtyInventory = value;
                }
                return item;
            },
        );

        const totalQty = fixedInHundredth(
            getTotalSum(body.storeInvertoryProducts, 'qtyInventory'),
        );

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeInvertoryProducts,
            },
            totalQty: +totalQty,
            totalZones,
        }));
    };

    handleReset = () => {
        const { body } = this.state;
        const storeInvertoryProducts = body.storeInvertoryProducts.map(
            (item) => ({ ...item, qtyInventory: 0 }),
        );

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                zone: '',
                storeInvertoryProducts,
            },
            totalQty: 0,
            totalZones: {},
            showResetPopUp: false,
            invertoryProducts: storeInvertoryProducts,
        }));
    };

    handleChangeValue = (target, itemName) => {
        const { body } = this.state;
        let totalZones = { ...this.state.totalZones };
        let { name, value } = target;
        const regexp = /^\d+(\.\d{1,2})?$/;
        if (value && !regexp.test(value)) {
            return;
        }
        if (value.length > 12) value = '';

        const [barcode, zone] = this.getItemName(itemName);
        const storeInvertoryProducts = body.storeInvertoryProducts.map(
            (item) => {
                if (item.barcode === barcode && item.zone === zone) {
                    if (name === 'stockDefect') {
                        totalZones[barcode] =
                            totalZones[barcode] +
                            ((item.stockDefect || 0) - value);
                    }
                    item[name] = value;
                }
                return item;
            },
        );

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeInvertoryProducts,
            },
            totalZones,
        }));
    };

    handleScan = async (code, ...rest) => {
        if (!code) return;
        const {
            details: { scanner },
        } = this.props;
        const { barcode, zone, stockChecked } = getScannerMotorolaBarcode(
            code,
            scanner,
        );
        const [count, end, isSubmit] = rest;
        const { body, isNew, newItem } = this.state;
        const { storeInvertoryProducts } = body;
        const filtredByBarcode = storeInvertoryProducts.filter(
            (item) => item.barcode === barcode,
        );
        const existingProduct = filtredByBarcode.findIndex(
            (item) => item.zone === zone,
        );
        let products: any = [];

        if (end) {
            if (scanner !== 'motorola') {
                this.setState({ showZoneSidePanel: true });
            }

            if (this.unknownBarcodes.length) {
                this.setState({ unknownBarcodes: this.unknownBarcodes });
            }
        }

        if (existingProduct > -1) {
            products = storeInvertoryProducts.map((item) => {
                if (item.barcode === barcode && item.zone === zone) {
                    let qtyInventory = (count || 1) * (stockChecked || 1);

                    if (barcode === newItem.barcode) {
                        qtyInventory = +newItem.qtyInventory || 1;
                        if (newItem.comment) {
                            item.comment = newItem.comment;
                        }
                    }

                    item.qtyInventory += qtyInventory;
                    if (count) {
                        this.importedBarcodes.push(barcode);
                    }
                }
                return item;
            });
        } else {
            try {
                const data = await getProductDataByBarcode(
                    barcode,
                    this.props.details,
                );
                if (data) {
                    const element: any = {
                        barcode,
                        name: data.name,
                        qtyInventory: count || 1,
                        comment: '',
                    };

                    if (barcode === newItem.barcode) {
                        element.qtyInventory = +newItem.qtyInventory || 1;
                        element.comment = newItem.comment || '';
                    }

                    if (stockChecked) {
                        element.qtyInventory =
                            element.qtyInventory * (stockChecked || 1);
                    }
                    if (zone) {
                        element.zone = zone;
                    }
                    products = [...storeInvertoryProducts, element];

                    if (count && barcode) {
                        this.importedBarcodes.push(barcode);
                    }
                } else {
                    const element: any = {
                        barcode,
                        name: "",
                        qtyInventory: count || 1,
                        stockInitial: 0,
                        comment: '',
                    };

                    if (barcode === newItem.barcode) {
                        element.qtyInventory = +newItem.qtyInventory || 1;
                        element.comment = newItem.comment || '';
                    }

                    if (stockChecked) {
                        element.qtyInventory =
                            element.qtyInventory * (stockChecked || 1);
                    }
                    if (zone) {
                        element.zone = zone;
                    }

                    products = [...storeInvertoryProducts, element];

                    if (count && barcode) {
                        this.importedBarcodes.push(barcode);
                    }
                }
            } catch (error) {
                if (count && barcode) {
                    this.unknownBarcodes.push(barcode);
                    return;
                }

                products = storeInvertoryProducts;
                this.setState({ isOpen: true, scanedBarcode: barcode });
            }
        }

        const totalQty = fixedInHundredth(
            getTotalSum(products, 'qtyInventory'),
        );

        const totalZones = this.getTotalZones(products);

        this.setState(
            (prevState: State) => ({
                totalQty,
                totalZones,
                newItem: this.initialNewItem,
                body: {
                    ...prevState.body,
                    storeInvertoryProducts: products,
                },
                invertoryProducts:
                    !count && filtredByBarcode.length
                        ? products.filter((item) => item.barcode === barcode)
                        : products,
            }),
            () => isSubmit && this.handleSubmit(),
        );
    };

    handleChangeItemTextField = (e, field) => {
        const { name, value } = e.target;

        if (name === 'newItem') {
            this.handleChangeNewItem(field, value);
            return;
        }

        const storeInvertoryProducts =
            this.state.body.storeInvertoryProducts.map((item) => {
                const [barcode, zone] = this.getItemName(name);
                if (item.barcode === barcode && item.zone === zone) {
                    item[field] = value;
                }
                return item;
            });

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeInvertoryProducts,
            },
        }));
    };

    setItemName = (item) => {
        if (item.newItem) {
            return 'newItem';
        }
        return item.barcode + (item.zone ? '-' + item.zone : '');
    };

    getItemName = (name) => {
        return name.split('-');
    };

    handleSubmit = (logisticStatus = LOGISTIC_STATUS_CODE.PRO) => {
        this.setState({ status: '' });

        const { body, stocks, newItem } = this.state;
        let invertoryBody = {};
        let barcodes: any = [];

        if (newItem.barcode) {
            this.handleScan(newItem.barcode, undefined, undefined, true);
            return;
        }

        if (body.storeInvertoryProducts && body.storeInvertoryProducts.length) {
            body.storeInvertoryProducts.forEach((item) => {
                const body = {
                    barcode: item.barcode,
                    stockChecked: +item.qtyInventory,
                    comment: item.comment,
                    zone: item.zone || undefined,
                    stockInitial: item.stockInitial !== undefined ? item.stockInitial : stocks[item.barcode],
                };
                if (item.stockDefect) {
                    body['stockDefect'] = +item.stockDefect;
                }
                if (item.stockRecondition) {
                    body['stockRecondition'] = item.stockRecondition;
                }
                barcodes.push(body);
            });
        }

        invertoryBody['logisticStatus'] = logisticStatus;
        invertoryBody['comment'] = body.comment;
        invertoryBody['storeInventoryRequestId'] = body.storeInventoryRequestId;
        invertoryBody['barcodes'] = barcodes;

        this.setState({ code: logisticStatus })
        this.props.createInventoryStart(invertoryBody);
    };

    handleAjouter = () => {
        const { invertoryProducts = [], newItem } = this.state;
        const arr = [...invertoryProducts];

        if (arr[0] && arr[0].newItem) {
            arr.shift();
            this.setState(
                {
                    invertoryProducts: arr,
                },
                () => this.handleScan(newItem.barcode),
            );
        } else {
            arr.unshift({ barcode: '', newItem: true });
            this.setState(
                {
                    invertoryProducts: arr,
                    resetPagination: true,
                },
                () =>
                    this.barcodeInputRef.current &&
                    this.barcodeInputRef.current.focus(),
            );
        }
    };

    inputRefs: any[] = [];
    setRefs = (ref) => this.inputRefs.push(ref);
    handleSearchBarcode = (value) => {
        if (value) {
            this.setState({ searchLoading: true }, async () => {
                await delay(100);
                const invertoryProducts =
                    this.state.body.storeInvertoryProducts.filter(
                        (item) => item.barcode === value,
                    );
                this.setState({
                    searchLoading: false,
                    invertoryProducts,
                    currentPage: 1,
                    resetPagination: true,
                });
            });
        }
    };

    handleSubmitZone = (zone) => {
        if (!zone) return;
        const storeInvertoryProducts =
            this.state.body.storeInvertoryProducts.map((item) => {
                if (
                    !item.zone &&
                    this.importedBarcodes.includes(item.barcode)
                ) {
                    item.zone = zone;
                }
                return item;
            });

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeInvertoryProducts,
            },
            invertoryProducts: storeInvertoryProducts,
            showZoneSidePanel: false,
        }));
    };

    handleSort = (name) => () => {
        const { storeInvertoryProducts } = this.state.body;
        const sorted =
            this.state.sorted === 'ascending' ? 'descending' : 'ascending';
        this.setState({
            invertoryProducts: sortArrayOfObject(
                storeInvertoryProducts,
                name,
                sorted,
            ),
            sortedColumn: name,
            sorted,
        });
    };

    handleSortEcart = () => {
        const {
            body: { storeInvertoryProducts },
        } = this.state;
        const sorted =
            this.state.sorted === 'ascending' ? 'descending' : 'ascending';
        const invertoryProducts = [...storeInvertoryProducts].sort((a, b) => {
            const valueA = fixedInHundredth(this.getItemEcart(a));
            const valueB = fixedInHundredth(this.getItemEcart(b));
            if (sorted === 'descending') {
                if (valueB < valueA) {
                    return -1;
                }
                if (valueB > valueA) {
                    return 1;
                }
            } else {
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
            }
            return 0;
        });
        this.setState({
            invertoryProducts,
            sortedColumn: 'ecart',
            sorted,
        });
    };

    handleResetData = () => {
        this.setState({
            resetPagination: true,
            invertoryProducts: this.state.body.storeInvertoryProducts,
        });
    };

    getTotalZones = (products) => {
        const totalZones: any = {};
        const data = arrayOfObjectGroupByKey(products, 'barcode');
        for (const key in data) {
            if (Array.isArray(data[key])) {
                const sum = data[key].reduce(
                    (acc, cur) =>
                        acc + (cur.qtyInventory - (cur.stockDefect || 0)),
                    0,
                );
                totalZones[key] = sum;
            }
        }
        return totalZones;
    };

    getItemEcart = (item, totalZones = this.state.totalZones) => {
        return (totalZones[item.barcode] || 0) - (item.stockInitial || 0);
    };

    getEcartTotalSums = () => {
        const { invertoryProducts = [] } = this.state;
        let totalPositiveEcart = 0;
        let totalNegativeEcart = 0;
        let totalEcart = 0;

        invertoryProducts.forEach((item) => {
            const ecart = this.getItemEcart(item);
            if (ecart > 0) {
                totalPositiveEcart += ecart;
            }
            if (ecart < 0) {
                totalNegativeEcart += ecart;
            }
            totalEcart += ecart;
        }, 0);

        return {
            totalPositiveEcart: fixNumber(totalPositiveEcart),
            totalNegativeEcart: fixNumber(totalNegativeEcart),
            totalEcart: fixNumber(totalEcart),
        };
    };

    render() {
        const {
            isCreateLoading,
            inventory: { inventoryRequestCode = '', logisticStatus = '' } = {},
            createdStatus
        } = this.props;

        const {
            body,
            status,
            isNew,
            isOpen,
            stocks,
            showConfirmPopup,
            totalQty,
            positiveTotalStocks,
            searchLoading,
            showZoneSidePanel,
            scanedBarcode,
            isEcart,
            showResetPopUp,
            unknownBarcodes,
            invertoryProducts,
            currentPage,
            resetPagination,
            sorted,
            sortedColumn,
            newItem,
            totalZones,
        } = this.state;

        const totalCount = invertoryProducts.length;
        const products = invertoryProducts.slice(
            (currentPage - 1) * this.perPage,
            this.perPage * currentPage,
        );

        const { headers, data, name } = this.getCsvData();
        const { totalEcart, totalNegativeEcart, totalPositiveEcart } = this.getEcartTotalSums();
        const isDisabled = createdStatus === "success" && this.state.code === LOGISTIC_STATUS_CODE.COM;
        return (
            <div
                id="deliveriesPopUp"
                className="deliveriesPopUp inventory--pop-up"
            >
                <div className="popUp--body set-data-content">
                    <div className="content">
                        <div className="mb-2">
                            <div className="extra content">
                                <div className="ui two buttons">
                                    <CustomBtn
                                        disabled={
                                            isDisabled || [
                                            LOGISTIC_STATUS_CODE.VAL,
                                            LOGISTIC_STATUS_CODE.COM,
                                        ].includes(logisticStatus)}
                                        handleClick={() =>
                                            this.handleSubmit()
                                        }
                                        classN="btn_1 w-100 blue_btn"
                                        loading={isCreateLoading}
                                    >
                                        {i18next.t(
                                            'Terminal.dashboards.edit',
                                        )}
                                    </CustomBtn>
                                    <CustomBtn
                                        disabled={
                                            isDisabled || [
                                            LOGISTIC_STATUS_CODE.VAL,
                                            LOGISTIC_STATUS_CODE.COM,
                                        ].includes(logisticStatus)}
                                        classN="btn_1 w-100 red_btn"
                                        handleClick={() =>
                                            this.setState({
                                                showConfirmPopup: true,
                                            })
                                        }
                                    >
                                        {i18next.t('Terminal.Block3.2')}
                                    </CustomBtn>

                                    <FermerBtn
                                        handleClick={
                                            this.props.handleClosePopUp
                                        }
                                    />
                                </div>
                            </div>
                            {status && (
                                <div
                                    className={`w-33 pb-2 ${
                                        status === 'success'
                                            ? 'successMessage'
                                            : 'errorMessage'
                                    }`}
                                >
                                    {status === 'success'
                                        ? i18next.t('alertMessage.2')
                                        : i18next.t('alertMessage.1')}
                                </div>
                            )}
                        </div>

                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.requestDate')}
                            </div>
                            <div className="popUp--body--card--body--between">
                                <div className="popUp--body--card--body--data--description">
                                    <DayPicker
                                        value={body.requestDate}
                                        onChange={(date) =>
                                            this.handleDayChange(date)
                                        }
                                    />
                                </div>
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.dashboards.storeInventoryRequestId',
                                    )}
                                </div>
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description disabled'
                                    }
                                    value={inventoryRequestCode || ''}
                                    disabled={true}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.stockTotal')}
                            </div>
                            <div className="popUp--body--card--body--between">
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description disabled color--darkgray'
                                    }
                                    type="text"
                                    name="totalStocks"
                                    value={positiveTotalStocks}
                                    disabled={true}
                                    autoComplete="off"
                                />
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t('Terminal.dashboards.ecart')}
                                </div>
                                <div
                                    className={`popUp--body--card--body--data--description input--div ${
                                        totalEcart > 0
                                            ? 'color--green'
                                            : 'color--darkred'
                                    }`}
                                >
                                    <span>
                                        {totalEcart > 0
                                            ? '+' + totalEcart
                                            : totalEcart}
                                    </span>{' '}
                                    (
                                    <span className="color--green">
                                        {totalPositiveEcart
                                            ? '+' + totalPositiveEcart
                                            : 0}
                                    </span>
                                    /
                                    <span className="color--darkred">
                                        {totalNegativeEcart}
                                    </span>
                                    )
                                </div>
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.dashboards.storeChecked',
                                    )}
                                </div>
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description disabled'
                                    }
                                    type="text"
                                    name="barcodeTotal"
                                    value={totalQty}
                                    disabled={true}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.Receipt.Deliveries.comment',
                                )}
                            </div>
                            <div className="popUp--body--card--body--between create-inventory-buttons">
                                <div>
                                    <TextArea
                                        className="popUp--body--card--body--data--description textarea"
                                        style={{ minHeight: 100 }}
                                        name="comment"
                                        value={body.comment}
                                        autoComplete="off"
                                        onChange={this.handleChangeInput}
                                    />
                                </div>
                                <div>
                                    <ImportBarcodesBtn
                                        handleUpload={this.handleScan}
                                        disabled={
                                            !isNew &&
                                            ![
                                                LOGISTIC_STATUS_CODE.PRO,
                                                LOGISTIC_STATUS_CODE.PEN,
                                            ].includes(logisticStatus)
                                        }
                                    />
                                </div>
                                <InventoryPdfButtons
                                    details={this.props.details}
                                    inventory={this.props.inventory}
                                    storeInvertoryProducts={
                                        body.storeInvertoryProducts
                                    }
                                    positiveTotalStocks={positiveTotalStocks}
                                    totalEcart={totalEcart}
                                    totalPositiveEcart={totalPositiveEcart}
                                    totalNegativeEcart={totalNegativeEcart}
                                    totalQty={totalQty}
                                    requestDate={body.requestDate}
                                    totalZones={totalZones}
                                    getItemEcart={this.getItemEcart}
                                />
                            </div>
                        </div>

                        <div className="dflex justify-content-between">
                            <SearchBox
                                onSubmit={this.handleSearchBarcode}
                                label={`${i18next.t(
                                    'Terminal.dashboards.barcode',
                                )}:`}
                                loading={searchLoading}
                                handleReset={this.handleResetData}
                            />
                            <div className="dflex justify-content-end">
                                {logisticStatus === LOGISTIC_STATUS_CODE.PRO ? (
                                    <div className="dflex justify-content-end">
                                        <CustomBtn
                                            classN="btn_1 red_btn maxWidth-150 minWidth-150 smallBtn"
                                            handleClick={() =>
                                                this.setState({
                                                    showResetPopUp: true,
                                                })
                                            }
                                        >
                                            {i18next.t('buttons.36')}
                                        </CustomBtn>
                                    </div>
                                ) : (
                                    ''
                                )}
                                { headers && data ?
                                <CSVLink headers={headers}
                                 className="btn_1 maxWidth-200 minWidth-200 smallBtn ml-1"
                                 filename={name}
                                 data={data}
                                 name={name}
                                 separator={';'}
                                >
                                    {i18next.t('Terminal.dashboards.exporter')}
                                </CSVLink>
                                : "" }
                                <CustomBtn
                                    classN="btn_1 ml-1 maxWidth-150 minWidth-150 smallBtn"
                                    handleClick={this.handleAjouter}
                                >
                                    {i18next.t('Terminal.dashboards.ajouter')}
                                </CustomBtn>
                                {!isNew && (
                                    <CustomBtn
                                        classN="btn_1 orange_btn maxWidth-150 minWidth-150 smallBtn ml-1"
                                        handleClick={() =>
                                            this.setState((prev) => ({
                                                isEcart: !prev.isEcart,
                                            }))
                                        }
                                    >
                                        {i18next.t('Terminal.dashboards.ecart')}
                                    </CustomBtn>
                                )}
                            </div>
                        </div>

                        <div className="deliveriesPopUp--body--data">
                            <BarcodeReader
                                onScan={(barcode) => this.handleScan(barcode)}
                            />
                        </div>
                    </div>

                    {totalCount ? (
                        <>
                            <Table
                                celled
                                sortable
                                selectable
                                className="productInfoBlock--infoBox--table invertory"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            className="productInfoBlock--infoBox--table--title cursor"
                                            sorted={
                                                sortedColumn === 'barcode'
                                                    ? sorted
                                                    : undefined
                                            }
                                            onClick={this.handleSort('barcode')}
                                        >
                                            {i18next.t(
                                                'Terminal.dashboards.barcode',
                                            )}
                                        </Table.HeaderCell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.Name',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.Size',
                                            )}
                                        </Table.Cell>
                                        <Table.HeaderCell
                                            className="productInfoBlock--infoBox--table--title cursor"
                                            sorted={
                                                sortedColumn === 'qtyInventory'
                                                    ? sorted
                                                    : undefined
                                            }
                                            onClick={this.handleSort(
                                                'qtyInventory',
                                            )}
                                        >
                                            {i18next.t(
                                                'Terminal.dashboards.storeChecked',
                                            )}
                                        </Table.HeaderCell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.stockDefect',
                                            )}
                                        </Table.Cell>
                                        <Table.HeaderCell
                                            className="productInfoBlock--infoBox--table--title cursor"
                                            sorted={
                                                sortedColumn === 'zone'
                                                    ? sorted
                                                    : undefined
                                            }
                                            onClick={this.handleSort('zone')}
                                        >
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.zone',
                                            )}
                                        </Table.HeaderCell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.totalZone',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.stock',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.initial',
                                            )}
                                        </Table.Cell>
                                        <Table.HeaderCell
                                            className="productInfoBlock--infoBox--table--title cursor"
                                            sorted={
                                                sortedColumn === 'ecart'
                                                    ? sorted
                                                    : undefined
                                            }
                                            onClick={this.handleSortEcart}
                                        >
                                            {i18next.t(
                                                'Terminal.dashboards.ecart',
                                            )}
                                        </Table.HeaderCell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.comment',
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body className="deliveri-list product---list--content">
                                    {products.map((item, i) => {
                                        const valueQty = fixedInHundredth(
                                            this.getItemEcart(item),
                                        );

                                        if (isEcart && !valueQty)
                                            return <React.Fragment key={i} />;

                                        return (
                                            <Table.Row
                                                key={i}
                                                className={
                                                    item.newItem
                                                        ? 'table--active--row'
                                                        : `${i % 2 === 0 ? "white-row" : "light-gray-row"}`
                                                }
                                            >
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.newItem ? (
                                                        <Input
                                                            ref={
                                                                this
                                                                    .barcodeInputRef
                                                            }
                                                            name="barcode"
                                                            value={
                                                                newItem.barcode
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) =>
                                                                this.handleChangeNewItem(
                                                                    target.name,
                                                                    target.value,
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        item.barcode
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.name}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.size}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {isNew && !item.newItem ? (
                                                        item.qtyInventory
                                                    ) : (
                                                        <Input
                                                            type="number"
                                                            name={this.setItemName(
                                                                item,
                                                            )}
                                                            value={
                                                                item.newItem
                                                                    ? newItem.qtyInventory
                                                                    : item.qtyInventory
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeQty
                                                            }
                                                            onBlur={
                                                                this.handleBlur
                                                            }
                                                            onFocus={
                                                                this.onFocus
                                                            }
                                                            ref={this.setRefs}
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {isNew || item.newItem ? (
                                                        item.stockDefect
                                                    ) : (
                                                        <Input
                                                            type="number"
                                                            name="stockDefect"
                                                            value={
                                                                item.stockDefect ||
                                                                ''
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) => {
                                                                this.handleChangeValue(
                                                                    target,
                                                                    this.setItemName(
                                                                        item,
                                                                    ),
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.zone}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description blueText">
                                                    {totalZones[item.barcode] ||
                                                        0}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {fixedInHundredth(stocks[item.barcode])}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {fixedInHundredth(item.stockInitial)}
                                                </Table.Cell>
                                                <Table.Cell
                                                    className={`productInfoBlock--infoBox--table--description ${
                                                        valueQty < 0
                                                            ? 'color--darkred'
                                                            : valueQty > 0
                                                            ? 'color--green'
                                                            : ''
                                                    }`}
                                                >
                                                    {valueQty && valueQty > 0
                                                        ? `+${fixedInHundredth(valueQty)}`
                                                        : valueQty &&
                                                          valueQty < 0
                                                        ? `${fixedInHundredth(valueQty)}`
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    <TextArea
                                                        rows={1}
                                                        name={this.setItemName(
                                                            item,
                                                        )}
                                                        value={
                                                            (item.newItem
                                                                ? newItem.comment
                                                                : item.comment) ||
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            this.handleChangeItemTextField(
                                                                e,
                                                                'comment',
                                                            )
                                                        }
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                            {totalCount > this.perPage && (
                                <Pagination
                                    onChangePage={(currentPage) =>
                                        this.setState({
                                            currentPage,
                                            resetPagination: false,
                                        })
                                    }
                                    totalCount={totalCount}
                                    perPage={this.perPage}
                                    resetDefault={resetPagination}
                                />
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </div>
                <InfoPopUp
                    open={isOpen}
                    classN="message-error"
                    message={i18next.t('Terminal.confirm.21', {
                        barcode: scanedBarcode,
                    })}
                    handleClose={() =>
                        this.setState({ isOpen: false, scanedBarcode: '' })
                    }
                />
                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={() => {
                            this.handleSubmit();
                            this.setState({ showConfirmPopup: false });
                        }}
                        handleConfirm={() => {
                            this.handleSubmit(LOGISTIC_STATUS_CODE.COM);
                            this.setState({ showConfirmPopup: false });
                        }}
                        text={i18next.t('Terminal.modal.2')}
                    />
                )}
                {showResetPopUp && (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showResetPopUp: false })
                        }
                        handleConfirm={this.handleReset}
                        text={i18next.t('Terminal.confirm.4')}
                    />
                )}
                <ExportUnknownBarcodes
                    open={!!unknownBarcodes.length}
                    handleClose={() => {
                        this.setState({ unknownBarcodes: [] });
                        this.unknownBarcodes = [];
                    }}
                    data={unknownBarcodes}
                />
                {STORE_COUNTRY !== STORE_COUNTRIES.de && showZoneSidePanel ? (
                    <ZoneSidePanel
                        handleClose={() =>
                            this.setState({ showZoneSidePanel: false })
                        }
                        handleSubmit={this.handleSubmitZone}
                    />
                ) : (
                    ''
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        singleInventory: getSingleInventory(state),
        isCreateLoading: getCreateLoading(state),
        createdStatus: getCreatedStatus(state),
        details: getAccountResource(state),
        createdId: getCreatedId(state),
    };
};

const mapDispatchToProps = (dispatch): typeof InventoryActionCreators => {
    return bindActionCreators(InventoryActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInventory);
