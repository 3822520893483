import React, {Component} from "react";
import i18next from "i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { destroyActiveBasket, getActiveBasketClientId, getActiveBasketName, getBasketData, getStoreLineItems } from "../../../helpers/helpers";
import {getAccountResource} from "../../login/redux/selectors";
import {getBasket} from "../../addToCart/redux/selectors";
import {QuoteActionCreators} from './../redux/actions'
import {getCreatedQuoteData, getCreatedQuoteStatus} from "../redux/selectors";
import InfoPopUp from "../../common/infoPopUp";
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import CustomBtn from "../../common/customBtn";
import QuotesSidePanel from "./components/quotesSidePanel";
import './index.scss'
import {QUOTE_DURATION, RECEIPT_TYPE} from "../../../utils/constants/variables";

interface Props {
    disabled?:boolean,
    activeAction?:string,
    handleSetActiveAction?:any,
    basket?:any,
    quoteStatus:string,
    details: any,
    quoteData: any,
    calculatorValue?:string,
}

interface State {
    showInfoPopup:boolean,
    showConfirmPopup:boolean,
    showSidePanel:boolean,
    InfoPopupMessage:string
}

class Quotes extends Component<Props & typeof QuoteActionCreators, State> {

    state: State = {
        showInfoPopup:false,
        showConfirmPopup:false,
        showSidePanel:false,
        InfoPopupMessage:i18next.t('Terminal.quote.1'),
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.calculatorValue !== this.props.calculatorValue && this.props.calculatorValue==="devis") {

            if(!getActiveBasketClientId()){
                this.setState({showInfoPopup: true})
            }else {
               this.setState({showConfirmPopup:true})
            }
        }
        if(prevProps.quoteStatus !==this.props.quoteStatus){
            if(this.props.quoteStatus==="success"){
                this.setState({showSidePanel: true })
            }else {
                this.setState({showInfoPopup: true,InfoPopupMessage:i18next.t('alertMessage.1') })
            }
        }
    }
    handleCreateQuote = () => {
        this.setState({ showConfirmPopup: false });
        const {basket} = this.props;

        try {
            const storeQuoteLineItems: any = getStoreLineItems(basket, true);
            const {price, totalQuantity, ticketNumber} = getBasketData(basket);
            const date = new Date();

            const body: any = {
                "deleted": false,
                "saleQuantity": totalQuantity,
                "storeClientId": getActiveBasketClientId(),
                "webDiscountId": null,
                "taxFree": false,
                "endDate": date.setDate(date.getDate() + QUOTE_DURATION),
                storeQuoteLineItems,
            }

            if(ticketNumber && price){
                const negativeLineItems = storeQuoteLineItems.filter(item => {
                    const product = basket[item.productId]
                    return product && product.sizes && product.sizes[0] && product.sizes[0].startingPrice < 0
                })

                if(negativeLineItems.length) {
                    body.storeSaleLineItems = negativeLineItems
                }
            }
            this.props.createQuoteStart(body);
        } catch (e) {
            console.log(e)
        }
    }
    handleDestroyBasket =()=> {
        destroyActiveBasket();
        this.props.getBasketStart();
        this.props.basketStoreGiftsStart();
        this.props.resetStoreClient(getActiveBasketName())
        this.setState({showSidePanel: false})

    }
    toggleRemovePopup = () => {
        this.setState({showConfirmPopup:false})
    }
    render() {
        const { disabled, activeAction, handleSetActiveAction, quoteStatus, quoteData={} } =this.props
        const { showInfoPopup, showConfirmPopup, InfoPopupMessage, showSidePanel }= this.state
        const { clientEmail, quoteNumber} = quoteData
        return (
            <>
                <CustomBtn
                    classN={` btn_1 ${activeAction === 'devis' ? ' active' : ''}`}
                    disabled={disabled}
                    handleClick={() => handleSetActiveAction('devis')}
                >
                    {i18next.t('Terminal.Block2.7')}
                </CustomBtn>

                <InfoPopUp
                    open={showInfoPopup}
                    message={InfoPopupMessage}
                    classN={quoteStatus==="success"?"successMessage":quoteStatus==="failed"?"errorMessage":""}
                    handleClose={()=>this.setState({showInfoPopup: false})}
                />
                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={this.toggleRemovePopup}
                        handleConfirm={this.handleCreateQuote}
                        text={i18next.t('Terminal.quote.2')}
                    />
                )}
                {showSidePanel &&
                    <QuotesSidePanel
                        destroyBasket={this.handleDestroyBasket}
                        printer={this.props.details.printer}
                        clientEmail={clientEmail}
                        status={this.props.quoteStatus}
                        printTicket={()=>this.props.quotePrintTicketStart(
                            {
                                quoteNumber,
                                receiptType: RECEIPT_TYPE.quote
                            }
                        )}
                    />
                }
            </>
        );
    }
}


const mapStateToProps = (state: any): Props => ({
    details: getAccountResource(state),
    basket: getBasket(state),
    quoteStatus:getCreatedQuoteStatus(state),
    quoteData:getCreatedQuoteData(state)
});

const mapDispatchToProps = (dispatch): typeof QuoteActionCreators => {
    return bindActionCreators(QuoteActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
