import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import i18next from "i18next";
import PopUp from "../../../popUp/popUp";
import CreateInventory from "./createInventory";
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import {InventoryActionCreators} from "./../../redux/actions";
import {getUpdateStatus} from "../../redux/selectors";
import InfoPopUp from "../../../common/infoPopUp";

interface State {
    isShowPopUp: boolean,
    updateStatusPopup : boolean,
    updatStatuseMsg: string
}
interface Props {
    updateStatus: string,
}
class NewInventory extends Component<State & typeof  InventoryActionCreators> {
    state: State = {
        isShowPopUp: false,
        updateStatusPopup : false,
        updatStatuseMsg:""
    };
    componentDidUpdate(prevProps ) {
        if(prevProps.updateStatus !== this.props.updateStatus){
            if(this.props.updateStatus === "success"){
                this.setState({updatStatuseMsg: i18next.t('alertMessage.2')})
            }else if(this.props.updateStatus === "failed"){
                this.setState({updatStatuseMsg: i18next.t('alertMessage.1')})
            }else {
                this.setState({updatStatuseMsg:""})
            }
        }
    }

    openPopUp = (e) => {
        this.setState({isShowPopUp: true});
    }
    handleClosePopUp = () => {
        this.setState({isShowPopUp: false});
    }
    handlelogisticSatus = ()=>{
        const { data } = this.props;
        this.props.updateStoreInventoryStart("CLO",data.storeInventoryRequestId)
    }
    hendelResetStatus = () => {
        this.props.resetStoreInventoryStatus()
    }

    render() {
        const {isShowPopUp,updateStatusPopup,updatStatuseMsg} = this.state;
        const {updateStatus} = this.props;
        return (
            <>
                <button className="btn_1 w-100 orange_btn "  onClick={this.openPopUp}>{i18next.t('Terminal.dashboards.new')}</button>
                <button className="btn_1 w-100 red_btn "  onClick={() =>  this.setState({updateStatusPopup: true})} style={{order:10}}>{i18next.t('buttons.12')}</button>
                {isShowPopUp ? <PopUp handleClose={this.handleClosePopUp} classN="createInvertoryPopUp">
                    <CreateInventory handleClosePopUp={this.handleClosePopUp} />
                </PopUp> : ""}
                {updateStatusPopup?
                    <ConfirmPopup
                        handleCancel={() => {this.setState({updateStatusPopup: false})}}
                        handleConfirm={() => {
                            this.handlelogisticSatus()
                            this.setState({updateStatusPopup: false})
                        }}
                        text={i18next.t('Terminal.modal.2')}
                    />:""}

                <InfoPopUp
                    open={!!updatStatuseMsg}
                    classN={`${updateStatus==="successMessage"? "" :updateStatus==="errorMessage"? 'message-error' :""}`}
                    message={updatStatuseMsg}
                    handleClose={()=> this.hendelResetStatus()}
                />
            </>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    updateStatus: getUpdateStatus(state),
});

const mapDispatchToProps = (dispatch): typeof InventoryActionCreators => {
    return bindActionCreators(InventoryActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInventory);

