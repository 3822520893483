import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {StoresFormActionCreators} from "../../storeFormSidePanel/redux/actions";
import {getLoading, getStoreSpecialDiscounts} from "../../storeFormSidePanel/redux/selectors";
import Loading from "../../common/loading/loading";
import { getActiveBasketName, getIntDiscount, changeSousTotal, fixNumber, getBasketProductsBySizes } from "../../../helpers/helpers";
import StoreFormSidePanel from "../../storeFormSidePanel/container";
import  {DEFAULT_CURRENCY_SYMBOL, STORE_COUNTRIES, STORE_COUNTRY} from '../../../utils/constants/variables'
import {getAccountResource} from "../../login/redux/selectors";
interface Props  {
    storeSpecialDiscounts: any,
    isLoading: boolean,
    handleClose?: void,
    activeField?: string,
    activeAction?: string,
    addToCart?: void,
    resetCalculator?: void,
    value?: string,
    account: any,
    basket?:any,
}

interface State {
    selectedDiscount: any
}

class PercentPopUp extends React.Component<Props & typeof StoresFormActionCreators> {

    state : State = {
        selectedDiscount: {}
    };

    componentDidUpdate(prevProps, prevState): void {
        if(prevProps.isLoading && !this.props.isLoading && this.props.storeSpecialDiscounts.length){
            this.setState({selectedDiscount: this.props.storeSpecialDiscounts[0] || {}});
        }
    }

    handleClose = () => {
        this.props.handleClose()
    };

    handleClick = (selectedDiscount) => () => {
        this.setState({selectedDiscount})
    };
    handleDoubleClick = (selectedDiscount) => {
        this.setState({selectedDiscount},() => this.handleSubmit())
    };

    handleSubmit = () => {
        const {activeAction, value, sousTotal, activeField = "", isSousTotal, basket} = this.props;
        const {storeSpecialDiscountId, code,specialDiscount} = this.state.selectedDiscount;
        const ids = activeField.split("/");
        let discountPrice =0;

        if(+value > 100) {
            changeSousTotal('update', 0);
            this.props.resetCalculator("");
            this.handleClose();
            return
        }

        if(activeAction === "reductionPercentAll") {
            const products = getBasketProductsBySizes(basket);
            products.forEach(item => {
                if(item.isPromo || Math.abs(parseFloat(item.discountDetail ? item.discountDetail.replace(",", ".") : "0")) < value){
                    this.props.addToCart(getActiveBasketName(), item.parentID, {
                        size: {
                            discountDetail: `-${fixNumber(value) || 0 }%`,
                            specialDiscountId: {storeSpecialDiscountId, code, specialDiscount},
                            productId: item.productId
                        }
                    });
                }
            });
            changeSousTotal('update', 0);
            this.props.resetCalculator("");
            this.handleClose();
            return
        }

        if(activeAction === "reductionMoney"){
            basket[ids[0]] && basket[ids[0]].sizes.forEach(item=> {
                if(item.productId===parseInt(ids[1])){
                    discountPrice += item.startingPrice
                }
            });
            if(discountPrice > parseFloat(value)){
                discountPrice = value - discountPrice
            }else {
                discountPrice = 0
            }
        }
        
        const discountDetail = activeAction === "reductionPercent" ? `-${fixNumber(value) || 0 }%` : `-${fixNumber(value) || 0 }${DEFAULT_CURRENCY_SYMBOL}`;
        if(sousTotal && Object.keys(sousTotal).length && isSousTotal){
            const discauntOfSousTotal= {
                ...sousTotal,
                discountPrice: getIntDiscount(discountDetail, sousTotal.value, 1),
                discountDetail,
                specialDiscountId: {storeSpecialDiscountId, code, specialDiscount},
                subTotalId:sousTotal.position,
            };
            changeSousTotal("add",discauntOfSousTotal)
        }else if(activeField && !isSousTotal){
            try {
                this.props.addToCart(getActiveBasketName(), ids[0], {
                    size: {
                        discountDetail: activeAction === "reductionPercent" ? `-${fixNumber(value) || 0}%`
                            : `-${fixNumber(value) || 0}${DEFAULT_CURRENCY_SYMBOL}`,
                        specialDiscountId: {
                            storeSpecialDiscountId,
                            code,
                            specialDiscount
                        },
                        productId: ids[1]
                    }
                });
            } catch (e) {
                console.log(e)
            }
        }
        changeSousTotal('update', 0);
        this.props.resetCalculator("");
        this.handleClose()
    };


    render(): React.ReactNode {
        const { isLoading, storeSpecialDiscounts } = this.props;
        const { selectedDiscount } = this.state;
        const isCountryIt = STORE_COUNTRY === STORE_COUNTRIES.it;
        return (
            <StoreFormSidePanel handleClose={this.handleClose}>
                {
                    isLoading ?
                        <Loading/>
                        :
                        <>
                            {
                                storeSpecialDiscounts.map(item => (
                                        <button
                                            key={item.storeSpecialDiscountId}
                                            className={`btn_1 special-discount-btn${selectedDiscount.storeSpecialDiscountId === item.storeSpecialDiscountId ? " active" : ""}
                                            ${isCountryIt && item.code === 'EMP' && 'disabled'}`}
                                            onClick={this.handleClick(item)}
                                            onDoubleClick={()=>this.handleDoubleClick(item)}
                                            disabled={isCountryIt && item.code === 'EMP'}
                                        >
                                            {item.specialDiscount}
                                        </button>
                                    )
                                )
                            }
                            <button className="btn_1 green_btn" onClick={this.handleSubmit}>{i18next.t('Terminal.Block3.2')}</button>
                        </>
                }
            </StoreFormSidePanel>
        );
    }
}
const mapStateToProps = (state: any): Props => ({
    storeSpecialDiscounts: getStoreSpecialDiscounts(state),
    isLoading: getLoading(state),
    account: getAccountResource(state),
});

const mapDispatchToProps = (dispatch): typeof StoresFormActionCreators => {
    return bindActionCreators(StoresFormActionCreators, dispatch);
};



export default connect(mapStateToProps,mapDispatchToProps)(PercentPopUp);
