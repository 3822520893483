import React from 'react'
import {Step} from 'semantic-ui-react'
import i18next from "i18next";

interface IProps {
    steps: object,
    activeStep: number,
}

const TerminalSteps: React.FC<IProps> = ({steps,activeStep}: IProps)  => {
    return (
        <div className="terminalClose--Header">
            <Step.Group ordered>
                <Step completed={steps[1]} active={activeStep===1}>
                    <Step.Content>
                        <Step.Title>{i18next.t(`Terminal.terminalClose.info`)}</Step.Title>
                    </Step.Content>
                </Step>

                <Step completed={steps[2]} active={activeStep===2}>
                    <Step.Content>
                        <Step.Title>{i18next.t(`Terminal.terminalClose.deposit`)}</Step.Title>
                    </Step.Content>
                </Step>

                <Step completed={steps[3]} active={activeStep===3} >
                    <Step.Content>
                        <Step.Title>{i18next.t(`Terminal.terminalClose.cash`)}</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>
        </div>
    )
}


export default TerminalSteps
