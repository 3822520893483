import {createReducer} from 'reduxsauce';
import {AddToDeliveryCartTypes} from './actions';

export interface IAddToDeliveryCartState {
    deliveryBasket: any,
    addedToDeliveryCart: boolean,
    error: string,
    maxLimit: boolean,
}

export const addToDeliveryCart: IAddToDeliveryCartState = {
    deliveryBasket: {},
    addedToDeliveryCart: false,
    error: "",
    maxLimit: false,
};

export const addtoDeliveryocartSuccess = (state, action) => {
    const {data} = action;
    return {...state, addedToDeliveryCart: data.added, maxLimit: !data.added, deliveryBasket: data.basket || {}};
};
export const addtoDeliveryocartFailure = (state, action) => {
    return {...state, error: action.error}
}

export const deliveryBasketStart = (state) => {
    return {...state};
};

export const deliveryBasketSuccess = (state, action) => {
    return {...state, addedToDeliveryCart: false,  deliveryBasket: action.data || {}};
};


export const removeDeliveryBasketSuccess = (state, action) => {
    return {...state, addedToDeliveryCart: false, maxLimit: false, deliveryBasket: action.data || {}};
};

export const handlers = {
    [AddToDeliveryCartTypes.ADDTODELIVERYCART_SUCCESS]: addtoDeliveryocartSuccess,
    [AddToDeliveryCartTypes.ADDTODELIVERYCART_FAILURE]: addtoDeliveryocartFailure,
    [AddToDeliveryCartTypes.GET_DELIVERY_BASKET_START]: deliveryBasketStart,
    [AddToDeliveryCartTypes.GET_DELIVERY_BASKET_SUCCESS]: deliveryBasketSuccess,
    [AddToDeliveryCartTypes.REMOVE_DELIVERY_BASKET_SUCCESS]: removeDeliveryBasketSuccess,
};

export default createReducer(addToDeliveryCart, handlers);
