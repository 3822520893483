import { createReducer } from 'reduxsauce';
import { AppointmentsTypes } from './actions';
import { getBasketStoreGifts } from '../../../helpers/helpers';

export interface IState {
    isLoading: boolean;
    serviceCalendar: any;
    singleServiceCalendar: any;
    error: any;
    storeClient: any;
    isCalendarIdLoading: boolean;
}

export const appointmentsState: IState = {
    isLoading: false,
    serviceCalendar: [],
    error: '',
    storeClient: null,
    isCalendarIdLoading: false,
    singleServiceCalendar: null
};

export const getServiceCalendarStart = (state) => {
    return {...state, isLoading: true};
};

export const getServiceCalendarSuccess = (state, action) => {
    return {...state, serviceCalendar: action.data, isLoading: false};
};

export const getServiceCalendarFailure = (state, action) => {
    return {...state, error: action.error, serviceCalendar: [], isLoading: false};
};

export const getStoreClientStart = (state) => {
    return state;
}

export const getStoreClientSuccess = (state, params) => {
    return {...state, storeClient: params.data}
}

export const getStoreClientFailure = (state) => {
    return {...state, storeClient: null}
}

export const getServiceCalendarIdStart = (state) => {
    return {...state, isCalendarIdLoading: true};
}

export const getServiceCalendarIdSuccess = (state, params) => {
    return {...state, singleServiceCalendar: params.data, isCalendarIdLoading: false};
}

export const getServiceCalendarIdFailure = (state) => {
    return {...state, singleServiceCalendar: null, isCalendarIdLoading: false};
}

export const handlers = {
    [AppointmentsTypes.GET_SERVICE_CALENDAR_START]: getServiceCalendarStart,
    [AppointmentsTypes.GET_SERVICE_CALENDAR_SUCCESS]: getServiceCalendarSuccess,
    [AppointmentsTypes.GET_SERVICE_CALENDAR_FAILURE]: getServiceCalendarFailure,
    [AppointmentsTypes.GET_STORE_CLIENT_START]: getStoreClientStart,
    [AppointmentsTypes.GET_STORE_CLIENT_SUCCESS]: getStoreClientSuccess,
    [AppointmentsTypes.GET_STORE_CLIENT_FAILURE]: getStoreClientFailure,
    [AppointmentsTypes.GET_SERVICE_CALENDAR_ID_START]: getServiceCalendarIdStart,
    [AppointmentsTypes.GET_SERVICE_CALENDAR_ID_SUCCESS]: getServiceCalendarIdSuccess,
    [AppointmentsTypes.GET_SERVICE_CALENDAR_ID_FAILURE]: getServiceCalendarIdFailure,
};

export default createReducer(appointmentsState, handlers);
