import React, {FC, useState, useEffect} from "react";

interface Props {
    data: any[]
    label: string,
    selectedId: string | number,
    handelClick: (id: number)=>void
}
const FormButtonGroup: FC<Props> = ({data, label, selectedId, handelClick}: Props) => {
    const [selected, setSelected] = useState<string | number>(0)
    useEffect(() => {
        setSelected(selectedId)
    }, [selectedId])
    const handelClickBtn =(id) => (e) => {
        e.preventDefault()
        setSelected(id)
        handelClick(id)
    }
    return (
        <div className="table-store-counter--Content--box">
            {
                data.map(item => {
                    return (
                        <button
                            key={item.id}
                            className={`btn_1 w-100 ${item.id === selected ? "active": ""}`}
                            onClick={handelClickBtn(item.id)}
                        >
                            {item[label]}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default FormButtonGroup
