import {createReducer} from 'reduxsauce';
import {returnTypes} from './actions';


export interface IReturnState {
    returnData: any,
    isLoading: boolean,
    isTicketLoading: boolean;
    updatedStatus:string,
    ticketData: any;
}

export const  returnContent: IReturnState = {
    returnData: [],
    isLoading: false,
    updatedStatus:"",
    isTicketLoading: false,
    ticketData: []
};

export const returnData = (state) => {
    return {...state, isLoading: true};
};

export const  returnSuccess = (state, action) => {
    return {...state, returnData: action.data, isLoading:false};
};

export const  returnFailure = (state, action) => {
    return {...state, error: action.error, returnData: [], isLoading:false};
};

export const updateReturn = (state) => {
    return {...state, updatedStatus: ""};
};

export const updateReturnSuccess = (state, action) => {
    return {...state, updatedStatus: action.data ? "success" : "failed"};
};

export const updateReturnFailure = (state, action) => {
    return {...state, error: action.error, updatedStatus: "failed"};
};

export const getTicketsStart = (state) => {
    return {...state, isTicketLoading: true};
}

export const getTicketsSuccess = (state, action) => {
    return {...state, isTicketLoading: false, ticketData: action.data};
}

export const getTicketsFailure = (state, action) => {
    return {...state, isTicketLoading: false, ticketData: []};
}

export const handlers = {
    [returnTypes.RETURN_START]: returnData,
    [returnTypes.RETURN_SUCCESS]:  returnSuccess,
    [returnTypes.RETURN_FAILURE]:  returnFailure,
    [returnTypes.UPDATE_RETURN_START]: updateReturn,
    [returnTypes.UPDATE_RETURN_SUCCESS]: updateReturnSuccess,
    [returnTypes.UPDATE_RETURN_FAILURE]: updateReturnFailure,
    [returnTypes.GET_TICKETS_START]: getTicketsStart,
    [returnTypes.GET_TICKETS_SUCCESS]: getTicketsSuccess,
    [returnTypes.GET_TICKETS_FAILURE]: getTicketsFailure
};

export default createReducer(returnContent, handlers);
