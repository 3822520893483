import React, {FC} from 'react';
import { Modal } from 'semantic-ui-react'
import i18next from "i18next";
import { CSVLink } from "react-csv";

interface Props {
    open: boolean,
    data: any,
    handleClose: ()=>void,
}

const ExportUnknownBarcodes: FC<Props> = ({
    open, 
    data,
    handleClose,
}) => {
    return (
            <Modal
                className="_modal--content"
                size="tiny"
                closeIcon
                open={open}
                onClose={handleClose}
            >
                <Modal.Header>{i18next.t('Terminal.unknown.barcode')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className={`modal-desc text-left`}>
                            {
                                data.map(el => <div key={el}>{el}</div>)
                            }
                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <div className="modal-actions">
                        <CSVLink
                            filename='unknown-barcode.csv'
                            className="btn_1 w-50 blue_btn"
                            data={data.map(barcode => ({barcode}))}
                            headers={[{ label: i18next.t('Terminal.unknown.barcode'), key: "barcode" }]}
                            onClick={handleClose}
                            separator={';'}
                        >
                            {i18next.t('Terminal.dashboards.exporter')}
                        </CSVLink>
                        <button
                            className="accept-button"
                            name="bouton" onClick={handleClose}>Ok
                        </button>
                    </div>
                </Modal.Actions>
            </Modal>
    )
}

export default ExportUnknownBarcodes;
