import React, { Component } from 'react';
import i18next from 'i18next';
import { Checkbox, Modal } from 'semantic-ui-react';
import {
    getCurrency,
    replaceComma,
    replaceDote,
} from '../../../../helpers/helpers';
import CustomBtn from '../../../common/customBtn';

interface Props {
    openModal: boolean;
    handleClose: any;
    handleSubmit: any;
    userPayData: any;
    payDisabled: boolean;
    emails: any;
}

class PaymentConfirm extends Component<Props> {
    render() {
        const { openModal, userPayData, payDisabled, emails } = this.props;

        return (
            <>
                <Modal
                    className="_modal--content payment-modal"
                    size="tiny"
                    closeIcon
                    open={openModal}
                    onClose={this.props.handleClose}
                >
                    <Modal.Header>
                        {i18next.t('Terminal.confirm.10')}
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="modal-desc">
                                <p>
                                    <span>{`${i18next.t(
                                        'Terminal.Receipt.Sale.Total',
                                    )}: `}</span>
                                    <span>
                                        {replaceComma(
                                            userPayData.priceAfterTaxes,
                                        )}
                                        {getCurrency()}
                                    </span>
                                </p>
                                <p>
                                    <span>{`${i18next.t(
                                        'Terminal.Receipt.Sale.saleQuantity',
                                    )}: `}</span>
                                    <span>
                                        {replaceDote(userPayData.totalQuantity)}
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        {userPayData.sumAmount > 0
                                            ? i18next.t('Terminal.payment.1')
                                            : userPayData.isAvoir
                                            ? `${i18next.t(
                                                  'Terminal.Receipt.Gift.Avoir',
                                              )}: `
                                            : i18next.t('Terminal.payment.7')}
                                    </span>
                                    <span>
                                        {replaceComma(userPayData.sumAmount)}
                                        {getCurrency()}
                                    </span>
                                </p>
                                {userPayData.cash && (
                                    <p>
                                        <span>{`${i18next.t(
                                            'Terminal.payment.8',
                                        )} `}</span>
                                        <span>
                                            {replaceComma(userPayData.cash)}
                                            {getCurrency()}
                                        </span>
                                    </p>
                                )}
                                <p
                                    className={`${
                                        userPayData.change !== 0
                                            ? 'color--red'
                                            : ''
                                    }`}
                                >
                                    <span>{`${i18next.t(
                                        'Terminal.payment.2',
                                    )} `}</span>
                                    <span>
                                        {replaceComma(userPayData.change)}
                                        {getCurrency()}
                                    </span>
                                </p>

                                <label
                                    className="mr-1 cursor"
                                    htmlFor="email-check"
                                >
                                    {i18next.t('Terminal.dashboards.email')}
                                </label>
                                <Checkbox
                                    onChange={(e, { checked }) =>
                                        emails.onChange(checked)
                                    }
                                    checked={emails.checked}
                                    className="radioLabel"
                                    id="email-check"
                                />
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <div className="modal-actions">
                            <button
                                onClick={this.props.handleClose}
                                className="close-button"
                            >
                                {i18next.t('Terminal.confirm.5')}
                            </button>
                            <CustomBtn
                                handleClick={this.props.handleSubmit}
                                classN="accept-button"
                                name="bouton"
                                loading={payDisabled}
                            >
                                {i18next.t('Terminal.confirm.13')}
                            </CustomBtn>
                        </div>
                    </Modal.Actions>
                </Modal>
            </>
        );
    }
}

export default PaymentConfirm;
