import React from "react";
import { Loader } from "semantic-ui-react";

interface IProps {
    handleClick?: () => void,
    handleDoubleClick?: () => void,
    disabled?: boolean,
    name?: string,
    classN?: string,
    loading?: boolean,
    order?: number | undefined
}

const CustomBtn: React.FC<IProps> = ({
     handleClick=()=>{},
     handleDoubleClick=()=>{},
     disabled = false,
     name,
     classN = "",
     loading,
     children,
     order
}) => {
    if(disabled){
        return (
            <button
                type="button"
                className={`${classN} disabled`}
                disabled={true}
                {...(order && { style: { order: order } })}
            >
                {name || children}
            </button>
        )
    }
    return (
            loading ?
                <button className={classN}>
                    <Loader active inline='centered' />
                </button>
            :
                <button
                    type="button"
                    className={classN}
                    onClick={handleClick}
                    onDoubleClick={handleDoubleClick}
                    {...(order && { style: { order: order } })}
                >
                    {children}
                </button>
        
        
    )
}

export default CustomBtn
