import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { Icon, Popup } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SearchBox from '../../common/searchBox';
import {
    getActiveBasketName,
    getCurrency,
    replaceComma,
} from '../../../helpers/helpers';
import { LOCAL_STORAGE } from '../../../utils/constants/variables';
import { getStock, getStockBarcodeList, getLoading } from '../../stocks/redux/selectors';
import { StockActionCreators } from '../../stocks/redux/actions';
import TableComponent from '../../datatable/container/datatable';
import ServiceDetails from './ServiceDetails';
import PopUp from '../../popUp/popUp';

interface IProps {
    stockData?: any;
    stockStart?: any;
    barcodesList?: any;
    isLoading?: boolean;
    singleStockStart: (id: number) => void
}

const ServicesPanel: React.FC<IProps> = ({ stockData = [], stockStart, barcodesList = [], isLoading, singleStockStart }) => {
    const [isStockDetailsVisible, setIsStockDetailsVisible] = useState<boolean>(false);
    const handleAddDeliveryBasket = (data, basket) => {
    };

    useEffect(() => {
        getServices();
    }, []);

    const columns = [
        {
            name: i18next.t('Terminal.dashboards.partner'),
            selector: 'brand',
            sortable: true,
            searchable: true,
            width: '12%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.collection || row.brand}
                        </div>
                    }
                    content={row.collection || row.brand}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.service'),
            selector: 'productName',
            sortable: true,
            searchable: true,
            center: true,
            width: '22%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.productName}
                        </div>
                    }
                    content={row.productName}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.detail'),
            selector: 'barcodeName',
            sortable: true,
            searchable: true,
            center: true,
            width: '24%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.barcodeName}
                        </div>
                    }
                    content={row.barcodeName}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.surface'),
            selector: 'size',
            searchable: false,
            sortable: true,
            center: true,
            width: '12%',
        },
        {
            name: i18next.t('Terminal.dashboards.durationTitle'),
            selector: 'duration',
            sortable: true,
            searchable: false,
            center: true,
            width: '14%',
        },
        {
            name: i18next.t('product.labels.9'),
            selector: 'listPrice',
            searchable: false,
            center: true,
            width: '16%',
            cell: (row) => replaceComma(row.listPrice) + getCurrency(),
        },
        {
            selector: 'barcode',
            notShow: true,
        },
        {
            selector: 'price',
            notShow: true,
        },
        {
            selector: 'productId',
            notShow: true,
            isUnique: true,
        },
        {
            selector: 'currency',
            notShow: true,
        },
        {
            selector: 'listPrice',
            notShow: true,
        },
        {
            selector: 'promotionDetails',
            notShow: true,
        },
        {
            selector: 'promoListPrice',
            notShow: true,
        },
        {
            selector: 'color',
            notShow: true,
        },
        {
            selector: 'collection',
            notShow: true,
        },
        {
            selector: 'parentId',
            notShow: true,
        },
    ];

    const serviceStock = useMemo(
        () =>
            barcodesList.reduce((acc, item) => {
                if (item.service) {
                    acc.push(item);
                }

                return acc;
            }, []),
        [barcodesList],
    );

    const getServices = () => {
        stockStart();
    }

    const toggleStockDetails = () => {
        setIsStockDetailsVisible(!isStockDetailsVisible);
    }

    const getServiceDetail = (event) => {
        singleStockStart(event.parentId);
        toggleStockDetails();
    }

    return (
        <div>
            <TableComponent
                tableData={serviceStock}
                isLoading={isLoading}
                openPopUp={getServiceDetail}
                columns={columns}
                reloadDashboard={getServices}
                keyField={'exportStocks'}
                selectedRow={1}
                buttons={['exportStockButtons']}
                isService={true}
                selectableRows={true}
                searchPosition={'bottom'}
            ></TableComponent>

            {isStockDetailsVisible && (
                <PopUp handleClose={toggleStockDetails} classN="mid-popup">
                    <ServiceDetails />
                </PopUp>
            )}
        </div>
    );
};

const mapStateToProps = (state: any): Partial<IProps> => ({
    stockData: getStock(state),
    barcodesList: getStockBarcodeList(state),
    isLoading: getLoading(state)
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPanel);
