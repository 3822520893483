import {createActions} from 'reduxsauce';

export const {Types: QuoteTypes, Creators: QuoteActionCreators} = createActions ({
    createQuoteStart: ['body'],
    createQuoteSuccess: ['data'],
    createQuoteFailure: ['error'],
    getBasketStart: ['name'],
    basketStoreGiftsStart: null,
    resetStoreClient: ["basketName"],
    quoteStart: ["data"],
    quoteSuccess: ['data'],
    quoteFailure: ['error'],
    singleQuoteStart: ["id"],
    singleQuoteSuccess: ['data'],
    singleQuoteFailure: ['error'],
    paperReceiptQuoteStart: ["body"],
    paperReceiptQuoteSuccess: ['data'],
    paperReceiptQuoteFailure: ['error'],
    quotePrintTicketStart: ['data'],
    quotePrintTicketSuccess: ['data'],
    quotePrintTicketFailure: ['error'],
    quoteDeleteTicketStart: ['data'],
    quoteDeleteTicketSuccess: ['data'],
    quoteDeleteTicketFailure: ['error'],
});
