import React from "react";
import "./blackWindow.scss";

interface Props {
    type?: string,
    handleClick?: any
}

export default class BlackWindow extends React.Component<Props> {

    componentDidMount(){
        document.addEventListener("keydown", this.handleOnKeyDown, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleOnKeyDown, false);
    }
    handleOnKeyDown = (event) => {
        if(event.keyCode === 27) {
            if(this.props.handleClick) {
                this.props.handleClick()
            }
        }
    }
    render() {
        let classname = "";
        switch (this.props.type) {
            case "menu":
                classname = "black-window-menu";
                break;
            case "SearchingBox":
                classname = "white-window-menu";
                break;
            default:
                classname = "black-window";
        }
        return <div className={classname} onClick={this.props.handleClick} onKeyDown={this.handleOnKeyDown}></div>;
    }
}