import {createActions} from 'reduxsauce';

export const {Types: reservationMagasinTypes, Creators: reservationMagasinActionCreators} = createActions ({
    reservationMagasinStart: null,
    reservationMagasinSuccess: ['data'],
    reservationMagasinFailure: ['error'],
    reservationMagasinSingleStart: ["data"],
    reservationMagasinSingleSuccess: ['data'],
    reservationMagasinSingleFailure: ['error'],
});
