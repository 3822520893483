import {createActions} from 'reduxsauce';

export const {Types: TerminalControllerTypes, Creators: TerminalControllerActionCreators} = createActions ({
    terminalOpenStart: ["body"],
    terminalOpenSuccess: ['data'],
    terminalOpenFailure: ['error'],
    terminalCloseStart: ["body"],
    terminalCloseSuccess: ['body'],
    terminalCloseFailure: ['error'],
    clientCounterStart:["count"],
    isTerminalOpenStart: ["param"],
    storesFormStart: [null],
    printReportStart: ['reportType', 'currency'],
    printDataStart:['data', 'action', 'comment'],
});