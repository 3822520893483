import { createReducer } from 'reduxsauce';
import { DeliveriesTypes } from './actions';

export interface IDeliveriesState {
    deliveriesData?: any;
    isLoading: boolean;
    isCreateLoading: boolean;
    isUpdateLoading: boolean;
    status: string;
    createdData: any;
    barcodeList: any;
    isGetBarcodeByColisLoading: boolean
}

export const deliveriesContent: IDeliveriesState = {
    deliveriesData: [],
    isLoading: false,
    isUpdateLoading: false,
    isCreateLoading: false,
    status: '',
    createdData: {},
    barcodeList: [],
    isGetBarcodeByColisLoading: false,
};

export const getDeliveriesData = (state) => {
    return { ...state, isLoading: true };
};

export const DeliveriesSuccess = (state, action) => {
    return { ...state, deliveriesData: action.data, isLoading: false };
};

export const DeliveriesFailure = (state, action) => {
    return { ...state, error: action.error, data: [], isLoading: false };
};

export const createDeliveriesStart = (state, action) => {
    const { storeDeliveryId, updatedStats } = action.data;
    return { ...state, isCreateLoading: storeDeliveryId && updatedStats ? false : true, status: '' };
};

export const createDeliveriesSuccess = (state, action) => {
    return {
        ...state,
        isCreateLoading: false,
        status: 'success',
        createdData: action.data,
    };
};

export const createDeliveriesFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        isCreateLoading: false,
        status: 'failed',
        createdData: {},
    };
};

export const barcodeByColis = (state) => {
    return { ...state, isGetBarcodeByColisLoading: true };
};

export const getBarcodeByColisSuccess = (state, action) => {
    return { ...state, barcodeList: action.data, isGetBarcodeByColisLoading: false };
};

export const emptyBarcodeByColisSuccess = (state) => {
    return { ...state, barcodeList: [] };
};

export const getBarcodeByColisFailure = (state, action) => {
    return { ...state, error: action.error };
};

export const updateSotreDeliveryStart = (state) => {
    return { ...state, isUpdateLoading: true };
};

export const updateSotreDeliverySuccess = (state) => {
    return { ...state, isUpdateLoading: false };
};

export const updateSotreDeliveryFailure = (state) => {
    return { ...state, isUpdateLoading: false };
};

export const handlers = {
    [DeliveriesTypes.DELIVERIES_START]: getDeliveriesData,
    [DeliveriesTypes.DELIVERIES_SUCCESS]: DeliveriesSuccess,
    [DeliveriesTypes.DELIVERIES_FAILURE]: DeliveriesFailure,
    [DeliveriesTypes.CREATE_DELIVERIES_START]: createDeliveriesStart,
    [DeliveriesTypes.CREATE_DELIVERIES_SUCCESS]: createDeliveriesSuccess,
    [DeliveriesTypes.CREATE_DELIVERIES_FAILURE]: createDeliveriesFailure,
    [DeliveriesTypes.GET_BARCODE_BY_COLIS_START]: barcodeByColis,
    [DeliveriesTypes.GET_BARCODE_BY_COLIS_SUCCESS]: getBarcodeByColisSuccess,
    [DeliveriesTypes.EMPTY_BARCODE_BY_COLIS_SUCCESS]: emptyBarcodeByColisSuccess,
    [DeliveriesTypes.GET_BARCODE_BY_COLIS_FAILURE]: getBarcodeByColisFailure,
    [DeliveriesTypes.UPDATE_STORE_DELIVERIES_START]: updateSotreDeliveryStart,
    [DeliveriesTypes.UPDATE_STORE_DELIVERIES_SUCCESS]: updateSotreDeliverySuccess,
    [DeliveriesTypes.UPDATE_STORE_DELIVERIES_FAILURE]: updateSotreDeliveryFailure,
};

export default createReducer(deliveriesContent, handlers);
