import React, { useState } from 'react';
import i18next from 'i18next';
import {
    countDuplicateElementsInArray,
    readFileAsync,
} from '../../../helpers/helpers';
import FileUploader from '../../fileUploader';

interface Props {
    handleUpload: (...rest: any) => void;
    handleSelectFile?: () => void;
    disabled: boolean;
}

const ImportBarcodesBtn: React.FC<Props> = ({
    handleUpload,
    handleSelectFile = () => {},
    disabled = false,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const onFileSelect = async (file) => {
        try {
            setLoading(true);
            handleSelectFile();

            const content = await readFileAsync(file);
            const data = content.split(/\r\n|\n/);

            if (Array.isArray(data)) {
                // const barcodes = countDuplicateElementsInArray(data);
                // const length = Object.keys(barcodes).length;
                // let i = 1;
                // for (let item in barcodes) {
                //     console.log(barcodes)
                //     if (item) {
                //         await handleUpload(item, barcodes[item], i === length, undefined, undefined, true);
                //     }
                //     i++;
                // }
                let barcodeArray = data.filter((code) => code && code.trim())
                for(let i = 0; i < barcodeArray.length; i++) {
                    if (barcodeArray[i] && barcodeArray[i].trim) {
                        await handleUpload(barcodeArray[i], 1, i === barcodeArray.length - 1, undefined, undefined, true);
                    }
                }
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <FileUploader
            label={i18next.t('buttons.31')}
            accept=".txt"
            onFileSelect={onFileSelect}
            loading={loading}
            disabled={disabled}
        />
    );
};

export default ImportBarcodesBtn;
