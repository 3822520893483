import React, { Component } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popup, Tab, Table } from 'semantic-ui-react';
import { Text } from '@react-pdf/renderer';
import { getReservationMagasinData } from './../redux/selectors';
import { reservationMagasinActionCreators } from './../redux/actions';
import TableComponent from '../../datatable/container/datatable';
import PopUp from '../../popUp/popUp';
import './reservationMagasin.scss';
import {
    getCurrency,
    getDateTimestamp,
    getDateTimestampByTime,
    getUserFromToken,
    replaceComma,
} from '../../../helpers/helpers';
import {
    DEFAULT_CURRENCY_SYMBOL,
    ORDER_STATUS_CODE,
} from '../../../utils/constants/variables';
import PdfTable from '../../pdfGenerator/pdfTableBtn';
import { getAccountResource } from '../../login/redux/selectors';
import { updateOrderLoading } from '../../datatable/redux/selectors';
import CustomBtn from '../../common/customBtn';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';
import { DatatableActionCreators } from '../../datatable/redux/actions';

interface Props {
    reservationMagasinData: any;
    reservationMagasinSingleData: any;
    isLoading: boolean;
    updateStatusLoading: boolean;
    details: any;
}

interface State {
    showPopUp: boolean;
    userOrderID: number;
    itemData: any;
    showConfirmPopup: boolean
}

const TableHeaderCell = (props) => (
    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
        {props.text}
    </Table.HeaderCell>
);

const TableCell = (props) => (
    <Table.Cell className="productInfoBlock--infoBox--table--description">
        {props.text}
    </Table.Cell>
);

const AddressTab = ({ address }) => (
    <Table celled selectable className="productInfoBlock--infoBox--table">
        <Table.Header>
            <Table.Row>
                {['city', 'zip', 'country', 'address1', 'address2'].map(
                    (item) => (
                        <TableHeaderCell
                            key={item}
                            text={i18next.t(`Terminal.dashboards.${item}`)}
                        />
                    ),
                )}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            <Table.Row>
                {['city', 'zip', 'country', 'address1', 'address2'].map(
                    (item) => (
                        <TableCell key={item} text={address[item]} />
                    ),
                )}
            </Table.Row>
        </Table.Body>
    </Table>
);

class ReservationMagasin extends Component<
    Props & State & typeof reservationMagasinActionCreators
> {
    state: State = {
        showPopUp: false,
        userOrderID: 0,
        itemData: {},
        showConfirmPopup: false
    };

    columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '30%',
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '18%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.quantity'),
            selector: 'quantity',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.stock'),
            selector: 'stock',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.prix'),
            selector: 'prix',
            width: '13%',
        },
    ];

    columns: any = [
        {
            name: i18next.t('Terminal.dashboards.orderId'),
            selector: 'orderId',
            sortable: true,
            isUnique: true,
            width: '17%',
        },
        {
            name: i18next.t('Terminal.dashboards.client'),
            selector: 'clientName',
            sortable: true,
            width: '20%',
        },
        {
            name: i18next.t('Terminal.dashboards.date'),
            selector: 'orderDate',
            sortable: true,
            width: '15%',
            format: 'dd/mm/yy',
        },
        {
            name: i18next.t('Terminal.dashboards.sale'),
            selector: 'totalSales',
            sortable: true,
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.quantity'),
            selector: 'totalQuantity',
            sortable: true,
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.status'),
            selector: 'orderStatus.label',
            sortable: true,
            width: '27%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.orderStatus && row.orderStatus.label}
                        </div>
                    }
                    content={row.orderStatus && row.orderStatus.label}
                    position="bottom center"
                />
            ),
        },
        {
            selector: 'webClientId',
            notShow: true,
        },
        {
            selector: 'billAddressXrefId',
            notShow: true,
        },
        {
            selector: 'shipAddressXrefId',
            notShow: true,
        },
        {
            selector: 'orderStatus',
            notShow: true,
        },
        {
            selector: 'orderLines',
            notShow: true,
        },
    ];

    conditionalRowStyles = [
        {
            when: (row) =>
                row.orderStatus &&
                [ORDER_STATUS_CODE.DEP, ORDER_STATUS_CODE.STO].includes(
                    row.orderStatus.code,
                ),
            style: {
                backgroundColor: 'red',
            },
        },
        {
            when: (row) =>
                row.orderStatus &&
                [ORDER_STATUS_CODE.PRE].includes(row.orderStatus.code),
            style: {
                backgroundColor: 'orange',
            },
        },
        {
            when: (row) =>
                row.orderStatus &&
                [ORDER_STATUS_CODE.PIC].includes(row.orderStatus.code),
            style: {
                backgroundColor: 'blue',
            },
        },
        {
            when: (row) =>
                row.orderStatus &&
                row.orderStatus.code === ORDER_STATUS_CODE.WIT,
            style: {
                backgroundColor: 'green',
            },
        },
    ];

    panes1 = [
        {
            menuItem: i18next.t('Terminal.dashboards.shippingAddress'),
            render: () => (
                <Tab.Pane attached={false}>
                    {this.props.reservationMagasinSingleData &&
                    this.props.reservationMagasinSingleData.shippingAddress ? (
                        <AddressTab
                            address={
                                this.props.reservationMagasinSingleData
                                    .shippingAddress
                            }
                        />
                    ) : (
                        ''
                    )}
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.dashboards.billingAddress'),
            render: () => (
                <Tab.Pane attached={false}>
                    {this.props.reservationMagasinSingleData &&
                    this.props.reservationMagasinSingleData.billingAddress ? (
                        <AddressTab
                            address={
                                this.props.reservationMagasinSingleData
                                    .billingAddress
                            }
                        />
                    ) : (
                        ''
                    )}
                </Tab.Pane>
            ),
        },
    ];

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.client'),
            render: () => {
                const { details: { storeDetails = {} } = {} } = this.props;
                const dataPdf = this.state.itemData.orderLines.map((item) => ({
                    name: item.productNameLevel1,
                    barcode: item.barcode,
                    size: item.size,
                    quantity: item.orderQty,
                    stock: item.stock,
                    prix: replaceComma(item.listPrice) + getCurrency(),
                }));
                return (
                    <Tab.Pane attached={false}>
                        {this.props.reservationMagasinSingleData &&
                        Object.keys(this.props.reservationMagasinSingleData)
                            .length ? (
                            <Table celled selectable>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(
                                                'Terminal.dashboards.firstName',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            {
                                                this.props
                                                    .reservationMagasinSingleData
                                                    .firstName
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(
                                                'Terminal.dashboards.lastName',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            {
                                                this.props
                                                    .reservationMagasinSingleData
                                                    .lastName
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(
                                                'Terminal.dashboards.email',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            {
                                                this.props
                                                    .reservationMagasinSingleData
                                                    .email
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(
                                                'Terminal.dashboards.phone',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            {this.props
                                                .reservationMagasinSingleData
                                                .billingAddress &&
                                                this.props
                                                    .reservationMagasinSingleData
                                                    .billingAddress.phone}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                        <Tab menu={{ pointing: true }} panes={this.panes1} />
                        <div className="buttons-container d-flex">
                            <PdfTable
                                disabled={false}
                                classN="btn_1 w-50 blue_btn"
                                columns={this.columnsPdf}
                                data={dataPdf}
                                ID={this.state.itemData.orderId}
                                currentDate={getDateTimestamp(
                                    this.state.itemData.orderDate,
                                )}
                                storeName={storeDetails.name}
                                associateFirstName={getUserFromToken().fname}
                                header={this.getPdfHeader()}
                            >
                                {i18next.t('buttons.29')}
                            </PdfTable>
                            <CustomBtn
                                classN="btn_1 w-50 red_btn"
                                handleClick={this.toggleConfirmeModal}
                                disabled={
                                    this.state.itemData
                                    && this.state.itemData.orderStatus
                                    && this.state.itemData.orderStatus.code !== ORDER_STATUS_CODE.STO
                                }
                            >
                                {i18next.t('buttons.49')}
                            </CustomBtn>
                        </div>
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.product'),
            render: () => {
                return (
                    <Tab.Pane attached={false}>
                        {this.props.reservationMagasinData.length &&
                        this.state.userOrderID ? (
                            <>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.orderId',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.date',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.totalQuantity',
                                                )}
                                            />
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <TableCell
                                                text={
                                                    this.state.itemData.orderId
                                                }
                                            />
                                            <TableCell
                                                text={getDateTimestampByTime(
                                                    this.state.itemData
                                                        .orderDate,
                                                )}
                                            />
                                            <TableCell
                                                text={
                                                    this.state.itemData
                                                        .totalQuantity
                                                }
                                            />
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.Name',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.barcode',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.Size',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.quantity',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.stock',
                                                )}
                                            />
                                            <TableHeaderCell
                                                text={i18next.t(
                                                    'Terminal.dashboards.prix',
                                                    { DEFAULT_CURRENCY_SYMBOL },
                                                )}
                                            />
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.itemData.orderLines &&
                                        this.state.itemData.orderLines.length
                                            ? this.state.itemData.orderLines.map(
                                                  (elem) => (
                                                      <Table.Row
                                                          key={elem.webOrderId}
                                                      >
                                                          <TableCell
                                                              text={
                                                                  elem.productNameLevel1
                                                              }
                                                          />
                                                          <TableCell
                                                              text={
                                                                  elem.barcode
                                                              }
                                                          />
                                                          <TableCell
                                                              text={elem.size}
                                                          />
                                                          <TableCell
                                                              text={
                                                                  elem.orderQty
                                                              }
                                                          />
                                                          <TableCell
                                                              text={elem.stock}
                                                          />
                                                          <TableCell
                                                              text={`${replaceComma(
                                                                  elem.listPrice,
                                                              )}${getCurrency()}`}
                                                          />
                                                      </Table.Row>
                                                  ),
                                              )
                                            : ''}
                                    </Table.Body>
                                </Table>
                            </>
                        ) : (
                            ''
                        )}
                    </Tab.Pane>
                );
            },
        },
    ];

    getPdfHeader = () => {
        const {
            reservationMagasinSingleData: data = {},
            details: { storeDetails = {} },
        } = this.props;
        if (Object.keys(data).length) {
            const { billingAddress = {} } = data;
            const addressTitle = [];
            const address: object[] = [];
            ['city', 'zip', 'country', 'address1', 'address2'].forEach(
                (item) => {
                    addressTitle.push(i18next.t(`Terminal.dashboards.${item}`));
                    address.push(billingAddress[item]);
                },
            );
            return [
                [
                    storeDetails.name,
                    <Text style={{ textAlign: 'right' }}>
                        {getUserFromToken().fname}
                    </Text>,
                ],
                [],
                [
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 15,
                            fontWeight: 'bold',
                        }}
                    >
                        {`${i18next.t('Terminal.Receipt.Transfer.receipt')} ${
                            this.state.userOrderID
                        }`}
                    </Text>,
                ],
                [],
                [i18next.t('Terminal.dashboards.firstName'), data.firstName],
                [i18next.t('Terminal.dashboards.lastName'), data.lastName],
                [i18next.t('Terminal.dashboards.email'), data.email],
                [
                    i18next.t('Terminal.dashboards.phone'),
                    data.billingAddress && (data.billingAddress.phone || ''),
                ],
                [],
                [],
                addressTitle,
                address,
                [],
                [],
                [
                    <Text
                        style={{
                            textTransform: 'capitalize',
                            fontSize: 13,
                            fontWeight: 'bold',
                        }}
                    >
                        {`${i18next
                            .t('Terminal.Receipt.Transfer.date')
                            .toUpperCase()} ${getDateTimestampByTime(
                            Date.now(),
                        )}`}
                    </Text>,
                ],
                [
                    <Text
                        style={{
                            textTransform: 'capitalize',
                            fontSize: 13,
                            fontWeight: 'bold',
                        }}
                    >
                        {i18next.t('Terminal.Receipt.Transfer.signature')}
                    </Text>,
                ],
                [],
                [],
            ];
        }
        return [];
    };

    componentDidMount() {
        this.props.reservationMagasinStart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateStatusLoading && !this.props.updateStatusLoading) {
            this.props.reservationMagasinStart();
        }
    }

    dataTableOpenPopUp = (e) => {
        this.props.reservationMagasinSingleStart({
            webClientId: e.webClientId,
            ...(e.shipAddressXrefId && {shipAddressXrefId: e.shipAddressXrefId}),
            billAddressXrefId: e.billAddressXrefId,
        });
        this.setState({
            showPopUp: true,
            userOrderID: e.orderId,
            itemData:
                this.props.reservationMagasinData.find(
                    (item) => item.orderId == e.orderId,
                ) || {},
        });
    };

    closePopUp = () => {
        this.setState({ showPopUp: false });
    };

    toggleConfirmeModal = () => {
        const { showConfirmPopup } = this.state;
        this.setState({ showConfirmPopup: !showConfirmPopup })
    }

    updateStatusSto = () => {
        const { itemData } = this.state;
        this.props.updateOrderStatusStart(
            itemData.orderId,
            ORDER_STATUS_CODE.DEP,
            undefined,
        );
        this.setState({
            itemData: {
                ...itemData,
                orderStatus: {
                    ...(itemData.orderStatus || {}), code: "DEP" }
                }
            })
        this.toggleConfirmeModal();
    }

    render() {
        const { reservationMagasinData, isLoading } = this.props;
        const { showPopUp, showConfirmPopup } = this.state;

        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={reservationMagasinData}
                        isLoading={isLoading}
                        columns={this.columns}
                        openPopUp={this.dataTableOpenPopUp}
                        buttons={['magasinbtn']}
                        conditionalRowStyles={this.conditionalRowStyles}
                        reloadDashboard={this.props.reservationMagasinStart}
                        keyField={'reservationMagasine'}
                    />
                </div>
                {showPopUp && (
                    <PopUp handleClose={this.closePopUp}>
                        <Tab menu={{ pointing: true }} panes={this.panes} />
                    </PopUp>
                )}
                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={this.toggleConfirmeModal}
                        handleConfirm={this.updateStatusSto}
                        text={i18next.t('Terminal.modal.2')}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { reservationMagasinData, reservationMagasinSingleData, isLoading } =
        getReservationMagasinData(state);
    return {
        reservationMagasinData,
        reservationMagasinSingleData,
        isLoading,
        details: getAccountResource(state),
        updateStatusLoading: updateOrderLoading(state),
    };
};

const mapDispatchToProps = (
    dispatch,
): typeof reservationMagasinActionCreators & typeof DatatableActionCreators => {
    return bindActionCreators({...reservationMagasinActionCreators, ...DatatableActionCreators}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationMagasin);
