import React from 'react';
import i18next from "i18next";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { LABEL_COLUMN, LABEL_ROW } from '../../../utils/constants/variables';
import { arrayGroupByCount, getCurrency, priceThreeDigitSets, replaceComma } from '../../../helpers/helpers';

export const GeneretBarcodes = ({data = [], details}) => {
    const {storeDetails:{storeType = ""} = {} } = details
    return (
        <Document>
            <Page>
                {
                    arrayGroupByCount(data, LABEL_COLUMN).map((item, idx) => {
                        return (
                            <View key = {idx} style={styles.barcodeRow}>
                                {
                                    item.map((product, i) => {
                                        if(!product.barcode) {
                                            return (
                                                <View key = {i} style={[styles.barcodeList]}></View>
                                            )
                                        }

                                        const price = product.promoListPrice || product.outletPrice || product.listPrice || product.price;
                                        
                                        return (
                                            <View key = {i} style={[styles.barcodeList]}>
                                                <View>
                                                    <Text style={[{textTransform: 'uppercase', fontSize: 10, fontFamily: 'Times-Roman'}]}>{product.productName && product.productName.slice(0, 22)}</Text>
                                                    <Text style={[styles.font9]}>{`${product.size || ""}${product.size && product.brand ? " - " : ''}${product.brand ? product.brand.slice(0, 8) : ""}${product.color ? ` - ${product.color}`: ""}`}</Text>
                                                </View>
                                                <View style={styles.barcodeRow}>
                                                    <View style={{width: "55%"}}>
                                                        <Image style={[{width: "100%", height: "auto"}]} src={product.barcode}/>
                                                    </View>
                                                    <View style={{width: "45%"}}>
                                                        <View>
                                                            <View style={[styles.barcodeRow]}>
                                                                <Text style={[styles.font9, styles.w50]}>
                                                                    {i18next.t('product.labels.3')}
                                                                </Text>
                                                                <Text style={[styles.font9, styles.w50, {textAlign: 'right'}]}>
                                                                    {priceThreeDigitSets(product.promoListPrice || product.listPrice, product.currency)}
                                                                </Text>
                                                            </View>
                                                            {product.outletPrice && (
                                                                <View style={[styles.barcodeRow]}>
                                                                    <Text style={[styles.font9, styles.w50]}>
                                                                        {i18next.t('product.labels.5')}
                                                                    </Text>
                                                                    <Text style={[styles.font9, styles.w50, {textAlign: 'right'}]}>
                                                                        {priceThreeDigitSets(product.outletPrice, product.currency)}
                                                                    </Text>
                                                                </View>
                                                            )}
                                                            {product.promotionDetails && 
                                                                <View style={[styles.barcodeRow]}>
                                                                    <Text style={[styles.font9, styles.w50]}>
                                                                        {i18next.t('product.labels.6')}
                                                                    </Text>
                                                                    <Text style={[styles.font9, styles.w50, {textAlign: 'right'}]}>
                                                                        {product.promotionDetails}
                                                                    </Text>
                                                                </View>
                                                            }
                                                        </View>
                                                        {(storeType.toUpperCase() !== "O" && storeType.toUpperCase() !== "F") && product.promoListPrice &&
                                                            <View>
                                                                <View style={[styles.barcodeRow]}>
                                                                    <Text style={[styles.font9, styles.w50]}>
                                                                        {i18next.t('product.labels.3')}
                                                                    </Text>
                                                                    <Text style={[styles.font9, styles.w50, {textAlign: 'right'}]}>
                                                                        {priceThreeDigitSets(product.listPrice, product.currency)}
                                                                    </Text>
                                                                </View>
                                                                {product.promotionDetails &&
                                                                    <View style={[styles.barcodeRow]}>
                                                                        <Text style={[styles.font9, styles.w50]}>
                                                                            {i18next.t('product.labels.2')}
                                                                        </Text>
                                                                        <Text style={[styles.font9, styles.w50, {textAlign: 'right'}]}>
                                                                            {product.promotionDetails}
                                                                        </Text>
                                                                    </View>
                                                                }
                                                            </View>
                                                        }
                                                    </View>
                                                </View>
                                                <View style={[styles.barcodeRow, {width: '100%', textAlign: 'right'}]}>
                                                    <Text style={[styles.text, {fontSize: 10, fontWeight: 'bold'}]}>
                                                        { `${i18next.t('product.labels.1')}: ${price ? `${replaceComma(price)} ${getCurrency((product.currency))}` : ""}`}
                                                    </Text>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        )
                    })
                }
            </Page>
        </Document> 
    );
}
  
  
  const styles = StyleSheet.create({
    body: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingHorizontal: 15,
    },
    view: {
        width: '100%',
    },
    text: {
      fontSize: 10,
      fontFamily: 'Times-Roman'
    },
    barcodeList: {
        width: 210/LABEL_COLUMN + "mm",
        height: 297/LABEL_ROW + "mm",
        paddingVertical: 3,
        paddingHorizontal: 5
    },
    barcodeRow: {
        display: "flex",
        flexDirection: "row",
    },
    font9: {
        fontSize: 9,
        fontFamily: 'Times-Roman'
    },

    productTitle: {
      fontSize: 12,
      padding: 2,
      fontFamily: 'Times-Roman'
    },
    product: {
      fontSize: 11,
      padding: 2,
      fontFamily: 'Times-Roman'
    },
    textBold: {
      fontSize: 13,
      fontWeight: 'bold',
      paddingLeft: 3
    },
 
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    },
    cell: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        alignSelf: "stretch"
    },
    border: {
        borderColor: "#7b7b7b",
        borderStyle: "solid",
        borderWidth: 2,
    },
    bgGraye: {
        backgroundColor: '#ccc'
    },
    w15: {
        width: '15%',
    },
    w20: {
        width: '20%',
    },
    w35: {
        width: '35%',
    },
    w50: {
        width: '50%',
    }
  });
