import frtimeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import frgregorian from 'cldr-data/main/fr/ca-gregorian.json';
import frnumbers from 'cldr-data/main/fr/numbers.json';

import ittimeZoneNames from 'cldr-data/main/it/timeZoneNames.json';
import itgregorian from 'cldr-data/main/it/ca-gregorian.json';
import itnumbers from 'cldr-data/main/it/numbers.json';

import detimeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import degregorian from 'cldr-data/main/de/ca-gregorian.json';
import denumbers from 'cldr-data/main/de/numbers.json';

import entimeZoneNames from 'cldr-data/main/en/timeZoneNames.json';
import engregorian from 'cldr-data/main/en/ca-gregorian.json';
import ennumbers from 'cldr-data/main/en/numbers.json';

export const getCalendarLocal = (languagePrefix: string = "fr") => {
    const locals = {
        fr: {
            timeZoneNames: frtimeZoneNames,
            gregorian: frgregorian,
            numbers: frnumbers,
        },
        it: {
            timeZoneNames: ittimeZoneNames,
            gregorian: itgregorian,
            numbers: itnumbers,
        },
        de: {
            timeZoneNames: detimeZoneNames,
            gregorian: degregorian,
            numbers: denumbers,
        },
        en: {
            timeZoneNames: entimeZoneNames,
            gregorian: engregorian,
            numbers: ennumbers,
        }
    }

    return locals[languagePrefix] || locals.fr;
}
