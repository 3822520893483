import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { DatatableActionCreators } from '../../datatable/redux/actions';
import CustomBtn from '../../common/customBtn';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';
import { ORDER_STATUS_CODE, TRANSFER_STATUS_CODE } from '../../../utils/constants/variables';
import { getUserFromToken } from '../../../helpers/helpers';
import { getAccountResource } from '../../login/redux/selectors';
import { updateOrderLoading } from '../../datatable/redux/selectors';

interface IProps {
    dispatch: any;
    data: any;
    details: any;
    orderLoading: boolean;
}

interface IState {
    showConfirmPopup: boolean;
    status: string;
    title: string;
}

class MagasinBtn extends React.Component<IProps, IState> {
    state: IState = {
        showConfirmPopup: false,
        status: '',
        title: '',
    };

     transferBody = () => {
        const { data: { orderId = null, totalQuantity = null, orderLines = [] } = {} } = this.props;

        const user =  getUserFromToken();
        const productQty = totalQuantity
        const storeCodeSend = this.props.details.storeCode;
        const storeTransferProducts = orderLines.reduce((acc, item) => {
            if(item.barcode){
                const obj = {
                    barcode: item.barcode,
                    qtyRecv: item.orderQty,
                    qtySend: item.orderQty,
                    quantity: item.orderQty,
                }
                acc.push(obj)
            }
            return acc
        }, [])

        return {
            associateCodeRecv: user.code,
            associateCodeSend: user.code,
            commentRecv: `${orderId}`,
            commentSend: `${orderId}`,
            deleted: false,
            productQty,
            storeCodeSend,
            storeTransferProducts,
            storeTransferReasonCode: "WEB",
            storeTransferStatusCode: TRANSFER_STATUS_CODE.SHI,
            totalBox: 1
        }
    }

    startRequest = () => {
        const { status } = this.state;
        const isStatusVal = status === ORDER_STATUS_CODE.WIT;
        this.props.dispatch(
            DatatableActionCreators.updateOrderStatusStart(
                this.props.data.orderId,
                status,
                undefined,
                (isStatusVal && this.transferBody())
            ),
        );
        this.setState({ showConfirmPopup: false });
    };

    handleClick = (status, title) => () => {
        this.setState({ showConfirmPopup: true, status, title });
    };

    render() {
        const { data: { orderId = null, orderStatus = {} } = {}, orderLoading } = this.props;
        const { showConfirmPopup, title } = this.state;
        const disabled = !orderId || ORDER_STATUS_CODE.WIT === orderStatus.code;
        const isOrderCanceledDisabled = ["DEP", "PIC"].includes(orderStatus.code);

        return (
            <>
                {showConfirmPopup ? (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showConfirmPopup: false })
                        }
                        handleConfirm={this.startRequest}
                        text={i18next.t('Terminal.confirm.7', {
                            orderId: orderId,
                        })}
                        title={title}
                    />
                ) : (
                    ''
                )}
                <CustomBtn
                    classN="btn_1 w-100 orange_btn"
                    handleClick={this.handleClick(
                        ORDER_STATUS_CODE.PRE,
                        i18next.t('buttons.5'),
                    )}
                    disabled={
                        disabled ||
                        [ORDER_STATUS_CODE.DEP, ORDER_STATUS_CODE.PIC].includes(
                            orderStatus.code,
                        )
                    }
                >
                    {i18next.t('buttons.5')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 blue_btn"
                    handleClick={this.handleClick(
                        ORDER_STATUS_CODE.PIC,
                        i18next.t('buttons.6'),
                    )}
                    disabled={
                        disabled ||
                        [ORDER_STATUS_CODE.STO, ORDER_STATUS_CODE.PRE].includes(
                            orderStatus.code,
                        )
                    }
                >
                    {i18next.t('buttons.6')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    handleClick={this.handleClick(
                        ORDER_STATUS_CODE.WIT,
                        i18next.t('buttons.42'),
                    )}
                    disabled={disabled || isOrderCanceledDisabled}
                    loading={orderLoading}
                >
                    {i18next.t('buttons.42')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 red_btn"
                    handleClick={this.handleClick(
                        ORDER_STATUS_CODE.CAN,
                        i18next.t('buttons.7'),
                    )}
                    disabled={disabled}
                >
                    {i18next.t('buttons.7')}
                </CustomBtn>
            </>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        details: getAccountResource(state),
        orderLoading: updateOrderLoading(state)
    };
};

export default connect(mapStateToProps)(MagasinBtn);
