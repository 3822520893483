import { UserTypes, UserActionCreators } from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import axios from 'axios';
import {
    USER_AUTHENTICATE_URL_TESTNET,
    USER_AUTHENTICATE_URL_TESTNET_OPT,
    WEB_CLIENT,
    USER_AUTHENTICATE_URL_TESTNET_LOGIN,
    USER_TERMINAL_DETAILES_URL_TESTNET, ASSOCIATE_SHIFTS_END_URL, STORE_FORM
} from "../../../utils/constants/service";
import { getApiPrefix, getDomainFromUrl } from "../../../helpers/helpers";
import { SESSION_STORAGE, STORAGE_GET_FORM } from "../../../utils/constants/variables";
import { StockActionCreators } from "../../stocks/redux/actions";
import { CalendarActionCreators } from "../../calendar/redux/actions";

export function* login(params) {
    const {user} = params;

    try {
        const data = yield call(() => {
            return axios.post(`${USER_AUTHENTICATE_URL_TESTNET}?cacheBuster=${+ new Date()}`, user)
                .then(res => res.data)
        });
        yield put(UserActionCreators.loginSuccess(data));
    } catch (e) {
        yield put(UserActionCreators.loginFailure(e.message));
    }
}

export function* privateLogin(params) {
    const { body } = params;
    const headers = {
        headers: {
            Authorization: `Bearer ${body.authId}`,
        }
    };
    try {
        yield put(UserActionCreators.isPrivateLoadingStart(true));
        const data = yield call(() => {
            return axios.post(`${USER_AUTHENTICATE_URL_TESTNET_OPT}?cacheBuster=${+ new Date()}`, body, headers)
                .then(res => res.data)
        });

        yield put(UserActionCreators.privateLoginSuccess({privateToken: data.id_token}));
    } catch (e) {
        yield put(UserActionCreators.privateLoginFailure(e.message));
    } finally {
        yield put(UserActionCreators.isPrivateLoadingStart(false));
    }
}

export function* loginFromWaveum({ token }) {
    const domain = getDomainFromUrl(window.location.origin);
    document.cookie = `privateToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain};path=/`;

    token
        ? yield put(UserActionCreators.privateLoginSuccess({ privateToken: token }))
        : yield put(UserActionCreators.privateLoginFailure("Invalid token"));
}

export function* clientLogin(userData) {

    const {user} = userData;
    try {

        const headers = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("privateToken")}`
            }
        };

        const data = yield call(() => {
            return axios.post(`${USER_AUTHENTICATE_URL_TESTNET_LOGIN}`, {...user}, headers)
                .then(res => res.data)
        });
        if (data && data.idToken) {
            const headers = {
                headers: {
                    Authorization: `Bearer  ${sessionStorage.privateToken}`,
                    "Associate-Authorization": `${data.idToken}`
                }
            };

            const getForm = yield call(() => {
                return axios.get(`${STORE_FORM}?language=${getApiPrefix()}`, headers)
                    .then(res => res.data)
            });
            localStorage.setItem(STORAGE_GET_FORM,JSON.stringify(getForm))
        }
        yield put(UserActionCreators.clientLoginSuccess(data));

        yield put(CalendarActionCreators.CalendarStart());
    } catch (e) {
        yield put(UserActionCreators.clientLoginFailure(e.message));
    }
}

export function* getUserInfo() {
    try {
        const headers = {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }};

        const data = yield call(() => {
            return axios.get(WEB_CLIENT, headers)
                .then(res => res.data)
        });

        yield put(UserActionCreators.userInfoSuccess(data));
    } catch (e) {
        yield put(UserActionCreators.userInfoFailure(e.message));
    }
}

export function* getAccountResource(params) {
    const headers = {headers: {
        Authorization: `Bearer ${sessionStorage.getItem("privateToken")}`,
    }};
    const { isReload } = params;
    try {
        if (isReload) {
            yield put(StockActionCreators.adminPageLoadingStart(true))
        }
        const accountResource = yield call(() =>{
            return axios.get(USER_TERMINAL_DETAILES_URL_TESTNET, headers)
                .then(res=>res.data)
        })

        if (accountResource.id_token) {
            sessionStorage.setItem(SESSION_STORAGE.privateToken, accountResource.id_token);
        }

        axios.interceptors.request.use(function (config) {
            config.headers["x-store-code"] =  accountResource.storeDetails.storeCode;
            return config;
        });

        yield put(UserActionCreators.accountResourceSuccess(accountResource));
        if(accountResource.openingDate && !accountResource.closingDate) {
            yield put(UserActionCreators.isTerminalOpenStart(true))
            localStorage.isDetailSuccess = true;
        } else {
            localStorage.isDetailSuccess = false;
        }
        if (isReload) {
            yield put(StockActionCreators.adminPageLoadingStart(false))
        }
    } catch (e) {
        localStorage.isDetailSuccess = false;
        yield put(UserActionCreators.accountResourceFailure(e.message));
    }
}

export function* clientLogout() {
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };

        const data = yield call(() =>{
            return axios.post(ASSOCIATE_SHIFTS_END_URL, {}, headers)
                .then(res=>res.data)
        });

        yield put(UserActionCreators.clientLogoutSuccess(data));

    } catch (e) {
        yield put(UserActionCreators.clientLogoutFailure(e.message));
    }
}

export function* userSaga() {
    yield takeLatest(UserTypes.LOGIN_START, login);
    yield takeLatest(UserTypes.PRIVATE_LOGIN_START, privateLogin);
    yield takeLatest(UserTypes.USER_INFO_START, getUserInfo);
    yield takeLatest(UserTypes.CLIENT_LOGIN_START, clientLogin);
    yield takeLatest(UserTypes.ACCOUNT_RESOURCE_START, getAccountResource);
    yield takeLatest(UserTypes.CLIENT_LOGOUT_START, clientLogout);
    yield takeLatest(UserTypes.LOGIN_FROM_WAVEUM_START, loginFromWaveum);
}

export default userSaga;
