import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dropdown, Icon, Table } from 'semantic-ui-react';

import { StockActionCreators } from '../../redux/actions';
import { getAddServiceStatus, getAssociateServices, getStock } from '../../redux/selectors';
import { getAccountResource } from '../../../login/redux/selectors';
import CustomBtn from '../../../common/customBtn';
import { Statuses } from '../../../../utils/constants/variables';
import InfoPopUp from '../../../common/infoPopUp';
import { PopupOptions } from '../../../../utils/constants/models';

interface IProps {
    getAssociateServiceStart: () => void;
    stockStart: () => void;
    addServicesStart: (body: any) => void;
    resetAddServiceStatus: () => void;
    associateServices: any[];
    details: any;
    stockData: any[];
    addServiceStatus: Statuses
}

const initialOptions = {
    isVisible: false,
    message: '',
    className: ''
}

const BuyService: React.FC<IProps> = ({
    getAssociateServiceStart,
    stockStart,
    addServicesStart,
    resetAddServiceStatus,
    associateServices,
    details,
    stockData,
    addServiceStatus
}) => {
    const [isNewFieldVisible, setIsNewFieldVisible] = useState<boolean>(false);
    const [services, setServices] = useState<any[]>([]);
    const [newItem, setNewItem] = useState<Record<string, any>>({});
    const [alertMessageOptions, setAlertMessageOptions] = useState<PopupOptions>(initialOptions);

    useEffect(() => {
        getAssociateServiceStart();
        stockStart();
    }, [])

    useEffect(() => {
        setServices(associateServices)
    }, [associateServices])

    useEffect(() => {
        switch(addServiceStatus) {
            case Statuses.success:
                setAlertMessageOptions({
                    isVisible: true,
                    message: i18next.t('alertMessage.2'),
                    className: 'successMessage'
                })
                break;
            case Statuses.failure:
                setAlertMessageOptions({
                    isVisible: true,
                    message: i18next.t('alertMessage.1'),
                    className: 'errorMessage'
                })
                break;
            default:
                break;
        }
    }, [addServiceStatus])

    const getAssociateByCode = (code) => {
        const { storeAssociates = [] } = details;
        const associate = storeAssociates.find((associate) => associate.associateCode === code);

        return associate ? `${associate.associateFirstName} ${associate.associateLastName}` : '';
    }

    const getProductNameById = (productId) => {
        const product = stockData.find((product) => product.productId === productId);

        return product ? product.productName : '';
    }

    const associateOptions = useMemo(() => {
        const { storeAssociates = [] } = details;
        return storeAssociates.map((associate) => {
            return {
                key: associate.id,
                text: `${associate.associateFirstName} ${associate.associateLastName}`,
                value: associate.associateCode,
            }
        })
    }, [details])

    const serviceOptions = useMemo(() => {
        return stockData.reduce((acc, service, index) => {
            if (service.service) {
                service.productName && acc.push({
                    key: index,
                    text: service.productName,
                    value: service.productId,
                })
            }
            return acc;
        }, [])
    }, [associateServices])

    const addNewItem = () => {
        if (!newItem.productId || !newItem.associateCode) return;
        setServices([{
            ...newItem,
            deleted: false,
            priority: 0,
        }, ...services]);
        setNewItem({});
        setIsNewFieldVisible(false);
    }

    const changeNewItemData = (key, _, event) => {
        const { value } = event;
        setNewItem({
            ...newItem,
            [key]: value
        })
    }

    const setDelete = (service) => {
        const serviceClone = [...services];
        const selectedIndex = serviceClone.findIndex(
            (item) => item.associateCode === service.associateCode && item.productId === service.productId
        )

        if (selectedIndex > -1) {
            serviceClone[selectedIndex].deleted = true;
        }

        setServices(serviceClone);
    }

    return (
        <div>
            <div className='top-right-buttons'>
                <CustomBtn
                    classN="btn_1 withoutHover"
                    handleClick={() => setIsNewFieldVisible(true)}
                >{i18next.t('buttons.56')}</CustomBtn>
                <CustomBtn
                    classN="btn_1 blue_btn withoutHover"
                    handleClick={() => addServicesStart(services)}
                >{i18next.t('Terminal.dashboards.save')}</CustomBtn>
            </div>
            <Table
                celled
                selectable
                className="productInfoBlock--infoBox--table collaps"
            >
                <Table.Header>
                    <Table.Row>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.service')}</Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.serviceProvider')}</Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title"></Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {isNewFieldVisible && (
                        <Table.Row>
                            <Table.Cell
                                className="productInfoBlock--infoBox--table--description cursor"
                            >
                                <Dropdown
                                    className="popUp--body--card--body--data--description bold"
                                    fluid
                                    selection
                                    options={serviceOptions}
                                    value={newItem.productId || ''}
                                    placeholder={''}
                                    onChange={changeNewItemData.bind(null, 'productId')}
                                />
                            </Table.Cell>
                            <Table.Cell
                                className="productInfoBlock--infoBox--table--description cursor"
                            >
                                <Dropdown
                                    className="popUp--body--card--body--data--description bold"
                                    fluid
                                    selection
                                    options={associateOptions}
                                    value={newItem.associateCode || ''}
                                    placeholder={''}
                                    onChange={changeNewItemData.bind(null, 'associateCode')}
                                />
                            </Table.Cell>
                            <Table.Cell
                                className="productInfoBlock--infoBox--table--description cursor"
                            >
                                <CustomBtn
                                    classN="btn_1"
                                    handleClick={addNewItem}
                                >
                                    {i18next.t('Terminal.terminalClose.add')}
                                </CustomBtn>
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {services.map((service, index) => (
                        <Table.Row
                            className={service.deleted === true ? 'message-red' : ''}
                            key={index}
                        >
                            <Table.Cell className="productInfoBlock--infoBox--table--title">{getProductNameById(service.productId)}</Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">{getAssociateByCode(service.associateCode)}</Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                <Icon
                                    name="trash alternate"
                                    className='destory-basket-icon cursor'
                                    style={{color: "#951818"}}
                                    onClick={() => setDelete(service)}
                                ></Icon>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <InfoPopUp
                open={alertMessageOptions.isVisible}
                message={alertMessageOptions.message}
                classN={alertMessageOptions.className}
                handleClose={() => {
                    setAlertMessageOptions(initialOptions);
                    resetAddServiceStatus();
                }}
            />
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        associateServices: getAssociateServices(state),
        details: getAccountResource(state),
        stockData: getStock(state),
        addServiceStatus: getAddServiceStatus(state)
    }
};

const mapDispatchToProps = (dispatch): typeof StockActionCreators  => {
    return bindActionCreators(Object.assign({}, {
        getAssociateServiceStart: StockActionCreators.getAssociateServiceStart,
        stockStart: StockActionCreators.stockStart,
        addServicesStart: StockActionCreators.addServicesStart,
        resetAddServiceStatus: StockActionCreators.addServicesStatusReset
    }), dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyService);
