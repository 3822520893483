import { createActions } from 'reduxsauce';

export const { Types: PaymentTypes, Creators: PaymentActionCreators } =
    createActions({
        createStoreStart: ['body', 'gift'],
        createStoreSuccess: ['data'],
        createStoreFailure: ['error'],
        getBasketStart: ['name'],
        basketStoreGiftsStart: null,
        resetStoreClient: ['basketName'],
        createStoreGiftStart: ['body', 'isPay'],
        storeClientStart: ['clientId', 'basketName'],
        getCurrentDaySaleStart: null,
        getCurrentDaySaleSuccess: ['data'],
        getCurrentDaySaleFailure: ['error'],
        printTicketStart: ['data'],
        setDeFiscalTransactionStart: ['data'],
        salesStart: ['data'],
        createStoreSaleFiscalStart: ['data', 'gift'],
        createStoreSaleFiscalSuccess: ['data'],
        createStoreSaleFiscalFailure: ['error'],
        createAtStoreSaleStart: ['body', 'gift'],
    });
