import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

interface IProps {
    data: any;
    keyName: string;
    textName: any[];
    valueName: string;
    placeholder?: string;
    onChange?: (arg?: any) => void;
    value?: string | number;
    classN?: string;
    disabledOption?: string;
    optionClass?: object;
    search?: any;
    disabled?: boolean;
    separate?: any[];
    loading?: boolean;
}

interface IState {
    options: any;
    isFetching: boolean;
    multiple: boolean;
    value: string | number;
}

class AutocompleteDropdown extends Component<IProps, IState> {
    state: IState = {
        options: [],
        isFetching: true,
        multiple: false,
        value: '',
    };

    componentDidMount(): void {
        if (this.props.data && this.props.data.length) {
            const options = this.getOptions(this.props);
            this.setState({
                options,
                isFetching: false,
                value: this.props.value || '',
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevProps.data &&
                !prevProps.data.length &&
                this.props.data &&
                this.props.data.length) ||
            prevProps.loading !== this.props.loading
        ) {
            const options = this.getOptions(this.props);
            this.setState({
                options,
                isFetching: this.props.loading || false,
                value: this.props.value || '',
            });
        }
        if (
            this.props.disabledOption &&
            prevProps.disabledOption !== this.props.disabledOption
        ) {
            const options = this.getOptions(this.props);
            this.setState({ options });
        }

        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value || '' });
        }
    }

    getOptionsText = (item = {}, textName = []) => {
        const { separate } = this.props;
        return textName.reduce((acc, val, idx) => {
            return `${acc} ${item[val] || ''}${
                separate && item[val] && item[textName[idx + 1]]
                    ? separate[idx]
                    : ''
            }`;
        }, '');
    };

    getOptions = ({
        data,
        keyName,
        textName,
        valueName,
        disabledOption,
        optionClass,
    }: any) => {
        return data.map((currentValue) => ({
            key: currentValue[keyName],
            text: this.getOptionsText(currentValue, textName),
            value: currentValue[valueName],
            disabled:
                !!disabledOption && disabledOption === currentValue[valueName],
            className:
                optionClass && eval(optionClass.action) ? optionClass.name : '',
        }));
    };

    handleChange = (e, { value }) => {
        this.setState(
            { value },
            () => this.props.onChange && this.props.onChange(this.state.value),
        );
    };

    render() {
        const { placeholder, classN, search, disabled } = this.props;
        const { multiple, options, isFetching, value } = this.state;

        return (
            <Dropdown
                fluid
                selection
                search={search}
                multiple={multiple}
                options={options}
                value={value}
                placeholder={placeholder || ''}
                onChange={this.handleChange}
                disabled={isFetching || disabled}
                loading={isFetching}
                className={classN || ''}
            />
        );
    }
}

export default AutocompleteDropdown;
