import { createActions } from 'reduxsauce';

export const {Types: AppointmentsTypes, Creators: AppointmentsActionCreators} = createActions({
    getServiceCalendarStart: null,
    getServiceCalendarSuccess: ['data'],
    getServiceCalendarFailure: ['error'],
    getStoreClientStart: ['id'],
    getStoreClientSuccess: ['data'],
    getStoreClientFailure: ['error'],
    getServiceCalendarIdStart: ['id'],
    getServiceCalendarIdSuccess: ['data'],
    getServiceCalendarIdFailure: ['error'],
});
