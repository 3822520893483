import React from "react";
import {Link, withRouter} from 'react-router-dom';
import i18next from "i18next";
import {MetroSpinner} from "react-spinners-kit";
import Photo from "../../common/product/photo";
import {ProductsActionCreators} from "../redux/actions";
import {IProductsState} from "../redux/reducers";
import {getCurrency, getLangPrefix, replaceComma} from "../../../helpers/helpers";
import {BASE_API_GATEWAY} from "../../../utils/constants/service";
import { PRODUCT_TYPES } from "../../../utils/constants/variables";
import "./products.scss";
import DiscountDetail from "../../common/product/discountDetail";


interface Props extends IProductsState {
    loading: boolean,
    filters: {},
}

interface State {
}

class ProductList extends React.Component<Props & typeof ProductsActionCreators, State> {

    state = {
        activeIndex: -1,
        width: window.innerWidth,
    };

    handleClick = (e, titleProps) => {
        window.scrollTo(0, 0);
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        this.setState({activeIndex: newIndex})
    };

    componentDidMount(): void {
        window.addEventListener("resize", this.handleResize);
        window.scrollTo(0, 0);
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = e => {
        this.setState({
            width: window.innerWidth
        })
    };


    getProductCategoryName = (item) => {
        const found = item.find(element => element.searchFilter === 'Collection');
        const categoryName = found ? found.values[0].value : '';
        return categoryName;
    };

    getFilteredList = () => {
        const unique = Array.from(new Set(this.props.list && this.props.list.content && this.props.list.content.map(a => a.productId))).map(id => {
            return this.props.list && this.props.list.content && this.props.list.content.find(a => a.productId === id)
        })
        return unique
    }

    render(): React.ReactNode {
        const {width} = this.state;
        const {list, handleClick, menuItem, loading, resetFiltre} = this.props;
        const path = this.props.location.pathname.includes("filtres") ? this.props.location.pathname.split("/filtres")[0] : this.props.location.pathname;
        let isMobile = width < 920;
        const visuals = menuItem && menuItem.visuals ? menuItem.visuals[0] : '';
        const LANGUAGE_PREFIX = getLangPrefix();
        const { productType } = menuItem;

        return (
            <>
                <MetroSpinner size={50}
                              color="#686769"
                              loading={loading}/>

                <div className="product-content">
                    <div className="articles-section">
                        <p className="text">
                        <span
                            className="articles-qty">{(list.content && list.content.length > 0) && list.content.length}</span>
                            articles
                        </p>
                    </div>
                    {
                        list && list.content && list.content.length ? (
                            this.getFilteredList().map((product, index) => {
                                    return (productType !== PRODUCT_TYPES.c && productType !== PRODUCT_TYPES.sc) ? (
                                        <div key={`${product.productId}_${index}`}
                                             className={`advertisingBox ${index === 6 && visuals && visuals.type === "CATALOGUE" ? "currentAdvertising" : ""} ${productType === PRODUCT_TYPES.s ? "fourColums" : ""} ${product.onDisplay === "Y" && !isMobile ? 'opened' : ""}`}>
                                            <div
                                                className={`product-item small product-content-sm-product  ${product.onDisplay === "Y" && !isMobile && 'opened'}`}>
                                                <Link className="link" to={`${path}/${product.canonicalUrl}`}>
                                                    <div className="product-item-img">
                                                        <Photo src={product.photo}
                                                               discountDetail={""}
                                                               onSpecial={""}
                                                               thumbnail={product.thumbnail}
                                                               alt={product.name}

                                                        />
                                                        
                                                        <DiscountDetail product={product} />

                                                        <div className="product-item-img-text">
                                                            <h2 className="product-title">{product.name}</h2>
                                                            <p className="product-categorye-name">
                                                                {
                                                                    product.attributes &&
                                                                    product.attributes.length &&
                                                                    this.getProductCategoryName(product.attributes)
                                                                }
                                                            </p>
                                                            <p className="product-price">
                                                                {i18next.t('catalogue.product.3')}
                                                                {
                                                                    (product.listPriceAfterDiscount) ?
                                                                        <span>
                                                                            <span className='old-price'>{replaceComma(product.startingPrice)}</span>
                                                                            <span className='new-price'> {replaceComma(product.listPriceAfterDiscount)} {getCurrency(product.currency)}</span>
                                                                        </span> :
                                                                        <span><span className='new-price'> {replaceComma(product.startingPrice)} {getCurrency(product.currency)}</span></span>
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="info-box">
                                                        <div className="front">
                                                            <h2 className="product-title">{product.name}</h2>
                                                            <p className="product-categorye-name">
                                                                {
                                                                    product.attributes &&
                                                                    product.attributes.length &&
                                                                    this.getProductCategoryName(product.attributes)
                                                                }
                                                            </p>
                                                            <div className="info-box-lg-content">
                                                                <p className="text">
                                                                    {
                                                                        product.attributes &&
                                                                        product.attributes.length &&
                                                                        this.getProductCategoryName(product.attributes)
                                                                    }
                                                                </p>
                                                                <p className="text multiline-ellipsis"
                                                                   dangerouslySetInnerHTML={{__html: product.description.substring(0, 92) + "..."}}>
                                                                </p>
                                                            </div>
                                                            <p className="product-price">{i18next.t('catalogue.product.3')}
                                                                {
                                                                    (product.listPriceAfterDiscount) ?
                                                                        <span>
                                                                            <span className='old-price'>{replaceComma(product.startingPrice)}</span>
                                                                            <span className='new-price'>{replaceComma(product.listPriceAfterDiscount)} {getCurrency(product.currency)}</span>
                                                                        </span> :
                                                                        <span><span className='new-price'> {replaceComma(product.startingPrice)} {getCurrency(product.currency)}</span></span>
                                                                }
                                                            </p>
                                                            <div className="info-box-lg-content">
                                                                <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                                <span className="title-brd-image"></span>
                                                            </div>
                                                        </div>
                                                        <div className="back">
                                                            <p className="btt">{i18next.t("catalogue.product.5")}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            {
                                                index === 6 && visuals && visuals.type === "CATALOGUE" &&
                                                (
                                                    <div className={`product-item small product-content-sm-product`}>
                                                        <Link to={`${LANGUAGE_PREFIX}${visuals.menuHref}`}
                                                              className=" advertising">
                                                            <img src={`${BASE_API_GATEWAY}${visuals.photo}`} alt="PHOTO"/>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <React.Fragment key={`${product.productId}_${index}`}>
                                            <div className={`advertisingBox  ${productType === PRODUCT_TYPES.s ? "fourColums" : ""} ${product.onDisplay == "Y" && !isMobile ? `opened` : ""}`}>
                                                <div
                                                    className={`product-item product-style-two ${product.onDisplay == "Y" && !isMobile && `opened`} `}>
                                                    <Link className="link"
                                                          to={`${path.substring(path.lastIndexOf("/") + 1) == "catalogue" ? path.substring(0, path.lastIndexOf("/")) : path}/${product.canonicalUrl}`}>
                                                        <div className="product-item-img">
                                                            
                                                            <Photo src={product.photo}
                                                                   discountDetail={""}
                                                                   onSpecial={""}
                                                                   thumbnail={product.thumbnail}
                                                                   alt={product.name}

                                                            />
                                                            
                                                            <DiscountDetail product={product} />

                                                            <div className="product-item-img-text">
                                                                <h2 className="product-title">{product.name}</h2>
                                                                <p className="product-categorye-name">
                                                                    {
                                                                        product.attributes &&
                                                                        product.attributes.length &&
                                                                        this.getProductCategoryName(product.attributes)
                                                                    }
                                                                </p>
                                                                <p className="product-price">
                                                            <span
                                                                className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                                                    {
                                                                        (product.listPriceAfterDiscount) ?
                                                                            <span>
                                                                                <span className='old-price'>{replaceComma(product.startingPrice)}</span>
                                                                                <span className='new-price'>{replaceComma(product.listPriceAfterDiscount)} {getCurrency(product.currency)}</span>
                                                                            </span> :
                                                                            <span><span className='new-price'>{replaceComma(product.startingPrice)} {getCurrency(product.currency)}</span></span>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="info-box">
                                                            <div className="front">
                                                                <h2 className="product-title">{product.name}</h2>
                                                                <p className="product-categorye-name">
                                                                    {
                                                                        product.attributes &&
                                                                        product.attributes.length &&
                                                                        this.getProductCategoryName(product.attributes)
                                                                    }
                                                                </p>
                                                                <div className="info-box-lg-content">
                                                                    <p className="text">
                                                                        {
                                                                            product.attributes &&
                                                                            product.attributes['General Material'] &&
                                                                            product.attributes['General Material'].length &&
                                                                            product.attributes['General Material'][0]
                                                                        }
                                                                    </p>
                                                                    <p className="text multiline-ellipsis"
                                                                       dangerouslySetInnerHTML={{__html: product.description.substring(0, 92) + "..."}}></p>
                                                                </div>
                                                                <p className="product-price">
                                                            <span
                                                                className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                                                    {
                                                                        (product.listPriceAfterDiscount) ?
                                                                            <span>
                                                                                <span className='old-price'>{replaceComma(product.startingPrice)}</span>
                                                                                <span className='new-price'>{replaceComma(product.listPriceAfterDiscount)} {getCurrency(product.currency)}</span>
                                                                            </span> :
                                                                            <span> <span className='new-price'>{replaceComma(product.startingPrice)} {getCurrency(product.currency)}</span></span>
                                                                    }
                                                                </p>
                                                                <div className="info-box-lg-content">
                                                                    <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                                    <span className="title-brd-image"></span>
                                                                </div>
                                                            </div>
                                                            <div className="back">

                                                                <p className="btt">{i18next.t("catalogue.product.5")}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            {index === 4 && visuals && visuals.type === "CATALOGUE" &&
                                                (
                                                    <div className={`advertisingBox`}>
                                                        <div className={`product-item product-style-two `}>
                                                            <Link to={`${LANGUAGE_PREFIX}${visuals.menuHref}`}
                                                                  className="advertising">
                                                                <img src={`${BASE_API_GATEWAY}${visuals.photo}`} alt="PHOTO"/>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                    )
                                }
                            )
                        ) : resetFiltre ?
                            <div className="errorBox"><h1 className="errorBox--Message">Aucun produit pour cette
                                sélection</h1></div> : null
                    }
                    {
                        list && list.content && list.content.length ?
                            <div className="filter-content">
                                <button className='filter-btn' onClick={handleClick}>filtrer (0)</button>
                            </div> : null
                    }
                </div>
            </>
        )
    }
}

export default withRouter(ProductList);
