import React, { useEffect, useState } from "react";
import { Input, Table } from "semantic-ui-react";
import i18next from "i18next";
import { NullBelegActionCreators } from "../../../nullBelegBtn/redux/actions";
import { Wallet } from "./wallet";
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL, PAYMENT_CODES, STORE_COUNTRIES, STORE_COUNTRY } from '../../../../utils/constants/variables';
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import { fixedInHundredth, getItemArrayOfObject, isLastDayMonth, replaceComma } from "../../../../helpers/helpers";
import InfoPopUp from "../../../common/infoPopUp";
import CustomBtn from "../../../common/customBtn";
import { MenuActionCreators } from "../../../../layouts/main/header/redux/actions";

interface IProps {
    details: any,
    handelChangeWallet: (param) => void,
    submit: (param?: any) => void,
    paymentsReportData: any,
    isTerminalOpen: boolean,
    counterClients: number,
    comment: string,
    isLoading: boolean,
    isDisabled: any,
    status: string,
    altWallet: any,
    wallet: any,
    walletPieces: any,
    altWalletPieces: any,
    terminalError: string,
    isStoreIds?: boolean,
    altTotal?: string | number,
    total?: string | number,
    dispatch: (data) => void,
    totalPrice?: number | null
}

const TerminalStep3: React.FC<IProps> = ({
    handelChangeWallet, details, isTerminalOpen, counterClients, comment, isLoading, isDisabled, isStoreIds,
    submit, status, altWallet, wallet, walletPieces, altWalletPieces, terminalError, paymentsReportData, total, altTotal, dispatch,
    totalPrice = null
}: IProps) => {
    const [show, setShow] = useState(false);
    const [showClose, setShowClose] = useState(false);
    const [required, setRequired] = useState(false);
    const [showInfoPopUp, setInfoPopUp] = useState(false);
    const [deposit, setDeposit] = useState<any>({
        [DEFAULT_CURRENCY]: {},
        [details.altCurrency]: details.altCurrency ? {} : null
    })

    useEffect(() => {
        const { currency = '', altCurrency = '' } = details;
        if (isTerminalOpen) {
            [currency, altCurrency].forEach(currency => {
                if (currency && paymentsReportData[currency]) {
                    let creditCardDeposit: string | number = 0;
                    let amountC8 = 0;
                    const item = paymentsReportData[currency] || {};
                    const { previousDayPayment = [], previousDayDeposit = {}, previousDaySafe = {} } = item;
                    const amountC1 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c1).paymentAmount || 0;
                    const amountC2 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c2).paymentAmount || 0;
                    const amountC6 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c6).paymentAmount || 0;
                    const amountC7 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c7).paymentAmount || 0;
                    if ([STORE_COUNTRIES.it, STORE_COUNTRIES.ch].includes(STORE_COUNTRY)) {
                        const amountC10 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c10).paymentAmount || 0;

                        creditCardDeposit = replaceComma(amountC1 + amountC7 + amountC10 - previousDayDeposit.creditCardDeposit) || "0"
                    }
                    else if ([STORE_COUNTRIES.de, STORE_COUNTRIES.at].includes(STORE_COUNTRY)) {
                        const amountC4 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c4).paymentAmount || 0;
                        amountC8 = getItemArrayOfObject(previousDayPayment, 'paymentMethodCode', PAYMENT_CODES.c8).paymentAmount || 0;
                        creditCardDeposit = replaceComma(amountC1 + amountC7 + amountC4 - previousDayDeposit.creditCardDeposit) || "0"
                    }
                    else {
                        creditCardDeposit = replaceComma(amountC1 - previousDayDeposit.creditCardDeposit) || "0"
                    }

                    setDeposit(prev => ({
                        ...prev, [currency]: {
                            amexDeposit: replaceComma(amountC2 - previousDayDeposit.amexDeposit) || "0",
                            checkDeposit: replaceComma(amountC6 + amountC8 - previousDayDeposit.checkDeposit - (previousDaySafe.checkSafe || 0)) || "0",
                            creditCardDeposit,
                        }
                    }))
                }
            })
        }
    }, [paymentsReportData])

    const handleChangeComment = (e) => {
        const { name, value } = e.target;
        handelChangeWallet({ [name]: value })
    }

    const handleChangeClient = (e) => {
        const { name, value } = e.target;
        if(!value || value  >= 0) {
            handelChangeWallet({ [name]: value })
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (!value) {
            handelChangeWallet({ [name]: 0 })
        }
    }
    const handleSubmit = () => {
        if (isTerminalOpen) {
            if (required) {
                setShow(true);
                return;
            }
            dispatch(MenuActionCreators.clockStart(true));
        } else {
            if (isDisabled && +counterClients < 0) {
                setInfoPopUp(true);
                return;
            }
        }

        submit(isTerminalOpen ? deposit : {})
    }

    useEffect(() => {
        const walletTotal = [...wallet, ...walletPieces].reduce((acc, item) => acc + item.value * item.price, 0)
        setRequired(fixedInHundredth(totalPrice || 0) !== fixedInHundredth(total || walletTotal || 0) && !comment);
    }, [total, totalPrice, comment])

    return (
        <div>
            <div className="fermeturePopUp--body-form-content-input--groups">
                <div className="table-store-counter--Content">
                    <div>
                        <Wallet
                            currencySymbol={details.currencySymbol || DEFAULT_CURRENCY_SYMBOL}
                            handelChangeWallet={handelChangeWallet}
                            altWallet={false}
                            wallet={wallet}
                            walletPieces={walletPieces}
                            previousDayPayment={paymentsReportData[details.currency] ? paymentsReportData[details.currency].previousDayAmountClosing : 0}
                            isTerminalOpen={isTerminalOpen}
                            total={total}
                        />
                    </div>
                    {details.altCurrencySymbol ?
                        <div>
                            <Wallet
                                currencySymbol={details.altCurrencySymbol}
                                handelChangeWallet={handelChangeWallet}
                                altWallet={true}
                                wallet={altWallet}
                                walletPieces={altWalletPieces}
                                previousDayPayment={paymentsReportData[details.altCurrency] ? paymentsReportData[details.altCurrency].previousDayAmountClosing : 0}
                                isTerminalOpen={isTerminalOpen}
                                total={altTotal}
                            />
                        </div> : ""
                    }
                </div>
                <div className="table-store-counter--Content">
                    {!isTerminalOpen ?
                        <div className="table-store-counter--Content--box w-100">

                            <Table celled collapsing className="w-100 table-store-counter">
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t('Terminal.terminalOpeningBillets.compteurClient')}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            <Input
                                                type="number"
                                                name="counterClients"
                                                value={counterClients}
                                                onChange={handleChangeClient}
                                                onBlur={handleBlur}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            {STORE_COUNTRY === STORE_COUNTRIES.at &&
                                <div className="d-flex justify-end w-100">
                                    <CustomBtn
                                        classN={`btn_1 w-50 red_btn`}
                                        handleClick={() => dispatch(NullBelegActionCreators.printNullBelegStart())}
                                        disabled={!isLastDayMonth()}
                                        loading={isLoading}
                                    >
                                        {i18next.t('buttons.44')}
                                    </CustomBtn>
                                </div>
                            }

                        </div>
                        : ""
                    }
                    <div className="table-store-counter--Content--box comment w-100">
                        <Table celled collapsing className="w-100 table-store-counter">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t('Terminal.terminalOpeningBillets.7')}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        <textarea
                                            className={required ? "required" : ""}
                                            name="comment"
                                            rows={4}
                                            cols={30}
                                            value={comment.toUpperCase()}
                                            onChange={handleChangeComment}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>



                <div className="table-store-counter--Content--footer">
                    <CustomBtn
                        classN={`btn_1 w-100 ${isTerminalOpen
                            ? required
                                ? "disabled"
                                : ""
                            : isStoreIds
                                ? ""
                                : "disabled"
                        }`}
                        disabled={isTerminalOpen ? false : !isStoreIds || required}
                        handleClick={handleSubmit}
                        loading={isLoading}
                    >
                        {isTerminalOpen
                            ? i18next.t('Terminal.terminalOpeningBillets.openBtn')
                            : i18next.t('Terminal.terminalOpeningBillets.closeBtn')
                        }
                    </CustomBtn>
                </div>
                {
                    status === "error" &&
                    <div className='message-error'>
                        {terminalError}
                    </div>
                }
            </div>
            {show &&
                <ConfirmPopup
                    handleCancel={() => setShow(false)}
                    handleConfirm={() => {
                        setShow(false)
                        isTerminalOpen && dispatch(MenuActionCreators.clockStart(true));
                        submit(deposit)
                    }
                    }
                    text={i18next.t('Terminal.confirm.15')}
                />
            }

            {showClose &&
                <ConfirmPopup
                    handleCancel={() => setShowClose(false)}
                    handleConfirm={() => {
                        setShowClose(false)
                        submit()
                    }}
                    text={i18next.t('Terminal.confirm.16')}
                    additionText={counterClients < 0 ? i18next.t('Terminal.confirm.17') : ""}
                />
            }
            <InfoPopUp
                open={showInfoPopUp}
                classN='message-error'
                message={i18next.t('Terminal.confirm.17')}
                handleClose={() => setInfoPopUp(false)}
            />
        </div>
    )
}

export default TerminalStep3;
