import { createActions } from 'reduxsauce';

export const { Types: AdminTypes, Creators: AdminActionCreators } = createActions({
    createAssociateStart: ["body", "callback"],
    createAssociateSuccess: ["data"],
    createAssociateFailure: ["error"],
    getAssociateCalendarServiceStart: ["body"],
    getAssociateCalendarServiceSuccess: ["data"],
    getAssociateCalendarServiceFailure: ["error"],
    createAssociateServiceStart: ["body", "callback"],
    createAssociateServiceSuccess: ["data"],
    createAssociateServiceFailure: ["error"],
});
