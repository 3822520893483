import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppointmentsActionCreators } from '../redux/actions';
import { getAppointmentData, getAppointmentLoading } from '../redux/selectors';
import TableComponent from '../../datatable/container/datatable';
import { getDateTimestamp, getUserFromToken, isTerminalAdmin } from '../../../helpers/helpers';
import PopUp from '../../popUp/popUp';
import AppointmentContent from './AppointmentContent';

import './appointments.scss'
import { Popup } from 'semantic-ui-react';
import { ORDER_STATUS_CODE } from '../../../utils/constants/variables';
import { SalesActionCreators } from '../../sales/redux/actions';

interface Props {
    isLoading: boolean;
    appointmentData: any;
    getServiceCalendarStart: () => void;
    singleSalesAnnuller: () => void
}

const AppointmentDashboard: React.FC<Props> = ({
    isLoading,
    appointmentData,
    getServiceCalendarStart,
    singleSalesAnnuller
}) => {
    const [isAppointmentDetailVisible, setIsAppointmentDetailVisible] =
        useState<boolean>(false);
    const [selectedAppointment, setSelectedAppointment] = useState<any>(null);

    const columns = [
        {
            name: i18next.t('Terminal.dashboards.id'),
            selector: 'brandServiceCalendarId',
            sortable: true,
            searchable: true,
            center: true,
            width: '7%',
            isUnique: true,
        },
        {
            name: i18next.t('Terminal.dashboards.ordine'),
            selector: 'orderNumber',
            sortable: true,
            searchable: true,
            center: true,
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.operator'),
            selector: 'associateFirstName',
            sortable: true,
            searchable: true,
            center: true,
            width: '15%',
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.date'),
            selector: 'startTime',
            sortable: true,
            searchable: true,
            center: true,
            width: '15%',
            cell: (row) => getDateTimestamp(row.startTime),
        },
        {
            name: i18next.t('Terminal.dashboards.service'),
            selector: 'subject',
            searchable: false,
            sortable: true,
            center: true,
            width: '25%',
        },
        {
            name: i18next.t('Terminal.dashboards.active'),
            selector: 'startTime',
            searchable: false,
            sortable: true,
            center: true,
            width: '10%',
            cell: (row) => {
                let isYes = row.startTime > new Date().getTime();
                const { code } = row.orderStatus || { code: '' };

                if (
                    row.deleted && code === ORDER_STATUS_CODE.CAN
                    || !row.deleted && code === ORDER_STATUS_CODE.SER
                ) {
                    isYes = false
                }

                return (
                    <div
                        className={`${
                            isYes
                                ? 'color--green'
                                : 'color--red'
                        }`}
                    >
                        {isYes ? 'Y' : 'N'}
                    </div>
                )
            },
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.statusCode'),
            selector: 'orderStatus',
            searchable: false,
            sortable: true,
            center: true,
            width: '15%',
            cell: (row) => {
                let isYes;
                const { code } = row.orderStatus || { code: '' };

                if (row.deleted && code === ORDER_STATUS_CODE.CAN) isYes = false;
                if (!row.deleted && code === ORDER_STATUS_CODE.SER) isYes = true;

                return (
                    <Popup
                        inverted
                        trigger={
                            <div data-tag={`
                                ___react-data-table-allow-propagation___
                                ${isYes
                                    ? 'color--green'
                                    : isYes === false ? 'color--red' : ''
                                }
                            `}>
                                {row.orderStatus && row.orderStatus.label}
                            </div>
                        }
                        content={row.orderStatus && row.orderStatus.label}
                        position="bottom center"
                    />
                )
            },
        },
        {
            selector: 'ticketNumber',
            notShow: true,
        },
        {
            selector: 'description',
            notShow: true,
        },
        {
            selector: 'location',
            notShow: true,
        },
        {
            selector: 'endTime',
            notShow: true,
        },
        {
            selector: 'storeClientId',
            notShow: true,
        },
        {
            selector: 'deleted',
            notShow: true,
        },
    ];

    useEffect(() => {
        getCalendarData();
    }, []);

    const getCalendarData = () => {
        getServiceCalendarStart();
    };

    const data = useMemo(() => {
        const { code } = getUserFromToken() || { code: '' };
        const terminalAdmin = isTerminalAdmin();

        if (!appointmentData) return [];

        if (terminalAdmin) {
            return Object.values(appointmentData).reduce((acc: Array<any>, item) => {
                if (Array.isArray(item)) {
                    acc = [...acc, ...item];
                }
                return acc;
            }, [])
        }

        return appointmentData && appointmentData[code]
            ? appointmentData[code]
            : [];
    }, [appointmentData]);

    const showDetails = (data) => {
        setSelectedAppointment(data);
        toggleDetailsPanel();
    };

    const filterAppointmets = (appointment) => {
        return appointment.deleted ? appointment.orderNumber : true;
    }

    const toggleDetailsPanel = () => {
        setIsAppointmentDetailVisible(!isAppointmentDetailVisible);
        singleSalesAnnuller();
    }

    return (
        <div>
            <TableComponent
                tableData={data.sort((prev, current) => current.startTime - prev.startTime).filter(filterAppointmets)}
                isLoading={isLoading}
                openPopUp={showDetails}
                columns={columns}
                reloadDashboard={getCalendarData}
                keyField={'appointment'}
                selectedRow={1}
                buttons={['appointmentsButtons']}
                isService={true}
                selectableRows={true}
                searchPosition={'bottom'}
            ></TableComponent>
            {isAppointmentDetailVisible && (
                <PopUp handleClose={toggleDetailsPanel}>
                    <AppointmentContent
                        selectedAppointment={selectedAppointment}
                        closePopup={toggleDetailsPanel}
                    />
                </PopUp>
            )}
        </div>
    );
};

const mapStateToProps = (state: any): any => ({
    isLoading: getAppointmentLoading(state),
    appointmentData: getAppointmentData(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...AppointmentsActionCreators, ...SalesActionCreators}, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppointmentDashboard);
