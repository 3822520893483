import React, { useRef, useState } from 'react';
import i18next from 'i18next';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Table } from 'semantic-ui-react';

import { StockActionCreators } from '../../stocks/redux/actions';
import {
    getStock,
    getSingleStock,
    getSingleLoading,
    getProductSold,
    getProductDeliveries,
    getStockUpdateloading
} from '../../stocks/redux/selectors';
import { getAccountResource } from '../../login/redux/selectors';
import Photo from '../../common/product/photo';
import { SERVICE_EDIT, SESSION_STORAGE } from '../../../utils/constants/variables';
import { getActiveBasketName, getCurrency, getDateTimestampByTime, getLogisticStatusLabel, isTerminalAdmin, replaceComma } from '../../../helpers/helpers';
import PenToIcon from '../../icons/PenTo';
import PopUp from '../../popUp/popUp';
import UpdatePrice from '../../stocks/container/components/updatePrice';
import { updateProductVisual } from '../../../utils/constants/service';

interface IProps {
    account: any;
    stockData: any;
    singleStockData: any;
    isSingleLoading: boolean;
    productSold: any;
    productDeliveries: any;
    isStockupdateloading: any;
    updateProductPriceStart: any;
}

const ServiceDetails: React.FC<IProps> = ({
    account,
    stockData,
    singleStockData,
    isSingleLoading,
    productSold,
    productDeliveries,
    isStockupdateloading,
    updateProductPriceStart
}) => {

    const [isEditPriceVisible, setIsEditPriceVisible] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<any>(null);
    const [uploadUrl, setUploadUrl] = useState<string>('');
    const fileRef = useRef<HTMLInputElement>(null);

    const barcodeInventoryListData = (barcodeInventoryList) => {
        const storeCode = sessionStorage.getItem(SESSION_STORAGE.storeCode);
        const stockData = (barcodeInventoryList || []).reduce((acc, item) => {
            if (item.storeCode === storeCode) {
                acc.stock = item.stock;
            }
            acc.unit = item.unit ? item.unit : "";
            return acc;
        }, { stock: "", unit: "" })

        return stockData;
    }


    const editProductPhoto = () => {
        const file = fileRef.current && fileRef.current.files && fileRef.current.files[0];
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
            updateProductVisual(formData, singleStockData.id)
            .then((res) => {
                setUploadUrl(res.photoUrl)
            })
        }
    }

    const panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.service'),
            render: () => {
                const isAdmin = isTerminalAdmin();
                return (
                    <Tab.Pane attached={false}>
                        <div className="productInfoBlock">
                            <div className="productInfoBlock--imgBox">
                                {singleStockData &&
                                Object.keys(singleStockData).length ? (
                                    singleStockData.productThumbnail ? (
                                        <Photo
                                            src={
                                                uploadUrl || singleStockData.productThumbnail
                                            }
                                        />
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="productInfoBlock--infoBox">
                                <h1>
                                    {singleStockData
                                        ? singleStockData.productName
                                        : ''}
                                </h1>
                            </div>
                            {isAdmin && (
                                <PenToIcon
                                    fill="#183153"
                                    classN="cursor small-icon"
                                    onClick={() => {
                                        fileRef.current && fileRef.current.click();
                                    }}
                                />
                            )}
                            <input
                                onChange={editProductPhoto}
                                ref={fileRef} type="file"
                                hidden
                            />
                        </div>
                        {singleStockData &&
                        true ? (
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table collaps"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.barcode',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.service')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.surface')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.price')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.productEdit',
                                            )}
                                        </Table.Cell>
                                        {!singleStockData.service && (
                                            <>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t(
                                                        'Terminal.dashboards.promoPrice',
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t(
                                                        'Terminal.dashboards.reduction',
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t(
                                                        'Terminal.dashboards.ecoTax',
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.stock')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.unit')}
                                                </Table.Cell>
                                            </>
                                        )}
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.duration')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.productGroup')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.service')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.recurring')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.obligatory')}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {singleStockData.barcodes && singleStockData.barcodes.map(
                                        (item) => {
                                        const { stock } = barcodeInventoryListData(item.barcodeInventoryList);
                                        return (
                                            <Table.Row
                                                key={item.id}
                                            >
                                                <Table.Cell
                                                    className="productInfoBlock--infoBox--table--description cursor"
                                                >
                                                    {item.barcode}
                                                </Table.Cell>
                                                <Table.Cell
                                                    className="productInfoBlock--infoBox--table--description cursor"
                                                >
                                                    {item.barcodeName}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.size}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description beetwenBox">
                                                    {replaceComma(item.listPrice)}
                                                    {getCurrency()}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.outletPrice
                                                        ? replaceComma(
                                                            item.outletPrice,
                                                        ) + getCurrency()
                                                        : ''}
                                                    {isAdmin && SERVICE_EDIT !== 0 && (
                                                        <PenToIcon
                                                            fill="#183153"
                                                            classN="cursor small-icon"
                                                            onClick={togglePriceUpdate.bind(null, item)}
                                                        />
                                                    )}
                                                </Table.Cell>

                                                {!singleStockData.service && (
                                                    <>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {item.promoListPrice
                                                                ? replaceComma(
                                                                    item.promoListPrice,
                                                                )
                                                                : ''}
                                                            {item.promoListPrice
                                                                ? getCurrency()
                                                                : ''}
                                                        </Table.Cell>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {item.promotionDetails}
                                                        </Table.Cell>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {item.ecoTax
                                                                ? `${item.ecoTax
                                                                    .toString()
                                                                    .replace(
                                                                        '.',
                                                                        ',',
                                                                    )} ${getCurrency()}`
                                                                : ''}
                                                        </Table.Cell>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description beetwenBox">
                                                            <div className="productInfoBlock--infoBox--table--description--btn">
                                                                {stock}
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            <PenToIcon
                                                                fill="#183153"
                                                                classN="cursor small-icon"
                                                            />
                                                        </Table.Cell>
                                                    </>
                                                )}
                                                
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.duration}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.productGroup}
                                                </Table.Cell>
                                                <Table.Cell className={`productInfoBlock--infoBox--table--description ${item.service ? 'color--green' : 'color--red'}`}>
                                                    {item.service === true ? "Y" : item.recurring === false ? "N" : ""}
                                                </Table.Cell>
                                                <Table.Cell className={`productInfoBlock--infoBox--table--description ${item.recurring ? 'color--green' : 'color--red'}`}>
                                                    {item.recurring === true ? "Y" : item.recurring === false ? "N" : ""}
                                                </Table.Cell>
                                                <Table.Cell className={`productInfoBlock--infoBox--table--description ${item.mandatory ? 'color--green' : 'color--red'}`}>
                                                    {item.mandatory ? "Y" : "N"}
                                                </Table.Cell>
                                            </Table.Row>
                                        )},
                                    )}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                        {productDeliveries.length ? (
                            <div>
                                <h1>{i18next.t('home.bottomButtons.9')} </h1>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.date',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Deliveries.slipNumber',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.ticketStatus',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Deliveries.qtyDelivered',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Deliveries.qtyChecked',
                                                )}{' '}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {productDeliveries.map((delivery, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {getDateTimestampByTime(delivery.slipDate, 'dd/mm/yy')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {delivery.slipNumber}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {getLogisticStatusLabel(delivery.logisticStatus)}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {delivery.qtyDelivered}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {delivery.qtyChecked}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        ) : ""}
                    </Tab.Pane>
                )
            },
        }
    ];

    const togglePriceUpdate = (service = null) => {
        setIsEditPriceVisible(service ? true : false);
        setSelectedService(service);
    }

    return (
        <div>
                <Tab
                    menu={{ pointing: true }}
                    panes={panes}
                />
                {isEditPriceVisible && <PopUp
                        handleClose={togglePriceUpdate}
                >
                    <UpdatePrice
                        selectedProduct={selectedService}
                        updateStockPriceAction={updateProductPriceStart}
                        singleStockId={singleStockData.id}
                        closePanel={togglePriceUpdate}
                        loading={isStockupdateloading}
                        isService
                    />
                </PopUp>
                }
        </div>
    )
}

const mapStateToProps = (state: any): Partial<IProps> => ({
    account: getAccountResource(state),
    stockData: getStock(state),
    singleStockData: getSingleStock(state),
    isSingleLoading: getSingleLoading(state),
    productSold: getProductSold(state),
    productDeliveries: getProductDeliveries(state),
    isStockupdateloading: getStockUpdateloading(state),
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
