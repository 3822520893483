import { createReducer } from 'reduxsauce';
import { DatatableTypes } from './actions';

export interface IDatatableState {
    error: string;
    isUpdatedOrderStatus: boolean;
    loading: boolean;
    uniqueId: string | number;
}

export const datatableContent: IDatatableState = {
    error: '',
    isUpdatedOrderStatus: false,
    loading: false,
    uniqueId: 0,
};

export const updateOrderStatusStart = (state) => {
    return { ...state, isUpdatedOrderStatus: false, loading: true };
};

export const updateOrderStatusSuccess = (state, action) => {
    return { ...state, isUpdatedOrderStatus: action.data, loading: false };
};

export const updateOrderStatusFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        isUpdatedOrderStatus: false,
        loading: false,
    };
};

export const setUniqueId = (state, action) => {
    return { ...state, uniqueId: action.id };
};

export const handlers = {
    [DatatableTypes.UPDATE_ORDER_STATUS_START]: updateOrderStatusStart,
    [DatatableTypes.UPDATE_ORDER_STATUS_SUCCESS]: updateOrderStatusSuccess,
    [DatatableTypes.UPDATE_ORDER_STATUS_FAILURE]: updateOrderStatusFailure,
    [DatatableTypes.SET_UNIQUE_ID]: setUniqueId,
};

export default createReducer(datatableContent, handlers);
