import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {Tab, Table} from "semantic-ui-react";
import {getQuote} from "./../redux/selectors";
import {QuoteActionCreators} from "./../redux/actions";
import TableComponent from "../../datatable/container/datatable";
import PopUp from "../../popUp/popUp";
import { getCurrency, replaceComma, getDateTimestampByTime, redirectClientPage} from "../../../helpers/helpers";
import {DEFAULT_CURRENCY_SYMBOL} from "../../../utils/constants/variables";
import { getMenu } from '../../../layouts/main/header/redux/selectors';

interface Props {
    quoteDashboard?: any,
    singleQuote?:any,
    isLoading: boolean,
    isDeleted: null | true | false,
    menu: any
}

interface State {
    openPopUp: boolean,
    selectedQuote:string,
    status: null | true | false,
}

class QuotesDashboard extends Component<Props & typeof QuoteActionCreators> {
    state: State = {
        openPopUp: false,
        selectedQuote:"",
        status: null
    }

    columns: any = [
        {
            name: i18next.t('Terminal.dashboards.date'),
            selector: 'quoteDate',
            sortable: true,
            datapicker:true,
            searchable: false,
            center: true,
            format: 'current',
            width: "14%",
            cell: row => <>{getDateTimestampByTime(row.quoteDate, 'dd/mm/yy')}</>
        },
        {
            name: i18next.t('Terminal.dashboards.expiration'),
            selector: 'endDate',
            sortable: true,
            datapicker:true,
            searchable: false,
            center: true,
            format: 'current',
            width: "14%",
            cell: row => <>{getDateTimestampByTime(row.endDate, 'dd/mm/yy')}</>
        },
        {
            name: i18next.t('Terminal.dashboards.quote'),
            selector: 'quoteNumber',
            sortable: true,
            searchable: true,
            center: true,
            isUnique:true,
            width: "19%",
        },
        {
            name: i18next.t('Terminal.dashboards.ticketNumber'),
            selector: 'storeSaleTicketNumber',
            sortable: true,
            searchable: true,
            center: true,
            width: "19%",
        },
        {
            name: i18next.t('Terminal.dashboards.name'),
            selector: 'name',
            sortable: true,
            searchable: true,
            center: true,
            width: "24%",
        },
        {
            name: i18next.t('Terminal.dashboards.active'),
            selector: 'active',
            notShow: false,
            searchable: false,
            width: "10%",
            center: true,
            sortable: true,
            cell: row => {
                    return (
                        <div className={`${row.active  ? 'color--green' : 'color--red'}`}>
                            {
                                (new Date()).getTime() > row.endDate ? "N" : "Y"
                            }
                        </div>
                    )
                }
        },
        {
            selector: 'firstName',
            notShow:true,
            searchable: true,
        },
    ];

    handleRedirectClients = () => {
        const {singleQuote: {clientId = null} = {}, menu} = this.props;
        redirectClientPage(clientId, menu)
        this.handleClosePopUp()
    }

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.quote'),
            render: () => <Tab.Pane attached={false}>
                {this.props.singleQuote && Object.keys(this.props.singleQuote).length ?
                    <div>
                        <Table celled selectable className="productInfoBlock--infoBox--table collaps">
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.quoteNumber')}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--title">{i18next.t('date.2')}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.expiration')}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.firstName')}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.lastName')}</Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--description">{this.state.selectedQuote}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--description">{getDateTimestampByTime(this.props.singleQuote.createdDate, 'dd/mm/yy')}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--description">{getDateTimestampByTime(this.props.singleQuote.endDate, 'dd/mm/yy')}</Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--description cursor"
                                        onClick={this.handleRedirectClients}
                                    >
                                        {this.props.singleQuote.clientFirstName}
                                    </Table.Cell>
                                    <Table.Cell
                                        className="productInfoBlock--infoBox--table--description cursor"
                                        onClick={this.handleRedirectClients}
                                    >
                                        {this.props.singleQuote.clientLastName}
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>
                        {this.props.singleQuote.storeQuoteLineItems && this.props.singleQuote.storeQuoteLineItems.length ?
                            <div>
                                <Table celled selectable className="productInfoBlock--infoBox--table collaps">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.id')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.Name')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.barcode')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.Size')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">
                                                {i18next.t('Terminal.dashboards.prix',{DEFAULT_CURRENCY_SYMBOL})}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.reduction')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.Receipt.Sale.listPriceAfterDiscount')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.unit')}</Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--title">{i18next.t('Terminal.dashboards.quantity')}</Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            this.props.singleQuote.storeQuoteLineItems.map(item =>(
                                                <Table.Row key={item.productId}>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.productId}</Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.productName}</Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.barcode}</Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.size}</Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{replaceComma(item.listPriceAfterDiscount || item.outletPrice || item.listPrice)} {getCurrency(item.currency)}</Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">
                                                        { item.discountDetail ? item.discountDetail : item.specialDiscountDetail !== 0 ? item.specialDiscountDetail :""}
                                                    </Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.listPriceAfterDiscount ? replaceComma(item.listPriceAfterDiscount)+" "+getCurrency(item.currency) :"" } </Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.unit}</Table.Cell>
                                                    <Table.Cell
                                                        className="productInfoBlock--infoBox--table--description">{item.quantity}</Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                            :""
                        }
                    </div>:""
                }
            </Tab.Pane>,
        },
    ]

    componentDidMount() {
        const { location = {}, history } = this.props;
        this.props.quoteStart({});

        if (location.state && location.state.quoteNumber) {
            this.handleSaleDetals(location.state);
            history.replace({ ...history.location, state: undefined });
        }
    }

    componentDidUpdate(prevProps) {
        let timeId
        if (prevProps.isDeleted !== this.props.isDeleted) {
            this.setState({ status: this.props.isDeleted });
            this.props.isDeleted !== null && this.props.isDeleted !== false && this.props.quoteStart({})
            timeId = setTimeout(() => {
                this.setState({ status: null });
            }, 5000);
        }

        return () => {
            clearInterval(timeId);
        };
    }

    handleClosePopUp = () => {
        this.setState({openPopUp: false});
    };

    handleSaleDetals = (e) => {
        this.props.singleQuoteStart(e.quoteNumber)
        this.setState({openPopUp: true,selectedQuote:e.quoteNumber});
    };

    render() {
        const { quoteDashboard, isLoading } = this.props;
        const { openPopUp, status } = this.state;;

        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={quoteDashboard}
                        isLoading={isLoading}
                        openPopUp={this.handleSaleDetals}
                        columns={this.columns}
                        datepicker={true}
                        buttons={['quoteButtons']}
                        reloadDashboard={() => this.props.quoteStart({})}
                        keyField={"quote"}
                    />
                </div>

                {status !== null && (
                    <div
                        className={`w-33 pb-2 mt--3 ${
                            status === true
                                ? 'successMessage'
                                : 'errorMessage'
                        }`}
                    >
                        {status === true
                            ? i18next.t('alertMessage.2')
                            : i18next.t('alertMessage.1')}
                    </div>
                )}

                {openPopUp ?
                    <PopUp handleClose={this.handleClosePopUp} classN="salesContent">
                        <div>
                            <Tab menu={{pointing: true}} panes={this.panes}/>
                        </div>
                    </PopUp> : ""
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => {
    const {quoteDashboard, singleQuote, isLoading, isDeleted} = getQuote(state);
    return{
        quoteDashboard,
        singleQuote,
        isLoading,
        isDeleted,
        menu: getMenu(state),
    }
};

const mapDispatchToProps = (dispatch): typeof QuoteActionCreators => {
    return bindActionCreators(QuoteActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotesDashboard);
