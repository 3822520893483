import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {ReturnActionCreators} from './../../redux/actions';
import {getUpdatedStatus} from './../../redux/selectors';
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import {getApiPrefix, getLangPrefix} from "../../../../helpers/helpers";
import InfoPopUp from "../../../common/infoPopUp";
import CustomBtn from '../../../common/customBtn';
import { ORDER_STATUS_CODE, RETURN_STATUS_CODE } from '../../../../utils/constants/variables';

interface Props {
    data: any,
    updatedStatus: string,
}

interface State {
    showConfirmpopUp: boolean,
    showInfoPopup: boolean,
    InfoPopupMessage: string,
    returnStatus:string,

}
class ReturnButtons extends Component<Props &  typeof ReturnActionCreators , State> {
    state = {
        showConfirmpopUp: false,
        showInfoPopup:false,
        InfoPopupMessage:"",
        returnStatus: "",
    }

    componentDidUpdate(prevProps) {
        if(prevProps.updatedStatus !==this.props.updatedStatus){
            if(this.props.updatedStatus==="success"){
                this.setState({showInfoPopup: true,InfoPopupMessage:i18next.t('alertMessage.2') })
            }else {
                this.setState({showInfoPopup: true,InfoPopupMessage:i18next.t('alertMessage.1') })
            }
        }
    }

    openConfirmPopUp = (status) => {
        this.setState({showConfirmpopUp:true, returnStatus:status})
    }
    closeConfirmPopUp =() => {
        this.setState({showConfirmpopUp:false,returnStatus:""})
    }

    handleUpdateStatus =  () => {
        const {returnStatus} =this.state
        const {data}= this.props
        if(data){
            this.props.updateReturnStart({
                language: getApiPrefix(),
                returnStatusCode: returnStatus,
                ticketId: data.ticketId,
                ticketStatusCode: ORDER_STATUS_CODE.PEN
            })
            this.closeConfirmPopUp()
        }
    }

    render() {
        const {updatedStatus, data} = this.props;
        const {showConfirmpopUp,showInfoPopup,InfoPopupMessage} = this.state;
        const isDisabled = [
            RETURN_STATUS_CODE.ACC,
            RETURN_STATUS_CODE.REF,
            RETURN_STATUS_CODE.PAI
        ].includes(data && data.returnStatusCode)
        return (
            <>
                {/* <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    disabled={isDisabled}
                    handleClick={() => this.openConfirmPopUp(RETURN_STATUS_CODE.ACC)}
                >
                    {i18next.t('buttons.19')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 red_btn"
                    disabled={isDisabled}
                    handleClick={() => this.openConfirmPopUp(RETURN_STATUS_CODE.REF)}
                >
                    {i18next.t('buttons.20')}
                </CustomBtn> */}
                {
                    showConfirmpopUp ? <ConfirmPopup handleConfirm={this.handleUpdateStatus} handleCancel={this.closeConfirmPopUp} text={i18next.t('Terminal.modal.2')} />:""
                }
                <InfoPopUp
                    open={showInfoPopup}
                    message={InfoPopupMessage}
                    classN={updatedStatus==="success"?"successMessage":updatedStatus==="failed"?"errorMessage":""}
                    handleClose={()=>this.setState({showInfoPopup: false})}
                />
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        updatedStatus: getUpdatedStatus(state),
    }
}

const mapDispatchToProps = (dispatch): typeof ReturnActionCreators => {
    return bindActionCreators(ReturnActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnButtons);
