import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import axios from 'axios';
import TopClientsSidePanel from './topClientsSidePanel';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import {
    changeBasketClientId,
    getActiveBasketName,
    getBasketData,
    getLangPrefix,
    toTimestamp,
} from '../../../../helpers/helpers';
import { DEFAULT_CURRENCY } from '../../../../utils/constants/variables';
import { getLoadingClient, getSingleClient } from '../../redux/selectors';
import { ClientActionCreators } from '../../redux/actions';
import InfoPopUp from '../../../common/infoPopUp';
import { getBasket } from '../../../addToCart/redux/selectors';
import {
    getAxiosHeaders,
    ADD_CLIENT_TICKET,
} from '../../../../utils/constants/service';

interface IProps {
    data: any;
    client: any;
    loading: boolean;
    history?: any;
    storeCode?: string;
}

interface State {
    showPopup: boolean;
    isDelete: boolean;
    open: boolean;
    showClientPopup: boolean;
    showSidePanel: boolean;
    clientMessage: string;
    updatedStatus: string;
}

class SetClientBtn extends Component<
    IProps & typeof ClientActionCreators,
    State
> {
    state: State = {
        showPopup: false,
        isDelete: false,
        open: false,
        showSidePanel: false,
        clientMessage: '',
        showClientPopup: false,
        updatedStatus: '',
    };

    componentDidUpdate(
        prevProps: Readonly<IProps & typeof ClientActionCreators>,
        prevState: Readonly<{}>,
        snapshot?: any,
    ): void {
        if (
            this.state.isDelete &&
            prevProps.loading &&
            !this.props.loading &&
            this.props.client
        ) {
            if (this.props.client.clientOrders) {
                this.setState({ open: true });
            } else {
                this.setState({ showPopup: true });
            }
        }
    }

    addBasketClient = () => {
        const { data } = this.props;
        if (!data.clientId) return;
        if (data.clientId) {
            this.props.storeClientStart(data.clientId, getActiveBasketName());
            changeBasketClientId(data.clientId);
            const activeRow = document.querySelector(
                '.rdt_TableRow.active-row',
            );
            const selected = document.querySelector('.rdt_TableRow.green');
            if (selected) {
                selected.classList.remove('green');
            }
            if (activeRow) {
                activeRow.classList.add('green');
                setTimeout(
                    () => this.props.history.push(getLangPrefix()),
                    1000,
                );
            }
        }
    };

    deleteBasketClient = () => {
        const selected = document.querySelector('.rdt_TableRow.green');
        if (selected) {
            selected.classList.remove('green');
        }
        this.props.resetStoreClient(getActiveBasketName());
    };

    handleClickDelete = () => {
        const { data = {} } = this.props;

        if (!data.clientId) return;

        this.setState({ isDelete: true }, () => {
            this.props.singleClientStart(data.clientId);
        });
    };

    handleDelete = () => {
        const { data, storeCode } = this.props;
        if (data && Object.keys(data).length && data.clientId) {
            let body: any = {
                birthdate: toTimestamp(data.birthdate) || null,
                clientCode: data.clientCode || null,
                clientType: data.clientType || null,
                comment: data.comment || null,
                currency: data.currency || DEFAULT_CURRENCY,
                dataOptinDate: toTimestamp(data.dataOptinDate) || null,
                deleted: true,
                email: data.email || null,
                firstName: data.firstName || null,
                gender: data.gender || 'M',
                lastName: data.lastName || null,
                newsOptinDate: toTimestamp(data.newsOptinDate) || null,
                phone: data.phone || null,
                storeClientId: data.clientId || null,
                webClientId: data.webClientId || null,
                storeCode,
            };
            this.props.setClientStart(body);
            this.setState({ showPopup: false });
        } else {
            return false;
        }
    };

    handleClosePopUp = () =>
        this.setState({ showPopup: false, isDelete: false });

    handleCloseInfoPopUp = () => {
        if (!this.state.updatedStatus) {
            this.props.clientStart();
        }
        this.setState({
            isDelete: false,
            open: false,
            updatedStatus: '',
            clientMessage: '',
        });
    };

    handleAddClient = () => {
        const { basket = {}, data = {} } = this.props;
        if (!data.clientId) return;
        const { ticketNumber } = getBasketData(basket);
        if (ticketNumber) {
            this.setState({ showClientPopup: true });
        } else {
            this.addBasketClient();
        }
    };
    confirmAddClient = () => {
        const { basket = {} } = this.props;
        const { data: { clientId = 0 } = {} } = this.props;
        const { ticketNumber } = getBasketData(basket);
        const headers = getAxiosHeaders();
        axios
            .put(
                `${ADD_CLIENT_TICKET}/${ticketNumber}/update-store-client?storeClientId=${clientId}`,
                {},
                headers,
            )
            .then((r) => {
                this.setState({
                    clientMessage: 'Success',
                    showClientPopup: false,
                    open: true,
                    updatedStatus: 'success',
                });
                this.addBasketClient();
            })
            .catch((e) => {
                this.setState({
                    clientMessage: 'failed',
                    showClientPopup: false,
                    open: true,
                    updatedStatus: 'failed',
                });
            });
    };

    render() {
        const {
            showPopup,
            open,
            showClientPopup,
            updatedStatus,
            clientMessage,
            showSidePanel,
        } = this.state;
        return (
            <>
                <button
                    className="blue_btn btn_1 w-100 mb-3"
                    onClick={() => this.setState({ showSidePanel: true })}
                >
                    {i18next.t('buttons.43')}
                </button>
                <button
                    className="green_btn btn_1 w-100"
                    onClick={this.handleAddClient}
                >
                    {i18next.t('buttons.10')}
                </button>
                <button
                    className="grey_btn btn_1 w-100"
                    onClick={this.deleteBasketClient}
                >
                    {i18next.t('buttons.11')}
                </button>
                <button
                    className="red_btn btn_1 w-100"
                    style={{order: 5}}
                    onClick={this.handleClickDelete}
                    disabled={this.props.loading}
                >
                    {i18next.t('buttons.12')}
                </button>
                {showPopup && (
                    <ConfirmPopup
                        handleCancel={this.handleClosePopUp}
                        handleConfirm={this.handleDelete}
                        text={i18next.t('Terminal.modal.1')}
                    />
                )}

                {showClientPopup && (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showClientPopup: false })
                        }
                        handleConfirm={this.confirmAddClient}
                        text={i18next.t('Terminal.modal.4')}
                    />
                )}

                {showSidePanel && (
                    <TopClientsSidePanel
                        handleClose={() =>
                            this.setState({ showSidePanel: false })
                        }
                    />
                )}

                <InfoPopUp
                    open={open}
                    classN={
                        updatedStatus === 'success'
                            ? 'successMessage'
                            : updatedStatus === 'failed'
                            ? 'errorMessage'
                            : ''
                    }
                    message={clientMessage || i18next.t('Terminal.modal.3')}
                    handleClose={this.handleCloseInfoPopUp}
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    basket: getBasket(state),
    client: getSingleClient(state),
    loading: getLoadingClient(state),
});

const mapDispatchToProps = (dispatch): typeof ClientActionCreators => {
    return bindActionCreators(ClientActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SetClientBtn);
