import React, { useEffect, useState } from 'react';
import { Dropdown, TextArea } from 'semantic-ui-react';
import i18next from 'i18next';

import {
    AddressInterface,
    ShippingParcel,
    ShippingRequest,
    BookPickupParams,
    ShipmentOrder
} from '../../../shared/models/shipModel';
import { getDateTimestamp } from '../../../helpers/helpers';
import DayPicker from '../../common/dayPicker';
import CustomBtn from '../../common/customBtn';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';

interface Props {
    fromAddress: AddressInterface;
    toAddress: AddressInterface;
    selectedShipment: any;
    selectedCarrier: any;
    createBook: any;
    isBookLoading: boolean;
    bookResult: any;
    emptyMessage: any
}

export default function BookSidePanel({
    fromAddress,
    toAddress,
    selectedShipment,
    selectedCarrier,
    isBookLoading,
    createBook,
    bookResult,
    emptyMessage
}: Props) {
    const date = new Date();
    const timeOptions = [
        {value: "08:00", text: "08:00", key: 0},
        {value: "08:30", text: "08:30", key: 1},
        {value: "09:00", text: "09:00", key: 2},
        {value: "09:30", text: "09:30", key: 3},
        {value: "10:00", text: "10:00", key: 4},
        {value: "10:30", text: "10:30", key: 5},
        {value: "11:00", text: "11:00", key: 6},
        {value: "11:30", text: "11:30", key: 7},
        {value: "12:00", text: "12:00", key: 8},
        {value: "12:30", text: "12:30", key: 9},
        {value: "14:00", text: "14:00", key: 10},
        {value: "14:30", text: "14:30", key: 11},
        {value: "15:00", text: "15:00", key: 12},
        {value: "15:30", text: "15:30", key: 13},
        {value: "16:00", text: "16:00", key: 14},
        {value: "16:30", text: "16:30", key: 15},
        {value: "17:00", text: "17:00", key: 16},
    ]
    date.setHours(date.getHours());

    const [time, setTime] = useState<string>(timeOptions[0].value);
    const [day, setDay] = useState<number>(date.getTime());
    const [comment, setComment] = useState<string>('');
    const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(false);

    const handleChangeDay = (day) => {
        const date = new Date(day);
        setDay(date.getTime());
    }

    const changeTime = (event, data) => {
        const { value = time } = data;
        setTime(value);
    }

    const changeComment = ({ target }) => {
        const { value } = target;
        setComment(value);
    }

    const getTimeSeconds = (miliseconds: number) => {
        let seconds = `${miliseconds}`;
        seconds = seconds.substring(0, seconds.length - 3);
        return Number(seconds);
    }

    const getPickupTime = () => {
        const date = new Date(day);
        const dayNumber = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const pickupDate: any = new Date(`${month}/${dayNumber}/${year} ${time}`);
        const offset = pickupDate.getTimezoneOffset() + 60;

        return getTimeSeconds(pickupDate.getTime()) - (offset * 60);
    }

    const getParcels = () => {
        const length = selectedShipment && selectedShipment.shippedQuantity;
        const weight = selectedShipment && selectedShipment.weight;
        const parcels: ShippingParcel[] = [];
        for (let i = 0; i < length; i++) {
            const parcel = new ShippingParcel(30,30,30,i === 0 ? weight : 1);
            parcels.push(parcel)
        }
        return parcels;
    }

    const handleBook = () => {
        const parcels = getParcels();
        const params = new BookPickupParams(
            // `${selectedShipment.shippingConfirmation}`,
            +selectedCarrier.identifier,
            selectedCarrier.name,
            "09:00",
            "12:00",
            "14:00",
            "17:00",
            getPickupTime(),
            comment,
            toAddress,
            fromAddress,
            parcels,
            String(selectedShipment.shippingConfirmation)
        );
        const bookRequest = new ShippingRequest("BookPickup", params);
        const orderBody = new ShipmentOrder(
            selectedShipment.carrierCode,
            selectedShipment.carrierId,
            selectedShipment.comment,
            selectedShipment.labelUrl,
            selectedShipment.orderId || selectedShipment.storeTransferId,
            selectedShipment.packageNumber,
            0,
            params.PickupTime,
            new Date().getTime(),
            selectedShipment.shippedQuantity,
            selectedShipment.shippingConfirmation,
            selectedShipment.weight,
        );

        createBook({ bookRequest, orderBody });
        setIsConfirmationVisible(false);
    }

    useEffect(() => {
        return () => {
            emptyMessage && emptyMessage({});
        }
    }, [])

    return (
        <div className='popUp--body--card--body set-data-content'>
            <div className='popUp--body--card--body set-data-content d-flex alg-center'>
                <div className='popUp--body--card--body--data--header w-50 mr-1'>
                    <DayPicker
                        value={day}
                        dayPickerProps={{
                            disabledDays: {
                                before: date,
                            },
                        }}
                        onChange={handleChangeDay}
                    />
                </div>
                <div className='popUp--body--card--body set-data-content w-50'>
                    <Dropdown
                        className="w-100 carrier-dropdown bold-text carrier-text-area"
                        compact
                        selection
                        closeOnChange
                        options={timeOptions}
                        value={time}
                        onChange={changeTime}
                    />
                </div>
            </div>
            <TextArea
                className="popUp--body--card--body--data--description bold-text mt-2"
                value={comment}
                name="comment"
                onChange={changeComment}
            />
            <div className='popUp--body--card--body set-data-content w-100'>
                <CustomBtn
                    classN={`btn_1 w-50 red_btn mt-2`}
                    handleClick={() => setIsConfirmationVisible(true)}
                    loading={isBookLoading}
                    disabled={false}
                >
                    {i18next.t('buttons.48')}
                </CustomBtn>
                <div
                    className={`${
                        bookResult.Result === "OK"
                            ? 'message-success'
                            : 'message-error'} book-result centeredBox w-50 mt-2`}
                >
                    {bookResult.Message}
                </div>
            </div>
            {isConfirmationVisible && (
                <ConfirmPopup
                    handleCancel={() => setIsConfirmationVisible(false)}
                    handleConfirm={() => handleBook()}
                    text={`${i18next.t('Terminal.confirm.26')} ${getDateTimestamp(day)} ${time}`}
                />
            )}
        </div>
    )
}
