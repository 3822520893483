import { all, spawn } from 'redux-saga/effects';
import userSaga from '../modules/login/redux/sagas';
import productSaga, {
    productCareSaga,
    productCompositionSaga,
} from '../modules/product/redux/sagas';
import geoLocationSaga from '../modules/geolocation/redux/sagas';
import productsSaga from '../modules/products/redux/sagas';
import addToCartSaga from '../modules/addToCart/redux/sagas';
import menuSaga from '../layouts/main/header/redux/sagas';
import homeSaga from '../modules/home/redux/sagas';
import locationSearchSaga from '../modules/locationSearchInput/redux/sagas';
import paymentSaga from '../modules/payment/redux/sagas';
import DownloadSaga from '../modules/recapCommande/redux/sagas';
import terminalSaga from './../modules/fermeturePopUp/redux/sagas';
import stockSaga from './../modules/stocks/redux/sagas';
import clientSaga from './../modules/clients/redux/sagas';
import livraisonClientSaga from './../modules/livraisonClient/redux/sagas';
import reservationMagasinSaga from './../modules/reservationMagasin/redux/sagas';
import salesSaga from './../modules/sales/redux/sagas';
import datatableSaga from './../modules/datatable/redux/sagas';
import storesSaga from './../modules/storeFormSidePanel/redux/sagas';
import storeGiftsSaga from './../modules/bonsCadeaux/redux/sagas';
import transfersSaga from '../modules/transfers/redux/sagas';
import DeliveriesSaga from '../modules/deliveries/redux/sagas';
import addToDeliveryCartSaga from '../modules/deliveryBasketPopUp/redux/sagas';
import quoteSaga from '../modules/quotes/redux/sagas';
import returnSaga from './../modules/return/redux/sagas';
import reportsSaga from './../modules/reports/redux/sagas';
import inventorySaga from './../modules/inventory/redux/sagas';
import nullBelegSaga from './../modules/nullBelegBtn/redux/sagas';
import calendarSaga from '../modules/calendar/redux/sagas';
import adminSaga from '../modules/Admin/redux/sagas';
import appointmentsSaga from '../modules/Appointments/redux/sagas';

export default function* rootSaga() {
    yield all([
        spawn(userSaga),
        spawn(productSaga),
        spawn(productCareSaga),
        spawn(productCompositionSaga),
        spawn(geoLocationSaga),
        spawn(productsSaga),
        spawn(addToCartSaga),
        spawn(menuSaga),
        spawn(homeSaga),
        spawn(locationSearchSaga),
        spawn(paymentSaga),
        spawn(DownloadSaga),
        spawn(terminalSaga),
        spawn(stockSaga),
        spawn(clientSaga),
        spawn(livraisonClientSaga),
        spawn(reservationMagasinSaga),
        spawn(salesSaga),
        spawn(datatableSaga),
        spawn(storesSaga),
        spawn(storeGiftsSaga),
        spawn(transfersSaga),
        spawn(DeliveriesSaga),
        spawn(addToDeliveryCartSaga),
        spawn(quoteSaga),
        spawn(returnSaga),
        spawn(reportsSaga),
        spawn(inventorySaga),
        spawn(nullBelegSaga),
        spawn(calendarSaga),
        spawn(adminSaga),
        spawn(appointmentsSaga)
    ]);
}
