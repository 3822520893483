import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { getReturn, getLoading, getIsTicketLoading, getTicketData } from './../redux/selectors';
import { ReturnActionCreators } from './../redux/actions';
import TableComponent from '../../datatable/container/datatable';
import PopUp from '../../popUp/popUp';
import ReturnDetails from './components/returnDetails';
import './returns.scss';
import { LOGISTIC_STATUS_CODE, RETURN_STATUS_CODE, STORAGE_GET_FORM } from '../../../utils/constants/variables';
import { Popup } from 'semantic-ui-react';
import { getAccountResource } from '../../login/redux/selectors';
import { getDateTimestamp, getLocalStorageObject } from '../../../helpers/helpers';
import ReturnMessages from './components/returnMessages';

interface Props {
    ticketData: any;
    isLoading: boolean;
    details: any;
    menuItem?: any;
}

interface State {
    isShowPopUp: boolean;
    singleReturn: object;
    isMessagePopupVisible: boolean;
}

class Returns extends Component<Props & typeof ReturnActionCreators> {
    state: State = {
        isShowPopUp: false,
        singleReturn: {},
        isMessagePopupVisible: false
    };

    ticketStatuses = getLocalStorageObject(STORAGE_GET_FORM).ticketStatuses;

    getColorByCode = (code) => {
        switch(code) {
            case LOGISTIC_STATUS_CODE.PEN:
                return 'color--red';
            case LOGISTIC_STATUS_CODE.WAI:
                    return 'color--orange';
            case LOGISTIC_STATUS_CODE.CLO:
                return 'color--green';
            default:
                return '';
        }
    }

    columns: any[] = [
        {
            name: i18next.t('Terminal.dashboards.ticketId'),
            selector: 'id',
            searchable: true,
            isUnique: true,
            sortable: true,
            width: '10%',
        },
        {
            name: i18next.t('Terminal.dashboards.date'),
            selector: 'createdDate',
            searchable: true,
            sortable: true,
            width: '14%',
            cell: (row) => {
                const [date] = row.createdDate.split(' ');
                return date || '';
            }
        },
        {
            name: i18next.t('Terminal.dashboards.ordine'),
            selector: 'orderId',
            searchable: true,
            sortable: true,
            width: '14%',
        },
        {
            name: i18next.t('Terminal.dashboards.contactName'),
            selector: 'contactName',
            searchable: true,
            sortable: true,
            width: '22%',
        },
        {
            name: i18next.t('Terminal.dashboards.ticketSubject'),
            selector: 'ticketSubject',
            searchable: true,
            sortable: true,
            width: '25%',
            cell: (row) => row.ticketSubject ? row.ticketSubject.subject : ''
        },
        {
            name: i18next.t('Terminal.dashboards.ticketStatus'),
            selector: 'ticketStatus',
            searchable: true,
            sortable: true,
            width: '15%',
            cell: (row) => {
                const { code } = this.ticketStatuses.find((item) => item.status === row.ticketStatus) || { code: "" };

                return (
                    <div
                        className={`${this.getColorByCode(code)}`}
                    >
                        {row.ticketStatus}
                    </div>
                )
            }
        },
        {
            name: 'clientCode',
            selector: 'clientCode',
            notShow: true,
        },
        {
            name: 'ticketId',
            selector: 'ticketId',
            notShow: true,
        },
        {
            name: 'returnStatusCode',
            selector: 'returnStatusCode',
            notShow: true,
        },
        {
            name: 'ticketMessages',
            selector: 'ticketMessages',
            notShow: true,
        },
        {
            name: 'contactEmail',
            selector: 'contactEmail',
            notShow: true,
        }
    ];
    conditionalRowStyles: any[] = [
        {
            when: (row) => row.returnStatusCode === RETURN_STATUS_CODE.REF,
            style: {
                backgroundColor: 'red',
            },
        },
        {
            when: (row) => row.returnStatusCode === RETURN_STATUS_CODE.ACC,
            style: {
                backgroundColor: 'green',
            },
        },
    ];

    componentDidMount() {
        // this.props.returnStart();
        this.props.getTicketsStart();
    }

    openPopUp = (rowData) => {
        this.setState({ isMessagePopupVisible: true, singleReturn: rowData })

    };

    handleClosePopUp = () => {
        this.setState({ isShowPopUp: false });
    };

    getReturnData = () => {
        const { ticketData } = this.props;

        return ticketData;
        // return ticketData.filter((item) => menuItem.key === "retours" ? item.returnStatusCode : !item.returnStatusCode);
    }

    toggleMessages = () => this.setState({ isMessagePopupVisible: !this.state.isMessagePopupVisible })

    render() {
        const { isLoading, details } = this.props;
        const { isShowPopUp, singleReturn, isMessagePopupVisible } = this.state;

        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={this.getReturnData()}
                        isLoading={isLoading}
                        openPopUp={this.openPopUp}
                        columns={this.columns}
                        conditionalRowStyles={this.conditionalRowStyles}
                        reloadDashboard={this.props.getTicketsStart}
                        classN="return"
                        buttons={['returnButtons']}
                        keyField={'returns'}
                    />
                </div>
                {isShowPopUp ? (
                    <PopUp handleClose={this.handleClosePopUp}>
                        <ReturnDetails
                            details={details}
                            data={singleReturn}
                            handleClosePopUp={this.handleClosePopUp}
                            createGift={(body) =>
                                this.props.createStoreGiftStart(body, true)
                            }
                        />
                    </PopUp>
                ) : (
                    ''
                )}
                {isMessagePopupVisible && (
                    <PopUp
                        handleClose={this.toggleMessages}
                        classN="mid-popup"
                    >
                        <ReturnMessages data={singleReturn} />
                    </PopUp>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    ticketData: getTicketData(state),
    isLoading: getIsTicketLoading(state),
    details: getAccountResource(state)
});

const mapDispatchToProps = (dispatch): typeof ReturnActionCreators => {
    return bindActionCreators(ReturnActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Returns);
