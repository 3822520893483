import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { Popup, Tab, Table } from 'semantic-ui-react';
import { getLivraisonClientData } from './../redux/selectors';
import { livraisonClientActionCreators } from './../redux/actions';
import TableComponent from '../../datatable/container/datatable';
import PopUp from './../../popUp/popUp';
import {
    getCurrency,
    getDateTimestamp,
    getDateTimestampByTime,
    getLocalFormData,
    getUserFromToken,
    replaceComma,
} from '../../../helpers/helpers';
import {
    DEFAULT_CURRENCY_SYMBOL,
    ORDER_STATUS_CODE,
} from '../../../utils/constants/variables';
import PdfTable from '../../pdfGenerator/pdfTableBtn';
import { getAccountResource } from '../../login/redux/selectors';
import { updateOrderLoading } from '../../datatable/redux/selectors';
import { createOrderShipment } from '../../../utils/constants/service';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';
import InfoPopUp from '../../common/infoPopUp';
import './livraisonClient.scss';
import { ShippingParcel } from '../../../shared/models/shipModel';
import CarrierTab from './carrierTab/carrierTab'
import { DatatableActionCreators } from '../../datatable/redux/actions';
import CustomBtn from '../../common/customBtn';

interface Props {
    livraisonClientData: any;
    livraisonClientSingleData: any;
    isLoading: boolean;
    isBookLoading: boolean;
    details: any;
    bookResult: any;
    updateStatusLoading;
    orderLoading: boolean;
}

interface State {
    showPopUp: boolean;
    userOrderID: number;
    itemData: any;
    loading: boolean;
    showConfirm: boolean;
    showPaiConfirm: boolean;
    showLoginConfirme: boolean;
    showBookPanel: boolean;
    showConfirmPopup: boolean;
    alertMsg: string;
    parcels: ShippingParcel[];
    quantityOptions: any[];
    selectedShipment: any;
    shipmentBody: {
        shipmentDate: number;
        packageNumber: number | string;
        shippedQuantity: number | string;
        weight: string;
        comment: string;
        selectedTransport: number,
        selectedService: number,
    };
}

class LivraisonClient extends Component<
    Props & State & typeof livraisonClientActionCreators
> {
    state: State = {
        showPopUp: false,
        userOrderID: 0,
        itemData: {},
        loading: false,
        showConfirm: false,
        showPaiConfirm: false,
        showLoginConfirme: false,
        showBookPanel: false,
        showConfirmPopup: false,
        alertMsg: '',
        parcels: [],
        quantityOptions: this.getQuantityOptions(),
        selectedShipment: {},
        shipmentBody: {
            shipmentDate: Date.now(),
            packageNumber: '',
            shippedQuantity: 1,
            weight: '',
            comment: '',
            selectedTransport: 0,
            selectedService: 0,
        },
    };

    getCarrierById(id: number | null = null) {
        const { carriers = []} = getLocalFormData();
        return carriers.find((carrier) => id ? carrier.id === id : carrier.defaultCarrier) || {};
    }

    getQuantityOptions() {
        return Array(4).fill(1).map((item, index) => ({
            key: index,
            value: index + 1,
            text: index + 1,
        }))
    }

    getParcelNameByKey(key: string) {
        switch(key) {
            case 'length':
                return i18next.t('Carrier.length');
            case 'width':
                return i18next.t('Carrier.width');
            case 'height':
                return i18next.t('Carrier.height');
            case 'weight':
                return i18next.t('Carrier.weight');
        }
    }

    changeParcelValue = ({ target }, index: number) => {
        const { name = '', value = '' } = target;
        const parcels = [...this.state.parcels];
        parcels[index][name] = +value;
        this.setState({ parcels })
    }

    columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '30%',
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '18%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.quantity'),
            selector: 'quantity',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.stock'),
            selector: 'stock',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.prix'),
            selector: 'prix',
            width: '13%',
        },
    ];

    columns: any = [
        {
            name: i18next.t('Terminal.dashboards.orderId'),
            selector: 'orderId',
            isUnique: true,
            sortable: true,
            width: '17%',
        },
        {
            name: i18next.t('Terminal.dashboards.client'),
            selector: 'clientName',
            sortable: true,
            width: '20%',
        },
        {
            name: i18next.t('Terminal.dashboards.date'),
            selector: 'orderDate',
            sortable: true,
            width: '15%',
            format: 'dd/mm/yy',
        },
        {
            name: i18next.t('Terminal.dashboards.sale'),
            selector: 'totalSales',
            sortable: true,
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.quantity'),
            selector: 'totalQuantity',
            sortable: true,
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.status'),
            selector: 'orderStatus.label',
            sortable: true,
            width: '27%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.orderStatus && row.orderStatus.label}
                        </div>
                    }
                    content={row.orderStatus && row.orderStatus.label}
                    position="bottom center"
                />
            ),
        },
        {
            selector: 'webClientId',
            notShow: true,
        },
        {
            selector: 'billAddressXrefId',
            notShow: true,
        },
        {
            selector: 'shipAddressXrefId',
            notShow: true,
        },
        {
            selector: 'orderStatus',
            notShow: true,
        },
    ];

    conditionalRowStyles = [
        {
            when: (row) =>
                row.orderStatus &&
                row.orderStatus.code === ORDER_STATUS_CODE.PRO,
            style: {
                backgroundColor: '#ffae1a',
            },
        },
        {
            when: (row) =>
                row.orderStatus &&
                row.orderStatus.code === ORDER_STATUS_CODE.REA,
            style: {
                backgroundColor: '#12a4ff',
            },
        },
        {
            when: (row) =>
                row.orderStatus &&
                row.orderStatus.code === ORDER_STATUS_CODE.SHI,
            style: {
                backgroundColor: '#6aa84f',
            },
        },
        {
            when: (row) =>
                row.orderStatus &&
                row.orderStatus.code === ORDER_STATUS_CODE.DIS,
            style: {
                backgroundColor: '#ff0000',
            },
        },
    ];

    panes1 = [
        {
            menuItem: i18next.t('Terminal.dashboards.shippingAddress'),
            render: () => (
                <Tab.Pane attached={false}>
                    {this.props.livraisonClientSingleData &&
                    this.props.livraisonClientSingleData.shippingAddress ? (
                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.city')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.zip')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.country',
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.address1',
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.address2',
                                        )}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .shippingAddress.city
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .shippingAddress.zip
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .shippingAddress.country
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .shippingAddress.address1
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .shippingAddress.address2
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.dashboards.billingAddress'),
            render: () => (
                <Tab.Pane attached={false}>
                    {this.props.livraisonClientSingleData &&
                    this.props.livraisonClientSingleData.billingAddress ? (
                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.city')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.zip')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.country',
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.address1',
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.address2',
                                        )}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .billingAddress.city
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .billingAddress.zip
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .billingAddress.country
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .billingAddress.address1
                                        }
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .billingAddress.address2
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                </Tab.Pane>
            ),
        },
    ];

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.client'),
            render: () => (
                <Tab.Pane attached={false}>
                    {this.props.livraisonClientSingleData &&
                    Object.keys(this.props.livraisonClientSingleData).length ? (
                        <Table celled selectable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t(
                                            'Terminal.dashboards.firstName',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .firstName
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t(
                                            'Terminal.dashboards.lastName',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .lastName
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t('Terminal.dashboards.email')}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {
                                            this.props.livraisonClientSingleData
                                                .email
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t('Terminal.dashboards.phone')}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {this.props.livraisonClientSingleData
                                            .billingAddress &&
                                            this.props.livraisonClientSingleData
                                                .billingAddress.phone}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                    <Tab menu={{ pointing: true }} panes={this.panes1} />
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.dashboards.product'),
            render: () => {
                const { details: { storeDetails = {} } = {}, orderLoading } = this.props;
                const { itemData: { orderLines = [] } = { orderLines: [] } } = this.state;
                const dataPdf = orderLines.reduce((pdfData, item) => {
                    item && pdfData.push({
                        name: item.productNameLevel1,
                        barcode: item.barcode,
                        size: item.size,
                        quantity: item.orderQty,
                        stock: item.stock,
                        prix: replaceComma(item.listPrice) + getCurrency(),
                    });
                    return pdfData;
                }, []);
                return (
                    <Tab.Pane attached={false}>
                        {this.props.livraisonClientData &&
                        Object.keys(this.props.livraisonClientData).length &&
                        this.state.userOrderID && (
                            <>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.orderId',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.date',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.totalQuantity',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {this.state.itemData.orderId}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {getDateTimestampByTime(
                                                    this.state.itemData
                                                        .orderDate,
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {
                                                    this.state.itemData
                                                        .totalQuantity
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.Name',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.barcode',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.Size',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.quantity',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.stock',
                                                )}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.prix',
                                                    { DEFAULT_CURRENCY_SYMBOL },
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.itemData.orderLines &&
                                        Array.isArray(this.state.itemData.orderLines)
                                            && this.state.itemData.orderLines.map(
                                                  (elem) => (
                                                      <Table.Row
                                                          key={elem.webOrderId}
                                                      >
                                                          <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                              {
                                                                  elem.productNameLevel1
                                                              }
                                                          </Table.Cell>
                                                          <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                              {elem.barcode}
                                                          </Table.Cell>
                                                          <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                              {elem.size}
                                                          </Table.Cell>
                                                          <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                              {elem.orderQty}
                                                          </Table.Cell>
                                                          <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                              {elem.stock}
                                                          </Table.Cell>
                                                          <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                              {replaceComma(
                                                                  elem.listPrice,
                                                              )}
                                                              {getCurrency()}
                                                          </Table.Cell>
                                                      </Table.Row>
                                                    ),
                                              )}
                                    </Table.Body>
                                </Table>
                                <div className='popUp--body--card--body--data--description d-flex'>
                                    <PdfTable
                                        disabled={false}
                                        classN="btn_1 w-50 blue_btn"
                                        columns={this.columnsPdf}
                                        data={dataPdf}
                                        ID={this.state.itemData.orderId}
                                        currentDate={getDateTimestamp(
                                            this.state.itemData.orderDate,
                                        )}
                                        storeName={storeDetails.name}
                                        associateFirstName={
                                            getUserFromToken().fname
                                        }
                                    >
                                        {i18next.t('buttons.29')}
                                    </PdfTable>
                                    <CustomBtn
                                        classN={`btn_1 w-50 red_btn`}
                                        handleClick={() => this.setState({ showPaiConfirm: true })}
                                        loading={orderLoading}
                                        disabled={this.isOrderStatusShi()}
                                    >
                                        {i18next.t('buttons.49')}
                                    </CustomBtn>
                                </div>
                            </>
                        )}
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.transporteur'),
            render: () => {
                const { itemData = {}} = this.state;

                return (
                    <Tab.Pane attached={false}>
                        <CarrierTab
                            itemData={itemData}
                        />
                    </Tab.Pane>
                );
            },
        },
    ];

    componentDidMount() {
        this.props.livraisonClientStart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.updateStatusLoading && !this.props.updateStatusLoading) {
            this.props.livraisonClientStart();
        }

        if(prevProps.livraisonClientData !== this.props.livraisonClientData) {
            const itemData = this.getItemData();
            this.setState({ itemData });
        }
    }

    dataTableOpenPopUp = (e) => {
        this.props.livraisonClientSingleStart({
            webClientId: e.webClientId,
            shipAddressXrefId: e.shipAddressXrefId,
            billAddressXrefId: e.billAddressXrefId,
        });

        const itemData = this.getItemData(e);

        const shipmentBody = {
            shipmentDate: itemData.shipmentDate || Date.now(),
            packageNumber: itemData.packageNumber || '',
            shippedQuantity: itemData.shippedQuantity || '1',
            weight: (itemData.shipmentWeight || '')
                .toString()
                .replace('.', ','),
            comment: itemData.shipmentComment || '',
        };

        this.setState({
            showPopUp: true,
            userOrderID: e.orderId,
            itemData,
            shipmentBody,
        });
    };

    updateOrderstatus = () => {
        const { itemData } = this.state;
        this.props.updateOrderStatusStart(
            itemData.orderId,
            ORDER_STATUS_CODE.PAI,
            true,
        );
        this.setState({ showPaiConfirm: false })
    }

    getItemData = (order: any = this.state.itemData) => {
        return this.props.livraisonClientData.find(
                (item) => item.orderId == order.orderId,
            ) || {};
    }

    closePopUp = () => {
        this.setState({ showPopUp: false });
    };

    handleCreateShipment = () => {
        const { details: { storeDetails = {} } = {} } = this.props;
        const { orderId } = this.state.itemData;
        const carrierCode = storeDetails.carrier;
        const body = {
            ...this.state.shipmentBody,
            orderId,
            carrierCode,
            weight: this.state.shipmentBody.weight.replace(',', '.'),
        };

        this.setState({ loading: true });
        createOrderShipment(body)
            .then((res) => {
                if (res) {
                    this.props.livraisonClientStart();
                    this.setState({
                        alertMsg: i18next.t('alertMessage.2'),
                    });
                } else {
                    this.setState({
                        alertMsg: i18next.t('alertMessage.1'),
                    });
                }
            })
            .catch((e) =>
                this.setState({
                    alertMsg: i18next.t('alertMessage.1'),
                }),
            )
            .finally(() =>
                this.setState({ loading: false, showConfirm: false }),
            );
    };

    isOrderStatusShi = () => {
        const { itemData = {}} = this.state;
        return Boolean(
            itemData && itemData.orderStatus && itemData.orderStatus.code === ORDER_STATUS_CODE.SHI
        )
    }

    render() {
        const { livraisonClientData, isLoading } = this.props;
        const {
            showPopUp,
            showConfirm,
            alertMsg,
            showPaiConfirm
        } = this.state;

        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={livraisonClientData}
                        isLoading={isLoading}
                        columns={this.columns}
                        openPopUp={this.dataTableOpenPopUp}
                        buttons={['envoiclientbtn']}
                        conditionalRowStyles={this.conditionalRowStyles}
                        reloadDashboard={this.props.livraisonClientStart}
                        keyField={'livrasisonClient'}
                    />
                </div>
                {showPopUp ? (
                    <PopUp handleClose={this.closePopUp}>
                        <Tab menu={{ pointing: true }} panes={this.panes} />
                    </PopUp>
                ) : (
                    ''
                )}
                {showConfirm && (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showConfirm: false })
                        }
                        handleConfirm={this.handleCreateShipment}
                        text={i18next.t('Terminal.confirm.4')}
                    />
                )}
                {showPaiConfirm && (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showPaiConfirm: false })
                        }
                        handleConfirm={this.updateOrderstatus}
                        text={i18next.t('Terminal.confirm.27')}
                    />
                )}
                <InfoPopUp
                    open={!!alertMsg}
                    message={alertMsg}
                    handleClose={() => this.setState({ alertMsg: '' })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { livraisonClientData, livraisonClientSingleData, isLoading, isBookLoading, bookResult } =
        getLivraisonClientData(state);
    return {
        livraisonClientData,
        livraisonClientSingleData,
        isLoading,
        isBookLoading,
        bookResult,
        details: getAccountResource(state),
        orderLoading: updateOrderLoading(state),
        updateStatusLoading: updateOrderLoading(state),
    };
};

const mapDispatchToProps = (dispatch): typeof livraisonClientActionCreators => {
    return bindActionCreators({
        ...livraisonClientActionCreators,
        ...DatatableActionCreators
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LivraisonClient);
