import { TransfersTypes, TransfersActionCreators } from './actions';
import { AddToDeliveryCartActionCreators } from './../../deliveryBasketPopUp/redux/actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import {
    createStoreTransfer,
    CREATE_CLIENT_BOOK,
    CREATE_CLIENT_SHIPPING,
    CREATE_STORE_TRANSFER_SHIPMENT,
    fetchStoreTransfer,
    getAxiosHeaders,
    GET_STORE_TRANSFER_SHIPMENT,
    updateStoreTransfer,
} from '../../../utils/constants/service';
import {
    LOCAL_STORAGE,
    TRANSFER_STATUS_CODE,
} from '../../../utils/constants/variables';
import axios from 'axios';

export function* getTransfersData() {
    try {
        const data = yield call(fetchStoreTransfer);
        yield put(TransfersActionCreators.transfersSuccess(data));
    } catch (e) {
        yield put(TransfersActionCreators.transfersFailure(e.message));
    }
}

export function* createTransfer(params) {
    const { body, basket, storeType, updateStatus } = params.data;
    try {
        const data = yield call(() => createStoreTransfer(body));
        if (
            updateStatus ||
            (storeType === 'W' && data && data.storeTransferCode)
        ) {
            const status = updateStatus || TRANSFER_STATUS_CODE.ACK;
            yield put(
                TransfersActionCreators.updateTransferStart({
                    code: data.storeTransferCode,
                    status,
                }),
            );
        }
        if (basket) {
            localStorage.removeItem(LOCAL_STORAGE.deliveryBasket);
            yield put(
                AddToDeliveryCartActionCreators.addtodeliverycartSuccess({}),
            );
            yield put(TransfersActionCreators.createTransferSuccess(data));
        } else {
            yield put(TransfersActionCreators.createTransferSuccess(data));
            if (!updateStatus) {
                yield put(TransfersActionCreators.transfersStart());
            }
        }
    } catch (e) {
        yield put(TransfersActionCreators.createTransferFailure(e.message));
    }
}

export function* updateTransfer(params) {
    const { code, status } = params.data;

    try {
        const data = yield call(() => updateStoreTransfer(code, status));
        yield put(TransfersActionCreators.updateTransferSuccess(data));
        if (data) {
            yield put(TransfersActionCreators.transfersStart());
        }
    } catch (e) {
        yield put(TransfersActionCreators.updateTransferFailure(e.message));
    }
}

export function* getTransferShipments(params) {
    const { transferId } = params;
    try {
        const data = yield call(() => axios.get(`${GET_STORE_TRANSFER_SHIPMENT}?storeTransferId=${transferId}`,
            getAxiosHeaders()).then((res) => {
                return res.data;
            }))
        yield put(TransfersActionCreators.getTransferShipmentsSuccess(data));
    } catch (e) {
        yield put(TransfersActionCreators.getTransferShipmentsFailure(e.message));
    }
}

export function* createStoreTransferShipment(params) {
    const { shipmentData, orderBody, updateStatus } = params.data;

    try {
        let result = yield call(() => {
            return axios.post(CREATE_CLIENT_SHIPPING, shipmentData, getAxiosHeaders())
                .then(res => res.data)
        });
        const { body } = result || { body: {} };
        if(body.Result === "OK") {
            orderBody.carrierCode = body.TrackingCarrier;
            orderBody.labelUrl = body.LabelURL;
            orderBody.packageNumber = body.TrackingNumber;
            orderBody.shippingConfirmation = body.NewOrderID;
            orderBody.storeTransferId = orderBody.orderId;
            delete orderBody.orderId;
            result = yield call(() => {
                return axios.post(CREATE_STORE_TRANSFER_SHIPMENT, orderBody, getAxiosHeaders())
                    .then(res => res.data)
            });
            result = yield put(TransfersActionCreators.updateTransferStart({
                code: orderBody.storeTransferCode,
                status: TRANSFER_STATUS_CODE.SHI
            }));
        }
        yield put(TransfersActionCreators.getTransferShipmentsStart(orderBody.storeTransferId));
    } catch(e) {
        yield put(TransfersActionCreators.createStoreTransferShipmentFailure(e.message));
    }
}

export function* createStoreTransferBook(params) {
    try {
        const {
            data:
            { bookRequest, orderBody } = { bookRequest: {}, orderBody: {} }
        } = params;

        let result = yield call(() => {
            return axios.post(CREATE_CLIENT_BOOK, bookRequest, getAxiosHeaders())
                .then(res => res.data)
        });
        const { body } = result || { body: {} };
        if(body.Result === "OK") {
            orderBody.pickupConfirmation = +body.ConfirmationID;
            orderBody.storeTransferId = orderBody.orderId;
            delete orderBody.orderId;

            result = yield call(() => {
                return axios.post(CREATE_STORE_TRANSFER_SHIPMENT, orderBody, getAxiosHeaders())
                    .then(res => res.data)
            });
        }
        yield put(TransfersActionCreators.getTransferShipmentsStart(orderBody.storeTransferId));
        yield put(TransfersActionCreators.createStoreTransferShipmentSuccess(body));
    } catch (e) {
        yield put(TransfersActionCreators.createStoreTransferShipmentFailure(e.message));
    }
}

export function* transfersSaga() {
    yield takeLatest(TransfersTypes.TRANSFERS_START, getTransfersData);
    yield takeLatest(TransfersTypes.CREATE_TRANSFER_START, createTransfer);
    yield takeLatest(TransfersTypes.UPDATE_TRANSFER_START, updateTransfer);
    yield takeLatest(TransfersTypes.GET_TRANSFER_SHIPMENTS_START, getTransferShipments);
    yield takeLatest(TransfersTypes.CREATE_STORE_TRANSFER_SHIPMENT_START, createStoreTransferShipment);
    yield takeLatest(TransfersTypes.CREATE_STORE_TRANSFER_BOOK_START, createStoreTransferBook);
}

export default transfersSaga;
