import React from "react";
import {Icon} from "semantic-ui-react";
import i18next from "i18next";
import Select from "../../../common/select/select";
import {getActiveBasketName, parseData} from "../../../../helpers/helpers";
import Colorbar from "./colorbar";
import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";
import AddToCart from "../../../addToCart/container/addToCart";
import StoreLocator from "../../../location/container/storeLocator";
import SizeGuide from "./SizeGuide";
import {LOCAL_STORAGE, PRODUCT_MAX_QUANTITY} from "../../../../utils/constants/variables";
import Swiper from 'react-id-swiper';


interface Props {
    data: any,
    handleBasketToggle?: any,
    handleGetWebStock?: any,
    handleScrollToDescription?:any,
    handleDeliverytToggle:any,
    webInventory : number,
    account?:any,
    selectedSize?:any,
}

interface State {
    showColors: boolean,
    showDetails: boolean,
    selectedSize: string,
    selectedQuantity: any,
    price: any,
    productId: any,
    showStoreLocator: any,
    showSizeGuide: boolean,
    selectedElement: number,
    choosedSize: boolean,
    width: any,
}

export default class ProductInfo extends React.Component<Props> {
    state: State = {
        showColors: true,
        showDetails: false,
        selectedSize: "",
        selectedQuantity: 0,
        price: this.props.data.discountDetail ? this.props.data.listPriceAfterDiscount : this.props.data.startingPrice,
        productId: this.props.data.productId,
        showStoreLocator: false,
        showSizeGuide: false,
        selectedElement: 0,
        choosedSize: false,
        width: window.innerWidth,

    };
    componentDidMount(){
        const {data} = this.props;
        const sizes = data.sizes || [];
        if(sizes && sizes.length){
            const prod = data.sizes;
            const currentProduct =prod && prod.filter(item => item.productId === sizes[0].productId)[0]
            this.selectSize(currentProduct,0)
            this.selectQuantity(currentProduct,"1")
        }
        window.addEventListener("resize", this.handleResize);
    }
    componentDidUpdate(prevProps, prevState) {
        const {data} = this.props;
        const sizes = data && data.sizes || [];
        if(prevProps.data && data && data.productId !== prevProps.data.productId && sizes && sizes.length === 1) {
            const prod = data.sizes;
            const currentProduct =prod && prod.filter(item => item.productId === sizes[0].productId)[0]
            this.selectSize(currentProduct,0)
            this.selectQuantity(currentProduct,"1")
        }

        if(prevProps.data && data && prevProps.data.productId !== data.productId){
            this.setState({productId: this.props.data.productId})
        }
        if(this.state.showDetails !== prevState.showDetails && this.state.showDetails){
            const prod = data.sizes;
            const currentProduct =prod && prod.filter(item => item.productId === sizes[0].productId)[0]
            this.selectSize(currentProduct,0)
            this.selectQuantity(currentProduct,"1")
        }

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize =() => {this.setState({width: window.innerWidth})};

    toggleColors = () => {
        this.setState({showColors: !this.state.showColors})
    };

    toggleDetails = () => {
        this.setState({showDetails: !this.state.showDetails})
    };

    selectSize = (size: string = "", index) => {
         this.props.handleGetWebStock(size)
         const price = index === this.state.selectedSize ? this.getPrice(this.props.data) : this.getPrice(this.props.data.sizes[index]);
        this.setState({
            selectedSize: index === this.state.selectedSize ? "" : index,
            choosedSize: index !== this.state.selectedSize
        })
    };

    selectQuantity = (name, value) => {
        this.setState({selectedQuantity: value, selectedElement: value})
    };

    getPrice = (data) => {
        return data.discountDetail ? data.listPriceAfterDiscount : data.startingPrice;
    };

    toggleStoreLocator = () => {
        this.setState({showStoreLocator: !this.state.showStoreLocator});
    };
    toggleSizeGuide = () => {
        this.setState({showSizeGuide: !this.state.showSizeGuide});
    };
    handleAddedToCart = (basket) => {
        this.setState({showDetails: false});
        if(basket===getActiveBasketName()){
            this.props.handleBasketToggle()
        }else if(basket === LOCAL_STORAGE.deliveryBasket){

            this.props.handleDeliverytToggle()
        }


    };

    handleClickColor = () => {
        this.setState({choosedSize: true,});
    }

    render() {
        const {data,webInventory,account} = this.props;
        const params = {
            slidesPerView:1,
            // init: false,
            speed: 600,
            loop: data && data.length ,
            autoplay: {delay: 5000},
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            wrapperClass: "swiper-wrapper",
            containerClass: "swiper-container",
            setWrapperSize: true,
            resizeReInit: true,
        };

        const description: any = data.description ? parseData(data.description) : "";
        const sizes = data.sizes || [];
        const options: any[] = [];
        for (let i = 1; i <= PRODUCT_MAX_QUANTITY; i++) {
            options.push({value: i.toString(), text: i.toString()});
        }
        options.unshift({value: "", text: i18next.t("product.product.5")});
        const {showColors, showDetails, selectedSize, selectedQuantity, price, productId, showStoreLocator, showSizeGuide, choosedSize, selectedElement,width} = this.state;
        const cartData = {};
        let isMobile = width < 920;
        cartData[productId] = {
            name: data.name,
            canonicallUrl: data.canonicalUrl,
            canonicalMenu: data.canonicalMenu,
            size: selectedSize === "" ? {} : Array.isArray(data.sizes) && data.sizes.length ?  data.sizes[selectedSize] : {},
            quantity: selectedQuantity,
            price: price,
            maxQuantity: PRODUCT_MAX_QUANTITY,
            photo:data.thumbnail || data.photo,
            pack: data.pack || [],
            oldPrice: this.props.data.discountDetail ? this.props.data.startingPrice : ""
        };

        const listPriceAfterDiscount = selectedSize === "" ? data.listPriceAfterDiscount : data.sizes[selectedSize].listPriceAfterDiscount

        return (
            <div className="container">
                <div className="product-info">

                    <div className={`${ data &&  data.photosX60 && data.photosX60.length > 1? "zoomContent" :"with-slider"}`} >
                        {
                            data && data.photosX60 &&  data.photosX60.length > 1 ?
                                <div className="aws-sld.photo-zoom">
                                    <Swiper {...params} >
                                        {
                                            data && data.photosX60.map((item,i)=>
                                                <div  key={i}>
                                                    <Photo src={item}
                                                           outlet={data && data.outlet}
                                                           discountDetail={data && data.discountDetail}
                                                           isPromo={!!data.listPriceAfterDiscount}
                                                           onSpecial={data && data.onSpecial}
                                                           thumbnail={data && data.thumbnail}
                                                           alt={data && data.metaTitle}
                                                           zoom={true}
                                                           isMobile={isMobile}
                                                    />
                                                </div>
                                            )
                                        }

                                    </Swiper>
                                </div> :
                                <Photo src={data && data.photo}
                                       outlet={data && data.outlet}
                                       discountDetail={data && data.discountDetail}
                                       isPromo={!!data.listPriceAfterDiscount}
                                       onSpecial={data.onSpecial}
                                       thumbnail={data && data.thumbnail}
                                       alt={data && data.metaTitle}
                                       zoom={true}
                                       isMobile={isMobile}
                                />
                        }

                        {data && data.compositeCanonicalUrlForSingle  ?<div className="discount-box">
                            <span onClick={() => this.props.handleScrollToDescription()}>{i18next.t('product.parure.18')}</span>
                        </div>:null}
                    </div>
                    <div className="product-info--leftBox">
                        <div className="product-info-content product-info--leftBox--content">
                            <h1 className="product-info-title">{data.name}</h1>
                            <h4 className="product-category">{data.collection}</h4>
                            <h2 className="product-info-text">{description.length > 200 ? `${description.substring(0, 200)}...` : description}
                                <span onClick={() => this.props.handleScrollToDescription("span")}>{i18next.t('product.parure.11')}</span></h2>
                            {data.colors && data.colors.length > 1 ?
                                <div className="product-color">
                                    <h2 className="product-color-title"
                                        onClick={this.toggleColors}>{i18next.t('product.product.2')}</h2>
                                    {showColors &&
                                    <Colorbar
                                        data={data.colors}
                                        backLink={`${this.props.data.canonicalMenu}/${this.props.data.canonicalUrl}`}
                                        handleClickColor={this.handleClickColor}
                                    />
                                    }
                                </div>
                                :
                                ""
                            }
                            <div>
                            </div>
                            {
                                data.inSale === false ?   <div className="product-size">
                                        <h4 className="product-size-title no-size">{i18next.t('product.product.21')}</h4>
                                        <div className="show-store-locator"
                                             onClick={this.toggleStoreLocator}>{i18next.t('product.product.6')}</div>
                                    </div>
                                    :
                                    Array.isArray(sizes) && !sizes.length ?
                                        <div className="product-size">
                                            <h4 className="product-size-title no-size">{i18next.t('product.product.18')}</h4>
                                            <div className="show-store-locator"
                                                 onClick={this.toggleStoreLocator}>{i18next.t('product.product.6')}</div>
                                        </div>
                                        :
                                        <>
                                            <div className="product-size">
                                                <h4 className="product-size-title">{i18next.t('product.product.3')}</h4>
                                                <div className="product-size-item-content">
                                                    <div className="product-size-item-content-wrapper">
                                                        {
                                                            sizes.length === 1 ?
                                                                (
                                                                    <div className="product-size-item  active">
                                                                        <p>{sizes[0].size}</p>
                                                                    </div>
                                                                ) :
                                                                sizes.map((item, i) => <div
                                                                    className={`product-size-item ${selectedSize === i ? "active" : ""}`}
                                                                    onClick={() => this.selectSize(item, i)} key={i}>
                                                                    <p>{item.size}</p>
                                                                </div>)
                                                        }
                                                        {
                                                            sizes.map((item)=> item.altSize ?  <div className="AltSize"> {item.altSize}</div> : "")
                                                        }
                                                    </div>

                                                    <div className="show-store-locator"
                                                         onClick={this.toggleSizeGuide}>{i18next.t('product.product.4')}</div>
                                                </div>

                                            </div>
                                            <div className="product-count">
                                                <h4 className="product-count-title">{i18next.t('product.product.12')}</h4>
                                                <div className="product-count-content">
                                                    <Select  value={selectedQuantity.toString()}
                                                             options={options}
                                                             handleChange={this.selectQuantity}  />
                                                    <div className="show-store-locator"
                                                         onClick={this.toggleStoreLocator}>{i18next.t('product.product.6')}</div>
                                                </div>
                                            </div>
                                        </>

                            }

                            <div className="product-sell-content">
                                <div className="product-price">
                                    <div> {selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}</div>
                                    {
                                        Array.isArray(data.sizes) && data.sizes.length ?
                                            <Price
                                                startP={selectedSize === "" ? data.startingPrice : data.sizes[selectedSize].startingPrice}
                                                discount={selectedSize === "" ? data.discountDetail : data.sizes[selectedSize].discountDetail}
                                                newP={listPriceAfterDiscount}
                                                quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                                isPromo={!!listPriceAfterDiscount}
                                            />
                                            :
                                            <Price
                                                startP={data.startingPrice}
                                                discount={data.discountDetail}
                                                newP={data.listPriceAfterDiscount}
                                                quantity={1}
                                            />
                                    }
                                </div>
                                <div className="webInventory">
                                    <AddToCart
                                        buttonText={i18next.t('product.product.13')}
                                        data={cartData}
                                        isEnable={Number(selectedElement) && choosedSize}
                                        handleAddedToCard={this.handleAddedToCart}
                                        selectedSize={this.props.selectedSize}
                                    />
                                    <div className="webInventory--text">{i18next.t('Terminal.Receipt.Summary.webInventory')} <span className="webInventory--text--number">{webInventory}</span></div>
                                </div>

                            </div>
                            <div className="product-info-content-mobile">
                                <h4>
                                    {selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}
                                    {
                                        Array.isArray(data.sizes) && data.sizes.length ?
                                            <Price
                                                startP={selectedSize === "" ? data.startingPrice : data.sizes[selectedSize].startingPrice}
                                                discount={selectedSize === "" ? data.discountDetail : data.sizes[selectedSize].discountDetail}
                                                newP={selectedSize === "" ? data.listPriceAfterDiscount : data.sizes[selectedSize].listPriceAfterDiscount}
                                                quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                            />
                                            :
                                            ""
                                    }
                                </h4>
                                <div className="product-info-mobile-button">
                                    {!showDetails ?
                                        <button onClick={this.toggleDetails}>
                                            {i18next.t('product.product.16')}
                                        </button>
                                        :
                                        <>
                                            <AddToCart
                                                buttonText={i18next.t('product.product.16')}
                                                data={cartData}
                                                handleAddedToCard={this.handleAddedToCart}
                                            />
                                            <div className="product-popup-mobile open">
                                                <div className="product-popup-mobile-wrapper">
                                                    <Icon name="x" onClick={this.toggleDetails}></Icon>
                                                    <h4 className="product-popup-mobile-title">{i18next.t('product.product.3')}</h4>
                                                    <div
                                                        className="dflex flex-wrap align-items-center product-popup-mobile-sizes">
                                                        {
                                                            sizes.map((item, i) => <div
                                                                className={`product-size-item ${selectedSize === i ? "active" : ""}`}
                                                                onClick={() => this.selectSize(item, i)} key={i}>
                                                                {item.size}
                                                            </div>)
                                                        }
                                                    </div>
                                                    <a href="#"rel="noopener">{i18next.t('product.product.4')}</a>

                                                    <h4 className="product-popup-mobile-title">{i18next.t('product.product.12')}</h4>
                                                    <Select align="center" value={selectedQuantity} options={options}
                                                            handleChange={this.selectQuantity}  />

                                                    <div className="product-popup-mobile-price-content">
                                                        <h4>
                                                            {selectedQuantity < 1 ? i18next.t('catalogue.product.3') : ""}
                                                            {
                                                                Array.isArray(data.sizes) && data.sizes.length ?
                                                                    <Price
                                                                        startP={selectedSize === "" ? data.startingPrice : data.sizes[selectedSize].startingPrice}
                                                                        discount={selectedSize === "" ? data.discountDetail : data.sizes[selectedSize].discountDetail}
                                                                        newP={selectedSize === "" ? data.listPriceAfterDiscount : data.sizes[selectedSize].listPriceAfterDiscount}
                                                                        quantity={selectedQuantity < 1 ? 1 : selectedQuantity}
                                                                    />
                                                                    :
                                                                    ""
                                                            }
                                                        </h4>
                                                        <div className="show-store-locator"
                                                             onClick={this.toggleStoreLocator}>{i18next.t('product.product.6')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showStoreLocator &&
                <StoreLocator toggleStoreLocator={this.toggleStoreLocator}/>
                }
                {showSizeGuide &&
                <SizeGuide toggleStoreLocator={this.toggleSizeGuide}/>
                }
            </div>
        );
    }
}
