import React, {Component} from "react";
import i18next from "i18next";

interface Props {
    currentPage?: any,
    activeRow?: number,
    handleUpdatePage?: any,
    changeCurrentActiveRow?: any,
    keyField?:string,
}


export default class TableController extends Component<Props> {
    handleClickUpper = (status) => {
        let val =  this.props.activeRow || 0;
        let index = status === "plus" ? ++val : status === "minus" ? --val  : 0;
        if(index >= 0 && index < 15) {
            this.props.changeCurrentActiveRow(index);
        }
    };

    handleChangePage = (key,currentPage) => {
        const {keyField} = this.props
        const parent = document.querySelector(`.${keyField}`);
        const btn : any = parent && parent.querySelector(`#pagination-${key}-page`);
        if (btn) {
            let page = key === "previous" ? currentPage - 1 : key === "next" ?  currentPage + 1 : 0
            this.props.handleUpdatePage(0,page)
            btn.click();
        }
    }
    render() {
        const {currentPage} = this.props;
        return (
            <>
                <button className="btn_1 w-100"
                        onClick={() => this.handleClickUpper("minus")}>{i18next.t('Terminal.Block1.1')}</button>
                <button className="btn_1 w-100"
                        onClick={() => this.handleClickUpper("plus")}>{i18next.t('Terminal.Block1.2')}</button>
                <button className="btn_1 w-100"
                        onClick={() => this.handleChangePage("previous",currentPage)}> &#60; </button>
                <button className="btn_1 w-100"
                        onClick={() => this.handleChangePage("next",currentPage)}> &#62; </button>
            </>
        )
    }
}
