import {LocationSearchTypes, LocationSearchActionCreators} from "./actions";
import {takeLatest, put, call, all} from "redux-saga/effects";
import axios from 'axios';
import {LOCATIONSEARCH_URL} from "../../../utils/constants/service";
import {BRAND_ID} from "../../../utils/constants/variables";


export function* getLocationSearch(params) {
    const {location, country} = params;
    try {
            const headers = {headers: {
                    brand: BRAND_ID,
                    country: country
                }};

        const data = yield call(() => {
            return axios.get(`${LOCATIONSEARCH_URL}${location}`, headers)
                .then(res => res.data)
        });

        yield put(LocationSearchActionCreators.locationSearchSuccess(data));
    } catch (e) {
        yield put(LocationSearchActionCreators.locationSearchFailure(e.message));
    }
}

export function* locationSearchSaga() {
    yield takeLatest(LocationSearchTypes.LOCATION_SEARCH_START, getLocationSearch);
}

export default locationSearchSaga;