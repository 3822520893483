import i18next from 'i18next';
import React from 'react';
import { Tab } from 'semantic-ui-react';
import AppointmentService from './AppointmentService';
import AppointmentClient from './AppointmentClient';
import AppointmentOptions from './AppointmentOptions';

interface Props {
    closePopup: () => void;
    selectedAppointment: any;
}

const AppointmentContent: React.FC<Props> = ({
    closePopup,
    selectedAppointment
}) => {
    const panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.service'),
            render: () => (
                <AppointmentService
                    closePopup={closePopup}
                    selectedAppointment={selectedAppointment}
                />
            )
        },
        {
            menuItem: i18next.t('Terminal.dashboards.clientLastName'),
            render: () => (
                <AppointmentClient
                    closePopup={closePopup}
                    selectedAppointment={selectedAppointment}
                />
            )
        },
        {
            menuItem: i18next.t('Terminal.dashboards.optionTab'),
            render: () => (
                <AppointmentOptions
                    closePopup={closePopup}
                    selectedAppointment={selectedAppointment}
                />
            )
        }
    ]

    return (
        <div>
            <Tab menu={{pointing: true}} panes={panes} />
        </div>
    )
}

export default AppointmentContent;