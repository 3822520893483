import React, {useState, useEffect} from "react";
import {Input, Table} from "semantic-ui-react";
import i18next from "i18next";
import { priceThreeDigitSets } from '../../../../helpers/helpers';

interface IProps {
    currencySymbol: string,
    handelChangeWallet: (param)=>void,
    altWallet: boolean,
    wallet: any,
    walletPieces: any,
    previousDayPayment: number,
    isTerminalOpen: boolean,
    total?: string | number,
}

export const Wallet: React.FC<IProps> = ({
     currencySymbol, handelChangeWallet, altWallet, wallet, walletPieces,
     previousDayPayment, isTerminalOpen, total: propsTotal = 0
}: IProps) => {
    const walletName = altWallet ?"altWallet":"wallet";
    const walletPiecesName = altWallet ?"altwalletPieces":"walletPieces";
    const totalPriceName = altWallet ?"altTotalPrice":"totalPrice";
    const totalName = altWallet ? "altTotal" : "total";
    const [totalPrice, setTotalPrice] = useState(0)
    const [total, setTotal] = useState<string | number>(0)

    useEffect(() => {
        const totalPrice = [...wallet, ...walletPieces].reduce((acc, item) => acc + item.value * item.price, 0)
        setTotalPrice(totalPrice)
    }, [])

    useEffect(() => {
        setTotal(propsTotal)
    }, [propsTotal])

    useEffect(() => {
        if(isTerminalOpen && previousDayPayment) {
            setTotal(previousDayPayment)
            handelChangeWallet({[totalName]:previousDayPayment.toFixed(2)})
        }
    }, [previousDayPayment])

    const handleChangeVal = (e,index,name) => {
        if (name==="wallet") {
            const currWallet = wallet
            currWallet[index].value = e.target.value < 0 ? Math.abs(e.target.value) : e.target.value;
            handelChangeWallet({[walletName]:currWallet})
        } else if (name==="walletPieces") {
            const currWallet = walletPieces
            currWallet[index].value = e.target.value < 0 ? Math.abs(e.target.value) : e.target.value;
            handelChangeWallet({[walletPiecesName]:currWallet})
        }
        let totalPriceBilets = 0;
        let totalPricePiecesBilets = 0;

        walletPieces.forEach(item => {
            totalPricePiecesBilets += item.price * item.value
        });
        wallet.forEach(item => {
            totalPriceBilets += item.price * item.value
        });
        const total = totalPricePiecesBilets + totalPriceBilets;
        setTotalPrice(total)
        const WalletData = {
            [walletName]: wallet,
            [walletPiecesName]: walletPieces,
            [totalPriceName]: total,
        }
        handelChangeWallet(WalletData)
    }

    const handleChangeTotal = (e) => {
        const {value} = e.target;
        setTotal(value)
        handelChangeWallet({[totalName]:value})
    }

    const handelFocus =(e,index,name) =>{
        const value = e.target.value;
        if(value === "0"){
            if(name==="wallet"){
                const currWallet =wallet
                currWallet[index].value = "";
                handelChangeWallet({[walletName]:currWallet})
            }else if(name==="walletPieces"){
                const currWallet =walletPieces
                currWallet[index].value =  "";
                handelChangeWallet({[walletPiecesName]:currWallet})
            }
        }
    }

    const handelBlur =(e,index,name) =>{
        const value = e.target.value;
        if(value === ""){
            if(name==="wallet"){
                const currWallet =wallet
                currWallet[index].value = 0;
                handelChangeWallet({[walletName]:currWallet})
            }else if(name==="walletPieces"){
                const currWallet =walletPieces
                currWallet[index].value =  0;
                handelChangeWallet({[walletPiecesName]:currWallet})
            }
        }
    }

    return (
        <>
            <div className="table-store-counter--Content">
                <div className="table-store-counter--Content--box ">
                <Table celled collapsing className="w-100 ">
                    <Table.Body>
                        {
                            wallet.map((item, index)=> (
                                <React.Fragment key={item.key}>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(`Terminal.terminalOpeningBillets.${index}`,{Currency: currencySymbol})}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description" >
                                            <Input type='number'  value={item.value}
                                                   onChange={(e) => handleChangeVal(e, index,"wallet")}
                                                   onFocus={(e)=>{handelFocus(e, index,"wallet")}}
                                                   onBlur={(e)=>{handelBlur(e, index,"wallet")}}
                                                   name={item.key}/>
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            ))
                        }

                    </Table.Body>
                </Table>
            </div>
                <div className="table-store-counter--Content--box">
                <Table celled collapsing className="w-100 ">
                    <Table.Body>
                        {
                            walletPieces.map((item, index) => (
                                <React.Fragment key={item.key}>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(`Terminal.terminalOpeningPieces.${index}`,{Currency: currencySymbol})}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            <Input type='number' placeholder='Value...'
                                                   value={item.value}
                                                   onChange={(e) => handleChangeVal(e, index,"walletPieces")}
                                                   onFocus={(e)=>{handelFocus(e, index,"walletPieces")}}
                                                   onBlur={(e)=>{handelBlur(e, index,"walletPieces")}}
                                                   name={item.key}/>
                                        </Table.Cell>
                                    </Table.Row>
                                </React.Fragment>
                            ))
                        }

                    </Table.Body>
                </Table>
            </div>
            </div>
            <div  className="table-store-counter--Content">
                <div className="table-store-counter--Content--box">
                    <Table celled collapsing className="w-100 table-store-counter">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className="fermeturePopUp--body--title blueText">
                                    {i18next.t(`Terminal.terminalOpeningBillets.Sum`)}
                                </Table.Cell>
                                <Table.Cell className="fermeturePopUp--body--description blueText disabled">
                                    <Input
                                        disabled={false}
                                        placeholder='Value...'
                                        value={priceThreeDigitSets(totalPrice, "", currencySymbol)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="fermeture--total">
                                <Table.Cell className="fermeturePopUp--body--title">
                                    {i18next.t('Terminal.terminalOpeningBillets.Total')}
                                </Table.Cell>
                                <Table.Cell className={`fermeturePopUp--body--description disabled`}>
                                    <Input
                                        name="total"
                                        value={priceThreeDigitSets(total, "", currencySymbol) || 0 + currencySymbol}
                                        onChange={(e) => handleChangeTotal(e)}
                                        disabled={false}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}

