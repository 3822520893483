import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Table } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { StockActionCreators } from '../../redux/actions';
import { getImportLoadInfo, getStockProducts } from '../../redux/selectors';
import { getDateTimestampByTime } from '../../../../helpers/helpers';
import ImportProductAction from './importProductActionPanel';
import ImportStatus from './importStatus';
import CustomBtn from '../../../common/customBtn';
import PopUp from '../../../popUp/popUp';
import Pagination from '../../../common/pagination';
import InfoPopUp from '../../../common/infoPopUp';

interface Props {
	getStockProductsStart: () => void;
	importStockProductsFailure: (message: any) => void;
	importProductDetailsStart: (id: number) => void;
	stockProducts: any;
	stockImportInfo: any;
	isService?: boolean;
}

type MessageType = {
	message: string;
	className: string;
}

const ImportProducts: React.FC<Props> = ({
	getStockProductsStart,
	importStockProductsFailure,
	importProductDetailsStart,
	stockProducts,
	stockImportInfo,
	isService = false
}) => {
	const pageSize = 10;
	const totalCount = stockProducts.length;
	const [isImportPanelVisible, setIsImportPanelVisible] = useState<boolean>(false);
	const [isImportStatusPanelVisible, setIsImportStatusPanelVisible] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [isInfoPopupVisible, setIsInfoPopupVisible] = useState<boolean>(false);
	const [messageInfo, setMessageInfo] = useState<MessageType>({
		message: '',
		className: 'message-success',
	});

	useEffect(() => {
		loadStockProducts();
	}, []);

	useEffect(() => {
		if (stockImportInfo && stockImportInfo.status) {
			setInfoMessage(
				stockImportInfo.status === 'SUCCESS'
					? i18next.t('alertMessage.14')
					: stockImportInfo.message,
				stockImportInfo.status
			);
		}
	}, [stockImportInfo]);

	const loadStockProducts = () => {
		getStockProductsStart();
	}

	const toggleImportPanel = () => setIsImportPanelVisible(!isImportPanelVisible);
	const toggleImportStatusPanel = () => setIsImportStatusPanelVisible(!isImportStatusPanelVisible);
	const toggleInfoPopup = (isOpen: boolean = !isInfoPopupVisible) => setIsInfoPopupVisible(isOpen);

	const getImportClassName = (status: string = '') => {
		switch (status) {
			case 'SUCCESS':
				return 'message-success';
			case 'FAILED':
				return 'message-error';
			case 'IN_PROGRESS':
				return 'message-pending';
			default:
				return 'message-error'
		}
	}

	const setInfoMessage = (message: string = '', status: string) => {
		const className = getImportClassName(status);
		setMessageInfo({
			message,
			className
		});
		toggleInfoPopup(true);
	};

	const showImportStatus = (productId: number) => {
		toggleImportStatusPanel();
		importProductDetailsStart(productId);
	};

	return (
		<div>
			<Table
				celled
				selectable
				className="productInfoBlock--infoBox--table collaps"
			>
				<Table.Header>
					<Table.Row>
						<Table.Cell className="productInfoBlock--infoBox--table--title">
							{i18next.t(
								'Terminal.dashboards.id',
							)}
						</Table.Cell>
						<Table.Cell className="productInfoBlock--infoBox--table--title">
							{i18next.t(
								'Terminal.dashboards.date',
							)}
						</Table.Cell>
						<Table.Cell className="productInfoBlock--infoBox--table--title">
							{i18next.t(
								'Terminal.dashboards.filename',
							)}
						</Table.Cell>
						<Table.Cell className="productInfoBlock--infoBox--table--title">
							{i18next.t(
								'Terminal.dashboards.fileType',
							)}
						</Table.Cell>
						<Table.Cell className="productInfoBlock--infoBox--table--title">
							{i18next.t(
								'Terminal.dashboards.comment',
							)}
						</Table.Cell>
						<Table.Cell className="productInfoBlock--infoBox--table--title">
							{i18next.t(
								'Terminal.dashboards.status',
							)}
						</Table.Cell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{stockProducts.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item) => (
						<Table.Row
							key={item.productFileImportId}
							onClick={showImportStatus.bind(null, item.productFileImportId)}
						>
							<Table.Cell className="productInfoBlock--infoBox--table--title">
								{item.productFileImportId}
							</Table.Cell>
							<Table.Cell className="productInfoBlock--infoBox--table--title">
									{getDateTimestampByTime(item.date, 'MM dd, YYYY')}
							</Table.Cell>
							<Table.Cell className="productInfoBlock--infoBox--table--title">
								{item.fileName}
							</Table.Cell>
							<Table.Cell className="productInfoBlock--infoBox--table--title">
								{item.fileType}
							</Table.Cell>
							<Table.Cell className="productInfoBlock--infoBox--table--title">
								{item.comment}
							</Table.Cell>
							<Table.Cell className={getImportClassName(item.status)}
							>
								{item.status}
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			{(totalCount || "") && (
				<Pagination
					onChangePage={
						(currentPage) => setCurrentPage(currentPage - 1)
					}
					totalCount={totalCount}
					perPage={pageSize}
					resetDefault={false}
				/>
			)}
			<div className="buttons-container d-flex">
				<CustomBtn
					disabled={false}
					classN="btn_1 w-50 blue_btn"
					handleClick={loadStockProducts}
					loading={false}
				>
					{i18next.t('buttons.36')}
				</CustomBtn>
				<CustomBtn
					disabled={false}
					classN="btn_1 w-50 green_btn"
					handleClick={toggleImportPanel}
					loading={false}
				>
					{i18next.t('buttons.53')}
				</CustomBtn>
			</div>
			{isImportPanelVisible && (
				<PopUp
					handleClose={toggleImportPanel}
					classN="exportStock import-product"
				>
					<ImportProductAction
						handleClose={toggleImportPanel}
						isService={isService}
					/>
				</PopUp>
			)}
			{isImportStatusPanelVisible && (
				<PopUp
					handleClose={toggleImportStatusPanel}
					classN="exportStock import-product"
				>
					<ImportStatus
						handleClose={toggleImportStatusPanel}
					/>
				</PopUp>
			)}
			<InfoPopUp
				classN={messageInfo.className}
                open={isInfoPopupVisible}
                message={messageInfo.message}
                handleClose={() => {
					toggleInfoPopup(false);
					importStockProductsFailure('');
				}}
            />
		</div>
	)
}
const mapStateToProps = (state: any): any => ({
	stockProducts: getStockProducts(state),
	stockImportInfo: getImportLoadInfo(state)
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
	return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportProducts);