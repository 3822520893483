import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Tab, Table, Dropdown } from 'semantic-ui-react';
import ReportPdfBtn from '../pdfGenerator/reportPdfBtn';
import PopUp from '../popUp/popUp';
import DayPicker from '../common/dayPicker';
import CustomBtn from '../common/customBtn';
import PrintNullBelegBtn from '../nullBelegBtn/container/printNullBelegBtn';
import {
    getXreportData,
    businessPremisesLogs,
} from '../../utils/constants/service';
import {
    fixedInHundredth,
    getCurrency,
    getDateNow,
    getDateTimestampByTime,
    isFloat,
    replaceComma,
    str2bytes,
} from '../../helpers/helpers';
import { changeFormatHourly } from '../../helpers/printer';
import { STORE_COUNTRIES, YEAR_DROPDOWN, MONTH_DROPDOWN } from '../../utils/constants/variables';
import MonthlyReportPdf from '../pdfGenerator/monthlyReportPdf';

const XReportSidePane = (props) => {
    const {month, year} = getDateNow();
    const { handleClose, details } = props,
        { storeDetails = {}, printer } = details,
        [loading, setLoading] = useState(true),
        [tabLoading, setTabLoading] = useState(false),
        [loadingExport, setLoadingExport] = useState(false),
        [data, setData] = useState<any>({}),
        [day, setDay] = useState(Date.now()),
        [saleDay, setSaleDay] = useState(Date.now()),
        [error, setError] = useState(''),
        [monthlyReportData, setMonthlyReportData] = useState({month, year});

    const fetchData = (timeFrame?, saleDay?) => {
        return getXreportData(undefined, saleDay, timeFrame)
            .then((res) => {
                setLoading(false);
                setData(res);
            })
            .catch(() => {
                setLoading(false);
                setTabLoading(false);
            });
    };

    useEffect(() => {
        setTabLoading(false);
    }, [props.paymentsReportData]);

    useEffect(() => {
        fetchData();
    }, []);

    const onTabChange = (e, { activeIndex }) => {
        const tabVal = { 2: 'D', 3: "W", 5: 'Y', 6: 'P' };
        const { details: { altCurrency, currency } } = props;
        const {month, year} = getDateNow();
        setMonthlyReportData({month, year});

        if (tabVal[activeIndex] || activeIndex === 0) {
            setTabLoading(true);
            fetchData(
                tabVal[activeIndex],
                activeIndex === 2 || activeIndex === 3
                    ? getDateTimestampByTime(
                          new Date(saleDay).getTime(),
                          'yy-mm-dd',
                      )
                    : undefined,
            ).then(() => setTabLoading(false));
        }
        if (activeIndex === 1) {
            setTabLoading(true);
            props.getPaymentReports(altCurrency || currency);
        } else if (activeIndex === 4) {
            getMonthZReport();
        }
    };

    const exportHistoryLogs = () => {
        const name = `tss_${details.terminalCode}_${getDateTimestampByTime(
                new Date(day).getTime(),
                'YYYYMMDD',
            )}`,
            FileSaver = require('file-saver');
        setLoadingExport(true);
        setError('');
        businessPremisesLogs()
            .then((data) => {
                FileSaver.saveAs(new Blob([str2bytes(data)]), `${name}.zip`);
                setLoadingExport(false);
            })
            .catch((err) => {
                setError(
                    err.response && err.response.data
                        ? err.response.data.description
                        : '',
                );
                setLoadingExport(false);
            });
    };

    const getSafeData = () => {
        const { paymentsReportData, details: { altCurrency, currency } } = props;
        let cashSafe = 0, checkSafe = 0, giftSafe = 0, altCashSafe = 0, altCheckSafe = 0, altGiftSafe = 0

        if (paymentsReportData[currency] && paymentsReportData[currency].previousDaySafe && paymentsReportData[currency].previousDaySafe) {
            cashSafe = paymentsReportData[currency].previousDaySafe.cashSafe || 0;
            checkSafe = paymentsReportData[currency].previousDaySafe.checkSafe || 0;
            giftSafe = paymentsReportData[currency].previousDaySafe.giftSafe || 0;
        }

        if (paymentsReportData[altCurrency] && paymentsReportData[altCurrency].previousDaySafe && paymentsReportData[currency].previousDaySafe) {
            altCashSafe = paymentsReportData[altCurrency].previousDaySafe.cashSafe || 0;
            altCheckSafe = paymentsReportData[altCurrency].previousDaySafe.checkSafe || 0;
            altGiftSafe = paymentsReportData[altCurrency].previousDaySafe.giftSafe || 0;
        }

        return { cashSafe, altCashSafe, checkSafe, altCheckSafe, altGiftSafe, giftSafe };
    }

    const getMonthZReport = () => {
        setTabLoading(true);
        setData({});
        const date = getDateTimestampByTime(new Date(monthlyReportData.year, +monthlyReportData.month, 0).getTime(), 'yy-mm-dd');
        fetchData('M',date).then(() => setTabLoading(false));
    }

    const getTotalsData = (totals) => {
        if (!totals || !Object.keys(totals).length) return [];
        const totalsData: any = [];
        Object.keys(totals).forEach((item) => {
            if (
                [
                    'taxRate',
                    'totalEcoTax',
                    'paymentNetTotal',
                    'specialDiscountAmountTotal',
                    'prepayment',
                ].includes(item)
            ) {
                return;
            }
            let value: any = '';
            if (
                [
                    'totalTickets',
                    'saleQuantityTotal',
                    'saleQuantityTotalReturn',
                    'saleQuantityAvg',
                    'giftCardQty'
                ].includes(item)
            ) {
                value = isFloat(totals[item])
                    ? totals[item].toFixed(2)
                    : totals[item];
            } else if (item === 'transformationRate') {
                value = totals[item] * 100 + '%';
            } else {
                value = `${replaceComma(totals[item])} ${getCurrency()} `;
            }

            totalsData.push({
                name: i18next.t(`Terminal.Receipt.zReport.${item}`),
                value,
            });
        });

        totalsData.splice(
            1,
            0,
            {
                name: i18next.t(`Terminal.Receipt.zReport.paymentNetTotal`),
                value: `${replaceComma(
                    totals['paymentAmountTotal'] -
                        totals['specialDiscountAmountTotal'],
                )} ${getCurrency()} `,
            },
            {
                name: i18next.t(`Terminal.Receipt.zReport.remises`),
                value: `${replaceComma(
                    totals['specialDiscountAmountTotal']
                        ? totals['specialDiscountAmountTotal']
                        : 0,
                )} ${getCurrency()} `,
            },
            {
                name: i18next.t(`Terminal.Receipt.zReport.withOutTax`),
                value: `${replaceComma(
                    totals['paymentNetTotal'] - totals['paymentTaxTotal'],
                )} ${getCurrency()} `,
            },
        );

        totalsData.push({
            name: i18next.t('Terminal.Receipt.prePayment.Credit'),
            value: `${replaceComma(totals.prepayment)} ${getCurrency()} `,
        });

        return totalsData;
    };

    const {
        totals = {},
        payments = [],
        associates = [],
        hourlySales = [],
        totalsMonth = {},
        totalsYear = {},
        totalsPerpetual = {},
        paymentsMonth = [],
        paymentsYear = [],
        paymentsPerpetual = [],
        totalsWeek = [],
        paymentsWeek = []
    } = data;
    const totalsData = getTotalsData(totals);
    const totalsMonthData = getTotalsData(totalsMonth);
    const totalsWeekData = getTotalsData(totalsWeek)
    const totalsYearData = getTotalsData(totalsYear);
    const totalsPerpetualData = getTotalsData(totalsPerpetual);
    const { cashSafe, altCashSafe, checkSafe, altCheckSafe, altGiftSafe, giftSafe } = getSafeData()
    const { paymentsReportData } = props;

    const dayContent = () => {
        return (
            <div className="table-store-counter--Content">
                <div className="table-store-counter--Content--box">
                    {totalsData.length ? (
                        <Table
                            celled
                            collapsing
                            className="w-100 table-store-counter orderedTable"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan="2"
                                        className="fermeturePopUp--body--title blueText text-center"
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.zReport.totals',
                                        )}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {totalsData.map((item, idx) => {
                                    return (
                                        <Table.Row key={idx}>
                                            <Table.Cell className="fermeturePopUp--body--title">
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell className="fermeturePopUp--body--description">
                                                {item.value}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                    {payments && payments.length ? (
                        <Table
                            celled
                            collapsing
                            className="w-100 table-store-counter"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan="3"
                                        className="fermeturePopUp--body--title blueText text-center"
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.zReport.payments',
                                        )}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {payments.map((item, idx) => {
                                    return (
                                        <Table.Row key={idx}>
                                            <Table.Cell className="fermeturePopUp--body--title">
                                                {item.paymentMethod}
                                            </Table.Cell>
                                            <Table.Cell className="fermeturePopUp--body--title">
                                                {item.paymentMethodCount}
                                            </Table.Cell>
                                            <Table.Cell className="fermeturePopUp--body--description">
                                                {`${replaceComma(
                                                    item.paymentAmount,
                                                )} ${getCurrency()}`}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}

                    {associates && associates.length ? (
                        <Table
                            celled
                            collapsing
                            className="w-100 table-store-counter"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan="2"
                                        className="fermeturePopUp--body--title blueText text-center"
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.zReport.associateFirstName',
                                        )}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {associates.map((item, idx) => {
                                    return (
                                        <Table.Row key={idx}>
                                            <Table.Cell className="w-50 fermeturePopUp--body--title">
                                                {item.associateFirstName}
                                            </Table.Cell>
                                            <Table.Cell className="fermeturePopUp--body--description">
                                                {`${replaceComma(
                                                    item.paymentNetTotal,
                                                )} ${getCurrency()} `}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                    {hourlySales && hourlySales.length ? (
                        <Table
                            celled
                            collapsing
                            className="w-100 table-store-counter"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        colSpan="2"
                                        className="fermeturePopUp--body--title blueText text-center"
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.zReport.hourlySales',
                                        )}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {hourlySales.map((item, idx) => {
                                    return (
                                        <Table.Row key={idx}>
                                            <Table.Cell className="w-50 fermeturePopUp--body--title">
                                                {changeFormatHourly(item.hour)}
                                            </Table.Cell>
                                            <Table.Cell className="fermeturePopUp--body--description">
                                                {`${replaceComma(
                                                    item.paymentNetTotal,
                                                )} ${getCurrency()} `}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    };

    const changeReportData = (event, data) => {
        setMonthlyReportData((prev) => ({
            ...prev,
            [data.name]: data.value
        }));
    }
    const panes = [
        {
            menuItem: i18next.t('Terminal.Receipt.zReport.today'),
            render: () => (
                <Tab.Pane attached={false} loading={tabLoading}>
                    {dayContent()}
                    <ReportPdfBtn
                        totals={totalsData}
                        payments={payments}
                        associates={associates}
                        hourlySales={hourlySales}
                        disabled={false}
                        details={details}
                        saleDay={`${monthlyReportData.month}/${monthlyReportData.year}`}
                        title={i18next.t('Terminal.Receipt.zReport.today')}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t("Terminal.terminalOpeningBillets.safeTitle"),
            render: () => (
                <Tab.Pane attached={false} loading={tabLoading}>
                    {Object.keys(paymentsReportData).map((currency, idx) => {
                        const isDefault = currency === details.currency
                        return (
                            <Table celled collapsing className="w-100 table-store-counter" key={idx}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title blueText">
                                            {i18next.t("Terminal.terminalOpeningBillets.previousDaySafe")}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                            {i18next.t("Terminal.dashboards.saleAmount")}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="fermeturePopUp--body--title">
                                            {i18next.t(`Terminal.dashboards.currency`)}
                                        </Table.Cell>
                                        <Table.Cell className="fermeturePopUp--body--description">
                                            {isDefault ? details.currency : details.altCurrency}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell
                                            className="fermeturePopUp--body--title">
                                            {i18next.t(`Terminal.openClose.Cash`)}
                                        </Table.Cell>
                                        <Table.Cell
                                            className="fermeturePopUp--body--description">
                                            {fixedInHundredth(isDefault ? cashSafe : altCashSafe).toFixed(2)}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell
                                            className="fermeturePopUp--body--title">
                                            {i18next.t(`Terminal.terminalOpeningBillets.check`)}
                                        </Table.Cell>
                                        <Table.Cell
                                            className="fermeturePopUp--body--description">
                                            {fixedInHundredth(isDefault ? checkSafe : altCheckSafe).toFixed(2)}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row >
                                        <Table.Cell
                                            className="fermeturePopUp--body--title">
                                            {i18next.t(`Terminal.terminalOpeningBillets.giftSafe`)}
                                        </Table.Cell>
                                        <Table.Cell
                                            className="fermeturePopUp--body--description">
                                            {fixedInHundredth(isDefault ? giftSafe : altGiftSafe).toFixed(2)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )
                    })}
                </Tab.Pane>
            )
        },
        {
            menuItem: i18next.t('Terminal.Receipt.zReport.saleDay'),
            render: () => (
                <Tab.Pane attached={false} loading={tabLoading}>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header w-50">
                            <DayPicker
                                value={saleDay}
                                onChange={(day: number) => setSaleDay(day)}
                            />
                        </div>
                        <div
                            className={
                                'popUp--body--card--body--data--description clienttab input w-50'
                            }
                        >
                            <CustomBtn
                                classN="btn_1 w-50 blue_btn"
                                handleClick={() =>
                                    onTabChange(null, { activeIndex: 2 })
                                }
                            >
                                {i18next.t('Terminal.Block3.2')}
                            </CustomBtn>
                        </div>
                    </div>
                    {dayContent()}
                    <ReportPdfBtn
                        totals={totalsData}
                        payments={payments}
                        associates={associates}
                        hourlySales={hourlySales}
                        disabled={false}
                        details={details}
                        saleDay={getDateTimestampByTime(saleDay, 'dd/mm/yy')}
                        title={i18next.t('Terminal.Receipt.zReport.saleDay')}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.Receipt.zReport.week'),
            render: () => (
                <Tab.Pane loading={tabLoading} attached={false}>
                    <div className="table-store-counter--Content">
                        <div className="table-store-counter--Content--box">
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header w-50">
                                    <DayPicker
                                        value={saleDay}
                                        onChange={(day: number) => setSaleDay(day)}
                                    />
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description clienttab input w-50'
                                    }
                                >
                                    <CustomBtn
                                        classN="btn_1 w-50 blue_btn"
                                        handleClick={() =>
                                            onTabChange(null, { activeIndex: 3 })
                                        }
                                    >
                                        {i18next.t('Terminal.Block3.2')}
                                    </CustomBtn>
                                </div>
                            </div>
                            {Array.isArray(totalsWeekData) &&
                            totalsWeekData.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter orderedTable"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="2"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.totals',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {totalsWeekData.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.name}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {item.value}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                            {paymentsWeek && paymentsWeek.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="3"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.payments',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {paymentsWeek.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.paymentMethod}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {
                                                            item.paymentMethodCount
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {`${replaceComma(
                                                            item.paymentAmount,
                                                        )} ${getCurrency()}`}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <ReportPdfBtn
                        totals={totalsWeekData}
                        payments={paymentsWeek}
                        associates={[]}
                        hourlySales={[]}
                        disabled={false}
                        details={details}
                        saleDay={getDateTimestampByTime(saleDay, 'dd/mm/yy')}
                        title={i18next.t('Terminal.Receipt.zReport.week')}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.Receipt.zReport.month'),
            render: () => (
                <Tab.Pane loading={tabLoading} attached={false}>
                    <div className="table-store-counter--Content">
                        <div className="table-store-counter--Content--box">
                            <div className="d-start-row">
                                <div className="popUp--body--card--body--data--header w-auto">
                                    <div className="mb-1 text-center">{i18next.t('Terminal.Receipt.zReport.year')}</div>
                                    <Dropdown
                                        options={YEAR_DROPDOWN}
                                        selection
                                        compact
                                        name="year"
                                        value={monthlyReportData.year}
                                        onChange={changeReportData}
                                    />
                                </div>
                                <div className="popUp--body--card--body--data--header w-auto">
                                    <div className="mb-1 text-center">{i18next.t('Terminal.Receipt.zReport.month')}</div>
                                    <Dropdown
                                        options={MONTH_DROPDOWN}
                                        selection
                                        compact
                                        name="month"
                                        value={monthlyReportData.month}
                                        onChange={changeReportData}
                                    />
                                </div>
                            </div>
                            {Array.isArray(totalsMonthData) &&
                            totalsMonthData.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter orderedTable"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="2"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.totals',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {totalsMonthData.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.name}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {item.value}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                            {paymentsMonth && paymentsMonth.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="3"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.payments',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {paymentsMonth.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.paymentMethod}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {
                                                            item.paymentMethodCount
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {`${replaceComma(
                                                            item.paymentAmount,
                                                        )} ${getCurrency()}`}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <ReportPdfBtn
                        totals={totalsMonthData}
                        payments={paymentsMonth}
                        associates={[]}
                        hourlySales={[]}
                        disabled={false}
                        details={details}
                        saleDay={`${monthlyReportData.month}/${monthlyReportData.year}`}
                        title={i18next.t('Terminal.Receipt.zReport.month')}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.Receipt.zReport.year'),
            render: () => (
                <Tab.Pane loading={tabLoading} attached={false}>
                    <div className="table-store-counter--Content">
                        <div className="table-store-counter--Content--box">
                            {Array.isArray(totalsYearData) &&
                            totalsYearData.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter orderedTable"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="2"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.totals',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {totalsYearData.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.name}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {item.value}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                            {paymentsYear && paymentsYear.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="3"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.payments',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {paymentsYear.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.paymentMethod}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {
                                                            item.paymentMethodCount
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {`${replaceComma(
                                                            item.paymentAmount,
                                                        )} ${getCurrency()}`}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <ReportPdfBtn
                        totals={totalsYearData}
                        payments={paymentsYear}
                        associates={[]}
                        hourlySales={[]}
                        disabled={false}
                        details={details}
                        saleDay={`${monthlyReportData.month}/${monthlyReportData.year}`}
                        title={i18next.t('Terminal.Receipt.zReport.year')}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.Receipt.zReport.perpetaul'),
            render: () => (
                <Tab.Pane loading={tabLoading} attached={false}>
                    <div className="table-store-counter--Content">
                        <div className="table-store-counter--Content--box">
                            {Array.isArray(totalsPerpetualData) &&
                            totalsPerpetualData.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter orderedTable"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="2"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.totals',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {totalsPerpetualData.map(
                                            (item, idx) => {
                                                return (
                                                    <Table.Row key={idx}>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {item.name}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            {item.value}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            },
                                        )}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                            {paymentsPerpetual && paymentsPerpetual.length ? (
                                <Table
                                    celled
                                    collapsing
                                    className="w-100 table-store-counter"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                colSpan="3"
                                                className="fermeturePopUp--body--title blueText text-center"
                                            >
                                                {i18next.t(
                                                    'Terminal.Receipt.zReport.payments',
                                                )}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {paymentsPerpetual.map((item, idx) => {
                                            return (
                                                <Table.Row key={idx}>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {item.paymentMethod}
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                        {
                                                            item.paymentMethodCount
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                        {`${replaceComma(
                                                            item.paymentAmount,
                                                        )} ${getCurrency()}`}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <ReportPdfBtn
                        totals={totalsPerpetualData}
                        payments={paymentsPerpetual}
                        associates={[]}
                        hourlySales={[]}
                        disabled={false}
                        details={details}
                        saleDay={`${monthlyReportData.month}/${monthlyReportData.year}`}
                        title={i18next.t('Terminal.Receipt.zReport.perpetaul')}
                    />
                </Tab.Pane>
            ),
        },
        storeDetails.country !== STORE_COUNTRIES.it && printer
            ? {
                  menuItem: i18next.t('Terminal.Receipt.zReport.history'),
                  render: () => (
                      <Tab.Pane attached={false}>
                          <div className="parent-for-comment">
                              <div className="popUp--body--card--body set-data-content">
                                  <div className="content">
                                      <div className="popUp--body--card--body--data">
                                          <div className="popUp--body--card--body--data--header w-50">
                                              <DayPicker
                                                  value={day}
                                                  onChange={(day: number) =>
                                                      setDay(day)
                                                  }
                                              />
                                          </div>
                                      </div>
                                      {[STORE_COUNTRIES.de].includes(
                                          storeDetails.country,
                                      ) ? (
                                          <div className="popUp--body--card--body--data">
                                              <div className="popUp--body--card--body--data--header  w-50">
                                                  {i18next.t(
                                                      'Terminal.Receipt.zReport.exportLogs',
                                                  )}
                                              </div>
                                              <div
                                                  className={
                                                      'popUp--body--card--body--data--description  clienttab input  w-50'
                                                  }
                                              >
                                                  <CustomBtn
                                                      classN="btn_1 w-50 blue_btn"
                                                      disabled={false}
                                                      handleClick={
                                                          exportHistoryLogs
                                                      }
                                                      loading={loadingExport}
                                                  >
                                                      {i18next.t(
                                                          'Terminal.Receipt.zReport.export',
                                                      )}
                                                  </CustomBtn>
                                              </div>
                                              {error && (
                                                  <div className="errorMessage">
                                                      {error}
                                                  </div>
                                              )}
                                          </div>
                                      ) : (
                                          ''
                                      )}
                                        <div className="popUp--body--card--body--data justify-content-start align-items-end mb-2">
                                            <div className="popUp--body--card--body--data--header w-auto">
                                                <div className="mb-1 text-center">{i18next.t('Terminal.Receipt.zReport.year')}</div>
                                                <Dropdown
                                                    options={YEAR_DROPDOWN}
                                                    selection
                                                    compact
                                                    name="year"
                                                    value={monthlyReportData.year}
                                                    onChange={changeReportData}
                                                />
                                            </div>
                                            <div className="popUp--body--card--body--data--header w-auto">
                                                <div className="mb-1 text-center">{i18next.t('Terminal.Receipt.zReport.month')}</div>
                                                <Dropdown
                                                    options={MONTH_DROPDOWN}
                                                    selection
                                                    compact
                                                    name="month"
                                                    value={monthlyReportData.month}
                                                    onChange={changeReportData}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    'popUp--body--card--body--data--description w-auto clienttab input'
                                                }
                                            >
                                                <MonthlyReportPdf
                                                    details={details}
                                                    monthlyReportData={monthlyReportData}
                                                />
                                            </div>
                                        </div>
                                      <PrintNullBelegBtn visible={true} />
                                  </div>
                              </div>
                          </div>
                      </Tab.Pane>
                  ),
              }
            : {},
    ];
    return (
        <PopUp handleClose={handleClose} loading={loading}>
            <Tab
                onTabChange={onTabChange}
                menu={{ pointing: true }}
                panes={panes}
            />
        </PopUp>
    );
};

export default connect(null, {})(XReportSidePane);
