import React, { useState, useEffect } from 'react';
import './pagination.scss';

interface Props {
    onChangePage: (p, t) => void;
    perPage?: number;
    totalCount: number;
    resetDefault?: boolean;
}

export default function Pagination(props: Props) {
    const {
        onChangePage,
        totalCount,
        perPage = 10,
        resetDefault = false,
    } = props;
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalCount / perPage);

    useEffect(() => {
        if (resetDefault) {
            handleChangePage('first')();
        }
    }, [resetDefault]);

    const handleChangePage = (action) => () => {
        let currentPage = page;
        if (action === 'first') {
            currentPage = 1;
        }
        if (action === 'last') {
            currentPage = pageCount;
        }
        if (action === 'next') {
            currentPage += 1;
        }
        if (action === 'previous') {
            currentPage -= 1;
        }
        setPage(currentPage);
        onChangePage(currentPage, totalCount);
    };

    return (
        <div className="custom-pagination">
            <nav className="pagination-nav">
                <span className="pagination-nav-info">{`${
                    (page - 1) * perPage + 1
                }-${
                    pageCount === page ? totalCount : page * perPage
                } of ${totalCount}`}</span>
                <div className="pagination-nav-buttons">
                    <button
                        disabled={page === 1}
                        className="pagination-nav-link"
                        onClick={handleChangePage('first')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="presentation"
                        >
                            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                            <path fill="none" d="M24 24H0V0h24v24z"></path>
                        </svg>
                    </button>
                    <button
                        disabled={page === 1}
                        className="pagination-nav-link"
                        onClick={handleChangePage('previous')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="presentation"
                        >
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </button>
                    <button
                        disabled={page === pageCount}
                        className="pagination-nav-link"
                        onClick={handleChangePage('next')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="presentation"
                        >
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </button>
                    <button
                        disabled={page === pageCount}
                        className="pagination-nav-link"
                        onClick={handleChangePage('last')}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="presentation"
                        >
                            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                        </svg>
                    </button>
                </div>
            </nav>
        </div>
    );
}
