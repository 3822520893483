import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BlackWindow from "./../../../modules/common/blackWindow";
import {StoresFormActionCreators} from "../redux/actions";
import closeIcon from "../../../assets/img/close_black.svg";
import {getLoading, getStoresForm} from "../redux/selectors";
import './index.scss'

interface Props  {
    handleClose?: void,
    storesForm: object,
    isLoading: boolean
}

class StoreFormSidePanel extends React.Component<Props & typeof StoresFormActionCreators> {

    componentDidMount(): void {
        this.props.storesFormStart()
    }

    handleClose = () => {
        this.props.handleClose()
    };

    render() {

        return (
            <>
                <BlackWindow type="page" handleClick={this.handleClose}/>

                <div className="store-form-side-panel">
                    <div className="store-form-side-panel--header">
                        <img src={closeIcon} onClick={this.handleClose} alt="PHOTO"/>
                    </div>
                    <div className="store-form-side-panel--body ">
                        <div className="container">
                            <div className="store-form-side-panel--content">
                                {this.props.children}
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
const mapStateToProps = (state: any): Props => ({
    storesForm: getStoresForm(state),
    isLoading: getLoading(state)
});

const mapDispatchToProps = (dispatch): typeof StoresFormActionCreators => {
    return bindActionCreators(StoresFormActionCreators, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(StoreFormSidePanel);
