import React, { Component } from 'react';
import i18next from 'i18next';
import PopUp from "../../../popUp/popUp";
import NewStocks from './newStocks';
import ExportStocks from './exportStocks';
import CustomBtn from '../../../common/customBtn';
import ImportProducts from './importProduct';
import UploadInventoryPanel from './uploadStoreInventory';
import { isTerminalAdmin } from '../../../../helpers/helpers';

interface State {
    showPopUp: boolean;
    exportPopUp: boolean;
    importProduct: boolean;
    showUploadStock: boolean;
}

class StockButtons extends Component<State> {
    state: State = {
        showPopUp: false,
        exportPopUp:false,
        importProduct: false,
        showUploadStock: false
    };
    popup = () => {
        this.setState({
            showPopUp: true,
        })
    };
    export = () => {
        this.setState({
            exportPopUp: true,
        })
    };

    handleClosePopUp = () => {
        this.setState({
            showPopUp: false,exportPopUp:false
        });
    };
    toggleImportProductSidePanel = () => {
        const { importProduct } = this.state;
        this.setState({importProduct: !importProduct});
    }
    toggleUploadStockSidePanel = () => {
        const { showUploadStock } = this.state;
        this.setState({ showUploadStock: !showUploadStock });
    }
    render() {
        const { showPopUp, exportPopUp, importProduct, showUploadStock } = this.state
        const isAdmin = isTerminalAdmin();
        return (
            <>
                <button className="btn_1 w-100 red_btn"  onClick={this.export}>
                    {i18next.t('buttons.28')}
                </button>
                <button className="btn_1 w-100 orange_btn disabled" disabled={true} onClick={this.popup} style={{order: 1}}>
                    {i18next.t('buttons.4')}
                </button>
                <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    handleClick={this.toggleImportProductSidePanel}
                    disabled={!isAdmin}
                >
                    {i18next.t('buttons.51')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 blue_btn"
                    handleClick={this.toggleUploadStockSidePanel}
                    disabled={!isAdmin}
                >
                    {i18next.t('buttons.52')}
                </CustomBtn>
                {
                    exportPopUp && <PopUp handleClose={this.handleClosePopUp} classN="exportStock">
                        <ExportStocks handleClosePopUp={this.handleClosePopUp} />
                    </PopUp>
                }
                {
                    importProduct && <PopUp handleClose={this.toggleImportProductSidePanel} classN="exportStock import-stock-products">
                        <ImportProducts />
                    </PopUp>
                }
                {
                    showPopUp && <PopUp handleClose={this.handleClosePopUp}>
                        <NewStocks handleClosePopUp={this.handleClosePopUp} />
                    </PopUp>
                }

                {
                    showUploadStock && <PopUp handleClose={this.toggleUploadStockSidePanel}>
                        <UploadInventoryPanel handleClosePopUp={this.toggleUploadStockSidePanel} />
                    </PopUp>
                }
                </>
        );
    }
}

export default StockButtons;
