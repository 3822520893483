import { getDateTimestampByTime } from "../../helpers/helpers";

const lang = localStorage.getItem('language') || 'it';
const calendarInitial = '0:09-18;1:09-18;2:09-18;3:09-18;4:09-18;5:09-18;6:09-18';

export const assoicateFormData = (data: any) => {
    data = data || {};

    return {
        ...(data.associateCode && { associateCode: data.associateCode }),
        ...(data.id && { id: data.id }),
        ...(data.createDate && { createDate: data.createDate }),
        associateEmail: data.associateEmail || "",
        associateFirstName: data.associateFirstName || "",
        associateLastName: data.associateLastName || "",
        associatePhone: data.associatePhone || "",
        password: data.password || "",
        deleted: false,
        priority: data.priority || 0,
        photoUrl: data.photoUrl || '',
        active: data.active || false,
        terminalAdmin: data.terminalAdmin || false
    }
}

export const associateCreateValidationFields = {
    associateEmail: true,
    associateFirstName: true,
    associateLastName: true,
    associatePhone: true,
    password: true,
}

export const associateUpdateValidationFields = {
    associateCode: true,
    associateFirstName: true,
    associateLastName: true,
    password: true,
    associateEmail: true,
}

export const associateServiceFormData = (data: any) => {
    data = data || {};

    return {
        brandId: data.brandId || '',
        brandServiceId: data.brandServiceId || '',
        srvCalendar: formatSrvCalendar(data.srvCalendar || ''),
        srvCancellation: data.srvCancellation || '',
        srvCities: data.srvCities ? data.srvCities.split(';') : [],
        srvInterval: data.srvInterval || '',
        srvMinimum: data.srvMinimum || '',
        srvNotice: data.srvNotice || '',
        storeCode: data.storeCode || '',
        storeId: data.storeId || '',
        associateCode: data.associateCode || '',
        srvStart: data.srvStart || ''
    }
}

export const formatSrvCalendar = (calendar: string) => {
    if (!calendar) {
        calendar = calendarInitial;
    }
    const weekDays = calendar.split(';');

    return weekDays.reduce((acc: any[], item) => {
        const language = `${lang}-${lang.toUpperCase()}`;
        const [day, interval] = item.split(':');
        const [startTime, endTime] = (interval || '').split('-');
        const format = new Intl.DateTimeFormat(language, {weekday: 'long'}).format;
        acc.push({
            weekDay: day,
            weekName: format(new Date(1970, 1, +day + 1)),
            start: startTime ? +startTime : 0,
            end: endTime ? +endTime : 0,
            empty: !startTime && !endTime
        });

        return acc;
    }, [])
}

export const associateServiceCreateValidationFields = {
    brandId: false,
    brandServiceId: false,
    srvCalendar: true,
    srvCancellation: true,
    srvCities: true,
    srvInterval: true,
    srvMinimum: true,
    srvNotice: true,
    storeCode: true,
    storeId: true,
    associateCode: false,
}

export const appointmentFormData = (data: any) => {
    data = data || {};

    return {
        associateFirstName: data.associateFirstName || '',
        brandServiceCalendarId: data.brandServiceCalendarId || '',
        description: data.description || '',
        location: data.location || '',
        endTime: data.endTime ? getDateTimestampByTime(data.endTime) : '',
        startTime: data.startTime ? getDateTimestampByTime(data.startTime) : '',
        subject: data.subject || '',
        ticketNumber: data.ticketNumber || '',
        orderNumber: data.orderNumber || ''
    }
}
