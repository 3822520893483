import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faRightFromBracket, faUsers, faCartFlatbed, faArrowsToCircle, faServer } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { getLangPrefix } from '../../../../helpers/helpers';
import { saveTokenInCookies } from '../../../../helpers/navbarHelpers';
import { WAVEUM_LOGIN } from '../../../../utils/constants/service';
import logo from '../../../../assets/img/waveum-navbar-logo.png'
import i18next from 'i18next';
import './sidebar.scss'
import { BACKOFFICE_BASE_URL } from '../../../../utils/constants/navbarPaths';

const OldLoginSidebar = ({
  userBrandApp,
  brandApp,
  logoutStart
}) => {
  const [activeBrands, setActiveBrands] = useState<Record<string, number>>({});

  const navbarItems = [
    { id: 5, label: i18next.t("storeLabels.storeUI"), colored: "#153b5e", icon: faCashRegister, path: '' },
    { id: 9, label: i18next.t("storeLabels.wholesaleUI"), colored: "#770737", icon: faCartFlatbed, path: '' },
    { id: 22, label: i18next.t("storeLabels.marketplaceUI"), colored: "#00A36C", icon: faArrowsToCircle, path: '' },
    { id: 12, label: i18next.t("storeLabels.partnerUI"), colored: "#CC5500", icon: faUsers, path: '' },
    // { id: 25, label: i18next.t("storeLabels.backOffice"), colored: "#7393B3", icon: faServer, path: '' }
  ]
  
  useEffect(() => {
    setActiveBrands(userBrandApp.reduce((acc, items) => {
      acc[items.brandApp] = true;
      return acc;
    }, {}))
  }, [])

  const changeActiveMenu = (id: number) => {
    if (!activeBrands[id]) return;
    const token = sessionStorage.privateToken;

    saveTokenInCookies(id, token);
  }

  const logoutAllStores = () => {
    logoutStart();
    window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self");
  }

  const openBackOffice = () => {
    window.open(`${BACKOFFICE_BASE_URL}`);
  }


  return (
    <div className="navbarContainer">
      <Link
        to={getLangPrefix()}
        className="logoContainer"
      >
        <img
          src={logo}
          alt="head-logo"
        />
      </Link>
      <div className="storeIconsContainer">
        {navbarItems.map((item) => (
          <div
            className={`storeIconItem ${activeBrands[item.id] ? 'active' : 'inactive'} ${brandApp === item.id ? 'hovered' : ''}`}
            key={item.id}
            onClick={() => changeActiveMenu(item.id)}
          >
            <FontAwesomeIcon
              color="#153B5E"
              size='2x'
              icon={item.icon}
            />
            <span>{item.label}</span>
          </div>
        ))}
        <div
          className={`storeIconItem active`}
          onClick={() => openBackOffice()}
        >
          <FontAwesomeIcon
            color='#153B5E'
            size='2x'
            icon={faServer}
          />
          <span>{i18next.t("storeLabels.backOffice")}</span>
        </div>
      </div>
      <div className='to-bottom'>
        <div
          className="storeIconItem active"
          onClick={logoutAllStores}
        >
          <FontAwesomeIcon
            color='#153c5d'
            size='2x'
            icon={faRightFromBracket}
          />
          <span>{i18next.t('Terminal.dashboards.logoutAll')}</span>
        </div>
      </div>
    </div>
  )
}

export default OldLoginSidebar;
