import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tab, Table } from 'semantic-ui-react';
import i18next from 'i18next';

import { StockActionCreators } from '../../redux/actions';
import { getImportProductDetails } from '../../redux/selectors';
import Pagination from '../../../common/pagination';

interface Props {
	handleClose: () => void;
    productDetails: any
}

const ImportStatus: React.FC<Props> = ({
    productDetails
}) => {
    const { productFileImportErrors = [] } = productDetails;
    const pageSize = 10;
	const totalCount = productFileImportErrors.length;
    const [currentPage, setCurrentPage] = useState<number>(0);

	const panes = [{
		menuItem: i18next.t('Terminal.dashboards.errors'),
		render: () => {
			return (
				<div className="content">
                    <Table
                        celled
                        selectable
                        className="productInfoBlock--infoBox--table collaps"
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t(
                                        'Terminal.dashboards.Line',
                                    )}
                                </Table.Cell>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t(
                                        'Terminal.dashboards.errorMessage',
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {productFileImportErrors.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {item.line}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {item.errorMessage}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    {(totalCount || "") && (
                        <Pagination
                            onChangePage={
                                (currentPage) => setCurrentPage(currentPage - 1)
                            }
                            totalCount={totalCount}
                            perPage={pageSize}
                            resetDefault={false}
                        />
                    )}
				</div>
			)
		}
	}];

	return (
		<div>
			<Tab menu={{pointing: true}} panes={panes} />
		</div>
	)
}
const mapStateToProps = (state: any): any => ({
    productDetails: getImportProductDetails(state)
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
  return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportStatus);