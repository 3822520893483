import React, {useRef} from "react";
import {Icon} from "semantic-ui-react";
import './select.scss'

interface Options {
    value?: any,
    text?: any
}

interface Props extends Options {
    align?: string,
    value?: any,
    name?: string,
    options?: Options[],
    handleChange?: any,
}

interface State {
    opened: Boolean,
    selectedValue?: any,
    selectedText?: any,
}

export default class Select extends React.Component<Props> {
    state: State = {
        opened: false,
        selectedValue: this.props.value,
        selectedText: this.getSelectedText(this.props.value, this.props.options),
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.value && nextProps.value !== this.props.value) {
            this.setState({
                selectedText: this.getSelectedText(nextProps.value, nextProps.options),
                selectedValue: nextProps.value
            })
        }
    };

    handleToggle = (event) => {
        event.stopPropagation();
        const el = document.getElementsByClassName("default-select active");
        if (el.length) {
            el[0].classList.remove("active");
        }
        this.setState({opened: !this.state.opened})
    };
    handleChange = (e) => {
        e.stopPropagation();
        const {name,options,handleChange} =this.props;
        const selectedValue = e.target.getAttribute("value");
        this.setState({
            selectedValue,
            selectedText: this.getSelectedText(selectedValue, options),
            opened: false
        });
        handleChange ? handleChange(name, selectedValue) : "";

    };

    getSelectedText(selectedValue = "", options) {
        const selectedItem = options.find(item => item.value == selectedValue);
        return selectedItem ? selectedItem.text : "";
    };

    render() {
        const {options = [], align, name} = this.props;
        const {opened, selectedText, selectedValue} = this.state;
        return (
            <div className={`default-select ${opened ? "active" : ""}`}>
               <span onClick={this.handleToggle}>
                   <input type="text" value={selectedText}  className={align} name={name} disabled/>
                   <Icon name="angle down"/>
               </span>
                <ul className="select-items">
                    {
                        options.map((item, i) => <li value={item.value} key={i} onClick={this.handleChange}
                                                     className={selectedValue === item.value ? "selected" : ""}>{item.text}</li>)
                    }
                </ul>
            </div>
        );
    }
}