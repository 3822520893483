import axios from 'axios';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { CALENDAR_API, getAxiosHeaders, GET_CALENDAR_API } from '../../../utils/constants/service';
import { getAccountResource } from '../../login/redux/selectors';
import { CalendarActionCreators, CalendarTypes } from './actions';

export function* calendar({ body }) {
    const { isDelete, user, data: addedData, brandServiceCalendarId: currentId } = body;
    const sheduleName = 'scheduleInfo';
    const currentShedul = addedData[0];

    try {
        const {
            EndTime, StartTime,
            endTime, startTime,
            allDay, IsAllDay,
            brandServiceCalendarId, Id,
            subject, Subject,
            RecurrenceRule, recurrenceRule,
        } = currentShedul;

        // yield put(CalendarActionCreators.CalendarLoadingAction());
        const accountResource = yield select(getAccountResource);

        const calendarBody = {
            associateCode: user.code,
            deleted: isDelete || false,
            allDay: IsAllDay || allDay,
            subject: Subject || subject,
            recurrenceRule: RecurrenceRule || recurrenceRule,
            endTime: new Date((EndTime || endTime)).getTime(),
            startTime: new Date((StartTime || startTime)).getTime(),
            brandServiceCalendarId: currentId || brandServiceCalendarId,
            storeCode: accountResource && accountResource.storeCode,
            ...(currentShedul && currentShedul.Description && { location: currentShedul.Location }),
            ...(currentShedul && currentShedul.Description && { description: currentShedul.Description }),
            ...(currentShedul && currentShedul.recurrenceRule && { recurrenceRule: currentShedul.recurrenceRule }),
            ...( accountResource &&
                 accountResource.storeDetails &&
                 accountResource.storeDetails.brandId &&
                    { brandId: accountResource.storeDetails.brandId }
                )
        };

        const data = yield call(() => {
            return axios.post(CALENDAR_API, calendarBody, getAxiosHeaders())
                .then(res => res.data)
        });

        if (data) {
            const calendarStorage = JSON.parse(localStorage.getItem(sheduleName) || '{}');

            calendarStorage[user.code] = [];

            if(calendarBody.deleted) {
                calendarStorage[user.code] = calendarStorage[user.code].filter((item) => {
                    return item.brandServiceCalendarId !== brandServiceCalendarId
                });
            } else {
                calendarStorage[user.code].push(calendarBody)
            }

            yield put(CalendarActionCreators.CalendarStart());
        };
    } catch (e) {
        console.log(e)
        yield put(CalendarActionCreators.CalendarFailure(e));
    }
}

export function* getCalendar () {

    const accountResource = yield select(getAccountResource);

    const brandId = accountResource && accountResource.storeDetails && accountResource.storeDetails.brandId;
    const terminalCode = accountResource && accountResource.terminalCode;
    const storeCode = accountResource && accountResource.storeCode;

    const headers = {
        ...getAxiosHeaders()
    };

    headers.headers.terminalCode = terminalCode;
    headers.headers.brandId = brandId;

    try {
        const data = yield call(() => {
            return axios.get(`${GET_CALENDAR_API}?storeCode=${storeCode}`, headers)
                .then(res => res.data);
        });

        if(data) {
            yield put(CalendarActionCreators.CalendarSuccess({data, brandId, terminalCode}));
        };

    } catch (e) {
        console.log(e)
        yield put(CalendarActionCreators.CalendarFailure(e));
    }
}

export function* calendarSaga() {
    yield takeLatest(CalendarTypes.CALENDAR_START, getCalendar);
    yield takeLatest(CalendarTypes.CALENDAR_UPDATE, calendar);
}

export default calendarSaga;