import {QuoteTypes, QuoteActionCreators} from "./actions";
import {takeLatest, put, call, select} from "redux-saga/effects";
import axios from 'axios';
import {CREATE_QUOTE_URL, QUOTE_URL, PAPER_QUOTE_URL, DELETE_QUOTE_URL} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers";
import {DEFAULT_CURRENCY} from "../../../utils/constants/variables";

export function* createQuote(params) {
    const {body} = params
    try {
        const headers = {
            headers: {
                Authorization : `Bearer ${sessionStorage.getItem("privateToken")}`,
                "Associate-Authorization" : `${localStorage.getItem("token")}`,
                currency : DEFAULT_CURRENCY,
            }
        };

        const data = yield call(() => {
            return axios.post(`${CREATE_QUOTE_URL}`, body, headers)
                .then(res => res.data)
        });

        yield put(QuoteActionCreators.createQuoteSuccess(data));
    } catch (e) {
        yield put(QuoteActionCreators.createQuoteFailure(e.message));
    }
}

export function* getQuoteData(params) {
    const body = params.data
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.post(`${QUOTE_URL}dashboard`,body, headers)
                .then(res => res.data)
        });
        yield put(QuoteActionCreators.quoteSuccess(data));
    } catch (e) {
        yield put(QuoteActionCreators.quoteFailure(e.message));
    }
}

export function* getSingleQuoteData(params) {
    const id = params.id
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency : DEFAULT_CURRENCY
            }
        };
        const data = yield call(() => {
            return axios.get(`${QUOTE_URL}${id}/details?language=${getApiPrefix()}`, headers)
                .then(res => res.data)
        });
        yield put(QuoteActionCreators.singleQuoteSuccess(data));
    } catch (e) {
        yield put(QuoteActionCreators.singleQuoteFailure(e.message));
    }
};

export function* paperReceiptQuote(params) {
    const {quoteNumber,receiptType} = params.body;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency : DEFAULT_CURRENCY
            }
        };
        const data = yield call(() => {
            return axios.get(`${PAPER_QUOTE_URL}?quoteNumber=${quoteNumber}&receiptType=${receiptType}`, headers)
                .then(res => res.data)
        });

        yield put(QuoteActionCreators.paperReceiptQuoteSuccess(data));
    } catch (e) {
        yield put(QuoteActionCreators.paperReceiptQuoteFailure(e.message));

    }
}

export function* quotePrintTicket(params) {
    const {quoteNumber,receiptType} = params.data;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency : DEFAULT_CURRENCY
            }
        };

        const data = yield call(() => {
            return axios.get(`${PAPER_QUOTE_URL}?quoteNumber=${quoteNumber}&receiptType=${receiptType}`, headers)
                .then(res => res.data)
        });

        yield put(QuoteActionCreators.quotePrintTicketSuccess(data));
    } catch (e) {
        yield put(QuoteActionCreators.quotePrintTicketFailure(e.message));

    }
}

export function* quoteDeleteTicket(params) {
    const { quoteNumber } = params.data;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
            }
        };

        const data = yield call(() => {
            return axios.put(`${DELETE_QUOTE_URL}?quoteNumber=${quoteNumber}`,{},headers)
                .then(res => res)
        });

        if(data.status === 200) {
            const { quoteDashboard } = yield select((state) => state.quoteR);
            yield put(QuoteActionCreators.quoteDeleteTicketSuccess(
                quoteDashboard.map(
                    (quoteItem) => quoteItem.quoteNumber === quoteNumber
                        ? {...quoteItem, deleted: true} : quoteItem
                )
            ));
        } else {
            yield put(QuoteActionCreators.quoteDeleteTicketFailure(false))
        }

    } catch (e) {
        yield put(QuoteActionCreators.quoteDeleteTicketFailure(e.message));
    }
}

export function* quoteSaga() {
    yield takeLatest(QuoteTypes.CREATE_QUOTE_START, createQuote);
    yield takeLatest(QuoteTypes.QUOTE_START, getQuoteData);
    yield takeLatest(QuoteTypes.SINGLE_QUOTE_START, getSingleQuoteData);
    yield takeLatest(QuoteTypes.PAPER_RECEIPT_QUOTE_START, paperReceiptQuote);
    yield takeLatest(QuoteTypes.QUOTE_PRINT_TICKET_START, quotePrintTicket);
    yield takeLatest(QuoteTypes.QUOTE_DELETE_TICKET_START, quoteDeleteTicket);
}

export default quoteSaga;
