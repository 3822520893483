import React, { Component } from 'react';
import i18next from 'i18next';
import {connect} from 'react-redux';
import {pdf} from "@react-pdf/renderer";
import CustomBtn from '../../../common/customBtn';
import { getStock } from '../../redux/selectors';
import { delay, multipleDuplicationOfArrayElements, textToBase64Barcode } from '../../../../helpers/helpers';
import { getAccountResource } from '../../../login/redux/selectors';
import {BarcodePositionSidePanel, GeneretBarcodes} from '../../../pdfGenerator/barcodePositionSidePanel';
import { StockActionCreators } from '../../redux/actions';
import { bindActionCreators } from 'redux';


interface Props {
    stockData: any,
    data: any,
    storeCode: string,
    selectedRows: any[],
    details: any,
}

interface State {
    isLoadingBarcodePdf: boolean,
    showPopUp: boolean,
    isClickedFilterBtn: boolean
}

class ExportPDFbutton extends Component<Props & typeof StockActionCreators, State> {
    state: State = {
        isLoadingBarcodePdf: false,
        showPopUp: false,
        isClickedFilterBtn: false,
    };


    handleBarcodePdf = async (value = 1, count = 1) => {
        try {
            this.setState({isLoadingBarcodePdf: true})
            await delay(100);
            const position = Array(+value - 1 || 0).fill({})
            let data = this.props.selectedRows.map((item) => {
                return {...item, barcode: textToBase64Barcode(item.barcode, "EAN13")}
            })

            if(count > 1) {
                data = multipleDuplicationOfArrayElements(data, +count)
            }
            const blob = await pdf(<GeneretBarcodes data={[...position, ...data]} details={this.props.details} />).toBlob();
            const url = URL.createObjectURL(blob);
            this.handleClose();
            this.setState({isLoadingBarcodePdf: false});
            window.open(url, '_blank');
        } catch (error) {
            this.setState({isLoadingBarcodePdf: false})
        }
    }

    handleClose = () => {
        this.setState({showPopUp: false})
    }

    handleClick = () => {
        this.setState((prev: State) => ({
            ...prev,
            isClickedFilterBtn: !prev.isClickedFilterBtn
        }), () => this.props.stockBarcodesListStart(this.state.isClickedFilterBtn ? false : undefined))
    }

    render() {
        const { isLoadingBarcodePdf, showPopUp} = this.state
        const {selectedRows} = this.props;
        return (
            <>
                {
                    selectedRows ?
                        <>
                            <CustomBtn
                                classN="btn_1 w-100 orange_btn"
                                handleClick={this.handleClick}
                            >
                                {'STOCK < > 0'}
                            </CustomBtn>
                            <CustomBtn
                                disabled={!selectedRows.length}
                                classN="order2 btn_1 w-100 green_btn"
                                handleClick={() => this.setState({showPopUp: true})}
                            >
                                {i18next.t('buttons.37')}
                            </CustomBtn>
                        </>
                        :
                        ""
                }
                {showPopUp &&
                    <BarcodePositionSidePanel
                        handleClose={this.handleClose}
                        handleClick={this.handleBarcodePdf}
                        loading={isLoadingBarcodePdf}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    stockData: getStock(state),
    details: getAccountResource(state),
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportPDFbutton);
