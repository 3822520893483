import React, {Component} from 'react';
import ClientsNew from "./clientsNew";
import Loading from "../../../common/loading/loading";

interface Props {
    data: any,
    isLoading: boolean,
    handleClosePopUp: any,
    storeCode?: string
}

class ClientsTab extends Component<Props> {
    render() {
        const {storeCode ,data ,handleClosePopUp} = this.props;
        return (
            this.props.isLoading ? <Loading/> : <ClientsNew data={data} handleClosePopUp={handleClosePopUp} isNew={false} storeCode={storeCode}/>
        )
    }
}


export default ClientsTab;
