import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import PageMetaData from "../../modules/common/pageMetaData/pageMetaData";
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL, setStoreData} from "../../utils/constants/variables";
import history from "./../../helpers/history";
import { Icon } from "semantic-ui-react";

export default function CommonCurrentPage({
    navTabs = [], selectedMenuItem, productData, showPageMetaData, handleChangeCurrency
}) {

    const [tabs, setTabs] = useState([]);
    const [updateComponent,setUpdateComponent] = useState(true);

    useEffect(()=>{

        if (navTabs.length){
            setTabs([...navTabs])
        }

    }, [navTabs]);
    const setDefault = (curr, sybol) => {
        const url =history.location.pathname;
        if(curr !== DEFAULT_CURRENCY || sybol !==DEFAULT_CURRENCY_SYMBOL){
            history.push( url || "" );
            setStoreData( curr, sybol );
            setUpdateComponent(!updateComponent)
            handleChangeCurrency();
        }
    };
    return (
        <>
            <ul className="page-sub-menu">
                {
                    tabs && tabs.length ?
                    tabs.map((tab:any, index) => (
                        <li key={index} className="page-sub-menu--controller">
                            {tab.url ?
                            <>
                                <Link to={tab.url}>{ index === 0 ? <Icon size='small' name='home' /> : ""} {tab.name ? tab.name.toUpperCase()  : " "} </Link>
                                { tab.currency && tab.currency.length ?
                                    <>
                                        | <div className={`${DEFAULT_CURRENCY===tab.currency? "page-sub-menu--controller--defaultCurrency":"page-sub-menu--controller--altCurrency"}`}
                                               onClick={()=>setDefault(tab.currency,tab.defaultCurrencySymbol)}>
                                        { tab.currency }
                                        </div>
                                    </> : " " }
                                { tab.altCurrency && tab.altCurrency.length ?
                                    <>
                                        | <div className={`${DEFAULT_CURRENCY===tab.altCurrency? "page-sub-menu--controller--defaultCurrency":"page-sub-menu--controller--altCurrency"}`}
                                               onClick={()=>setDefault(tab.altCurrency,tab.altCurrencySymbol)}>
                                        { tab.altCurrency }
                                        </div>
                                    </> : " " }
                            </>:
                                <span> {tab.name ? tab.name.toUpperCase() : ""}</span>}

                        </li>
                    ))
                    :
                    <li/>

                }
            </ul>

            {selectedMenuItem && showPageMetaData ?
                <PageMetaData
                    productData={productData}
                    navTabs={navTabs}
                    selectedMenuItem={selectedMenuItem}
                />
                : ""
            }
        </>
    )
}
