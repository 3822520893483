import React from "react";
import {connect} from "react-redux";
import {getUser, getClientLoginError, getAccountResourceLoading} from "../../redux/selectors";
import {getLangPrefix} from "../../../../helpers/helpers";
import {bindActionCreators} from "redux";

import {withRouter} from "react-router-dom";
import i18next from "i18next";
import {Icon} from 'semantic-ui-react';

import {getAccountResource, isAuthenticated} from "../../redux/selectors";

import BlackWindow from "../../../../modules/common/blackWindow";
import UserDropdown from "../components/userDropdown";
import {UserActionCreators} from "../../redux/actions";

import "./index.scss"


interface Props  {
    clientLoginStatus: string,
    authenticated: boolean,
    user: any,
    loading: boolean
}

interface State {
    show?: boolean,
    showClientLoginModal?: boolean,
    user: any,
}

class ClientLogin extends React.Component<Props & typeof UserActionCreators, State> {
    state: State = {
        show: false,
        showClientLoginModal: false,
        user: {
            username: '',
            password: '',
            rememberMe: true
        },
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clientLoginStatus !== "SUCCESS" && this.props.clientLoginStatus === "SUCCESS") {
            this.closeModal();
        }
    }

    handleClientLogin = () => {
        const {showClientLoginModal} = this.state;
        this.setState({showClientLoginModal: !showClientLoginModal})
    };

    handleLoginSubmit = (e) => {
        e.preventDefault();
        this.props.clientLoginStart(this.state.user);
    };


    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState((prevState: State) => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }));

    };

    showPass = () => {
        const {show} = this.state;
        this.setState({
            show: !show
        })
    };

    closeModal = () => {
        this.setState({showClientLoginModal: false,});

    };

    closeModallogin = () => {
        this.setState({showClientLoginModal: false});
    };


    terminalLogout = () => {
        this.props.logoutStart();
        this.props.history.push(`${getLangPrefix()}/login`)
    }

    render(): React.ReactNode {
        const {clientLoginStatus} = this.props;
        const {showClientLoginModal, user, show} = this.state;
        const {username, password} = user;
        return (
            <>
                {!this.props.authenticated ?
                    <div className="d-between login-modal login-icon">
                        {showClientLoginModal && <BlackWindow type="page" handleClick={this.closeModallogin}/>}
                        <div className={`modal-wrapper ${showClientLoginModal ? "opened" : ""}`}>
                            <div className="profile_pic" onClick={this.handleClientLogin}>
                                <div className="circle"></div>
                            </div>
                            {showClientLoginModal &&
                            <div className="modal open">
                                <form onSubmit={this.handleLoginSubmit}>
                                  <div className="modal-inner">
                                    <div className="modal-input">
                                      <label>{i18next.t('Terminal.login.8')}</label>
                                      <input
                                          type="text"
                                          placeholder={i18next.t('Terminal.login.8')}
                                          name="username"
                                          value={username}
                                          onChange={this.handleChange}/>
                                    </div>
                                    <div className="modal-input pass">
                                      <label>{i18next.t('Terminal.login.3')}</label>
                                      <input
                                          type={this.state.show ? "text" : "password"}
                                          placeholder={i18next.t('Terminal.login.3')}
                                          name="password"
                                          value={password}
                                          onChange={this.handleChange}
                                      />
                                      <span className={`icon-pass ${show ? "show" : ""}`} onClick={this.showPass}>
                                          <Icon name={this.state.show ? "eye" : "eye slash"}></Icon>
                                       </span>
                                    </div>
                                      {
                                          clientLoginStatus === "FAILED" ?
                                              <div className="login-failed-message">Utilisateur ou mot de passe incorrect</div> : ""
                                      }
                                    <div>
                                      <button
                                        className={`modal-btn ${!password || !username ? "disabled" : ""}`}
                                        disabled={!password || !username}
                                      >
                                          {i18next.t('Terminal.login.9')}
                                      </button>

                                      <button
                                        className="modal-btn logout"
                                        type="button"
                                        onClick={this.terminalLogout}
                                      >
                                          {i18next.t('Terminal.login.10')}
                                      </button>
                                    </div>

                                  </div>
                                </form>
                            </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="d-between login-modal ">
                        { showClientLoginModal ? <BlackWindow type="page" handleClick={this.closeModallogin}/> : ""}
                        <div className={`modal-wrapper ${showClientLoginModal ? "opened": ""}`}>
                            <div className="profile_pic" onClick={this.handleClientLogin}>
                                <div className="profile-icon">
                                    <div className="circle"></div>
                                </div>
                            </div>
                            {showClientLoginModal && <UserDropdown history={this.props.history} account={this.props.account} />}
                        </div>
                    </div>

                }
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: getUser(state),
    authenticated: isAuthenticated(state),
    clientLoginStatus: getClientLoginError(state),
    account: getAccountResource(state),
    loading: getAccountResourceLoading(state)
});

const mapDispatchToProps = (dispatch): typeof UserActionCreators => {
    return bindActionCreators(UserActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientLogin));
