import {createReducer} from 'reduxsauce';
import {ReportsTypes} from './actions';

export interface IState {
    isPrinting: boolean,
    printData: any,
    printError: string,
    paymentsReportData: any,
    paymentsReportStatus: string,
    isLoading: boolean
}

export const reportsContent: IState = {
    isPrinting: false,
    printData: {},
    printError: '',
    paymentsReportData: {},
    paymentsReportStatus: "",
    isLoading: false,
};

export const printReportStart = (state) => {
    return {...state, isPrinting: true};
};

export const printReportSuccess = (state, action) => {
    return {...state, printData: action.data, isPrinting: false};
};

export const printReportFailure = (state, action) => {
    return {...state, printError: action.error, printData: {}, isPrinting: false};
};

export const paymentsReportStart = (state) => {
    return {...state, paymentsReportStatus: "", isLoading: true};
};

export const paymentsReportSuccess = (state, action) => {
    return {...state, paymentsReportData: {...state.paymentsReportData, [action.currency]: action.data}, paymentsReportStatus: "success", isLoading: false};
};

export const paymentsReportFailure = (state, action) => {
    return {...state,  paymentsReportData: [], paymentsReportStatus: "failed", isLoading: false};
};

export const handlers = {
    [ReportsTypes.PRINT_REPORT_START]: printReportStart,
    [ReportsTypes.PRINT_REPORT_SUCCESS]: printReportSuccess,
    [ReportsTypes.PRINT_REPORT_FAILURE]: printReportFailure,
    [ReportsTypes.PAYMENTS_REPORT_START]: paymentsReportStart,
    [ReportsTypes.PAYMENTS_REPORT_SUCCESS]: paymentsReportSuccess,
    [ReportsTypes.PAYMENTS_REPORT_FAILURE]: paymentsReportFailure,

};

export default createReducer(reportsContent,handlers);
