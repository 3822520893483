import {AddToCartTypes, AddToCartActionCreators} from "./actions";
import {AddToDeliveryCartActionCreators} from './../../deliveryBasketPopUp/redux/actions';
import { takeLatest, put, call, select } from "redux-saga/effects";
import { addToLocalStorage, getLocalStorageObject, getPackDiscountPrice, getProductPack, removeFromStorage, getActiveBasketName, getLocalFormData } from "../../../helpers/helpers";
import { getStoreClient } from "../../clients/redux/selectors";

export function* addToCart(params) {
    const {name = getActiveBasketName(), key, value, setAsQuantity = false} = params;
    const {pack,size} =value
    const data = yield select(getStoreClient);
    const { clientTypeLabels = [] } = getLocalFormData();
    const empLabel = clientTypeLabels.find(label => label.code === "EMP");
    const isEmpUser = data && data[name] && data[name].profile
        && data[name].profile.clientType === "EMP" && empLabel && empLabel.discountRate;

    try {
        const data = yield call(() => {
            const added = addToLocalStorage(name, key, value, setAsQuantity, isEmpUser);
            if(Array.isArray(pack) && pack.length) {
                pack.map(item=> {
                    let sizes = {
                        currency: size.currency,
                        oldPrice: item.startingPrice,
                        productId: item.productId,
                        productReference: size.productReference,
                        quantity: size.quantity,
                        size: item.size,
                        startingPrice: item.startingPrice,
                        taxRate: item.taxRate,
                    }
                    let packValue = {
                        canonicalMenu: item.canonicalMenu,
                        canonicallUrl: item.canonicalUrl,
                        collection: item.collection,
                        description: item.description,
                        discountDetail: item.discountDetail,
                        name: item.name,
                        photo: item.photo,
                        productId: item.productId,
                        size: sizes,
                        quantity:item.quantity,
                        startingPrice: item.startingPrice,
                        taxRate: item.taxRate,
                        packParent: key

                    }
                    addToLocalStorage(name, item.parentProductId, packValue);
                } )
            }

            if(setAsQuantity) {
                const productPack = getProductPack(name,key);
                Array.isArray(productPack) && productPack.length && productPack.forEach(item => {
                    const newQuantity = item.quantity;
                    addToLocalStorage(name,item.productId, {size: {quantity: newQuantity, productId: item.prSize}},true)
                })
            }
            return {added, basket:getLocalStorageObject(name)};
        });
        if(name === getActiveBasketName()){
            yield put(AddToCartActionCreators.addtocartSuccess(data));
        }else{
            yield put(AddToDeliveryCartActionCreators.addtodeliverycartSuccess(data));
        }

    } catch (e) {
        yield put(AddToCartActionCreators.addtocartFailure(e.message));
    }
}


export function* basket(params) {
    const {name = getActiveBasketName()} = params
    try {
        const data = yield call(() => {
            return getLocalStorageObject(name)
        });
        yield put(AddToCartActionCreators.getBasketSuccess(data));
    } catch (e) {
        yield put(AddToCartActionCreators.addtocartFailure(e.message));
    }
}

export function* removeBasket(params) {
    const {name = getActiveBasketName(), key, prSize} = params;

    try {
        const data = yield call(() => {
            const packElems = getProductPack(name, key);
            if(Array.isArray(packElems) && packElems.length){
                packElems.forEach(item => {
                    const packParentElem = getLocalStorageObject(name)[item.productId]
                    if(packParentElem){
                        const {childElementQuantity} = getPackDiscountPrice(name, packParentElem,item.productId)

                        let currentQuantity = 0;
                        getLocalStorageObject(name)[key].sizes.forEach(item => {
                            if(item.productId===prSize) {
                                currentQuantity = item.quantity
                            }
                        })

                        if(currentQuantity >= childElementQuantity){
                            removeFromStorage(name, item.productId, item.prSize);
                        }else {
                            addToLocalStorage(name,item.productId, {size: {quantity: childElementQuantity  - currentQuantity, productId: item.prSize}},true)
                        }
                    }
                })
            }
            removeFromStorage(name, key, prSize);

            return getLocalStorageObject(name);
        });
        if(name === getActiveBasketName()){
            yield put(AddToCartActionCreators.removeBasketSuccess(data));
        }else{
            yield put(AddToDeliveryCartActionCreators.removeDeliveryBasketSuccess(data));
        }

    } catch (e) {
        yield put(AddToCartActionCreators.addtocartFailure(e.message));
    }
}

export function* addToCartSaga() {
    yield takeLatest(AddToCartTypes.ADDTOCART_START, addToCart);
    yield takeLatest(AddToCartTypes.GET_BASKET_START, basket);
    yield takeLatest(AddToCartTypes.REMOVE_BASKET_START, removeBasket);
}

export default addToCartSaga;
