import React, { useEffect } from  'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

import i18next from 'i18next';

import { AppointmentsActionCreators } from '../redux/actions';
import { SalesActionCreators } from '../../sales/redux/actions';
import { getAccountResource } from '../../login/redux/selectors';
import { getSingleSale } from '../../sales/redux/selectors';
import { getOptionSelection } from '../../../helpers/helpers';

const AppointmentsOptions: React.FC<any> = ({
    selectedAppointment,
    singleSalesStart,
    details,
    singleSale
}) => {

    useEffect(() => {
        getOrderSales();
    }, [])

    const getOrderSales = () => {
        singleSalesStart(selectedAppointment.ticketNumber, details.currency, details.storeCode)
    }

    return (
        <div>
            <Table
                celled
                selectable
                className="productInfoBlock--infoBox--table collaps"
            >
                <Table.Header>
                    <Table.Row>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t(
                                'Terminal.dashboards.option',
                            )}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t(
                                'buttons.10',
                            )}
                        </Table.Cell>
                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                            {i18next.t(
                                'Terminal.dashboards.comment',
                            )}
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {singleSale.options && singleSale.options.map((option, index) => (
                        <Table.Row key={index}>
                            <Table.Cell className="productInfoBlock--infoBox--table--title font-22">
                                {option.option}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {getOptionSelection(option)}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                {option.comment}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        details: getAccountResource(state),
        singleSale: getSingleSale(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({...SalesActionCreators, ...AppointmentsActionCreators}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsOptions);