import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import {
    pdf,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
} from '@react-pdf/renderer';
import { arrayOfObjectGroupByKey, getAbbreviatedCurrencySymbol, getCurrency, getDateTimestamp, getDateTimestampByTime, getLocalFormData,  replaceComma } from '../../helpers/helpers';
import CustomBtn from '../common/customBtn';
import { getMonthlyReport } from '../../utils/constants/service';

interface IProps {
    [rest: string]: any;
}

const MonthlyReportPdf: React.FC<IProps> = ({
    ...rest
}) => {
    const [loading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const handlePdf = async () => {
        try {
            let { monthlyReportData } = rest;

            setLoading(true);
            setError(false);
            const data = await getMonthlyReport(monthlyReportData);
            
            if(!data || !data.length){
                setError(true);
                setLoading(false);
                return;
            }

            const blob = await pdf(
                <TablePdf {...rest} data={data} />,
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setLoading(false);
            window.open(url, '_blank');
        } catch (error) {
            setLoading(false);
            setError(true);
            console.log(error);
        }
    };

    return (
        <>
            <CustomBtn
                classN="btn_1 w-100 green_btn"
                disabled={false}
                handleClick={handlePdf}
                loading={loading}
            >
                {i18next.t('buttons.45')}
            </CustomBtn>
            {
                isError && (
                    <div className="errorMessage" style={{position: 'absolute'}}>
                        {i18next.t('Terminal.Receipt.zReport.errorMonthlyReport')}
                    </div>
                )
            }
        </>
    );
};

export default MonthlyReportPdf;



const TablePdf = (props:any) => {
    const storName = props.details.storeDetails.name;
    const monthlyReport = `${i18next.t('Terminal.Receipt.zReport.monthlyReport')} (${props.monthlyReportData.month}/${props.monthlyReportData.year})`
    const { paymentMethods } = getLocalFormData();
    const obj = arrayOfObjectGroupByKey(props.data, "currency");
    const codeInfon = {"GFA": "009", "GFC": "008"}

    const getPaymentPosition = (paymentMethodCode: string) => {
        const code = codeInfon[paymentMethodCode] || paymentMethodCode;
        return paymentMethods.findIndex((method) => method.code === code)
    }

    const calculateTotalInfo = () => {
        return Object.keys(obj).reduce((result, key) => {
            result[key] = result[key] ? result[key] : {};
            obj[key].forEach((item) => {
                result[key].paymentNetTotal = result[key].paymentNetTotal
                    ? result[key].paymentNetTotal + item.totals.paymentNetTotal
                    : item.totals.paymentNetTotal
                result[key].paymentTaxTotal = result[key].paymentTaxTotal
                    ? result[key].paymentTaxTotal + item.totals.paymentTaxTotal
                    : item.totals.paymentTaxTotal
                item.payments.forEach((payment) => {
                    const code = codeInfon[payment.paymentMethodCode] || payment.paymentMethodCode;
                    result[key][code] = result[key][code]
                        ? result[key][code] + payment.paymentAmount
                        : payment.paymentAmount
                })
            });
            return result;
        }, {});
    }

    const totalInfo = calculateTotalInfo();

    return (
        <Document>
            {Object.keys(obj).map((key) => 
                <Page style={styles.body} orientation="landscape" key={key}>
                    <View style={[styles.row, {marginBottom: 20}]}>
                        <Text style={[styles.cell, styles.text]}>
                            {getDateTimestampByTime(Date.now())}
                        </Text>
                        <Text style={[styles.cell, styles.text]}>
                            {storName}
                        </Text>
                        <Text style={[styles.cell, styles.text]}>
                            {monthlyReport}
                        </Text>
                        <Text style={[styles.cell, styles.text]}>
                            {key}
                        </Text>
                    </View>
                    <View style={[styles.border, styles.row, styles.bgGraye]}>
                        <Text style={[styles.cell, styles.productTitle, {width: "9%"}]}>
                            {i18next.t('Terminal.dashboards.date')}
                        </Text>
                        <Text style={[styles.cell, styles.productTitle, {width: "9%"}]}>
                            {i18next.t('Terminal.Receipt.zReport.paymentNetTotal')}
                        </Text>
                        <Text style={[styles.cell, styles.productTitle, {width: "9%"}]}>
                            {i18next.t('Terminal.Receipt.zReport.paymentTax')}
                        </Text>
                        {
                            paymentMethods.map((method, idx) => (
                                <Text key={idx} style={[styles.cell, styles.productTitle, {width: "9%"}]}>
                                    {method.name}
                                </Text> 
                            ))
                        }
                    </View>
                    {
                        obj[key].map((item, i) => (
                            <View key={i} wrap={false} style={[styles.row]}>
                                <Text style={[styles.cell, styles.product, {width: "9%"}]}>
                                    {getDateTimestamp(item.saleDate)}
                                </Text>
                                <Text style={[styles.cell, styles.product, {width: "9%"}]}>
                                    {replaceComma(item.totals.paymentNetTotal)}{getAbbreviatedCurrencySymbol(getCurrency(key))}
                                </Text>
                                <Text style={[styles.cell, styles.product, {width: "9%"}]}>
                                    {replaceComma(item.totals.paymentTaxTotal)}{getAbbreviatedCurrencySymbol(getCurrency(key))}
                                </Text>
                                {paymentMethods.map((e, i) => {
                                    return (
                                        <Text key={i} style={[styles.cell, styles.product, {width: "9%"}]}>
                                            {
                                                item.payments.map((payment) => getPaymentPosition(payment.paymentMethodCode) === i
                                                        ? `${replaceComma(payment.paymentAmount)}${getAbbreviatedCurrencySymbol(getCurrency(key))}`
                                                        : ''
                                                )
                                            }
                                        </Text>
                                    )
                                })}
                            </View>
                        ))
                    }
                    {
                        totalInfo[key] && (
                            <View wrap={false} style={[styles.row]}>
                                <Text style={[styles.cell, styles.product, {width: "9%"}, styles.bold]}>
                                    {i18next.t("Terminal.terminalClose.total")}
                                </Text>
                                <Text style={[styles.cell, styles.product, {width: "9%"}, styles.bold]}>
                                    {replaceComma(totalInfo[key].paymentNetTotal)}{getAbbreviatedCurrencySymbol(getCurrency(key))}
                                </Text>
                                <Text style={[styles.cell, styles.product, {width: "9%"}, styles.bold]}>
                                    {replaceComma(totalInfo[key].paymentTaxTotal)}{getAbbreviatedCurrencySymbol(getCurrency(key))}
                                </Text>
                                {paymentMethods.map((e, i) => {
                                    return (
                                        <Text key={i} style={[styles.cell, styles.product, {width: "9%"}, styles.bold]}>
                                            {
                                                `${replaceComma(totalInfo[key][e.code])}${getAbbreviatedCurrencySymbol(getCurrency(key))}`
                                            }
                                        </Text>
                                    )
                                })}
                            </View>
                        )
                    }
                    <Text 
                        style={styles.pageNumber} 
                        render={({ pageNumber }) => `${pageNumber}`} 
                        fixed 
                    />
                </Page>
            )}
        </Document>
    )
}

const styles = StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 15,
    },
    view: {
        width: '100%',
        height: '100%',
    },
    text: {
        fontSize: 13,
        padding: 2,
        fontFamily: 'Times-Roman',
        fontWeight: 'bold',
    },
    productTitle: {
        fontSize: 12,
        padding: 2,
        fontFamily: 'Times-Roman',
        fontWeight: 'bold',
    },
    product: {
        fontSize: 11,
        padding: 2,
        fontFamily: 'Times-Roman',
        borderTop: '1px solid #ccc',
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'stretch',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35,
    },
    cell: {
        flexGrow: 1,
        flexShrink: 1,
        alignSelf: 'stretch',
        borderTop: '1px solid #ccc',
    },
    border: {
        borderColor: '#7b7b7b',
        borderStyle: 'solid',
        borderWidth: 2,
    },
    bgGraye: {
        backgroundColor: '#ccc',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 13,
        bottom: 5,
        textAlign: 'right',
    },
    bold: {
        fontFamily: "Helvetica-Bold"
    }
});