import React, {FC} from 'react';
import { Modal } from 'semantic-ui-react'
import i18next from "i18next";

interface Props {
    open: boolean,
    classN?:string,
    message: string,
    handleClose: () => void,
    title?:string,
}

const InfoPopUp: FC<Props> = ({open, message, handleClose, classN="",title=i18next.t('Terminal.modal.title')}) => {
    return (
            <Modal
                className="_modal--content"
                size="tiny"
                closeIcon
                open={open}
                onClose={handleClose}
            >
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className={`modal-desc ${classN}`}>
                            {message}
                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <div className="modal-actions">
                        <p  className="close-button"></p>
                        <button
                            className="accept-button"
                            name="bouton" onClick={handleClose}>Ok
                        </button>
                    </div>
                </Modal.Actions>
            </Modal>
    )
}

export default InfoPopUp;
