import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import i18next from 'i18next';

import { usePrevious } from '../../../../shared/hooks/usePrevious';
import CustomBtn from '../../../common/customBtn';
import '../stocks.scss';
import { filterInputNumberValue } from '../../../../helpers/helpers';

interface Props {
	selectedProduct: any;
    updateStockPriceAction: (data, id) => void;
    closePanel: () => void;
    singleStockId: number;
    loading: boolean;
    isService?: boolean;
}

const UpdatePrice: React.FC<Props> = ({
    selectedProduct,
    updateStockPriceAction,
    closePanel,
    singleStockId,
    loading,
    isService = false
}) => {
    const [prodcut, setProduct] = useState<Record<string, number | string>>({
        country: selectedProduct.country
            || selectedProduct && selectedProduct.barcodeInventoryList
            && selectedProduct.barcodeInventoryList[0]
            && selectedProduct.barcodeInventoryList[0].storeCountry,
        currency: selectedProduct.currency,
        listPrice: selectedProduct.listPrice || 0,
        outletPrice: selectedProduct.outletPrice || 0,
        productId: selectedProduct.id
    })
    const prevLoading = usePrevious<boolean>(loading);

    useEffect(() => {
        if (!loading && prevLoading) {
            closePanel();
        }
    }, [loading])

    const changePrice = ({ target }) => {
        const { value, name } = target;
        setProduct({
            ...prodcut,
            [name]: filterInputNumberValue(value)
        });
    }

    const handleChangeStockSize = () => {
        updateStockPriceAction(prodcut, singleStockId);
    }

    return (
        <div className="popUp--body stock-size-container">
            <div className="content">
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.listPrice')}
                    </div>
                    <div className="ui error input popUp--body--card--body--data--description  email">
                        <Input
                            placeholder='Colis'
                            name="listPrice"
                            value={prodcut.listPrice}
                            onChange={changePrice}
                            autoFocus
                            className='ml-stock'
                            min="0"
                        />
                    </div>
                </div>
                {!isService && (
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.outletPrice')}
                        </div>
                        <div className="ui error input popUp--body--card--body--data--description  email">
                            <Input
                                placeholder='Colis'
                                value={prodcut.outletPrice}
                                name="outletPrice"
                                onChange={changePrice}
                                autoFocus
                                className='ml-stock'
                                min="0"
                            />
                        </div>
                    </div>
                )}
                <div className="popUp--body--card--body--data">
                    <CustomBtn
                        classN="btn_1 green_btn w-100"
                        loading={loading}
                        disabled={false}
                        handleClick={handleChangeStockSize}
                    >
                        {i18next.t('Terminal.dashboards.edit')}
                    </CustomBtn>
                </div>
            </div>
        </div>
	)
}

export default UpdatePrice;