import React, {Component} from 'react';
import i18next from "i18next";
import CustomBtn from "../../../common/customBtn";
import AddGift from "./../../../bonsCadeaux/container/components/addGift";

interface Props {
    dispatch: any,
    data: any,
}

interface State {
    storeGiftCode: string,
}

class ClientGift extends Component<Props, State> {
    state: State = {
        storeGiftCode: ""
    };

    render() {
        const {storeGiftCode} = this.state;
        const {data = {}} = this.props;
        return (
            <>
                <CustomBtn
                    classN='btn_1 w-100 blue_btn'
                    handleClick={()=>{this.setState({storeGiftCode: data.storeGiftCode})}}
                    disabled={data.redemptionDate }
                >
                    {i18next.t('buttons.30')}
                </CustomBtn>

                {storeGiftCode && (
                    <AddGift
                        storeGiftCode={storeGiftCode}
                        storeGiftPopup={true}
                        handleClose={() =>this.setState({storeGiftCode:""})}
                    />
                )}
            </>
        )
    }
}


export default ClientGift;
