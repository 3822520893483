import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import "./categoryFilters.scss";

import closeIcon from "./../../../../assets/img/close_black.svg"
import i18next from "i18next";

interface Props extends RouteComponentProps<any>{
    menuList?: any,
    selectedItem?: any,
    toggle?: any,
    toggleClick?: any,
    updateFilters?: any,
    filters?: any
}

interface State {
    activeIndexs?: any[],
    content?:any,
    colors?: any,
    checkboxes?: any,
}

class CategoryFilters extends React.Component<Props & State> {
    state = {
        activeIndexs: Array(),
        checkedColor: new Set(),
        content: {},
        colors:[],
        checkboxes:{}
    };

    componentDidMount() {
        let y = window.location.href.includes("filtres") ? window.location.href.split("/filtres")[1].replace(/%20/g, " ") : "";
        y = y.replace(/%7Bperc%7D/g, "%" );
        let urlArr = y.split("/").toString();
        if(urlArr && urlArr.length > 1) {
            let contentObj : any = {}
            let checkboxes : any = {}
            urlArr.split(",").forEach((item,index)=>{
                if (item.toUpperCase() === "COLOR") {
                    let colors = urlArr.split(",")[index + 1].split("-");
                    contentObj={...contentObj,[item]:colors}
                    this.setState({colors})
                }else{
                    if(index && index % 2 ==0){
                        const checkname = urlArr.split(",")[index-1]
                        const checkBoxValue = urlArr.split(",")[index].split("-")
                        if(checkname && checkname.toUpperCase() !== "COLOR" && checkname.length ) {
                            let checkboxesArr : any = [];
                            checkboxesArr.push(checkBoxValue);
                            checkboxes = {...checkboxes,[checkname]:checkBoxValue}
                            contentObj={...contentObj,...checkboxes}
                        }
                    }
                }
            })
            this.props.updateFilters(contentObj, false)
            this.setState({content:contentObj,checkboxes})
        };
    };
    componentDidUpdate(prevProps,prevState) {
        const {filters} = this.props;
        if(prevState.activeIndexs.length !== this.state.activeIndexs.length ||(prevProps.filters && prevProps.filters.length !== (filters && filters.length))){
            const newIndex : any = []
            if(filters &&  filters.length ){ filters.forEach((item, index) =>{ newIndex.push(index)});this.setState({ activeIndexs: newIndex })}
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndexs } = this.state;
        const newIndex :any = activeIndexs;

        const currentIndexPosition  = activeIndexs.indexOf(index);
        if (currentIndexPosition > -1) {
            newIndex.splice(currentIndexPosition, 1);
        } else {
            newIndex.push(index);
        }

        this.setState({ activeIndexs: newIndex });
    };

    updatePathName = () => {
        let res = '';
        let path =  this.props.location.pathname;
        const {content} = this.state;

        if(content && Object.keys(content).length ){
            Object.keys(content).map((item) => {
                if(content[item] && content[item].length){
                    res += item + '/' + content[item].join("-") + "/"
                }
            });
        }

        if(res) {
            path = path.includes("filtres") ? path.split("/filtres")[0] : path;
            res = res.replace(/%/g, '{perc}');
            this.props.history.push((path + "/filtres/" + res).replace(/\/\/+/g, '/'));
        } else {
            this.props.history.push(path.split("/filtres")[0]);
        }
    };

    responseContent = (value, type) => {
        const { updateFilters } = this.props;
        const {content} = this.state;
        if(!content[type]){
            let contentObj ={...content,[type]:[value]}
            this.setState({content:contentObj},()=>{this.updatePathName();updateFilters(contentObj, true, type)})
        }else if(content[type] && content[type].indexOf(value) === -1){
            let filtreArr = [...content[type]];
            filtreArr.push(value);
            const contentObj ={...content,[type]:filtreArr}
            this.setState({content:contentObj},()=>{this.updatePathName();updateFilters(contentObj, true, type)})
        } else {
            let filtreArr = [...content[type]];
            const index =filtreArr.findIndex((i) => i === value);
            filtreArr.splice(index, 1)
            const contentObj ={...content,[type]:filtreArr}
            this.setState({content:contentObj},()=>{this.updatePathName();updateFilters(contentObj, true, type)})
        }
    };

    checkColor = (value,type) => {
        const {colors} = this.state
        const index =  colors.findIndex(item=> item ===value)
        if(index !== -1) {
            let colorsArr : any = [...colors];
            colorsArr.splice(index,1);
            this.setState({colors:colorsArr})
        } else {
            let colorsArr  : any = [...colors];
            colorsArr.push(value)
            this.setState({colors:colorsArr})
        }
        this.responseContent(value, type);
    };

    checkCheckboxes = (value, type) => {
        const {checkboxes}= this.state;
        if(checkboxes[type]){
            const index =  checkboxes[type].findIndex(item=> item ===value)
            if(index !== -1) {
                let checkboxesArr : any = [...checkboxes[type]];
                checkboxesArr.splice(index,1);
                let checkboxesObj = {...checkboxes,[type]:checkboxesArr}
                this.setState({checkboxes:checkboxesObj})
            }
            else {
                let checkboxesArr : any = [...checkboxes[type]];
                checkboxesArr.push(value)
                let checkboxesObj = {...checkboxes,[type]:checkboxesArr}
                this.setState({checkboxes:checkboxesObj})
            }
        }else {
            let checkboxesArr : any = [];
            checkboxesArr.push(value);
            let checkboxesObj : any= {...checkboxes,[type]:checkboxesArr}
            this.setState({checkboxes:checkboxesObj})
        }

        this.responseContent(value, type);
    };

    render(){
        const { activeIndexs,colors,checkboxes} = this.state;
        const { filters , toggle, toggleClick } = this.props;
        let open = (toggle) ? 'open-filter' : '';
        return (
            <>
                {
                    filters && filters.length ?<div className={`sidbar ${open}`}>
                        <div className="filter-title">{i18next.t('footer.contact.4')}
                            <img  src={closeIcon} onClick={toggleClick} className="x icon" alt="close" />
                        </div>
                        {
                            filters &&  filters.length ? filters.map((item, index) => {
                                const colorStyle = item.searchFilter.toUpperCase() === "COLOR" ? "colors-list" : "filter-list";
                                return <Accordion className="sidebar-box" key={index}>
                                    <Accordion.Title className="title"
                                                     active={activeIndexs.includes(index)}
                                                     index={index}
                                                     onClick={this.handleClick}>{item.filter}</Accordion.Title>
                                    <Accordion.Content active={activeIndexs.includes(index)}>
                                        <ul className={colorStyle}>
                                            {
                                                item.values && item.values.length && item.values.map((value, index) => {
                                                    const elemIndex=  colors.findIndex(item=> item ===value.searchValue)
                                                    if(item.searchFilter.toUpperCase() === "COLOR") {
                                                        return <li key={index} className={elemIndex !== -1 ? "selected" : ""}>
                                                    <span
                                                        className={`color-box ${elemIndex !== -1 ? " checked" : ""}${(value.searchValue === "#000000") ? " black" : "" }${(value.value === "Multicolore") ? " multi" : "" }`}
                                                        style={{"backgroundColor": value.value.slice(0, 7), "border" : '1px solid grey',}}
                                                        onClick={() => {this.checkColor(value.searchValue, item.searchFilter)}}
                                                    >
                                                    </span>

                                                            <span className='color-name'>{value.value.split("-")[1] ? value.value.split("-")[1] : value.value.split("-")[0]}</span>
                                                        </li>
                                                    } else {
                                                        const elemIndex= checkboxes[item.searchFilter] ? checkboxes[item.searchFilter].findIndex(elem=> elem ===value.searchValue): -1;
                                                        return <li key={index}>
                                                            <label className="checkbox-label" htmlFor={`${item.filter}-${index}`}>
                                                                <input
                                                                    onChange={()=>{}}
                                                                    type="checkbox"
                                                                    id={`${item.filter}-${index}`}
                                                                    onClick={() => {this.checkCheckboxes(value.searchValue, item.searchFilter)}}
                                                                    checked = {elemIndex !== -1}
                                                                />
                                                                <span className='information'>{value.value}</span>
                                                                <span className="check-elem"></span>
                                                            </label>
                                                        </li>
                                                    }
                                                })
                                            }
                                        </ul>
                                    </Accordion.Content>
                                </Accordion>
                            }) : ""
                        }
                        <button className="show-res-btn">Afficher les résultats</button>
                    </div>:null
                }
            </>
        )
    }
}

export default withRouter(CategoryFilters);
