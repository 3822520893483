import React from 'react';
import { Tab, Table } from 'semantic-ui-react';
import TerminalStep3 from './terminaStep3';
import i18next from 'i18next';
import { getDateTimestampByTime, priceThreeDigitSets } from '../../../../helpers/helpers';

const OpenTerminal = (props) => {
    
    const fields = ["cashDeposit", "checkDeposit", "amexDeposit", "creditCardDeposit", "giftCardDeposit", "wireDeposit"]
    const panes = [
        {
            menuItem: i18next.t('Terminal.terminalOpeningBillets.tab1'),
            render: () => {
                const terminal= props.paymentsReportData[Object.keys(props.paymentsReportData)[0]]
                return (
                    <Tab.Pane loading={props.loading} attached={false}>
                        { terminal && <div className="mb-2">
                            <Table celled collapsing className="w-100 table-store-counter">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t(`Terminal.terminalOpeningBillets.previousDayTerminalClosing`)}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {getDateTimestampByTime(terminal.previousDayTerminalClosing)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t(`Terminal.openClose.Associate`)}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {terminal.previousDayAssociateClosing}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className="fermeturePopUp--body--title">
                                        {i18next.t(`Terminal.terminalOpeningBillets.previousDayCommentClosing`)}
                                    </Table.Cell>
                                    <Table.Cell className="fermeturePopUp--body--description">
                                        {terminal.previousDayCommentClosing}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        </div>
                        }
                        <div className="table-store-counter--Content">
                            {
                                Object.keys(props.paymentsReportData).map((item, idx) => {
                                    const data = props.paymentsReportData[item];
                                    const { previousDayTurnover } = data;
                                    return (
                                        <React.Fragment key={item}>
                                    { data ?
                                        <div className="table-store-counter--Content open-terminal--table--content">
                                            <div>
                                            <Table celled collapsing className="w-100 table-store-counter">
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalOpeningBillets.previousDayAmountClosing`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            {priceThreeDigitSets(data.previousDayAmountClosing, "", data.currencySymbol)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        previousDayTurnover && (
                                                            <>
                                                                <Table.Row>
                                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                                        {i18next.t(`Terminal.Receipt.zReport.paymentAmountTotal`)}
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                                        {priceThreeDigitSets(previousDayTurnover.paymentAmountTotal, "", data.currencySymbol)}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                                        {i18next.t(`Terminal.Receipt.zReport.paymentNetTotal`)}
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                                        {priceThreeDigitSets(previousDayTurnover.paymentNetTotal, "", data.currencySymbol)}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                                        {i18next.t(`Terminal.Receipt.zReport.remises`)}
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                                        {priceThreeDigitSets(previousDayTurnover.specialDiscountAmountTotal, "", data.currencySymbol)}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </>
                                                        )
                                                    }
                                                </Table.Body>
                                            </Table>
                                            {
                                                Array.isArray(data.previousDayPayment) && data.previousDayPayment.length ?
                                                    <Table celled collapsing className="w-100 table-store-counter">
                                                        <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell className="fermeturePopUp--body--title blueText">
                                                                {i18next.t("Terminal.dashboards.method")}
                                                            </Table.Cell>
                                                            <Table.Cell className="fermeturePopUp--body--description blueText">
                                                                {i18next.t("Terminal.dashboards.quantity")}
                                                            </Table.Cell>
                                                            <Table.Cell className="fermeturePopUp--body--description blueText">
                                                                {i18next.t("Terminal.dashboards.saleAmount")}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                            {
                                                                data.previousDayPayment.map((item, idx) => {
                                                                    return (
                                                                        <Table.Row key={idx}>
                                                                            <Table.Cell
                                                                                className="fermeturePopUp--body--title">
                                                                                {item.paymentMethod}
                                                                            </Table.Cell>
                                                                            <Table.Cell
                                                                                className="fermeturePopUp--body--description">
                                                                                {item.paymentMethodCount}
                                                                            </Table.Cell>
                                                                            <Table.Cell
                                                                                className="fermeturePopUp--body--description">
                                                                                {priceThreeDigitSets(item.paymentAmount, "", data.currencySymbol)}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                : ""
                                            }
                                            {
                                                data.previousDayDeposit && Object.keys(data.previousDayDeposit).length ?
                                                    <Table celled collapsing className="w-100 table-store-counter">
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell className="fermeturePopUp--body--title blueText">
                                                                    {i18next.t("Terminal.terminalClose.deposit")}
                                                                </Table.Cell>
                                                                <Table.Cell className="fermeturePopUp--body--description blueText">
                                                                    {i18next.t("Terminal.dashboards.saleAmount")}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            {
                                                                fields.map((field, idx) => {
                                                                    return (
                                                                        <Table.Row key={idx}>
                                                                            <Table.Cell
                                                                                className="fermeturePopUp--body--title">
                                                                                {i18next.t(`Terminal.terminalClose.${field}`)}
                                                                            </Table.Cell>
                                                                            <Table.Cell
                                                                                className="fermeturePopUp--body--description">
                                                                                {priceThreeDigitSets(data.previousDayDeposit[field], "", data.currencySymbol)}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                : ""
                                            }
                                            {
                                                data.previousDaySafe && Object.keys(data.previousDaySafe).length ?
                                                    <Table celled collapsing className="w-100 table-store-counter">
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell className="fermeturePopUp--body--title blueText">
                                                                    {i18next.t("Terminal.terminalOpeningBillets.previousDaySafe")}
                                                                </Table.Cell>
                                                                <Table.Cell className="fermeturePopUp--body--description blueText">
                                                                    {i18next.t("Terminal.dashboards.saleAmount")}
                                                                </Table.Cell>
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <Table.Cell
                                                                    className="fermeturePopUp--body--title">
                                                                    {i18next.t(`Terminal.openClose.Cash`)}
                                                                </Table.Cell>
                                                                <Table.Cell
                                                                    className="fermeturePopUp--body--description">
                                                                    {priceThreeDigitSets(data.previousDaySafe.cashSafe, "", data.currencySymbol)}
                                                                </Table.Cell>
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <Table.Cell
                                                                    className="fermeturePopUp--body--title">
                                                                    {i18next.t(`Terminal.terminalOpeningBillets.check`)}
                                                                </Table.Cell>
                                                                <Table.Cell
                                                                    className="fermeturePopUp--body--description">
                                                                    {priceThreeDigitSets(data.previousDaySafe.checkSafe, "", data.currencySymbol)}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell
                                                                    className="fermeturePopUp--body--title">
                                                                    {i18next.t(`Terminal.terminalOpeningBillets.giftSafe`)}
                                                                </Table.Cell>
                                                                <Table.Cell
                                                                    className="fermeturePopUp--body--description">
                                                                    {priceThreeDigitSets(data.previousDaySafe.giftSafe , "", data.currencySymbol)}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>
                                                : ""
                                            }
                                        </div>
                                        </div>
                                    : ""
                                    }
                                </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </Tab.Pane>
                )
            },
        },
        {
            menuItem: i18next.t('Terminal.terminalOpeningBillets.tab2'),
            render: () => <Tab.Pane attached={false}>
                <TerminalStep3
                    handelChangeWallet={props.handelChangeState}
                    details={props.details}
                    wallet={props.wallet}
                    walletPieces={props.walletPieces}
                    altWallet={props.altWallet}
                    altWalletPieces={props.altWalletPieces}
                    isTerminalOpen={props.isTerminalOpen}
                    counterClients={props.counterClients}
                    comment={props.comment}
                    isLoading={props.isLoading}
                    isDisabled={props.isDisabled}
                    submit={props.handleSubmiteOpenTerminal}
                    status={props.status}
                    terminalError={props.terminalError}
                    paymentsReportData={props.paymentsReportData}
                    dispatch={props.dispatch}
                    totalPrice={props.totalPrice}
                />
            </Tab.Pane>,
        },
    ]
    return (
        <Tab className="open-terminal-tab w-100" menu={{pointing: true}} panes={panes}/>
    )
}

export default OpenTerminal
