import React, {Component} from 'react';
import {Redirect, Switch} from "react-router";
import {connect} from 'react-redux'
import {MetroSpinner} from "react-spinners-kit";

import ErrorBoundaryRoute from "../../shared/error/error-boundary-route";
import Header from './header/container/header';
import Footer from './footer/container/footer';

import Home from '../../modules/home/container/home';
import Product from "../../modules/product/container/product";
import Products from '../../modules/products/container/index';
import Stocks from "./../../modules/stocks/container/stocks";
import Clients from "./../../modules/clients/container/clients";
import LivraisonClient from "../../modules/livraisonClient/container/livraisonClient";
import ReservationMagasin from "../../modules/reservationMagasin/container/reservationMagasin";
import Sales from "./../../modules/sales/container/sales";
import BonsCadeaux from "./../../modules/bonsCadeaux/container";
import Transfers from './../../modules/transfers/container/transfers';
import Deliveries from './../../modules/deliveries/container/deliveries';
import Returns from './../../modules/return/container/returns';
import Services from '../../modules/services/container/Services';
import QuotesDashboard from  './../../modules/quotes/container/dashboard';
import Inventory from './../../modules/inventory/container/inventory';

import {BRAND_ID, CATALOGUE_TYPE, MENU_TYPE} from "../../utils/constants/variables";
import {getLangPrefix, isValidPrivateToken} from "../../helpers/helpers";
import {getMenu} from "./header/redux/selectors";
import { bindActionCreators } from 'redux';
import { UserActionCreators } from '../../modules/login/redux/actions';
import { getAccountResource } from '../../modules/login/redux/selectors';
import i18next from 'i18next';
import Calendar from '../../modules/calendar/container/calendar';
import AdminDashboard from '../../modules/Admin/container/Admin'
import AppointmentDashboard from '../../modules/Appointments/container/Appointments'
import OldLoginSidebar from './sidebar/container/sidebar';

interface State {
    routeList: any;
    firstTime: boolean;
    clinetMenuItems: any;
}

class MainLayout extends Component<any> {
    state: State = {
        routeList: null,
        firstTime: true,
        clinetMenuItems: {}
    };

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.menu.length && this.props.menu.length && !this.state.firstTime) {
            this.getRouteList(this.props.menu);
        }
        if(this.state.firstTime) {
            this.getRouteList(this.props.menu);
            this.setState({ firstTime: false })
        }
    };

    getRouteList = (data) => {
        function setList(menu) {
            return (menu && menu.length && menu || []).reduce((acc: any, menuItem: any) => {
                if(['click_and_collect', 'ship_from_store'].includes(menuItem.key)) {
                    const key = menuItem.key.split("_")[0];
                    acc.clinetMenuItems[key] = menuItem;
                } else {
                    if(menuItem.canonicalUrl) {
                        acc.routeList.push(menuItem);
                    }
                    if(menuItem.children && menuItem.children.length) {
                        const { routeList } = setList(menuItem.children);
                        acc.routeList = [...acc.routeList, ...routeList];
                    }
                }
                return acc;
            }, { routeList: [], clinetMenuItems: {} })
        };
        const { routeList, clinetMenuItems } = setList(data);
        this.setState({routeList, clinetMenuItems})
    };

    getComponentByRoute = (urlKey="") => {
        const keys = {
            products: Stocks,
            clients: Clients,
            transfers: Transfers,
            quotes: QuotesDashboard,
            deliveries: Deliveries,
            sales: Sales,
            coupons: BonsCadeaux,
            retours: Returns,
            inventory : Inventory,
            tickets: Returns,
            services: Services,
            admin: AdminDashboard,
            appointments: AppointmentDashboard
        }
        return keys[urlKey.toLowerCase()] || Products;
    };

    reduceCallback = (acc, url, i )=> {
        const component = this.getComponentByRoute(url.key);
        const LANGUAGE_PREFIX = getLangPrefix();
        const routes = [
            <ErrorBoundaryRoute
                exact
                path={`${LANGUAGE_PREFIX}${url.canonicalUrl}`}
                component={component} key={i}
                menuItem={url}
            />,
        ]

        if(url.menuType === MENU_TYPE) {
            routes.push(<ErrorBoundaryRoute
                exact
                path={`${LANGUAGE_PREFIX}${url.canonicalUrl}/:productId`}
                component={Product}
                key={i}
                menuItem={url}
            />)
        }

        if(url.menuType === MENU_TYPE || url.menuType === CATALOGUE_TYPE) {
            routes.push(<ErrorBoundaryRoute
                exact
                path={`${LANGUAGE_PREFIX}${url.canonicalUrl}/filtres/:data+`}
                component={component}
                key={i}
                menuItem={url}
            />)
        }
        <Redirect from="/" to={`/${LANGUAGE_PREFIX}`}/>

        return [...acc, ...routes]
    }

    render() {
        const LANGUAGE_PREFIX = getLangPrefix();
        if(!isValidPrivateToken()){
            return (<Redirect to={`${LANGUAGE_PREFIX}/login`} />)
        }
        const { account = {}, logoutStart } = this.props;
        const { userBrandApp = [], brandApp = BRAND_ID } = account;
        const isSidebarVisible = userBrandApp.length > 1;
        const { routeList, clinetMenuItems } = this.state;
        return (
            <div className={`mainContent ${isSidebarVisible ? 'left-padding' : ''}`}>
                <Header />
                {isSidebarVisible && (
                    <OldLoginSidebar
                        userBrandApp={userBrandApp}
                        logoutStart={logoutStart}
                        brandApp={brandApp}
                    />
                )}
                <div className="mainContent--content">
                    <Switch>
                        <Switch>
                            {routeList ?
                                <Switch>
                                    { routeList.reduce(this.reduceCallback, []) }
                                    {clinetMenuItems.ship && <ErrorBoundaryRoute
                                        path={`${LANGUAGE_PREFIX}${clinetMenuItems.ship.canonicalUrl}`}
                                        menuItem={{customMenu: true, name: i18next.t('Terminal.Menu.2'), url:`${LANGUAGE_PREFIX}${clinetMenuItems.ship.canonicalUrl}`}}
                                        component={LivraisonClient}
                                    />}
                                    {clinetMenuItems.click && <ErrorBoundaryRoute
                                        path={`${LANGUAGE_PREFIX}${clinetMenuItems.click.canonicalUrl}`}
                                        menuItem={{customMenu: true, name: i18next.t('Terminal.Menu.1'), url:`${LANGUAGE_PREFIX}${clinetMenuItems.click.canonicalUrl}`}}
                                        component={ReservationMagasin}
                                    />}
                                    <ErrorBoundaryRoute exact path="/" component={Sales} menuItem={{}}/>
                                    <ErrorBoundaryRoute exact path={`${LANGUAGE_PREFIX}`} component={Calendar} menuItem={{}}/>

                                    <Redirect from="*" to={`${LANGUAGE_PREFIX}/`}/>

                                </Switch>
                                :
                                <MetroSpinner
                                    size={50}
                                    color="#686769"
                                    loading={true}
                                />
                            }

                        </Switch>
                        <ErrorBoundaryRoute exact path="/" component={Home} menuItem={{}}/>
                        <Redirect from="*" to={`/`}/>
                    </Switch>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    menu: getMenu(state),
    account: getAccountResource(state),
})

const mapDispatchToProps = (dispatch): typeof UserActionCreators => {
    return bindActionCreators(UserActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
