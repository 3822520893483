import {stockTypes, StockActionCreators} from "./actions";
import {takeLatest, put, call, select} from "redux-saga/effects";
import axios from 'axios';
import {
    STORE_SECURED,
    STOCK_URL,
    CREATE_STOCK_URL,
    getAxiosHeaders,
    ALL_PRODUCTS_SOLD,
    STOCK_COUNTERS,
    getProductDataByBarcode,
    ELASTIC_INDEX_SERVICE,
    PRODUCT_DELIVERY,
    IMPORT_PRODUCT_DASHBOARD,
    IMPORT_PRODUCTS,
    IMPORT_PRODUCT_DETAILS,
    UPDATE_PRODUCT_STOCK,
    UPDATE_PRODUCT_PRICE,
    UPDATE_ELASTIC_PRODUCT_INDEX,
    UPLOAD_STORE_INVENTORY,
    getAssociateServices,
    addAssociateServices,
    fetchStoreAssociates
} from "../../../utils/constants/service";
import {BRAND_ID, DEFAULT_CURRENCY} from "../../../utils/constants/variables";
import { getApiPrefix} from "../../../helpers/helpers"
import {getAccountResource} from "../../login/redux/selectors";

export function* getHomeData() {
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency : DEFAULT_CURRENCY
            }
        };
        const data = yield call(() => {
            return axios.get(`${STOCK_URL}?language=${getApiPrefix()}`, headers)
                .then(res => res.data)
        });
        yield put(StockActionCreators.stockSuccess(data));
    } catch (error) {
        yield put(StockActionCreators.stockFailure(error));
    }
}

export function* getSingleStockData(params){
    const privateToken = sessionStorage.getItem("privateToken")
    const token = localStorage.getItem("token");
    const clientId = params.id;
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency : DEFAULT_CURRENCY
            }
        };
        const data = yield call(() => {
            return axios.get(`${STORE_SECURED}store-products/${clientId}/details?language=${getApiPrefix()}`, headers)
                .then(res => res.data)
        });
        yield put(StockActionCreators.singleStockSuccess(data));
    } catch (error) {
        yield put(StockActionCreators.singleStockFailure(error));
    }
}

export function* getBarcodeProduct(params) {
    try {
        const {barcode} = params;
        const details = yield select(getAccountResource)
        const data = yield call(() => getProductDataByBarcode(barcode, details));

        yield put(StockActionCreators.barcodeProductSuccess(data.productId ? data : {productId: -1} ))

    }catch (error) {
       yield put(StockActionCreators.barcodeProductFailure(error))
    }
}

export function* createStock(params) {
    const {body} =params,
        privateToken = sessionStorage.getItem("privateToken"),
        token = localStorage.getItem("token"),
        headers = {
        headers: {
            brand: BRAND_ID,
            Authorization: `Bearer ${privateToken}`,
            "Associate-Authorization": `${token}`,
            'Content-Type': 'application/json'
        }};
    const { storeDetails } = yield select(getAccountResource);
    try {
        const data = yield call(() => {
            return axios.post(`${CREATE_STOCK_URL}`, body, headers)
                .then(res => res.data)
        });
        const status = yield call(() => {
            return axios.post(`${ELASTIC_INDEX_SERVICE}?productId=${data.productId}`, {}, headers)
                .then(res => res.status)
        });
        if(status === 200) {
            yield call(() => {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(body.barcode)
                    }, 6000)
                })
            });
            yield put(StockActionCreators.createStockSuccess(data));
        } else {
            throw new Error('Elastic index not valid');
        }
    } catch (error) {
        yield put(StockActionCreators.createStockFailure(error));
    }
}

export function* productSold({id}) {
    try {
        const data = yield call(() => {
            return axios.get(`${ALL_PRODUCTS_SOLD}?productId=${id}&language=${getApiPrefix()}`, getAxiosHeaders())
                .then(res => res.data)
        });

        yield put(StockActionCreators.productSoldSuccess(data))

    }catch (error) {
       yield put(StockActionCreators.productSoldFailure(error))
    }
}

export function* getStockCounters(params) {
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency : DEFAULT_CURRENCY
            }
        };
        const data = yield call(() => {
            return axios.get(`${STOCK_COUNTERS}?language=${getApiPrefix()}`, headers)
                .then(res => res.data)
        });
        yield put(StockActionCreators.stockCountersSuccess(data));
    } catch (error) {
        yield put(StockActionCreators.stockCountersFailure(error));
    }
}

export function* getProductDelivery(params) {
    try {
        const { barcode } = params;
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };

        const data = yield call(() => {
            return axios.get(`${PRODUCT_DELIVERY}?barcode=${barcode}`, headers).then((res) => res.data);
        })

        yield put(StockActionCreators.getProductDeliverySuccess(data));
    } catch (error) {
        yield put(StockActionCreators.getProductDeliveryFailure(error));
    }
}

export function* getProductDeliveryStart() {
    try {
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => axios.get(`${IMPORT_PRODUCT_DASHBOARD}`, headers).then((res) => res.data))
        yield put(StockActionCreators.getStockProductsSuccess(data));
    } catch(error) {
        yield put(StockActionCreators.getStockProductsFailure(error));
    }
}

export function* importStockProduct(params) {
    try {
        const { body } = params;
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${privateToken}`,
            "Associate-Authorization": `${token}`,
            "Content-Type": "multipart/form-data"
        }

        const data = yield call(() => axios({
                method: "POST",
                url: IMPORT_PRODUCTS,
                data: body,
                headers,
            }).then(res => res.data)
        )
        yield put(StockActionCreators.getStockProductsStart());
        yield put(StockActionCreators.importStockProductsSuccess(data));
    } catch(error) {
        yield put(StockActionCreators.importStockProductsFailure(error));
    }
}

export function* importStockProductDetails(params) {
    try {
        const { id } = params;
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => axios.get(`${IMPORT_PRODUCT_DETAILS}?productFileImportId=${id}`, headers)
            .then((res) => res.data))

        yield put(StockActionCreators.importProductDetailsSuccess(data));
    } catch (error) {
        yield put(StockActionCreators.importProductDetailsFailure(error));
    }
}

export function* updateProductStock(params) {
    try {
        const { barcode, stock, productId } = params;
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const { data, status } = yield call(() => axios.put(`${UPDATE_PRODUCT_STOCK}?barcode=${barcode}&stock=${stock}`, {}, headers)
            .then((res) => res));

        if (status === 200) {
            yield put(StockActionCreators.singleStockStart(productId));
        }

        yield put(StockActionCreators.updateProductStockSuccess(data));
    } catch (error) {
        yield put(StockActionCreators.updateProductStockFailure(error));
    }
}

export function* updateProductPrice(params) {
    try {
        const { body, productId } = params;
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };

        if (!body.outletPrice) delete body.outletPrice;

        const { data, status } = yield call(() => axios.post(`${UPDATE_PRODUCT_PRICE}`, body, headers).then(res => res));
        if (status === 200) {
            yield call(() => axios.post(`${UPDATE_ELASTIC_PRODUCT_INDEX}?productId=${body.productId}`, {}, headers)
                .then((res) => res));
            yield put(StockActionCreators.singleStockStart(productId));
        }
        yield put(StockActionCreators.updateProductPriceSuccess(data));
    } catch (error) {
        yield put(StockActionCreators.updateProductPriceFailure(error));
    }
}

export function* uploadStoreInventory(params) {
    try {
        const { body } = params;
        const privateToken = sessionStorage.getItem("privateToken");
        const token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${privateToken}`,
            "Associate-Authorization": `${token}`,
            "Content-Type": "multipart/form-data"
        }

        const data = yield call(() => axios({
                method: "POST",
                url: UPLOAD_STORE_INVENTORY,
                data: body,
                headers,
            }).then(res => res.data)
        );

        yield put(StockActionCreators.uploadStoreInventorySuccess(data));
    } catch(error) {
        yield put(StockActionCreators.uploadStoreInventoryFailure(error));
    }
}

export function* getAssociateService() {
    try {
        const data = yield call(() => getAssociateServices())
        yield put(StockActionCreators.getAssociateServiceSuccess(data))
    } catch(error) {
        yield put(StockActionCreators.getAssociateServiceFailure(error))
    }
}

export function* addServices(params) {
    const { body } = params;

    try {
        const data = yield call(() => addAssociateServices(body))

        yield put(StockActionCreators.addServicesSuccess(data))
        yield put(StockActionCreators.getAssociateServiceStart())
    } catch (error) {
        yield put(StockActionCreators.addServicesFailure(error))
    }
}

export function* getStoreAssociates() {
    try {
        const details = yield select(getAccountResource);
        const data = yield call(() => fetchStoreAssociates(details && details.storeCode));

        yield put(StockActionCreators.getStoreAssociatesSuccess(data))
    } catch (error) {
        yield put(StockActionCreators.getStoreAssociatesFailure(error))
    }
}

export function* stockSaga() {
    yield takeLatest(stockTypes.STOCK_START, getHomeData);
    yield takeLatest(stockTypes.SINGLE_STOCK_START, getSingleStockData);
    yield takeLatest(stockTypes.BARCODE_PRODUCT_START, getBarcodeProduct);
    yield takeLatest(stockTypes.CREATE_STOCK_START, createStock);
    yield takeLatest(stockTypes.PRODUCT_SOLD_START, productSold);
    yield takeLatest(stockTypes.STOCK_COUNTERS_START, getStockCounters);
    yield takeLatest(stockTypes.GET_PRODUCT_DELIVERY_START, getProductDelivery);
    yield takeLatest(stockTypes.GET_STOCK_PRODUCTS_START, getProductDeliveryStart);
    yield takeLatest(stockTypes.IMPORT_STOCK_PRODUCTS_START, importStockProduct);
    yield takeLatest(stockTypes.IMPORT_PRODUCT_DETAILS_START, importStockProductDetails);
    yield takeLatest(stockTypes.UPDATE_PRODUCT_STOCK_START, updateProductStock);
    yield takeLatest(stockTypes.UPDATE_PRODUCT_PRICE_START, updateProductPrice);
    yield takeLatest(stockTypes.UPLOAD_STORE_INVENTORY_START, uploadStoreInventory);
    yield takeLatest(stockTypes.GET_ASSOCIATE_SERVICE_START, getAssociateService);
    yield takeLatest(stockTypes.ADD_SERVICES_START, addServices);
    yield takeLatest(stockTypes.GET_STORE_ASSOCIATES_START, getStoreAssociates);
}

export default stockSaga;
