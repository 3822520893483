import React from "react";
import '../product.scss'
import Complect from "./complect";
import Parure from "./parure";
import Details from "./details";

interface Props {
    handleBasketToggle: any,
    handleGetWebStock: any,
    handleDeliverytToggle: any,
    data: any,
    menuItem?: any,
    webInventory: number,
}

interface State {
}

class ProductC extends React.Component<Props> {
    state: State = {};

    public divRef = React.createRef<HTMLDivElement>();
    public refDescription = React.createRef<HTMLDivElement>();

    scrollToDescription = (type) => {
        if (type === "h4") {
            return window.scrollTo(0, this.divRef.current!.offsetTop - 215)
        } else {
            return window.scrollTo(0, this.refDescription.current!.offsetTop - this.refDescription.current!.offsetHeight  );

        }
    };

    render() {
        const {data, menuItem,handleGetWebStock,webInventory} = this.props;
        return (
            <div className="products 1111">
                <Complect
                    handleBasketToggle={this.props.handleBasketToggle}
                    handleDeliverytToggle={this.props.handleDeliverytToggle}
                    data={data}
                    handleScrollToDescription={this.scrollToDescription}
                    handleGetWebStock={handleGetWebStock}
                    webInventory={webInventory}
                />
                <div ref={this.divRef}>
                    <Parure data={data} menuItem={menuItem}/>
                </div>
                <Details refDescription={this.refDescription} data={data}/>
            </div>
        )
    }
}

export default ProductC;

