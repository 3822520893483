import {createActions} from 'reduxsauce';

export const {Types: AddToDeliveryCartTypes, Creators: AddToDeliveryCartActionCreators} = createActions ({
    addtocartStart: ["name", "key", "value", "setAsQuantity"],
    addtodeliverycartSuccess: ['data'],
    addtodeliverycartFailure:['error'],
    getDeliveryBasketStart: ['name'],
    getDeliveryBasketSuccess: ["data"],
    removeBasketStart: ["name", "key", "prSize"],
    removeDeliveryBasketSuccess: ["data"],
    createTransferStart: ["data"],
    storesStart: [null],
    storesFormStart: [null],
    getBasketStocksStart: ['barcodeData','storeCode', 'storeType','basket'],
});
