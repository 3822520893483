import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';

import CustomBtn from '../../common/customBtn';

import { AdminActionCreators } from '../redux/actions';
import { bindActionCreators } from 'redux';
import { getAssociateFetchLoading, getAssociateloading, getAssociateServiceData } from '../redux/selectors';
import { associateServiceCreateValidationFields, associateServiceFormData } from '../../../utils/constants/formsState';
import { getStoreAssociatesSelector } from '../../stocks/redux/selectors';
// import { citiesZipcode } from '../../../utils/constants/variables';
import { zipCodes } from "../../../utils/constants/variables";
import './NewService.scss'
import { encodeCalendarPayload, getUserFromToken } from '../../../helpers/helpers';
import MultiDropdown from '../../multiSelectDropdown/multiSelectDropdown';
import { getAccountResource } from '../../login/redux/selectors';

interface Props {
    createAssociateStart: (body) => void;
    adminPageLoadingStart: (status: boolean) => void;
    getAssociateCalendarServiceStart: (code: string) => void;
    createAssociateServiceStart: (body, callback) => void;
    closePopup: () => void;
    selectedAssociate: any;
    isBrandCalendarLoading: boolean;
    storeAssociates: any;
    associateServiceData: any;
    details: any;
}

const NewService: React.FC<Props> = ({
    storeAssociates,
    getAssociateCalendarServiceStart,
    // closePopup,
    associateServiceData,
    isBrandCalendarLoading,
    createAssociateServiceStart,
    details,
    selectedAssociate
}) => {
    const [formData, setFormData] = useState<Record<string, any>>(associateServiceFormData({}));
    const [formErrors, setFormErrors] = useState<Record<string, any>>({});
    const [associateCode, setAssociateCode] = useState<string>('');
    const [updateMessage, setUpdateMessage] = useState<Record<string, any>>({});
    const endHour = 23;

    useEffect(() => {
        setFormData(associateServiceFormData(associateServiceData));
    }, [associateServiceData])

    useEffect(() => {
        handleChange({value: selectedAssociate.associateCode})
    }, [])

    const changeFormData = (_, event) => {
        const { name, value } = event;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const toNumberAndChange = (_, event) => {
        const { name, value } = event;
        changeFormData(_, { name, value: +value })
    }

    const getFormDataByName = (name) => {
        const result = formData[name] || "";

        return result;
    }

    const validateFormData = () => {
        const { isValid, validationResult } = Object.keys(
            associateServiceCreateValidationFields
        ).reduce((acc, key) => {
            if (associateServiceCreateValidationFields[key] && !formData[key]) {
                acc.validationResult[key] = true;
                acc.isValid = false;
            }
            return acc;
        }, { isValid: true, validationResult: {} })

        setFormErrors(validationResult);
        return { isValid }
    }

    const handleSubmitFormData = () => {
        const { storeDetails } = details;
        const { storeCode, storeId, brandId } = storeDetails;
        const validationResult = validateFormData();

        formData.storeCode = storeCode;
        formData.storeId = storeId;
        formData.brandId = brandId;
        formData.associateCode = selectedAssociate.associateCode;

        if (!validationResult.isValid) return;

        if (!formData.brandServiceId) {
            delete formData.brandServiceId;
        }

        createAssociateServiceStart({
            ...formData,
            srvCities: formData.srvCities.join(';'),
            srvCalendar: encodeCalendarPayload(formData.srvCalendar),
        }, (status) => {
            if (status) {
                setAssociateCode('');
                handleChange({value: selectedAssociate.associateCode});

                return setUpdateMessage({label: i18next.t("alertMessage.2"), error: false})
            }

            return setUpdateMessage({label: i18next.t("alertMessage.1"), error: true})
        });
    }

    const handleChange = (event) => {
        const { value } = event;
        setAssociateCode(value);
        if (value) getAssociateCalendarServiceStart(value);
    }

    const changeCalendarDate = (index, type, value) => {
        const data = {...formData};
        data.srvCalendar[index][type] = value;
        setFormData(data);
    }

    const anullerDay = (index) => {
        const data = {...formData};
        data.srvCalendar[index].empty = !data.srvCalendar[index].empty;
        setFormData(data);
    }

    const zipOptions = useMemo(() => {
        return [...getFormDataByName('srvCities'), ...zipCodes].reduce((acc, item, index) => {
            if (!acc.some((code) => code.value === item)) {
                acc.push({
                    key: index,
                    value: item,
                    text: item
                })
            }
            return acc;
        }, [])
    }, [getFormDataByName('srvCities')])

    return (
        <div className="parent-for-comment flex-col">
            {associateCode && (
                <div className="popUp--body--card--body set-data-content">

                    <div className='popUp--body--card--body--data'>
                        <div className="popUp--body--card--body--data--header s-aligen-start w-16-panel-city">
                            {i18next.t('AssociateService.srvCalendar')}
                        </div>
                        <div className='w-100'>
                            <div className='calendar-select-container'>
                                {getFormDataByName('srvCalendar').map((calendarItem, index) => (
                                    <div key={index} className='multi-time-select'>
                                        <div className="d-flex alg-center justify-between">
                                            <span className='text-bold mb-1'>{calendarItem.weekName.toUpperCase()}</span>
                                            <div className='mb-1 flex alg-center'>
                                                <Checkbox
                                                    onChange={() => anullerDay(index)}
                                                    checked={calendarItem.empty}
                                                    className='mr-1'
                                                />
                                                <span className='text-bold mb-1'>{i18next.t('AssociateService.notExistLabel')}</span>
                                            </div>
                                        </div>
                                        <div className='calendar-dropdown'>
                                            <div>
                                                <Dropdown
                                                    onChange={(_, event) => changeCalendarDate(index, 'start', event.value)}
                                                    options={new Array(
                                                        calendarItem.end ? calendarItem.end : endHour
                                                    ).fill(0).map((item, index) => ({
                                                        key: index,
                                                        value: index + item,
                                                        text: `${`${index + item}`.padStart(2, '0')}:00`
                                                    }))}
                                                    selection
                                                    value={calendarItem.start}
                                                    className="bold font-normal"
                                                    disabled={calendarItem.empty}
                                                />
                                            </div>
                                            <span className='text-bold mr-1 ml-1'> - </span>
                                            <div>
                                                <Dropdown
                                                    onChange={(_, event) => changeCalendarDate(index, 'end', event.value)}
                                                    options={new Array(
                                                        endHour - calendarItem.start
                                                    ).fill(calendarItem.start + 1).map((item, index) => ({
                                                        key: index,
                                                        value: index + item,
                                                        text: `${`${index + item}`.padStart(2, '0')}:00`
                                                    }))}
                                                    selection
                                                    value={calendarItem.end}
                                                    className="bold font-normal"
                                                    disabled={calendarItem.empty}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="popUp--body--card--body--between">
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header w-80">
                                {i18next.t('AssociateService.srvCancellation')}
                            </div>
                            <Input
                                onChange={changeFormData}
                                className={`popUp--body--card--body--data--description ${formErrors.srvCancellation ? 'error' : ''}`}
                                name="srvCancellation"
                                value={getFormDataByName("srvCancellation")}
                                autoComplete="off"
                            />
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header w-80">
                                {i18next.t('AssociateService.srvNotice')}
                            </div>
                            <Input
                                onChange={changeFormData}
                                className={`popUp--body--card--body--data--description ${formErrors.srvNotice ? 'error' : ''}`}
                                name="srvNotice"
                                value={getFormDataByName("srvNotice")}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="popUp--body--card--body--between">
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header w-80">
                                {i18next.t('AssociateService.srvInterval')}
                            </div>
                            <Input
                                onChange={changeFormData}
                                className={`popUp--body--card--body--data--description ${formErrors.srvInterval ? 'error' : ''}`}
                                name="srvInterval"
                                value={getFormDataByName("srvInterval")}
                                autoComplete="off"
                            />
                        </div>
                        {/* <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('AssociateService.srvMinimum')}
                            </div>
                            <Input
                                onChange={changeFormData}
                                className={`popUp--body--card--body--data--description ${formErrors.srvMinimum ? 'error' : ''}`}
                                name="srvMinimum"
                                value={getFormDataByName("srvMinimum")}
                                autoComplete="off"
                            />
                        </div> */}
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header w-80">
                                {i18next.t('AssociateService.srvStartLable')}
                            </div>
                            <Input
                                onChange={toNumberAndChange}
                                className={`popUp--body--card--body--data--description`}
                                name="srvStart"
                                value={getFormDataByName("srvStart")}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    {/* <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header w-16-panel-city">
                            {i18next.t('AssociateService.srvStartLable')}
                        </div>
                        <Input
                            onChange={toNumberAndChange}
                            className={`popUp--body--card--body--data--description`}
                            name="srvStart"
                            value={getFormDataByName("srvStart")}
                            autoComplete="off"
                        />
                    </div> */}
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header w-16-panel-city">
                            {i18next.t('AssociateService.srvCities')}
                        </div>
                        <MultiDropdown
                            options={zipOptions}
                            value={getFormDataByName("srvCities")}
                            onChange={(event) => changeFormData('', { name: 'srvCities', value: event.value })}
                            inputValidation={{
                                regex: new RegExp('^[0-9]{5}$'),
                                message: 'Invalid zip code'
                            }}
                            placeholder={i18next.t("placeholders.newZip")}
                        />
                    </div>
                    <div className="extra content">
                        <div className="ui two buttons">
                            <CustomBtn
                                classN="btn_1 w-100 blue_btn withoutHover"
                                handleClick={handleSubmitFormData}
                                loading={isBrandCalendarLoading}
                            >
                                {i18next.t('Terminal.dashboards.edit')}
                            </CustomBtn>
                        </div>
                        <span className={`d-flex justify-center w-100 ${updateMessage.error ? 'message-error' : 'message-success'}`}>{updateMessage.label}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state: any): any => {
    return {
        storeAssociates: getStoreAssociatesSelector(state),
        associateServiceData: getAssociateServiceData(state),
        isBrandCalendarLoading: getAssociateFetchLoading(state),
        details: getAccountResource(state)
    }
};

const mapDispatchToProps = (dispatch): typeof AdminActionCreators  => {
    return bindActionCreators(Object.assign({}, AdminActionCreators, {
        getAssociateCalendarServiceStart: AdminActionCreators.getAssociateCalendarServiceStart,
        createAssociateServiceStart: AdminActionCreators.createAssociateServiceStart
    }), dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(NewService);
