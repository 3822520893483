import React from "react";
import i18next from "i18next";
import Select from "../../../common/select/select";
import {Icon} from 'semantic-ui-react';
import Colorbar from "./colorbar";
import {Link} from "react-router-dom";
import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";
import {PRODUCT_MAX_QUANTITY} from "../../../../utils/constants/variables";
import AddToCart from './../../../addToCart/container/addToCart';

interface Props {
    index?: number,
    data?: any,
    handleChangeColor?: any,
    handleChangeQuantity?: any,
    handleChangeSize?: any,
    handleChangeSelected?: any,
    mobile?: boolean,
    selected?: boolean,
    reset?: boolean,
    quantity?: any,
    selectedProducts?: any,
    handleAddedToCart?: any,
    alt?: any,
}

interface State {
    options: any,
    isColorToggled: boolean,
    selectedSize: any,
    quantity?: any,
}

interface Options {
    value?: any,
    text?: any
}

export default class ComplectItem extends React.Component<Props, State> {
    state: State = {
        options: [],
        isColorToggled: false,
        selectedSize: "",
        quantity: 0,
    };

    componentWillReceiveProps = (nextProps) => {
        if (!this.props.reset && nextProps.reset) {
            this.setState({
                selectedSize: "",
                quantity: 0,
            })
        }
    };

    handleChangeQuantity = (name, quantity) => {
        this.setState({quantity});
        this.props.handleChangeQuantity(this.props.data.productId, quantity, this.state.selectedSize);
    };

    handleChangeSize = (name, selectedSize) => {
        this.setState({selectedSize});
        this.props.handleChangeSize(this.props.data.productId, selectedSize, this.state.quantity)
    };

    handleColorToggle = (e) => {
        e.stopPropagation();
        this.setState({isColorToggled: !this.state.isColorToggled});
    };

    render() {
        const {data, data: {maxQuantity = PRODUCT_MAX_QUANTITY, productId}} = this.props;
        const {isColorToggled, selectedSize, quantity} = this.state;
        const options: Options[] = [];
        for (let i = 1; i <= maxQuantity; i++) {
            options.push({value: i.toString(), text: i.toString()});
        }
        let sizes = data.sizes ? data.sizes.map((item, i) => ({value: i.toString(), text: item.size})) : [];
        sizes.unshift({value: "", text: i18next.t("product.product.17")});
        options.unshift({value: "", text: i18next.t("product.product.5")});
        const listPriceAfterDiscount = selectedSize === "" ? data.listPriceAfterDiscount : data.sizes[selectedSize].listPriceAfterDiscount

        return (
            <div className={`pure-complect-item-content ${selectedSize && Number(quantity) ? "active" : ''}`}>
                <div className="pure-complect-item-content-img">
                    <Link to={data.canonicalUrl}>
                        { data.photo && data.thumbnail ?
                            <Photo src={data.photo}
                                   outlet={data && data.outlet}
                                   discountDetail={data && data.discountDetail}
                                   isPromo={!!data.listPriceAfterDiscount}
                                   thumbnail={data.thumbnail}
                                   onSpecial={data.onSpecial}
                                   alt={data.name}/> :""}
                    </Link>
                </div>

                <div className="pure-complect-item-wrapper">
                    <div className="pure-complect-item-wrapper--header">
                        <div className="pure-complect-item-wrapper-title-box">
                            <h4 className="title">{data.name}</h4>
                            <Link to={data.canonicalUrl}>{i18next.t('product.parure.2')}</Link>
                        </div>
                        <div className="pure-complect-item-wrapper-links">
                            {
                                data.sizes && data.sizes.length ?
                                    <>
                                        <Select value={selectedSize} options={sizes} handleChange={this.handleChangeSize} />
                                        <Select align="center" name={productId ? productId.toString() : ""} value={quantity}
                                                options={options}
                                                handleChange={this.handleChangeQuantity}
                                        />
                                    </>
                                    :
                                    <div className="product-no-size">
                                        <h4 className="no-size-title">{i18next.t('product.product.18')}</h4>
                                    </div>

                            }
                            <a href="#" rel="noopener">{i18next.t('product.product.4')}</a>
                        </div>
                    </div>

                    <div className="pure-complect-color-box">
                        {data.colors && data.colors.length > 1 ?
                            <>
                            <div className="pure-complect-color-button dflex align-items-center"
                                 onClick={this.handleColorToggle}>
                                <p>{i18next.t('product.parure.3')}</p>
                                <Icon name="angle down" className={`${isColorToggled ? "active" : ''}`}></Icon>
                            </div>
                            {isColorToggled &&
                            <Colorbar data={data.colors}/>
                            }
                            </>
                            :
                            ""
                        }
                    </div>
                    <div className="pure-complect-item-wrapper-price">
                        {quantity < 1 ? <h6 className="title">{i18next.t('product.product.7')}</h6> : ""}
                        <Price
                            startP={selectedSize === "" ? data.startingPrice : data.sizes[selectedSize].startingPrice}
                            discount={selectedSize === "" ? data.discountDetail : data.sizes[selectedSize].discountDetail}
                            newP={listPriceAfterDiscount}
                            quantity={quantity < 1 ? 1 : quantity}
                            isPromo={!!listPriceAfterDiscount}
                        />
                    </div>
                </div>
                <div className="complect-item-btn">
                    <AddToCart
                        buttonText={i18next.t('product.product.13')}
                        data={this.props.selectedProducts}
                        isComplect={true}
                        handleAddedToCard={this.props.handleAddedToCart}
                        isEnable={quantity && selectedSize}
                    />
                </div>
            </div>
        );
    }
}
