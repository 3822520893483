import React, { useState } from 'react';
import i18next from 'i18next';
import {
    Page,
    Text,
    View,
    Document,
    Image,
    StyleSheet,
    Font,
    pdf,
} from '@react-pdf/renderer';
import { getDateByTimestamp, getDiscountDetail } from '../../helpers/printer';
import {
    BASE_API_GATEWAY,
    getPaperReceiptClient,
    getPaperReceiptData,
} from '../../utils/constants/service';
import { RECEIPT_TYPE } from '../../utils/constants/variables';
import CustomBtn from '../common/customBtn';
import InfoPopUp from '../common/infoPopUp';
import {
    redirectClientPage,
    getItClientMandatoryFields,
} from '../../helpers/helpers';

export const FatturaBtn = (props) => {
    const {
        className,
        disabled = false,
        details = {},
        ticketNumber,
        clientId,
        menu,
    } = props;
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handlePdf = async () => {
        try {
            if (!ticketNumber) {
                return;
            }
            if (!clientId) {
                setMessage(i18next.t('Terminal.confirm.14'));
                return;
            }

            setLoading(true);

            const client = await getPaperReceiptClient(clientId);
            const { profile = {}, billingAddresses = [] } = client;
            const address = billingAddresses[0] || {};
            const message = getItClientMandatoryFields(client);

            if (message) {
                setMessage(`${i18next.t('alertMessage.9')}: ${message}`);
                setLoading(false);
                return;
            }
            const data = await getPaperReceiptData(
                ticketNumber,
                RECEIPT_TYPE.invoice,
            );

            const blob = await pdf(
                <FatturaPdf
                    data={{ ...data, billingAddresses: address, profile }}
                    details={details}
                />,
            ).toBlob();

            setLoading(false);
            const url = URL.createObjectURL(blob);
            // this.props.salesStart({})
            window.open(url, '_blank');
        } catch (error) {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setMessage('');
        redirectClientPage(clientId, menu);
    };

    return (
        <>
            <CustomBtn
                classN={className}
                disabled={disabled}
                handleClick={handlePdf}
                loading={loading}
            >
                {i18next.t('buttons.16')}
            </CustomBtn>
            <InfoPopUp
                open={!!message}
                classN="message-error"
                message={message}
                handleClose={handleClose}
            />
        </>
    );
};

export const FatturaPdf = ({ data = {}, details = {} }) => {
    const { storeDetails = {}, currencySymbol, currency } = details;
    const { fiscalLogo = '' } = storeDetails;
    const {
        paymentDate,
        invoiceNumber,
        billingAddresses = {},
        referenceTicket,
        referencePaymentDate,
        saleQuantity,
        storeSaleLineItemTicketList = [],
        profile,
        storeSalePaymentTaxTicketList = [],
        paymentAmount,
        storeReceiptDisclaimer,
    } = data;
    const {
        fiscalCode = '',
        pecEmail = '',
        sdiCode = '',
        vatNumber = '',
    } = profile;
    const {
        company = '',
        address1 = '',
        city = '',
        zip = '',
        country = '',
        name = '',
    } = billingAddresses;

    const formatTwoDecimals = (num) => {
        try {
            if (!num) return '';
            return Number(num).toFixed(2).replace('.', ',');
        } catch (e) {
            return '';
        }
    };

    const getProductBlock = () => {
        const products = [];
        storeSaleLineItemTicketList.forEach((item) => {
            const discount = getDiscountDetail(item, currencySymbol) || '';
            products.push(
                <View style={[styles.row]} key={item.barcode}>
                    <Text style={[styles.product, styles.cell, styles.w15]}>
                        {item.barcode}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w19]}>
                        {(item.productName || '').slice(0, 20)}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w8]}>
                        {item.size}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w8]}>
                        {item.orderQuantity}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w10]}>
                        {formatTwoDecimals(item.listPrice)}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w10]}>
                        {discount}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w12]}>
                        {formatTwoDecimals(
                            item.listPrice * item.orderQuantity -
                                item.totalPrice,
                        )}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w10]}>
                        {formatTwoDecimals(item.totalPrice)}
                    </Text>
                    <Text style={[styles.product, styles.cell, styles.w8]}>
                        {item.taxRate
                            ? (item.taxRate * 100).toFixed(2) + '%'
                            : '0,00%'}
                    </Text>
                </View>,
            );
        });
        return products;
    };

    const getPayBlock = () => {
        const text = [];
        storeSalePaymentTaxTicketList.forEach((item, idx) => {
            text.push(
                <View style={[styles.row]} key={idx}>
                    <Text style={[styles.text, styles.cell, styles.w25]}>
                        {saleQuantity}
                    </Text>
                    <Text style={[styles.text, styles.cell, styles.w25]}>
                        {(formatTwoDecimals(item.taxRate * 100) || '0,00') +
                            '%'}
                    </Text>
                    <Text style={[styles.text, styles.cell, styles.w25]}>
                        {formatTwoDecimals(
                            item.paymentAmount - item.paymentTax,
                        )}
                    </Text>
                    <Text style={[styles.text, styles.cell, styles.w25]}>
                        {formatTwoDecimals(item.paymentTax) || '0,00'}
                    </Text>
                </View>,
            );
        });
        return text;
    };

    return (
        <Document>
            <Page style={styles.body}>
                <View style={[styles.row, styles.mb15]}>
                    <View style={{ width: '40%' }}>
                        <Image
                            style={styles.logo}
                            src={`${BASE_API_GATEWAY}${fiscalLogo}`}
                        />
                    </View>
                    <View style={{ width: '60%' }}>
                        <View style={[styles.row, styles.border1]}>
                            <View
                                style={[
                                    styles.cell,
                                    styles.text,
                                    styles.border1,
                                ]}
                            >
                                <Text style={[styles.text]}>FATTURA N.</Text>
                                <Text style={[styles.textBold]}>
                                    {invoiceNumber}
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.cell,
                                    styles.text,
                                    styles.border1,
                                ]}
                            >
                                <Text style={[styles.textBold]}>
                                    {getDateByTimestamp(paymentDate, false)}
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.cell,
                                    styles.text,
                                    styles.border1,
                                ]}
                            >
                                <Text style={[styles.text]}>Pagina</Text>
                                <Text
                                    style={[styles.textBold]}
                                    render={({ pageNumber, totalPages }) =>
                                        `${pageNumber} / ${
                                            totalPages || pageNumber
                                        }`
                                    }
                                />
                            </View>
                        </View>
                        <View style={[styles.border, { marginVertical: 3 }]}>
                            <Text style={styles.text}>Spett.le Cliente</Text>
                            {!company && <Text style={styles.textBold}>{name}</Text>}
                            <Text style={styles.textBold}>{company}</Text>
                            <Text style={styles.textBold}>{address1}</Text>
                            {(city || country || zip) && (
                                <Text
                                    style={styles.textBold}
                                >{`${zip} ${city}, ${country}`}</Text>
                            )}
                            <Text style={styles.nestedTexts}>
                                Codice fiscale:{' '}
                                <Text style={styles.textBold}>
                                    {fiscalCode}
                                </Text>
                            </Text>
                            {!!vatNumber && (
                                <Text style={styles.nestedTexts}>
                                    Numero IVA:{' '}
                                    <Text style={styles.textBold}>
                                        {vatNumber}
                                    </Text>
                                </Text>
                            )}
                        </View>
                        <View style={[styles.border]}>
                            {referenceTicket && (
                                <Text style={styles.text}>
                                    Scontrino N.:{' '}
                                    <Text style={styles.textBold}>
                                        {referenceTicket}
                                    </Text>
                                </Text>
                            )}
                            {referencePaymentDate && (
                                <Text style={styles.textBold}>
                                    {getDateByTimestamp(referencePaymentDate)}
                                </Text>
                            )}
                        </View>
                    </View>
                </View>
                <View style={[styles.border, styles.row, styles.bgGraye]}>
                    <Text
                        style={[styles.productTitle, styles.cell, styles.w15]}
                    >
                        Codice
                    </Text>
                    <Text
                        style={[styles.productTitle, styles.cell, styles.w19]}
                    >
                        Descrizione
                    </Text>
                    <Text style={[styles.productTitle, styles.cell, styles.w8]}>
                        Taglia
                    </Text>
                    <Text style={[styles.productTitle, styles.cell, styles.w8]}>
                        Qta
                    </Text>
                    <Text
                        style={[styles.productTitle, styles.cell, styles.w10]}
                    >
                        Prezzo
                    </Text>
                    <Text
                        style={[styles.productTitle, styles.cell, styles.w10]}
                    >
                        %Riga
                    </Text>
                    <Text
                        style={[styles.productTitle, styles.cell, styles.w12]}
                    >
                        Sconta Riga
                    </Text>
                    <Text
                        style={[styles.productTitle, styles.cell, styles.w10]}
                    >
                        Valore
                    </Text>
                    <Text style={[styles.productTitle, styles.cell, styles.w8]}>
                        IVA
                    </Text>
                </View>
                {getProductBlock()}
                <View wrap={false} style={styles.footer}>
                    <View>
                        <View style={[styles.row, styles.note]}>
                            <Text
                                style={[
                                    styles.textBold,
                                    { marginRight: '5px' },
                                ]}
                            >
                                Note:
                            </Text>
                            <View style={styles.cell}>
                                <Text style={[styles.smallText]}>
                                    {sdiCode}
                                </Text>
                                <Text style={[styles.smallText]}>
                                    {pecEmail}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.lineHr}></View>
                        <View style={styles.row}>
                            <View style={{ width: '40%' }}>
                                <Text></Text>
                            </View>
                            <View style={{ width: '60%' }}>
                                <View style={{ textAlign: 'center' }}>
                                    <Text
                                        style={[
                                            styles.textBold,
                                            styles.ivaTitle,
                                        ]}
                                    >
                                        Riepilogo IVA
                                    </Text>
                                </View>
                                <View
                                    style={[
                                        styles.border,
                                        styles.row,
                                        styles.bgGraye,
                                    ]}
                                >
                                    <Text
                                        style={[
                                            styles.textBold,
                                            styles.cell,
                                            styles.w25,
                                        ]}
                                    >
                                        Totale qta
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textBold,
                                            styles.cell,
                                            styles.w25,
                                        ]}
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.Sale.taux',
                                        )}
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textBold,
                                            styles.cell,
                                            styles.w25,
                                        ]}
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.Sale.saleAmountNet',
                                        )}
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textBold,
                                            styles.cell,
                                            styles.w25,
                                        ]}
                                    >
                                        {i18next.t(
                                            'Terminal.Receipt.Sale.paymentTax',
                                        )}
                                    </Text>
                                </View>
                                {getPayBlock()}
                                <View
                                    style={[
                                        styles.border,
                                        styles.row,
                                        styles.textBold,
                                    ]}
                                >
                                    <Text
                                        style={[styles.textBold, styles.cell]}
                                    >
                                        Totale fattura
                                    </Text>
                                    <Text
                                        style={[styles.textBold, styles.cell]}
                                    >
                                        {currency}
                                    </Text>
                                    <Text
                                        style={[styles.textBold, styles.cell]}
                                    >
                                        {formatTwoDecimals(paymentAmount)}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ marginTop: 5, marginBottom: 20 }}>
                            <Text style={styles.paragraph}>
                                {storeReceiptDisclaimer}
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 15,
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    footer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    title: {
        fontSize: 16,
        textAlign: 'center',
        fontFamily: 'Oswald',
    },
    text: {
        fontSize: 13,
        padding: 2,
        fontFamily: 'Times-Roman',
    },
    nestedTexts: {
        fontSize: 13,
        paddingHorizontal: 2,
        fontFamily: 'Times-Roman',
    },
    paragraph: {
        marginVemartical: 12,
        paddingHorizontal: 15,
        paddingTop: 70,
        paddingBottom: 5,
        fontSize: 11,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
    },
    smallText: {
        fontSize: 12,
        fontFamily: 'Times-Roman',
        lineHeight: 1,
    },
    productTitle: {
        fontSize: 12,
        padding: 2,
        fontFamily: 'Times-Roman',
    },
    product: {
        fontSize: 11,
        padding: 2,
        fontFamily: 'Times-Roman',
    },
    textBold: {
        fontSize: 13,
        fontWeight: 'bold',
        fontFamily: 'Oswald',
        paddingLeft: 3,
    },
    note: {
        alignItems: 'center',
    },
    ivaTitle: {
        marginBottom: '10px',
        textDecoration: 'underline',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'stretch',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35,
    },
    cell: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        alignSelf: 'stretch',
    },
    border: {
        borderColor: '#7b7b7b',
        borderStyle: 'solid',
        borderWidth: 2,
    },
    border1: {
        borderColor: '#7b7b7b',
        borderStyle: 'solid',
        borderWidth: 1,
    },
    bgGraye: {
        backgroundColor: '#ccc',
    },
    lineHr: {
        width: '100%',
        height: '2px',
        backgroundColor: '#7b7b7b',
        marginVertical: '15px',
    },
    w8: {
        width: '8%',
    },
    w10: {
        width: '10%',
    },
    w12: {
        width: '12%',
    },
    w13: {
        width: '13%',
    },
    w15: {
        width: '15%',
    },
    w19: {
        width: '19%',
    },
    w20: {
        width: '20%',
    },
    w25: {
        width: '25%',
        textAlign: 'center',
    },
    mb15: {
        marginBottom: '15px',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: '100%',
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});
