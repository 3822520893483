import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import i18next from "i18next";
import {getSingleStoreGift, getSingleGiftLoading} from "../../redux/selectors";
import {StoreGiftsActionCreators} from "../../redux/actions";
import { addGiftToBasket, getActiveBasketClientId, isValidDate, isExistGiftInBasket, isExistCoupons } from '../../../../helpers/helpers';
import history from '../../../../helpers/history'
import InfoPopUp from "../../../common/infoPopUp";

interface Props {
    singleGift: any,
    handleClose:() => void,
    storeGiftCode: string,
    singleError: string,
    storeGiftPopup?: boolean,
}

interface State {
    open: boolean,
    message: string
}

class AddGift extends Component<Props & typeof StoreGiftsActionCreators, State> {
    state: State = {
        open: false,
        message: ""
    };

    componentDidMount() {
        this.props.singleStoreGiftsStart({storeGiftCode:this.props.storeGiftCode})
    }
    componentDidUpdate(prevProps: Readonly<Props & typeof StoreGiftsActionCreators>, prevState: Readonly<State>, snapshot?: any): void {
        const {singleGift} = this.props;
        const basketClientId = getActiveBasketClientId();
        if(singleGift.storeGiftCode && prevProps.singleGift.storeGiftCode !== singleGift.storeGiftCode){
            if(isValidDate(singleGift.endDate) && new Date().getTime() > singleGift.endDate){
                this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp1'), open: true})
            } else if(isValidDate(singleGift.startDate) && new Date().getTime() < singleGift.startDate){
                this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp2'), open: true})
            } else if(basketClientId && basketClientId !== singleGift.storeClientId){
                this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp5'), open: true})
            } else if(singleGift.percent && isExistCoupons()) {
                this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp3'), open: true})
            }  else if(singleGift.redemptionDate) {
                // this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp4'), open: true})
                history.push("/")
            } else if (!isExistGiftInBasket(singleGift.storeGiftCode)) {
                addGiftToBasket(singleGift)
                this.props.basketStoreGiftsStart()
                this.props.getBasketStart()
                if(this.props.storeGiftPopup){
                    // this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp4'), open: true})
                    history.push("/")
                }else {
                    this.props.handleClose()
                }
            } else {
                this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp3'), open: true})
            }
        }

        if(prevProps.isGiftLoading && !this.props.isGiftLoading && !singleGift.storeGiftCode){
            this.setState({message: i18next.t('Terminal.Receipt.Gift.PopUp3'), open: true})
        }
    }

    render() {
        const {message, open} = this.state;
        return (
            <InfoPopUp
                open={open}
                message={message}
                handleClose={this.props.handleClose}
            />
        )
    }
}

const mapStateToProps = (state: any) => ({
    singleGift: getSingleStoreGift(state),
    isGiftLoading: getSingleGiftLoading(state)
})
const mapDispatchToProps = (dispatch): typeof StoreGiftsActionCreators => {
    return bindActionCreators(StoreGiftsActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGift);
