export const getStock = state => state.stockContent.stockData;
export const getStockBarcodeList = state => state.stockContent.barcodesList;
export const getStockNegativeBarcodeList = state => state.stockContent.negativeBarcodesList;
export const getSingleStock = state => state.stockContent.singleStockData;
export const getBarcodeProduct = state => state.stockContent.barcodeProduct;
export const getLoading = state => state.stockContent.isLoading;
export const getSingleLoading = state => state.stockContent.isSingleLoading;
export const getLoadingProduct = state => state.stockContent.isLoadingProduct;
export const getcreateStockStatus = state => state.stockContent.createStockStatus;
export const getProductSold = state => state.stockContent.productSold;
export const getIsStockDashboard = state => state.stockContent.isStockDashboard;
export const getStockCounters = state => state.stockContent.stockCounters;
export const getNewStocProduct = state => state.stockContent.newStockProduct;
export const getProductDeliveries = state => state.stockContent.productDeliveries;
export const getStockProducts = state => state.stockContent.stockProducts;
export const getImportLoading = state => state.stockContent.isImportLoading;
export const getImportLoadInfo = state => state.stockContent.loadInfo;
export const getImportProductDetails = state => state.stockContent.importProductDetails;
export const getStockUpdateloading = state => state.stockContent.isStockUpdateloading;
export const getIsUplaodLoading = state => state.stockContent.isUploadLoading;
export const getUploadResult = state => state.stockContent.uploadResult
export const getAssociateServiceLoading = state => state.stockContent.isAssociateServiceLoading;
export const getAssociateServices = state => state.stockContent.associateServices;
export const getAdminPageLoading = state => state.stockContent.adminPageLoading;
export const getStoreAssociatesSelector = state => state.stockContent.storeAssociates;
export const getStoreAssociatesLoadingSelector = state => state.stockContent.getStoreAssociatesLoading;
export const getAddServiceStatus = state => state.stockContent.addServiceStatus;
