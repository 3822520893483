import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	ScheduleComponent,
	Day,
	Week,
	WorkWeek,
	Month,
	Agenda,
	Inject,
	Resize,
	DragAndDrop,
} from '@syncfusion/ej2-react-schedule';

import { L10n, loadCldr } from '@syncfusion/ej2-base';
import { getCalendarLocal } from '../calendarLocals';
import {
	getAccountResource,
	isAuthenticated,
} from '../../login/redux/selectors';
import {
	decryptCryptr,
	getScheduleSlicedDay,
	getUserFromToken,
} from '../../../helpers/helpers';
import { CalendarActionCreators } from '../redux/actions';
import { getCalendar, getCalendarLoading } from '../redux/selectors';

import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import locale from '../../../utils/translations/calendar.json';
import Loading from '../../common/loading/loading';
import BtnRounded from '../../common/btnRounded/btnRounded';

import './calendar.scss';
import {
	LOCAL_STORAGE,
	ONE_DAY_MILISEC,
} from '../../../utils/constants/variables';

interface IProps {
	account?: any;
	CalendarStart: any;
	CalendarUpdate: any;
	calendar: any;
	CalendarLoadingAction: any;
	calendarLoading: boolean;
	authenticated: boolean;
}

type ScheduleType = {
	Id: number;
	Subject: string;
	StartTime: Date;
	EndTime: Date;
	IsAllDay: boolean;
};

type ActiveCalendar = Record<string, string | number>;

const Calendar: React.FC<IProps> = ({
	account,
	authenticated,
	CalendarStart,
	CalendarUpdate,
	calendar,
	calendarLoading,
}) => {
	const startHour = '08:00';
	const endHour = '24:00';
	const user = getUserFromToken();
	const activeAssociateInitial = { key: user.code };
	const ScheduleComponentRef = useRef<any>(null);
	const ScheduleComponentRefSecond = useRef<any>(null);
	const [localLanguage, setlocalLanguage] = useState<string>('');
	const [activeCalendar, setActiveCalendar] = useState<ActiveCalendar>({});
	const [buttons, setButtons] = useState<ActiveCalendar[]>([]);
	const [currentCalendar, setCurrentCalendar] = useState<any>([]);

	const populatecalendarButtons = (): ActiveCalendar[] => {
		// const buttons: Record<string, ActiveCalendar> = {};
		const { storeAssociates = [] } = account || {};
		const terminalAdmin = decryptCryptr(LOCAL_STORAGE.isAssociateAdmin) === 'true';
		const buttons = storeAssociates.reduce((acc, associate) => {
			const key = associate.associateCode;

			if ((!terminalAdmin && key !== user.code)) return acc;

			const associateName = associate.associateFirstName;

			acc[associate.priority] = {
				priority: associate.priority,
				key,
				associateName
			}

			return acc;
		}, {});

		return Object.values(buttons);
	};

	// set new buttons when associate login or change
	useEffect(() => {
		const buttonsList = populatecalendarButtons();
		setButtons(buttonsList);
		changeActive(buttonsList[0]);
	}, [authenticated, calendar]);

	useEffect(() => {
		setCurrentCalendar([]);
		queueMicrotask(() => {
			setCurrentCalendar([
				...(calendar[activeCalendar && activeCalendar.key] || []),
			]);
		});
	}, [activeCalendar]);

	// start calendar fetch on componenet load
	useEffect(() => {
		if (Object.keys(user).length) CalendarStart();
	}, []);

	// set language for calendar
	useEffect(() => {
		const { storeDetails: { language } = { language: '' } } = account;
		if (language) {
			const [lowerLang] = language.split('_');
			const { timeZoneNames, gregorian, numbers } = getCalendarLocal(lowerLang);
			setlocalLanguage(lowerLang);
			loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
			L10n.load(locale);
		}
	}, [account]);

	const onActionComplete = (args) => {
		const isUser = !!Object.keys(user).length;

		if (!isUser) return false

		const calendarItem = args[0];

		try {
			if (calendarItem.deletedRecords.length) {
				return CalendarUpdate({
					data: calendarItem.deletedRecords,
					isDelete: true,
					user: { code: activeCalendar.key },
				});
			}
			if (calendarItem.addedRecords.length) {
				return CalendarUpdate({
					data: calendarItem.addedRecords,
					isDelete: false,
					user: { code: activeCalendar.key },
				});
			}
			if (
				calendarItem.changedRecords.length &&
				calendarItem.requestType === 'eventRemoved'
			) {
				const day = new Date(
					ScheduleComponentRef.current.activeEventData.event.EndTime,
				).getDay();
				const currnetShedul = getScheduleSlicedDay(
					day,
					calendarItem.changedRecords[0],
					calendarItem.data[0],
				);
				return CalendarUpdate({
					...(calendarItem.data[0].brandServiceCalendarId && {
						brandServiceCalendarId:
							calendarItem.data[0].brandServiceCalendarId,
					}),
					data: currnetShedul,
					isDelete: false,
					user: { code: activeCalendar.key },
				});
			}
			if (
				calendarItem.changedRecords.length &&
				calendarItem.requestType === 'eventChanged'
			) {
				return CalendarUpdate({
					data: calendarItem.changedRecords,
					isDelete: false,
					user,
				});
			}
		} catch {
			return false;
		}
	};

	const changeActive = (item: ActiveCalendar = activeAssociateInitial) => {
		setActiveCalendar({ ...item });
	};

	const getCalendarData = () => {
		return calendar[activeCalendar.key];
	};

	const todaysSelections = useMemo(() => {
		const data = getCalendarData();
		return Object.values(
			(data || []).reduce((acc: any, calendarItem) => {
				const date = new Date();
				const calendarStartDate = new Date(calendarItem.startTime);

				date.setHours(0, 0, 0, 0);

				const startTime = date.getTime();
				const endTime = startTime + ONE_DAY_MILISEC;

				if (
					startTime < calendarItem.startTime &&
					calendarItem.startTime < endTime
				) {
					acc[calendarStartDate.getHours()] = calendarItem;
				}
				return acc;
			}, {}),
		);
	}, [calendar, activeCalendar]);

	const createDateFormat = (date) => {
		return (
			`${date.getHours()}`.padStart(2, '0') +
			':' +
			`${date.getMinutes()}`.padStart(2, '0')
		);
	};

	const getDateFormat = (item) => {
		const startDate = new Date(item.startTime);
		const endDate = new Date(item.endTime);
		const weekDayName = new Intl.DateTimeFormat('it-IT', {
			weekday: 'long',
		}).format(startDate);
		const startHours = createDateFormat(startDate);
		const endHours = createDateFormat(endDate);

		return `${weekDayName} at ${startHours} - ${endHours}`;
	};

	return (
		<div>
			{!localLanguage ? (
				<Loading />
			) : (
				<div className="calendar_container">
					{calendarLoading && <Loading />}
					<div
						className={`${!Object.keys(user).length ? 'calendarDisabled' : ''
							} ${calendarLoading ? 'opacity-none' : ''
							} calendar-flex`}
					>
						<ScheduleComponent
							ref={ScheduleComponentRef}
							readOnly={true}
							locale={localLanguage}
							actionComplete={(...args) =>
								onActionComplete(args)
							}
							selectedDate={new Date()}
							eventSettings={{
								dataSource: currentCalendar,
							}}
							timeScale={{
								enable: true,
								interval: 60,
								slotCount: 2,
							}}
							startHour={startHour}
							endHour={endHour}
						>
							<Inject
								services={[
									Day,
									Week,
									WorkWeek,
									Month,
									Agenda,
									Resize,
									DragAndDrop,
								]}
							/>
						</ScheduleComponent>
						<div className="calendar-info-container">
							<div className="centeredBox-calendar">
								<div className="wrapperButtons">
									{buttons.length > 1
										? buttons.map((item, index) => (
											<BtnRounded
												classN={`${activeCalendar.priority ===
														item.priority
														? 'active'
														: ''
													}`}
												key={index}
												onClick={() =>
													changeActive(item)
												}
											>
												{index + 1}
											</BtnRounded>
										))
										: null}
								</div>
								<span className="associate-name text-bold">
									{activeCalendar.associateName}
								</span>
							</div>

							{todaysSelections.map((item: any, index) => (
								<div className="calendar-info" key={index}>
									<span>
										<span className="text-bolder">
											{item.Subject}
										</span>
										{item.Location && `, ${item.Location}`}
									</span>
									<span>{getDateFormat(item)}</span>
									<span>{item.Description}</span>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: any): any => {
	return {
		account: getAccountResource(state),
		calendar: getCalendar(state),
		authenticated: isAuthenticated(state),
		calendarLoading: getCalendarLoading(state),
	};
};

const mapDispatchToProps = (dispatch): any => {
	return bindActionCreators(CalendarActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
