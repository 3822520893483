import React, {useEffect, useState} from 'react'
import { Input } from 'semantic-ui-react'
import { replaceComma, replaceCommaToDecimal, roundUpTreeDecimal } from '../../../helpers/helpers';

interface Props {
    onChange: (p) => void,
    value: string | number,
    name: string,
    maxValue?: number,
    disabled?: boolean
}

export default function ({onChange, value = '', name, maxValue, disabled = false}: Props) {
    const [val, setValue] = useState(value)

    useEffect(() => {
        if(value != val) {
            setValue(replaceComma(roundUpTreeDecimal(value)))
        }
    }, [value])

    const handleChange = (e) => {
        const {name, value} = e.target;
        let val = value.replace('.', ',')
        const index = val.indexOf(',')

        if(val && isNaN(+replaceCommaToDecimal(val))){
            return;
        }
        if(index > -1 && val.length - index > 3) {
            return;
        }

        if(val.length > 1 && val[0] === "0" && val[1] !== ",") {
            val = val.substring(1)
        }
        if(maxValue && val > maxValue){
            return;
        }

        setValue(val)

        onChange({name, value: val})
    }
    const onBlur = ({target}) => {
        const {name, value} = target;
        let val = "0";
        if(parseFloat(value.replace(',', '.'))){
            val = replaceComma(parseFloat(value.replace(',', '.')))
        }
        onChange({name, value: val})
        setValue(val)
    }
    const onFocus = ({target}) => {
        const {value} = target;
        if(value === '0'){
            setValue('');
        }
    }
    return (
        <Input 
            disabled={disabled}
            value={val} 
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    )
}