import {createReducer} from 'reduxsauce';
import {QuoteTypes} from './actions';
import {paperReceiptQuote} from "./sagas";
import { setDays } from '../../../helpers/helpers';
import { QUOTE_EXPIRATION } from '../../../utils/constants/variables';


export interface IQuoteState {
    quoteData: any,
    quoteStatus: string,
    quoteDashboard: any,
    singleQuote: any,
    isLoading: boolean,
    paperReceipt: any,
    isLoadingPaperReceipt: boolean,
    isPrinting: boolean,
    printerResponse: {},
    isDeleted: null | true | false,
}

export const quoteR: IQuoteState = {
    quoteData: {},
    quoteStatus: "",
    quoteDashboard:[],
    singleQuote:{},
    isLoading:false,
    paperReceipt: {},
    isLoadingPaperReceipt: false,
    isPrinting: false,
    printerResponse: {},
    isDeleted: null
};

export const createQuote = (state) => {
    return {...state, status: "", loading: true};
};

export const createQuoteSuccess = (state, action) => {
    return {...state,quoteData: action.data,quoteStatus:"success"};
};

export const createQuoteFailure = (state, action) => {
    return {...state,quoteData: {},quoteStatus:"failed"};
};

export const getQuoteData = (state) => {
    return {...state, isLoading: true};
};

export const quoteSuccess = (state, action) => {
    const quoteDashboard = action.data.map(item => ({
        ...item,
        name: `${item.firstName || ""} ${item.lastName || ""}`,
        active: Date.now() <= setDays(QUOTE_EXPIRATION, item.quoteDate) && !item.storeSaleTicketNumber
    }))
    return {...state, quoteDashboard, isLoading: false};
};

export const quoteFailure = (state, action) => {
    return {...state, error: action.error, quoteDashboard: [], isLoading: false};
};

export const getSinglequoteData = (state) => {
    return {...state};
};

export const singleQuoteSuccess = (state, action) => {
    return {...state, singleQuote: action.data};
};

export const singleQuoteFailure = (state, action) => {
    return {...state, error: action.error, singleQuote: {}};
};

export const paperReceiptQuoteStart = (state) => {
    return {...state, isLoadingPaperReceipt: true};
};

export const paperReceiptQuoteSuccess = (state, action) => {
    return {...state, paperReceipt: action.data, isLoadingPaperReceipt: false};
};

export const paperReceiptQuoteFailure = (state, action) => {
    return {...state, error: action.error, paperReceipt: {}, isLoadingPaperReceipt: false};
};

export const quotePrintTicketStart = (state) => {
    return {...state, isPrinting: true};
};

export const quotePrintTicketSuccess = (state, action) => {
    return {...state, printerResponse: action.data, isPrinting: false};
};

export const quotePrintTicketFailure = (state, action) => {
    return {...state, error: action.error, printerResponse: {}, isPrinting: false};
};

export const quoteDeleteTicketStart = (state) => {
    return {...state, isDeleted: null};
};

export const quoteDeleteTicketSuccess = (state, action) => {
    return {...state, quoteDashboard: action.data, isDeleted: true};
};

export const quoteDeleteTicketFailure = (state, action) => {
    return {...state, error: action.error, isDeleted: false};
};


export const handlers = {
    [QuoteTypes.CREATE_QUOTE_START]: createQuote,
    [QuoteTypes.CREATE_QUOTE_SUCCESS]: createQuoteSuccess,
    [QuoteTypes.CREATE_QUOTE_FAILURE]: createQuoteFailure,
    [QuoteTypes.QUOTE_START]: getQuoteData,
    [QuoteTypes.QUOTE_SUCCESS]: quoteSuccess,
    [QuoteTypes.QUOTE_FAILURE]: quoteFailure,
    [QuoteTypes.SINGLE_QUOTE_START]: getSinglequoteData,
    [QuoteTypes.SINGLE_QUOTE_SUCCESS]: singleQuoteSuccess,
    [QuoteTypes.SINGLE_QUOTE_FAILURE]: singleQuoteFailure,
    [QuoteTypes.PAPER_RECEIPT_QUOTE_START]: paperReceiptQuoteStart,
    [QuoteTypes.PAPER_RECEIPT_QUOTE_SUCCESS]: paperReceiptQuoteSuccess,
    [QuoteTypes.PAPER_RECEIPT_QUOTE_FAILURE]: paperReceiptQuoteFailure,
    [QuoteTypes.QUOTE_PRINT_TICKET_START]: quotePrintTicketStart,
    [QuoteTypes.QUOTE_PRINT_TICKET_SUCCESS]: quotePrintTicketSuccess,
    [QuoteTypes.QUOTE_PRINT_TICKET_FAILURE]: quotePrintTicketFailure,
    [QuoteTypes.QUOTE_DELETE_TICKET_START]: quoteDeleteTicketStart,
    [QuoteTypes.QUOTE_DELETE_TICKET_SUCCESS]: quoteDeleteTicketSuccess,
    [QuoteTypes.QUOTE_DELETE_TICKET_FAILURE]: quoteDeleteTicketFailure,
};

export default createReducer(quoteR, handlers);
