import React from "react";
import i18next from "i18next";
import ComplectItem from "./complectItem";
import {getCurrency, parseData, replaceComma, getActiveBasketName} from "../../../../helpers/helpers";
import Colorbar from "./colorbar";
import Photo from "../../../common/product/photo";
import { LOCAL_STORAGE, PRODUCT_MAX_QUANTITY} from "../../../../utils/constants/variables";
import StoreLocator from "../../../location/container/storeLocator";
import AddToCart from "../../../addToCart/container/addToCart";
import {connect} from "react-redux";
import {isAddedToCart} from "../../../addToCart/redux/selectors";
import {bindActionCreators} from "redux";
import {AddToCartActionCreators} from "../../../addToCart/redux/actions";
import 'swiper/css/swiper.css';
import Price from "../../../common/product/price";
import Swiper from "react-id-swiper";

interface Props  {
    handleBasketToggle?: any,
    handleDeliverytToggle?: any,
    handleGetWebStock?: any,
    data?: any,
    handleScrollToDescription?: any,
    addtocartStart?:any,
    addedToCart?:any,
    webInventory?: number,
}

interface State {
    reset: boolean,
    quantity: number,
    selectedProductsQuantity: number,
    total: number,
    selectedProducts: any,
    isShownDetails: boolean,
    showColors: boolean,
    productList: any,
    showStoreLocator: boolean,
    showSizeGuide: boolean,
    width: any,
}

 class Complect extends React.Component<Props, State,typeof AddToCartActionCreators > {
    state: State = {
        reset: false,
        quantity: 0,
        selectedProductsQuantity: 0,
        total: 0,
        isShownDetails: false,
        showColors: true,
        selectedProducts: {},
        productList: this.props.data.compositeData ? this.props.data.compositeData.products : this.props.data.products,
        showStoreLocator: false,
        showSizeGuide: false,
        width: window.innerWidth,
    };
     componentDidMount(){window.addEventListener("resize", this.handleResize);}

     componentWillUnmount() {window.removeEventListener("resize", this.handleResize);}

     handleResize =() => {this.setState({width: window.innerWidth})};

    handleChangeSelected = (productId, quantity, sizeIndex) => {
            let selectedProducts = this.state.selectedProducts;
            productId = productId.toString();
            if (Object.keys(selectedProducts).includes(productId)  ) {
                delete selectedProducts[productId];

            }
            else {
                const product = Array.isArray(this.state.productList) ? this.state.productList.find(item => item.productId === parseInt(productId)) : {};
                const price = sizeIndex === "" ? this.getPrice(product) : this.getPrice(product.sizes[sizeIndex]);
                const size = sizeIndex === "" ? {} : product.sizes[sizeIndex];
                selectedProducts[productId] = {
                    quantity: quantity.toString(),
                    size,
                    price,
                    photo: product.photo,
                    name: product.name,
                    canonicalUrl: product.canonicalUrl,
                    pack: product.pack || [],
                    maxQuantity: PRODUCT_MAX_QUANTITY,
                    oldPrice: product.discountDetail ? product.startingPrice : ""
                };
            }
            this.setState({selectedProducts, reset: false});
            this.calculateTotal();
    };

    handleChangeQuantity = (productId, quantity, sizeIndex) => {
        const {selectedProducts} = this.state;
        if (quantity > 0 && sizeIndex !== "" && !selectedProducts[productId]) {
            this.handleChangeSelected(productId, quantity, sizeIndex)

            this.calculateTotal();
        }

        if (selectedProducts[productId]) {
            if (quantity == 0) {
                this.handleChangeSelected(productId, quantity, sizeIndex)
                this.calculateTotal();

            } else {
                selectedProducts[productId].quantity = quantity;
                this.setState({selectedProducts});
                this.calculateTotal();
            }
        }

    };

    handleChangeSize = (productId, sizeIndex, quantity) => {
        const {selectedProducts} = this.state;
        const product = Array.isArray(this.state.productList) ? this.state.productList.find(item => item.productId === parseInt(productId)) : {};
        if (sizeIndex !== "") {
            if (quantity > 0 && !selectedProducts[productId]) {
                this.handleChangeSelected(productId, quantity, sizeIndex)

            }
            if (selectedProducts[productId]) {
                const price = sizeIndex === "" ? this.getPrice(product) : this.getPrice(product.sizes[sizeIndex]);
                selectedProducts[productId].size = sizeIndex === "" ? {} : product.sizes[sizeIndex];
                selectedProducts[productId].price = price;

            }
            this.setState({selectedProducts});
            this.calculateTotal();

        } else if (selectedProducts[productId]) {
            this.handleChangeSelected(productId, quantity, sizeIndex)
            this.setState({selectedProducts});
        }
    };

    getPrice = (data) => {
        return data.discountDetail ? data.listPriceAfterDiscount : data.startingPrice;
    };

    calculateTotal() {
        const {selectedProducts} = this.state;
        let total = 0;
        let quantity = 0;
        for (const index of Object.keys(selectedProducts)) {
            quantity += parseInt(selectedProducts[index].quantity);
            total += (selectedProducts[index].quantity * parseFloat(selectedProducts[index].price));
        }
        this.setState({quantity, total})
    };

    toggleDetails = () => {
        this.setState({isShownDetails: !this.state.isShownDetails})
    };

    toggleColors = () => {
        this.setState({showColors: !this.state.showColors})
    };

    handleAddedToCart = (basket) => {
        this.setState({selectedProducts: {}, total: 0, quantity: 0, reset: true});
        if(basket===getActiveBasketName()){
            this.props.handleBasketToggle()
        }else if(basket === LOCAL_STORAGE.deliveryBasket){
            this.props.handleDeliverytToggle()
        }
    };

    toggleStoreLocator = () => {
        this.setState({showStoreLocator: !this.state.showStoreLocator});
    };

    render() {
        const {quantity, total, selectedProducts, isShownDetails, showColors, showStoreLocator,width} = this.state;
        let {data, data: {productType}} = this.props;
        const description: any = data.description ? parseData(data.description) : "";
        const params = {
            slidesPerView:1,
            // init: false,
            speed: 600,
            autoplay: {delay: 5000},
            loop: data && data.length ,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            wrapperClass: "swiper-wrapper",
            containerClass: "swiper-container",
            setWrapperSize: true,
            resizeReInit: true,
        };
        if (data.compositeData) {
            data = data.compositeData;
        }
        let isMobile = width < 920;
        if (data.compositeData) {
            data = data.compositeData;
        }
        return (
            <>


                {data && Object.entries(data).length ?
                    <div className={`pure-complect ${productType === "C" ? "type-c" : ""}`}>
                        <div className='container'>
                            <h4 className="pure-complect-title">{i18next.t('product.parure.1')}</h4>
                            <div className="pure-complect-content">
                                <div className="pure-complect-image-content">
                                    <div className="pure-complect-image-box">
                                        <div className="aws-sld photo-zoom">
                                            <div className={`${data.photosX60 && data.photosX60.length > 1 ? "with-slider":"zoomContent"}`}>
                                                {
                                                    data.photosX60 &&  data.photosX60.length > 1 ?
                                                        <div className="aws-sld photo-zoom">
                                                            <Swiper {...params} >
                                                                {
                                                                    data.photosX60.map((item,i)=>
                                                                        <div  key={i}>
                                                                            <Photo src={item}
                                                                                    outlet={data && data.outlet}
                                                                                    discountDetail={data && data.discountDetail}
                                                                                    onSpecial={data.onSpecial}
                                                                                    thumbnail={data.thumbnail}
                                                                                    alt={data.metaTitle}
                                                                                    zoom={true}
                                                                                    isMobile={isMobile}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                            </Swiper>
                                                        </div> :
                                                        <Photo src={data.photo}
                                                                outlet={data && data.outlet}
                                                                discountDetail={data && data.discountDetail}
                                                                onSpecial={data.onSpecial}
                                                                thumbnail={data.thumbnail}
                                                                alt={data.metaTitle}
                                                                zoom={true}
                                                                isMobile={isMobile}
                                                        />
                                                }

                                                {data && data.compositeCanonicalUrlForSingle ?
                                                    <div className="discount-box">
                                                        <span onClick={() => this.props.handleScrollToDescription()}>{i18next.t('product.parure.18')}</span>
                                                    </div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="discount-box">
                                            <h4 onClick={() => this.props.handleScrollToDescription("h4")}>{i18next.t('product.parure.2')}</h4>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="price-count">
                                            <p><span>{quantity}</span> {quantity < 2 ? i18next.t('product.parure.16') : i18next.t('product.parure.4')}</p><span>{replaceComma(total)} {getCurrency(data.currency)}</span>
                                        </div>

                                        <div className="pure-complect-content-button"></div>
                                            <ul className="pure-complect-product-checked-list">
                                                { // Todo

                                                    Object.keys(selectedProducts).map((productId) => (
                                                        <li className="dflex justify-content-between align-items-start"
                                                            key={productId}>
                                                            <span>{selectedProducts[productId].name} - {selectedProducts[productId].size.size} </span>
                                                            <span className="price">{selectedProducts[productId].quantity} x <Price startP={selectedProducts[productId].size.startingPrice} discount={selectedProducts[productId].size.discountDetail} newP={selectedProducts[productId].size.listPriceAfterDiscount}/></span>
                                                        </li>
                                                    ))
                                                }
                                            </ul>




                                        <AddToCart
                                            buttonText={i18next.t('product.parure.6')}
                                            data={selectedProducts}
                                            isComplect={true}
                                            handleAddedToCard={this.handleAddedToCart}
                                            isEnable={quantity}
                                        />
                                        <div className="show-store-locator"
                                                onClick={this.toggleStoreLocator}>{i18next.t('product.product.6')}</div>




                                    </div>

                                </div>

                                <div className="pure-complect-item-about-content">
                                    <div className="pure-complect-item-about">
                                        {productType === "C" &&
                                            <div className="product-info-content product-info--leftBox--content">
                                                <h1 className="pure-complect-item-about-title">{data.name}</h1>
                                                <h5 className="pure-complect-item-about-subtitle">{data.collection}</h5>
                                                {!isShownDetails &&
                                                <>
                                                    <h2 className="pure-complect-item-about-text" >{description.length > 200 ? `${description.substring(0, 200)}...` : description}
                                                    <span onClick={() => this.props.handleScrollToDescription("span")}>{i18next.t('product.parure.11')}</span>
                                                    </h2>
                                                    {data.colors && data.colors.length > 1 ?
                                                        <div className="product-color">
                                                            <h4 className="product-color-title"
                                                                onClick={this.toggleColors}>{i18next.t('product.product.2')}</h4>
                                                            {showColors &&
                                                            <Colorbar data={data.colors}/>
                                                            }
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    </>
                                                }

                                            </div>
                                        }

                                    </div>

                                    <div className="complect-item-content">
                                        {
                                            isShownDetails &&

                                            <AddToCart
                                                buttonText={i18next.t('product.parure.6')}
                                                data={selectedProducts}
                                                isComplect={true}
                                                handleAddedToCard={this.handleAddedToCart}
                                                isEnable={quantity}
                                            />
                                        }
                                        {data.products && data.products.slice(0, 8).map(item => (
                                            <ComplectItem
                                                handleChangeSelected={this.handleChangeSelected}
                                                handleChangeQuantity={this.handleChangeQuantity}
                                                handleChangeSize={this.handleChangeSize}
                                                data={item}
                                                key={item.productId}
                                                reset={this.state.reset}
                                                selectedProducts={this.state.selectedProducts}
                                                handleAddedToCart={this.handleAddedToCart}
                                                alt={data.metaTitle}
                                            />
                                        ))
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : ""
                }
                {showStoreLocator &&
                <StoreLocator toggleStoreLocator={this.toggleStoreLocator}/>
                }
            </>
        );
    }
}
const mapStateToProps = (state: any) : Props => ({
    addedToCart: isAddedToCart(state),
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators(AddToCartActionCreators, dispatch)
};

export default connect(mapStateToProps,mapDispatchToProps)(Complect);
