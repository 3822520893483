import {createReducer} from 'reduxsauce';
import {HomeTypes} from './actions';

export interface IHomeState {
    data: any,
    barcodeProduct: any,
    selectedSize: any,
    isBarcodeProductVerify: boolean,
    isLoadingProduct: boolean,
    getBarcodeProductStatus: string,
    OfflineBarcodeFailure: boolean,
    webInventory: number,
    coupon: any,
    couponLoading: boolean,
    couponStatus: string,
    isBarcodePanelVisible: boolean,
}

export const homeContent: IHomeState = {
    data: [],
    barcodeProduct: {},
    isBarcodeProductVerify: false,
    isLoadingProduct: false,
    getBarcodeProductStatus:"",
    OfflineBarcodeFailure: false,
    webInventory:0,
    coupon:{},
    selectedSize: {},
    couponLoading : false,
    couponStatus:"",
    isBarcodePanelVisible: false,
};

export const getHomeData = (state) => {
    return {...state};
};

export const homeSuccess = (state, action) => {
    return {...state, data: action.data};
};

export const homeFailure = (state, action) => {
    return {...state, error: action.error, data: []};
};

export const getBarcodeProduct = (state) => {
    return {...state, barcodeProduct: {}, isBarcodeProductVerify: false, isLoadingProduct: true,getBarcodeProductStatus:"",OfflineBarcodeFailure: false};
};

export const  getBarcodeProductSuccess = (state, action) => {
    return {...state, barcodeProduct: action.data, isLoadingProduct: false, getBarcodeProductStatus:"success"};
};

export const  getBarcodeProductFailure = (state, action) => {
    return {...state, error: action.error, barcodeProduct: {productId: null}, isLoadingProduct: false,getBarcodeProductStatus:"failed"};
};

export const barcodeProductVerify = (state, action) => {
    return {...state, isBarcodeProductVerify: action.data};
};
export const OfflineBarcodeFailure =(state)=> {
    return {...state, OfflineBarcodeFailure: true};
}

export const webInventorySuccess = (state,action) => {
    return {...state,webInventory: action.webInventory, selectedSize: action.data}
}

export const getCouponWebStart = (state, action) => {
    return {...state, couponLoading:true , couponStatus:"", coupon:{} };
};
export const getCouponWebSuccess =(state,action)=> {
    return {...state, coupon: action.data,couponStatus:"success",couponLoading:false};
}

export const getCouponWebFailure = (state,action) => {
    return {...state,error: action.error, couponLoading:false , couponStatus:"failed", coupon:{}}
}

export const openBarcodePanel = (state, action) => {
    return {...state, barcodeProduct: action.data, isBarcodePanelVisible: true};
}

export const closeBarcodePanel = (state) => {
    return {...state, isBarcodePanelVisible: false};
}

export const handlers = {
    [HomeTypes.HOME_START]: getHomeData,
    [HomeTypes.HOME_SUCCESS]: homeSuccess,
    [HomeTypes.HOME_FAILURE]: homeFailure,
    [HomeTypes.GET_BARCODE_PRODUCT_START]: getBarcodeProduct,
    [HomeTypes.GET_BARCODE_PRODUCT_SUCCESS]: getBarcodeProductSuccess,
    [HomeTypes.GET_BARCODE_PRODUCT_FAILURE]: getBarcodeProductFailure,
    [HomeTypes.BARCODE_PRODUCT_VERIFY_START]: barcodeProductVerify,
    [HomeTypes.OFFLINE_BARCODE_FAILURE]: OfflineBarcodeFailure,
    [HomeTypes.WEB_INVENTORY_SUCCESS]: webInventorySuccess,
    [HomeTypes.GET_COUPON_WEB_START]: getCouponWebStart,
    [HomeTypes.GET_COUPON_WEB_SUCCESS]: getCouponWebSuccess,
    [HomeTypes.GET_COUPON_WEB_FAILURE]: getCouponWebFailure,
    [HomeTypes.OPEN_BARCODE_PANEL_START]: openBarcodePanel,
    [HomeTypes.CLOSE_BARCODE_PANEL_START]: closeBarcodePanel,
};

export default createReducer(homeContent,handlers);
