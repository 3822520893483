import React from 'react'
import i18next from "i18next";
import {Form} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {MetroSpinner} from "react-spinners-kit";


const FormStep2 =({handleSubmit, handleChange, user, isPrivateLoading=false}) => {
    const {verifyCode} = user
    return (
        <Form className="validation validation-form" onSubmit={handleSubmit}>
            <p className='title'>{i18next.t('Terminal.login.1')}</p>
            <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder={i18next.t('Terminal.login.7')}
                name="verifyCode"
                value={verifyCode}
                onChange={handleChange}
                className="login-input"
            />

            <div className="col-xs-12 padding_none">
                {isPrivateLoading ?
                    <button className="login-btn disabled" type="button">{i18next.t('Terminal.login.5')} <MetroSpinner size={20}/></button>
                    :
                    <button className="login-btn">{i18next.t('Terminal.login.5')}</button>
                }


            </div>
        </Form>
    )
}

export default FormStep2