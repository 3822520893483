import { createReducer } from 'reduxsauce';
import { PaymentTypes } from './actions';

export interface IPaymentState {
    createdStore: any;
    error: string;
    fiscalError: string;
    status: string;
    loading: boolean;
    currentDaySale: any;
    fiscalData: any;
}

export const paymentR: IPaymentState = {
    createdStore: {},
    error: '',
    fiscalError: '',
    status: '',
    loading: false,
    currentDaySale: {},
    fiscalData: {},
};

export const createStore = (state) => {
    return { ...state, status: '', loading: true };
};

export const createStoreSuccess = (state, action) => {
    return {
        ...state,
        createdStore: action.data,
        status: 'SUCCESS',
        loading: false,
    };
};

export const createStoreFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        createdStore: {},
        status: 'FAILED',
        loading: false,
    };
};

export const currentDaySaleSuccess = (state, action) => {
    return { ...state, currentDaySale: action.data };
};

export const currentDaySaleFailure = (state, action) => {
    return { ...state, error: action.error, currentDaySale: {} };
};

export const createStoreSaleFiscalStart = (state) => {
    return { ...state, status: '', fiscalData: {}, fiscalError: '' };
};

export const createStoreSaleFiscalSuccess = (state, action) => {
    return { ...state, fiscalData: action.data };
};

export const createStoreSaleFiscalFailure = (state, action) => {
    return {
        ...state,
        fiscalError: action.error,
        fiscalData: {},
        status: 'FAILED',
    };
};

export const createAtStoreSaleStart = (state) => {
    return { ...state, status: '', loading: true };
};

export const handlers = {
    [PaymentTypes.CREATE_STORE_START]: createStore,
    [PaymentTypes.CREATE_STORE_SUCCESS]: createStoreSuccess,
    [PaymentTypes.CREATE_STORE_FAILURE]: createStoreFailure,
    [PaymentTypes.GET_CURRENT_DAY_SALE_SUCCESS]: currentDaySaleSuccess,
    [PaymentTypes.GET_CURRENT_DAY_SALE_FAILURE]: currentDaySaleFailure,
    [PaymentTypes.CREATE_STORE_SALE_FISCAL_START]: createStoreSaleFiscalStart,
    [PaymentTypes.CREATE_STORE_SALE_FISCAL_SUCCESS]:
        createStoreSaleFiscalSuccess,
    [PaymentTypes.CREATE_STORE_SALE_FISCAL_FAILURE]:
        createStoreSaleFiscalFailure,
    [PaymentTypes.CREATE_AT_STORE_SALE_START]: createAtStoreSaleStart,
};

export default createReducer(paymentR, handlers);
