import React from 'react';
import Swiper from 'react-id-swiper';
import BlackWindow from "../../common/blackWindow";
import {getBasketsArray} from "../../../helpers/helpers";
import BasketPopup from "./basketPopup";
interface IProps {
    handleBasketToggle?: any,
    history?: any
}

const params = {
    slidesPerView: 3,
    spaceBetween: 0,
    autoplay: false,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
    }
}

const MultiBasket: React.FC<IProps> = ({handleBasketToggle, history}: IProps) => {
    const baskets = getBasketsArray()
    return (
        <>
            <BlackWindow type="page" handleClick={() => handleBasketToggle(false)}/>
            <div className="backetmodalContent">
                <div className={baskets.length === 3 ? "hide-swiper-pagination swiper--content" : "swiper--content"}>
                <Swiper {...params}>
                {
                    baskets.map((item, i) => {
                        return (
                            <div key={i}>
                                <BasketPopup history={history} handleBasketToggle={handleBasketToggle} basketName={item}/>
                            </div>
                        )
                    })
                }
                </Swiper>
                </div>
            </div>
        </>
    )
}

export default MultiBasket