import { takeLatest, put, call, select } from "redux-saga/effects";
import { getBrandCalendarById, getBrandServiceCalendar, getStoreClientById } from "../../../utils/constants/service";
import { getAccountResource } from "../../login/redux/selectors";
import { AppointmentsActionCreators, AppointmentsTypes } from './actions';

export function* getServiceCalendar() {
    try {
        const accountResource = yield select(getAccountResource);
        const storeCode = accountResource && accountResource.storeCode;
        const data = yield call(() => getBrandServiceCalendar(storeCode));

        yield put(AppointmentsActionCreators.getServiceCalendarSuccess(data));
    } catch (error) {
        yield put(AppointmentsActionCreators.getServiceCalendarFailure(error));
    }
}

export function* getStoreClient(params) {
    const { id } = params;
    try {
        const data = yield call(() => getStoreClientById(id));

        yield put(AppointmentsActionCreators.getStoreClientSuccess(data));
    } catch (error) {
        yield put(AppointmentsActionCreators.getStoreClientFailure(error));
    }
}

export function* getServiceCalendarId(params) {
    const { id } = params;
    try {
        const data = yield call(() => getBrandCalendarById(id));

        yield put(AppointmentsActionCreators.getServiceCalendarIdSuccess(data));
    } catch (error) {
        yield put(AppointmentsActionCreators.getServiceCalendarIdFailure(error));
    }
}

export function* appointmentsSaga() {
    yield takeLatest(AppointmentsTypes.GET_SERVICE_CALENDAR_START, getServiceCalendar);
    yield takeLatest(AppointmentsTypes.GET_STORE_CLIENT_START, getStoreClient);
    yield takeLatest(AppointmentsTypes.GET_SERVICE_CALENDAR_ID_START, getServiceCalendarId);
}

export default appointmentsSaga;