import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Tab, Table, Popup, Icon } from 'semantic-ui-react';
import {
    getSingleStock,
    getStock,
    getSingleLoading,
    getProductSold,
    getProductDeliveries,
    getStockUpdateloading,
} from './../../redux/selectors';
import { getAccountResource } from '../../../login/redux/selectors';
import { StockActionCreators } from './../../redux/actions';
import PopUp from '../../../popUp/popUp';
import Photo from '../../../common/product/photo';
import DeliveryBasketPopUp from '../../../deliveryBasketPopUp/container/deliveryBasketPopUp';
import {
    getCurrency,
    replaceComma,
    addDeliveryStore,
    getActiveBasketName,
    getDateTimestampByTime,
    getLangPrefix,
    getLogisticStatusLabel,
    isTerminalAdmin,
} from '../../../../helpers/helpers';
import {
    DEFAULT_CURRENCY_SYMBOL,
    LOCAL_STORAGE,
    SESSION_STORAGE,
} from '../../../../utils/constants/variables';
import history from '../../../../helpers/history';
import MultiBasket from '../../../basketPopup/container/multiBasket';
import PenToIcon from '../../../icons/PenTo';
import UpdateStock from './updateStock';
import UpdatePrice from './updatePrice';
import { updateProductVisual } from '../../../../utils/constants/service';

interface Props {
    stockData: any;
    singleStockData: any;
    isSingleLoading: boolean;
    account: any;
    productSold: object[];
    handleClosePopUp: () => void;
    isStockupdateloading: boolean;
}

interface State {
    storeData: any;
    activeStoreID: number;
    activeStoreSize: string;
    isDoubleClick: boolean;
    showBasketDelivery: boolean;
    showBasket: boolean;
    activeBarcodeId: number;
    isUpdateStockVisible: boolean;
    isUpdateStockPriceVisible: boolean;
    selectedProduct: any;
    uploadUrl: string;
}

class StocksDetails extends Component<Props & typeof StockActionCreators> {
    state: State = {
        storeData: [],
        activeStoreID: 0,
        activeStoreSize: '',
        isDoubleClick: false,
        showBasketDelivery: false,
        showBasket: false,
        activeBarcodeId: 0,
        isUpdateStockVisible: false,
        isUpdateStockPriceVisible: false,
        selectedProduct: {},
        uploadUrl: ''
    };

    fileRef = React.createRef<HTMLInputElement>();

    editProductPhoto = () => {
        const file = this.fileRef.current && this.fileRef.current.files && this.fileRef.current.files[0];
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
            updateProductVisual(formData, this.props.singleStockData.id)
            .then((res) => {
                this.setState({ uploadUrl: res.photoUrl })
            })
        }
    }

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.product'),
            render: () => {
                const { productDeliveries, singleStockData } = this.props;
                const { uploadUrl } = this.state;
                const isAdmin = isTerminalAdmin();

                return (
                    <Tab.Pane attached={false}>
                        <div className="productInfoBlock">
                            <div className="productInfoBlock--imgBox">
                                {this.props.singleStockData &&
                                Object.keys(this.props.singleStockData).length ? (
                                    this.props.singleStockData.productThumbnail ? (
                                        <Photo
                                            src={
                                                uploadUrl || this.props.singleStockData.productThumbnail
                                            }
                                        />
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="productInfoBlock--infoBox">
                                <h1>
                                    {this.props.singleStockData
                                        ? this.props.singleStockData.productName
                                        : ''}
                                </h1>
                            </div>
                            {isAdmin && (
                                <PenToIcon
                                    fill="#183153"
                                    classN="cursor small-icon"
                                    onClick={() => {
                                        this.fileRef.current && this.fileRef.current.click();
                                    }}
                                />
                            )}
                            <input
                                onChange={this.editProductPhoto}
                                ref={this.fileRef} type="file"
                                hidden
                            />
                        </div>
                        {this.props.singleStockData &&
                        Array.isArray(this.props.singleStockData.barcodes) ? (
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table collaps"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.barcode',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.Size')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.price')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.productEdit',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.promoPrice',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.reduction',
                                            )}
                                        </Table.Cell>
                                        {singleStockData.service && (
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.ecoTax',
                                                )}
                                            </Table.Cell>
                                        )}
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.stock')}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t('Terminal.dashboards.unit')}
                                        </Table.Cell>
                                        {singleStockData.service && (
                                            <>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.duration')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.productGroup')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.service')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                    {i18next.t('Terminal.dashboards.recurring')}
                                                </Table.Cell>
                                            </>
                                        )}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {singleStockData.barcodes.map(
                                        (item) => {
                                        const { stock } = this.barcodeInventoryListData(item.barcodeInventoryList);
                                        return (
                                            <Table.Row
                                                key={item.id}
                                                onDoubleClick={() =>
                                                    stock > 0
                                                        ? this.handleAddDelivery(
                                                            item.barcode,
                                                            item,
                                                        )
                                                        : ''
                                                }
                                                className={this.state.activeBarcodeId === item.id ? 'activeRow' : ''}
                                            >
                                                <Table.Cell
                                                    className="productInfoBlock--infoBox--table--description cursor"
                                                    onClick={this.setActiveBarcode.bind(this, item)}
                                                >
                                                    {item.barcode}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.size}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description beetwenBox">
                                                    {replaceComma(item.listPrice)}
                                                    {getCurrency()}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.outletPrice
                                                        ? replaceComma(
                                                            item.outletPrice,
                                                        ) + getCurrency()
                                                        : ''}
                                                    {isAdmin && (
                                                        <PenToIcon
                                                            fill="#183153"
                                                            classN="cursor small-icon"
                                                            onClick={this.setProduct.bind(this, {
                                                                ...item,
                                                                stock,
                                                                country: this.props.account.storeDetails && this.props.account.storeDetails.country
                                                            }, 'price')}
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.promoListPrice
                                                        ? replaceComma(
                                                            item.promoListPrice,
                                                        )
                                                        : ''}
                                                    {item.promoListPrice
                                                        ? getCurrency()
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.promotionDetails}
                                                </Table.Cell>
                                                {singleStockData.service && (
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.ecoTax
                                                            ? `${item.ecoTax
                                                                .toString()
                                                                .replace(
                                                                    '.',
                                                                    ',',
                                                                )} ${getCurrency()}`
                                                            : ''}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell className="productInfoBlock--infoBox--table--description beetwenBox">
                                                    <div className="productInfoBlock--infoBox--table--description--btn">
                                                        {stock}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {isAdmin && (
                                                        <PenToIcon
                                                            fill="#183153"
                                                            classN="cursor small-icon"
                                                            onClick={this.setProduct.bind(this, {
                                                                ...item,
                                                                stock,
                                                                country: this.props.account.storeDetails && this.props.account.storeDetails.country
                                                            }, '')}
                                                        />
                                                    )}
                                                </Table.Cell>
                                                {singleStockData.service && (
                                                    <>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {item.duration}
                                                        </Table.Cell>
                                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                            {item.productGroup}
                                                        </Table.Cell>
                                                        <Table.Cell className={`productInfoBlock--infoBox--table--description ${item.service ? 'color--green' : 'color--red'}`}>
                                                            {item.service === true ? "Y" : item.recurring === false ? "N" : ""}
                                                        </Table.Cell>
                                                        <Table.Cell className={`productInfoBlock--infoBox--table--description ${item.recurring ? 'color--green' : 'color--red'}`}>
                                                            {item.recurring === true ? "Y" : item.recurring === false ? "N" : ""}
                                                        </Table.Cell>
                                                    </>
                                                )}
                                            </Table.Row>
                                        )},
                                    )}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                        {productDeliveries.length ? (
                            <div>
                                <h1>{i18next.t('home.bottomButtons.9')} </h1>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.date',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Deliveries.slipNumber',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.ticketStatus',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Deliveries.qtyDelivered',
                                                )}{' '}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Deliveries.qtyChecked',
                                                )}{' '}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {productDeliveries.map((delivery, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {getDateTimestampByTime(delivery.slipDate, 'dd/mm/yy')}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {delivery.slipNumber}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {getLogisticStatusLabel(delivery.logisticStatus)}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {delivery.qtyDelivered}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {delivery.qtyChecked}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        ) : ""}
                    </Tab.Pane>
                )
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.stock'),
            render: () => (
                <Tab.Pane attached={false}>
                    <div className="productInfoBlock">
                        <div className="productInfoBlock--imgBox">
                            {this.props.singleStockData &&
                            Object.keys(this.props.singleStockData).length ? (
                                this.props.singleStockData.productThumbnail ? (
                                    <Photo
                                        src={
                                            this.props.singleStockData
                                                .productThumbnail
                                        }
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="productInfoBlock--infoBox">
                            <h1>
                                {this.props.singleStockData
                                    ? this.props.singleStockData.productName
                                    : ''}
                            </h1>
                        </div>
                    </div>
                    {this.props.singleStockData &&
                    Array.isArray(this.props.singleStockData.barcodes) ? (
                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.barcode',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Size')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.price',
                                            {
                                                DEFAULT_CURRENCY_SYMBOL,
                                            },
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.stock')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.unit')}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.singleStockData.barcodes.map(
                                    (item) => {
                                        const { stock, unit } = this.barcodeInventoryListData(
                                            item.barcodeInventoryList,
                                        );

                                        return (
                                            <Table.Row
                                                key={item.id}
                                                onClick={() =>
                                                    this.upDateBarcodeInventoryList(
                                                        item.id,
                                                        item.barcodeInventoryList,
                                                        item.size,
                                                    )
                                                }
                                                onDoubleClick={() =>
                                                    this.handleAddToHomeBasket(
                                                        item.barcode,
                                                    )
                                                }
                                                className={`${
                                                    item.id ===
                                                    this.state.activeStoreID
                                                        ? 'activeRow'
                                                        : ''
                                                }`}
                                            >
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.barcode}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.size}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {replaceComma(
                                                        item.listPrice,
                                                    )}
                                                    {getCurrency()}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {stock}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {unit || item.unit}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    },
                                )}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                    {this.state.storeData && this.state.storeData.length ? (
                        <div>
                            <h1>{i18next.t('Terminal.dashboards.stock')} </h1>
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.storeName',
                                            )}{' '}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.storeAddress',
                                            )}{' '}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.storeCity',
                                            )}{' '}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.country',
                                            )}{' '}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.storePhone',
                                            )}{' '}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.stock',
                                            )}{' '}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.storeData.map((item, i) =>
                                        item.stock !== 0 ? (
                                            <Table.Row key={i}>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.storeCode
                                                        ? `${item.storeName} (${item.storeCode})`
                                                        : item.storeName}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.storeAddress}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.storeCity}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.storeCountry}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.storePhone}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description beetwenBox">
                                                    <div className="productInfoBlock--infoBox--table--description--text">
                                                        {' '}
                                                        {item.stock}
                                                    </div>
                                                    {item.stock > 0 &&
                                                    (item.storeType === 'W' ||
                                                        this.props.account
                                                            .storeDetails
                                                            .storeType ===
                                                            item.storeType ||
                                                        (this.props.account
                                                            .storeDetails
                                                            .storeType ===
                                                            'S' &&
                                                            item.storeType ===
                                                                'G')) ? (
                                                        <>
                                                            <div className="productInfoBlock--infoBox--table--description--btn">
                                                                {this.props
                                                                    .account
                                                                    .storeDetails
                                                                    .storeCode ===
                                                                item.storeCode ? (
                                                                    ''
                                                                ) : (
                                                                    <Popup
                                                                        trigger={
                                                                            <button
                                                                                className="btnTruck"
                                                                                onClick={() =>
                                                                                    this.handleAddDelivery(
                                                                                        this
                                                                                            .props
                                                                                            .singleStockData
                                                                                            .barcodes,
                                                                                        item,
                                                                                        LOCAL_STORAGE.deliveryBasket,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icon
                                                                                    disabled
                                                                                    name="truck"
                                                                                />
                                                                            </button>
                                                                        }
                                                                        content={i18next.t(
                                                                            'Terminal.Receipt.Transfer.Transfer',
                                                                        )}
                                                                        position="top right"
                                                                        inverted
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="productInfoBlock--infoBox--table--description--btn">
                                                                <Popup
                                                                    trigger={
                                                                        <button
                                                                            className="btnTruck"
                                                                            onClick={() =>
                                                                                this.handleAddDelivery(
                                                                                    this
                                                                                        .props
                                                                                        .singleStockData
                                                                                        .barcodes,
                                                                                    item,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                disabled
                                                                                name="shopping cart"
                                                                            />
                                                                        </button>
                                                                    }
                                                                    content={i18next.t(
                                                                        'product.product.13',
                                                                    )}
                                                                    position="top right"
                                                                    inverted
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            ''
                                        ),
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    ) : (
                        ''
                    )}
                </Tab.Pane>
            ),
        },
        {
            menuItem: i18next.t('Terminal.dashboards.ventes'),
            render: () => (
                <Tab.Pane attached={false}>
                    <div className="productInfoBlock">
                        <div className="productInfoBlock--imgBox">
                            {this.props.singleStockData &&
                            Object.keys(this.props.singleStockData).length ? (
                                this.props.singleStockData.productThumbnail ? (
                                    <Photo
                                        src={
                                            this.props.singleStockData
                                                .productThumbnail
                                        }
                                    />
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="productInfoBlock--infoBox">
                            <h1>
                                {this.props.singleStockData
                                    ? this.props.singleStockData.productName
                                    : ''}
                            </h1>
                        </div>
                    </div>
                    {this.props.productSold.length ? (
                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table collaps"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.date')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.ticketNumber',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Size')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.quantity',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.price')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.currency',
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.productSold.map((item) => (
                                    <Table.Row key={item.ticketNumber}>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {getDateTimestampByTime(
                                                item.saleDate,
                                                'dd/mm/yy',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {item.ticketNumber}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {item.size}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {item.quantity}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {replaceComma(item.listPrice)}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {item.currency}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                </Tab.Pane>
            ),
        },
    ];

    componentDidUpdate(prevProps): void {
        const { activeBarcodeId } = this.state;
        const { singleStockData: { barcodes = [] } = {}, productDeliveries } = this.props;
        if (
            this.props.barcodeProductStatus !==
                prevProps.barcodeProductStatus &&
            this.props.barcodeProductStatus === 'success' &&
            this.state.isDoubleClick
        ) {
            this.setState({ isDoubleClick: false });
            history.push('/');
        }
        if (
            prevProps.isSingleLoading &&
            !this.props.isSingleLoading &&
            Array.isArray(this.props.singleStockData.barcodes) &&
            this.props.singleStockData.barcodes[0]
        ) {
            this.upDateBarcodeInventoryList(
                this.props.singleStockData.barcodes[0].id,
                this.props.singleStockData.barcodes[0].barcodeInventoryList,
                this.props.singleStockData.barcodes[0].size,
            );
        }

        if (barcodes[0] && barcodes[0].id && this.props.singleStockData !== prevProps.singleStockData) {
            this.setActiveBarcode(barcodes[0]);
        }
    }

    setActiveBarcode = (barcodeData) => {
        const { activeBarcodeId } = this.state;
        const { id = 0, barcode = '' } = barcodeData || {};

        if (activeBarcodeId === id) return;

        this.setState({ activeBarcodeId: id })
        // this.props.getProductDeliveryStart(barcode);
    };

    upDateBarcodeInventoryList = (id, barcodeInventoryList = [], size) => {
        this.setState({
            storeData: barcodeInventoryList.filter((i: any) => i.stock > 0),
            activeStoreID: id,
            activeStoreSize: size,
        });
    };

    handlePrintItem =
        (item: any = {}, data: any = {}) =>
        () => {
            const { productName } = data;
            this.props.printDataStart({ ...item, productName }, 'stockProduct');
        };

    handleAddDelivery = (data, store, name = getActiveBasketName()) => {
        const { activeStoreSize } = this.state;
        if (typeof data === 'string') {
            this.props.getBarcodeProductStart({
                barcode: data,
                storeCode: store.storeCode,
                basket: name,
            });
        } else {
            data.forEach((item) =>
                item.size === activeStoreSize
                    ? this.props.getBarcodeProductStart({
                          barcode: item.barcode,
                          storeCode: store.storeCode,
                          basket: name,
                      })
                    : '',
            );
        }

        if (name === getActiveBasketName()) {
            history.push(getLangPrefix());
        } else {
            addDeliveryStore(store);
            this.setState({ showBasketDelivery: true });
        }
    };

    handleAddToHomeBasket = (barcode) => {
        if (barcode) {
            this.setState({ isDoubleClick: true }, () =>
                this.props.getBarcodeProductStart({ barcode }),
            );
        }
    };

    barcodeInventoryListData(barcodeInventoryList) {
        const storeCode = sessionStorage.getItem(SESSION_STORAGE.storeCode);
        const stockData = (barcodeInventoryList || []).reduce((acc, item) => {
            if (item.storeCode === storeCode) {
                acc.stock = item.stock;
            }
            acc.unit = item.unit ? item.unit : "";
            return acc;
        }, { stock: "", unit: "" })

        return stockData;
    }

    setProduct = (product, type) => {
        this.setState({selectedProduct: product});
        type === 'price' ? this.toggleStockPriceUpdatePopup() : this.toggleStockUpdatePopup();
    }

    toggleStockUpdatePopup = () => {
        this.setState({isUpdateStockVisible: !this.state.isUpdateStockVisible})
    }

    toggleStockPriceUpdatePopup = () => {
        this.setState({isUpdateStockPriceVisible: !this.state.isUpdateStockPriceVisible})
    }

    render() {
        const {
            isSingleLoading,
            updateProductStockStart,
            singleStockData,
            updateProductPriceStart,
            isStockupdateloading
        } = this.props;
        const {
            showBasketDelivery,
            showBasket,
            isUpdateStockVisible,
            isUpdateStockPriceVisible,
            selectedProduct
        } = this.state;
        return (
            <>
                <PopUp
                    handleClose={this.props.handleClosePopUp}
                    loading={isSingleLoading}
                >
                    <Tab menu={{ pointing: true }} panes={this.panes} />
                </PopUp>
                {showBasketDelivery && (
                    <DeliveryBasketPopUp
                        handleBasketToggle={(showBasketDelivery) =>
                            this.setState({ showBasketDelivery })
                        }
                    />
                )}

                {showBasket && (
                    <MultiBasket
                        handleBasketToggle={(showBasket) =>
                            this.setState({ showBasket })
                        }
                        history={this.props.history}
                    />
                )}
                {isUpdateStockVisible && <PopUp
                        handleClose={this.toggleStockUpdatePopup}
                    >
                        <UpdateStock
                            stock={selectedProduct.stock}
                            barcode={selectedProduct.barcode}
                            updateStockAction={updateProductStockStart}
                            singleStockId={singleStockData.id}
                            closePanel={this.toggleStockUpdatePopup}
                            loading={isStockupdateloading}
                        />
                    </PopUp>
                }
                {isUpdateStockPriceVisible && <PopUp
                        handleClose={this.toggleStockPriceUpdatePopup}
                    >
                        <UpdatePrice
                            selectedProduct={selectedProduct}
                            updateStockPriceAction={updateProductPriceStart}
                            singleStockId={singleStockData.id}
                            closePanel={this.toggleStockPriceUpdatePopup}
                            loading={isStockupdateloading}
                        />
                    </PopUp>
                }
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    account: getAccountResource(state),
    stockData: getStock(state),
    singleStockData: getSingleStock(state),
    isSingleLoading: getSingleLoading(state),
    productSold: getProductSold(state),
    productDeliveries: getProductDeliveries(state),
    isStockupdateloading: getStockUpdateloading(state),
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StocksDetails);
