import React, {Component} from 'react';
import PopUp from "../../../popUp/popUp";
import i18next from "i18next";
import BonsCadeauxPopUp from "./bonsCadeauxPopUp";
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import {connect} from 'react-redux';
import { addDays, toTimestamp } from '../../../../helpers/helpers';
import {StoreGiftsActionCreators} from "../../redux/actions"
import {DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL, GIFT_DURATION, RECEIPT_TYPE} from "../../../../utils/constants/variables";
import CustomBtn from "../../../common/customBtn";
import AddGift from "./addGift";
import { getIsTerminalOpen } from '../../../fermeturePopUp/redux/selectors';
import { createGift } from '../../../../utils/constants/service';

interface Props {
    dispatch: any,
    data: any,
    isTerminalOpen: boolean
}

interface State {
    isShowPopUp: boolean,
    showConfirmPopup: boolean,
    storeGiftCode: string,
    loading: boolean
}

class SetStoreGift extends Component<Props, State> {
    state: State = {
        isShowPopUp: false,
        showConfirmPopup: false,
        storeGiftCode: "",
        loading: false
    };

    openPopUp = () => {
        this.setState({isShowPopUp: true});
    };
    handleClosePopUp = () => {
        this.setState({isShowPopUp: false});
    };
    toggleRemovePopup = () => {
        this.setState({showConfirmPopup: !this.state.showConfirmPopup})
    };
    handleDelete = () => {
        this.setState({showConfirmPopup:false});
         const {data} = this.props;
         let body :any = {};
         data && Object.keys(data).length && Object.keys(data).forEach(item=> data[item]=== undefined ? body[item]=null: body[item]=data[item]);
         if(body && Object.keys(body).length){
            body['deleted'] = true;
            body['endDate'] = toTimestamp(body['endDate']);
            body['startDate'] = toTimestamp(body['startDate']);
            body['redemptionDate'] = toTimestamp(body['redemptionDate']);
            body['amount'] =Number(body['amount'].replace(DEFAULT_CURRENCY_SYMBOL,"").replace(",00",""));
            body['percent'] = Number(body['percent'].replace("%",""));
            this.props.dispatch(StoreGiftsActionCreators.createStoreGiftStart(body));
             this.setState({showConfirmPopup:false})
        }

    };

    handleCreateGift = (percent) => () => {
        this.setState({loading: true})
        const data = {
            percent,
            amount: null,
            active: true,
            barcode: "",
            storeClientId: null,
            credit: false,
            currency: DEFAULT_CURRENCY,
            startDate: Date.now(),
            endDate: addDays(new Date(),GIFT_DURATION).getTime(),
            qrCode: null,
            redemptionDate: null,
            storeGiftId: null,
        }

        createGift(data).then(({storeGiftCode}) => {
            this.setState({loading: false, storeGiftCode})
        }).catch(e => {
            this.setState({loading: false})
        })
    }


    render() {
        const { isShowPopUp, showConfirmPopup, storeGiftCode, loading } = this.state;
        const { data = {}, isTerminalOpen} = this.props;
        return (
            <>
                <CustomBtn
                    classN="btn_1 w-100 orange_btn"
                    handleClick={this.openPopUp}
                    disabled={!isTerminalOpen}
                >
                    {i18next.t('buttons.4')}
                </CustomBtn>
                <CustomBtn
                    classN='btn_1 w-100 blue_btn'
                    handleClick={()=>{this.setState({storeGiftCode: data.storeGiftCode})}}
                    disabled={data.redemptionDate}
                >
                    {i18next.t('buttons.30')}
                </CustomBtn>
                <CustomBtn 
                    classN="btn_1 w-100 red_btn" 
                    handleClick={this.toggleRemovePopup}
                    disabled={data.credit && !(data.active && !data.expired && !data.redemptionDate)}
                >
                    {i18next.t('buttons.12')}
                </CustomBtn>

                <CustomBtn 
                    classN="btn_1 w-100 green_btn gift_percent-btn" 
                    handleClick={this.handleCreateGift(10)}
                    loading={loading}
                >
                    -10%
                </CustomBtn>
                <CustomBtn 
                    classN="btn_1 w-100 blue_btn gift_percent-btn" 
                    handleClick={this.handleCreateGift(20)}
                    loading={loading}
                >
                    -20%
                </CustomBtn>

                {isShowPopUp && (
                    <PopUp handleClose={this.handleClosePopUp}>
                        <BonsCadeauxPopUp data={{}} handleClosePopUp={this.handleClosePopUp}/>
                    </PopUp>
                )}

                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={this.toggleRemovePopup}
                        handleConfirm={this.handleDelete}
                        text={i18next.t('Terminal.modal.1')}
                    />
                )}
                {storeGiftCode && (
                    <AddGift
                        storeGiftCode={storeGiftCode}
                        storeGiftPopup={true}
                        handleClose={() =>this.setState({storeGiftCode:""})}
                    />
                )}
            </>
        )
    }
}
const mapStateToProps = (state: any) => ({
    isTerminalOpen: getIsTerminalOpen(state)
});

export default connect(mapStateToProps, null)(SetStoreGift);
