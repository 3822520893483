import { PaymentTypes, PaymentActionCreators } from './actions';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import axios from 'axios';
import i18next from 'i18next';
import {
    CREATE_STORE_GIFT_URL,
    CREATE_STORE_SALE,
    CURRENT_DAY_SALE,
    FISCAL_FINISH_TRANSACTION_DE,
    FISCAL_START_TRANSACTION_DE,
    CREATE_STORE_SALE_FISCAL,
    getAxiosHeaders,
    getPaperReceiptData,
    PRINTER_URL,
} from '../../../utils/constants/service';
import {
    fixedInHundredth,
    getBasketStoreGifts,
} from '../../../helpers/helpers';
import {
    DEFAULT_CURRENCY,
    RECEIPT_TYPE,
    STORE_COUNTRIES,
    STORE_COUNTRY,
    TICKET_TYPE,
} from '../../../utils/constants/variables';
import { getCreatedStore } from './selectors';
import { getFiscalTssDetails } from '../../../layouts/main/header/redux/selectors';
import { getAccountResource } from '../../login/redux/selectors';

export function* createStore(params) {
    const { body, gift } = params;
    const { paymentTermCode, storeGifts = [], saleTotal } = body;

    try {
        const headers = {
            headers: {
                ...getAxiosHeaders().headers,
                currency: DEFAULT_CURRENCY,
            },
        };

        const data = yield call(() => {
            return axios
                .post(`${CREATE_STORE_SALE}`, body, headers)
                .then((res) => res.data);
        });

        data.paymentTermCode = paymentTermCode;

        yield put(PaymentActionCreators.createStoreSuccess(data));

        if (gift && Object.keys(gift).length) {
            if (saleTotal < 0) {
                gift.ticketNumber = data.ticketNumber;
            }
            yield put(PaymentActionCreators.createStoreGiftStart(gift, true));
        }

        if (storeGifts.length) {
            const gifts: any[] = [];
            getBasketStoreGifts().forEach((gift) => {
                if (
                    storeGifts.find(
                        (item) => item.storeGiftId === gift.storeGiftId,
                    )
                ) {
                    gift.redemptionDate = new Date().getTime();
                    if (!gift.active) {
                        gift.active = true;
                        gift.redemptionDate = null;
                    }

                    gifts.push(
                        axios.post(CREATE_STORE_GIFT_URL, gift, headers),
                    );
                }
            });

            yield all(gifts);
        }
    } catch (e) {
        yield put(PaymentActionCreators.createStoreFailure(e.message));
    }
}

export function* currentDaySale() {
    try {
        const headers = getAxiosHeaders();
        const { conversionRate, altCurrency, currency } = yield select(
            getAccountResource,
        );
        headers.headers['currency'] = currency;

        const fetchData = [
            axios.get(`${CURRENT_DAY_SALE}`, headers).then((res) => res.data),
        ];

        if (altCurrency) {
            const headers = getAxiosHeaders();
            headers.headers['currency'] = altCurrency;
            fetchData.push(
                axios
                    .get(`${CURRENT_DAY_SALE}`, headers)
                    .then((res) => res.data),
            );
        }

        const [data = {}, altData] = yield call(() => Promise.all(fetchData));
        if (altData && altData.paymentNetTotal) {
            data.paymentNetTotal = +fixedInHundredth(
                (data.paymentNetTotal || 0) +
                    +fixedInHundredth(1 / conversionRate) *
                        (altData.paymentNetTotal || 0),
            );
            data.saleQuantityTotal =
                (data.saleQuantityTotal || 0) +
                (altData.saleQuantityTotal || 0);
        }

        yield put(PaymentActionCreators.getCurrentDaySaleSuccess(data));
    } catch (e) {
        yield put(PaymentActionCreators.getCurrentDaySaleFailure(e.message));
    }
}

export function* createStoreSaleFiscalStart(params) {
    try {
        const { fiscalBody, body } = params.data;
        const bodyFinish = { ...fiscalBody };

        const data = yield call(() => {
            return axios
                .post(
                    `${FISCAL_START_TRANSACTION_DE}`,
                    fiscalBody,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        if (
            data &&
            data.statusCode &&
            data.statusCode.toUpperCase() !== 'SUCCESS'
        ) {
            throw new Error(i18next.t('Terminal.payment.10'));
        }

        yield* createStore({ body, gift: params.gift });

        const { ticketNumber = '' } = yield select(getCreatedStore);

        if (!ticketNumber) {
            throw new Error(i18next.t('Terminal.payment.9'));
        }

        bodyFinish['transactionNumber'] = data.transactionNumber;

        const finishData = yield call(() => {
            return axios
                .post(
                    `${FISCAL_FINISH_TRANSACTION_DE}`,
                    bodyFinish,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        if (
            finishData &&
            finishData.statusCode &&
            finishData.statusCode.toUpperCase() !== 'SUCCESS'
        ) {
            throw new Error(i18next.t('Terminal.payment.10'));
        }

        const {
            algorithm = '',
            publicKey = '',
            timeFormat = '',
        } = yield select(getFiscalTssDetails);

        const fiscalData = {
            algorithm,
            publicKey,
            timeFormat,
            ticketNumber,
            transactionStart: data.logTimeInMs,
            serialNumber: data.serialNumber,
            transactionNumber: data.transactionNumber,
            transactionFinish: finishData.logTimeInMs,
            status: finishData.transactionStatusCode,
            signatureCounter: finishData.signatureCounter,
            signatureValue: finishData.encodedSignatureValue,
            processData: bodyFinish.processData,
        };

        yield put(
            PaymentActionCreators.createStoreSaleFiscalSuccess(finishData),
        );

        const res = yield call(() => {
            return axios
                .post(
                    `${CREATE_STORE_SALE_FISCAL}`,
                    fiscalData,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        if (!res) {
            throw new Error(i18next.t('Terminal.payment.9'));
        }

        const { printer } = yield select(getAccountResource);

        if (printer) {
            const type = TICKET_TYPE.ticket;
            const receiptType = RECEIPT_TYPE.ticket;

            yield put(
                PaymentActionCreators.printTicketStart({
                    ticketNumber,
                    receiptType,
                    type,
                    isPay: !params.gift,
                }),
            );
        }
    } catch (e) {
        yield put(
            PaymentActionCreators.createStoreSaleFiscalFailure(e.message),
        );
    }
}

export function* createAtStoreSale(params) {
    try {
        yield* createStore(params);

        const { ticketNumber = '' } = yield select(getCreatedStore);
        const { printer } = yield select(getAccountResource);

        if (!ticketNumber) {
            throw new Error(i18next.t('Terminal.payment.9'));
        }

        const fiscalData = {
            ticketNumber,
            zeroValueReceipt: false,
        };

        const res = yield call(() => {
            return axios
                .post(
                    `${CREATE_STORE_SALE_FISCAL}`,
                    fiscalData,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        if (!res || res.statusCode !== 'SUCCESS') {
            throw new Error(i18next.t('Terminal.payment.9'));
        }

        if (printer) {
            yield put(
                PaymentActionCreators.printTicketStart({
                    ticketNumber,
                    receiptType: RECEIPT_TYPE.ticket,
                    type: TICKET_TYPE.ticket,
                    isPay: !params.gift,
                }),
            );
        }
    } catch (e) {
        yield put(PaymentActionCreators.createStoreFailure(e.message));
    }
}

export function* paymentSaga() {
    yield takeLatest(PaymentTypes.CREATE_STORE_START, createStore);
    yield takeLatest(PaymentTypes.GET_CURRENT_DAY_SALE_START, currentDaySale);
    yield takeLatest(
        PaymentTypes.CREATE_STORE_SALE_FISCAL_START,
        createStoreSaleFiscalStart,
    );
    yield takeLatest(
        PaymentTypes.CREATE_AT_STORE_SALE_START,
        createAtStoreSale,
    );
}

export default paymentSaga;
