import React, { useState, useEffect } from 'react';
import { BASE_API_GATEWAY } from '../../../utils/constants/service';
import { LazyImage } from 'react-lazy-images';
import i18next from 'i18next';
import noImg from './../../../assets/img/null.png';

import {
    GlassMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION,
} from 'react-image-magnifiers';
import DiscountDetail from './discountDetail';

export default function Photo({
    src,
    discountDetail = '',
    outlet = '',
    isPromo = false,
    onSpecial = '',
    thumbnail = '',
    alt = 'img',
    zoom = false,
    isMobile = false,
}) {
    const [defaultSrc, setDefaultSrc] = useState(`${BASE_API_GATEWAY}${src}`);
    useEffect(() => {
        setDefaultSrc(`${BASE_API_GATEWAY}${src}`);
    }, [src]);
    let isvalid = false;
    if (src) {
        isvalid = !!(
            src.split('/').findIndex((item) => item === 'null') === -1 ||
            thumbnail.split('/').findIndex((item) => item === 'null') === -1
        );
    }

    return (
        <>
            {isvalid ? (
                zoom && !isMobile ? (
                    <LazyImage
                        src={`${BASE_API_GATEWAY}${src}`}
                        error={() => <span></span>}
                        placeholder={({ imageProps, ref }) => (
                            <img
                                ref={ref}
                                src={`${BASE_API_GATEWAY}${thumbnail}`}
                                alt={imageProps.alt}
                            />
                        )}
                        actual={({ imageProps }) => (
                            <>
                                <GlassMagnifier
                                    imageSrc={`${BASE_API_GATEWAY}${src}`}
                                    imageAlt={alt}
                                    largeImageSrc={defaultSrc} // Optional
                                    mouseActivation={
                                        MOUSE_ACTIVATION.DOUBLE_CLICK
                                    } // Optional
                                    touchActivation={
                                        TOUCH_ACTIVATION.DOUBLE_TAP
                                    } // Optional
                                    magnifierSize={'40%'}
                                />
                            </>
                        )}
                    />
                ) : thumbnail ? (
                    <LazyImage
                        src={`${BASE_API_GATEWAY}${src}`}
                        error={() => <span></span>}
                        placeholder={({ imageProps, ref }) => (
                            <img
                                ref={ref}
                                src={`${BASE_API_GATEWAY}${thumbnail}`}
                                alt={imageProps.alt}
                            />
                        )}
                        actual={({ imageProps }) => (
                            <img {...imageProps} alt={alt} />
                        )}
                    />
                ) : (
                    <img
                        src={defaultSrc}
                        onError={(e) => setDefaultSrc(noImg)}
                        alt="pour products image"
                    />
                )
            ) : (
                ''
            )}

            <DiscountDetail
                product={{
                    outlet,
                    discountDetail,
                    onSpecial,
                    listPriceAfterDiscount: isPromo,
                }}
                isPhoto={true}
            />
        </>
    );
}
