import React, { RefObject, useState } from 'react';
import i18next from 'i18next';

import './fileInput.scss';

interface Props {
	name: string;
	inputRef: RefObject<HTMLInputElement> | null;
	id: string;
	isNameVisible?: boolean;
}

const FileInput: React.FC<Props> = ({
	name,
	inputRef,
	id,
	isNameVisible = true
}) => {
	const [fileName, setFileName] = useState<string>('');

	const handleOnFileChange = (event) => {
		const file = event.target.files && event.target.files[0];
		setFileName(file.name);
	};

	return (
		<div className='file-button-container'>
			<label className="file-label" htmlFor={id}>{i18next.t('buttons.54')}</label>
			{isNameVisible && <span className='file-info'>{fileName}</span>}
			<input
				className="file-input"
				name={name}
				id={id}
				ref={inputRef}
				type="file"
				onChange={handleOnFileChange}
			/>
		</div>
	)
}

export default FileInput;