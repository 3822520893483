import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import BarcodeReader from 'react-barcode-reader';
import {
    fixedInHundredth,
    getBasketData,
    getCurrency,
    getMenuItemCanonicalUrl,
    replaceComma,
    replaceDote,
    threeDigitSets,
} from '../../../../helpers/helpers';
import AddGift from '../../../bonsCadeaux/container/components/addGift';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { RECEIPT_TYPE } from '../../../../utils/constants/variables';
import history from '../../../../helpers/history';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';

interface IProps {
    basket: any;
    handleChange: (param) => void;
    handelChangeTemp: (param) => void;
    getBarcodeProductStart: (param) => void;
    activeAction: string;
    showUser: any;
    client: any;
    paperReceiptStart: (param) => void;
    paperReceiptQuoteStart: (param) => void;
    terminalCode: string;
    menu: any;
    storeCode: string;
    isPaymentPanelVisible?: boolean;
}

const calculator = [
    ['7', '8', '9'],
    ['4', '5', '6'],
    ['1', '2', '3'],
    [',', '0', ',00'],
];

const Calculator: React.FC<IProps> = ({
    basket,
    handleChange,
    getBarcodeProductStart,
    activeAction = '',
    client,
    paperReceiptStart,
    showUser,
    terminalCode = '',
    paperReceiptQuoteStart,
    handelChangeTemp,
    menu,
    storeCode = '',
    isPaymentPanelVisible = false,
}: IProps) => {
    const [inputValue, setInputValue] = useState('');
    const [inputScanValue, setInputScanValue] = useState('');
    const [storeGiftCode, setStoreGiftCode] = useState('');
    const [differentStoreTicket, setDifferentStoreTicket] = useState('');
    const divRef = React.useRef<HTMLDivElement>(null);
    const { price, totalQuantity } = getBasketData(basket);
    const {
        profile: { firstName = '', lastName = '', currencyId = '' } = {},
        storeClientLoyalty: { loyaltyPoints = '', loyaltyValue = '' } = {},
    } = client;

    useEffect(() => {
        let value = '';
        if (activeAction === 'gift') {
            value = terminalCode.padStart(8, 'G00');
        } else if (activeAction !== '') {
            value = inputValue;
        }
        setInputValue(value);
        if (divRef.current !== null) {
            divRef.current.focus();
        }
    }, [activeAction]);

    useEffect(() => {
        handelChangeTemp(inputValue.replace(',', '.'));
    }, [inputValue]);

    const handleReset = () => {
        if (activeAction === 'sousTotal') {
            handleChange('RemoveSousTotal');
        } else if (activeAction === 'remboursement') {
            handleChange('RevertProduct');
        } else {
            handleChange('');
        }
        setInputValue('');
        setInputScanValue('');
    };

    const setCalculator = () => {
        if (
            (!activeAction ||
                ['verify', 'remboursement'].includes(activeAction)) &&
            inputValue
        ) {
            if (
                inputValue[0].toUpperCase() === 'G' ||
                inputValue[0].toUpperCase() === 'R'
            ) {
                handleScan(inputValue);
                return;
            }
            if (!(basket.ticket && basket.ticket.ticketNumber)) {
                getBarcodeProductStart({
                    barcode: inputValue,
                    scanType: activeAction,
                });
            }
            handleChange('');
        } else if (activeAction === 'devis') {
            handleChange('devis');
        } else if (activeAction === 'sousTotal') {
            handleChange('SetSousTotal');
        } else if (activeAction === 'remboursement') {
            handleChange(-1);
        } else if (activeAction === 'gift') {
            setStoreGiftCode(inputValue);
            handleChange('');
        } else {
            handleChange(inputValue.replace(',', '.'));
        }
        setInputValue('');
        setInputScanValue('');
    };

    const handleChangeValue = (item) => () => {
        if (
            /^\d+\,\d{2}$/.test(inputValue) ||
            (!inputValue && (item === ',00' || item === ',')) ||
            (/^\d+\,\d{1}$/.test(inputValue) &&
                (item === ',00' || item === ',')) ||
            (/^\d+\,$/.test(inputValue) && (item === ',00' || item === ','))
        ) {
            return;
        }

        setInputValue((prev) => prev + item);
    };

    const handleScan = (barcode) => {
        if (
            !isPaymentPanelVisible &&
            barcode &&
            (!activeAction ||
                activeAction === 'remboursement' ||
                activeAction === 'verify' ||
                activeAction === 'sousTotal')
        ) {
            if (barcode[0].toUpperCase() === 'G') {
                setStoreGiftCode(barcode);
            } else if (barcode[0].toUpperCase() === 'R') {
                if (
                    storeCode &&
                    storeCode.padStart(5, '0') !== barcode.slice(1, 6)
                ) {
                    setDifferentStoreTicket(barcode);
                } else {
                    paperReceiptStart({
                        ticketNumber: barcode,
                        receiptType: RECEIPT_TYPE.ticket,
                    });
                }
            } else if (barcode[0].toUpperCase() === 'Q') {
                paperReceiptQuoteStart({
                    quoteNumber: barcode,
                    receiptType: RECEIPT_TYPE.quote,
                });
            } else if (!(basket.ticket && basket.ticket.ticketNumber)) {
                setInputValue(barcode);
                getBarcodeProductStart({
                    barcode,
                    scanType: activeAction === 'sousTotal' ? '' : activeAction,
                });
                setStoreGiftCode('');
            }
            setInputValue('');
            setInputScanValue('');
        }
    };

    const inputScanBarcode = ({ target }) => {
        if (activeAction) return;
        const { value = '' } = target;
        if (
            value.length === 2 &&
            ((inputScanValue &&
                (inputScanValue[0].toUpperCase() === 'G' ||
                    inputScanValue[0].toUpperCase() === 'R')) ||
                value.toUpperCase().includes('G') ||
                value.toUpperCase().includes('R'))
        ) {
            setInputScanValue((prev) => prev + value.replace(/[0-9]/g, ''));
        }
    };

    const handleChangeNumber = (key, e) => {
        if (key !== 'backspace') {
            if (
                inputValue.length === 5 &&
                (inputScanValue + inputValue).includes(terminalCode)
            ) {
                setInputValue(
                    (prev) =>
                        inputScanValue.toUpperCase() +
                        prev +
                        key.replace('.', ','),
                );
            } else {
                setInputValue((prev) => prev + key.replace('.', ','));
            }
        } else {
            let val = inputValue.substring(0, inputValue.length - 1);
            if (inputValue.length === 1 && activeAction === 'gift') {
                val = 'G';
            }
            setInputValue(val);
            setInputScanValue('');
        }
    };

    const getCurencyElement = () => {
        return <span className='currency-symbol'> {getCurrency(currencyId)})</span>
    }

    return (
        <div className="calculator" ref={divRef}>
            <div className="calculator--display">
                <span
                    className={`calculator--display--title  ${
                        totalQuantity < 0 ? 'revert' : ''
                    }`}
                >
                    {replaceDote(totalQuantity)}{' '}
                    {totalQuantity < 2 ? (
                        <span className="text">
                            {i18next.t('product.basket.23')}
                        </span>
                    ) : (
                        <span className="text">
                            {i18next.t('product.basket.12')}
                        </span>
                    )}
                </span>
                <span
                    className={`calculator--display--price ${
                        price < 0 ? 'revert' : ''
                    }`}
                >
                    <span
                        className={`calculator--display--title  ${
                            price < 0 ? 'revert' : ''
                        }`}
                    >
                        <span className="text">
                            {i18next.t('Terminal.Receipt.Sale.Total')}
                        </span>{' '}
                        :{' '}
                    </span>{' '}
                    {replaceComma(price)}
                    <span className={`currency ${getCurrency()}`}>
                        {getCurrency()}
                    </span>
                </span>
            </div>
            {firstName || lastName ? (
                <div className="calculator--client" onClick={showUser}>
                    {firstName} {lastName}{' '}
                    {loyaltyPoints
                        ? `(${threeDigitSets(loyaltyPoints)} PT / ${threeDigitSets(fixedInHundredth(loyaltyValue))}`
                        : ''}
                        {loyaltyPoints && getCurencyElement()}
                </div>
            ) : (
                <button
                    onClick={() =>
                        history.push(
                            `${getMenuItemCanonicalUrl(
                                'page',
                                'clients',
                                menu,
                            )}`,
                        )
                    }
                    className="calculator--client noUser"
                >
                    {i18next.t('Terminal.dashboards.noUser')}
                </button>
            )}
            <div className="calculator--numbers">
                <div className="calculator--numbers--row">
                    <BarcodeReader onScan={handleScan} />
                    <KeyboardEventHandler
                        handleKeys={[
                            '0',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '.',
                            'backspace',
                        ]}
                        onKeyEvent={(key, e) => handleChangeNumber(key, e)}
                    >
                        <input
                            className="calculator--numbers--row--title"
                            placeholder={i18next.t('Terminal.Block3.1')}
                            value={inputValue || '0'}
                            disabled={false}
                            onChange={inputScanBarcode}
                        />
                    </KeyboardEventHandler>
                </div>
                {calculator.map((items, i) => {
                    return (
                        <div className="calculator--numbers--row" key={i}>
                            {items.map((item, key) => {
                                return (
                                    <div
                                        key={`${item}-${key}`}
                                        className={`calculator--numbers--row--item ${
                                            item === inputValue ? 'active' : ''
                                        }`}
                                        onClick={handleChangeValue(item)}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <div className="calculator--numbers--row">
                    <div
                        className="calculator--numbers--row--item controler submit"
                        onClick={setCalculator}
                    >
                        {i18next.t('Terminal.Block3.2')}
                    </div>
                    <div
                        className="calculator--numbers--row--item controler cancle"
                        onClick={handleReset}
                    >
                        {i18next.t('Terminal.Block3.3')}
                    </div>
                </div>
            </div>
            {storeGiftCode && (
                <AddGift
                    storeGiftCode={storeGiftCode}
                    handleClose={() => setStoreGiftCode('')}
                />
            )}
            {differentStoreTicket && (
                <ConfirmPopup
                    handleCancel={() => setDifferentStoreTicket('')}
                    handleConfirm={() => {
                        paperReceiptStart({
                            ticketNumber: differentStoreTicket,
                            receiptType: RECEIPT_TYPE.ticket,
                        });
                        setDifferentStoreTicket('');
                    }}
                    text={i18next.t('Terminal.confirm.20')}
                    classN="message-error"
                />
            )}
        </div>
    );
};

export default Calculator;
