import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import i18next from "i18next";
import FormStep1 from "./components/formStep1";
import FormStep2 from "./components/formStep2";
import {UserActionCreators} from "../redux/actions";
import {
    getStep,
    getFirstStep,
    getPrivateLoginStatus,
    getLoginStatus,
    isPrivateAuthenticated,
    getPrivateLoading
} from "../redux/selectors";
import logo from '../../../assets/img/waveum/waveum_white_logo1.png';
import waveumlogo from '../../../assets/img/waveum/waveum_main_logo.png';
import './login.scss';
import { getCookieByKey, getLangPrefix } from "../../../helpers/helpers";
import { WAVEUM_LOGIN } from "../../../utils/constants/service";

interface Props {
    privateAuthenticated: boolean,
    step: number,
    firstStep: object,
    privateLoginStatus: boolean,
    loginStatus: boolean,
    isPrivateLoading: boolean,
}

interface State {
    user: {
        username: string,
        password: string,
        verifyCode: string,
        rememberMe: boolean
    };
}

class Login extends React.Component<Props & typeof UserActionCreators, State> {

    state: State = {
        user: {
            username: "",
            password: "",
            verifyCode: "",
            rememberMe: false
        }
    };

    componentDidMount(): void {
        const privateToken = getCookieByKey("privateToken");

        if (privateToken) {
            this.props.loginFromWaveumStart(privateToken);
        } else {
            window.open(`${WAVEUM_LOGIN}${getLangPrefix()}`, "_self");
        }
    }

    handleChange = (e, {name, value, checked}) => {
        if (checked !== undefined && name === "rememberMe") {
            value = checked;
        }
        this.setState((prevState: State) => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }))
    };

    handleSubmit = () => {
        const {step, firstStep} = this.props;
        const {user} = this.state;
        const {callbacks} = firstStep;
        if(step === 2 && callbacks){
            const data = {...firstStep, callbacks: [...callbacks]}
            if(data.callbacks[0].input && data.callbacks[0].input[0]) {
                data.callbacks[0].input[0].value = user.verifyCode
            }
            this.props.privateLoginStart(data, user.password)
        }else {
            const data = {
                username: CryptoJS.SHA256(user.username.toLowerCase()).toString(),
                password: user.password,
                rememberMe: user.rememberMe
            }
            this.props.loginStart(data)
        }
    };

    render(): React.ReactNode {
        if (this.props.privateAuthenticated  && sessionStorage.getItem("privateToken")) {
            return <Redirect to={`${getLangPrefix()}`}/>
        }
        const {step, isPrivateLoading} = this.props;
        const {user} = this.state;

        return (
            <div className="loginContainer">
                <div className="login-header">
                    <a href="https://www.waveum.com" >
                        <img src={logo} className="head-logo" alt="head-logo" />
                    </a>
                </div>
                <div className="padding_none login-page-outer">
                    <div className=" padding_none login-part">
                        <div className=" padding_none tab-content">
                            <div id="home" className="tab-pane fade in active">
                                <div className="padding_none login-inner">
                                    <Link to={"/"} className="waveum-logo">
                                        <img src={waveumlogo} alt="" />
                                    </Link>
                                    <div>
                                        {step === 2 ?
                                            <FormStep2
                                                handleSubmit={this.handleSubmit}
                                                handleChange={this.handleChange}
                                                user={user}
                                                isPrivateLoading={isPrivateLoading}
                                            />
                                            :
                                            <FormStep1
                                                handleSubmit={this.handleSubmit}
                                                handleChange={this.handleChange}
                                                user={user}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="login-footer-outer">
                    <div className="footer-inner">
                        <ul className="justify-content-center dflex">
                            <li>{i18next.t('Terminal.login.15')}</li>
                        </ul>
                    </div>
                </footer>

            </div>

        )
    }
}

const mapStateToProps = (state: any) => ({
    privateAuthenticated: isPrivateAuthenticated(state),
    step: getStep(state),
    firstStep: getFirstStep(state),
    privateLoginStatus: getPrivateLoginStatus(state),
    loginStatus: getLoginStatus(state),
    isPrivateLoading: getPrivateLoading(state),
});

const mapDispatchToProps = (dispatch): typeof UserActionCreators => {
    return bindActionCreators(UserActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);

