import { NullBelegTypes, NullBelegActionCreators } from './actions';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import axios from 'axios';
import i18next from 'i18next';
import {
    CREATE_STORE_SALE,
    CREATE_STORE_SALE_FISCAL,
    getAxiosHeaders,
} from '../../../utils/constants/service';
import {
    DEFAULT_CURRENCY,
    TICKET_TYPE,
} from '../../../utils/constants/variables';
import { getAccountResource } from '../../login/redux/selectors';

export function* printNullBeleg(params) {
    const body = params.body || {
        orderStatus: 'SOL',
        paymentTermCode: '001',
        saleQuantity: 0,
        storeClientId: null,
        webDiscountId: null,
        saleTotal: 0,
        storeGifts: [],
        prepayment: false,
        saleTotalTax: 0,
        taxFree: false,
        storeSalePayments: [
            {
                paymentMethodCode: '005',
                paymentMethodType: 'P',
                saleAmount: 0,
                saleTax: 0,
            },
        ],
        sendEmail: false,
        storeSaleLineItems: [
            {
                quantity: 1,
                currency: 'EUR',
                itemType: 'ZERO_VALUE',
                orderLineId: 1,
            },
        ],
    };

    try {
        const headers = {
            headers: {
                ...getAxiosHeaders().headers,
                currency: DEFAULT_CURRENCY,
            },
        };

        const data = yield call(() => {
            return axios
                .post(`${CREATE_STORE_SALE}`, body, headers)
                .then((res) => res.data);
        });

        data.paymentTermCode = body.paymentTermCode;

        const { ticketNumber = '' } = data;

        if (!ticketNumber) {
            throw new Error(i18next.t('Terminal.payment.9'));
        }

        const fiscalData = {
            ticketNumber,
            zeroValueReceipt: true,
        };

        yield call(() => {
            return axios
                .post(
                    `${CREATE_STORE_SALE_FISCAL}`,
                    fiscalData,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        const { printer } = yield select(getAccountResource);

        if (printer) {
            yield put(
                NullBelegActionCreators.printTicketStart({
                    ticketNumber,
                    receiptType: 'ZERO_RECEIPT',
                    type: TICKET_TYPE.ticket,
                    isPay: false,
                }),
            );
        }
        yield put(NullBelegActionCreators.printNullBelegSuccess(data));
    } catch (e) {
        yield put(NullBelegActionCreators.printNullBelegFailure(e.message));
    }
}

export function* nullBelegSaga() {
    yield takeLatest(NullBelegTypes.PRINT_NULL_BELEG_START, printNullBeleg);
}

export default nullBelegSaga;
