import axios from 'axios';
import { getApiPrefix } from '../../helpers/helpers';
import { BRAND_ID, DEFAULT_CURRENCY, STORE_COUNTRY } from './variables';

export const TERMINAL_URL = process.env.REACT_APP_TERMINAL_URL;
export const BASE_URL = process.env.REACT_APP_EXTERNAL_GATEWAY_URL
    ? process.env.REACT_APP_EXTERNAL_GATEWAY_URL
    : process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL
    : process.env.PUBLIC_URL;
export const EXTERNAL_GATEWAY_URL_PREFIX = `/externalgateway`;
export const BASE_API_GATEWAY = process.env.REACT_APP_EXTERNAL_GATEWAY_URL
    ? process.env.REACT_APP_EXTERNAL_GATEWAY_URL
    : BASE_URL + EXTERNAL_GATEWAY_URL_PREFIX;

export const BASE_PRODUCT_SERVICE_URL = BASE_API_GATEWAY + `/productservice`;
export const PRODUCT_SERVICE_API_URL =
    (process.env.REACT_APP_PRODUCT_SERVICE_URL
        ? process.env.REACT_APP_PRODUCT_SERVICE_URL
        : BASE_PRODUCT_SERVICE_URL) + `/api/`;
export const PRODUCT_SERVICE_API_EXTERNAL_URL =
    PRODUCT_SERVICE_API_URL + `external/`;

export const DOWNLOAD_ID = PRODUCT_SERVICE_API_URL + 'files/download/invoice/';
export const MENU_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + 'menu';
export const CONTENT_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + 'content/grid/';
export const PRODUCTS_URL =
    PRODUCT_SERVICE_API_EXTERNAL_URL + 'product/catalogue/menu/';
export const PRODUCTS_CATALOGUE_URL =
    PRODUCT_SERVICE_API_EXTERNAL_URL + 'product/catalogue';
export const PRODUCT_URL = PRODUCT_SERVICE_API_EXTERNAL_URL + 'product/';
export const PRODUCT_CARE_URL = PRODUCT_URL + 'attributes/care/';
export const PRODUCT_COMPOSITION_URL =
    PRODUCT_SERVICE_API_EXTERNAL_URL + 'product/attributes/composition/';
export const PRODUCT_CANONICAL_URL =
    PRODUCT_SERVICE_API_EXTERNAL_URL + 'product/canonical-url/';
// export const MAILJET_SUBSCRIBE_URL = BASE_API_GATEWAY + "/api/mailinglist/subscribe";
export const LOCATIONSEARCH_URL =
    PRODUCT_SERVICE_API_EXTERNAL_URL + 'store/nearest/';
export const WEB_CLIENT =
    BASE_API_GATEWAY + '/api/external-secured/web-clients';

export const TERMINAL_GATEWAY_URL = TERMINAL_URL + '/gateway/api';
export const TERMINAL_EXTERNALGATEWAY_URL =
    TERMINAL_URL + '/externalgateway/api/';
export const USER_AUTHENTICATE_URL_TESTNET_LOGIN =
    TERMINAL_GATEWAY_URL + '/associate/authenticate';
export const USER_AUTHENTICATE_URL_TESTNET = TERMINAL_URL + '/api/request-otp';
export const USER_AUTHENTICATE_URL_TESTNET_OPT =
    TERMINAL_URL + '/api/submit-otp';
export const USER_AUTHENTICATE_URL_TESTNET_ACCOUNT =
    TERMINAL_URL + '/api/account';
export const USER_AUTHENTICATE_URL_TESTNET_PRIVATE_KEY =
    TERMINAL_URL + '/api/privatekey';
export const USER_TERMINAL_DETAILES_URL_TESTNET =
    TERMINAL_URL + `/api/terminal/details?brandApp=${BRAND_ID}`;

export const STORE_SECURED = TERMINAL_URL + '/api/store-secured/';
export const UPDATE_STATUS = STORE_SECURED + 'store-orders';
export const STORE_WEB_CLIENTS_URL =
    TERMINAL_EXTERNALGATEWAY_URL + 'store/web-clients';
export const STORE_SALE_URL = STORE_SECURED + 'store-sales/';
export const STORE_SALE_PAPER_RECEIPT = STORE_SALE_URL + 'paper-receipt';
export const CREATE_STORE_SALE_FISCAL =
    STORE_SALE_URL + 'create-store-sale-fiscal';
export const FISCAL_TSS_DETAILS = STORE_SECURED + 'fiskal/tssDetails';
export const FISCAL_TSS_DETAILS_AT =
    TERMINAL_URL + '/gateway/billingpaymentservice/api/store-secured/rks/info';
export const FISCAL_START_TRANSACTION_DE =
    STORE_SECURED + 'fiskal/startTransaction';
export const FISCAL_FINISH_TRANSACTION_DE =
    STORE_SECURED + 'fiskal/finishTransaction';
export const FISCAL_CLOSE_TRANSACTION_DE =
    STORE_SECURED + 'fiskal/closeStartedTransactions';
export const TERMINAL_CONTROLLER_URL = STORE_SECURED + 'terminal/';
export const STOCK_URL = STORE_SECURED + 'store-products/dashboard';
export const STOCK_COUNTERS = STORE_SECURED + 'stores/stock-counters';
export const CREATE_STOCK_URL =
    STORE_SECURED + 'store-products/create-store-product';
export const CLIENT_URL = STORE_SECURED + 'store-clients/all';
export const SINGLE_CLIENT_URL = STORE_SECURED + 'store-clients/';
export const STORE_ORDERS_API = STORE_SECURED + 'store-orders/';
export const WEB_ORDER_API = STORE_ORDERS_API + 'dis-web-orders';
export const CREATE_ORDER_SHIPMENT = STORE_ORDERS_API + 'create-order-shipment';
export const BARCODE_PRODUCTS =
    TERMINAL_GATEWAY_URL + '/store-secured/store-products/by-barcode';
export const CREATE_STORE_SALE = STORE_SALE_URL + 'create-store-sale';
export const ALL_PRODUCTS_SOLD = STORE_SALE_URL + 'all-products-sold';
export const STORE_FORM = STORE_SECURED + 'stores/form';
export const STORE_BY_COUNTRY = STORE_SECURED + 'stores/by-country';
export const STORE_GIFTS_URL = STORE_SECURED + 'store-gifts/dashboard';
export const SINGLE_STORE_GIFTS_URL = STORE_SECURED + 'store-gifts/';
export const STORE_PAPER_GIFT_URL = STORE_SECURED + 'store-gifts/paper-gift';
export const CREATE_STORE_GIFT_URL = STORE_SECURED + 'store-gifts/create-store-gift';
export const CREATE_CLIENT_SHIPPING = STORE_SECURED + 'shipping/ship';
export const CREATE_CLIENT_BOOK = STORE_SECURED + 'shipping/book-pickup';
export const CREATE_STORE_CLIENT =
    TERMINAL_GATEWAY_URL + '/store-secured/store-clients/create-store-client';
export const CREATE_STORE_CLIENT_ADDRESS =
    TERMINAL_GATEWAY_URL +
    '/store-secured/store-clients/create-store-client-address';
export const STORE_TRANSFERS_URL = STORE_SECURED + 'store-transfers/dashboard';
export const CREATE_STORE_TRANSFER_URL =
    STORE_SECURED + 'store-transfers/create-store-transfer';
export const UPDATE_STORE_TRANSFER_URL =
    STORE_SECURED + 'store-transfers/update-store-transfer-status';
export const CREATE_STORE_TRANSFER_SHIPMENT =
    STORE_SECURED + 'store-transfers/create-store-transfer-shipment';
export const GET_STORE_TRANSFER_SHIPMENT =
    STORE_SECURED + 'store-transfers/get-store-transfer-shipment';
export const DELIVERIES_URL = STORE_SECURED + 'store-deliveries/dashboard';
export const ADD_CLIENT_TICKET = STORE_SECURED + 'store-sales';
export const CREATE_DELIVERIES_URL =
    STORE_SECURED + 'store-deliveries/create-store-delivery';
export const PRODUCT_DELIVERY = STORE_SECURED + 'store-deliveries/product-delivery'
export const CREATE_QUOTE_URL =
    STORE_SECURED + 'store-quotes/create-store-quote';
export const DELETE_QUOTE_URL =
    STORE_SECURED + 'store-quotes/delete-store-quote';
export const QUOTE_URL = STORE_SECURED + 'store-quotes/';
export const PAPER_QUOTE_URL = STORE_SECURED + 'store-quotes/paper-quote';
export const STORE_RETURN_URL = STORE_SECURED + 'returns/return-in-store';
export const UPDATE_STORE_RETURN_URL = STORE_SECURED + 'returns/update-status';
export const CURRENT_DAY_SALE = STORE_SECURED + 'terminal/current-day-sales';
export const NOTIFICATION_COUNTERS_URL =
    STORE_SECURED + 'stores/notification-counters';
export const REPORTS_XZ_URL = STORE_SECURED + 'reports/x-z-report';
export const REPORTS_PAYMENTS = STORE_SECURED + 'reports/payments';
export const MONTHLY_REPORT = STORE_SECURED + 'reports/monthly-report';
export const INVENTORY_URL =
    STORE_SECURED + 'store-inventory-requests/dashboard';
export const SINGLE_INVENTORY_URL = STORE_SECURED + 'store-inventory-requests/';
export const CREATE_INVENTORY_URL =
    STORE_SECURED + 'store-inventory-requests/create-store-inventory-request';
export const UPDATE_INVENTORY_URL =
    STORE_SECURED +
    'store-inventory-requests/update-store-inventory-request-status';
export const ASSOCIATE_SHIFTS_END_URL =
    STORE_SECURED + 'associate-shifts/end-associate-shift';
export const VALIDATE_DISCOUNT_URL =
    STORE_SECURED + 'web-discount/validate-discount';
export const PRINTER_URL = 'http://localhost:3001/api/v1/printer';
export const UPDATE_STORE_DELIVERY =
    TERMINAL_GATEWAY_URL +
    '/store-secured/store-deliveries/update-store-delivery-status';
export const GET_BARCODE_BY_PARSEL_NUMBER =
    TERMINAL_GATEWAY_URL +
    '/store-secured/store-deliveries/barcodes-by-parcel-number';
export const CREATE_TICKET_URL = STORE_SECURED + 'tickets/create-ticket';
export const BUSINESS_PREMISES_LOGS_URL =
    STORE_SECURED + 'fiskal/businessPremisesLogs';
export const BRANDS_NAME_URL = STORE_SECURED + 'brands/customer-brands';
export const SUBSCRIBE = STORE_SECURED + 'mailinglist/subscribe';
export const TOP_10_CLIENTS_URL =
    BASE_API_GATEWAY +
    '/storeservice/api/store-secured/search/top-10-store-clients';
export const ELASTIC_INDEX_SERVICE = BASE_API_GATEWAY + '/elasticindexservice/api/elastic/product/index';
export const IMPORT_PRODUCT_DASHBOARD = STORE_SECURED + 'store-products/import-product-dashboard'
export const IMPORT_PRODUCT_DETAILS = STORE_SECURED + 'store-products/import-product-details'
export const IMPORT_PRODUCTS = STORE_SECURED + 'store-products/import-products'
export const UPDATE_PRODUCT_STOCK = STORE_SECURED + 'store-products/update-product-store-inventory';
export const UPDATE_PRODUCT_PRICE = STORE_SECURED + 'store-products/update-product-price';
export const UPDATE_ELASTIC_PRODUCT_INDEX = BASE_URL + EXTERNAL_GATEWAY_URL_PREFIX + '/elasticindexservice/api/elastic/product/index';
export const UPLOAD_STORE_INVENTORY = STORE_SECURED + 'stores/upload-store-inventory-file';
export const CALENDAR_API = STORE_SECURED + 'brands/create-brand-service-calendar';
export const GET_CALENDAR_API = STORE_SECURED + 'brands/get-brand-service-calendars';
export const CREATE_STORE_ASSOCIATE = STORE_SECURED + 'store-associates/create-store-associate';
export const GET_STORE_ASSOCIATES = STORE_SECURED + 'store-associates/get-store-associates-by-store-code';
export const GET_ASSOCIATE_SERVICES = STORE_SECURED + 'store-associates/get-services'
export const ADD_SERVICES = STORE_SECURED + 'store-associates/add-services';
export const CREATE_ASSOCIATE_BRAND_SERVICE = STORE_SECURED + 'brands/create-brand-service'
export const GET_ASSOCIATE_BRAND_SERVICE = STORE_SECURED + 'brands/get-brand-service';
export const CREATE_ASSOCIATE_PHOTO = STORE_SECURED + 'store-associates/create-store-associate-photo';
export const UPDATE_PRODUCT_VISUAL = STORE_SECURED + 'store-products';
export const STORE_CLIENTS = STORE_SECURED + 'store-clients/';
export const GET_TICKETS = STORE_SECURED + 'tickets/get-tickets';
export const GET_BRAND_CALENDAR_BY_ID = STORE_SECURED + 'brands/get-brand-service-calendar-id';
export const WAVEUM_LOGIN = process.env.REACT_APP_WAVEUM_LOGIN;

export const getAxiosHeaders = (currency?) => {
    const privateToken = sessionStorage.getItem('privateToken');
    const token = localStorage.getItem('token');
    const headers: any = {
        headers: {
            Authorization: `Bearer ${privateToken}`,
            'Associate-Authorization': `${token}`,
        },
    };
    if (currency) {
        headers.headers.currency = currency;
    }
    return headers;
};

export const printerOpenCashDrawer = () =>
    fetch(`${PRINTER_URL}/open-cash-drawer`).catch((e) => console.log(e));

export const printerCashDrawer = (body) =>
    fetch(PRINTER_URL, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body,
    }).catch((e) => console.log(e));

export const openCashDrawer = () =>
    axios.post(
        `${STORE_SECURED}terminal/create-drawer-opening`,
        {},
        getAxiosHeaders(),
    );

export const getProductDataByBarcode = async (barcode, details) =>
    await axios
        .get(
            `${BARCODE_PRODUCTS}/${barcode}?language=${getApiPrefix()}&currency=${DEFAULT_CURRENCY}&storeCode=${
                details.storeCode
            }&country=${details.storeDetails.country}&promoCluster=${
                details.storeDetails.promotionCluster
            }`,
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const redeemGiftStart = async (body, storeClientId) => {
    let data;
    try {
        await axios.put(
            `${SINGLE_CLIENT_URL}${storeClientId}/redeem-store-client-loyalty`,
            {},
            getAxiosHeaders(),
        );
        data = await axios.post(CREATE_STORE_GIFT_URL, body, getAxiosHeaders())
            .then(res => res.data);
        return data;
    } catch(e) {
        return data;
    }
};

export const getXreportData = (reportType = 'X', saleDay = '', timeFrame?) =>
    axios
        .get(
            `${REPORTS_XZ_URL}/?reportType=${reportType}${
                saleDay ? `&saleDay=${saleDay}` : ''
            }${timeFrame ? `&timeFrame=${timeFrame}` : ''}`,
            getAxiosHeaders(DEFAULT_CURRENCY),
        )
        .then((res) => res.data);

export const getPaperReceiptData = async (ticketNumber, receiptType) =>
    await axios
        .get(
            `${STORE_SALE_PAPER_RECEIPT}/?ticketNumber=${ticketNumber.toUpperCase()}&receiptType=${receiptType}`,
            getAxiosHeaders(DEFAULT_CURRENCY),
        )
        .then((res) => res.data);

export const getPaperReceiptClient = async (clientId) =>
    await axios
        .get(
            `${SINGLE_CLIENT_URL}${clientId}?language=${getApiPrefix()}`,
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const createTicket = async (body) =>
    await axios
        .post(CREATE_TICKET_URL, body, getAxiosHeaders())
        .then((res) => res.data);

export const businessPremisesLogs = async () =>
    await axios
        .get(`${BUSINESS_PREMISES_LOGS_URL}`, getAxiosHeaders())
        .then((res) => res.data);

export const associateShiftsEnd = async () =>
    await axios
        .post(ASSOCIATE_SHIFTS_END_URL, {}, getAxiosHeaders())
        .then((res) => res.data);

export const updateFiscalTransaction = async (
    ticketNumber,
    transactionNumber,
) =>
    await axios
        .put(
            `${STORE_SALE_URL}${ticketNumber}/update-fiscal-transaction?transactionNumber=${transactionNumber}`,
            {},
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const fetchSingleStoreGift = async (code) =>
    await axios
        .get(
            `${SINGLE_STORE_GIFTS_URL}${code.toUpperCase()}`,
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const fetchBrandsName = async () =>
    await axios
        .get(`${BRANDS_NAME_URL}`, getAxiosHeaders())
        .then((res) => res.data);

export const createGift = async (body) =>
    await axios
        .post(CREATE_STORE_GIFT_URL, body, getAxiosHeaders())
        .then((res) => res.data);

export const fetchPaperReceipt = async (
    ticketNumber,
    receiptType,
    currency = DEFAULT_CURRENCY,
) =>
    await axios
        .get(
            `${STORE_SALE_PAPER_RECEIPT}/?ticketNumber=${ticketNumber.toUpperCase()}&receiptType=${receiptType}`,
            getAxiosHeaders(currency),
        )
        .then((res) => res.data);

export const closeFiskalTransactionDe = async () =>
    await axios
        .post(FISCAL_CLOSE_TRANSACTION_DE, {}, getAxiosHeaders())
        .then((res) => res.data);

export const createStoreTransfer = async (body) =>
    await axios
        .post(
            CREATE_STORE_TRANSFER_URL,
            body,
            getAxiosHeaders(DEFAULT_CURRENCY),
        )
        .then((res) => res.data);

export const fetchStoreTransfer = async () =>
    await axios
        .get(
            `${STORE_TRANSFERS_URL}?language=${getApiPrefix()}`,
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const updateStoreTransfer = async (code, status) =>
    await axios
        .put(
            `${UPDATE_STORE_TRANSFER_URL}?storeTransferCode=${code}&storeTransferStatus=${status}`,
            {},
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const fetchSalesList = async (body = {}) =>
    await axios
        .post(`${STORE_SALE_URL}dashboard`, body, getAxiosHeaders())
        .then((res) => res.data);

export const fetchSale = async (ticketNumber, currency, storeCode) =>
    await axios
        .get(
            `${STORE_SALE_URL}${ticketNumber}/details?storeCode=${storeCode}&language=${getApiPrefix()}`,
            getAxiosHeaders(currency),
        )
        .then((res) => res.data);

export const setOrderStatus = (orderId, status, generateOrderFile = false) =>
    axios
        .put(
            `${UPDATE_STATUS}/${orderId}/update-status?orderStatus=${status}&generateOrderFile=${generateOrderFile}`,
            {},
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const subscribeEmail = async (body = {}) =>
    await axios
        .post(SUBSCRIBE, body, getAxiosHeaders())
        .then((res) => res.data);

export const updatePaymentMethod = (ticketNumber, oldPaymentCode, newCode) =>
    axios
        .put(
            `${STORE_SALE_URL}${ticketNumber}/update-payment-method?oldPaymentCode=${oldPaymentCode}&newPaymentCode=${newCode}`,
            {},
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const fetchTop10Clients = async (searchCriteria) =>
    await axios
        .get(
            `${TOP_10_CLIENTS_URL}?searchCriteria=${searchCriteria}`,
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const fetchStores = async (country = STORE_COUNTRY) =>
    await axios
        .get(`${STORE_BY_COUNTRY}/${country}`, getAxiosHeaders())
        .then((res) => res.data);

export const createOrderShipment = async (body = {}) =>
    await axios
        .post(CREATE_ORDER_SHIPMENT, body, getAxiosHeaders())
        .then((res) => res.data);

export const getMonthlyReport = async ({ month, year }) =>
    await axios
        .get(
            `${MONTHLY_REPORT}/?year=${year}&month=${month}`,
            getAxiosHeaders(),
        )
        .then((res) => res.data);

export const createStoreAssociate = (body) => {
    return axios.post(CREATE_STORE_ASSOCIATE, body, getAxiosHeaders())
        .then((res) => res.data);
}

export const getAssociateServices = () => {
    return axios.get(GET_ASSOCIATE_SERVICES, getAxiosHeaders())
        .then((res) => res.data);
}

export const addAssociateServices = (body) => {
    return axios.post(ADD_SERVICES, body, getAxiosHeaders())
        .then((res) => res.data);
}

export const fetchStoreAssociates = (storeCode) => {
    return axios.get(`${GET_STORE_ASSOCIATES}?storeCode=${storeCode}`, getAxiosHeaders())
        .then((res) => res.data);
}

export const getAssociateBrandService = (associateCode) => {
    return axios.get(`${GET_ASSOCIATE_BRAND_SERVICE}?associateCode=${associateCode}`, getAxiosHeaders())
        .then((res) => res.data);
}

export const createAssociateBrandService = (body) => {
    return axios.post(`${CREATE_ASSOCIATE_BRAND_SERVICE}`, body, getAxiosHeaders())
        .then((res) => res.data);
}

export const getBrandServiceCalendar = (storeCode) => {
    return axios.get(`${GET_CALENDAR_API}?storeCode=${storeCode}`, getAxiosHeaders())
        .then((res) => res.data)
}

export const createAssociatePhoto = (formData) => {
    return axios.post(CREATE_ASSOCIATE_PHOTO, formData, getAxiosHeaders())
        .then((res) => res.data);
}

export const updateProductVisual = (formData, productId) => {
    return axios.post(`${UPDATE_PRODUCT_VISUAL}/${productId}/product-visual/upload`, formData, getAxiosHeaders())
        .then((res) => res.data);
}

export const getStoreClientById = (id) => {
    return axios.get(`${STORE_CLIENTS}${id}?language=${getApiPrefix()}`, getAxiosHeaders())
        .then((res) => res.data)
}

export const fetchTickets = (storeCode) => {
    return axios.get(`${GET_TICKETS}?language=${getApiPrefix()}&storeCode=${storeCode}`, getAxiosHeaders())
        .then((res) => res.data)
}

export const getBrandCalendarById = (orderNumber) => {
    return axios.get(`${GET_BRAND_CALENDAR_BY_ID}?orderNumber=${orderNumber}`, getAxiosHeaders())
        .then((res) => res.data)
}
