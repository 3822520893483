import {createReducer} from 'reduxsauce';
import {TransfersTypes} from './actions';
import {getActiveBasketName} from "../../../helpers/helpers";


export interface ITransfersState {
    shipmentError: boolean,
    transfersData: any,
    isLoading: boolean,
    isCreateLoading: boolean,
    createdResponse: object,
    createdStatus: string,
    updatedStatus: string,
    isUpdateLoading: boolean,
    isShipmentLoading: boolean,
    isTransferShipmentLoading: boolean,
    shipmentResult: any,
    transferShipments: any[];
    transferBookResult: any,
}

export const  transfersContent: ITransfersState = {
    shipmentError: false,
    transfersData: [],
    isLoading: false,
    isCreateLoading: false,
    isUpdateLoading: false,
    isShipmentLoading: false,
    isTransferShipmentLoading: false,
    createdResponse: {},
    shipmentResult: {},
    createdStatus: "",
    updatedStatus: "",
    transferShipments: [],
    transferBookResult: {}
};

export const transfersData = (state) => {
    return {...state, isLoading: true};
};

export const transfersSuccess = (state, action) => {
    return {...state, transfersData: action.data, isLoading:false};
};

export const transfersFailure = (state, action) => {
    return {...state, error: action.error, transfersData: [], isLoading:false};
};

export const createTransfer = (state) => {
    return {...state, isCreateLoading: true, createdStatus: ""};
};

export const createTransferSuccess = (state, action) => {
    return {...state, createdResponse: action.data, isCreateLoading:false, createdStatus: "success"};
};

export const createTransferFailure = (state, action) => {
    return {...state, error: action.error, createdResponse: {}, isCreateLoading:false, createdStatus: "failed"};
};

export const updateTransfer = (state) => {
    return {...state, updatedStatus: "", isUpdateLoading: true};
};

export const updateTransferSuccess = (state, action) => {
    return {...state, updatedStatus: action.data ? "success" : "failed", isUpdateLoading: false};
};

export const updateTransferFailure = (state, action) => {
    return {...state, error: action.error, updatedStatus: "failed", isUpdateLoading: false};
};

export const resetUpdateStatus = (state) => {
    return {...state,  updatedStatus: ""};
}

export const resetUpdateResponse = (state) => {
    return {...state,  createdResponse: {}};
}

export const getTransferShipmentSuccess = (state, action) => {
    return {...state, transferShipments: action.data, isShipmentLoading: false};
}

export const getTransferShipmentFailure = (state) => {
    return {...state, transferShipments: [], isShipmentLoading: false};
}

export const createStoreShipment = (state) => {
    return {...state, isShipmentLoading: true};
}

export const createStoreShipmentSuccess = (state, action) => {
    return {...state, transferBookResult: action.data, isTransferShipmentLoading: false, isShipmentLoading: false, shipmentError: false};
}

export const createStoreShipmentFailure = (state) => {
    return {...state, transferBookResult: {}, isTransferShipmentLoading: false, isShipmentLoading: false, shipmentError: true};
}

export const createStoreBook = (state) => {
    return {...state, isTransferShipmentLoading: true};
}

export const emptyShipmentError = (state) => {
    return {...state, shipmentError: false};
}

export const handlers = {
    [TransfersTypes.TRANSFERS_START]: transfersData,
    [TransfersTypes.TRANSFERS_SUCCESS]: transfersSuccess,
    [TransfersTypes.TRANSFERS_FAILURE]: transfersFailure,
    [TransfersTypes.CREATE_TRANSFER_START]: createTransfer,
    [TransfersTypes.CREATE_TRANSFER_SUCCESS]: createTransferSuccess,
    [TransfersTypes.CREATE_TRANSFER_FAILURE]: createTransferFailure,
    [TransfersTypes.UPDATE_TRANSFER_START]: updateTransfer,
    [TransfersTypes.UPDATE_TRANSFER_SUCCESS]: updateTransferSuccess,
    [TransfersTypes.UPDATE_TRANSFER_FAILURE]: updateTransferFailure,
    [TransfersTypes.RESET_UPDATE_STATUS]: resetUpdateStatus,
    [TransfersTypes.RESET_UPDATE_RESPONSE]: resetUpdateResponse,
    [TransfersTypes.CREATE_STORE_TRANSFER_SHIPMENT_SUCCESS]: createStoreShipmentSuccess,
    [TransfersTypes.CREATE_STORE_TRANSFER_SHIPMENT_FAILURE]: createStoreShipmentFailure,
    [TransfersTypes.CREATE_STORE_TRANSFER_SHIPMENT_START]: createStoreShipment,
    [TransfersTypes.CREATE_STORE_TRANSFER_SHIPMENT_SUCCESS]: createStoreShipmentSuccess,
    [TransfersTypes.CREATE_STORE_TRANSFER_BOOK_FAILURE]: createStoreShipmentFailure,
    [TransfersTypes.GET_TRANSFER_SHIPMENTS_SUCCESS]: getTransferShipmentSuccess,
    [TransfersTypes.GET_TRANSFER_SHIPMENTS_FAILURE]: getTransferShipmentFailure,
    [TransfersTypes.CREATE_STORE_TRANSFER_BOOK_START]: createStoreBook,
    [TransfersTypes.EMPTY_TRANSFER_SHIPMENT_ERROR_START]: emptyShipmentError,
};

export default createReducer(transfersContent, handlers);
