import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    Image,
} from '@react-pdf/renderer';
import { getDateTimestampByTime } from './../../helpers/helpers';

export const TableGenerator = ({
    ID = '',
    data = [],
    info = [],
    columns = [],
    storeName = '',
    currentDate = '',
    associateFirstName = '',
    header = [],
}) => {
    const getProductBlock = () => {
        const text = [];
        data.forEach((item, index) => {
            text.push(
                <View wrap={false} style={[styles.row]} key={index}>
                    {columns.map((col, idx) => {
                        if (col.type && col.type === 'image') {
                            return (
                                <View key={idx} style={{ width: col.width }}>
                                    <Image
                                        key={col.selector}
                                        style={[
                                            { width: '80%', height: 'auto' },
                                        ]}
                                        src={item[col.selector]}
                                    />
                                </View>
                            );
                        }
                        return (
                            <Text
                                key={idx}
                                style={[
                                    styles.product,
                                    styles.cell,
                                    col.width && { width: col.width },
                                ]}
                            >
                                {item[col.selector]}
                            </Text>
                        );
                    })}
                </View>,
            );
        });
        return text;
    };

    return (
        <Document>
            <Page style={styles.body} orientation="landscape">
                {Array.isArray(header) && header.length ? (
                    <View style={{ marginBottom: '400px' }}>
                        {header.map((el, i) => {
                            return (
                                <View key={i} style={[styles.row]}>
                                    {Array.isArray(el) && el.length ? (
                                        el.map((item, idx) => (
                                            <Text
                                                key={idx}
                                                style={[
                                                    styles.productTitle,
                                                    styles.cell,
                                                    {
                                                        textAlign: 'left',
                                                        width: `${
                                                            100 / el.length
                                                        }%`,
                                                    },
                                                ]}
                                            >
                                                {item}
                                            </Text>
                                        ))
                                    ) : (
                                        <Text
                                            style={{ marginBottom: '10px' }}
                                        ></Text>
                                    )}
                                </View>
                            );
                        })}
                    </View>
                ) : (
                    <View style={[styles.row]}>
                        <Text style={[styles.productTitle, styles.cell]}>
                            {getDateTimestampByTime(Date.now())}
                        </Text>
                        {storeName ? (
                            <Text style={[styles.productTitle, styles.cell]}>
                                {storeName}
                            </Text>
                        ) : (
                            <Text></Text>
                        )}
                        {ID ? (
                            <Text style={[styles.productTitle, styles.cell]}>
                                {ID} {currentDate ? `(${currentDate})` : ''}
                            </Text>
                        ) : (
                            <Text></Text>
                        )}
                        {associateFirstName ? (
                            <Text style={[styles.productTitle, styles.cell]}>
                                {associateFirstName}
                            </Text>
                        ) : (
                            <Text></Text>
                        )}
                    </View>
                )}
                {Array.isArray(info) && info.length ? (
                    <View style={[styles.row]}>
                        {info.map((item, index) => (
                            <Text
                                key={index}
                                style={[styles.productTitle, styles.cell]}
                            >
                                {item}
                            </Text>
                        ))}
                    </View>
                ) : (
                    <Text></Text>
                )}
                <View
                    fixed
                    style={[styles.border, styles.row, styles.bgGraye]}
                    render={() => {
                        return columns.map((item, index) => (
                            <Text
                                key={index}
                                style={[
                                    styles.productTitle,
                                    styles.cell,
                                    item.width && { width: item.width },
                                ]}
                            >
                                {item.name}
                            </Text>
                        ));
                    }}
                />
                {getProductBlock()}
                <Text 
                    style={styles.pageNumber} 
                    render={({ pageNumber }) => `${pageNumber}`} 
                    fixed 
                />
            </Page>
        </Document>
    );
};

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 15,
    },
    view: {
        width: '100%',
        height: '100%',
    },
    text: {
        fontSize: 13,
        padding: 2,
        fontFamily: 'Times-Roman',
    },

    productTitle: {
        fontSize: 12,
        padding: 2,
        fontFamily: 'Times-Roman',
    },
    product: {
        fontSize: 11,
        padding: 2,
        fontFamily: 'Times-Roman',
        borderTop: '1px solid #ccc',
        wordBreak: 'break-all',
    },
    textBold: {
        fontSize: 13,
        fontWeight: 'bold',
        fontFamily: 'Oswald',
        paddingLeft: 3,
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'stretch',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35,
    },
    cell: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        alignSelf: 'stretch',
        borderTop: '1px solid #ccc',
        wordBreak: 'break-all',
    },
    border: {
        borderColor: '#7b7b7b',
        borderStyle: 'solid',
        borderWidth: 2,
    },
    bgGraye: {
        backgroundColor: '#ccc',
    },
    w15: {
        width: '15%',
        wordBreak: 'break-all',
    },
    w20: {
        width: '20%',
        wordBreak: 'break-all',
    },
    w35: {
        width: '35%',
        wordBreak: 'break-all',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 13,
        bottom: 5,
        textAlign: 'right',
    },
});
