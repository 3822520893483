import React from 'react';
import i18next from 'i18next';
const DiscountDetail = ({ product, isPhoto = false }: any) => {
    if (product.discountDetail) {
        return (
            <div className={isPhoto ? 'isSale active' : 'discount-box'}>
                <div className={`text ${product.outlet ? 'text-outlet' : ''}`}>
                    {product.outlet
                        ? i18next.t('product.parure.19')
                        : product.listPriceAfterDiscount
                        ? 'PROMO'
                        : ''}
                </div>
                <div
                    className={`amount ${
                        product.discountDetail === '-20%'
                            ? 'greenBg'
                            : product.discountDetail === '-30%'
                            ? 'yellowBg'
                            : product.discountDetail === '-40%'
                            ? 'redBg'
                            : ''
                    }`}
                >
                    {product.discountDetail.slice(0, -1)}
                    <span className="small">
                        {product.discountDetail.slice(-1)}
                    </span>
                </div>
            </div>
        );
    }
    if (product.onSpecial == 'Y') {
        if (isPhoto) {
            return (
                <div className="isSale active">
                    <div className="discount-box">
                        <p className="text on-special-text text-nouveaute">
                            {i18next.t('product.parure.15')}
                        </p>
                    </div>
                </div>
            );
        }
        return (
            <div className="discount-box">
                <p className="text on-special-text">{i18next.t('product.parure.15')}</p>
            </div>
        );
    }
    return null;
};

export default DiscountDetail;
