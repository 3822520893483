import React from "react"
import i18next from "i18next";
import {Modal} from "semantic-ui-react";
import "./confirmPopup.scss"


interface Props {
    handleConfirm: any,
    handleCancel: any,
    title?: string,
    text: string,
    additionText?: string
    classN?: string,
}

export default class ConfirmPopup extends React.Component<Props> {

    render() {
        const {title=i18next.t('Terminal.modal.title'), text,classN, additionText} = this.props;
        return (
            <Modal
                className="_modal--content"
                size="tiny"
                closeIcon
                open={true}
                onClose={this.props.handleCancel}
            >
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className={`modal-desc ${classN ? classN : ""}`}>
                            {text}
                            {
                                additionText && <p dangerouslySetInnerHTML={{ __html: additionText}}></p>
                            }
                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <div className="modal-actions">
                        <button
                            className="close-button"
                            onClick={this.props.handleCancel}>{i18next.t('Terminal.confirm.2')}
                        </button>
                        <button
                            className="accept-button"
                            onClick={this.props.handleConfirm}>{i18next.t('Terminal.confirm.1')}
                        </button>
                    </div>
                </Modal.Actions>
            </Modal>
        );
    }
}
