import React, {Component} from "react";
import {Accordion, Icon} from "semantic-ui-react";
import i18next from "i18next";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import {HomeActionCreators} from "../../../home/redux/actions";
import {getMenu} from "../../../../layouts/main/header/redux/selectors";
import {getHome} from "../../../home/redux/selectors";
import {connect} from "react-redux";
import {LazyImage} from "react-lazy-images";

interface Props {
    canonicalUrl?: string,
    history?: any,
    location?: any,
    menuList?: any,
    menuItem?: any,
    data?: any,
    gridData?: any,
}

interface State {
    url: any,
    found: any,
    activeIndex?: any,
}

class Parure extends Component<Props & typeof HomeActionCreators, State> {
    state = {
        activeIndex: -1,
        url: '/#',
        found: {},

    };

    componentDidMount() {
        const {menuItem} = this.props;

        if (menuItem && menuItem.gridId) {
            this.props.dispatch(HomeActionCreators.homeStart(menuItem.gridId))
        }
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    render() {
        const {activeIndex,} = this.state;
        const {data, menuItem} = this.props;
        const {photosX60, story, photosThumb = []} = data;
        const imgs: any = [];
        Array.isArray(photosX60) && photosX60.length && photosX60.slice(0, 3).forEach((item, i) => {
            imgs.push({src: item, thumb: photosThumb[i] || ""})
        });

        return (
            <React.Fragment>
                {
                    menuItem && menuItem.id  !== 708 && story && imgs.length > 1 ?
                        <section className="poure-products-about">
                            <div className="container">
                                <div className="poure-products-about-item-images poure-products-about-item">
                                    <div
                                        className="poure-products-about-item-images-box-content dflex align-items-center">
                                        {
                                            imgs.slice(0, 3).map((item, index) => (
                                                <div key={index} className="poure-products-about-item-images-box">
                                                    <LazyImage
                                                        src={`${BASE_API_GATEWAY}${item.src}`}
                                                        debounceDurationMs={300}
                                                        alt="sd"
                                                        placeholder={({imageProps, ref}) => (
                                                            <img
                                                                ref={ref}
                                                                src={`${BASE_API_GATEWAY}${item.thumb}`}
                                                                alt={imageProps.alt}

                                                            />
                                                        )}
                                                        actual={({imageProps}) => (
                                                            <img {...imageProps} alt="sd" />
                                                        )}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        data.productType === "C" ? (
                                            <div className="poure-products-about-item">
                                                <h4 className="poure-products-about-item-title">{i18next.t('product.parure.17')}</h4>
                                                {
                                                    <span dangerouslySetInnerHTML={{__html: story}}/>
                                                }
                                            </div>
                                        ) : null
                                    }


                                </div>
                            </div>
                            <Accordion className="container mobile-poure-products-about-item">

                                <div>
                                    <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                                        {i18next.t('product.parure.17')}
                                        <Icon name='plus' className="pull-right"/>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === 2}>
                                        <span
                                            dangerouslySetInnerHTML={{__html: data.productType === "C" ? story : ""}}/>
                                        {
                                            imgs.slice(0, 3).map((item, index) => (
                                                <div key={index} className="poure-products-about-item-images-box">
                                                    <img src={`${BASE_API_GATEWAY}${item.src}`} alt="product image"/>
                                                </div>
                                            ))
                                        }
                                    </Accordion.Content>
                                </div>
                            </Accordion>
                        </section>
                        : ""
                }


            </React.Fragment>
        );
    }
}

const mapStateToProps = (State: any): Props => ({
    menuList: getMenu(State),
    gridData: getHome(State),
});

export default connect(mapStateToProps)(Parure);