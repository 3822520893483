import { createActions } from 'reduxsauce';

export const { Types: clientTypes, Creators: ClientActionCreators } =
    createActions({
        clientStart: ['data'],
        clientSuccess: ['data'],
        clientFailure: ['error'],
        singleClientStart: ['id'],
        singleClientSuccess: ['data'],
        singleClientFailure: ['error'],
        setClientStart: ['client'],
        setClientSuccess: ['data'],
        setClientFailure: ['error'],
        clientNewEditStart: ['body', 'address'],
        clientNewEditSuccess: ['data'],
        clientNewEditFailure: ['error'],
        storeClientStart: ['clientId', 'basketName'],
        storeClientSuccess: ['data'],
        storeClientFailure: ['error'],
        resetStoreClient: ['basketName'],
        resetStoreClientSuccess: ['basketName'],
        resetCreateClientStatusStart: null,
        setUniqueId: ['id'],
        storesStart: [null],
        topClientsStart: ['data'],
        topClientsSuccess: ['data'],
        topClientsFailure: ['error'],
    });
