import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import i18next from 'i18next';
import { Icon, Input, Table, TextArea } from 'semantic-ui-react';
import { TransfersActionCreators } from '../../redux/actions';
import AutocompleteDropdown from '../../../common/autocompleteDropdown';
import SetTransferBtn from './setTransferBtn';
import {
    getLoadingStores,
    getStoresForm,
} from '../../../storeFormSidePanel/redux/selectors';
import { getCreatedResponse, getCreatedStatus, getCreateLoading } from '../../redux/selectors';
import { getAccountResource } from '../../../login/redux/selectors';
import { getLocalFormData, getTotalSum } from '../../../../helpers/helpers';
import {
    SESSION_STORAGE,
    TRANSFER_STATUS_CODE,
} from '../../../../utils/constants/variables';
import {
    fetchStores,
    getProductDataByBarcode,
} from '../../../../utils/constants/service';
import CustomBtn from '../../../common/customBtn';
import InfoPopUp from '../../../common/infoPopUp';

interface Props {
    isLoadingStores: boolean;
    storeTransferReasons: object;
    createdStatus: string;
    isCreateLoading: boolean;
    account: any;
    handleClosePopUp?: any;
    createdResponse: {}
}

interface State {
    status: string;
    barcode: string;
    open: boolean;
    storeSendLoading: boolean;
    storeRecvLoading: boolean;
    countrySend: string;
    countryRecv: string;
    storesRecv: object[];
    storesSend: object[];
    body: {
        storeTransferId: null | number;
        storeCodeSend: string;
        storeCodeRecv: string;
        associateCodeRecv: string;
        commentSend: string;
        commentRecv: string;
        storeTransferStatusCode: string;
        storeTransferReasonCode: string;
        productQty: number;
        storeTransferProducts: any[];
        totalBox: number | string;
    };
}

class CreateTransfer extends Component<
    Props & typeof TransfersActionCreators,
    State
> {
    state: State = {
        status: '',
        barcode: '',
        open: false,
        storeSendLoading: false,
        storeRecvLoading: false,
        countrySend: '',
        countryRecv: '',
        storesRecv: [],
        storesSend: [],
        body: {
            storeTransferId: null,
            storeCodeSend: '',
            storeCodeRecv: '',
            associateCodeRecv: '',
            commentSend: '',
            commentRecv: '',
            storeTransferStatusCode: TRANSFER_STATUS_CODE.PEN,
            storeTransferReasonCode: 'DEP',
            productQty: 0,
            storeTransferProducts: [],
            totalBox: 0,
        },
    };

    componentDidMount(): void {
        const country = this.props.account.storeDetails
            ? this.props.account.storeDetails.country
            : '';
        fetchStores(country).then((res) => {
            this.setState({
                storesRecv: this.getStoresRecv(res),
                storesSend: this.getStoresSend(res),
                countryRecv: country,
                countrySend: country
            });
        });
        this.props.storesFormStart();
    }

    componentDidUpdate(prevProps, prevState): void {
        const { storeCode } = this.props.account;
        const { storeCodeSend, storeCodeRecv } = this.state.body;
        if (prevProps.createdStatus !== this.props.createdStatus) {
            this.setState({ status: this.props.createdStatus });
        }

        if (
            prevState.body.storeCodeSend !== storeCodeSend &&
            storeCodeSend !== storeCode
        ) {
            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeCodeRecv: storeCode,
                },
            }));
        }

        if (
            prevState.body.storeCodeRecv !== storeCodeRecv &&
            storeCodeRecv !== storeCode
        ) {
            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeCodeSend: storeCode,
                },
            }));
        }
    }

    handleChange = ({ target }) => {
        const { name, value } = target;
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                [name]: value,
            },
        }));
    };

    handleChangeDropdown = (name, value) => {
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                [name]: value,
            },
        }));
    };

    handleChangeQuantity = ({ target }) => {
        const { name, value } = target;
        const storeTransferProducts = this.state.body.storeTransferProducts.map(
            (item: any) => {
                if (item.barcode === name) {
                    return { ...item, quantity: +value || 0 };
                }
                return item;
            },
        );
        const productQty = getTotalSum(storeTransferProducts, 'quantity');

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeTransferProducts: storeTransferProducts,
                productQty,
            },
        }));
    };

    handleScan = async (barcode, count?, end?, isSubmit?) => {
        if (!barcode) return;

        const {
            body: { storeTransferProducts },
        } = this.state;
        const isExistingProduct = !!storeTransferProducts.find(
            (item) => item.barcode === barcode,
        );
        let products: any = [];
        if (isExistingProduct) {
            products = storeTransferProducts.map((item) => {
                if (item.barcode === barcode) {
                    return { ...item, quantity: item.quantity + (count || 1) };
                }
                return item;
            });
        } else {
            const data = await getProductDataByBarcode(
                barcode,
                this.props.account,
            );

            if (!data) return;

            const name = data.name;
            const size = data.sizes && data.sizes[0] ? data.sizes[0].size : '';
            const stock = data.stock
                ? data.stock
                : data.sizes && data.sizes[0]
                ? data.sizes[0].stock
                : '';
            products = [
                ...storeTransferProducts,
                ...[{ barcode, quantity: count || 1, name, size, stock }],
            ];
        }

        const productQty = getTotalSum(products, 'quantity');

        this.setState(
            (prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeTransferProducts: products,
                    productQty,
                },
            }),
            () => isSubmit && this.handleSubmit(),
        );
    };

    handleClickAjouter = () => {
        const { barcode } = this.state;
        if (barcode) {
            this.handleScan(barcode);
            this.setState({ barcode: '' });
        }
    };

    handleDelete = (barcode) => () => {
        const { storeTransferProducts } = this.state.body;
        const products = storeTransferProducts.filter(
            (el) => el.barcode !== barcode,
        );
        const productQty = getTotalSum(products, 'quantity');

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeTransferProducts: products,
                productQty,
            },
        }));
    };

    handleSubmit = () => {
        const { createdResponse: { storeTransferId = null } = { storeTransferId: null } } = this.props;
        if (this.state.barcode) {
            this.handleScan(this.state.barcode, undefined, undefined, true);
            this.setState({ barcode: '' });
        }

        if (!this.state.body.storeTransferProducts.length) {
            this.setState({ open: true });
            return;
        }
        const {
            account: { terminalCode },
        } = this.props;
        const storeTransferProducts = this.state.body.storeTransferProducts.map(
            ({ barcode, quantity }) => ({ barcode, quantity }),
        );
        const body = {
            ...this.state.body,
            associateCodeSend: null,
            associateCodeRecv:
                terminalCode ||
                sessionStorage.getItem(SESSION_STORAGE.terminalCode),
            storeTransferProducts,
            storeTransferId
        };
        this.props.createTransferStart({ body });
    };

    handleChangeCountry = (name, country) => {
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeCodeSend:
                    name === 'countrySend' ? '' : prevState.body.storeCodeSend,
                storeCodeRecv:
                    name === 'countryRecv' ? '' : prevState.body.storeCodeRecv,
            },
            storeSendLoading: name === 'countrySend',
            storeRecvLoading: name === 'countryRecv',
        }));

        fetchStores(country)
            .then((res) => {
                if (name === 'countrySend') {
                    this.setState({
                        storesSend: this.getStoresSend(res),
                        countrySend: country,
                    });
                } else {
                    this.setState({
                        storesRecv: this.getStoresRecv(res),
                        countryRecv: country,
                    });
                }
            })
            .finally(() =>
                this.setState({
                    storeSendLoading: false,
                    storeRecvLoading: false,
                }),
            );
    };

    getStoresSend = (stores) => {
        const { storeDetails: { storeType = '' } = {} } = this.props.account;

        return storeType === 'T'
            ? stores.filter((item) => item.storeType === 'T')
            : stores.filter(
                  (item) => item.storeType !== 'W' && item.storeType !== 'T',
              );
    };

    getStoresRecv = (stores) => {
        const { storeDetails: { storeType = '' } = {} } = this.props.account;

        return storeType === 'T'
            ? stores.filter((item) => item.storeType === 'T')
            : stores.filter((item) => item.storeType !== 'T');
    };

    ListProducts = (item, newItem = false) => {
        return (
            <Table.Row key={item.barcode}>
                <Table.Cell
                    className={`productInfoBlock--infoBox--table--description${
                        newItem ? ' new' : ''
                    }`}
                >
                    {newItem ? (
                        <Input
                            className="barcode--input"
                            value={this.state.barcode}
                            onChange={({ target }) =>
                                this.setState({ barcode: target.value })
                            }
                        />
                    ) : (
                        item.barcode
                    )}
                </Table.Cell>
                <Table.Cell className="productInfoBlock--infoBox--table--description">
                    {item.name}
                </Table.Cell>
                <Table.Cell className="productInfoBlock--infoBox--table--description">
                    {item.size}
                </Table.Cell>
                <Table.Cell className="productInfoBlock--infoBox--table--description">
                    {item.stock}
                </Table.Cell>
                <Table.Cell className="productInfoBlock--infoBox--table--description">
                    <Input
                        name={item.barcode}
                        value={item.quantity}
                        onChange={this.handleChangeQuantity}
                    />
                </Table.Cell>
                <Table.Cell className="productInfoBlock--infoBox--table--description trash">
                    {newItem ? (
                        ''
                    ) : (
                        <Icon
                            name="trash alternate"
                            onClick={this.handleDelete(item.barcode)}
                        ></Icon>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    };

    render() {
        const { isCreateLoading, storeTransferReasons } = this.props;
        const {
            body,
            status,
            open,
            countryRecv,
            countrySend,
            storesRecv,
            storesSend,
            storeRecvLoading,
            storeSendLoading,
        } = this.state;
        const { storeTransferProducts } = body;
        const { countries = [] } = getLocalFormData();

        return (
            <div className="transferPopUp">
                <div className="popUp--body set-data-content">
                    <div className="content">
                        <div className="extra content">
                            <div className="ui two buttons">
                                <CustomBtn
                                    classN="btn_1 w-100 blue_btn withoutHover"
                                    handleClick={this.handleSubmit}
                                    loading={isCreateLoading}
                                >
                                    {i18next.t('Terminal.dashboards.edit')}
                                </CustomBtn>
                                <div
                                    className="btn_1 w-100"
                                    onClick={this.props.handleClosePopUp}
                                >
                                    {i18next.t('buttons.40')}
                                </div>
                            </div>
                        </div>
                        {status && (
                            <div
                                className={
                                    status === 'success'
                                        ? 'successMessage mb-2'
                                        : 'errorMessage mb-2'
                                }
                            >
                                {status === 'success'
                                    ? i18next.t('alertMessage.2')
                                    : i18next.t('alertMessage.1')}
                            </div>
                        )}
                        <div className="popUp--body--card--body--between">
                            <div className="popUp--body--card--body--data w-50 transfer-color-blue">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t('Terminal.dashboards.country')}
                                </div>
                                <AutocompleteDropdown
                                    data={countries}
                                    value={countrySend}
                                    search={true}
                                    keyName="code"
                                    textName={['name']}
                                    valueName="code"
                                    classN={
                                        'popUp--body--card--body--data--description'
                                    }
                                    onChange={(value) =>
                                        this.handleChangeCountry(
                                            'countrySend',
                                            value,
                                        )
                                    }
                                />
                            </div>
                            <div className="popUp--body--card--body--data transfer-color-blue">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.sendName',
                                    )}
                                </div>
                                <AutocompleteDropdown
                                    data={storesSend}
                                    value={body.storeCodeSend}
                                    search={true}
                                    disabledOption={body.storeCodeRecv}
                                    keyName="storeCode"
                                    loading={storeSendLoading}
                                    textName={[
                                        'name',
                                        'address',
                                        'zip',
                                        'city',
                                        'phone',
                                    ]}
                                    valueName="storeCode"
                                    placeholder={i18next.t(
                                        'Terminal.Receipt.Transfer.sendName',
                                    )}
                                    classN={
                                        'popUp--body--card--body--data--description'
                                    }
                                    onChange={(value) =>
                                        this.handleChangeDropdown(
                                            'storeCodeSend',
                                            value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--between">
                            <div className="popUp--body--card--body--data w-50">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t('Terminal.dashboards.country')}
                                </div>
                                <AutocompleteDropdown
                                    data={countries}
                                    value={countryRecv}
                                    search={true}
                                    keyName="code"
                                    textName={['name']}
                                    valueName="code"
                                    classN={
                                        'popUp--body--card--body--data--description'
                                    }
                                    onChange={(value) =>
                                        this.handleChangeCountry(
                                            'countryRecv',
                                            value,
                                        )
                                    }
                                />
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.recvName',
                                    )}
                                </div>
                                <AutocompleteDropdown
                                    data={storesRecv}
                                    value={body.storeCodeRecv}
                                    search={true}
                                    disabledOption={body.storeCodeSend}
                                    keyName="storeCode"
                                    loading={storeRecvLoading}
                                    textName={[
                                        'name',
                                        'address',
                                        'zip',
                                        'city',
                                        'phone',
                                    ]}
                                    valueName="storeCode"
                                    placeholder={i18next.t(
                                        'Terminal.Receipt.Transfer.recvName',
                                    )}
                                    classN={
                                        'popUp--body--card--body--data--description'
                                    }
                                    onChange={(value) =>
                                        this.handleChangeDropdown(
                                            'storeCodeRecv',
                                            value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--between">
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.commentSend',
                                    )}
                                </div>
                                <TextArea
                                    className="popUp--body--card--body--data--description textarea"
                                    style={{ minHeight: 55, height: 55 }}
                                    name="commentSend"
                                    value={body.commentSend}
                                    onChange={this.handleChange}
                                    disabled={!body.storeCodeSend}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.commentRecv',
                                    )}
                                </div>
                                <TextArea
                                    className="popUp--body--card--body--data--description textarea"
                                    style={{ minHeight: 55, height: 55 }}
                                    name="commentRecv"
                                    value={body.commentRecv}
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    disabled={!body.storeCodeRecv}
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.Receipt.Transfer.reasonCode',
                                )}
                            </div>
                            <AutocompleteDropdown
                                data={storeTransferReasons}
                                value={body.storeTransferReasonCode}
                                search={true}
                                classN="popUp--body--card--body--data--description"
                                keyName="code"
                                textName={['reason']}
                                valueName="code"
                                placeholder={i18next.t(
                                    'Terminal.Receipt.Transfer.reasonCode',
                                )}
                                onChange={(value) =>
                                    this.handleChangeDropdown(
                                        'storeTransferReasonCode',
                                        value,
                                    )
                                }
                            />
                        </div>
                        <div className="popUp--body--card--body--between">
                            <form
                                autoComplete="off"
                                className="popUp--body--card--body--data"
                            >
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.qtyTotal',
                                    )}
                                </div>
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description disabled'
                                    }
                                    value={body.productQty || ''}
                                    disabled={true}
                                    autoComplete="off"
                                />
                            </form>
                            <form
                                autoComplete="off"
                                className="popUp--body--card--body--data"
                            >
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Deliveries.cartons',
                                    )}
                                </div>
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description'
                                    }
                                    value={body.totalBox || ''}
                                    onChange={this.handleChange}
                                    name="totalBox"
                                    type="number"
                                />
                            </form>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <BarcodeReader
                                onScan={(barcode) => this.handleScan(barcode)}
                            />
                        </div>
                        <SetTransferBtn
                            handleUpload={this.handleScan}
                            handleAjouter={this.handleClickAjouter}
                            storeDetails={this.props.account.storeDetails}
                            pdfData={storeTransferProducts}
                        />
                        <Table
                            celled
                            selectable
                            className="transferspopUp productInfoBlock--infoBox--table invertory"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.barcode',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Name')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Size')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Deliveries.stock',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.quantity',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title" />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body className="transfers-list product---list--content">
                                {storeTransferProducts.map((item, i) => {
                                    return this.ListProducts(item);
                                })}
                                {this.ListProducts({}, true)}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
                <InfoPopUp
                    open={open}
                    classN="errorMessage"
                    message={i18next.t('Terminal.Receipt.Transfer.noProduct')}
                    handleClose={() => this.setState({ open: false })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { storeTransferReasons = [] } = getStoresForm(state);

    return {
        isLoadingStores: getLoadingStores(state),
        isCreateLoading: getCreateLoading(state),
        createdStatus: getCreatedStatus(state),
        storeTransferReasons,
        account: getAccountResource(state),
        createdResponse: getCreatedResponse(state),
    };
};

const mapDispatchToProps = (dispatch): typeof TransfersActionCreators => {
    return bindActionCreators(TransfersActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransfer);
