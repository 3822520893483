import React, {useState} from "react";
import { pdf, Page, Text, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import i18next from "i18next";
import CustomBtn from "../common/customBtn";
import {delay, getCurrency, getDateTimestampByTime, getUserFromToken, replaceComma} from "../../helpers/helpers";
import { changeFormatHourly } from "../../helpers/printer";

interface IProps {
    disabled: boolean,
    totals: any,
    payments: any,
    associates: any,
    hourlySales: any,
    details: any
    saleDay?: any
    title?: any
}

const ReportPdfBtn: React.FC<IProps> = ({ disabled, ...rest}) => {
    const [loading, setLoading] = useState(false);

    const handlePdf = async () => {
        try {
            setLoading(true);
            await delay(100);
            const blob = await pdf(<TableGenerator {...rest} />).toBlob();
            const url = URL.createObjectURL(blob);
            setLoading(false);
            window.open(url, '_blank');
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    return (
        <CustomBtn 
            classN={`btn_1 w-50 blue_btn`} 
            disabled={disabled} 
            handleClick={handlePdf}
            loading={loading}
        >
            {i18next.t('buttons.29')}
        </CustomBtn>
    )
}

export default ReportPdfBtn;

const TableGenerator = ({totals, payments, associates, hourlySales, details, saleDay = "", title = ""}) => {
    const storeName = details.storeDetails ? details.storeDetails.name : '';
    
    return (
        <Document>
            <Page style={styles.body}>
                <View style={[ styles.row ]} fixed >
                    <Text style={[styles.productTitle, styles.cell]}>{getDateTimestampByTime(Date.now())}</Text>
                    {storeName ? <Text style={[styles.productTitle, styles.cell]}>{storeName}</Text> :<Text></Text>}
                    <Text style={[styles.productTitle, styles.cell]}>{getUserFromToken().fname}</Text>
                </View>
                {totals.length ?
                    <View>
                        <View style={[ styles.row ]} fixed >
                            <Text style={[styles.productTitle, styles.cell, styles.title, styles.uppercase]}>{i18next.t("Terminal.Receipt.zReport.totals")} {title} {saleDay}</Text>
                        </View>
                        {
                            totals.map(( item, idx) =>(
                                <View key={idx} style={[styles.row]}  fixed >
                                    <Text style={[styles.product, styles.cell, {width: "50%"}]}>{item.name}</Text>
                                    <Text style={[styles.product, styles.cell, {width: "50%"} ]}>{item.value}</Text>
                                </View>
                            ))
                        }
                    </View>
                    : 
                    <Text></Text>
                }
                {payments.length ?
                    <View>
                        <View style={[ styles.row ]} fixed >
                            <Text style={[styles.productTitle, styles.cell, styles.title]}>{i18next.t("Terminal.Receipt.zReport.payments")}</Text>
                        </View>
                        {
                            payments.map(( item, idx) =>(
                                <View key={idx} style={[styles.row]}  fixed >
                                    <Text style={[styles.product, styles.cell, {width: "50%"}]}>{item.paymentMethod}</Text>
                                    <Text style={[styles.product, styles.cell, {width: "15%"} ]}>{item.paymentMethodCount}</Text>
                                    <Text style={[styles.product, styles.cell, {width: "35%", textAlign: 'right'} ]}>{`${replaceComma(item.paymentAmount)} ${getCurrency()}`}</Text>
                                </View>
                            ))
                        }
                    </View>
                    : 
                    <Text></Text>
                }
                {associates.length ?
                    <View>
                        <View style={[ styles.row ]} fixed >
                            <Text style={[styles.productTitle, styles.cell, styles.title]}>{i18next.t("Terminal.Receipt.zReport.associateFirstName")}</Text>
                        </View>
                        {
                            associates.map(( item, idx) =>(
                                <View key={idx} style={[styles.row]}  fixed >
                                    <Text style={[styles.product, styles.cell, {width: "50%"}]}>{item.associateFirstName}</Text>
                                    <Text style={[styles.product, styles.cell, {width: "50%"} ]}>{`${replaceComma(item.paymentNetTotal)} ${getCurrency()} `}</Text>
                                </View>
                            ))
                        }
                    </View>
                    : 
                    <Text></Text>
                }
                {hourlySales.length ?
                    <View>
                        <View style={[ styles.row ]} fixed >
                            <Text style={[styles.productTitle, styles.cell, styles.title]}>{i18next.t("Terminal.Receipt.zReport.hourlySales")}</Text>
                        </View>
                        {
                            hourlySales.map(( item, idx) =>(
                                <View key={idx} style={[styles.row]}  fixed >
                                    <Text style={[styles.product, styles.cell, {width: "50%"}]}>{changeFormatHourly(item.hour)}</Text>
                                    <Text style={[styles.product, styles.cell, {width: "50%"} ]}>{`${replaceComma(item.paymentNetTotal)} ${getCurrency()} `}</Text>
                                </View>
                            ))
                        }
                    </View>
                    : 
                    <Text></Text>
                }
                
            </Page>
        </Document>
    )
}

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles:any = StyleSheet.create({
    body: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 15,
    },
    view: {
        width: '100%',
        height: '100%',
    },
    text: {
        fontSize: 13,
        padding: 2,
        fontFamily: 'Times-Roman'
    },
    uppercase: {
        textTransform: "uppercase"
    },

    productTitle: {
        fontSize: 12,
        padding: 2,
        fontFamily: 'Times-Roman'
    },
    product: {
        fontSize: 11,
        padding: 2,
        fontFamily: 'Times-Roman',
        borderTop: "1px solid #ccc",
    },
    textBold: {
        fontSize: 13,
        fontWeight: 'bold',
        fontFamily: 'Oswald',
        paddingLeft: 3
    },

    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "stretch",
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 35
    },
    cell: {
        flexGrow: 1,
        flexShrink: 1,
        alignSelf: "stretch",
        borderTop: "1px solid #ccc"
    },
    title: {
        borderColor: "#7b7b7b",
        borderStyle: "solid",
        borderWidth: 2,
        textAlign: 'center',
        backgroundColor: '#ccc'
    },
});

