import {DeliveriesTypes, DeliveriesActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import {
    DELIVERIES_URL,
    CREATE_DELIVERIES_URL,
    UPDATE_STORE_DELIVERY,
    getAxiosHeaders,
    GET_BARCODE_BY_PARSEL_NUMBER
} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers"
import {DEFAULT_CURRENCY} from "../../../utils/constants/variables";

export function* getDeliveriesData() {
    try {
        const data = yield call(() => {
            return axios.get(`${DELIVERIES_URL}?language=${getApiPrefix()}`, getAxiosHeaders(DEFAULT_CURRENCY))
                .then(res => res.data)
        });
        yield put(DeliveriesActionCreators.deliveriesSuccess(data));
    } catch (e) {
        yield put(DeliveriesActionCreators.deliveriesFailure(e.message));
    }
}

export function* barcodeByColis(params) {
    const { parcelNumber } = params;
    try {
        const data = yield call(() => {
            return axios.get(`${GET_BARCODE_BY_PARSEL_NUMBER}?parcelNumber=${parcelNumber}`, getAxiosHeaders(DEFAULT_CURRENCY))
                .then(res => res.data)
        });
        yield put(DeliveriesActionCreators.getBarcodeByColisSuccess(data));
    } catch (e) {
        yield put(DeliveriesActionCreators.getBarcodeByColisFailure(e.message));
    }
}

export function* createDeliveries(params) {
    const body = {...params.data, updatedStats: undefined};
    const { storeDeliveryId, updatedStats } = params.data;
    
    try {
        const data = yield call(() => {
            return axios.post(`${CREATE_DELIVERIES_URL}?language=${getApiPrefix()}`, body, getAxiosHeaders(DEFAULT_CURRENCY))
                .then(res => res.data)
        });
        if(storeDeliveryId && updatedStats){
             yield put(DeliveriesActionCreators.updateStoreDeliveriesStart(updatedStats, storeDeliveryId))
        }else {
            yield put(DeliveriesActionCreators.deliveriesStart(data));
        }

         yield put(DeliveriesActionCreators.createDeliveriesSuccess(data));

    } catch (e) {
        yield put(DeliveriesActionCreators.createDeliveriesFailure(e.message));
    }
}

export function* updateStoreDeliveries(params) {
    const { logisticStatus, storeDeliveryId } = params;
    try {
        const data = yield call(() => {
            return axios.put(`${UPDATE_STORE_DELIVERY}?storeDeliveryId=${storeDeliveryId}&logisticStatus=${logisticStatus}`,  "", getAxiosHeaders(DEFAULT_CURRENCY))
                .then(res => res.data)
        });
        yield put(DeliveriesActionCreators.deliveriesStart(data));
        yield put(DeliveriesActionCreators.updateStoreDeliveriesSuccess(data));
    }catch (e) {
        yield put(DeliveriesActionCreators.updateStoreDeliveriesFailure());
    }
}

export function* DeliveriesSaga() {
    yield takeLatest(DeliveriesTypes.DELIVERIES_START, getDeliveriesData);
    yield takeLatest(DeliveriesTypes.CREATE_DELIVERIES_START, createDeliveries);
    yield takeLatest(DeliveriesTypes.UPDATE_STORE_DELIVERIES_START, updateStoreDeliveries);
    yield takeLatest(DeliveriesTypes.GET_BARCODE_BY_COLIS_START, barcodeByColis);
}

export default DeliveriesSaga;
