import {createActions} from 'reduxsauce';

export const {Types: AddToCartTypes, Creators: AddToCartActionCreators} = createActions ({
    addtocartStart: ["name", "key", "value", "setAsQuantity"],
    addtocartSuccess: ['data'],
    addtocartFailure: ['error'],
    getBasketStart: ['name'],
    getBasketSuccess: ["data"],
    removeBasketStart: ["name", "key", "prSize"],
    removeBasketSuccess: ["data"],
    storeClientStart: ['clientId', "basketName"],
    resetStoreClient: ["basketName"],
    basketStoreGiftsStart: null,
    removeBasketGiftsStart: ['storeGiftCode'],
    getBasketStocksStart: ['barcodeData','storeCode', 'storeType','basket'],
});
