import React, { Component } from 'react';
import { connect } from 'react-redux';

import i18next from 'i18next';
import { getQuote } from '../../redux/selectors';
import { QuoteActionCreators } from '../../redux/actions';
import { bindActionCreators } from 'redux';
import {
    addPaperReceiptProduct,
    getEmptyBasketName,
} from '../../../../helpers/helpers';
import InfoPopUp from '../../../common/infoPopUp';
import history from '../../../../helpers/history';
import { getAccountResource } from '../../../login/redux/selectors';
import { RECEIPT_TYPE } from '../../../../utils/constants/variables';
import CustomBtn from '../../../common/customBtn';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';

interface Props {
    data?: any;
    paperReceipt: any;
    isLoadingPaperReceipt: boolean;
    account: any;
}

interface State {
    open: boolean;
    showConfirm: boolean
}

class QuoteButtons extends Component<
    Props & typeof QuoteActionCreators,
    State
> {
    state: State = {
        open: false,
        showConfirm: false
    };

    componentDidUpdate(prevProps, prevState): void {
        if (
            prevProps.isLoadingPaperReceipt &&
            !this.props.isLoadingPaperReceipt &&
            this.props.paperReceipt &&
            this.props.paperReceipt.quoteNumber
        ) {
            const res = addPaperReceiptProduct(
                this.props.paperReceipt,
                'quoteNumber',
            );
            if (res) {
                this.props.getBasketStart();
                history.push('/');
            } else {
                this.setState({ open: true });
            }
        }
    }

    resetQuote = () => {
        const { data = {} } = this.props;
        const { quoteNumber = '' } = data;
        const receiptType = RECEIPT_TYPE.quote;
        const basketName = getEmptyBasketName();
        if (basketName) {
            this.props.paperReceiptQuoteStart({ quoteNumber, receiptType });
        } else {
            this.setState({ open: true });
        }
    };

    printQuote = () => {
        const { data = {}, account = {} } = this.props;
        const { quoteNumber = '' } = data;
        if (!quoteNumber || !account.printer) return;
        this.props.quotePrintTicketStart({
            quoteNumber,
            receiptType: RECEIPT_TYPE.quote,
        });
    };

    handleConfirm = () => {
        const { data: { quoteNumber = ''} = { quoteNumber: '' } } = this.props;
        this.props.quoteDeleteTicketStart({quoteNumber});
        this.setState({ showConfirm: false });
    }

    render() {
        const {
            isLoadingPaperReceipt,
            account: { printer = false } = {},
            data = {},
        } = this.props;
        const { open } = this.state;

        return (
            <>
                <button
                    className={`btn_1 w-100 orange_btn ${
                        printer ? '' : 'disabled'
                    }`}
                    disabled={!printer}
                    onClick={this.printQuote}
                >
                    {i18next.t('buttons.17')}
                </button>
                <CustomBtn
                    classN="btn_1 w-100 blue_btn"
                    handleClick={this.resetQuote}
                    loading={isLoadingPaperReceipt}
                    disabled={!data.active}
                >
                    {i18next.t('buttons.18')}
                </CustomBtn>
                <button
                    className={`btn_1 w-100 red_btn ${
                        printer ? '' : 'disabled'
                    }`}
                    disabled={!printer}
                    style={{ order: 10 }}
                    onClick={() => this.setState({ showConfirm: true })}
                >
                    {i18next.t('buttons.12')}
                </button>
                <InfoPopUp
                    open={open}
                    classN="message-error"
                    message={i18next.t('Terminal.confirm.11')}
                    handleClose={() => this.setState({ open: false })}
                />
                {this.state.showConfirm && (
                    <ConfirmPopup
                        handleCancel={() => {
                            this.setState({ showConfirm: false });
                        }}
                        handleConfirm={this.handleConfirm}
                        text={i18next.t('Terminal.modal.2')}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { paperReceipt, isLoadingPaperReceipt } = getQuote(state);
    return {
        account: getAccountResource(state),
        paperReceipt,
        isLoadingPaperReceipt,
    };
};

const mapDispatchToProps = (dispatch): typeof QuoteActionCreators => {
    return bindActionCreators(QuoteActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteButtons);
