import {createReducer} from 'reduxsauce';
import {stockTypes} from './actions';
import { getStockBarcodesList } from '../../../helpers/helpers';
import { Statuses } from '../../../utils/constants/variables';

export interface IStockState {
    stockData?: any,
    singleStockData: any,
    barcodeProduct: any,
    isLoading: boolean,
    isSingleLoading: boolean,
    isLoadingProduct: boolean,
    setStock: any,
    createStockStatus: string,
    productSold: object[],
    isStockDashboard: boolean,
    stockCounters: any,
    newStockProduct: object,
    barcodesList: object[],
    negativeBarcodesList: object[],
    isProductDeliveryloading: boolean;
    productDeliveries: any[];
    stockProducts: any[];
    stockProductError: string;
    isImportLoading: boolean;
    loadInfo: any;
    importProductDetails: any;
    isStockUpdateloading: boolean;
    isUploadLoading: boolean;
    uploadResult: boolean | null;
    isAssociateServiceLoading: boolean;
    associateServices: any[];
    adminPageLoading: boolean;
    addAssociateServiceLoading: boolean;
    storeAssociates: any[];
    getStoreAssociatesLoading: boolean;
    addServiceStatus: Statuses;
}

export const stockContent: IStockState = {
    stockData: [],
    singleStockData: {},
    barcodeProduct: {},
    isLoading: false,
    isSingleLoading: false,
    isLoadingProduct: false,
    setStock:{},
    createStockStatus:"",
    productSold: [],
    isStockDashboard: false,
    stockCounters:{},
    newStockProduct: {},
    barcodesList: [],
    negativeBarcodesList: [],
    isProductDeliveryloading: false,
    productDeliveries: [],
    stockProducts: [],
    stockProductError: "",
    isImportLoading: false,
    loadInfo: null,
    importProductDetails: {
        productFileImportErrors: []
    },
    isStockUpdateloading: false,
    isUploadLoading: false,
    uploadResult: null,
    isAssociateServiceLoading: false,
    associateServices: [],
    adminPageLoading: false,
    addAssociateServiceLoading: false,
    storeAssociates: [],
    getStoreAssociatesLoading: false,
    addServiceStatus: Statuses.initial,
};



export const getStockData = (state) => {
    return {...state, isLoading: true};
};

export const stockSuccess = (state, action) => {
    return {
        ...state,
        stockData: action.data,
        isLoading: false,
        barcodesList: getStockBarcodesList(action.data),
        negativeBarcodesList: getStockBarcodesList(action.data, true),
    };
};

export const stockFailure = (state, action) => {
    return {...state, error: action.error, data: [], isLoading: false};
};

export const stockBarcodesList = (state, action) => {
    return {
        ...state,
        barcodesList: getStockBarcodesList(state.stockData, action.param),
    };
};

export const getSingleStockData = (state) => {
    return {...state, isSingleLoading: true};
};

export const singleStockSuccess = (state, action) => {
    return {...state, singleStockData: action.data, isSingleLoading: false};
};

export const singleStockFailure = (state, action) => {
    return {...state, error: action.error, singleStockData: {}, isSingleLoading: false};
};

export const barcodeProduct = (state, action) => {
    return{...state, barcodeProduct:{}, isLoadingProduct: true}
}
export const barcodeProductSuccess = (state, action) => {
    return{...state, barcodeProduct: action.data, isLoadingProduct: false}
}
export const barcodeProductFailure = (state, action) => {
    return{...state, barcodeProduct: {productId: null}, error: action.error, isLoadingProduct: false}
}

export const createStockProduct =(state) => {
    return {...state, setStock: {}, isLoading: true}
};

export const createStockSuccess =(state, action) => {
    return {...state, newStockProduct: action.data, createStockStatus:"success", isLoading: false}
};

export const createStockFailure =(state, action) => {
    return {...state, error: action.error, newStockProduct: {}, createStockStatus:"failed", isLoading: false}
};

export const productSold =(state) => {
    return {...state}
};

export const productSoldSuccess =(state, action) => {
    return {...state, productSold: action.data,}
};

export const productSoldFailure =(state, action) => {
    return {...state, error: action.error, productSold: []}
};

export const isStockDashboardStart = (state, action) => {
    return {...state, isStockDashboard: action.data}
};

export const getStockCounters = (state) => {
    return {...state };
};

export const stockCountersSuccess = (state, action) => {
    return {...state, stockCounters: action.data};
};

export const stockCountersFailure = (state, action) => {
    return {...state, error: action.error, stockCounters: {}};
};

export const resetCreatedStockStatus = (state,action) => {
    return {...state,  newStockProduct: {}, createStockStatus:""}
}

export const getProductDeliveryStart = (state, action) => {
    return {...state, isProductDeliveryloading: true}
}

export const getProductDeliverySuccess = (state, action) => {
    return {...state, productDeliveries: action.data, isProductDeliveryloading: false};
}

export const getProductDeliveryFailure = (state) => {
    return {...state, productDeliveries: [], isProductDeliveryloading: false};
}

export const getStockProductsStart = (state) => {
    return {...state, stockProducts: []};
}

export const getStockProductsSuccess = (state, action) => {
    return {...state, stockProducts: action.data};
}

export const getStockProductsFailure = (state) => {
    return {...state, stockProducts: [], stockProductError: ""};
}

export const importStockProductStart = (state) => {
    return {...state, isImportLoading: true, loadInfo: null};
}

export const importStockProductSuccess = (state, action) => {
    return {...state, isImportLoading: false, loadInfo: action.data};
}

export const importStockProductFailure = (state) => {
    return {...state, isImportLoading: false, loadInfo: null};
}

export const importStockProductDetailsStart = (state) => {
    return {...state};
}

export const importStockProductDetailsSuccess = (state, action) => {
    return {...state, importProductDetails: action.data};
}

export const importStockProductDetailsFailure = (state) => {
    return {...state, importProductDetails: {}};
}

export const updateProductStockStart = (state) => {
    return {...state, isStockUpdateloading: true};
}

export const updateProductStockSuccess = (state) => {
    return {...state, isStockUpdateloading: false};
}

export const updateProductStockFailure = (state) => {
    return {...state, isStockUpdateloading: false};
}

export const updateProductPriceStart = (state) => {
    return {...state, isStockUpdateloading: true};
}

export const updateProductPriceSuccess = (state) => {
    return {...state, isStockUpdateloading: false};
}

export const updateProductPriceFailure = (state) => {
    return {...state, isStockUpdateloading: false};
}

export const uploadStoreInventoryStart = (state) => {
    return {...state, isUploadLoading: true, uploadResult: null};
}

export const uploadStoreInventorySuccess = (state, action) => {
    return {...state, isUploadLoading: false, uploadResult: action.data};
}

export const uploadStoreInventoryFailure = (state) => {
    return {...state, isUploadLoading: false, uploadResult: false};
}

export const uploadStoreInventoryInitial = (state) => {
    return {...state, isUploadLoading: false, uploadResult: null};
}

export const getAssociateServiceStart = (state, action) => {
    return { ...state, isAssociateServiceLoading: true }
}

export const getAssociateServiceSuccess = (state, action) => {
    return { ...state, isAssociateServiceLoading: false, associateServices: action.data }
}

export const getAssociateServiceFailure = (state, action) => {
    return { ...state, isAssociateServiceLoading: false, associateServices: [] }
}

export const adminPageLoadingChange = (state, action) => {
    return { ...state, adminPageLoading: action.status }
}

export const addAssociateServiceStart = (state, action) => {
    return { ...state, addAssociateServiceLoading: true }
}

export const addAssociateServiceSuccess = (state) => {
    return { ...state, addAssociateServiceLoading: false, addServiceStatus: Statuses.success }
}

export const addAssociateServiceFailure = (state) => {
    return { ...state, addAssociateServiceLoading: false, addServiceStatus: Statuses.failure }
}

export const addAssociateServiceReset = (state) => {
    return { ...state, addServiceStatus: Statuses.initial };
}

export const getStoreAssociatesStart = (state) => {
    return { ...state, getStoreAssociatesLoading: true }
}

export const getStoreAssociatesSuccess = (state, action) => {
    return { ...state, getStoreAssociatesLoading: false, storeAssociates: action.data }
}

export const getStoreAssociatesFailure = (state) => {
    return { ...state, getStoreAssociatesLoading: false }
}

export const handlers = {
    [stockTypes.STOCK_START]: getStockData,
    [stockTypes.STOCK_SUCCESS]: stockSuccess,
    [stockTypes.STOCK_FAILURE]: stockFailure,
    [stockTypes.STOCK_BARCODES_LIST_START]: stockBarcodesList,
    [stockTypes.SINGLE_STOCK_START]: getSingleStockData,
    [stockTypes.SINGLE_STOCK_SUCCESS]: singleStockSuccess,
    [stockTypes.SINGLE_STOCK_FAILURE]: singleStockFailure,
    [stockTypes.BARCODE_PRODUCT_START]: barcodeProduct,
    [stockTypes.BARCODE_PRODUCT_SUCCESS]: barcodeProductSuccess,
    [stockTypes.BARCODE_PRODUCT_FAILURE]: barcodeProductFailure,
    [stockTypes.CREATE_STOCK_START]:createStockProduct,
    [stockTypes.CREATE_STOCK_SUCCESS]: createStockSuccess,
    [stockTypes.CREATE_STOCK_FAILURE]: createStockFailure,
    [stockTypes.PRODUCT_SOLD_START]: productSold,
    [stockTypes.PRODUCT_SOLD_SUCCESS]: productSoldSuccess,
    [stockTypes.PRODUCT_SOLD_FAILURE]: productSoldFailure,
    [stockTypes.IS_STOCK_DASHBOARD_START]: isStockDashboardStart,
    [stockTypes.STOCK_COUNTERS_START]: getStockCounters,
    [stockTypes.STOCK_COUNTERS_SUCCESS]: stockCountersSuccess,
    [stockTypes.STOCK_COUNTERS_FAILURE]: stockCountersFailure,
    [stockTypes.RESET_CREATED_STOCK_STATUS]: resetCreatedStockStatus,
    [stockTypes.GET_PRODUCT_DELIVERY_START]: getProductDeliveryStart,
    [stockTypes.GET_PRODUCT_DELIVERY_SUCCESS]: getProductDeliverySuccess,
    [stockTypes.GET_PRODUCT_DELIVERY_FAILURE]: getProductDeliveryFailure,
    [stockTypes.GET_STOCK_PRODUCTS_START]: getStockProductsStart,
    [stockTypes.GET_STOCK_PRODUCTS_SUCCESS]: getStockProductsSuccess,
    [stockTypes.GET_STOCK_PRODUCTS_FAILURE]: getStockProductsFailure,
    [stockTypes.IMPORT_STOCK_PRODUCTS_START]: importStockProductStart,
    [stockTypes.IMPORT_STOCK_PRODUCTS_SUCCESS]: importStockProductSuccess,
    [stockTypes.IMPORT_STOCK_PRODUCTS_FAILURE]: importStockProductFailure,
    [stockTypes.IMPORT_PRODUCT_DETAILS_START]: importStockProductDetailsStart,
    [stockTypes.IMPORT_PRODUCT_DETAILS_SUCCESS]: importStockProductDetailsSuccess,
    [stockTypes.IMPORT_PRODUCT_DETAILS_FAILURE]: importStockProductDetailsFailure,
    [stockTypes.UPDATE_PRODUCT_STOCK_START]: updateProductStockStart,
    [stockTypes.UPDATE_PRODUCT_STOCK_SUCCESS]: updateProductStockSuccess,
    [stockTypes.UPDATE_PRODUCT_STOCK_FAILURE]: updateProductStockFailure,
    [stockTypes.UPDATE_PRODUCT_PRICE_START]: updateProductPriceStart,
    [stockTypes.UPDATE_PRODUCT_PRICE_SUCCESS]: updateProductPriceSuccess,
    [stockTypes.UPDATE_PRODUCT_PRICE_FAILURE]: updateProductPriceFailure,
    [stockTypes.UPLOAD_STORE_INVENTORY_START]: uploadStoreInventoryStart,
    [stockTypes.UPLOAD_STORE_INVENTORY_SUCCESS]: uploadStoreInventorySuccess,
    [stockTypes.UPLOAD_STORE_INVENTORY_FAILURE]: uploadStoreInventoryFailure,
    [stockTypes.UPLOAD_STORE_INVENTORY_INITIAL]: uploadStoreInventoryInitial,
    [stockTypes.GET_ASSOCIATE_SERVICE_START]: getAssociateServiceStart,
    [stockTypes.GET_ASSOCIATE_SERVICE_SUCCESS]: getAssociateServiceSuccess,
    [stockTypes.GET_ASSOCIATE_SERVICE_FAILURE]: getAssociateServiceFailure,
    [stockTypes.ADMIN_PAGE_LOADING_START]: adminPageLoadingChange,
    [stockTypes.ADD_SERVICES_START]: addAssociateServiceStart,
    [stockTypes.ADD_SERVICES_SUCCESS]: addAssociateServiceSuccess,
    [stockTypes.ADD_SERVICES_FAILURE]: addAssociateServiceFailure,
    [stockTypes.ADD_SERVICES_STATUS_RESET]: addAssociateServiceReset,
    [stockTypes.GET_STORE_ASSOCIATES_START]: getStoreAssociatesStart,
    [stockTypes.GET_STORE_ASSOCIATES_SUCCESS]: getStoreAssociatesSuccess,
    [stockTypes.GET_STORE_ASSOCIATES_FAILURE]: getStoreAssociatesFailure,
};

export default createReducer(stockContent,handlers);
