import {HomeTypes, HomeActionCreators} from "./actions";
import {takeLatest, put, call, select} from "redux-saga/effects";
import axios from 'axios';
import {
    CONTENT_URL, getAxiosHeaders, getProductDataByBarcode, VALIDATE_DISCOUNT_URL,
} from "../../../utils/constants/service";
import {
    getApiPrefix,
    getCartData,
    getActiveBasketName,
    checkExistingProductInBasket, getProductDataForBasket,getWebInventory
} from "../../../helpers/helpers"
import {LOCAL_STORAGE, SESSION_STORAGE} from "../../../utils/constants/variables";
import {getAccountResource} from "../../login/redux/selectors";
import {getConnectedStatus} from "../../../layouts/main/header/redux/selectors";


export function* getHomeData(params) {
    try {
        const headers = {headers: {language: getApiPrefix()}};
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.id}`, headers)
                .then(res => res.data)
        });
        yield put(HomeActionCreators.homeSuccess(data));
    } catch (e) {
        yield put(HomeActionCreators.homeFailure(e.message));
    }
}

export function* getProductByBarcode(params) {
    try {
        const details = yield select(getAccountResource);
        const isDisconnected = yield select(getConnectedStatus)
        const {barcode, scanType, basket, webStock=false} = params.data;

        if(isDisconnected) {
            const barcodeList = JSON.parse(localStorage.getItem(LOCAL_STORAGE.barcodeList) || '') || []
            const product = barcodeList.find(item => item.barcode === barcode)

            if(!product){
                yield put(HomeActionCreators.OfflineBarcodeFailure());
                throw new Error('Product not found');
            }

            yield put(HomeActionCreators.addtocartStart(getActiveBasketName(), product.productId, {...getProductDataForBasket(product)}));

            return;
        }

        const storeCode = params.data.storeCode ? params.data.storeCode : sessionStorage.getItem(SESSION_STORAGE.storeCode)
        
        const data = yield call(() => getProductDataByBarcode(barcode, details));

        if(webStock){
            yield put(HomeActionCreators.webInventorySuccess(getWebInventory(data,barcode), data))
        }else {
            if (Number.isInteger(data.productId) && !scanType) {
                const cartData = getCartData(data, barcode, storeCode)
                yield put(HomeActionCreators.addtocartStart(basket, data.productId, { ...cartData[data.productId] }));
            }

            if (data.productId && scanType === "remboursement") {
                const product = data.sizes.filter(product => product.productReference === barcode)[0]
                const prSizeId = product ? product.productId : 0;
                if(!checkExistingProductInBasket(data.productId, prSizeId)){
                    const cartData = getCartData(data, barcode, storeCode)
                    yield put(HomeActionCreators.addtocartStart(getActiveBasketName(), data.productId, {...cartData[data.productId]}));
                }
                yield put(HomeActionCreators.addtocartStart(getActiveBasketName(), data.productId, {
                    size: {
                        startingPrice: -1,
                        productId: prSizeId
                    }
                }));
            }

            yield put(HomeActionCreators.getBarcodeProductSuccess(Number.isInteger(data.productId) ? data : {productId: -1, barcode, scanType}));

            if (scanType === 'verify') {
                yield put(HomeActionCreators.barcodeProductVerifyStart(true))
            }
        }

    } catch (e) {
        yield put(HomeActionCreators.getBarcodeProductFailure(e.message));

    }
}

export function* getCouponWebStart(params) {
    const {discountCode,amount,brandId} = params
    try {
        const data = yield call(() =>  { return axios.get(`${VALIDATE_DISCOUNT_URL}?brandId=${brandId}&discountCode=${discountCode}${amount ? `&amount=${amount}` : ""}`, getAxiosHeaders()).then(res => res.data)});
        yield put(HomeActionCreators.getCouponWebSuccess(data));
    } catch (e) {
        yield put(HomeActionCreators.getCouponWebFailure(e.message));
    }
}


export function* homeSaga() {
    yield takeLatest(HomeTypes.HOME_START, getHomeData);
    yield takeLatest(HomeTypes.GET_BARCODE_PRODUCT_START, getProductByBarcode);
    yield takeLatest(HomeTypes.GET_COUPON_WEB_START, getCouponWebStart);
}

export default homeSaga;

