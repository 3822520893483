import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { HomeActionCreators } from '../redux/actions';
import PageMetaData from '../../common/pageMetaData/pageMetaData';
import SummaryBasket from './components/summaryBasket';
import FermeturePopUp from '../../fermeturePopUp/container/fermeturePopUp';
import { getBarcodePopupStatus, getBarcodeProduct, getBarcodeProductVerify, getError, getHome, getLoadingProduct, getoflineBarcodeError } from '../redux/selectors';
import { getConnectedStatus, getLanguage, getMenu, getnotificationCounters } from '../../../layouts/main/header/redux/selectors';
import './home.scss';
import {
    addPaperReceiptProduct,
    destroyActiveBasket,
    getActiveBasketClientId,
    getActiveBasketName,
    getBasketData,
    getBasketProductsBySizes,
    getCurrency,
    getLangPrefix,
    getTaxes,
    replaceComma,
    sortBasketByDate,
    isEmptyBasket,
    isExistPercentGiftInBasket,
    isExistCoupons,
    getSousTotal,
    getBasketProductsBarcodeList,
    getLocalFormData,
    getBasketStoreGifts
} from '../../../helpers/helpers';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';
import MultiBasket from '../../basketPopup/container/multiBasket';
import { getBasket } from '../../addToCart/redux/selectors';
import Calculator from './components/calculator';
import { getAccountResource, isAuthenticated, isTerminalAdmin } from '../../login/redux/selectors';
import VerifyBarcodeModal from './components/verifyBarcodeModal';
import Payment from '../../payment/container';
import InfoPopUp from "../../common/infoPopUp";
import { getIsTerminalOpen } from "../../fermeturePopUp/redux/selectors";
import { DEFAULT_CURRENCY_SYMBOL, LOCAL_STORAGE, PAGE_TYPE, PRODUCT_ITEM_TYPES, SHIPMENT_THRESHOLD, STORE_COUNTRIES, STORE_COUNTRY } from '../../../utils/constants/variables';
import { getDeliveryBasket } from "../../deliveryBasketPopUp/redux/selectors";
import { getLoadingClient, getSingleClient, getStoreClient } from "../../clients/redux/selectors";
import { getLoadingPaperReceipt, getPaperReceipt } from "../../sales/redux/selectors";
import PopUp from "../../popUp/popUp";
import ClientsTab from "../../clients/container/components/clientsTab";
import Quotes from './../../quotes/container/index';
import NewStocks from "../../stocks/container/components/newStocks";
import CustomBtn from "../../common/customBtn";
import { printerOpenCashDrawer } from '../../../utils/constants/service';
import history from "../../../helpers/history";
import { getStatus } from '../../payment/redux/selectors';
import { getQuote } from '../../quotes/redux/selectors';
import CouponWeb from './components/couponWeb';
import { MenuActionCreators } from '../../../layouts/main/header/redux/actions';

interface Props {
    data: any,
    error: any,
    language: string,
    menu: any,
    basket: any,
    authenticated: boolean,
    isLoadingProduct: boolean,
    barcodeProduct: object,
    accountResource: object,
    deliveryBasket: any,
    storeClient: any,
    paperReceipt: any,
    isLoadingPaperReceipt: boolean,
    singleClientData: any,
    isLoadingClient: boolean,
    notificationCounters: object,
    isDisconnected: boolean,
    isBarcodeProductVerify: boolean,
    oflineBarcodeError: boolean,
    isTerminalAdmin: boolean,
    isTerminalOpen: boolean,
    paymentStatus: string,
    isLoadingQuote: boolean,
    quotePaperReceipt: any,
    isBarcodePanelVisible: boolean
}
interface State {
    scrollValue: number,
    allowUser: boolean,
    showTerminalController: boolean,
    showConfirmPopup: boolean,
    showBasket: boolean,
    calculatorValue: string,
    activeAction: string,
    page: any,
    isPromo: boolean,
    isSpecialDiscount: boolean,
    isGiftCard: boolean,
    isPriceBtn: boolean,
    sousTotal: boolean,
    scrollCount: number,
    activeField: string,
    itemsName: any,
    infoMessage: string,
    clientPopUp: boolean,
    showNewProduct: boolean,
    showOflineBarcodeError: boolean,
    tempCalculatorValue: string,
    openPaymentPanel: boolean,
}

class Home extends React.Component<Props & typeof HomeActionCreators, State> {
    state: State = {
        scrollValue: 0,
        allowUser: true,
        showTerminalController: false,
        showConfirmPopup: false,
        showBasket: false,
        calculatorValue: '',
        activeAction: '',
        page: [],
        isPromo: false,
        isSpecialDiscount: false,
        isGiftCard: false,
        isPriceBtn: false,
        sousTotal: false,
        scrollCount: 0,
        activeField: '',
        itemsName: [],
        infoMessage: '',
        clientPopUp: false,
        showNewProduct: false,
        showOflineBarcodeError: false,
        tempCalculatorValue: '',
        openPaymentPanel: false,
    };

    private scrollRef = React.createRef<HTMLDivElement>();
    private scrollRefDiv = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const { menuItem, homeStart, menu, basket } = this.props;
        const items: any[] = [];
        const clientCounter = Number(localStorage.getItem(LOCAL_STORAGE.clientCounter));
        const currentBasketName = getActiveBasketName();
        this.props.basketStoreGiftsStart()

        if (Object.keys(menuItem).length) {
            homeStart(menuItem.gridId);
        }
        this.props.clientCounterStart(clientCounter || 0)

        const clientId = getActiveBasketClientId()
        if (clientId && !this.props.storeClient[currentBasketName]) {
            this.props.storeClientStart(clientId, currentBasketName)
        }

        if (menu.length) {
            const page = menu.filter((item) => item.menuType === PAGE_TYPE);
            this.setState({ page });
        }

        if (basket && Object.keys(basket).length) {
            getBasketProductsBySizes(basket).sort((a, b) => sortBasketByDate(a, b)).map((item, i) => {
                items.push(`${item.parentID}/${item.productId}`);
                if (i === 0) {
                    this.setState(
                        {
                            activeField: `${item.parentID}/${item.productId}`,
                        },
                        () =>
                            this.checkProductPromo(
                                this.state.activeField,
                            ),
                    );
                }
            });

            this.setState({ itemsName: items }, () => this.scrollDownHref(getBasketProductsBySizes(basket).length - 1));

        }
        const elemLength = this.props.basket.sousTotal ? this.props.basket.sousTotal.findIndex(item => item.position === this.state.scrollCount) : -1
        if (elemLength !== -1) {
            this.setState({ sousTotal: true })
        } else {
            this.setState({ sousTotal: false, activeAction: "" })
        }

        document.addEventListener("keydown", this.handleKeyPress, false);
    };

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.activeAction === "reductionMoney" || prevState.activeAction === "reductionPercent")
            && !["reductionMoney", "reductionPercent", "sousTotal"].includes(this.state.activeAction)
        ) {
            this.props.getBasketStart(getActiveBasketName());
            this.checkProductPromo(this.state.activeField)
        }

        if (this.state.activeAction !== prevState.activeAction && this.state.activeAction === "quantity" && !this.state.activeField.length) {
            const { basket } = this.props
            const key = Object.keys(basket)[0]
            this.setState({ activeField: `${key}/${basket[key].sizes[0].productId}`, scrollCount: 0, }, () => {
                this.checkProductPromo(this.state.activeField);
            });
        }

        if (this.props.menuItem.gridId !== prevProps.menuItem.gridId || this.props.language !== prevProps.language) {
            this.props.homeStart(this.props.menuItem.gridId);
        }

        if(!prevProps.isBarcodePanelVisible && this.props.isBarcodePanelVisible) {
            this.setState({ showNewProduct: true })
        }

        if (prevProps.isLoadingProduct && !this.props.isLoadingProduct) {
            if (!this.props.barcodeProduct.scanType) {
                if (this.props.barcodeProduct.productId === -1) {
                    this.setState({ showNewProduct: true })
                } else {
                    const { productId, sizes } = this.props.barcodeProduct
                    this.setState({
                        scrollCount: getBasketProductsBySizes(this.props.basket).length - 1,
                        activeField: `${productId}/${sizes && sizes[0].productId}`,
                    },
                        () => {
                            this.scrollRef.current!.scrollTo(
                                0,
                                this.state.scrollCount * 80,
                            );
                            this.checkProductPromo(this.state.activeField);
                        },
                    );
                }
            }
            this.setState({ activeAction: '' })
        }

        if (prevProps.isLoadingPaperReceipt && !this.props.isLoadingPaperReceipt) {
            this.addPaperReceipt(this.props.paperReceipt, 'ticketNumber')
        }

        if (prevProps.isLoadingQuote && !this.props.isLoadingQuote) {
            this.addPaperReceipt(this.props.quotePaperReceipt, 'quoteNumber')
        }

        if (this.props.oflineBarcodeError !== prevProps.oflineBarcodeError && this.props.oflineBarcodeError) {
            this.setState({ showOflineBarcodeError: true })
        }

        if (prevProps.paymentStatus !== this.props.paymentStatus && this.props.paymentStatus === "SUCCESS") {
            this.setState({ activeAction: '', calculatorValue: '' })
        }

        if (this.props.basket && prevProps.basket && (Object.keys(this.props.basket).length !== Object.keys(prevProps.basket).length)) {
            const items: any[] = [];
            const elemLength = this.props.basket.sousTotal ? this.props.basket.sousTotal.findIndex(item => item.position === this.state.scrollCount) : -1;
            let count = this.state.scrollCount;

            this.checkProductPromo(this.state.activeField);

            if (this.props.basket && Object.keys(this.props.basket).length) {

                const products = getBasketProductsBySizes(this.props.basket);

                if (
                    !(Object.keys(prevProps.basket).length && this.state.scrollCount <= products.length - 1)
                    || products.length !== getBasketProductsBySizes(prevProps.basket).length
                ) {
                    count = products.length - 1
                }

                products.sort((a, b) => sortBasketByDate(a, b)).map((item, i) => {
                    items.push(`${item.parentID}/${item.productId}`);
                    if (i === count) {
                        this.setState({ activeField: `${item.parentID}/${item.productId}` });
                    }
                });

                this.setState({ itemsName: items }, () => this.scrollDownHref(count));



                if (elemLength !== -1) { this.setState({ sousTotal: true }) } else { this.setState({ sousTotal: false, activeAction: "" }) }

            }
        }

        if (this.state.scrollCount !== prevState.scrollCount || (prevState.activeAction !== this.state.activeAction && this.state.activeAction !== "sousTotal")) {
            if (this.props.basket.sousTotal && this.props.basket.sousTotal.length) {
                const elemLength = this.props.basket.sousTotal ? this.props.basket.sousTotal.findIndex(item => item.position === this.state.scrollCount) : -1
                if (elemLength !== -1) {
                    this.setState({ sousTotal: true })
                } else {
                    this.setState({ sousTotal: false })
                }
            }
        }

        if (prevState.activeAction !== this.state.activeAction && (this.state.activeAction === "" && prevState.activeAction === "sousTotal")) {
            if (this.props.basket.sousTotal && this.props.basket.sousTotal.length) {
                const elemLength = this.props.basket.sousTotal ? this.props.basket.sousTotal.findIndex(item => item.position === this.state.scrollCount) : -1
                if (elemLength !== -1) {
                    this.setState({ activeAction: "sousTotal" })
                }
            }
        }
    }

    handleKeyPress = ({ key }) => {
        if (key === "c" || key === "C") {
            const clientCounter = Number(localStorage.getItem(LOCAL_STORAGE.clientCounter)) || 0;
            this.props.clientCounterStart(clientCounter + 1)
        }
    };

    fetchStocks = () => {
        const { accountResource } = this.props;
        const storeCode = accountResource && accountResource.storeDetails && accountResource.storeDetails.storeCode
        const storeType = accountResource && accountResource.storeDetails && accountResource.storeDetails.storeType
        const barcodeList = getBasketProductsBarcodeList()
        if (barcodeList.length && storeCode) {
            this.props.getBasketStocksStart(barcodeList, storeCode, storeType, getActiveBasketName())
        }
    }

    addPaperReceipt = (data, param) => {
        const res = addPaperReceiptProduct(data, param)
        if (res) {
            this.props.getBasketStart()
            const currentBasketName = getActiveBasketName()
            const clientId = getActiveBasketClientId()
            if (clientId && !this.props.storeClient[currentBasketName]) {
                this.props.storeClientStart(clientId, currentBasketName)
            }
        } else {
            this.setState({ infoMessage: i18next.t('Terminal.confirm.11') })
        }
    }

    closeModal = () => this.setState({ showTerminalController: false });

    showTerminalController = () => {
        if (this.props.isTerminalOpen) {
            if (getBasketData(this.props.basket).totalPrice || getBasketData(this.props.deliveryBasket).totalPrice) {
                this.setState({ infoMessage: i18next.t('Terminal.confirm.8') })
            } else {
                this.setState({ showTerminalController: true });
            }
        } else {
            this.setState({ showTerminalController: true });
        }
        if (this.props.accountResource && this.props.accountResource.printer && STORE_COUNTRY !== STORE_COUNTRIES.it) {
            printerOpenCashDrawer()
        }
    };

    toggleRemovePopup = () => this.setState({ showConfirmPopup: false });

    handleDestroyBasket = () => {
        destroyActiveBasket();
        this.props.getBasketStart();
        this.props.basketStoreGiftsStart();
        this.props.resetStoreClient(getActiveBasketName())
        this.setState({ showConfirmPopup: false, activeAction: '', calculatorValue: '', sousTotal: false });
    };

    handleBasketToggle = (show) => this.setState({ showBasket: show });

    terminalReuquest = () => this.setState({ showTerminalController: false });

    handleChangeCalculatorValue = (val = '') => {
        this.setState({ calculatorValue: val });
        if (val === '') {
            this.setState({ activeAction: '', sousTotal: false });
        }
    };

    handleSetActiveAction = (action) => () => {
        if (action === 'webOrder' && !getActiveBasketClientId()) {
            this.setState({ infoMessage: i18next.t('alertMessage.6'), activeAction: "", calculatorValue: '' })
            return
        }
        localStorage.activeAction = action;
        this.setState({ activeAction: action, calculatorValue: '' });
    }

    handleSousTotal = () => this.setState({ sousTotal: true, activeAction: 'sousTotal' });

    doubleClickSousTotal = () => {
        this.setState({ sousTotal: true, activeAction: 'sousTotal', calculatorValue: '' }, () => {
            getSousTotal(this.state.scrollCount)
            this.props.getBasketStart()
        })
    };


    checkProductPromo = (name) => {
        if (name) {
            const ids = name.split('/');
            const activeProduct = this.props.basket[ids[0]];

            if (activeProduct && activeProduct.sizes) {
                let isPromo = false;
                let isSpecialDiscount = false;
                let isPriceBtn = false;
                let isGiftCard = false;

                activeProduct.sizes.forEach(item => {
                    if(item.productId === +ids[1]) {
                        isPromo = item.isPromo;
                        isSpecialDiscount = item.specialDiscountId && item.specialDiscountId.storeSpecialDiscountId;
                        isPriceBtn = item.subTotalDiscount && item.subTotalDiscount.discountDetail || (!item.priceChange && isSpecialDiscount);
                        isGiftCard = item.itemType === PRODUCT_ITEM_TYPES.GFC;
                    }
                })

                this.setState({ isPromo, isSpecialDiscount, isPriceBtn, isGiftCard, activeAction: '' });
                const elemLength = this.props.basket.sousTotal ? this.props.basket.sousTotal.findIndex(item => item.position === this.state.scrollCount) : -1
                if (elemLength !== -1) {
                    this.handleSousTotal()
                }
            }
        }
    };

    scrollDownHref = (index = 0, activeField = '') => {
        if (index ? this.state.scrollCount < index : this.state.scrollCount < this.state.itemsName.length - 1)
            this.setState(
                (prevState) => {
                    return {
                        scrollCount: index ? index : prevState.scrollCount + 1,
                        activeField: activeField || this.state.itemsName[
                            index ? index : prevState.scrollCount + 1
                        ],
                    };
                },
                () => {
                    this.scrollRef.current!.scrollTo(
                        0,
                        this.state.scrollCount * 80,
                    );
                    this.checkProductPromo(this.state.activeField);
                },
            );
    };

    scrollUpHref = () => {
        if (
            this.state.scrollCount > 0 &&
            this.state.scrollCount < this.state.itemsName.length
        ) {
            this.setState(
                (prevState) => {
                    return {
                        scrollCount: prevState.scrollCount - 1,
                        activeField: this.state.itemsName[
                            prevState.scrollCount - 1
                        ],
                    };
                },
                () => {
                    this.scrollRef.current!.scrollTo(
                        0,
                        this.state.scrollCount * 80,
                    );
                    this.checkProductPromo(this.state.activeField);
                },
            );
        }
    };

    handleActiveField = (name, index) => () => {
        this.setState({ activeField: name, scrollCount: index, }, () => {
            this.checkProductPromo(this.state.activeField);
        });
    };

    handleCloseClientPopUp = () => {
        this.setState({ clientPopUp: !this.state.clientPopUp })
    }

    handleCloseInfoPopUp = () => {
        if (this.state.activeAction === 'webOrder') {
            this.setState({ activeAction: '' })
        }
        if(this.state.infoMessage === i18next.t('alertMessage.6') && !getActiveBasketClientId()) {
            this.setState({ infoMessage: '' })
            this.props.history.push(`${getLangPrefix()}/clients`);
        } else {
            this.setState({ infoMessage: '' })
        }
    }

    handleShowUser = () => {
        const { storeClient } = this.props;
        const { profile = {} } = storeClient[getActiveBasketName()] || {}
        if (profile && profile.clientId) {
            this.props.singleClientStart(profile.clientId)
            this.handleCloseClientPopUp()
        }
    }

    handleQuote = (activeAction) => {
        this.setState({ activeAction })
    }
    handleUrl = (url) => {
        history.push(url)
    }

    handleChangeTempCalculatorValue = (value) => this.setState({ tempCalculatorValue: value });

    handleDoubleMethod = (action, val: any = null) => {
        const { tempCalculatorValue } = this.state
        if (action === "verify" && tempCalculatorValue) {
            this.props.getBarcodeProductStart({ barcode: tempCalculatorValue, scanType: action });
            this.handleChangeCalculatorValue('')
        } else {
            this.setState({ activeAction: action, calculatorValue: val || tempCalculatorValue });
        }
    }

    closeNewStockPopUp = () => {
        this.props.closeBarcodePanelStart();
        this.setState({ showNewProduct: false })
    }

    checkIsEmpClient = () => {
        const { clientTypeLabels = [] } = getLocalFormData();
        const currentBasketName = getActiveBasketName();
        const empLabel = clientTypeLabels.find(label => label.code === "EMP");
        const { profile = {}} = this.props.storeClient && this.props.storeClient[currentBasketName] || {};

        return profile && profile.clientType === "EMP"
            && empLabel && empLabel.discountRate;
    }

    loadUrls = (key) => {
        const { page = [] } = this.state;

        if (page.length) {
            const validItem = page.find((item) => item.key === key)
            history.push(getLangPrefix() + validItem.canonicalUrl)
        } else {
            this.props.dispatch(MenuActionCreators.menuStart((menu) => {
                const validItem = menu.find((item) => item.key === key)
                const pageFilter = menu.filter((item) => item.menuType === PAGE_TYPE);
                this.setState({ page: pageFilter });
                history.push(getLangPrefix() + validItem.canonicalUrl)
            }));
        }
    }

    render() {
        const LANGUAGE_PREFIX = getLangPrefix();
        const {
            menu,
            menuItem,
            basket,
            authenticated,
            accountResource,
            storeClient,
            singleClientData,
            isLoadingClient,
            isDisconnected,
            isTerminalAdmin,
            isTerminalOpen,
            notificationCounters = {},
        } = this.props;
        const {
            showTerminalController,
            showConfirmPopup,
            showBasket,
            calculatorValue,
            activeAction,
            page,
            isSpecialDiscount,
            isGiftCard,
            isPriceBtn,
            sousTotal,
            activeField,
            itemsName,
            scrollCount,
            infoMessage,
            clientPopUp,
            showNewProduct,
            showOflineBarcodeError,
            openPaymentPanel
        } = this.state;
        const { price, ticketNumber, totalQuantity, paymentDue, giftTotalAmount, reduction, totalPrice } = getBasketData(basket);
        const disabled = !(authenticated && isTerminalOpen);
        const { terminalCode, taxRate, storeCode = '' } = accountResource;
        const isTicket = disabled || !!ticketNumber;
        const disabledRedBtn = (!ticketNumber && STORE_COUNTRY === STORE_COUNTRIES.it) || !getBasketProductsBySizes(basket).length || paymentDue;
        const taxes = getTaxes(basket, taxRate);
        const { profile = {}, storeClientLoyalty = {} } = storeClient[getActiveBasketName()] || {};
        const disabledPaymentBtn = activeAction === 'devis' ||
            (STORE_COUNTRY === STORE_COUNTRIES.it && totalPrice < 0 && getBasketStoreGifts().length) ||
            (paymentDue ? false : disabled || (ticketNumber && !price) || !totalQuantity || paymentDue);
        const disabledSubTotal = isTicket || isSpecialDiscount || isGiftCard;
        const disabledCoupon = isTicket || isDisconnected || !totalQuantity || isExistPercentGiftInBasket() || isExistCoupons();
        const staticButtons = [
            { menuId: 1, title: i18next.t('home.bottomButtons.1'), key: "clients", canonicalUrl: "" },
            { menuId: 2, title: i18next.t('home.bottomButtons.2'), key: "stocks", canonicalUrl: "" },
            { menuId: 3, title: i18next.t('home.bottomButtons.3'), key: "sales", canonicalUrl: "" },
            { menuId: 4, title: i18next.t('home.bottomButtons.4'), key: "coupons", canonicalUrl: "" },
            { menuId: 5, title: i18next.t('home.bottomButtons.5'), key: "returns", canonicalUrl: "" },
            { menuId: 6, title: i18next.t('home.bottomButtons.6'), key: "quotes", canonicalUrl: "" },
            { menuId: 7, title: i18next.t('home.bottomButtons.7'), key: "transfers", canonicalUrl: "" },
            { menuId: 8, title: i18next.t('home.bottomButtons.8'), key: "inventory", canonicalUrl: "" },
            { menuId: 9, title: i18next.t('home.bottomButtons.9'), key: "deliveries", canonicalUrl: "" },
        ];
        const buttonList = page.length ? page : staticButtons;

        return (
            <main>
                {menuItem && Object.keys(menuItem).length ? (
                    <PageMetaData pageKey={menuItem.key} />
                ) : (
                    ''
                )}
                {showTerminalController ? (
                    <FermeturePopUp
                        history={this.props.history}
                        handleClose={this.closeModal}
                        terminalReuquest={this.terminalReuquest}
                    />
                ) : (
                    ''
                )}
                <InfoPopUp
                    open={!!infoMessage}
                    classN='message-error'
                    message={infoMessage}
                    handleClose={this.handleCloseInfoPopUp}
                />
                <InfoPopUp
                    open={showOflineBarcodeError}
                    classN='message-error'
                    message={i18next.t('Error.5')}
                    handleClose={() => this.setState({ showOflineBarcodeError: false })}
                />

                <div className="container homeContent">
                    <div className="homeContent--productManage">
                        <SummaryBasket
                            value={calculatorValue}
                            handleChangeCalculatorValue={
                                this.handleChangeCalculatorValue
                            }
                            activeAction={activeAction}
                            isSousTotal={sousTotal}
                            scrollRef={this.scrollRef}
                            scrollRefDiv={this.scrollRefDiv}
                            handleActiveField={this.handleActiveField}
                            scrollCount={scrollCount}
                            activeField={activeField}
                            itemsName={itemsName}
                            setActiveField={(
                                activeField: string,
                                itemsName: any[],
                                scrollCount: number,
                            ) => this.setState({ activeField, itemsName, scrollCount })}
                            paperReceipt={this.props.paperReceipt}
                            fetchStocks={this.fetchStocks}
                        />

                        <div className="homeContent--productManage--totalRow">
                            <div className="homeContent--productManage--totalRow--buttonBox ">
                                <button
                                    className="btn_1 summeryBtn "
                                    onClick={this.scrollUpHref}
                                >
                                    {i18next.t('Terminal.Block1.1')}
                                </button>
                                <button
                                    className="btn_1 summeryBtn"
                                    onClick={() => this.scrollDownHref()}
                                >
                                    {i18next.t('Terminal.Block1.2')}
                                </button>
                                <button className={`btn_1 summeryBtn ${activeAction === 'quantity' ? 'active' : ''}${isEmptyBasket() ? " disabled" : ""}`}
                                    disabled={isEmptyBasket()}
                                    onClick={this.handleSetActiveAction('quantity')}
                                >
                                    {i18next.t('Terminal.Block1.3')}
                                </button>
                            </div>
                            <div className="homeContent--productManage--totalRow--totalValue">
                                <div className="homeContent--productManage--totalRow--totalValue--item">
                                    <p className="homeContent--productManage--totalRow--totalValue--item--title">
                                        {i18next.t('Terminal.Block1.4')}:
                                    </p>
                                    <span className="homeContent--productManage--totalRow--totalValue--item--description">
                                        {replaceComma(taxes)} {getCurrency()}{' '}
                                    </span>
                                </div>
                                <div className="homeContent--productManage--totalRow--totalValue--item">
                                    <p className="homeContent--productManage--totalRow--totalValue--item--title">
                                        {i18next.t('Terminal.Block1.5')}:
                                    </p>
                                    <span className="homeContent--productManage--totalRow--totalValue--item--description">
                                        {replaceComma(price)} {getCurrency()}
                                    </span>
                                </div>
                                {
                                    reduction ?
                                        <div className="homeContent--productManage--totalRow--totalValue--item color--green">
                                            <p className="homeContent--productManage--totalRow--totalValue--item--title">
                                                {i18next.t('Terminal.Block2.6')}:
                                            </p>
                                            <span className="homeContent--productManage--totalRow--totalValue--item--description">
                                                {replaceComma(reduction)} {getCurrency()}
                                            </span>
                                        </div>
                                    : ""
                                }
                            </div>
                        </div>

                        <div className="homeContent--productManage--totalRow">
                            <div className="homeContent--productManage--totalRow--buttonBox  buttons-content-middle">
                                <Payment
                                    disabled={disabledPaymentBtn}
                                    inPage="home"
                                    webOrder={activeAction === "webOrder"}
                                    handleOpenPaymentPanel={(status) => this.setState({ openPaymentPanel: status })}
                                    resetAction={() => this.setState({ activeAction: "" })}
                                />

                                <CustomBtn
                                    classN={`btn_1 color_black`}
                                    disabled={disabled}
                                    handleClick={() => this.setState({ showConfirmPopup: true })}
                                >
                                    {i18next.t('Terminal.Block2.2')}
                                </CustomBtn>

                                <CustomBtn
                                    classN={`btn_1 ${sousTotal && !disabledSubTotal ? 'active ' : ''}`}
                                    disabled={disabledSubTotal || this.checkIsEmpClient()}
                                    handleClick={this.handleSousTotal}
                                    handleDoubleClick={this.doubleClickSousTotal}
                                >
                                    {i18next.t('Terminal.Block2.3')}
                                </CustomBtn>

                                <CustomBtn
                                    classN={`btn_1 ${activeAction === 'verify' ? 'active ' : ''}`}
                                    disabled={isTicket || isDisconnected}
                                    handleClick={this.handleSetActiveAction('verify')}
                                    handleDoubleClick={() => this.handleDoubleMethod("verify")}
                                >
                                    {i18next.t('Terminal.Block2.4')}
                                </CustomBtn>

                                <CustomBtn
                                    classN={`btn_1 ${activeAction === 'price' ? 'active ' : ''}`}
                                    disabled={isTicket || isPriceBtn || isGiftCard}
                                    handleClick={this.handleSetActiveAction("price")}
                                    handleDoubleClick={() => this.handleDoubleMethod("price")}
                                >
                                    {i18next.t('Terminal.Block2.5')}
                                </CustomBtn>

                                <CustomBtn
                                    classN={`btn_1 ${activeAction === 'reductionPercent' ? 'active ' : ''}`}
                                    disabled={isTicket || isGiftCard || this.checkIsEmpClient()}
                                    handleClick={this.handleSetActiveAction("reductionPercent")}
                                    handleDoubleClick={() => this.handleDoubleMethod("reductionPercent")}
                                >
                                    {i18next.t('Terminal.Block2.6')}
                                    <span className="percentSign">&nbsp;%</span>
                                </CustomBtn>

                                <Quotes
                                    disabled={isTicket || disabled || isDisconnected || isEmptyBasket()}
                                    activeAction={activeAction}
                                    handleSetActiveAction={this.handleQuote}
                                    calculatorValue={calculatorValue}
                                />

                                <CustomBtn
                                    classN={`btn_1 red_btn ${activeAction === 'remboursement' ? 'active ' : ''}`}
                                    disabled={disabled || disabledRedBtn}
                                    handleClick={this.handleSetActiveAction("remboursement")}
                                    handleDoubleClick={() => this.handleDoubleMethod("remboursement", -1)}
                                >
                                    {i18next.t('Terminal.Block2.8')}
                                </CustomBtn>

                                <CustomBtn
                                    classN={`btn_1 ${activeAction === 'reductionMoney' ? 'active ' : ''}`}
                                    disabled={isTicket || isGiftCard || this.checkIsEmpClient()}
                                    handleClick={this.handleSetActiveAction("reductionMoney")}
                                    handleDoubleClick={() => this.handleDoubleMethod("reductionMoney")}
                                >
                                    {i18next.t('Terminal.Block2.9')}
                                    <span className="percentSign">&nbsp;<span className={`currency ${DEFAULT_CURRENCY_SYMBOL}`}>{DEFAULT_CURRENCY_SYMBOL}</span></span>
                                </CustomBtn>

                                <CustomBtn
                                    classN={`btn_1 ${activeAction === 'webOrder' ? 'active ' : ''}`}
                                    disabled={isTicket || isDisconnected || price < SHIPMENT_THRESHOLD || isEmptyBasket()}
                                    handleClick={this.handleSetActiveAction('webOrder')}
                                >
                                    {i18next.t('Terminal.Block2.12')}
                                </CustomBtn>

                                <div className="btn_1 giftBtnBox">
                                    <CustomBtn
                                        classN={`btn_1 blue_btn giftBtnBox--btn ${activeAction === 'gift' ? 'active ' : ''}`}
                                        disabled={isTicket || isDisconnected}
                                        handleClick={this.handleSetActiveAction('gift')}
                                    >
                                        {i18next.t('Terminal.Block2.13')}
                                    </CustomBtn>
                                    <CouponWeb
                                        disabled={disabledCoupon}
                                        details={accountResource}
                                        amount={price}
                                    />
                                </div>


                                <CustomBtn
                                    classN={`btn_1 orange_btn ${activeAction === 'reductionPercentAll' ? 'active ' : ''}`}
                                    disabled={isTicket || isGiftCard || this.checkIsEmpClient()}
                                    handleClick={this.handleSetActiveAction("reductionPercentAll")}
                                    handleDoubleClick={() => this.handleDoubleMethod("reductionPercentAll")}
                                >
                                    {i18next.t('Terminal.Block2.16')}
                                    <span className="percentSign">&nbsp;%</span>
                                </CustomBtn>
                            </div>
                        </div>
                    </div>

                    <Calculator
                        basket={basket}
                        handleChange={this.handleChangeCalculatorValue}
                        getBarcodeProductStart={
                            this.props.getBarcodeProductStart
                        }
                        activeAction={activeAction}
                        client={{ profile, storeClientLoyalty }}
                        paperReceiptStart={this.props.paperReceiptStart}
                        paperReceiptQuoteStart={this.props.paperReceiptQuoteStart}
                        showUser={this.handleShowUser}
                        terminalCode={terminalCode}
                        handelChangeTemp={this.handleChangeTempCalculatorValue}
                        menu={menu}
                        storeCode={storeCode}
                        isPaymentPanelVisible={openPaymentPanel}
                    />
                </div>
                <div className="homeContent--productManage--totalRow container">
                    <div className="homeContent--productManage--totalRow--buttonBox  buttons-content">
                        {buttonList.length
                            ? buttonList.map((item) => {
                                return (
                                    <React.Fragment key={item.menuId} >
                                        <CustomBtn
                                            classN="btn_1 btn_bottom"
                                            disabled={!authenticated || isDisconnected}
                                            name={item.title}
                                            handleClick={() => this.loadUrls(item.key)}
                                        >
                                            {item.title}
                                            {item.key === "transfers" && notificationCounters.storeTransfers ? <span className="btn_1--number redBg">{notificationCounters.storeTransfers}</span> : ""}
                                            {item.key === "inventory" && notificationCounters.storeInventoryRequests ? <span className="btn_1--number redBg">{notificationCounters.storeInventoryRequests}</span> : ""}
                                            {item.key === "deliveries" && notificationCounters.storeDeliveries ? <span className="btn_1--number redBg">{notificationCounters.storeDeliveries}</span> : ""}
                                            {item.key === "returns" && notificationCounters.openTicketCounter ? <span className="btn_1--number redBg">{notificationCounters.openTicketCounter}</span> : ""}
                                        </CustomBtn>
                                    </React.Fragment>
                                );
                            })
                            : ''}

                        <CustomBtn
                            classN="btn_1 red_btn"
                            disabled={!authenticated || !(accountResource && accountResource.drawer) || isDisconnected || !isTerminalAdmin}
                            name={i18next.t('Terminal.Block2.10')}
                            handleClick={this.showTerminalController}
                        >
                            {isTerminalOpen
                                ? i18next.t('Terminal.Block2.10')
                                : i18next.t('Terminal.Block2.11')}
                        </CustomBtn>
                    </div>
                </div>

                {showBasket && (
                    <MultiBasket handleBasketToggle={this.handleBasketToggle} />
                )}

                {
                    showNewProduct && <PopUp handleClose={() => this.closeNewStockPopUp()}>
                        <NewStocks
                            getBarcodeProductStart={this.props.getBarcodeProductStart}
                            value={this.props.barcodeProduct.barcode}
                            handleClosePopUp={() => this.closeNewStockPopUp()}
                        />
                    </PopUp>
                }

                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={this.toggleRemovePopup}
                        handleConfirm={this.handleDestroyBasket}
                        text={i18next.t('Terminal.confirm.3')}
                    />
                )}

                {this.props.isBarcodeProductVerify && <VerifyBarcodeModal close={this.handleSetActiveAction('')} />}

                {clientPopUp ? <PopUp handleClose={this.handleCloseClientPopUp}>
                    <ClientsTab
                        data={singleClientData}
                        isLoading={isLoadingClient}
                        handleClosePopUp={this.handleCloseClientPopUp}
                        storeCode={storeCode}
                    />
                </PopUp> : ""}

            </main>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const { paperReceipt: quotePaperReceipt, isLoadingPaperReceipt: isLoadingQuote } = getQuote(state);
    return {
        authenticated: isAuthenticated(state),
        menu: getMenu(state),
        data: getHome(state),
        error: getError(state),
        language: getLanguage(state),
        basket: getBasket(state),
        barcodeProduct: getBarcodeProduct(state),
        isLoadingProduct: getLoadingProduct(state),
        accountResource: getAccountResource(state),
        isTerminalOpen: getIsTerminalOpen(state),
        deliveryBasket: getDeliveryBasket(state),
        storeClient: getStoreClient(state),
        paperReceipt: getPaperReceipt(state),
        isLoadingPaperReceipt: getLoadingPaperReceipt(state),
        singleClientData: getSingleClient(state),
        isLoadingClient: getLoadingClient(state),
        notificationCounters: getnotificationCounters(state),
        isDisconnected: getConnectedStatus(state),
        isBarcodeProductVerify: getBarcodeProductVerify(state),
        oflineBarcodeError: getoflineBarcodeError(state),
        isTerminalAdmin: isTerminalAdmin(state),
        paymentStatus: getStatus(state),
        isLoadingQuote,
        quotePaperReceipt,
        isBarcodePanelVisible: getBarcodePopupStatus(state),
    };
};

const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators({...HomeActionCreators, dispatch }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
