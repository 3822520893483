import {createActions} from 'reduxsauce';

export const {Types: DeliveriesTypes, Creators: DeliveriesActionCreators} = createActions ({
    deliveriesStart: null,
    deliveriesSuccess: ['data'],
    deliveriesFailure: ['error'],
    createDeliveriesStart:['data'],
    createDeliveriesSuccess:['data'],
    createDeliveriesFailure:['error'],
    updateStoreDeliveriesStart:['logisticStatus','storeDeliveryId'],
    updateStoreDeliveriesSuccess:[null],
    updateStoreDeliveriesFailure:[null],
    getBarcodeByColisStart:['parcelNumber'],
    getBarcodeByColisFailure:['error'],
    getBarcodeByColisSuccess:['data'],
    emptyBarcodeByColisSuccess: [null],
});
