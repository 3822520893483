import React, { Component } from "react";
import i18next from "i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentSidePanel from './components/paymentSidePanel'
import { PaymentActionCreators } from "../redux/actions";
import {
    getBasketData,
    getActiveBasketClientId,
    getTaxes,
    getStoreLineItems,
    getDateNow,
    getBasketCoupon,
    customRoundByCountry,
    getTax,
    fixedInHundredth,
    getThreeDecimal,
    getRealNumber,
    getNotRoundedRealNumber
} from '../../../helpers/helpers';
import history from '../../../helpers/history';
import { getAccountResource, isAuthenticated } from "../../login/redux/selectors";
import { getBasket } from "../../addToCart/redux/selectors";
import PaymentConfirm from "./components/paymentConfirm";
import CustomBtn from "../../common/customBtn";
import { PAYMENT_CODES, PROCESS_TYPE, STORE_COUNTRIES, STORE_COUNTRY, TERMINAL_DATE, TRANSACTION_TYPES } from '../../../utils/constants/variables';
import { getCreatedStore, getLoading } from '../redux/selectors';
import "./payment.scss";


interface Props {
    basket: any,
    authenticated: boolean,
    accountResource: any,
    disabled?: boolean,
    inPage?: string,
    webOrder?: boolean,
    resetAction?: void,
    loading: boolean,
    createdData: any,
    handleOpenPaymentPanel?: (status: boolean) => void,
}

interface State {
    openModal: boolean,
    openSidePanel: boolean,
    selectedPaymentTerms: string,
    storeSalePayments: object[],
    userPayData: any,
    giftData: any,
    sendEmail: boolean;
    paymentTermCode :{};
}

class Payment extends Component<Props & typeof PaymentActionCreators, State> {

    state: State = {
        openModal: false,
        openSidePanel: false,
        selectedPaymentTerms: "",
        storeSalePayments: [],
        userPayData: {},
        giftData: null,
        sendEmail: true,
        paymentTermCode: {}
    };

    componentDidMount() {
        const openSidePanel = history.location && history.location.state && history.location.state.showPaymentSidePanel;
        const paymentTermCode = history.location && history.location.state && history.location.state.paymentTermCode;
        if (openSidePanel) {
            this.props.handleOpenPaymentPanel(true);
            this.setState({ openSidePanel, paymentTermCode }, () => history.replace({ ...history.location, state: undefined }))
        };
    }

    handleSubmit = () => {
        const { basket, accountResource } = this.props;
        let { ticket = {}, storeGifts = [] } = basket;
        const { taxRate, storeDetails = {} } = accountResource;
        const { selectedPaymentTerms, storeSalePayments, giftData, userPayData, sendEmail } = this.state;
        const { sumAmount, priceAfterTaxes, cash } = userPayData;
        if (storeGifts && storeGifts.length) {
            storeGifts = storeGifts.map(({ active, storeGiftId }) => ({ cardSale: !active, quantity: 1, storeGiftId }))
        }

        try {
            if (!Object.keys(basket).length) {
                this.setState({ openModal: false })
            }
            const storeSaleLineItems: any[] = getStoreLineItems(basket);
            const { price, totalQuantity, ticketNumber, paymentDue, storeSaleFiscal: { transactionNumber, transactionFinish } } = getBasketData(basket);
            const coupons = getBasketCoupon()
            const taxes = getTaxes(basket, taxRate);
            let saleTotal = +price.toFixed(2)
            let saleTotalTax = +taxes.toFixed(2);
            let taxFree = false;
            let prepayment = false;


            if (selectedPaymentTerms === PAYMENT_CODES.c4) {
                taxFree = true;
                saleTotal = saleTotal - saleTotalTax;
                saleTotalTax = 0;
            }

            if (
                selectedPaymentTerms === PAYMENT_CODES.c2 ||
                (ticketNumber && selectedPaymentTerms === PAYMENT_CODES.c1 && paymentDue && paymentDue > 0)
            ) {
                prepayment = true;
            }

            const body: any = {
                orderStatus: "SOL",
                paymentTermCode: selectedPaymentTerms,
                saleQuantity: totalQuantity,
                storeClientId: getActiveBasketClientId(),
                webDiscountId: null,
                saleTotal,
                storeGifts,
                prepayment,
                saleTotalTax,
                taxFree,
                storeSalePayments,
                sendEmail
            };

            if (storeSaleLineItems.length) {
                body.storeSaleLineItems = storeSaleLineItems;
            };
            if (selectedPaymentTerms === PAYMENT_CODES.c7) {
                body.invoice = true;
            };
            if (sumAmount > 0 && sumAmount - priceAfterTaxes > 0) {
                body.paymentChange = customRoundByCountry(sumAmount - priceAfterTaxes);
            };
            if (this.props.webOrder || selectedPaymentTerms === PAYMENT_CODES.c5) {
                body.webDelivery = true
            };
            if (!(getDateNow(TERMINAL_DATE).day === getDateNow(new Date()).day &&
                getDateNow(TERMINAL_DATE).year === getDateNow(new Date()).year &&
                getDateNow(TERMINAL_DATE).month === getDateNow(new Date()).month))
            {
                body.terminalDate = TERMINAL_DATE.getTime();
            };
            if (coupons && coupons.length) {
                body.webDiscountId = coupons[0].webDiscountId
            };

            if(ticketNumber && price) {
                delete body.storeGifts;
            };

            if(ticket.quoteNumber) {
                body.quoteNumber = ticket.quoteNumber;
            };

            if (STORE_COUNTRY === STORE_COUNTRIES.it) {
                if (transactionNumber) {
                    body.referenceTicket = transactionNumber
                }
                if (transactionFinish) {
                    body.referenceTicketDate = transactionFinish
                }
                if (ticketNumber && body.paymentTermCode === PAYMENT_CODES.c1 && ticket.paymentDue) {
                    body.paymentDeposit = ticket.paymentAmount - ticket.paymentDue
                }
            } else {
                if (ticketNumber) {
                    body.referenceTicket = ticketNumber;
                    body.referenceTicketDate = ticket.paymentDate;
                }
                if (ticketNumber && price) {
                    const negativeLineItems = storeSaleLineItems.filter(item => {
                        const product = basket[item.productId]
                        return product && product.sizes && product.sizes[0] && product.sizes[0].startingPrice < 0
                    })

                    if (negativeLineItems.length) {
                        body.storeSaleLineItems = negativeLineItems;
                    }
                }
            };

            if(giftData && !giftData.storeClientId) {
                giftData.storeClientId = getActiveBasketClientId();
                delete giftData.clientCode;
            }

            if (storeDetails.country === STORE_COUNTRIES.de) {
                const transactionType = storeDetails.storeType === 'T' ? TRANSACTION_TYPES.aVTraining : TRANSACTION_TYPES.beleg;
                const cashMethod: any = storeSalePayments.find((item: any) => item.paymentMethodCode === PAYMENT_CODES.c5) || {};
                const bodySaleTotal = giftData ? '0.00' : body.saleTotal.toFixed(2);

                let processData = `${transactionType}^${bodySaleTotal}_0.00_0.00_0.00_0.00^`;
                if (body.paymentTermCode === PAYMENT_CODES.c4) {
                    processData = `${transactionType}^0.00_0.00_0.00_0.00_${bodySaleTotal}^`;
                }

                if (cashMethod.saleAmount) {
                    const cashAmount = cashMethod.saleAmount.toFixed(2)
                    processData += `${cashAmount}:Bar`
                    if (storeSalePayments.length > 1 && cash) {
                        // processData += `_${cash.toFixed(2)}:Unbar`
                        processData += `_${(bodySaleTotal - cashAmount).toFixed(2)}:Unbar`
                    }
                } else {
                    processData += `${bodySaleTotal}:Unbar`
                }
                const fiscalBody = {
                    processData,
                    processType: PROCESS_TYPE
                }

                this.props.createStoreSaleFiscalStart({ fiscalBody, body }, giftData)

            } else if(storeDetails.country === STORE_COUNTRIES.at){
                this.props.createAtStoreSaleStart(body, giftData)
            } else {
                const { price, notRoundedPrice } = getBasketData(basket);
                const residual = getThreeDecimal(price - getNotRoundedRealNumber(notRoundedPrice, 3));
                typeof residual === 'number' && (body.paymentRounding = residual);
                this.props.createStoreStart(body, giftData);
            }

        } catch (e) {
            console.log(e)
        }
    };

    handleCloseModal = () => {
        this.setState({ openModal: false, sendEmail: true })
    };

    handleSetMethod = (method) => {
        const { ticketNumber, paymentDue, paymentTermCode } = getBasketData(this.props.basket);
        const { taxRate } = this.props.accountResource;
        const { selectedPaymentTerms, giftData } = method;

        const storeSalePayments =  method.storeSalePayments.map(item => {
            if(selectedPaymentTerms === PAYMENT_CODES.c2){
                if([STORE_COUNTRIES.de, STORE_COUNTRIES.it, STORE_COUNTRIES.ch, STORE_COUNTRIES.at].includes(STORE_COUNTRY)){
                    const saleTax = parseFloat(fixedInHundredth(getTax(taxRate, item.saleAmount))) || 0;
                    return {...item, saleTax};
                }
                return {...item, saleTax: 0};
            }
            if(ticketNumber && paymentDue > 0 && paymentTermCode === PAYMENT_CODES.c2) {
                if([STORE_COUNTRIES.de, STORE_COUNTRIES.it, STORE_COUNTRIES.ch, STORE_COUNTRIES.at].includes(STORE_COUNTRY)){
                    const saleTax = parseFloat(fixedInHundredth(getTax(taxRate, item.saleAmount))) || 0;
                    return {...item, saleTax};
                }
            }

            return item;
        });
                                    
        this.setState({ openModal: true, selectedPaymentTerms, storeSalePayments, giftData });
    };

    handleCloseSidePanel = () => {
        this.setState({
            openSidePanel: false,
            storeSalePayments: [],
            selectedPaymentTerms: ""
        })
        this.props.handleOpenPaymentPanel(false);
    };

    handleChangeUserPay = (userPayData = {}) => {
        this.setState({ userPayData })
    }
    toggleSidePanel = (status: boolean) => {
        this.setState({ openSidePanel: true })
        this.props.handleOpenPaymentPanel(status);
    }
    render() {
        const { basket, disabled, inPage, accountResource, webOrder, resetAction, handleOpenPaymentPanel } = this.props;
        const { openModal, openSidePanel, userPayData, sendEmail, paymentTermCode } = this.state;
        return (
            <>
                <CustomBtn
                    classN={`${inPage === "basket" ? "articles-content-button payment" : "btn_1 green_btn"}`}
                    disabled={disabled}
                    handleClick={() => this.toggleSidePanel(true)}
                >
                    {i18next.t('Terminal.Block2.1')}
                </CustomBtn>

                {openSidePanel &&
                    <PaymentSidePanel
                        handleClose={this.handleCloseSidePanel}
                        handleCloseModal={this.handleCloseModal}
                        handleSetMethod={this.handleSetMethod}
                        basket={basket}
                        accountResource={accountResource}
                        handleChangeUserPay={this.handleChangeUserPay}
                        webOrder={webOrder}
                        resetAction={resetAction}
                        createLoading={this.props.loading}
                        paymentTermCode={paymentTermCode}
                    />
                }

                <PaymentConfirm
                    openModal={openModal}
                    handleClose={this.handleCloseModal}
                    handleSubmit={this.handleSubmit}
                    userPayData={userPayData}
                    payDisabled={this.props.loading}
                    emails={
                        {
                            checked: sendEmail,
                            onChange: (checked) => this.setState({ sendEmail: checked })
                        }
                    }
                />
            </>
        );
    }
}


const mapStateToProps = (state: any): Props => ({
    authenticated: isAuthenticated(state),
    basket: getBasket(state),
    accountResource: getAccountResource(state),
    loading: getLoading(state),
    createdData: getCreatedStore(state),
});

const mapDispatchToProps = (dispatch): typeof PaymentActionCreators => {
    return bindActionCreators(PaymentActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
