import React, { useEffect, useState } from 'react';
import Clock from '../clock';
import {
    getUserFromToken,
    getDateFromString,
    isValidDate,
    getDateTimestampByTime,
    isTerminalAdmin,
} from '../../../helpers/helpers';
import { Dropdown, Form, Message, Icon } from 'semantic-ui-react';
import PopUp from '../../popUp/popUp';
import i18next from 'i18next';
import { setStoreData } from './../../../utils/constants/variables';
import Cleave from 'cleave.js/dist/cleave-react-node';
import ConfirmPopup from '../confirmPopup/confirmPopup';
import InfoPopUp from '../infoPopUp';

interface IProps {
    account: any;
    clientLogout: () => void;
    clientLogin: (user) => void;
    clientLoginStatus: string;
    showTerminalInfo: boolean;
    authenticated: boolean;
    clock: boolean;
    clockStart: (data: boolean) => void
}

const TerminalInfo: React.FC<IProps> = ({
    account,
    clientLogout,
    clientLogin,
    clientLoginStatus,
    showTerminalInfo,
    authenticated,
    clock,
    clockStart
}) => {
    const [state, setState] = useState({
        terminalAdmin: false,
        terminalDate: '',
        showInfoConfirmPopUp: false,
        switchPopUp: false,
        username: '',
        password: '',
        showDateDropdown: false,
        showPopUp: false,
    });
    const [clockDate, setClockDate] = useState<any>();

    const user = getUserFromToken(),
        formatDate = 'DD/MM/YYYY',
        storeAssociates = account && account.storeAssociates;

    useEffect(() => {
        if (!authenticated && state.password) {
            clientLogin({
                username: state.username,
                password: state.password,
                rememberMe: true,
            });
        }
        if (clientLoginStatus === 'SUCCESS' && state.password) {
            setState((prevState) => {
                return {
                    ...prevState,
                    switchPopUp: !prevState.switchPopUp,
                    password: '',
                };
            });
        }
        if (storeAssociates && storeAssociates.length) {
            storeAssociates.forEach((item) => {
                if (item.associateCode === user.code) {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            terminalAdmin: item.terminalAdmin,
                        };
                    });
                }
            });
        }
    }, [authenticated, clientLoginStatus]);

    useEffect(() => {
        if (clock) {
            handleReset();
            clockStart(false);
        }
    }, [clock])

    const switchClient = () => {
        clientLogout();
    };

    const handleSwitch = (username = '') => {
        setState((prevState) => {
            return {
                ...prevState,
                switchPopUp: !prevState.switchPopUp,
                username: username,
            };
        });
    };

    const handleChange = (target) => {
        const { name, value } = target;

        setState((prevState) => {
            return { ...prevState, [name]: value };
        });
    };

    const handleOpenConfirm = () => {
        const date = state.terminalDate.split('/');
        const isValid =
            date[1] &&
            date[2] &&
            date[0] &&
            date[2].length === 4 &&
            isValidDate(`${date[1]}/${date[0]}/${date[2]}`);
        setState((prevState) => {
            if (isValid) {
                return {
                    ...prevState,
                    showInfoConfirmPopUp: true,
                    showDateDropdown: !isValid,
                };
            }
            return {
                ...prevState,
                showPopUp: true,
            };
        });
    };

    const handleCloseConfirm = () => {
        setState((prevState) => {
            return {
                ...prevState,
                showInfoConfirmPopUp: false,
                terminalDate: '',
            };
        });
    };
    const isPromotionVisible = () => location.pathname.includes('stocks')

    const handleUpdateTerminalDate = () => {
        const date = getDateFromString(state.terminalDate, formatDate, true);
        setStoreData('', '', date);
        setClockDate(date);
        handleCloseConfirm();
    };
    const handleReset = () => {
        setStoreData('', '', new Date());
        setClockDate(undefined);
        setState((prevState) => ({ ...prevState, showDateDropdown: false }));
    };
    const {
        storeDetails = {},
        terminalCode = '',
        storePromotion = {},
        brandApp,
    } = account;
    const { storeType, storeShortName, brandId, country, language, promotionCluster } =
        storeDetails;
    const {
        promotionEndDate = 0,
        promotionName = '',
        promotionStartDate = 0,
    } = storePromotion || {};

    return (
        <li>
            {showTerminalInfo ? (
                <div>
                    {state.terminalAdmin ? (
                        <Dropdown
                            trigger={<Clock terminalDate={clockDate} />}
                            icon={false}
                            className="terminal-info--Clock"
                            onOpen={() =>
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        showDateDropdown: true,
                                    };
                                })
                            }
                            onBlur={() =>
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        showDateDropdown: false,
                                    };
                                })
                            }
                        >
                            <Dropdown.Menu open={state.showDateDropdown}>
                                <Dropdown.Item>
                                    <div className="terminal-info--Clock--body">
                                        <div className={`day--picker-content`}>
                                            <Cleave
                                                placeholder={formatDate}
                                                value={state.terminalDate}
                                                options={{
                                                    date: true,
                                                    delimiter: '/',
                                                    datePattern: [
                                                        'd',
                                                        'm',
                                                        'Y',
                                                    ],
                                                }}
                                                onChange={({ target }) =>
                                                    setState((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            terminalDate:
                                                                target.value,
                                                        };
                                                    })
                                                }
                                            />
                                            <Icon className="calendar alternate outline dateIcons" />
                                        </div>
                                        <div className="terminal-info--Clock--body--controllers">
                                            <button
                                                className="btn_1 red_btn w-100"
                                                onClick={handleOpenConfirm}
                                            >
                                                {i18next.t('buttons.35')}
                                            </button>
                                            <button
                                                className="btn_1 green_btn w-100"
                                                onClick={handleReset}
                                            >
                                                {i18next.t('buttons.36')}
                                            </button>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <div className="terminal-info--Clock">
                            <Clock />
                        </div>
                    )}
                    {storeShortName
                        ? ` | ${storeShortName} | ${storeType} | `
                        : ''}
                    {terminalCode ? ` ${terminalCode} | ` : ''}
                    {country ? ` ${country} | ` : ''}
                    {brandApp ? ` ${brandApp} | ` : ''}
                    {brandId ? ` ${brandId} | ` : ''}
                    <Dropdown
                        text={user.fname || ''}
                        icon={false}
                        className="terminal-info--accountDropDown"
                    >
                        <Dropdown.Menu>
                            {storeAssociates && isTerminalAdmin()
                                ? storeAssociates.map((item) => (
                                      <Dropdown.Item
                                          key={item.associateCode}
                                          text={item.associateFirstName}
                                          disabled={
                                              item.associateCode === user.code
                                          }
                                          onClick={() =>
                                              handleSwitch(
                                                  item.associateFirstName,
                                              )
                                          }
                                      />
                                  ))
                                : ''}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ) : (
                promotionName && (
                    <div>
                        {isPromotionVisible() && `${promotionCluster} | `}
                        {promotionName}
                        {` ${getDateTimestampByTime(
                            promotionStartDate,
                            'dd/mm/yy',
                        )} - ${getDateTimestampByTime(
                            promotionEndDate,
                            'dd/mm/yy',
                        )}`}
                    </div>
                )
            )}
            {state.switchPopUp && (
                <PopUp handleClose={handleSwitch} classN="shwitchClient">
                    <Form
                        className="terminal-info--loginForm"
                        onSubmit={() => switchClient()}
                    >
                        <Form.Field inline>
                            <label>{i18next.t('Terminal.login.8')}</label>
                            <input
                                defaultValue={state.username}
                                disabled={true}
                            />
                        </Form.Field>
                        <Form.Field inline>
                            <label>{i18next.t('Terminal.login.3')}</label>
                            <input
                                placeholder={i18next.t('Terminal.login.3')}
                                type="password"
                                value={state.password}
                                name="password"
                                onChange={({ target }) => handleChange(target)}
                            />
                        </Form.Field>
                        {clientLoginStatus === 'FAILED' ? (
                            <Message negative>
                                <Message.Header>
                                    Utilisateur ou mot de passe incorrect
                                </Message.Header>
                            </Message>
                        ) : (
                            ''
                        )}
                        <button
                            className={`btn_1 w-100 ${
                                !state.password || !state.username
                                    ? 'disabled'
                                    : ''
                            }`}
                            disabled={!state.password || !state.username}
                            type="submit"
                        >
                            {i18next.t('Terminal.login.9')}
                        </button>
                    </Form>
                </PopUp>
            )}
            {state.showInfoConfirmPopUp && (
                <ConfirmPopup
                    handleCancel={handleCloseConfirm}
                    handleConfirm={handleUpdateTerminalDate}
                    text={i18next.t('Terminal.modal.2')}
                />
            )}
            <InfoPopUp
                open={state.showPopUp}
                message={i18next.t('Terminal.modal.5')}
                handleClose={() =>
                    setState((prevState) => ({
                        ...prevState,
                        showPopUp: false,
                    }))
                }
            />
        </li>
    );
};

export default TerminalInfo;
