import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import {Icon} from "semantic-ui-react";

interface IProps {
    value: number,
    onChange: (day?:number) => void,
    resetIcon?: boolean,
    dayPickerProps?: any
}

const FORMAT = 'dd/MM/yyyy';

const parseDate = (str, format, locale) => {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
};

const formatDate = (date, format, locale) => {
    return dateFnsFormat(date, format, { locale });
};

const DayPicker: React.FC<IProps> = ({value, onChange, resetIcon=false, dayPickerProps = {}}) => {

    const handleChange = (day) => {
        onChange(day)
    }

    const date = value !== null ? new Date(value).toString() !== "Invalid Date" ? new Date(value) : new Date() : value

    return (
        <div className="day--picker-content">
            <DayPickerInput
                key={value}
                value={date}
                formatDate={formatDate}
                format={FORMAT}
                parseDate={parseDate}
                placeholder={`${FORMAT}`}
                onDayChange={handleChange}
                dayPickerProps={dayPickerProps}
            />
            {
                resetIcon ?
                    <Icon className="history dateIcons" color='red' onClick={() => handleChange(null)}/>
                    :
                    <Icon className="calendar alternate outline dateIcons"/>
            }

        </div>
    );
}

export default DayPicker
