import {ProductsActionCreators, ProductsTypes} from "./actions";
import {call, put, select, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {PRODUCTS_URL, PRODUCTS_CATALOGUE_URL} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers";
import {BRAND_ID, DEFAULT_CURRENCY, COUNTRIES, LOCAL_STORAGE} from "../../../utils/constants/variables";
import {getAccountResource} from "../../login/redux/selectors";

export function* products(param){
    const { selectedItem, pageSize } = param;
    const { menuId, productModelId, productCategoryId, productType, catalog } = selectedItem;
    localStorage[LOCAL_STORAGE.selectedItem] = JSON.stringify(selectedItem);

    try {
        const details = yield select(getAccountResource);
        const {storeDetails: {country, promotionCluster}} = details;
        let headers: any = {
            headers: {
                ...(catalog && {catalog: catalog}),
                currency: DEFAULT_CURRENCY,
                language: getApiPrefix(),
                country: country,
                promoCluster: promotionCluster
            }
        };
        const fetcher = () => {
            let url = `${PRODUCTS_URL}${menuId}`
            if(productModelId || productCategoryId){
                headers.headers.productType = productType;
                headers.headers[productModelId ? "productModelId": "productCategoryId"] = productModelId || productCategoryId;
                if (selectedItem.permanent) headers.headers.permanent = true;
                url = PRODUCTS_CATALOGUE_URL;
            }
            if(param.filters){
                return axios.post(`${url}?page=${pageSize}&sort=ranking,asc`, JSON.parse(param.filters), headers)
                    .then(res => res.data)
            } else {
                return axios.post(`${url}?page=${pageSize}&sort=ranking,asc`,{}, headers)
                    .then(res => res.data)
            }
        }

        let data = yield call(fetcher);

        if(BRAND_ID === 5 && data.catalogue && !data.catalogue.totalElements){

            if(COUNTRIES[1].includes(country)) {
                headers = {...headers, headers: {...headers.headers, country: "IT", language: getApiPrefix("IT")}}
                data = yield call(fetcher);
                if(data.catalogue && !data.catalogue.totalElements){
                    headers = {...headers, headers: {...headers.headers, country: "FR", language: getApiPrefix("FR")}}
                    data = yield call(fetcher);
                }
            }else  {
                if(COUNTRIES[0].includes(country)) {
                    headers = {...headers, headers: {...headers.headers, country: "IT", language: getApiPrefix("IT")}}
                }

                if(country === COUNTRIES[2]) {
                    headers = {...headers, headers: {...headers.headers, country: "FR", language: getApiPrefix("FR")}}
                }

                data = yield call(fetcher);
            }

        }
        yield put(ProductsActionCreators.productsSuccess(data, selectedItem.menuId, param.filters, pageSize));
    } catch (e) {
        yield put(ProductsActionCreators.productsFailure(e.message));
    }
}

export function* productsSaga(){
    yield takeLatest(ProductsTypes.PRODUCTS_START, products);
}

export default productsSaga;
