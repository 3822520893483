import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {getClients, getSingleClient, getLoading, getLoadingClient} from "./../redux/selectors";
import {ClientActionCreators} from "./../redux/actions";
import './clients.scss';
import TableComponent from "../../datatable/container/datatable"
import PopUp from "../../popUp/popUp";
import i18next from "i18next";
import ClientsTab from "./components/clientsTab";
import { getUniqueId } from '../../datatable/redux/selectors';
import AutocompleteDropdown from '../../common/autocompleteDropdown';
import { getStores } from '../../storeFormSidePanel/redux/selectors';
import { getAccountResource } from '../../login/redux/selectors';
import {Popup} from "semantic-ui-react";
import { getBasketClientIdByName} from "../../../helpers/helpers";

interface Props {
    clientData: any,
    singleClientData: any,
    isLoading: boolean,
    isLoadingClient: boolean,
    uniqueId: string | number,
    stores: any,
    details: any
}

interface State {
    clientPopUp: boolean,
    storeCode: string,
    selectedRow: any

}

class Clients extends Component<Props & typeof ClientActionCreators> {
    state: State = {
        clientPopUp: false,
        storeCode: "",
        selectedRow: {}

    };

    columns: any = [
        {
            name: i18next.t('Terminal.dashboards.firstName'),
            selector: 'firstName',
            sortable: true,
            searchable: true,
            width: "18%",
        },
        {
            name: i18next.t('Terminal.dashboards.lastName'),
            selector: 'lastName',
            sortable: true,
            searchable: true,
            width: "27%",
        },
        {
            name: i18next.t('Terminal.dashboards.zip'),
            selector: 'zip',
            sortable: true,
            searchable: true,
            width: "19%",
        },
        {
            name: i18next.t('Terminal.dashboards.city'),
            selector: 'city',
            sortable: true,
            searchable: true,
            width: "21%",
            cell: row =>
                <Popup
                    inverted
                    trigger={ <div data-tag="___react-data-table-allow-propagation___"> {row.city}</div>}
                    content={row.city}
                    position='bottom center'
                />
        },
        {
            name: i18next.t('Terminal.dashboards.phone'),
            selector: 'phone',
            sortable: true,
            searchable: true,
            width: "15%",
        },
        {
            name: i18next.t('Terminal.dashboards.clientId'),
            selector: 'clientId',
            notShow:true,
            isUnique: true,
        },
        {
            selector: 'birthdate',
            notShow:true,
        },
        {
            selector: 'clientType',
            notShow:true,
        },
        {
            selector: 'comment',
            notShow:true,
        },
        {
            selector: 'currency',
            notShow:true,
        },
        {
            selector: 'dataOptinDate',
            notShow:true,
        },
        {
            selector: 'email',
            notShow:true,
        },
        {
            selector: 'gender',
            notShow:true,
        },
        {
            selector: 'newsOptinDate',
            notShow:true,
        },
        {
            selector: 'phone',
            notShow:true,
        },
        {
            selector: 'storeClientId',
            notShow:true,
        },
        {
            selector: 'webClientId',
            notShow:true,
        },
    ];
    conditionalRowStyles: any[] = [
        {
            when: row => row.clientId === getBasketClientIdByName(),
            style: {
                backgroundColor: "green",
            },
        },
    ];
    componentDidMount() {
        const { location = {}, history } = this.props
        this.props.clientStart();
        this.props.storesStart();
        if(location.state && location.state.clientId){
            this.openUserPopUp(location.state)
            history.replace({ ...history.location, state: undefined });
        }
        if (localStorage.emptyEmailOrAddressClientId) {
            this.openUserPopUp({ clientId: localStorage.emptyEmailOrAddressClientId })
            localStorage.removeItem('emptyEmailOrAddressClientId');
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.uniqueId && this.props.uniqueId !== prevProps.uniqueId){
            this.setState({selectedRow: {id: this.props.uniqueId, fieald: 'clientId'}})
        }
    }

    openUserPopUp = (e) => {
        this.props.singleClientStart(e.clientId)
        this.setState({clientPopUp: true});
    }

    handleClosePopUp = () => {
        this.setState({clientPopUp: false});
    }

    handleChangeStore = (value) => {
        this.setState({storeCode: value})
        this.props.clientStart({code: value});
    }

    render() {
        const {clientData, singleClientData, isLoading, isLoadingClient, stores, details: {storeDetails = {}, storeCode = ''} = {}} = this.props;
        const {clientPopUp, selectedRow} = this.state;

        return (
            <div className="container">

                <div className="stockContent">
                    <TableComponent
                        tableData={clientData}
                        isLoading={isLoading}
                        openPopUp={this.openUserPopUp}
                        columns={this.columns}
                        buttons={["setClientBtn","nouveaubtn"]}
                        reloadDashboard={this.props.clientStart}
                        selectedRow={selectedRow}
                        storeCode={this.state.storeCode || storeCode}
                        keyField={"client"}
                        conditionalRowStyles={this.conditionalRowStyles}
                    >
                        <div className='store--client--dropdown'>
                            <AutocompleteDropdown
                                data={stores.filter(item => storeDetails.storeType === item.storeType || item.storeType === "W")}
                                value={this.state.storeCode || storeCode}
                                disabledOption={this.state.storeCode || storeCode}
                                search={true}
                                keyName="code"
                                textName={['name','address']}
                                valueName="storeCode"
                                placeholder={ i18next.t('Terminal.transferBasket.3')}
                                classN={"deleveryBasket--storeSelector--dropDown"}
                                onChange={(value) =>this.handleChangeStore(value)}
                                optionClass={{action: `currentValue.storeCode === "${storeCode}"`, name: "blueText"}}
                            />
                        </div>
                    </TableComponent>
                </div>
                {clientPopUp ? <PopUp handleClose={this.handleClosePopUp} classN={"clients"}>
                    <ClientsTab data={singleClientData} isLoading={isLoadingClient} handleClosePopUp={this.handleClosePopUp} storeCode={this.state.storeCode || storeCode}  />
                </PopUp> : ""}

            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    clientData: getClients(state),
    singleClientData: getSingleClient(state),
    isLoading: getLoading(state),
    isLoadingClient: getLoadingClient(state),
    uniqueId: getUniqueId(state),
    stores: getStores(state),
    details: getAccountResource(state),
});

const mapDispatchToProps = (dispatch): typeof ClientActionCreators => {
    return bindActionCreators(ClientActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
