import {createActions} from 'reduxsauce';

export const {Types: ReportsTypes, Creators: ReportsActionCreators} = createActions ({
    printReportStart: ['reportType', 'currency'],
    printReportSuccess: ['data'],
    printReportFailure: ['error'],
    paymentsReportStart:['currency'],
    paymentsReportSuccess: ['data', 'currency'],
    paymentsReportFailure: ['error'],
});
