import React from "react";
import {connect} from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import {bindActionCreators} from "redux";
import {IProductsState} from "../redux/reducers";
import {ProductsActionCreators} from "../redux/actions";
import {getProducts, loading, loadMoreData, getMenu} from "../redux/selectors";
import {MetroSpinner} from "react-spinners-kit";
import CategoryFilters from '../../../layouts/common/categoryFilters/container/categoryFilters';
import ProductList from './productList';
import {getItemMenu} from '../../../helpers/helpers';
import {CATALOGUE_TYPE, MENU_TYPE, DEFAULT_CURRENCY} from "../../../utils/constants/variables";
//styles
import "./products.scss";

interface Props extends IProductsState {
    canonicalUrl?: string
}

interface ProductState {
    products: any,
    loading: boolean,
    loadMoreData: boolean,
    menuList: any,
}

interface State {
    ready: boolean;
    pageSize: number;
    selectedItem: any;
    filterModalIsOpen: boolean;
    totalElements: number;
    parent: any;
    resetFiltre:boolean,
    resetFiltrePath: string,
    filters: any,
    width:any,
    currency: string
}

class Products extends React.Component<Props & typeof ProductsActionCreators, State, ProductState> {
    state: State;
    productsRef: React.RefObject<any>;
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            ready: false,
            pageSize: 0,
            selectedItem: {},
            filterModalIsOpen: false,
            totalElements: 0,
            parent: [],
            resetFiltre: false,
            resetFiltrePath:"",
            filters:{},
            currency: DEFAULT_CURRENCY
        };

        this.productsRef = React.createRef();
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);

        if(this.props.canonicalUrl && this.props.location.pathname !== this.props.canonicalUrl) this.props.history.push(this.props.canonicalUrl);
        this.setSelectedItem(this.props);
    }

    componentWillReceiveProps = async (nextProps) => {
        const {menuList} = this.props;
        const {pageSize} = this.state;

        if (menuList !== nextProps.menuList) {
            const {menuItem} = this.props;
            await this.fetchProducts(menuItem, pageSize);
        }

        if (nextProps.products && Object.keys(nextProps.products).length) {
            this.setState({ready: true});
        }

    };
    componentDidUpdate = async (prevProps, prevState) => {
        const {products, menuItem} = this.props;
        if (products.filters !== prevProps.products.filters) {
            if(!this.props.products.filters){
                const setPathName = this.props.location.pathname.substring(0, this.props.location.pathname.indexOf('filtres'));

                this.setState({resetFiltre: true,resetFiltrePath:setPathName});
                this.props.products
                    && this.props.products.catalogue
                    && this.props.products.catalogue.content
                    && window.location.assign(setPathName)
            }
        }
        if(products.catalogue !== prevProps.products.catalogue && !products.catalogue.content) {
            this.props.history.push({
                pathname: '/',
                state: {showMenu: true, activeIndex: localStorage.activeMenuItem}
            });
        }
        if(this.state.currency !== DEFAULT_CURRENCY) {
            this.setState({currency: DEFAULT_CURRENCY, pageSize: 0})
            await this.fetchProducts(menuItem, 0);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.resetFiltre != nextState.resetFiltre;
    }
    fetchMoreData = async () => {
        const {pageSize, selectedItem} = this.state;
        const {loadMoreData, products} = this.props;
        const {catalogue} = products;

        if (catalogue && catalogue.content && catalogue.content.length ) {
            if (catalogue.totalElements === catalogue.content.length) {
                return
            }
        }

        if (catalogue && catalogue.content && catalogue.numberOfElements < catalogue.size) {
            return
        }

        if (!loadMoreData) {
            return;
        }
        await this.fetchProducts(selectedItem, pageSize + 1);
        this.setState((current) => ({...current, pageSize: pageSize + 1}))
    };

    fetchProducts = async (selectedItem, pageSize) => {
        const filters = JSON.stringify(this.state.filters);
        await this.props.productsStart(selectedItem, pageSize, filters);
    };

    setSelectedItem(props) {
        const {pageSize,filters} = this.state;
        const {menuItem} = props;
        let parent: any = [];
        if (menuItem.menuType === CATALOGUE_TYPE) {
            const parentEl = getItemMenu(MENU_TYPE, menuItem.key, this.props.menuList);
            parent = parentEl ? [parentEl] : [];
        }
        this.setState({selectedItem: menuItem, parent},  () => {
            if (filters !== null) {
                this.fetchProducts(menuItem, pageSize);
            }
        });
    }

    toggleClick = () => {
        const {filterModalIsOpen} = this.state;
        if (!filterModalIsOpen) {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
        }

        this.setState({
            filterModalIsOpen: !filterModalIsOpen
        });
    };

    updateProducts = async (filtersObj, load = true, type = "") => {
        const {products} = this.props;
        let requestObj : any = {filters:{...filtersObj}}
        if (filtersObj.Color && filtersObj.Color.length ) {
            requestObj= {filters:{...filtersObj},filterColor: true}
        }else {
            requestObj = {filters:{...filtersObj},filterColor: false}
        }
        const colors = products.filters && products.filters.find(item => item.searchFilter.toUpperCase() === "COLOR");
        if(colors && colors.values && colors.values.length){
            let newObj :any = {...requestObj};
            requestObj= {...newObj,existingColors:colors.values}
        }
        this.setState({pageSize: 0,filters:requestObj},  () => load ? this.fetchProducts(this.state.selectedItem, 0):"");
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize =() => {this.setState({width: window.innerWidth})};

    render(): React.ReactNode {

        const {products, loading, menuList, loadMoreData, menuItem} = this.props;
        const {catalogue} = products;
        const {filterModalIsOpen, ready,  resetFiltre,} = this.state;
        let size = catalogue ?
            catalogue.content && catalogue.content.length && (catalogue.content.length <= catalogue.totalElements)
                ? catalogue.content.length : catalogue.totalElements
            : 0;
        return (
            <div>
                <section className={`product-section container ${resetFiltre? "centeredBox":""}`} ref={this.productsRef}>
                    <CategoryFilters
                        menuList={menuList ? menuList : []}
                        filters={products && products.filters ? products.filters : []}
                        toggle={filterModalIsOpen}
                        toggleClick={this.toggleClick}
                        updateFilters={this.updateProducts}
                    />
                    <div className={`product-content-outer  ${resetFiltre? "centeredBox":""}`}>
                        <InfiniteScroll
                            dataLength={size}
                            next={this.fetchMoreData}
                            hasMore={ready && loadMoreData}
                            scrollThreshold={0.3}
                            loader={
                                <MetroSpinner
                                    size={50}
                                    color="#686769"
                                    loading={loading}
                                />
                            }
                        >
                            <ProductList menuItem={menuItem} list={products.catalogue ? products.catalogue : []}
                                         handleClick={this.toggleClick} loading={loading} resetFiltre={resetFiltre} />
                        </InfiniteScroll>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (productState: any): ProductState => ({
    products: getProducts(productState),
    loading: loading(productState),
    loadMoreData: loadMoreData(productState),
    menuList: getMenu(productState),
});

const mapDispatchToProps = (dispatch): typeof ProductsActionCreators => {
    return bindActionCreators(ProductsActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
