import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import BarcodeReader from 'react-barcode-reader';
import {getStoreGifts, getLoading} from "../redux/selectors";
import {StoreGiftsActionCreators} from "../redux/actions";
import TableComponent from "../../datatable/container/datatable"
import PopUp from "../../popUp/popUp";
import i18next from "i18next";
import BonsCadeauxPopUp from "./components/bonsCadeauxPopUp";
import {Popup} from "semantic-ui-react";
import "./bons.scss";

interface Props {
    storeGifts: any,
    isLoading: boolean
}

interface State {
    isShowPopUp: boolean,
    singleStoreGifts: object,
    isLoadingSingle: boolean,
}

class BonsCadeaux extends Component<Props & typeof StoreGiftsActionCreators> {
    state: State = {
        isShowPopUp: false,
        singleStoreGifts: {},
        isLoadingSingle: false,
    };

    columns: any = [

        {
            name: i18next.t('Terminal.dashboards.endDate'),
            selector: 'endDate',
            sortable: true,
            width: "15%",
            format: 'dd/mm/yy',
        },
        {
            name: i18next.t('Terminal.dashboards.storeGiftCode'),
            selector: 'storeGiftCode',
            sortable: true,
            width: "19%",
            searchable: true,
            isUnique: true,
        },
        {
            name: i18next.t('Terminal.dashboards.credit'),
            selector: 'credit',
            width: "14%",
            searchable: true,
            notShow:false,
            sortable: true,
            cell: row =>
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {
                                row.credit ?
                                    i18next.t('Terminal.dashboards.toHave')
                                    :
                                    i18next.t('Terminal.dashboards.nicePresent')
                            }
                        </div>
                    }
                    content={
                        row.credit ?
                            i18next.t('Terminal.dashboards.toHave')
                            :
                            i18next.t('Terminal.dashboards.nicePresent')
                    }
                    position='bottom center'
                />
        },
        {
            name: i18next.t('Terminal.dashboards.clientLastName'),
            selector: 'clientLastName',
            sortable: true,
            searchable: true,
            width: "10%",
            cell: row =>
                <Popup
                    inverted
                    trigger={ <div data-tag="___react-data-table-allow-propagation___"> {row.clientLastName}</div>}
                    content={row.clientLastName}
                    position='bottom center'
                />
        },
        {
            name: i18next.t('Terminal.dashboards.percent'),
            selector: 'percent',
            sortable: true,
            width: "14%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.Price'),
            selector: 'amount',
            sortable: true,
            width: "14%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.actif'),
            selector: 'redemptionDate',
            width: "8%",
            sortable: true,
            center: true,
            cell: row =>
                <div data-tag="___react-data-table-allow-propagation___" className={`text-center ${row.active ? 'color--green' : 'color--red'}`}>
                    {
                        row.active ?
                            "Y"
                            :
                            "N"
                    }
                </div>,
        },
        {
            name: i18next.t('Terminal.dashboards.valide'),
            selector: 'redemptionDate',
            width: "6%",
            sortable: true,
            center: true,
            cell: row =>
                <div data-tag="___react-data-table-allow-propagation___" className={`text-center ${row.redemptionDate  ? 'color--red' : 'color--green'}`}>
                    {
                        row.redemptionDate  ?
                            "N"
                            :
                            "Y"
                    }
                </div>,

        },
        {
            name: i18next.t('Terminal.dashboards.id'),
            selector: 'storeGiftId',
            notShow:true,
            isUnique: true,
        },
        {
            selector: 'active',
            notShow:true,
        },
        {
            selector: 'barcode',
            notShow:true,
        },
        {
            selector: 'clientCode',
            notShow:true,
        },
        {
            selector: 'currency',
            notShow:true,
        },
        {
            selector: 'qrCode',
            notShow:true,
        },
        {
            selector: 'startDate',
            notShow:true,
        },
        {
            selector: 'expired',
            notShow:true,
        },
    ];

    componentDidMount() {
        this.props.storeGiftsStart();
    }
    openPopUp = (e) => {
        this.setState({isLoadingSingle: true})
        const singleStoreGifts = this.props.storeGifts.find(item=>item.storeGiftId === e.storeGiftId) || {}
        this.setState({isShowPopUp: true, singleStoreGifts,isLoadingSingle: false});
    }
    handleClosePopUp = () => {
        this.setState({isShowPopUp: false});
    }

    handleScan = (barcode) => {
        const singleStoreGifts = this.props.storeGifts.find(item=>item.storeGiftCode === barcode) || {}
        if(singleStoreGifts.storeGiftId) {
            this.setState({isShowPopUp: true, singleStoreGifts});
        }
    }

    render() {
        const {storeGifts, isLoading} = this.props;
        const {isShowPopUp, singleStoreGifts,isLoadingSingle} = this.state;

        return (
            <div className="container">
                <div className="stockContent">
                    <BarcodeReader onScan={this.handleScan} />
                    <TableComponent
                        tableData={storeGifts}
                        isLoading={isLoading}
                        openPopUp={this.openPopUp}
                        columns={this.columns}
                        buttons={['setStoreGift']}
                        reloadDashboard={this.props.storeGiftsStart}
                        keyField={"bonsCadeaux"}
                    />
                </div>
                {isShowPopUp ? <PopUp handleClose={this.handleClosePopUp} loading={isLoadingSingle}>
                    <BonsCadeauxPopUp data={singleStoreGifts} handleClosePopUp={this.handleClosePopUp} />
                </PopUp> : ""}
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    storeGifts: getStoreGifts(state),
    isLoading: getLoading(state)
});

const mapDispatchToProps = (dispatch): typeof StoreGiftsActionCreators => {
    return bindActionCreators(StoreGiftsActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BonsCadeaux);
