import React, {useState, useEffect} from 'react';
import PopUp from "../../../popUp/popUp";
import i18next from "i18next";

interface IProps {
    status: string,
    clientEmail: string,
    printer: boolean,
    printTicket: (data)=>void,
    destroyBasket: ()=>void,
}

const QuotesSidePanel : React.FC<IProps> = ({status, clientEmail, printer, printTicket, destroyBasket}) => {
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        if(status === 'success'){
            setOpen(true)
        }
    }, [status])

    if(!isOpen) return <></>

    const handleDestroyBasket = () => {
        destroyBasket()
        setOpen(false)
    }

    return (
        <PopUp handleClose={() => {}} classN="quotesContent">
            <div>

                <button className={`btn_1 red_btn w-100`} onClick={handleDestroyBasket}>
                    {i18next.t('Terminal.Block3.4')}
                </button>
                <p className="success--message">
                    {i18next.t('alertMessage.2')}
                </p>

                {clientEmail ?
                    <>
                        <p className="success--message">
                            {i18next.t('Terminal.payment.6', {clientEmail})}
                        </p>
                        <button
                            className={`btn_1 w-100 green_btn${printer ? "" : " disabled"}`}
                            disabled={!printer}
                            onClick={printTicket}
                        >
                            {i18next.t('Terminal.Block3.5')}
                        </button>
                    </>
                    :
                    ""
                }
            </div>
        </PopUp>
    )
}

export default QuotesSidePanel;
