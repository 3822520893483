import { MenuTypes, MenuActionCreators } from './actions';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import axios from 'axios';
import {
    FISCAL_TSS_DETAILS,
    FISCAL_TSS_DETAILS_AT,
    getAxiosHeaders,
    MENU_URL,
    NOTIFICATION_COUNTERS_URL,
} from '../../../../utils/constants/service';
import { getApiPrefix } from '../../../../helpers/helpers';
import {
    BRAND_ID,
    LOCAL_STORAGE,
    STORE_COUNTRIES,
    STORE_COUNTRY,
} from '../../../../utils/constants/variables';
import { getConnectedStatus } from './selectors';

export function* getMenuData(params) {
    const { callback = null } = params;
    try {
        const headers = {
            headers: {
                brand: BRAND_ID,
                language: getApiPrefix(),
                menuType: 'MENU',
            },
        };
        let data = yield call(() => {
            return axios.get(`${MENU_URL}`, headers).then((res) => {
                return res.data;
            });
        });
        if (Array.isArray(data) && data.length) {
            localStorage.setItem(LOCAL_STORAGE.menuList, JSON.stringify(data));
        } else {
            data = JSON.parse(localStorage[LOCAL_STORAGE.menuList] || '[]');
        }
        yield put(MenuActionCreators.menuSuccess(data));
        callback && callback(data);
    } catch (e) {
        yield put(MenuActionCreators.menuFailure(e.message));
    }
}

export function* getnotificationCounters() {
    const isDisconnected = yield select(getConnectedStatus);
    try {
        const data = yield call(() => {
            return axios
                .get(
                    `${NOTIFICATION_COUNTERS_URL}?language=${getApiPrefix()}`,
                    getAxiosHeaders(),
                )
                .then((res) => {
                    return res.data;
                });
        });
        yield put(MenuActionCreators.notificationCountersSuccess(data));

        if (isDisconnected) {
            yield put(MenuActionCreators.networkDetectorStart(false));
        }
    } catch (e) {
        if (isDisconnected && e.message !== 'Network Error') {
            yield put(MenuActionCreators.networkDetectorStart(false));
        }
        yield put(MenuActionCreators.notificationCountersFailure(e.message));
    }
}

export function* fetchFiscalTssDetails() {
    try {
        const url =
            STORE_COUNTRY === STORE_COUNTRIES.de
                ? FISCAL_TSS_DETAILS
                : FISCAL_TSS_DETAILS_AT;
        const data = yield call(() => {
            return axios.get(url, getAxiosHeaders()).then((res) => res.data);
        });

        yield put(MenuActionCreators.fiscalTssDetailsSuccess(data));
    } catch (e) {
        yield put(MenuActionCreators.fiscalTssDetailsFailure(e.message));
    }
}

export function* menuSaga() {
    yield takeLatest(MenuTypes.MENU_START, getMenuData);
    yield takeLatest(
        MenuTypes.NOTIFICATION_COUNTERS_START,
        getnotificationCounters,
    );
    yield takeLatest(MenuTypes.FISCAL_TSS_DETAILS_START, fetchFiscalTssDetails);
}

export default menuSaga;
