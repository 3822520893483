import {createActions} from 'reduxsauce';

export const {Types: HomeTypes, Creators: HomeActionCreators} = createActions ({
    homeStart: ['id'],
    homeSuccess: ['data'],
    homeFailure: ['error'],
    validateDiscountStart: ['data'],
    addtocartStart: ["name", "key", "value", "setAsQuantity"],
    getBasketStart: ['name'],
    getBarcodeProductStart: ['data'],
    getBarcodeProductSuccess: ['data'],
    getBarcodeProductFailure: ['error'],
    barcodeProductVerifyStart: ['data'],
    resetStoreClient: ["basketName"],
    clientCounterStart:["count"],
    basketStoreGiftsStart: null,
    storeClientStart: ['clientId', "basketName"],
    paperReceiptStart: ["data"],
    singleClientStart:["id"],
    OfflineBarcodeFailure: null,
    webInventorySuccess:['webInventory', 'data'],
    paperReceiptQuoteStart: ["body"],
    getBasketStocksStart: ['barcodeData','storeCode', 'storeType','basket'],
    getCouponWebStart: ["discountCode","amount","brandId"],
    getCouponWebSuccess: ["data"],
    getCouponWebFailure: ["error"],
    openBarcodePanelStart: ["data"],
    closeBarcodePanelStart: [null],
});
