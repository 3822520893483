import React from 'react'
import FormButtonGroup from "./formButtonGroup";
interface IProps {
    storeEvents: any,
    storeWeathers: any,
    storeTemperatures: any,
    storeEventId: string | number,
    storeWeatherId: string | number,
    storeTemperatureId: string | number,
    handelChangeState: (param)=>void,
}
const TerminalStep1: React.FC<IProps> = ({storeEvents, storeEventId, storeWeathers, storeWeatherId, storeTemperatures, storeTemperatureId, handelChangeState}: IProps)  => {
    return (
        <div className="fermeturePopUp--body-form-content-btn--groups">
            <div className="table-store-counter--Content">
                <FormButtonGroup data={storeEvents} label="event" selectedId={storeEventId}
                                 handelClick={(id) => handelChangeState({storeEventId: id})}/>
                <FormButtonGroup data={storeWeathers} label="weather" selectedId={storeWeatherId}
                                 handelClick={(id) => handelChangeState({storeWeatherId: id})}/>
                <FormButtonGroup data={storeTemperatures} label="temperature" selectedId={storeTemperatureId}
                                 handelClick={(id) => handelChangeState({storeTemperatureId: id})}/>
            </div>
        </div>
    )
}

export default TerminalStep1
