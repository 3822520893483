export const getHome = state => state.homeContent.data;
export const getError = state => state.homeContent.error;
export const getBarcodeProduct = state => state.homeContent.barcodeProduct;
export const getSelectedSizeProduct = state => state.homeContent.selectedSize;
export const getBarcodeProductVerify = state => state.homeContent.isBarcodeProductVerify;
export const getLoadingProduct = state => state.homeContent.isLoadingProduct;
export const getBarcodeProductStatus = state => state.homeContent.getBarcodeProductStatus;
export const getoflineBarcodeError= state => state.homeContent.OfflineBarcodeFailure;
export const getWebInventory = state => state.homeContent.webInventory;
export const getWebCoupon = state => state.homeContent.coupon;
export const getWebCouponStatus = state => state.homeContent.couponStatus;
export const getWebCouponLoading = state => state.homeContent.couponLoading;
export const getBarcodePopupStatus = state => state.homeContent.isBarcodePanelVisible;
