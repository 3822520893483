import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import {
    fixedInHundredth,
    getDateTimestamp,
    sortArrayOfObject,
    textToBase64Barcode,
} from '../../../../helpers/helpers';
import PdfTable from '../../../pdfGenerator/pdfTableBtn';

export default function InventoryPdfButtons(props) {
    const [dataPdf, setDataPdf] = useState([]);
    const [dataEcartPdf, setDataEcartPdf] = useState([]);
    const [zoneOptions, setZoneOptions] = useState([]);
    const [isExistData, setIsExistData] = useState(false);
    const [pdfInfo, setPdfInfo] = useState<any>([]);

    const columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '13%',
            type: '',
            // type:
            //     this.props.inventory &&
            //     this.props.inventory.fullInventory === false
            //         ? 'image'
            //         : '',
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '17%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.storeChecked'),
            selector: 'qtyInventory',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.stockDefect'),
            selector: 'stockDefect',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.zone'),
            selector: 'zone',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.totalZone'),
            selector: 'totalZone',
            width: '10%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.stock'),
            selector: 'stocks',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.dashboards.ecart'),
            selector: 'ecart',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.comment'),
            selector: 'comment',
            width: '10%',
        },
    ];

    const columnsEcartPdf: any = columnsPdf.map((item) => {
        if (item.selector === 'barcode') {
            return { ...item, type: 'image' };
        }
        return item;
    });

    const {
        details: { storeDetails = {} } = {},
        inventory: { inventoryRequestCode = '', associateFirstName = '' } = {},
        storeInvertoryProducts,
        positiveTotalStocks,
        totalEcart,
        totalPositiveEcart,
        totalNegativeEcart,
        totalQty,
        requestDate,
        totalZones,
    } = props;

    const storeName = storeDetails.name;

    useEffect(() => {
        if (!isExistData) {
            setIsExistData(true);
        }
    }, [storeInvertoryProducts]);

    useEffect(() => {
        const dataPdf: any = [];
        const dataEcartPdf: any = [];
        const zoneOptions: any = [{ key: '', text: '', value: '' }];
        const pdfInfo = [
            `${i18next.t(
                'Terminal.dashboards.stockTotal',
            )}: ${positiveTotalStocks}`,
            `${i18next.t('Terminal.dashboards.ecart')}: ${
                totalEcart > 0 ? '+' + totalEcart : totalEcart
            } (${
                totalPositiveEcart ? '+' + totalPositiveEcart : 0
            }/${totalNegativeEcart})`,
            `${i18next.t('Terminal.dashboards.storeChecked')}: ${totalQty}`,
        ];

        sortArrayOfObject(storeInvertoryProducts, 'zone').forEach(
            (item, idx) => {
                const valueQty = fixedInHundredth(
                    props.getItemEcart(item, totalZones),
                );

                if (
                    item.zone &&
                    zoneOptions.findIndex((el) => el.value === item.zone) === -1
                ) {
                    zoneOptions.push({
                        key: idx,
                        text: item.zone,
                        value: item.zone,
                    });
                }

                if (item.qtyInventory > 0) {
                    const index = dataPdf.findIndex(
                        (el) => el.productReference === item.barcode,
                    );

                    if (index === -1) {
                        dataPdf.push({
                            productReference: item.barcode,
                            barcode: item.barcode, //fullInventory === false  ? textToBase64Barcode(item.barcode, 'EAN13') : item.barcode
                            name: item.name,
                            size: item.size,
                            qtyInventory: item.qtyInventory,
                            stockDefect: item.stockDefect,
                            stockRecondition: item.stockRecondition,
                            stocks: item.stockInitial,
                            ecart:
                                valueQty > 0
                                    ? `+${valueQty}`
                                    : valueQty < 0
                                    ? `${valueQty}`
                                    : '',
                            comment: item.comment,
                            zone: item.zone,
                            totalZone: totalZones[item.barcode],
                        });
                    } else {
                        dataPdf[index].zone = `${dataPdf[index].zone}/${
                            item.zone || ''
                        }`;
                        dataPdf[index].qtyInventory = `${
                            dataPdf[index].qtyInventory
                        }/${item.qtyInventory || ''}`;
                    }
                }
                if (valueQty) {
                    const index = dataEcartPdf.findIndex(
                        (el) => el.productReference === item.barcode,
                    );

                    if (index === -1) {
                        dataEcartPdf.push({
                            productReference: item.barcode,
                            barcode: item.barcode
                                ? textToBase64Barcode(item.barcode, 'EAN13')
                                : '',
                            name: item.name,
                            size: item.size,
                            qtyInventory: item.qtyInventory,
                            stockDefect: item.stockDefect,
                            stockRecondition: item.stockRecondition,
                            stocks: item.stockInitial,
                            ecart: valueQty > 0 ? `+${valueQty}` : valueQty,
                            comment: item.comment,
                            zone: item.zone,
                            totalZone: totalZones[item.barcode],
                        });
                    } else {
                        dataEcartPdf[index].zone = `${
                            dataEcartPdf[index].zone
                        }/${item.zone || ''}`;
                        dataEcartPdf[index].qtyInventory = `${
                            dataEcartPdf[index].qtyInventory
                        }/${item.qtyInventory || ''}`;
                    }
                }
            },
        );
        setZoneOptions(zoneOptions);
        setDataEcartPdf(dataEcartPdf);
        setDataPdf(dataPdf);
        setPdfInfo(pdfInfo);
    }, [isExistData]);

    return (
        <div>
            <PdfTable
                disabled={false}
                classN="btn_1 w-100 blue_btn"
                columns={columnsPdf}
                data={dataPdf}
                ID={inventoryRequestCode}
                currentDate={getDateTimestamp(requestDate)}
                storeName={storeName}
                associateFirstName={associateFirstName}
                info={pdfInfo}
                options={zoneOptions}
            >
                {i18next.t('buttons.29')}
            </PdfTable>
            <PdfTable
                disabled={false}
                classN="btn_1 w-100 red_btn"
                columns={columnsEcartPdf}
                data={dataEcartPdf}
                ID={inventoryRequestCode}
                currentDate={getDateTimestamp(requestDate)}
                storeName={storeName}
                associateFirstName={associateFirstName}
                info={pdfInfo}
                options={zoneOptions}
            >
                {i18next.t('buttons.39')}
            </PdfTable>
        </div>
    );
}
