import {createReducer} from 'reduxsauce';
import {StoreGiftsTypes} from './actions';
import { getBasketStoreGifts } from '../../../helpers/helpers';

export interface IState {
    storeGifts?: any,
    error: string,
    isGiftLoading: boolean,
    isLoading: boolean,
    createdData: any,
    createdStatus: string,
    isCreateLoading: boolean,
    singleStoreGift: object,
    basketGifts: object,
    printedGiftData: object,
    isPrinting: boolean,
    printerStatus: string
}

export const storeGiftsState: IState = {
    storeGifts: [],
    error: "",
    isGiftLoading: false,
    isLoading: false,
    createdData: {},
    createdStatus: "",
    isCreateLoading: false,
    singleStoreGift: {},
    basketGifts: getBasketStoreGifts(),
    printedGiftData: {},
    isPrinting: false,
    printerStatus: ''
};

export const storeGiftsStart = (state) => {
    return {...state, isLoading: true};
};

export const storeGiftsSuccess = (state, action) => {
    return {...state, storeGifts: action.data, isLoading: false};
};

export const storeGiftsFailure = (state, action) => {
    return {...state, error: action.error, storeGifts: [], isLoading: false};
};

export const createStoreGiftStart = (state) => {
    return {...state, createdData:{}, createdStatus: "", isCreateLoading: true};
};

export const createStoreGiftSuccess = (state, action) => {
    return {...state, createdData: action.data, createdStatus: "success", isCreateLoading: false};
};

export const createStoreGiftFailure = (state, action) => {
    return {...state, error: action.error, createdData: {}, createdStatus: "failed", isCreateLoading: false};
};

export const singleStoreGiftsStart = (state) => {
    return {...state, singleStoreGift:{}, isGiftLoading: true};
};

export const singleStoreGiftsSuccess = (state, action) => {
    return {...state, singleStoreGift: action.data, isGiftLoading: false};
};

export const singleStoreGiftsFailure = (state, action) => {
    return {...state, error: action.error, singleStoreGift: {}};
};

export const printStoreGiftsStart = (state) => {
    return {...state, isPrinting: true, printerStatus: ''};
};

export const printStoreGiftsSuccess = (state, action) => {
    return {...state, printedGiftData: action.data, isPrinting: false, printerStatus: 'success'};
};

export const printStoreGiftsFailure = (state, action) => {
    return {...state, error: action.error, printedGiftData: {}, isPrinting: false, printerStatus: 'failed'};
};

export const getBasketGifts = (state) => {
    return {...state, basketGifts: getBasketStoreGifts()}
};

export const removeBasketGiftsSuccess = (state) => {
    return {...state, basketGifts: getBasketStoreGifts()};
};

export const handlers = {
    [StoreGiftsTypes.STORE_GIFTS_START]: storeGiftsStart,
    [StoreGiftsTypes.STORE_GIFTS_SUCCESS]: storeGiftsSuccess,
    [StoreGiftsTypes.STORE_GIFTS_FAILURE]: storeGiftsFailure,
    [StoreGiftsTypes.CREATE_STORE_GIFT_START]: createStoreGiftStart,
    [StoreGiftsTypes.CREATE_STORE_GIFT_SUCCESS]: createStoreGiftSuccess,
    [StoreGiftsTypes.CREATE_STORE_GIFT_FAILURE]: createStoreGiftFailure,
    [StoreGiftsTypes.SINGLE_STORE_GIFTS_START]: singleStoreGiftsStart,
    [StoreGiftsTypes.SINGLE_STORE_GIFTS_SUCCESS]: singleStoreGiftsSuccess,
    [StoreGiftsTypes.SINGLE_STORE_GIFTS_FAILURE]: singleStoreGiftsFailure,
    [StoreGiftsTypes.PRINT_STORE_GIFTS_START]: printStoreGiftsStart,
    [StoreGiftsTypes.PRINT_STORE_GIFTS_SUCCESS]: printStoreGiftsSuccess,
    [StoreGiftsTypes.PRINT_STORE_GIFTS_FAILURE]: printStoreGiftsFailure,
    [StoreGiftsTypes.BASKET_STORE_GIFTS_START]: getBasketGifts,
    [StoreGiftsTypes.REMOVE_BASKET_GIFTS_SUCCESS]: removeBasketGiftsSuccess,
};

export default createReducer(storeGiftsState, handlers);
