import {createReducer} from 'reduxsauce';
import {inventoryTypes} from './actions';


export interface IinventoryState {
    inventoryData?: any,
    singleInventoryData?: any,
    isLoading: boolean,
    isCreateLoading: boolean,
    isSingleLoading:boolean,
    status: string,
    updateStatus: string,
    createdId: string | number | null
}

export const inventoryContent: IinventoryState = {
    inventoryData: [],
    singleInventoryData: [],
    isLoading: false,
    isCreateLoading: false,
    isSingleLoading: false,
    status: "",
    updateStatus:"",
    createdId: null
};

export const getInventoryData = (state) => {
    return {...state, isLoading: true};
};

export const InventorySuccess = (state, action) => {
    return {...state, inventoryData: action.data, isLoading:false};
};

export const InventoryFailure = (state, action) => {
    return {...state, error: action.error, data: [], isLoading:false};
};

export const getSingleInventoryData = (state) => {
    return {...state, singleInventoryData: [],isSingleLoading:true};
};

export const SingleInventorySuccess = (state, action) => {
    return {...state, singleInventoryData: action.data, isSingleLoading:false};
};

export const SingleInventoryFailure = (state, action) => {
    return {...state, error: action.error, singleInventoryData: [], isSingleLoading:false};
};

export const createInventoryStart = (state) => {
    return {...state, isCreateLoading: true, status: ""};
};

export const createInventorySuccess = (state, action) => {
    return {...state, isCreateLoading: false, status: "success", createdId: action.data};
};

export const createInventoryFailure = (state, action) => {
    return {...state, error: action.error, isCreateLoading: false, status: "failed", createdId: null};
};

export const updateStoreInventoryStart = (state) => {
    return {...state, updateStatus: ""};
};

export const updateStoreInventorySuccess = (state, action) => {
    return {...state,  updateStatus: "success"};
};

export const updateStoreInventoryFailure = (state, action) => {
    return {...state, updateStatus: "failed"};
};

export const resetStoreInventoryStatus = (state) => {
    return {...state, updateStatus: ""};
};

export const resetStoreInventoryCreateStatus = (state) => {
    return {...state, status: ""};
};

export const handlers = {
    [inventoryTypes.INVENTORY_START]: getInventoryData,
    [inventoryTypes.INVENTORY_SUCCESS]: InventorySuccess,
    [inventoryTypes.INVENTORY_FAILURE]: InventoryFailure,
    [inventoryTypes.SINGLE_INVENTORY_START]: getSingleInventoryData,
    [inventoryTypes.SINGLE_INVENTORY_SUCCESS]: SingleInventorySuccess,
    [inventoryTypes.SINGLE_INVENTORY_FAILURE]: SingleInventoryFailure,
    [inventoryTypes.CREATE_INVENTORY_START]: createInventoryStart,
    [inventoryTypes.CREATE_INVENTORY_SUCCESS]: createInventorySuccess,
    [inventoryTypes.CREATE_INVENTORY_FAILURE]: createInventoryFailure,
    [inventoryTypes.UPDATE_STORE_INVENTORY_START]: updateStoreInventoryStart,
    [inventoryTypes.UPDATE_STORE_INVENTORY_SUCCESS]: updateStoreInventorySuccess,
    [inventoryTypes.UPDATE_STORE_INVENTORY_FAILURE]: updateStoreInventoryFailure,
    [inventoryTypes.RESET_STORE_INVENTORY_STATUS]: resetStoreInventoryStatus,
    [inventoryTypes.RESET_STORE_INVENTORY_CREATE_STATUS]: resetStoreInventoryCreateStatus,

};

export default createReducer(inventoryContent,handlers);
