import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { Popup, Tab } from 'semantic-ui-react';
import {
    getTransfers,
    getLoading,
    getCreateLoading,
    getTransferShipments,
} from './../redux/selectors';
import { TransfersActionCreators } from './../redux/actions';
import TableComponent from '../../datatable/container/datatable';
import PopUp from '../../popUp/popUp';
import TransferDetails from './components/transferDetails';
import './transfers.scss';
import { DEFAULT_CURRENCY, TRANSFER_STATUS_CODE } from '../../../utils/constants/variables';
import { getAccountResource } from '../../login/redux/selectors';
import CarrierTab from '../../livraisonClient/container/carrierTab/carrierTab'
import { getStores } from '../../storeFormSidePanel/redux/selectors';

interface Props {
    transfers: any;
    isLoading: boolean;
    isCreateLoading: boolean;
    detiles: any;
    transferShipments: any[],
    stores: any;
}

interface State {
    isShowPopUp: boolean;
    singleTransfer: any;
}

class Transfers extends Component<Props & typeof TransfersActionCreators> {
    state: State = {
        isShowPopUp: false,
        singleTransfer: {},
    };

    columns: any[] = [
        {
            name: i18next.t('Terminal.Receipt.Transfer.date'),
            selector: 'createdDate',
            searchable: true,
            sortable: true,
            format: 'dd/mm/yy',
            width: '18%',
        },
        {
            name: i18next.t('Terminal.dashboards.id'),
            selector: 'storeTransferCode',
            searchable: true,
            sortable: true,
            width: '10%',
            cell: (row) =>
                row.storeTransferCode ? row.storeTransferCode.slice(-5) : '',
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.sendName'),
            selector: 'storeNameSend',
            searchable: true,
            sortable: true,
            width: '22%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {' '}
                            {row.storeNameSend}
                        </div>
                    }
                    content={
                        row.storeCodeSend
                            ? `${row.storeNameSend} (${row.storeCodeSend})`
                            : row.storeNameSend
                    }
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.Receipt.Transfer.recvName'),
            selector: 'storeNameRecv',
            searchable: true,
            sortable: true,
            width: '20%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {' '}
                            {row.storeNameRecv}
                        </div>
                    }
                    content={
                        row.storeCodeRecv
                            ? `${row.storeNameRecv} (${row.storeCodeRecv})`
                            : row.storeNameRecv
                    }
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.cartons'),
            selector: 'totalBox',
            sortable: true,
            center: true,
            width: '10%',
        },

        {
            name: i18next.t('Terminal.Receipt.Transfer.statusCode'),
            selector: 'storeTransferStatusLabel',
            sortable: true,
            width: '20%',
        },
        {
            name: 'storeTransferCode',
            selector: 'storeTransferCode',
            notShow: true,
            isUnique: true,
        },
        {
            name: 'storeTransferStatusCode',
            selector: 'storeTransferStatusCode',
            notShow: true,
        },
        {
            name: 'storeCodeSend',
            selector: 'storeCodeSend',
            notShow: true,
        },
        {
            name: 'storeCodeRecv',
            selector: 'storeCodeRecv',
            notShow: true,
        },
        {
            name: 'products',
            selector: 'products',
            notShow: true,
        },
        {
            name: 'storeTransferId',
            selector: 'storeTransferId',
            notShow: true,
        },
        {
            name: 'associateCodeRecv',
            selector: 'associateCodeRecv',
            notShow: true,
        },
        {
            name: 'storeTransferReasonCode',
            selector: 'storeTransferReasonCode',
            notShow: true,
        },
    ];

    conditionalRowStyles: any[] = [
        {
            when: (row) =>
                row.storeTransferStatusCode === TRANSFER_STATUS_CODE.REQ,
            style: {
                backgroundColor: 'white',
            },
        },
        {
            when: (row) =>
                row.storeTransferStatusCode === TRANSFER_STATUS_CODE.REJ,
            style: {
                backgroundColor: 'red',
            },
        },
        {
            when: (row) =>
                row.storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK,
            style: {
                backgroundColor: 'orange',
            },
        },
        {
            when: (row) =>
                row.storeTransferStatusCode === TRANSFER_STATUS_CODE.REC,
            style: {
                backgroundColor: 'green',
            },
        },
        {
            when: (row) =>
                row.storeTransferStatusCode === TRANSFER_STATUS_CODE.SHI,
            style: {
                backgroundColor: 'blue',
            },
        },
        {
            when: (row) =>
                row.storeTransferStatusCode === TRANSFER_STATUS_CODE.DIFF,
            style: {
                backgroundColor: 'red',
            },
        },
    ];

    componentDidMount() {
        this.props.storesStart();
        this.props.transfersStart();
    }

    componentDidUpdate(prevProps) {
        if (
            this.state.isShowPopUp &&
            prevProps.isLoading &&
            !this.props.isLoading
        ) {
            const singleTransfer =
                this.props.transfers.find(
                    (item) =>
                        item.storeTransferCode ===
                        this.state.singleTransfer.storeTransferCode,
                ) || {};
            this.setState({ singleTransfer });
        }
    }

    openPopUp = (e) => {
        const singleTransfer =
            this.props.transfers.find(
                (item) => item.storeTransferCode === e.storeTransferCode,
            ) || {};

        this.props.getTransferShipmentsStart(singleTransfer.storeTransferId);
        this.setState({ isShowPopUp: true, singleTransfer });
    };
    handleClosePopUp = () => {
        this.props.resetUpdateResponse();
        this.setState({ isShowPopUp: false });
    };
    getItemData = () => {
        const { singleTransfer } = this.state;
        const { stores = [], transferShipments = [], detiles } = this.props;
        const storeSend = stores.find((store) => store.storeCode === singleTransfer.storeCodeSend) || {};
        const storeRecv = stores.find((store) => store.storeCode === singleTransfer.storeCodeRecv) || {};
        const productName = singleTransfer && Array.isArray(singleTransfer.products) && singleTransfer.products.length
        ? singleTransfer.products[0].name : "";
        const isButtonsDisabled = singleTransfer.storeCodeSend === detiles.storeCode
            ? "RECREJDIFF".includes(singleTransfer.storeTransferStatusCode) : true;

        return {
            storeSend,
            storeRecv,
            totalSales: 0,
            currency: DEFAULT_CURRENCY,
            storeTransferId: singleTransfer.storeTransferId,
            storeTransferCode: singleTransfer.storeTransferCode,
            orderShipments: transferShipments,
            productName,
            isButtonsDisabled,
        };
    }

    render() {
        const {
            transfers,
            isLoading,
            isCreateLoading,
            detiles,
        } = this.props;
        const { isShowPopUp, singleTransfer } = this.state;
        const { terminalCode } = detiles;
        const tableData = transfers.filter(
            (item) =>
                !(
                    item.storeTransferStatusCode === TRANSFER_STATUS_CODE.PEN &&
                    terminalCode !== item.terminalRequesting
                ),
        );

        const panes = [
            {
                menuItem: i18next.t('Terminal.dashboards.2'),
                render: () => (
                    <Tab.Pane attached={false} loading={false}>
                        <TransferDetails
                            data={singleTransfer}
                            createTransferStart={this.props.createTransferStart}
                            isLoading={isCreateLoading}
                            handleClosePopUp={this.handleClosePopUp}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: i18next.t('Terminal.dashboards.transporteur'),
                render: () => (
                    <Tab.Pane attached={false} loading={false}>
                        <CarrierTab
                            itemData={this.getItemData()}
                            type={"transfers"}
                            closePopup={this.handleClosePopUp}
                        />
                    </Tab.Pane>
                ),
            }
        ]

        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={tableData}
                        isLoading={isLoading}
                        openPopUp={this.openPopUp}
                        columns={this.columns}
                        buttons={['transferButtons']}
                        conditionalRowStyles={this.conditionalRowStyles}
                        reloadDashboard={this.props.transfersStart}
                        classN="transfer"
                        keyField={'transfer'}
                    />
                </div>
                {isShowPopUp ? (
                    <PopUp
                        handleClose={this.handleClosePopUp}
                        classN="transferspopUp"
                    >
                        <Tab
                            menu={{ pointing: true }}
                            panes={panes}
                        />
                    </PopUp>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    transfers: getTransfers(state),
    isLoading: getLoading(state),
    isCreateLoading: getCreateLoading(state),
    detiles: getAccountResource(state),
    stores: getStores(state),
    transferShipments: getTransferShipments(state),
});

const mapDispatchToProps = (dispatch): typeof TransfersActionCreators => {
    return bindActionCreators(TransfersActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
