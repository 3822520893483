import React, { useState } from 'react';
import i18next from 'i18next';
import CustomBtn from '../../common/customBtn';
import PopUp from '../../popUp/popUp';
import {GeneretBarcodes} from './pdfGenerator';
import { LABEL_COLUMN, LABEL_ROW } from '../../../utils/constants/variables';
import './styles.scss'

interface Props {
    handleClick:(a, b) => void,
    handleClose: () => void,
    loading: boolean,
    showCount?: boolean
}

const BarcodePositionSidePanel: React.FC<Props> = ({handleClick, handleClose, loading, showCount = true}: Props) => {

    const [value, setValue] = useState(1);
    const [labelsCount, setLabelsCount] = useState(1);


    const onChange = ({target:{value}}) => {
        if(value === "" || (parseInt(value) > 0 && parseInt(value) <= LABEL_COLUMN * LABEL_ROW)){
            setValue(value)
        }
    }

    const onChangeLabelsCount = ({target:{value}}) => {
        if(value === "" || (parseInt(value) > 0 && parseInt(value) <= 5)){
            setLabelsCount(value)
        }
    }
    
    return (
        <PopUp classN="barcode--possition-popup" handleClose={handleClose}>
            <div className="export--stock--possition-content">
                <div className='dflex justify-content-between align-items-center'>
                    <div>{i18next.t('product.labels.7')}</div>
                    <input type="number" name="position" min="1" max={LABEL_COLUMN * LABEL_ROW} value={value} onChange={onChange} />
                </div>
                {
                    showCount &&
                    <div className='dflex justify-content-between align-items-center'>
                        <div>{i18next.t('product.labels.8')}</div>
                        <input type="number" name="labelsCount" min="1" max={5} value={labelsCount} onChange={onChangeLabelsCount} />
                    </div>
                }
                <CustomBtn
                    disabled={false}
                    classN="btn_1 w-50 green_btn"
                    handleClick={() => handleClick(value, labelsCount)}
                    loading={loading}
                >
                    {i18next.t('buttons.22')}
                </CustomBtn>
            </div>
        </PopUp>
    );
}

export {
    GeneretBarcodes,
    BarcodePositionSidePanel
};
