import React, {Component} from 'react';
import i18next from "i18next";
import footer from '../../../../assets/img/footer.webp';
//styles
import "./footer.scss";
import PrinterErrorMessage from "../../../../modules/printer/container";
import CreateTicket from './components/createTicket';

interface State {
    openCreateTicket: boolean,
}
class Footer extends Component {
    state : State ={
        openCreateTicket: false,
    }
    toggleCreateTicket = () => {
        this.setState((prevState : State) => {return{ ...prevState,openCreateTicket: !prevState.openCreateTicket}})
    }
    render(): React.ReactNode {
        const { openCreateTicket } = this.state;

        return (
            <>
                <footer className="footer">
                    <div className="copy-right">
                        <div className="copy-right--title container d-center">
                            <div className="copy-right--title--leftPart">{i18next.t('footer.mini.0')}</div>
                            <div className="copy-right--title--rigthPart" onClick={() => this.toggleCreateTicket()}>{i18next.t('footer.mini.1')}</div>
                        </div>
                    </div>
                </footer>
                <PrinterErrorMessage />
                {openCreateTicket ? <CreateTicket handleClose={this.toggleCreateTicket} /> :""}
            </>
        );
    }
}

export default Footer;
