import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import i18next from "i18next";
import { cloneObject, getCurrency, priceThreeDigitSets, replaceComma, replaceCommaToDecimal } from '../../../../helpers/helpers';
import { PAYMENT_CODES, STORE_COUNTRIES } from '../../../../utils/constants/variables';
import PriceInput from '../../../common/priceInput'
import CustomBtn from "../../../common/customBtn";

interface IProps {
    handelChangeWallet: (param) => void,
    setNextStep3: (status?: boolean) => void,
    cashDeposit: number | string,
    checkDeposit: number | string,
    amexDeposit: number | string,
    creditCardDeposit: number | string,
    giftCardDeposit: number | string,
    altCashDeposit: number | string,
    altCheckDeposit: number | string,
    altAmexDeposit: number | string,
    altCreditCardDeposit: number | string,
    altGiftCardDeposit: number | string,
    paymentsReportData: any,
    details: any,
    cashSafe: number | string,
    altCashSafe: number | string,
    checkSafe: number | string,
    altCheckSafe: number | string,
    altGiftSafe: number | string,
    giftSafe: number | string,
    customEspeces: number | string,
    altCustomEspeces: number | string,
    altWireDeposit: number | string,
    wireDeposit: number | string,
    step2DisabledButtons: string[],
    setStep2DisabledButtons: (key) => void;
}

const TerminalStep2: React.FC<IProps> = (props: IProps) => {
    const [isAddAllow, setIsAddAllow] = useState<boolean>(false);
    const [state, setState]: any = useState({ disabledAddBtns: [], disabledResetBtns: [], existingButtonCode : [] });
    const [isChest, setIsChest] = useState<boolean>(false);
    const [isBank, setIsBank] = useState<boolean>(false);
    const [initialCashSafe, setInitialCashSafe] = useState<number | string>(0);
    const {
        handelChangeWallet, setNextStep3, cashDeposit, checkDeposit, amexDeposit, creditCardDeposit,
        giftCardDeposit, altCashDeposit, altCheckDeposit, altAmexDeposit, altCreditCardDeposit,
        altGiftCardDeposit, paymentsReportData, details, customEspeces, altCustomEspeces,
        cashSafe, altCashSafe, checkSafe, altCheckSafe, giftSafe, altGiftSafe, altWireDeposit, wireDeposit,
        step2DisabledButtons, setStep2DisabledButtons
    } = props;
    const { storeDetails: { country = '' } = {} } = details;
    const isCountryDeAt = [STORE_COUNTRIES.at, STORE_COUNTRIES.de].includes(country);
    const isCountryItCh = [STORE_COUNTRIES.it, STORE_COUNTRIES.ch].includes(country);
    const isCountryCredit = isCountryItCh || isCountryDeAt;
    const paymenCardDeposit = isCountryItCh ? PAYMENT_CODES.c10 : "";
    const bankArr = [
        PAYMENT_CODES.c1, PAYMENT_CODES.c2, PAYMENT_CODES.c4, PAYMENT_CODES.c5, PAYMENT_CODES.c6, PAYMENT_CODES.c7,
        isCountryDeAt ? PAYMENT_CODES.c8 : '', isCountryCredit ? paymenCardDeposit : ""
    ];
    const chestArr = [
        PAYMENT_CODES.c5, PAYMENT_CODES.c6,
        isCountryCredit ? "" : PAYMENT_CODES.c7, isCountryDeAt ? PAYMENT_CODES.c8 : ''
    ];
    const names = {
        bank: {
            [details.currency]: {
                [PAYMENT_CODES.c1]: "creditCardDeposit",
                [PAYMENT_CODES.c2]: "amexDeposit",
                [PAYMENT_CODES.c4]: "wireDeposit",
                [PAYMENT_CODES.c5]: "cashDeposit",
                [PAYMENT_CODES.c6]: "checkDeposit",
                [PAYMENT_CODES.c7]: "giftCardDeposit",
                [PAYMENT_CODES.c8]: "giftCardDeposit",
                [paymenCardDeposit]: "creditCardDeposit",
                totalName: "total",
                cashSafeMovedCashDeposit: 'cashSafeMovedCashDeposit'
            },
            [details.altCurrency]: {
                [PAYMENT_CODES.c1]: "altCreditCardDeposit",
                [PAYMENT_CODES.c2]: "altAmexDeposit",
                [PAYMENT_CODES.c4]: "altWireDeposit",
                [PAYMENT_CODES.c5]: "altCashDeposit",
                [PAYMENT_CODES.c6]: "altCheckDeposit",
                [PAYMENT_CODES.c7]: "altGiftCardDeposit",
                [PAYMENT_CODES.c8]: "altGiftCardDeposit",
                [paymenCardDeposit]: "altCreditCardDeposit",
                totalName: "altTotal",
                cashSafeMovedCashDeposit: 'altCashSafeMovedCashDeposit'
            }
        },
        chest: {
            [details.currency]: {
                [PAYMENT_CODES.c5]: "cashSafe",
                [PAYMENT_CODES.c6]: "checkSafe",
                [PAYMENT_CODES.c7]: "giftSafe",
                [PAYMENT_CODES.c8]: "giftSafe",
                cashInSafe: "cashInSafe"
            },
            [details.altCurrency]: {
                [PAYMENT_CODES.c5]: "altCashSafe",
                [PAYMENT_CODES.c6]: "altCheckSafe",
                [PAYMENT_CODES.c7]: "altGiftSafe",
                [PAYMENT_CODES.c8]: "altGiftSafe",
                cashInSafe: "altCashInSafe"
            }
        },
    };

    const isCashButtonMandatory = () =>
        Object.keys(paymentsReportData).reduce((status, currency) => {
            const isDefault = currency === details.currency;
            const openingName = isDefault ? 'openingAmount' : 'altOpeningAmount';
            const item = (
                paymentsReportData[currency] && Array.isArray(paymentsReportData[currency].latestDayPayment)
                    ? cloneObject(paymentsReportData[currency].latestDayPayment) : [])
                    .find((element) => element.paymentMethodCode === PAYMENT_CODES.c5) || {};
            const value = isDefault ? customEspeces : altCustomEspeces;
            const ItemPrice = (+item.paymentAmount || +details[openingName]) - +`${value}`.replace(',', '.');
            if (ItemPrice !== 0) {
                status = true;
            }
            return status;
        }, false)

    useEffect(() => {
        const { currency } = details;

        if(paymentsReportData[currency] && Array.isArray(paymentsReportData[currency].latestDayPayment)){
            const existingButtonCode: string[] = []
            paymentsReportData[currency].latestDayPayment.forEach(el => {
                if(
                    el.paymentMethodCode !== PAYMENT_CODES.c5 &&
                    bankArr.includes(el.paymentMethodCode) &&
                    !step2DisabledButtons.join('').includes(el.paymentMethodCode)
                ) {
                    existingButtonCode.push(el.paymentMethodCode);
                }
            });
            if (!existingButtonCode.includes(PAYMENT_CODES.c5) && isCashButtonMandatory() && !step2DisabledButtons.join('').includes(PAYMENT_CODES.c5)) {
                existingButtonCode.push(PAYMENT_CODES.c5);
            }

            if(existingButtonCode.length){
                setState(prev => ({...prev, existingButtonCode}))
            } else {
                setNextStep3();
            }
            setIsAddAllow(true);
            if(step2DisabledButtons.length) {
                setState(prev => ({...prev, disabledAddBtns: step2DisabledButtons}))
            }
        }
    }, [])

    useEffect(() => {
        if (!initialCashSafe) setInitialCashSafe(cashSafe);
    }, [cashSafe])

    useEffect(() => {
        const existingButtonCode: string[] = [...state.existingButtonCode]
        const index = existingButtonCode.findIndex((code) => code === PAYMENT_CODES.c5);
        if(!isCashButtonMandatory()) {
            index > -1 && existingButtonCode.splice(index, 1);
            if (isAddAllow) {
                setState(prev => ({...prev, existingButtonCode}));
                !existingButtonCode.length && setNextStep3();
            }
        } else {
            index < 0 && !step2DisabledButtons.join('').includes(PAYMENT_CODES.c5) && existingButtonCode.push(PAYMENT_CODES.c5);
            isAddAllow && setState(prev => ({...prev, existingButtonCode}));
            existingButtonCode.length && setNextStep3(true);
        }
    }, [customEspeces, altCustomEspeces])

    const handleChange = ({ name, value }) => {
        if ((name === "customEspeces" || name === "altCustomEspeces") && value === "") { value = 0 }
        handelChangeWallet({ [name]: value })
    }

    const handleAdd = (item, currency, selectedName, isReset = false) => {
        const { paymentAmount, paymentMethodCode } = item,
            isDefault = currency === details.currency;
        let ItemPrice = paymentAmount;

        if (paymentMethodCode === PAYMENT_CODES.c5 && !isReset) {
            const especes = isDefault ? +replaceCommaToDecimal(customEspeces) : +replaceCommaToDecimal(altCustomEspeces);
            ItemPrice = ItemPrice - especes;
            if (selectedName === 'bank') {
                setIsBank(true);
            } else {
                setIsChest(true);
            }
        }

        const res = +replaceCommaToDecimal(props[names[selectedName][currency][paymentMethodCode]].toString())
        const value = (res + ItemPrice) || 0

        if (isReset) {
            handleChange({ name: names["chest"][currency][paymentMethodCode], value: 0 })
            setState(prevState => ({
                ...prevState,
                disabledResetBtns: [...prevState.disabledResetBtns, paymentMethodCode + currency]
            }))
        } else {
            const existingButtonCode = state.existingButtonCode.filter(el => el !== paymentMethodCode)
            if(!existingButtonCode.length){
                setNextStep3()
            }
            setState(prevState => ({
                ...prevState,
                existingButtonCode,
                disabledAddBtns: [...prevState.disabledAddBtns, paymentMethodCode + currency]
            }))
            setStep2DisabledButtons(paymentMethodCode + currency);
        }

        if (paymentMethodCode === PAYMENT_CODES.c5) {
            handelChangeWallet({ [names[selectedName][currency].totalName]: value.toString() });
            selectedName === "chest" && handelChangeWallet({ [names[selectedName][currency].cashInSafe]: ItemPrice });
            if(isReset){
                handelChangeWallet({ [names[selectedName][currency].cashSafeMovedCashDeposit]: paymentAmount.toString() })
                selectedName === "chest" && handelChangeWallet({ [names[selectedName][currency].cashInSafe]: 0 });
            }
        }

        if (isCountryCredit && paymentMethodCode === PAYMENT_CODES.c7) {
            handleChange(
                {
                    name: names[selectedName][currency][PAYMENT_CODES.c1],
                    value: (isDefault ? creditCardDeposit : altCreditCardDeposit) + value
                }
            )
        } else {
            handleChange({ name: names[selectedName][currency][paymentMethodCode], value })
        }
    }

    const isButtonDisabled = (item = { paymentMethodCode: '', paymentAmount: -1 }, currency) => {
        return (
            state.disabledAddBtns.includes(item.paymentMethodCode + currency) ||
            +item.paymentAmount === 0 &&
            [PAYMENT_CODES.c5].includes(item.paymentMethodCode)
        );
    }

    return (
        <div>
            <div className="fermeturePopUp--body-form-content-input--groups">
                {paymentsReportData && Object.keys(paymentsReportData).length ?
                    <>
                        <div className="table-store-counter--Content">
                            {
                                Object.keys(paymentsReportData).map((currency, idx) => {
                                    let latestDayPaymentTotalCount = 0;
                                    let latestDayPaymentTotalAmount = 0;
                                    const isDefault = currency === details.currency;
                                    const customEsp = isDefault ? +replaceCommaToDecimal(customEspeces) : +replaceCommaToDecimal(altCustomEspeces);
                                    const openingName = isDefault ? 'openingAmount' : 'altOpeningAmount';
                                    const payments = paymentsReportData[currency] && Array.isArray(paymentsReportData[currency].latestDayPayment) ? cloneObject(paymentsReportData[currency].latestDayPayment) : [];
                                    const c5Index = payments.findIndex(item => item.paymentMethodCode === PAYMENT_CODES.c5);

                                    if (c5Index > -1 && details[openingName]) {
                                        payments[c5Index].paymentAmount = payments[c5Index].paymentAmount + details[openingName]
                                    }else if (details[openingName]) {
                                        payments.push({
                                            paymentMethod: i18next.t(`Terminal.openClose.Cash`),
                                            paymentMethodCount: 1,
                                            paymentAmount: details[openingName],
                                            paymentMethodCode: PAYMENT_CODES.c5,
                                            isOpeningAmount: true,
                                        })
                                    }

                                    return (
                                        <div key={idx} className="table-store-counter--Content--box">
                                            <Table celled collapsing className="w-100 table-store-counter">
                                                <Table.Body>
                                                    <Table.Row key="1_1">
                                                        <Table.Cell className="fermeturePopUp--body--title blueText">
                                                            {i18next.t("Terminal.dashboards.payMethod")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t("Terminal.dashboards.quantity")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t("Terminal.dashboards.saleAmount")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t("Terminal.terminalOpeningBillets.depositTitle")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t("Terminal.terminalOpeningBillets.safeTitle")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t("Terminal.terminalOpeningBillets.especesTitle")}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        payments && payments.map((item, idx) => {
                                                            let ItemPrice = item.paymentAmount;

                                                            if (item.paymentMethodCode === PAYMENT_CODES.c5) {
                                                                ItemPrice = ItemPrice - customEsp;
                                                            };

                                                            latestDayPaymentTotalCount += item.paymentMethodCount;
                                                            latestDayPaymentTotalAmount += ItemPrice;

                                                            return (
                                                                <Table.Row key={idx}>
                                                                    <Table.Cell className="fermeturePopUp--body--title">
                                                                        {item.paymentMethod}
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                                        {item.paymentMethodCount}
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                                        {ItemPrice ? priceThreeDigitSets(ItemPrice, currency) : ItemPrice + getCurrency(currency)}
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description add-deposit-btn">
                                                                        {bankArr.includes(item.paymentMethodCode) &&
                                                                            <CustomBtn
                                                                                classN={`btn_1 red_btn`}
                                                                                handleClick={() => { handleAdd(item, currency, "bank") }}
                                                                                disabled={
                                                                                    [PAYMENT_CODES.c5].includes(item.paymentMethodCode)
                                                                                        ? !isCashButtonMandatory() || isButtonDisabled(item, currency)
                                                                                        : isButtonDisabled(item, currency)
                                                                                }
                                                                            >
                                                                                {i18next.t(`Terminal.terminalClose.add`)}
                                                                            </CustomBtn>
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description add-deposit-btn">
                                                                        {chestArr.includes(item.paymentMethodCode) &&
                                                                            <CustomBtn
                                                                                classN={`btn_1 orange_btn`}
                                                                                handleClick={() => { handleAdd(item, currency, "chest") }}
                                                                                disabled={
                                                                                    [PAYMENT_CODES.c5].includes(item.paymentMethodCode)
                                                                                        ? !isCashButtonMandatory() || isButtonDisabled(item, currency)
                                                                                        : isButtonDisabled(item, currency)
                                                                                }
                                                                            >
                                                                                {i18next.t(`Terminal.terminalClose.add`)}
                                                                            </CustomBtn>
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell className="fermeturePopUp--body--description">
                                                                        {[PAYMENT_CODES.c5].includes(item.paymentMethodCode)
                                                                            ? !isButtonDisabled(item, currency) ?
                                                                            <PriceInput
                                                                                value={isDefault ? customEspeces : altCustomEspeces}
                                                                                name={isDefault ? "customEspeces" : "altCustomEspeces"}
                                                                                onChange={(event) => handleChange(event)}
                                                                                maxValue={ItemPrice + customEsp}
                                                                            /> : isDefault ? customEspeces : altCustomEspeces
                                                                            : ''
                                                                        }
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                    }
                                                    <Table.Row key="1_2">
                                                        <Table.Cell className="fermeturePopUp--body--title color--green">
                                                            {i18next.t(`Terminal.terminalClose.total`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description color--green">
                                                            {latestDayPaymentTotalCount}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description color--green">
                                                            {latestDayPaymentTotalAmount
                                                                ? priceThreeDigitSets(latestDayPaymentTotalAmount, currency)
                                                                : latestDayPaymentTotalAmount + getCurrency(currency)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--title"></Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--title"></Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--title"></Table.Cell>
                                                    </Table.Row>

                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="table-store-counter--Content">
                            {
                                Object.keys(paymentsReportData).map((currency, idx) => {
                                    const isDefault = currency === details.currency
                                    const openingName = isDefault ? 'openingAmount' : 'altOpeningAmount';
                                    const payments = paymentsReportData[currency] && Array.isArray(paymentsReportData[currency].latestDayPayment) ? cloneObject(paymentsReportData[currency].latestDayPayment) : [];
                                    const c5Index = payments.findIndex(item => item.paymentMethodCode === PAYMENT_CODES.c5);
                                    const cashDepositValue = isDefault ? cashDeposit : altCashDeposit;
                                    const cashAmount = (
                                        c5Index > -1 && details[openingName]
                                            ? details[openingName] + payments[c5Index].paymentAmount
                                            : details[openingName]) - Number(`${isDefault ? customEspeces : altCustomEspeces}`.replace(/[,]/g, "."));

                                    return (
                                        <div key={idx} className="table-store-counter--Content--box">
                                            <Table celled collapsing className="w-100 table-store-counter">
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title blueText">
                                                            {i18next.t(`Terminal.terminalClose.deposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t(`Terminal.dashboards.saleAmount`)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.dashboards.currency`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            {isDefault ? details.currency : details.altCurrency}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalClose.cashDeposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                value={cashDepositValue}
                                                                name={isDefault ? "cashDeposit" : "altCashDeposit"}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="message-error">{
                                                                isBank
                                                                    ? replaceComma(cashAmount - (+`${cashDepositValue}`.replace(',', '.')))
                                                                    : ''
                                                            }</span>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalClose.checkDeposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? checkDeposit : altCheckDeposit}
                                                                name={isDefault ? "checkDeposit" : "altCheckDeposit"}
                                                                onChange={handleChange}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalClose.amexDeposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? amexDeposit : altAmexDeposit}
                                                                name={isDefault ? "amexDeposit" : "altAmexDeposit"}
                                                                onChange={handleChange}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalClose.wireDeposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? wireDeposit : altWireDeposit}
                                                                name={isDefault ? "wireDeposit" : "altWireDeposit"}
                                                                onChange={handleChange}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalClose.creditCardDeposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? creditCardDeposit : altCreditCardDeposit}
                                                                name={isDefault ? "creditCardDeposit" : "altCreditCardDeposit"}
                                                                onChange={handleChange}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalClose.giftCardDeposit`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? giftCardDeposit : altGiftCardDeposit}
                                                                name={isDefault ? "giftCardDeposit" : "altGiftCardDeposit"}
                                                                onChange={handleChange}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="table-store-counter--Content">
                            {
                                Object.keys(paymentsReportData).map((currency, idx) => {
                                    const { latestDayPayment = [] } = paymentsReportData[currency]
                                    const { paymentAmount: cashMax = 0 } = latestDayPayment.find(i => i.paymentMethodCode === PAYMENT_CODES.c5) || {}
                                    const { paymentAmount: checkMax = 0 } = latestDayPayment.find(i => i.paymentMethodCode === PAYMENT_CODES.c6) || {}
                                    const { paymentAmount: giftMax = 0 } = latestDayPayment.find(i => i.paymentMethodCode === PAYMENT_CODES.c7) || {}
                                    const isDefault = currency === details.currency
                                    const openingName = isDefault ? 'openingAmount' : 'altOpeningAmount';
                                    const payments = paymentsReportData[currency] && Array.isArray(paymentsReportData[currency].latestDayPayment) ? cloneObject(paymentsReportData[currency].latestDayPayment) : [];
                                    const c5Index = payments.findIndex(item => item.paymentMethodCode === PAYMENT_CODES.c5);
                                    const cashSafeValue = isDefault ? cashSafe : altCashSafe;
                                    const cashAmount = (
                                        c5Index > -1 && details[openingName]
                                            ? details[openingName] + payments[c5Index].paymentAmount
                                            : details[openingName]) - Number(`${isDefault ? customEspeces : altCustomEspeces}`.replace(/[,]/g, "."));

                                    return (
                                        <div className="table-store-counter--Content--box" key={idx}>
                                            <Table celled collapsing className="w-100 table-store-counter" key={idx}>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title blueText">
                                                            {i18next.t("Terminal.terminalOpeningBillets.previousDaySafe")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                            {i18next.t("Terminal.dashboards.saleAmount")}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.dashboards.currency`)}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description">
                                                            {isDefault ? details.currency : details.altCurrency}
                                                        </Table.Cell>
                                                        <Table.Cell className="fermeturePopUp--body--description blueText">
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.openClose.Cash`)}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                value={cashSafeValue}
                                                                name={isDefault ? "cashSafe" : "altCashSafe"}
                                                                onChange={handleChange}
                                                                maxValue={cashMax}
                                                            />
                                                            <span className="message-error">{
                                                                isChest
                                                                    ? replaceComma(Number(initialCashSafe) + cashAmount - (+`${cashSafeValue}`.replace(',', '.')))
                                                                    : ''
                                                            }</span>
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--description">
                                                            <CustomBtn
                                                                classN={`btn_1 smallBtn red_btn w-100`}
                                                                handleClick={() => {
                                                                    handleAdd(
                                                                        {
                                                                            paymentAmount: +replaceCommaToDecimal(isDefault ? cashSafe.toString() : altCashSafe.toString()),
                                                                            paymentMethodCode: PAYMENT_CODES.c5,
                                                                        }, currency, "bank", true)
                                                                }
                                                                }
                                                                disabled={state.disabledResetBtns.includes(PAYMENT_CODES.c5 + currency) || ((isDefault && cashSafe.toString() === "0") || (!isDefault && altCashSafe.toString() === "0"))}
                                                            >
                                                                {i18next.t(`buttons.34`)}
                                                            </CustomBtn>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row >
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalOpeningBillets.check`)}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--description">
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? checkSafe : altCheckSafe}
                                                                name={isDefault ? "checkSafe" : "altCheckSafe"}
                                                                onChange={handleChange}
                                                                maxValue={checkMax}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--description">
                                                            <CustomBtn
                                                                classN={`btn_1 smallBtn red_btn w-100`}
                                                                handleClick={() => handleAdd(
                                                                    {
                                                                        paymentAmount: +replaceCommaToDecimal(isDefault ? checkSafe.toString() : altCheckSafe.toString()),
                                                                        paymentMethodCode: PAYMENT_CODES.c6,
                                                                    }, currency, "bank", true)}
                                                                disabled={state.disabledResetBtns.includes(PAYMENT_CODES.c6 + currency) || ((isDefault && checkSafe.toString() === "0") || (!isDefault && altCheckSafe.toString() === "0"))}
                                                            >
                                                                {i18next.t(`buttons.34`)}
                                                            </CustomBtn>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row >
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--title">
                                                            {i18next.t(`Terminal.terminalOpeningBillets.giftSafe`)}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--description"
                                                        >
                                                            <PriceInput
                                                                disabled
                                                                value={isDefault ? giftSafe : altGiftSafe}
                                                                name={isDefault ? "giftSafe" : "altGiftSafe"}
                                                                onChange={handleChange}
                                                                maxValue={giftMax}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            className="fermeturePopUp--body--description">
                                                            <CustomBtn
                                                                classN={`btn_1 smallBtn red_btn w-100`}
                                                                handleClick={() => handleAdd(
                                                                    {
                                                                        paymentAmount: +replaceCommaToDecimal(isDefault ? giftSafe.toString() : altGiftSafe.toString()),
                                                                        paymentMethodCode: PAYMENT_CODES.c7,
                                                                    }, currency, "bank", true)}
                                                                disabled={state.disabledResetBtns.includes(PAYMENT_CODES.c7 + currency) || ((isDefault && giftSafe.toString() === "0") || (!isDefault && altGiftSafe.toString() === "0"))}
                                                            >
                                                                {i18next.t(`buttons.34`)}
                                                            </CustomBtn>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </>
                    : ""
                }
            </div>
        </div>
    )
}

export default TerminalStep2;
