import {createActions} from 'reduxsauce';

export const {Types: UserTypes, Creators: UserActionCreators} = createActions ({
    loginStart: ['user'],
    loginSuccess: ['data'],
    loginFailure: ['error'],
    privateLoginStart: ['body', 'pass'],
    privateLoginSuccess: ['data'],
    privateLoginFailure: ['error'],
    logoutStart: ['param'],
    tokenValidateStart: [null],
    userInfoStart: [null],
    userInfoSuccess: ['data'],
    userInfoFailure: ['error'],
    clientLoginStart: ['user'],
    clientLoginSuccess: ['data'],
    clientLoginFailure: ['error'],
    clientLogoutStart: [null],
    clientLogoutSuccess: ["data"],
    clientLogoutFailure: ["error"],
    accountResourceStart: ["isReload"],
    accountResourceSuccess: ['data'],
    accountResourceFailure: ['error'],
    isPrivateLoadingStart: ['load'],
    printerStart:['ticketType', 'args'],
    printerStatusStart: [null],
    printReportStart: ['reportType', 'currency'],
    isTerminalOpenStart: ["param"],
    loginFromWaveumStart: ["token"]
});