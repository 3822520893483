/* eslint-disable */
import i18next from 'i18next';
import CryptoJS from 'crypto-js';
import parse from 'html-react-parser';
import jwt from 'jsonwebtoken';
import JsBarcode from 'jsbarcode';
import {
    PRODUCT_MAX_QUANTITY,
    BROWSER_KEY,
    DEFAULT_CURRENCY,
    DEFAULT_CURRENCY_SYMBOL,
    DEFAULT_BASKETS,
    ACTIVE_BASKET,
    SESSION_STORAGE,
    STORE_TYPE,
    LOCAL_STORAGE,
    STORAGE_GET_FORM,
    PROMOTION,
    PROMOTION_RULES,
    STORE_COUNTRY,
    STORE_COUNTRIES,
    CURRENCY_SYMBOLS_VALUES,
    PAYMENT_CODES,
    PRODUCT_ITEM_TYPES,
} from '../utils/constants/variables';

import { getProductDataByBarcode } from '../utils/constants/service';
import history from './history';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const debounce = (fn, wait = 300) => {
    let timer;
    return (...args) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => fn(...args), wait);
    };
};

export const debounced200 = debounce((fn) => fn(), 200);

export const getActiveBasketName = () => {
    try {
        const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
        const basketKeys = Object.keys(activeBasket);
        const name = basketKeys.find(
            (key) => activeBasket[key] && activeBasket[key].status === 'active',
        );
        if (basketKeys.length < 3) {
            localStorage.removeItem(ACTIVE_BASKET);
            const activeBasket = DEFAULT_BASKETS.reduce((acc, item, i) => {
                acc[item] = {
                    status: i === 0 ? 'active' : 'inactive',
                    storeClientId: 0,
                };
                return acc;
            }, {});
            localStorage.setItem(ACTIVE_BASKET, JSON.stringify(activeBasket));
        }
        return name || 'basket_1';
    } catch (e) {
        return '';
    }
};

export const changeActiveBasket = (name) => {
    const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
    const activeName = getActiveBasketName();
    if (name !== activeName && Object.keys(activeBasket).length) {
        const baskets = {
            ...activeBasket,
            [name]: { ...activeBasket[name], status: 'active' },
            [activeName]: { ...activeBasket[activeName], status: 'inactive' },
        };
        try {
            localStorage.setItem(ACTIVE_BASKET, JSON.stringify(baskets));
            return true;
        } catch (e) {
            return false;
        }
    }

    return false;
};

export const getActiveBasketClientId = () => {
    const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
    const name = Object.keys(activeBasket).find(
        (key) => activeBasket[key] && activeBasket[key].status === 'active',
    );
    if (name && activeBasket[name].storeClientId) {
        return activeBasket[name].storeClientId;
    }
    return null;
};

export const changeBasketClientId = (
    storeClientId = 0,
    name = getActiveBasketName(),
) => {
    const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
    if (name) {
        const baskets = {
            ...activeBasket,
            [name]: { ...activeBasket[name], storeClientId: storeClientId },
        };
        try {
            localStorage.setItem(ACTIVE_BASKET, JSON.stringify(baskets));
            return true;
        } catch (e) {
            return false;
        }
    }
};

export const getBasketClientIdByName = (name = getActiveBasketName()) => {
    const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
    if (name && activeBasket[name] && activeBasket[name].storeClientId) {
        return activeBasket[name].storeClientId;
    }
    return 0;
};

export const destroyAllBaskets = () => {
    try {
        getBasketsArray().forEach((item) => {
            localStorage.removeItem(item);
        });
        localStorage.removeItem(ACTIVE_BASKET);

        return true;
    } catch (error) {
        return false;
    }
};

export const destroyActiveBasket = () => {
    try {
        const name = getActiveBasketName();
        localStorage.removeItem(name);
        changeBasketClientId();
        if (!DEFAULT_BASKETS.includes(name)) {
            const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
            delete activeBasket[name];
            const basketKeys = Object.keys(activeBasket);
            activeBasket[basketKeys[basketKeys.length - 1]].status = 'active';
            localStorage.setItem(ACTIVE_BASKET, JSON.stringify(activeBasket));
        }
        return true;
    } catch (e) {
        return false;
    }
};

export const addNewBasket = () => {
    const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
    const name = `basket_${Date.now()}`;
    activeBasket[name] = { status: 'active', storeClientId: 0 };
    localStorage.setItem(ACTIVE_BASKET, JSON.stringify(activeBasket));
    changeActiveBasket(name);
};

export const getBasketsArray = () => {
    const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
    return Object.keys(activeBasket);
};

export const getPackDiscountPrice = (name, product, packID) => {
    let parentElementQuantity = 0;
    let childElementQuantity = 0;
    let childElementStartingPrice = 0;
    let totalChildPrice = 0;
    let currentItemPrice = 0;

    if (product.packParent) {
        const packElement = getLocalStorageObject(name)[product.packParent];
        const pack = getLocalStorageObject(name)[packID];
        packElement &&
            Array.isArray(packElement.sizes) &&
            packElement.sizes.forEach((item) => {
                parentElementQuantity += item.quantity;
                currentItemPrice = item.quantity;
            });

        pack &&
            Array.isArray(pack.sizes) &&
            pack.sizes.forEach((item) => {
                childElementQuantity += item.quantity;
                childElementStartingPrice += item.startingPrice;
            });
        if (parentElementQuantity < childElementQuantity) {
            const count = childElementQuantity - parentElementQuantity;
            totalChildPrice = count * childElementStartingPrice;
        }
    }
    return {
        parentElementQuantity,
        childElementQuantity,
        totalChildPrice,
        childElementStartingPrice,
        currentItemPrice,
    };
};

export const getProductPack = (name, id) => {
    const packElem = getLocalStorageObject(name)[id];
    let quantity = 0;
    Array.isArray(packElem.sizes) &&
        packElem.sizes.forEach((item) => {
            quantity += item.quantity;
        });
    let packID = [];
    if (Array.isArray(packElem.pack)) {
        packElem.pack.forEach((item) => {
            const elem = {
                productId: item['parentProductId'],
                prSize: item.productId,
                quantity,
            };
            packID.push(elem);
        });
    }
    return packID;
};

export const returnProduct = (product, startingPrice, returnReasonStore) => {
    if (startingPrice < 0) {
        delete product.returnReasonStore;
    } else {
        if (returnReasonStore) {
            product.returnReasonStore = returnReasonStore;
        }
    }
    if (product.taxFreeTotalPrice) {
        product.taxFreeTotalPrice = -1 * product.taxFreeTotalPrice;
    }

    if (product.listPriceAfterDiscount) {
        product.listPriceAfterDiscount = -1 * product.listPriceAfterDiscount;
    }

    if (product.subTotalDiscount && product.subTotalDiscount.discountPrice) {
        product.subTotalDiscount.discountPrice =
            product.subTotalDiscount.discountPrice * -1;
    }

    product.startingPrice = -1 * startingPrice;
    product.oldPrice = -1 * product.oldPrice;

    return product;
};

export const addToLocalStorage = function (
    name = getActiveBasketName(),
    key,
    value,
    setAsQuantity = false,
    isEmpUser = false
) {
    if (value && value.size && !value.size.oldPrice) {
        value.size.oldPrice = value.size.startingPrice;
    }
    if (value && value.size) {
        delete value.size['storeInventories'];
        delete value.size['inventories'];
    }

    let addedToStorage = true;
    const existing = getLocalStorageObject(name);

    if (
        existing.ticket &&
        existing.ticket.ticketNumber &&
        value.size.startingPrice !== -1 &&
        !value.size.quantity
    ) {
        return false;
    }

    if (existing[key] && Object.entries(existing[key]).length) {
        const sizeProductId = parseInt(value.size.productId);
        const sizeIndexByProductId = getIndexByValue(
            existing[key].sizes,
            sizeProductId,
            'productId',
        );

        if (
            existing[key].sizes &&
            existing[key].sizes.length &&
            sizeIndexByProductId !== -1
        ) {
            const { startingPrice, isPromo } =
                existing[key].sizes[sizeIndexByProductId];

            if (value.size.quantity) {
                existing[key].sizes[sizeIndexByProductId].quantity =
                    Number(value.size.quantity) +
                    (setAsQuantity
                        ? 0
                        : Number(
                              existing[key].sizes[sizeIndexByProductId]
                                  .quantity,
                          ));
                if (!existing[key].sizes[sizeIndexByProductId].subTotalId) {
                    existing[key].sizes[sizeIndexByProductId].createdDate =
                        Date.now();
                }
            }
            if (value.size && value.size.returnReasonStore && value.size.returnReasonStore.currentlyReturned) {
                existing[key].sizes[sizeIndexByProductId].currentlyReturned = value.size.returnReasonStore.currentlyReturned;
            }
            if (value.name || value.canonicalMenu || setAsQuantity) {
                localStorage.setItem(name, JSON.stringify(existing));
                addPromotionsDiscounts(name);
                return true;
            }

            if (
                value.size.specialDiscountId &&
                value.size.specialDiscountId.storeSpecialDiscountId
            ) {
                existing[key].sizes[sizeIndexByProductId].specialDiscountId =
                    value.size.specialDiscountId;
            }

            if (value.size.discountDetail) {
                const {
                    promoDiscountDetail,
                    promoListPriceAfterDiscount,
                    isPromo,
                    quantity,
                } = existing[key].sizes[sizeIndexByProductId];
                const price = promoListPriceAfterDiscount || startingPrice;

                if (parseFloat(value.size.discountDetail)) {
                    delete existing[key].sizes[sizeIndexByProductId]
                        .qtyDiscount;
                    delete existing[key].sizes[sizeIndexByProductId]
                        .amtDiscount;

                    if (value.size.discountDetail.includes('%')) {
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].listPriceAfterDiscount = getNotRoundedRealNumber(
                            price -
                                price * (Math.abs(
                                        parseFloat(value.size.discountDetail),
                                    ) / 100), 4);
                    } else {
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].listPriceAfterDiscount = fixNumber(
                            price -
                                Math.abs(
                                    parseFloat(value.size.discountDetail),
                                ) /
                                    quantity,
                        );
                    }

                    existing[key].sizes[sizeIndexByProductId].discountDetail =
                        value.size.discountDetail;

                    if (value.size.specialDiscountId) {
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].specialDiscountId = value.size.specialDiscountId;
                    }
                } else {
                    if (promoDiscountDetail && isPromo) {
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].listPriceAfterDiscount = promoListPriceAfterDiscount;
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].discountDetail = promoDiscountDetail;
                    } else {
                        delete existing[key].sizes[sizeIndexByProductId]
                            .listPriceAfterDiscount;
                        delete existing[key].sizes[sizeIndexByProductId]
                            .discountDetail;
                    }
                    delete existing[key].sizes[sizeIndexByProductId]
                        .specialDiscountId;
                }
                // if(priceChange) {
                //     existing[key].sizes[sizeIndexByProductId].startingPrice = oldPrice;
                // }
            }

            if (value.size.startingPrice === -1) {
                returnProduct(
                    existing[key].sizes[sizeIndexByProductId],
                    startingPrice,
                    value.size.returnReasonStore,
                );
            } else if (value.size.startingPrice || value.size.startingPrice === 0) {
                const newPrice =
                    startingPrice >= 0
                        ? value.size.startingPrice
                        : value.size.startingPrice * -1;
                if (existing[key].sizes[sizeIndexByProductId].discountDetail) {
                    if (
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].discountDetail.includes('%')
                    ) {
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].listPriceAfterDiscount = fixNumber(
                            value.size.startingPrice -
                                value.size.startingPrice *
                                    (Math.abs(
                                        Number(
                                            existing[key].sizes[
                                                sizeIndexByProductId
                                            ].discountDetail.replace('%', ''),
                                        ),
                                    ) /
                                        100),
                        );
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].startingPrice = newPrice;
                    } else {
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].listPriceAfterDiscount =
                            value.size.startingPrice -
                            Math.abs(
                                Number(
                                    existing[key].sizes[
                                        sizeIndexByProductId
                                    ].discountDetail.replace(
                                        DEFAULT_CURRENCY_SYMBOL,
                                        '',
                                    ),
                                ),
                            );
                        existing[key].sizes[
                            sizeIndexByProductId
                        ].startingPrice = newPrice;
                    }
                } else {
                    existing[key].sizes[sizeIndexByProductId].startingPrice =
                        newPrice;
                }

                if (isPromo) {
                    delete existing[key].sizes[sizeIndexByProductId]
                        .listPriceAfterDiscount;
                    delete existing[key].sizes[sizeIndexByProductId]
                        .promoDiscountDetail;
                    delete existing[key].sizes[sizeIndexByProductId]
                        .promoListPriceAfterDiscount;
                    delete existing[key].sizes[sizeIndexByProductId]
                        .discountDetail;
                    existing[key].sizes[sizeIndexByProductId].isPromo = false;
                }

                existing[key].sizes[sizeIndexByProductId].priceChange =
                    newPrice;
            }

            if (
                value.size.subTotalDiscount &&
                Object.keys(value.size.subTotalDiscount).length
            ) {
                existing[key].sizes[sizeIndexByProductId]['subTotalDiscount'] =
                    value.size.subTotalDiscount;
            } else if (
                value.size.subTotalDiscount &&
                Object.keys(value.size.subTotalDiscount).length === 0
            ) {
                delete existing[key].sizes[sizeIndexByProductId][
                    'subTotalDiscount'
                ];
            }
            if (value.size.subTotalId > -1) {
                existing[key].sizes[sizeIndexByProductId]['subTotalId'] =
                    value.size.subTotalId;
            } else if (value.size.subTotalId === -1) {
                delete existing[key].sizes[sizeIndexByProductId]['subTotalId'];
            }
        } else {
            const newSize = { ...value.size, isPromo: false };
            if (
                value.size &&
                parseFloat(value.size.discountDetail) &&
                value.size.listPriceAfterDiscount
            ) {
                newSize.isPromo = true;
                newSize.promoDiscountDetail = value.size.discountDetail;
                newSize.promoListPriceAfterDiscount =
                    value.size.listPriceAfterDiscount;
            }

            if (
                getLocalFormItem(
                    'giftCards',
                    'barcode',
                    newSize.productReference,
                ).barcode
            ) {
                newSize.itemType = PRODUCT_ITEM_TYPES.GFC;
            }

            newSize.createdDate = new Date().getTime();
            newSize.parentID = key;
            existing[key].sizes.push(newSize);
        }
    } else {
        const { size, ...data } = value;
        const newSize = { ...size, isPromo: false };

        if (
            size.discountDetail &&
            parseFloat(value.size.discountDetail) &&
            size.listPriceAfterDiscount
        ) {
            newSize.isPromo = true;
            newSize.promoDiscountDetail = size.discountDetail;
            newSize.promoListPriceAfterDiscount = size.listPriceAfterDiscount;
        }

        if (
            getLocalFormItem('giftCards', 'barcode', newSize.productReference)
                .barcode
        ) {
            newSize.itemType = PRODUCT_ITEM_TYPES.GFC;
        }

        if(isEmpUser && data.isClientDiscountAllow) {
            const { clientTypeLabels = [], storeSpecialDiscounts = [] } = getLocalFormData();
            const { discountRate } = clientTypeLabels.find(label => label.code === "EMP") || {};
            const { storeSpecialDiscountId, specialDiscount, code } = storeSpecialDiscounts.find(label => label.code === "EMP");

            newSize.discountDetail = `-${fixNumber(discountRate * 100) || 0}%`;
            newSize.listPriceAfterDiscount = newSize.isPromo
                ? newSize.listPriceAfterDiscount - discountRate * newSize.listPriceAfterDiscount
                : newSize.startingPrice - discountRate * newSize.startingPrice;
            newSize.specialDiscountId = {
                storeSpecialDiscountId,
                specialDiscount,
                code
            }
        }

        newSize.createdDate = new Date().getTime();
        newSize.parentID = key;
        data.sizes = [newSize];
        existing[key] = data;
    }

    if (addedToStorage) {
        localStorage.setItem(name, JSON.stringify(existing));
        addPromotionsDiscounts(name);
    }

    return addedToStorage;
};

export const addPromotionsDiscounts = (name) => {
    if (+PROMOTION_RULES !== 1 || !PROMOTION) return false;
    const basket = getLocalStorageObject(name);
    const { ticketNumber } = getBasketData(basket);
    if (ticketNumber) return false;
    addPromotionsQtyDiscounts(name, basket);
    addPromotionsAmtDiscounts(name);
    addPromotionsPerDiscounts(name);
};

export const addPromotionsQtyDiscounts = (name, basket) => {
    try {
        if (!PROMOTION.qty) return;
        const { storeSpecialDiscounts = [] } = getLocalFormData();
        const qty = PROMOTION.qty.split(':');
        const { code, specialDiscount, storeSpecialDiscountId } =
            storeSpecialDiscounts.find((item) => item.code === qty[0]) || {};
        const specialDiscountId = {
            code,
            specialDiscount,
            storeSpecialDiscountId,
        };
        if (!qty.length) return;
        const basketArr = [];
        getBasketProductsBySizes(basket, true).forEach((item) => {
            for (let i = 0; i < item.quantity; i++) {
                basketArr.push({
                    parentID: item.parentID,
                    productId: item.productId,
                    startingPrice:
                        item.promoListPriceAfterDiscount || item.startingPrice,
                    quantity: 1,
                });
            }
            basket[item.parentID].sizes.forEach((prod) => {
                if (prod.qtyDiscount || prod.amtDiscount) {
                    delete prod.specialDiscountId;
                    delete prod.discountDetail;
                    delete prod.listPriceAfterDiscount;
                }
                delete prod.qtyDiscount;
                delete prod.amtDiscount;
                delete prod.perDiscount;
                if (item.promoListPriceAfterDiscount) {
                    prod.listPriceAfterDiscount =
                        item.promoListPriceAfterDiscount;
                    prod.discountDetail = item.promoDiscountDetail;
                }
            });
        });

        let basketArray = basketArr.sort(
            (a, b) => a.startingPrice - b.startingPrice,
        );

        basketArray.forEach((item, idx) => {
            const [, discount] = qty[idx + 1]
                ? qty[idx + 1].split(',')
                : qty[qty.length - 1].split(',');
            const startingPrice =
                item.promoListPriceAfterDiscount || item.startingPrice;
            basket[item.parentID].sizes.forEach((prod) => {
                if (prod.productId === item.productId) {
                    const listPriceAfterDiscount = fixNumber(
                        startingPrice -
                            startingPrice *
                                (Math.abs(parseFloat(discount)) / 100),
                    );
                    if (parseFloat(discount)) {
                        prod.listPriceAfterDiscount = listPriceAfterDiscount;
                        prod.discountDetail = discount;
                        prod.qtyDiscount = prod.qtyDiscount
                            ? [
                                  ...prod.qtyDiscount,
                                  { discount, listPriceAfterDiscount },
                              ]
                            : [{ discount, listPriceAfterDiscount }];
                        prod.specialDiscountId = specialDiscountId;
                    }
                    if (parseFloat(discount) === 0) {
                        prod.qtyDiscount = prod.qtyDiscount
                            ? [
                                  ...prod.qtyDiscount,
                                  { discount, listPriceAfterDiscount },
                              ]
                            : [{ discount, listPriceAfterDiscount }];
                    }
                }
            });
        });

        localStorage.setItem(name, JSON.stringify(basket));
        return true;
    } catch (e) {
        return false;
    }
};

export const addPromotionsAmtDiscounts = (name) => {
    try {
        if (!PROMOTION.amt) return;
        const { storeSpecialDiscounts = [] } = getLocalFormData();
        const amt = PROMOTION.amt.split(':');
        const [amtPrice, amtDiscount] = amt[1] ? amt[1].split(',') : [];
        if (!amtPrice || !amtDiscount) return;
        const { code, specialDiscount, storeSpecialDiscountId } =
            storeSpecialDiscounts.find((item) => item.code === amt[0]) || {};
        const specialDiscountId = {
            code,
            specialDiscount,
            storeSpecialDiscountId,
        };

        localStorage.setItem(
            name,
            JSON.stringify(
                getPromotionsBasket(
                    name,
                    'amtDiscount',
                    amtDiscount,
                    specialDiscountId,
                    amtPrice,
                ),
            ),
        );
        return true;
    } catch (e) {
        return false;
    }
};

export const addPromotionsPerDiscounts = (name) => {
    try {
        if (!PROMOTION.per) return;
        const { storeSpecialDiscounts = [] } = getLocalFormData();
        const per = PROMOTION.per.split(':');
        const [perCount, perDiscount] = per[1] ? per[1].split(',') : [];
        if (!perCount || !perDiscount) return;
        const { code, specialDiscount, storeSpecialDiscountId } =
            storeSpecialDiscounts.find((item) => item.code === per[0]) || {};
        const specialDiscountId = {
            code,
            specialDiscount,
            storeSpecialDiscountId,
        };

        localStorage.setItem(
            name,
            JSON.stringify(
                getPromotionsBasket(
                    name,
                    'perDiscount',
                    perDiscount,
                    specialDiscountId,
                    perCount,
                ),
            ),
        );
        return true;
    } catch (e) {
        return false;
    }
};

export const getPromotionsBasket = (
    name,
    promotion,
    discount,
    specialDiscountId,
    value,
) => {
    const basket = getLocalStorageObject(name);
    const { totalQuantity, price } = getBasketData(basket);
    try {
        Object.keys(basket).forEach((key) => {
            basket[key].sizes.forEach((item) => {
                if (item.itemType === PRODUCT_ITEM_TYPES.GFC) {
                    return;
                }
                if (
                    promotion === 'amtDiscount'
                        ? price < value
                        : totalQuantity < value
                ) {
                    if (!item[promotion]) return;

                    delete item.perDiscount;
                    delete item.amtDiscount;
                    delete item.specialDiscountId;

                    if (item.promoListPriceAfterDiscount) {
                        item.listPriceAfterDiscount =
                            item.promoListPriceAfterDiscount;
                        item.discountDetail = item.promoDiscountDetail;
                    } else {
                        delete item.discountDetail;
                        delete item.listPriceAfterDiscount;
                    }
                    return;
                }
                const startingPrice =
                    item.promoListPriceAfterDiscount || item.startingPrice;
                if (
                    Math.abs(parseFloat(item.discountDetail)) >
                        Math.abs(parseFloat(discount)) &&
                    item.promoDiscountDetail !== item.discountDetail
                )
                    return;
                item.listPriceAfterDiscount = fixNumber(
                    startingPrice -
                        startingPrice * (Math.abs(parseFloat(discount)) / 100),
                );
                item.discountDetail = discount;
                item[promotion] = discount;
                item.specialDiscountId = specialDiscountId;
                delete item.qtyDiscount;
                promotion === 'amtDiscount'
                    ? delete item.perDiscount
                    : delete item.amtDiscount;
            });
        });
        return basket;
    } catch (e) {
        return basket;
    }
};

export const getLocalStorageObject = (name) => {
    const existing = localStorage.getItem(name);
    if (!existing) {
        return {};
    }
    try {
        return JSON.parse(existing);
    } catch (e) {
        return {};
    }
};

export const removeFromStorage = function (
    name = getActiveBasketName(),
    key,
    sizeProductId,
) {
    const existing = getLocalStorageObject(name);

    if (existing[key] && Object.entries(existing[key]).length) {
        const sizeIndexByProductId = getIndexByValue(
            existing[key].sizes,
            sizeProductId,
            'productId',
        );
        if (
            existing[key].sizes &&
            existing[key].sizes.length &&
            sizeIndexByProductId !== -1
        ) {
            delete existing[key].sizes.splice(sizeIndexByProductId, 1);
            if (existing[key].sizes && !existing[key].sizes.length) {
                delete existing[key];
            }
        }
        const prod = getBasketProductsBySizes(existing);
        if (!(prod && prod.length)) {
            delete existing.sousTotal;
        }
        localStorage.setItem(name, JSON.stringify(existing));
        addPromotionsDiscounts(name);
    }
};

export const findItem = (menuList, levels) => {
    let found;
    if (typeof menuList == 'object') {
        found = menuList.find((item) => {
            return item.key.split('_').join('-') === levels[0];
        });
        let level = levels.slice(1);
        if (found && found.children && level.length > 0) {
            return findItem(found.children, level);
        }
    }
    return found;
};

export const getItemMenu = (type, key, data) => {
    if (data && data.length) {
        return data.find(
            (item) =>
                (item.menuType.toUpperCase() === type.toUpperCase() &&
                    item.key === key) ||
                (item.children &&
                    item.children.length &&
                    getItemMenu(type, key, item.children)),
        );
    }
    return '';
};

export const getMenuItemCanonicalUrl = (type, key, menu) => {
    try {
        const item = getItemMenu(type, key, menu);
        return getLangPrefix() + (item.canonicalUrl || '');
    } catch (error) {
        return '/';
    }
};

export const getProductFullUrl = (location, canonicalUrl = '') => {
    let currentLocation = location.pathname.split('/');
    let levels = currentLocation.slice(1);
    levels.splice(-1, 1);
    levels.push(canonicalUrl);
    return '/' + levels.join('/');
};

export const parseData = (data) => {
    const regex = /(<([^>]+)>)/gi;
    return parse(data.replace(regex, ''));
};

export const getCurrency = (currency = '') => {
    let result = '';
    switch (currency) {
        case DEFAULT_CURRENCY:
            result = DEFAULT_CURRENCY_SYMBOL;
            break;
        case 'EUR':
            result = '€';
            break;
        case 'CHF':
            result = 'CHF';
            break;
        default:
            result = DEFAULT_CURRENCY_SYMBOL;
            break;
    }
    return result;
};

export const getAbbreviatedCurrencySymbol = (currencySymbol = '') => {
    try {
        return CURRENCY_SYMBOLS_VALUES[currencySymbol] || currencySymbol;
    } catch (e) {
        return currencySymbol;
    }
};

export const replaceComma = (price) => {
    try {
        if (!Number(price) || isNaN(Number(price))) {
            return '0,00';
        }
        return Number(price).toFixed(2).replace('.', ',');
    } catch (e) {
        return '0,00';
    }
};

export const roundUpTreeDecimal = (price) => {
    const negative = price < 0 ? -1 : 1;
    let newPrice = `${price * 1000}`.match(/([-0-9]+)\.0{3}/i);
    newPrice = newPrice ? Number(newPrice[1]) : price * 1000;
    return Math.ceil(Math.abs(newPrice)) / 1000 * negative;
}

export const getRealNumber = (num, size) => {
    const numbers = `${num}`.split('');
    const validNumber = numbers.reduce((acc, number, index) => {
        if(number === '.') {
            acc.position = index;
        } else {
            acc.number += number;
        }
        if (acc.position + size === acc.number.length) {
            acc.number += '.';
        }
        return acc;
    }, {number: '', start: 0})
    return Number(validNumber.number);
}

export const getNotRoundedRealNumber = (number, size) => {
    const pattern = new RegExp(`[0-9]+\.[0-9]{${size}}`)
    const result = `${number}`.match(pattern);
    return Array.isArray(result) ? Number(result[0]) : number;
}

export const fixedInHundredth = (number, roundChf = true) => {
    try {
        let num = number;
        let regexp = /^\d+\.\d{3,}$/;
        const regexExpression = regexp.test(Math.abs(num));
        if (num && regexExpression) {
            const negative = num < 0 ? -1 : 1;
            num = Math.round(getRealNumber(Math.abs(num), 2)) / 100;
            num = +(+num * negative).toFixed(2)
        }
        regexp = /^\d+\.\d{2}$/;
        if(num && regexp.test(Math.abs(num)) && DEFAULT_CURRENCY === "CHF" && !regexExpression && roundChf) {
            const lastDigit = Math.round(num * 10 % 1 * 10);
            num += ((lastDigit <= 7.5 ? lastDigit <= 5 / 2 ? 0 : 5 : 10) - lastDigit) / 100;
        }
        return num;
    } catch (error) {
        return 0;
    }
};

export const replaceCommaToDecimal = (str) => {
    if (typeof str === 'string') {
        const res = str.replace(',', '.');
        if (isNaN(Number(res))) {
            return '0';
        }
        return Number(res).toFixed(2);
    }
    return +str || 0;
};

export const priceThreeDigitSets = (
    price,
    currency = '',
    currencySymbol = '',
) => {
    return price
        ? `${replaceComma(price).replace(
              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
              '.',
          )} ${currencySymbol || getCurrency(currency)}`
        : '';
};

export const threeDigitSets = (num = '') => {
    const str = num.toString();
    if (!Number(str)) return num;
    return str.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

const getIndexByValue = (array = [], value = '', key = '') => {
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) return i;
    }
    return -1;
};

export const findNodeByProperty = (tree, filter, property = 'id') =>
    tree.find(
        (node) =>
            node[property] === filter ||
            (node[property] !== filter &&
                node.children &&
                node.children.length &&
                findNodeByProperty(node.children, filter, property)),
    );

export const getOpeningHours = (str = '') => {
    let res = [];
    str = str.replace(/^(;*)/, '').replace(/(;*)$/, '');
    if (str) {
        res = str.split(';');
    }
    return res;
};

export const formatOpeningHours = (hours = '') => {
    let item = hours.split('|');
    let hour = '';
    if (item.length > 2) {
        for (let i = 0; i < item.length; i++) {
            if (i % 2 === 0) {
                hour += `${item[i]}h-`.replace(/^(0*)/, '');
            } else {
                hour += `${item[i]}h`.replace(/^(0*)/, '');
            }
            if (i !== 0 && i % 2 === 1 && i < item.length - 1) {
                hour += ' et ';
            }
        }
    } else {
        hour = `${item[0].replace(/^(0*)/, '')}h - ${item[1].replace(
            /^(0*)/,
            '',
        )}h`;
    }

    return hour;
};

export const formatPhone = (str = '') => {
    let phone = str.replace(/^.{0,3}/, '0').match(/.{1,2}/g);
    return phone.join(' ');
};

export const getCatalogueParentMenuId = (menu, menuList, parenKey) => {
    if (menu && menu.key && menuList && menuList.length) {
        let parent = menuList.find((item) => item.key === parenKey);
        if (parent && parent.children) {
            parent = getItemMenu('MENU', menu.key, parent.children);
        }
        if (parent && parent.children) {
            parent = getItemMenu('MENU', menu.key, parent.children);
        }
        if (parent && parent.children) {
            parent = getItemMenu('MENU', menu.key, parent.children);
        }
        return parent && parent.id;
    }

    return '';
};

export const getDateTimestamp = (timestamp) => {
    if (!timestamp) {
        return '';
    }
    const date = new Date();
    date.setTime(timestamp);
    const { year, month, day } = getDateNow(date);
    return `${day}/${month}/${year}`;
};

export const getDateTimestampByTime = (timestamp, format = '') => {
    if (!timestamp) return '';

    let date = new Date();

    if (+timestamp) {
        date.setTime(timestamp);
    } else if (new Date(timestamp).toString() !== 'Invalid Date') {
        date = new Date(timestamp);
    }

    const { year, month, day, hours, minutes } = getDateNow(date);

    if (format === 'timeLocal') {
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }
    if (format === 'dd-mm-yy') {
        return `${day}-${month}-${year}`;
    }
    if (format === 'yy-mm-dd') {
        return `${year}-${month}-${day}`;
    }
    if (format === 'dd/mm/yy') {
        return `${day}/${month}/${year}`;
    }
    if (format === 'YYYYMMDD') {
        return `${year}${month}${day}`;
    }
    if (format === 'MM dd, YYYY') {
        const dateString = date.toDateString();
        try {
            return dateString.match(/\w+ (\w+ [0-9]{2} [0-9]{4})/i)[1].replace(/([0-9]{2})/i, '$1,');
        } catch(error) {
            return dateString;
        }
    }
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const getDateNow = (date = new Date()) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return { day, month, year, hours, minutes, seconds };
};

export const getGMTDateByTimstamp = (time = Date.now(), gmt = 0) => {
    try {
        const date = new Date(time);
        return new Date(+date - date.getTimezoneOffset() + 3600000 * gmt)
            .toISOString()
            .split('.')[0];
    } catch (error) {
        return '';
    }
};

export const getItemProductTotalPrice = (product = {}, isTax = false) => {
    const discount = product.listPriceAfterDiscount
        ? product.startingPrice - product.listPriceAfterDiscount
        : 0;
    if (product.taxFreeTotalPrice) {
        return product.taxFreeTotalPrice;
    }

    if (Array.isArray(product.qtyDiscount) && product.qtyDiscount.length) {
        return getTotalSum(product.qtyDiscount, 'listPriceAfterDiscount');
    }

    if (product.subTotalDiscount && product.subTotalDiscount.discountDetail) {
        return product.subTotalDiscount.discountPrice;
    }

    if (
        product.isPromo ||
        (product.discountDetail && product.discountDetail.includes('%'))
    ) {
        return Math.abs(product.quantity) * product.listPriceAfterDiscount;
    }

    if (product.discountDetail) {
        let price =
            Math.abs(product.quantity) * Math.abs(product.startingPrice);
        const discount = Math.abs(parseFloat(product.discountDetail));

        // if(isTax) {
        //     return product.startingPrice > 0 ? price : -1 * price;
        // }

        price = price - discount;
        return product.startingPrice > 0 ? price : -1 * price;
    }

    return Math.abs(product.quantity) * product.startingPrice - discount || 0;
};

export const getItemProductReductionPrice = (product = {}) => {
    try {
        const {
            oldPrice = 0,
            quantity = 0,
            subTotalDiscount,
            listPriceAfterDiscount = 0,
            promoListPriceAfterDiscount = 0,
        } = product;
        const price =
            promoListPriceAfterDiscount && ['O', 'F'].includes(STORE_TYPE)
                ? promoListPriceAfterDiscount
                : oldPrice;

        if (subTotalDiscount && subTotalDiscount.discountPrice) {
            return quantity * price - subTotalDiscount.discountPrice;
        }

        if (!listPriceAfterDiscount) {
            return 0;
        }

        return quantity * (price - listPriceAfterDiscount);
    } catch (error) {
        return 0;
    }
};

export const getThreeDecimal = (number) => {
    const stringNumber = `${number}`;
    const testPattern = /\.[0-9]{3,}/
    const selectPattern = /-?[-0-9]+\.[0-9]{3}/
    if (testPattern.test(stringNumber)) {
        const result = stringNumber.match(selectPattern);
        return +fixedInHundredth(Array.isArray(result) ? +result[0] : number, false);
    }
    return number;
}

export const getBasketData = (orders = {}) => {
    const { ticket = {}, storeGifts = [], coupons = [] } = orders;
    const {
        storeSaleFiscal = {},
        ticketNumber = '',
        paymentDue = null,
        saleQuantity,
        paymentTermCode,
    } = ticket;

    let price = 0;
    let totalPrice = 0;
    let totalNegativePrice = 0;
    let totalQuantity = 0;
    let livraison = 0;
    let giftTotalAmount = 0;
    let pack = [];
    let reduction = 0;
    let currentLyReturned = 0;
    let notRoundedPrice = 0;

    if (Object.keys(orders).length) {
        for (let i in orders) {
            if (Array.isArray(orders[i].pack) && orders[i].pack.length) {
                orders[i].pack.forEach((item) => {
                    let count = 0;
                    Array.isArray(orders[i].sizes) &&
                        orders[i].sizes.forEach((elem) => {
                            count += elem.quantity;
                        });
                    orders[item['parentProductId']] &&
                        Array.isArray(orders[item['parentProductId']].sizes) &&
                        orders[item['parentProductId']].sizes.forEach(
                            (sizeItem) => {
                                count = sizeItem.quantity - count;
                            },
                        );
                    pack.push({
                        item,
                        totalCount: count > 0 ? count : 0,
                    });
                });
            }
        }
    }

    if (Object.keys(orders).length) {
        Object.keys(orders).forEach((i) => {
            const itemPrice = orders[i].sizes
                ? orders[i].sizes.reduce((total, current) => {
                      const isCurrentlyReturned = current.returnReasonStore && current.returnReasonStore.currentlyReturned;
                      const packItem = pack.find(
                          (item) => item.item.productId === current.productId,
                      );
                      let sum = getItemProductTotalPrice(current) || 0;
                      totalQuantity = +fixedInHundredth(
                          ticketNumber
                              ? current.startingPrice < 0 && isCurrentlyReturned
                                  ? totalQuantity + current.quantity
                                  : totalQuantity
                              : totalQuantity + current.quantity,
                      );
                      reduction += +fixedInHundredth(
                          getItemProductReductionPrice(current),
                      );

                      if (packItem) {
                          return (
                              sum +
                              packItem.totalCount * packItem.item.startingPrice
                          );
                      } else {
                          currentLyReturned += !isCurrentlyReturned && sum < 0 ? total + sum : 0;
                          return total + sum;
                      }
                  }, 0)
                : 0;

            notRoundedPrice += itemPrice
            const productPrice = +fixedInHundredth(roundUpTreeDecimal(itemPrice));

            if (productPrice < 0) {
                totalNegativePrice += productPrice;
            }
            price += productPrice;
            totalPrice += productPrice;
        });

        if (storeGifts && storeGifts.length) {
            let giftPrice = 0;
            let giftTotalPrice = 0;
            storeGifts.forEach(({ percent, amount, active }) => {
                if (percent) {
                    giftTotalPrice += active
                        ? (-totalPrice * percent) / 100
                        : (totalPrice * percent) / 100;
                    giftPrice += active
                        ? (-price * percent) / 100
                        : (price * percent) / 100;
                } else {
                    giftTotalPrice += active ? -amount : amount;
                    giftPrice += active ? -amount : amount;
                    giftTotalAmount += active ? -amount : amount;
                }
                if (!active) {
                    totalQuantity++;
                }
            });
            totalPrice = +fixedInHundredth(totalPrice) + giftTotalPrice;
            price = +fixedInHundredth(price) + giftPrice;
            notRoundedPrice = notRoundedPrice + giftPrice;
        }
        if (coupons.length) {
            totalPrice = totalPrice - getCouponsDiscountByPrice(totalPrice);
            price = price - getCouponsDiscountByPrice(price);
            notRoundedPrice = notRoundedPrice - getCouponsDiscountByPrice(notRoundedPrice);
        }
    }

    if (ticketNumber && (paymentDue === 0 || paymentDue)) {
        price = totalNegativePrice
            ? paymentDue - Math.abs(totalNegativePrice + Math.abs(currentLyReturned))
            : paymentDue;
        notRoundedPrice = price;
    }
    if (ticketNumber && paymentDue && paymentDue > 0) {
        totalQuantity = saleQuantity;
    }

    // price = customRoundByCountry(price);
    // totalPrice = customRoundByCountry(totalPrice);

    return {
        price,
        totalPrice,
        livraison,
        totalQuantity,
        ticketNumber,
        paymentTermCode,
        paymentDue,
        totalNegativePrice,
        storeSaleFiscal,
        giftTotalAmount,
        reduction,
        notRoundedPrice
    };
};

export const getLang = () => {
    let lang = 'fr';
    if (['en', 'it', 'de'].includes(i18next.language)) {
        lang = i18next.language;
    }
    localStorage.setItem('language', lang);
    return lang;
};

export const getApiPrefix = (language = getLang()) => {
    const lang = language.toLowerCase();
    return `${lang}_${lang.toUpperCase()}`;
};

export const getLangPrefix = () => `/${getLang()}`;

// export const getFilterValue = (value) => {
//     if (typeof value === "string") {
//         return value.replace(/"|''|^'|\s+'$|'$/g, "");
//     } else {
//         return value
//     }
// };

export const encrypt = (key, value) => {
    const Cryptr = require('cryptr');
    const cryptr = new Cryptr(BROWSER_KEY);
    return localStorage.setItem(key, cryptr.encrypt(value));
};

export const decryptCryptr = (key) => {
    try {
        const Cryptr = require('cryptr');
        const cryptr = new Cryptr(BROWSER_KEY);
        const value = localStorage.getItem(key);
        return value ? cryptr.decrypt(value) : "";
    } catch (error) {
        return null;
    }
};

export const getBase64ImageFromUrl = async (imageUrl) => {
    return new Promise(async (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', imageUrl, true);
        xhr.onload = function () {
            const response = xhr.responseText;
            let binary = '';
            for (let i = 0; i < response.length; i++) {
                binary += String.fromCharCode(response.charCodeAt(i) & 0xff);
            }
            const src = 'data:image/jpeg;base64,' + btoa(binary);
            resolve(src);
        };

        xhr.overrideMimeType('text/plain; charset=x-user-defined');
        xhr.send();
    });
};

export const getGridTypeId = (data) => {
    if (!data) {
        return 0;
    }

    if (Array.isArray(data.children) && data.children.length) {
        const find = data.children.find((item) => item.children);
        if (find) {
            return getGridTypeId(find);
        } else {
            return data.children[0]['gridTypeId'] || 0;
        }
    } else {
        return data['gridTypeId'] || 0;
    }
};

export const getVaultBoxLocation = (uid, password) => {
    return CryptoJS.AES.encrypt(
        uid,
        CryptoJS.enc.Hex.parse(CryptoJS.SHA256(password).toString()),
        { iv: CryptoJS.enc.Hex.parse(password) },
    );
};

export const isValidPrivateToken = () => {
    const privateToken = sessionStorage.getItem(SESSION_STORAGE.privateToken);
    if (privateToken) {
        const decodedJwt = jwt.decode(privateToken);
        if (decodedJwt) {
            const expirationTime = decodedJwt['exp'];
            const currentTime = +new Date();
            if (expirationTime && expirationTime * 1000 > currentTime) {
                return true;
            }
        }
    }

    sessionStorage.clear();
    return false;
};

export const isValidClientToken = () => {
    const token = localStorage.getItem('token');

    if (token) {
        const decodedJwt = jwt.decode(token);
        if (decodedJwt) {
            const expirationTime = decodedJwt['exp'];
            const currentTime = +new Date();
            if (expirationTime && expirationTime * 1000 > currentTime) {
                return true;
            }
        }
    }
    localStorage.removeItem('token');
    localStorage.removeItem(LOCAL_STORAGE.isAssociateAdmin);
    return false;
};
export const getUserFromToken = () => {
    const token = localStorage.getItem('token');

    if (token) {
        const decodedJwt = jwt.decode(token);
        if (decodedJwt && decodedJwt['storeAssociateToken']) {
            return JSON.parse(decodedJwt['storeAssociateToken']);
        }
    }
    return {};
};

export const isTerminalAdmin = () => {
    return decryptCryptr(LOCAL_STORAGE.isAssociateAdmin) === 'true';
}

export const getCartData = (data, barcode, storeCode, qty = 1) => {
    try {
        let stock = 0;
        const cartData = {};
        const productSize = data.sizes.find(
            (product) => product.productReference === barcode,
        );
        const size = productSize || data.sizes[0] || {};
        cartData[data.productId] = {
            size: { ...size, quantity: qty },
            name: data.name,
            canonicallUrl: data.canonicalUrl,
            canonicalMenu: data.canonicalMenu,
            price: data.listPriceAfterDiscount || data.startingPrice,
            maxQuantity: PRODUCT_MAX_QUANTITY,
            photo: data.thumbnail || data.photo,
            pack: data.pack || [],
            oldPrice: data.startingPrice || '',
            isClientDiscountAllow: Boolean(data.listPriceAfterDiscount ? data.outlet : true)
        };

        productSize &&
            Array.isArray(productSize.storeInventories) &&
            productSize.storeInventories.forEach((store) => {
                if (store.storeCode === storeCode) {
                    stock = store.storeStock;
                    cartData[data.productId] = {
                        ...cartData[data.productId],
                        size: { ...cartData[data.productId].size, stock },
                    };
                }
            });

        return cartData;
    } catch (e) {
        return {};
    }
};

export const getProductDataForBasket = (product, quantity = 1) => {
    const {
        barcode,
        prices,
        currency = DEFAULT_CURRENCY,
        price,
        productId,
        size = '',
        taxRate = 0,
        name = '',
        storeClearanceId,
        listPrice,
        startingPrice,
    } = product;

    try {
        let currentPrice = null;
        if (Array.isArray(prices)) {
            const item = prices.find(
                (item) => item.currency === DEFAULT_CURRENCY,
            );
            if (item) {
                currentPrice = item.price;
            }
        }
        return {
            name,
            canonicallUrl: product.canonicalUrl || '',
            canonicalMenu: product.canonicalMenu || '',
            size: {
                size,
                taxRate,
                quantity,
                currency,
                productId: productId || storeClearanceId,
                startingPrice: currentPrice || listPrice || price,
                productReference: barcode,
            },
            price: currentPrice || price || listPrice || 0,
            maxQuantity: PRODUCT_MAX_QUANTITY,
            photo: '',
            pack: product.pack || [],
            oldPrice: startingPrice || price,
        };
    } catch (e) {
        return {};
    }
};

export const checkExistingProductInBasket = (productId, productSizeId) => {
    if (!productId || !productSizeId) {
        return false;
    }
    try {
        const baskets = JSON.parse(localStorage.getItem(getActiveBasketName()));
        if (baskets[productId] && Array.isArray(baskets[productId].sizes)) {
            return baskets[productId].sizes
                ? !!baskets[productId].sizes.find(
                      (item) => item.productId === productSizeId,
                  )
                : false;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const getTableData = (tableData, columns) => {
    let data = [];
    tableData &&
        tableData.length &&
        tableData.forEach((item) => {
            let itemArr = {};
            for (const column of columns) {
                itemArr = {
                    ...itemArr,
                    [column.selector]:
                        column.selector.search(/date/i) > -1 &&
                        column.format !== 'current'
                            ? getDateTimestampByTime(
                                  item[column.selector],
                                  column.format,
                              )
                            : ![
                                  'storeSaleTicketNumber',
                                  'saleQuantity',
                              ].includes(column.selector) &&
                              (column.selector.search(/sale/i) > -1 ||
                                  column.selector.search(/amount/i)) > -1
                            ? priceThreeDigitSets(
                                  item[column.selector],
                                  item.currency,
                              )
                            : column.selector === 'paymentDue'
                            ? priceThreeDigitSets(
                                  item[column.selector],
                                  item.currency,
                              )
                            : column.selector.search(/percent/i) > -1
                            ? getPercentFormat(item[column.selector])
                            : item[column.selector],
                };
            }

            data.push(itemArr);
        });
    return data;
};

export const filterByDate = (fromTo, data, columns) => {
    let filterData = [];
    const searchName = columns.filter((item) => item.datapicker)[0].selector;
    if (fromTo && data) {
        const to = fromTo.to.setHours(23, 59, 59, 999);
        const from = fromTo.from.setHours(0, 0, 0, 0);
        filterData = data.filter(
            (item) => item[searchName] >= from && item[searchName] <= to,
        );
    }
    return filterData;
};

export const getBasketProductsBySizes = (
    basket = {},
    excludeCartGift = false,
) => {
    let productList = [];
    const basketKeys = Object.keys(basket);
    if (basketKeys) {
        basketKeys.forEach((product) => {
            if (basket[product].sizes && basket[product].sizes.length) {
                basket[product].sizes.forEach((elem) => {
                    if (
                        excludeCartGift &&
                        elem.itemType === PRODUCT_ITEM_TYPES.GFC
                    ) {
                        return;
                    }
                    if (!elem.createdDate) {
                        elem.createdDate = new Date().getTime();
                    }
                    if (!elem.parentID) {
                        elem.parentID = product;
                    }
                    productList.push(elem);
                });
            }
        });
    }

    return productList;
};

export const getBasketItemProduct = (basket, productId) => {
    try {
        return getBasketProductsBySizes(basket).find(
            (i) => i.productId === +productId,
        );
    } catch (error) {
        return undefined;
    }
};

export const getSousTotal = (index) => {
    try {
        const basket = getLocalStorageObject(getActiveBasketName());
        let sousTotal = basket.sousTotal ? basket.sousTotal : [],
            subArr = [],
            discountArr = [];

        const existing = sousTotal.findIndex((item) => item.position === index);

        if (existing === -1) {
            sousTotal.push({ position: index, value: 0 });
        }

        sousTotal.sort((a, b) => a.position - b.position);

        sousTotal.forEach((sub) => {
            let value = 0,
                subObj = {};
            const updateBasket = getLocalStorageObject(getActiveBasketName()),
                products = getBasketProductsBySizes(updateBasket).sort((a, b) =>
                    sortBasketByDate(a, b),
                );

            for (let i = 0; i <= sub.position; i++) {
                const subId = products[i]
                    ? products[i].subTotalId === 0
                        ? 'zero'
                        : products[i].subTotalId
                    : false;

                if (
                    products[i] &&
                    products[i].itemType !== PRODUCT_ITEM_TYPES.GFC &&
                    (!subId ||
                        (subId && products[i].subTotalId >= sub.position))
                ) {
                    if (
                        !products[i].listPriceAfterDiscount &&
                        products[i].discountDetail
                    ) {
                        value += getIntDiscount(
                            products[i].discountDetail,
                            products[i].startingPrice,
                            products[i].quantity,
                        );
                    } else {
                        value += products[i].qtyDiscount
                            ? getTotalSum(
                                  products[i].qtyDiscount,
                                  'listPriceAfterDiscount',
                              )
                            : products[i].quantity *
                              (products[i].listPriceAfterDiscount ||
                                  products[i].startingPrice);
                    }
                    addToLocalStorage(
                        getActiveBasketName(),
                        products[i].parentID,
                        {
                            size: {
                                subTotalDiscount: {},
                                subTotalId: sub.position,
                                productId: products[i].productId,
                            },
                        },
                    );
                }
            }

            if (sub.discountDetail) {
                subObj = {
                    position: sub.position,
                    discountDetail: sub.discountDetail,
                    discountPrice: getIntDiscount(sub.discountDetail, value, 1),
                    specialDiscountId: sub.specialDiscountId,
                    subTotalId: sub.position,
                    value,
                };
                discountArr.push({ ...subObj });
            } else {
                subObj = { position: sub.position, value };
            }
            subArr = [...subArr, { ...subObj }];
        });
        localStorage.setItem(
            getActiveBasketName(),
            JSON.stringify({
                ...getLocalStorageObject(getActiveBasketName()),
                sousTotal: subArr,
            }),
        );
        discountArr
            .sort((a, b) => a.position - b.position)
            .forEach((item) => changeSousTotal('add', item));
    } catch (error) {
        console.log(error);
    }
};

export const changeSousTotal = (
    action,
    value,
    name = getActiveBasketName(),
) => {
    try {
        const basket = getLocalStorageObject(name),
            products = getBasketProductsBySizes(basket).sort((a, b) =>
                sortBasketByDate(a, b),
            );
        let sousTotal = basket.sousTotal ? basket.sousTotal : [];

        if (action === 'remove') {
            const index = sousTotal.findIndex(
                (item) => item.position === value,
            );

            if (index !== -1) {
                sousTotal.splice(index, 1);
                if (sousTotal.length) {
                    localStorage.setItem(
                        name,
                        JSON.stringify({ ...basket, sousTotal }),
                    );
                } else {
                    delete basket.sousTotal;
                    localStorage.setItem(name, JSON.stringify({ ...basket }));
                }
                products.forEach((item, idx) => {
                    if (value >= idx) {
                        addToLocalStorage(name, item.parentID, {
                            size: {
                                subTotalDiscount: {},
                                subTotalId: -1,
                                productId: item.productId,
                            },
                        });
                    }
                });
            }
            sousTotal.forEach((item) => getSousTotal(item.position));
        }
        if (action === 'add') {
            const index = sousTotal.findIndex(
                    (item) => item.position === value.position,
                ),
                prod = getBasketProductsBySizes(basket).sort((a, b) =>
                    sortBasketByDate(a, b),
                );

            let sumValue = 0;
            let sumDiscount = 0;
            if (index !== -1) {
                sousTotal.splice(index, 1);
            }

            sousTotal.push(value);
            localStorage.setItem(
                name,
                JSON.stringify({ ...basket, sousTotal }),
            );

            prod.forEach((item, index) => {
                if (
                    value.position >= index &&
                    item.subTotalId === value.position
                ) {
                    const discountDetail = getSubTotalDiscountDetail(
                        value,
                        item,
                    );

                    if (discountDetail) {
                        const price =
                            item.promoListPriceAfterDiscount ||
                            item.startingPrice;
                        const discountPrice = getIntDiscount(
                            discountDetail,
                            price,
                            item.quantity,
                        );

                        sumValue += price * item.quantity;
                        sumDiscount += discountPrice;

                        addToLocalStorage(name, item.parentID, {
                            size: {
                                subTotalDiscount: {
                                    discountPrice,
                                    discountDetail,
                                    specialDiscountId: {
                                        ...value.specialDiscountId,
                                    },
                                    subTotalId: value.subTotalId,
                                },
                                subTotalId: value.subTotalId,
                                productId: item.productId,
                                discountDetail: '0',
                            },
                        });
                    } else {
                        addToLocalStorage(name, item.parentID, {
                            size: {
                                subTotalId: value.subTotalId,
                                productId: item.productId,
                            },
                        });
                    }
                }
            });
            if (sumValue && sumDiscount) {
                const basket = getLocalStorageObject(name);
                const sousTotal = basket.sousTotal.map((item) => {
                    if (item.subTotalId === value.subTotalId) {
                        return {
                            ...item,
                            value: sumValue,
                            discountPrice: sumDiscount,
                        };
                    }
                    return item;
                });
                localStorage.setItem(
                    name,
                    JSON.stringify({ ...basket, sousTotal }),
                );
            }
            getBasketData({ ...basket, sousTotal });
        }
        if (action === 'removeIndex') {
            sousTotal.length &&
                sousTotal.forEach((item) => {
                    if (item.position >= value) {
                        changeSousTotal('remove', item.position);
                    }
                });
        }
        if (action === 'update') {
            sousTotal.length &&
                sousTotal.forEach((item) => getSousTotal(item.position));
        }
    } catch (error) {
        console.log(error);
    }
};

export const isPercentDiscount = (discount) => {
    try {
        return discount.endsWith('%');
    } catch (error) {
        return null;
    }
};

export const getIntDiscount = (discount, price, quantity) => {
    if (!discount) {
        return 0;
    }
    try {
        const discountPrice = Math.abs(parseFloat(discount));

        if (isPercentDiscount(discount)) {
            return quantity * fixNumber(price - (price * discountPrice) / 100);
        }

        return fixNumber(quantity * price - discountPrice);
    } catch (e) {
        return 0;
    }
};

export const getSubTotalDiscountDetail = (subItem, item) => {
    try {
        const { value, discountDetail } = subItem;
        const {
            quantity,
            promoListPriceAfterDiscount,
            startingPrice = 0,
        } = item;

        if (isPercentDiscount(discountDetail)) return discountDetail;

        const discount = Math.abs(
            (parseFloat(discountDetail) *
                quantity *
                (promoListPriceAfterDiscount || startingPrice)) /
                value,
        );
        return `-${fixNumber(discount)}${DEFAULT_CURRENCY_SYMBOL}`;
    } catch (error) {
        return null;
    }
};

export const getPercentFormat = (num) => (num ? num + '%' : '');

export const isValidDate = (date) => {
    return new Date(date).toString() !== 'Invalid Date';
};

export const addGiftToBasket = (gift = {}) => {
    try {
        if (!gift.storeGiftId) return false;
        const basket = getLocalStorageObject(getActiveBasketName());
        const { storeGifts = [] } = basket;
        const isExist = storeGifts.find((storeGift) => gift.storeGiftId === storeGift.storeGiftId)
        !isExist && localStorage.setItem(
            getActiveBasketName(),
            JSON.stringify({ ...basket, storeGifts: [...storeGifts, gift] }),
        );
        return true;
    } catch (e) {
        return false;
    }
};

export const getBasketStoreGifts = () => {
    try {
        const { storeGifts = [] } = getLocalStorageObject(
            getActiveBasketName(),
        );
        return storeGifts;
    } catch (e) {
        return [];
    }
};

export const removeBasketGifts = (storeGiftCode) => {
    const basket = getLocalStorageObject(getActiveBasketName());
    try {
        if (
            storeGiftCode &&
            basket.storeGifts &&
            basket.storeGifts.length > 1
        ) {
            basket.storeGifts = basket.storeGifts.filter(
                (item) => item.storeGiftCode !== storeGiftCode,
            );
        } else {
            delete basket.storeGifts;
        }
        localStorage.setItem(getActiveBasketName(), JSON.stringify(basket));
        return true;
    } catch (e) {
        return false;
    }
};

export const isExistGiftInBasket = (storeGiftCode) => {
    const storeGifts = getBasketStoreGifts();
    return storeGifts.some((item) => item.storeGiftCode === storeGiftCode);
};

export const getStoreGiftValue = (storeGift) => {
    if (!storeGift || typeof storeGift !== 'object') return '';
    const { amount, percent, currency } = storeGift;
    if (amount) {
        return `${replaceComma(amount)} ${getCurrency(currency)}`;
    }
    if (percent) {
        return percent + '%';
    }
    return '';
};

export const isExistPercentGiftInBasket = () => {
    const storeGifts = getBasketStoreGifts();
    return storeGifts.some((item) => item.percent);
};

export const addCouponToBasket = (coupon) => {
    try {
        if (!coupon.valid) return false;
        const basket = getLocalStorageObject(getActiveBasketName());
        localStorage.setItem(
            getActiveBasketName(),
            JSON.stringify({ ...basket, coupons: [coupon] }),
        );
        return true;
    } catch (e) {
        return false;
    }
};

export const getCouponsDiscountByPrice = (price) => {
    try {
        let discountPrice = 0;
        const basket = getLocalStorageObject(getActiveBasketName());
        const { coupons = [] } = basket;
        if (!coupons.length) return price;
        coupons.forEach((item) => {
            if (item['couponType'] === 'PERCENT') {
                discountPrice += fixNumber((price * item.value) / 100);
            } else {
                discountPrice += fixNumber(item.value);
            }
        });
        return discountPrice;
    } catch (e) {
        return 0;
    }
};



export const isExistCouponInBasket = (discountCode) => {
    try {
        const { coupons = [] } = getLocalStorageObject(getActiveBasketName());
        return coupons.some((item) => item['discountCode'] === discountCode);
    } catch (e) {
        return false;
    }
};

export const isExistCoupons = () => {
    try {
        const { coupons = [] } = getLocalStorageObject(getActiveBasketName());
        return !!coupons.length;
    } catch (e) {
        return false;
    }
};

export const getTotalSum = (arr = [], param) => {
    try {
        if (!Array.isArray(arr)) {
            return 0;
        }
        if (!param) return arr.reduce((a, b) => a + b, 0);

        return arr.reduce((acc, item) => {
            if (+item[param] || +item[param] === 0) {
                return acc + +item[param];
            }
            return +item || 0;
        }, 0);
    } catch (e) {
        return 0;
    }
};

export const getBasketCount = () => {
    try {
        const baskets = JSON.parse(localStorage.getItem(ACTIVE_BASKET));
        let counter = 0;
        if (baskets && Object.keys(baskets).length) {
            Object.keys(baskets).forEach((item) => {
                try {
                    if (
                        localStorage.getItem(item) &&
                        Object.keys(JSON.parse(localStorage.getItem(item)))
                            .length
                    ) {
                        counter++;
                    }
                } catch (e) {
                    localStorage.removeItem(item);
                }
            });
        }
        return counter;
    } catch (e) {
        console.log(e);
    }
};

export const addDays = (date, days) => {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
};

export const clientCounter = (count) => {
    localStorage.setItem(LOCAL_STORAGE.clientCounter, count);
    return count;
};

export const deleteEmptyValueFromObj = (obj, valueType = 'number') => {
    try {
        const newObj = { ...obj };
        for (const key in newObj) {
            if (valueType === 'number' && !+newObj[key]) {
                delete newObj[key];
            } else if (!newObj[key]) {
                delete newObj[key];
            }
        }
        return newObj;
    } catch (e) {
        return {};
    }
};

export const addDeliveryStore = (data = {}) => {
    const { storeCode, storeType } = data;
    const name = data.name || data.storeName;
    let existing = localStorage.getItem(LOCAL_STORAGE.deliveryBasket);
    try {
        existing = existing ? JSON.parse(existing) : {};
    } catch (e) {
        existing = {};
    }

    existing['store'] = { name, storeCode, storeType };

    localStorage.setItem(
        LOCAL_STORAGE.deliveryBasket,
        JSON.stringify(existing),
    );

    return existing;
};

export const cloneObject = (obj) => {
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch (e) {
        return obj;
    }
};

export const getExpensesArray = () => {
    const expenseTypes = getLocalStorageObject('expenseTypes');
    const expenses = [];
    try {
        if (typeof expenseTypes !== 'object') {
            return [];
        }
        for (const i in expenseTypes) {
            for (const key in expenseTypes[i]) {
                expenses.push({
                    amount: expenseTypes[i][key],
                    expenseTypeId: +key,
                });
            }
        }
    } catch (e) {
        return [];
    }

    return expenses;
};

export const getBarcodeList = (name) => {
    const basket = getLocalStorageObject(name);
    const prod = getBasketProductsBySizes(basket);
    let barcodeList = [];
    let totalQTY = 0;
    if (prod && prod.length) {
        prod.forEach((product) => {
            barcodeList.push({
                barcode: product.productReference,
                quantity: product.quantity,
            });
            totalQTY += product.quantity;
        });
    }
    return { barcodeList, totalQTY };
};

export const getCurrentBarcode = () => {
    const activeBasket = Object.entries(getLocalStorageObject(ACTIVE_BASKET));
    const activeBasketName = activeBasket.find((item) => item[1].status === 'active');
    const product = Object.values(JSON.parse(localStorage[activeBasketName[0]] || '{}'))[0];
    const size = product && product.sizes && product.sizes[0];
    return size && size.productReference;
}

export const getStocksOfBasket = (data, storeCode, basket, storeType) => {
    try {
        let existing = localStorage.getItem(basket);
        const prod = getBasketProductsBySizes(data);
        existing = existing ? JSON.parse(existing) : {};

        if (existing && Object.keys(existing).length) {
            Object.keys(existing).forEach((product) => {
                existing[product].sizes &&
                    existing[product].sizes.forEach((item) => {
                        let stock = 0;
                        const elem = prod.find(
                            (sizeElem) => sizeElem.productId === item.productId,
                        );
                        if (elem) {
                            const store =
                                (Array.isArray(elem.storeInventories) &&
                                    elem.storeInventories.find(
                                        (el) => el.storeCode === storeCode,
                                    )) ||
                                {};
                            if (store.storeStock) {
                                stock = store.storeStock;
                            }

                            if (
                                stock === 0 &&
                                storeType === 'W' &&
                                elem.webInventory
                            ) {
                                stock =
                                    elem.webInventory > 20
                                        ? 20
                                        : elem.webInventory;
                            }

                            if (
                                stock === 0 &&
                                basket !== getActiveBasketName()
                            ) {
                                item.quantity = 0;
                            } else if (
                                stock !== 0 &&
                                !item.quantity &&
                                basket !== getActiveBasketName()
                            ) {
                                item.quantity = stock;
                            } else if (
                                item.quantity > stock &&
                                basket !== getActiveBasketName()
                            ) {
                                item.quantity = stock;
                            }
                            item['stock'] = stock;
                        }
                    });
            });
        }

        localStorage.setItem(`${basket}`, JSON.stringify(existing));

        return existing;
    } catch (e) {
        console.log(e);
    }
};

export const getOnlyProductsFromBasket = (name = getActiveBasketName()) => {
    const basket = getLocalStorageObject(name);

    delete basket.offlinePay;
    delete basket.storeGifts;
    delete basket.sousTotal;
    delete basket.ticket;

    return basket;
};

export const isEmptyBasket = (name = getActiveBasketName()) => {
    const basket = getOnlyProductsFromBasket(name);
    if (Object.keys(basket).length) {
        return false;
    }
    return true;
};

export const getEmptyBasketName = () => {
    if (isEmptyBasket()) {
        return getActiveBasketName();
    }
    const basketsName = getBasketsArray();
    let name = '';
    for (let i = 0; i < basketsName.length; i++) {
        if (isEmptyBasket(basketsName[i])) {
            name = basketsName[i];
            break;
        }
    }
    return name;
};

export const addPaperReceiptProduct = (
    data = {},
    ticketNumber = 'ticketNumber',
    storeSaleLineItem = 'storeSaleLineItemTicketList',
    returnType,
    isCurrentlyReturned = false
) => {
    try {
        const {
            storeClientId,
            paymentDue,
            paymentDate = 0,
            paymentAmount,
            storeGifts,
            webDiscount,
            saleQuantity,
            paymentTermCode,
            isTicketDelete
        } = data;

        if (
            !Array.isArray(data[storeSaleLineItem]) ||
            !data[storeSaleLineItem].length
        )
            return "No storeSaleLineItemTicketList";
        const products = {};
        const basketName = getEmptyBasketName();
        let idx = 0;
        let coupons = [];
        let sousTotalId = 0;
        let sousTotalPrice = 0;
        let sousTotalDiscount = 0;

        data[storeSaleLineItem].forEach((item) => {
            if (item.giftCard || products[item.productId]) return;
            const price = parseFloat(item.listPrice) || 0;
            const quantity = Math.abs(item.orderQuantity) || 1;
            const startingPrice = item.orderQuantity > 0 ? price : -1 * price;
            const discountDetail = `${item.specialDiscountDetail}${
                item.specialDiscountPercent === 'Y'
                    ? '%'
                    : DEFAULT_CURRENCY_SYMBOL
            }`;

            const priceChange = item.priceChange
                ? parseFloat(item.listPrice)
                : undefined;
            const taxFreeTotalPrice =
                paymentTermCode === PAYMENT_CODES.c4
                    ? item.totalPrice
                    : undefined;

            const product = {
                name: item.productName,
                canonicallUrl: item.canonicalUrl || '',
                canonicalMenu: item.canonicalMenu || '',
                price,
                maxQuantity: quantity,
                photo: item.thumbnail || item.photo || '',
                pack: item.pack || [],
                oldPrice: '',
                sizes: [
                    {
                        currency: DEFAULT_CURRENCY,
                        isPromo: false,
                        oldPrice: startingPrice,
                        productId: item.productId,
                        productReference: item.barcode,
                        size: item.size,
                        quantity,
                        priceChange,
                        startingPrice,
                        taxRate: item.taxRate || 0,
                        createdDate: Date.now() + item.storeSaleLineId,
                        subTotalId: item.subTotalId,
                        taxFreeTotalPrice,
                    },
                ],
            };

            if (
                parseFloat(item.discountDetail) &&
                !item.specialDiscountSubtotal
            ) {
                product.sizes[0].listPriceAfterDiscount =
                    item.orderQuantity > 0
                        ? item.listPriceAfterDiscount
                        : -1 * item.listPriceAfterDiscount;
                product.sizes[0].discountDetail = item.discountDetail;
            }

            if (
                parseFloat(item.specialDiscountDetail) &&
                !item.specialDiscountSubtotal
            ) {
                if (item.specialDiscountPercent === 'Y') {
                    const listPrice = getNotRoundedRealNumber(
                        price -
                            price *
                                (Math.abs(item.specialDiscountDetail) / 100), 4
                    );
                    product.sizes[0].discountDetail =
                        -item.specialDiscountDetail + '%';
                    product.sizes[0].listPriceAfterDiscount =
                        item.orderQuantity > 0 ? listPrice : -1 * listPrice;
                } else {
                    product.sizes[0].discountDetail =
                        -item.specialDiscountDetail + DEFAULT_CURRENCY_SYMBOL;
                    product.sizes[0].listPriceAfterDiscount = fixNumber(
                        price - Math.abs(item.specialDiscountDetail),
                    );
                }
                product.sizes[0].specialDiscountId = {
                    storeSpecialDiscountId: item.specialDiscountId,
                    code: item['specialDiscountCode'],
                    specialDiscount: item.specialDiscount,
                };
            }

            if (item.discountDetail && item.listPriceAfterDiscount) {
                product.sizes[0].listPriceAfterDiscount = fixNumber(
                    item.totalPrice / quantity,
                );
                product.sizes[0].isPromo = true;
                product.sizes[0].promoDiscountDetail = item.discountDetail;
                product.sizes[0].promoListPriceAfterDiscount =
                    item.listPriceAfterDiscount;
            }

            if (idx === 0) {
                if (data[storeSaleLineItem][idx].subTotalId > -1) {
                    sousTotalId = data[storeSaleLineItem][idx].subTotalId;
                }
            } else {
                if (data[storeSaleLineItem][idx - 1]) {
                    sousTotalId = data[storeSaleLineItem][idx - 1].subTotalId;
                }
            }

            if (item.itemTypeCode !== PRODUCT_ITEM_TYPES.GFC) {
                if (sousTotalId !== item.subTotalId) {
                    sousTotalDiscount = item.totalPrice || 0;
                    sousTotalPrice = price * quantity;
                } else {
                    sousTotalDiscount += item.totalPrice || 0;
                    sousTotalPrice += price * quantity;
                }

                if (
                    item.specialDiscountSubtotal &&
                    parseFloat(item.specialDiscountDetail)
                ) {
                    product.sizes[0].subTotalDiscount = {
                        discountPrice: item.totalPrice,
                        discountDetail,
                        specialDiscountId: {
                            storeSpecialDiscountId: item.specialDiscountId,
                            code: item['specialDiscountCode'],
                            specialDiscount: item.specialDiscount,
                        },
                    };

                    const discount =
                        item.specialDiscountPercent === 'Y'
                            ? -item.specialDiscountDetail + '%'
                            : -(
                                  item.specialDiscountDetail *
                                  (item.subTotalId + 1)
                              ) + DEFAULT_CURRENCY_SYMBOL;
                    changeSousTotal(
                        'add',
                        {
                            discountDetail: discount,
                            discountPrice: sousTotalDiscount,
                            position: item.subTotalId,
                            value: sousTotalPrice,
                            specialDiscountId: {
                                specialDiscount: item.specialDiscount,
                                storeSpecialDiscountId: item.specialDiscountId,
                            },
                        },
                        basketName,
                    );
                } else if (item.subTotalId > -1) {
                    changeSousTotal('add', {
                        position: item.subTotalId,
                        value: sousTotalPrice,
                    });
                }
                idx++;
            }

            if (typeof returnType === 'boolean') {
                const { returnReasonStoreList = [] } = getLocalFormData();
                const returnReasonStore = returnReasonStoreList.find(
                    (i) => i.code === 'OTH',
                );
                if(isCurrentlyReturned) {
                    returnReasonStore.currentlyReturned = true;
                }
                if (returnReasonStore) {
                    delete returnReasonStore.language;
                }
                if (returnType) {
                    returnProduct(
                        product.sizes[0],
                        startingPrice,
                        returnReasonStore,
                    );
                } else {
                    product.sizes[0].returnReasonStore = returnReasonStore;
                }
            }

            products[item.productId] = product;
        });

        products.ticket = {
            [ticketNumber]: data[ticketNumber],
            paymentDue,
            paymentDate,
            paymentAmount,
            saleQuantity,
            paymentTermCode,
            isTicketDelete
        };
        if (data.storeSaleFiscal && data.storeSaleFiscal.transactionNumber) {
            products.ticket.storeSaleFiscal = {
                transactionNumber: data.storeSaleFiscal.transactionNumber,
                transactionFinish: data.storeSaleFiscal.transactionFinish,
            };
        }

        if (basketName) {
            if (Array.isArray(storeGifts)) {
                storeGifts.map((item) => (item.active = !item.cardSale));
            }
            if (webDiscount) {
                coupons = [{ ...webDiscount }];
            }
            const productGroup = arrayOfObjectGroupByKey(
                data[storeSaleLineItem],
                'productId',
            );
            for (let productId in productGroup) {
                if (productGroup[productId].length > 1) {
                    const qtyDiscount = [];
                    productGroup[productId].forEach((item) => {
                        qtyDiscount.push({
                            discount: item.specialDiscountDetail
                                ? `-${item.specialDiscountDetail}%`
                                : '0',
                            listPriceAfterDiscount: item.totalPrice,
                        });
                    });

                    const item = getMaxItemArrayOfObject(
                        productGroup[productId],
                        'specialDiscountId',
                    );
                    products[productId].sizes[0].qtyDiscount = qtyDiscount;
                    products[productId].sizes[0].quantity = getTotalSum(
                        productGroup[productId],
                        'orderQuantity',
                    );
                    products[productId].sizes[0].specialDiscountId = {
                        storeSpecialDiscountId: item.specialDiscountId,
                        code: item['specialDiscountCode'],
                        specialDiscount: item.specialDiscount,
                    };
                }
            }

            const basket = getLocalStorageObject(basketName);
            localStorage.setItem(
                basketName,
                JSON.stringify({ ...basket, ...products, storeGifts, coupons }),
            );
            changeBasketClientId(storeClientId, basketName);
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const toTimestamp = (strDate) => {
    try {
        if (strDate && strDate.length) {
            strDate = strDate.replace(' : ', ':');
            strDate = strDate.split(' ');
            const timeParts = (strDate[1] || '00:00').split(':');
            const dateParts = strDate[0].split('/');
            const date = new Date(
                dateParts[2],
                parseInt(dateParts[1], 10) - 1,
                dateParts[0],
                timeParts[0],
                timeParts[1],
            );

            return date.getTime() || null;
        }
        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const fixNumber = (number) => {
    try {
        const num = +(number * 100).toString().replace(/\.49(\d+)?/i, '.5'); //round 9.995 * 100 = 999.4999999999999 => 999.5
        return Math.round(num) / 100;
    } catch (e) {
        return number;
    }
};

export const generateInputNumber = (inputValue) => {
    try {
        inputValue = `${inputValue}`;
        const isMatch = inputValue.match(/^(\-|\+)?[0-9]+\.?([0-9]+)?/i);
        if(inputValue.trim() === '') return '';
        if(isMatch) {
            return `${isMatch[1]
                    ? isMatch[0]
                    : /^[0]+$/.test(isMatch[0])
                        ? '0' : '+' + isMatch[0]}`;
        } else if(/(\+|\-)/.test(inputValue)) {
            return inputValue[0];
        } else {
            return 0;
        }
    } catch (e) {
        return 0;
    }
}

export const getProductDiscount = (product) => {
    const { discountDetail, specialDiscountDetail, specialDiscountPercent } =
        product;

    if (discountDetail) {
        return discountDetail;
    }

    if (!specialDiscountDetail) {
        return '';
    }

    if (specialDiscountPercent) {
        return replaceComma(specialDiscountDetail) + '%';
    } else {
        return replaceComma(specialDiscountDetail) + DEFAULT_CURRENCY_SYMBOL;
    }
};

export const getProductPriceAfterDiscount = (product = {}) => {
    const {
        listPriceAfterDiscount,
        specialDiscountPercent,
        specialDiscountDetail,
        priceChange,
        quantity,
        currency,
        listPrice,
        outletPrice,
    } = product;
    const price = listPriceAfterDiscount || outletPrice || listPrice;

    if (specialDiscountDetail === undefined || !price) {
        return '';
    }

    let discountPrice = '';

    if (specialDiscountPercent === true) {
        if (priceChange) {
            discountPrice = replaceComma(
                price * quantity * (1 + specialDiscountDetail / 100),
            );
        } else {
            discountPrice = replaceComma(
                price * quantity * (1 - specialDiscountDetail / 100),
            );
        }
    } else {
        if (priceChange) {
            discountPrice = replaceComma(
                price * quantity + specialDiscountDetail,
            );
        } else {
            discountPrice = replaceComma(
                price * quantity - specialDiscountDetail,
            );
        }
    }

    return discountPrice + ' ' + getCurrency(currency);
};

export const replaceDote = (number) => {
    try {
        if (
            number.toString().split('.')[1] &&
            number.toString().split('.')[1].length > 0
        ) {
            return Number(number).toFixed(2).replace('.', ',');
        } else {
            return number;
        }
    } catch (error) {
        return number;
    }
};

export const arrayInsertNewItemsByIndex = (arr, index, ...newItems) => [
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
];

export const setOfflinePay = (
    data = {},
    basketName = getActiveBasketName(),
) => {
    const basket = getLocalStorageObject(basketName);
    localStorage.setItem(
        basketName,
        JSON.stringify({
            ...basket,
            offlinePay: data,
        }),
    );
};
export const getOfflinePay = (basketName = getActiveBasketName()) => {
    const basket = getLocalStorageObject(basketName);
    return basket.offlinePay || {};
};

export const sortBasketByDate = (a, b) => {
    const createdDateA = a.createdDate;
    const createdDateB = b.createdDate;

    let comparison = 0;
    if (createdDateA > createdDateB) {
        comparison = 1;
    } else if (createdDateA < createdDateB) {
        comparison = -1;
    }
    return comparison;
};

export const getTaxes = (basket = {}, taxRate) => {
    try {
        const { price, totalPrice } = getBasketData(basket),
            { ticket = {}, storeGifts = [], coupons = [] } = basket,
            { ticketNumber = '', paymentDue } = ticket,
            sousTotal = basket['sousTotal'];
        let tax = 0;

        if (!price && !(isTaxesCountry() && storeGifts.length)) return 0;

        if (STORE_COUNTRY === STORE_COUNTRIES.it && +paymentDue) {
            return getTax(taxRate, +paymentDue);
        }

        if (sousTotal && sousTotal.length) {
            if (totalPrice === price) {
                return getBasketTax(basket);
            }
            if (!ticketNumber) {
                return (
                    getBasketTax(basket) -
                    ((totalPrice - price) * taxRate) / (1 + taxRate)
                );
            }
            if (price < 0) {
                return getBasketTax(basket, true);
            }
            return 0;
        }

        tax = getBasketTax(basket, price < 0);
        if (storeGifts && storeGifts.length && !ticketNumber) {
            storeGifts.forEach((item) => {
                if (isTaxesCountry() && item.active && item.credit) return;

                if (item.amount) {
                    tax =
                        tax +
                        getTax(
                            taxRate,
                            item.active ? -item.amount : item.amount,
                        );
                } else if (item.percent) {
                    tax = getTax(taxRate, price);
                }
            });
        }
        if (coupons.length) {
            tax = getTax(taxRate, price);
        }
        return tax;
    } catch (e) {
        return 0;
    }
};

export const getBasketTax = (basket = {}, negative = false) => {
    try {
        const isTicket = basket.ticket && basket.ticket.ticketNumber && !basket.ticket.paymentDue;
        return getBasketProductsBySizes(basket).reduce((acc, item) => {
            const { taxRate, returnReasonStore: { currentlyReturned } = { currentlyReturned: false }, startingPrice } = item || {};
            const price = getItemProductTotalPrice(item, true);
            const tax = !currentlyReturned && isTicket ? 0 : getTax(taxRate, price);

            if (negative && !isTaxesCountry()) {
                if (startingPrice < 0) {
                    return (acc += tax);
                }
                // return acc;
            }
            return (acc += tax);
        }, 0);
    } catch (error) {
        return 0;
    }
};

export const getTax = (taxRate, price) =>
    taxRate && price ? (taxRate * price) / (1 + taxRate) : 0;

export const getStoreLineItems = (basket, isQuotes = false) => {
    if (!basket) return [];
    try {
        const prod = getBasketProductsBySizes(basket).sort((a, b) =>
            sortBasketByDate(a, b),
        );
        const storeQuoteLineItems = [];

        if (prod && prod.length) {
            const isTicket = basket.ticket && basket.ticket.ticketNumber && !basket.ticket.paymentDue;
            const isReturn = isTicket && prod.some((el) => el.startingPrice < 0);

            prod.forEach((item, i) => {
                const isCurrentlyReturned =
                    item.returnReasonStore &&
                    item.returnReasonStore.currentlyReturned;

                if (isReturn && item.startingPrice >= 0 || isTicket && !isCurrentlyReturned) return;

                const lineItems = {
                    productId: item.productId,
                    quantity:
                        item.startingPrice < 0
                            ? -1 * item.quantity
                            : item.quantity,
                    currency: DEFAULT_CURRENCY,
                    itemType: item.itemType || PRODUCT_ITEM_TYPES.EAN,
                    orderLineId: i + 1,
                    subTotalId: item.subTotalId,
                };

                if (item.priceChange) {
                    lineItems.priceChange = item.priceChange;
                }

                if (item.qtyDiscount) {
                    const discounts = arrayOfObjectGroupByKey(
                        item.qtyDiscount,
                        'discount',
                    );
                    for (let key in discounts) {
                        const qty = discounts[key].length;

                        lineItems.quantity =
                            item.startingPrice < 0 ? -1 * qty : qty;

                        if (parseFloat(key)) {
                            lineItems.specialDiscountPercent = 'Y';
                            lineItems.specialDiscountDetail = Math.abs(
                                parseFloat(key),
                            );
                            lineItems.specialDiscountId =
                                item.specialDiscountId.storeSpecialDiscountId;
                        }
                    }
                } else {
                    if (isQuotes) {
                        lineItems.weight = 0;
                        lineItems.length = 0;
                    }

                    if (item.returnReasonStore && item.returnReasonStore.code) {
                        lineItems.returnReasonStoreCode =
                            item.returnReasonStore.code;
                    }
                    basket.sousTotal &&
                        basket.sousTotal.forEach((elem) => {
                            if (
                                elem.position === i &&
                                elem.specialDiscountId &&
                                Object.keys(elem.specialDiscountId).length
                            ) {
                                lineItems.specialDiscountId =
                                    elem.specialDiscountId.storeSpecialDiscountId;
                                lineItems.specialDiscountPercent =
                                    elem.discountDetail.includes('%')
                                        ? 'Y'
                                        : 'N';
                                lineItems.specialDiscountDetail = Math.abs(
                                    parseFloat(elem.discountDetail),
                                );
                                lineItems.specialDiscountSubtotal = true;
                            }
                        });
                    if (
                        item.specialDiscountId &&
                        (parseFloat(item.discountDetail) ||
                            item.specialDiscountId.specialDiscountDetail) &&
                        item.specialDiscountId.storeSpecialDiscountId
                    ) {
                        lineItems.specialDiscountId =
                            item.specialDiscountId.storeSpecialDiscountId;
                        lineItems.specialDiscountPercent =
                            item.discountDetail &&
                            item.discountDetail.includes('%')
                                ? 'Y'
                                : 'N';
                        lineItems.specialDiscountDetail =
                            Math.abs(parseFloat(item.discountDetail)) ||
                            item.specialDiscountId.specialDiscountDetail;
                    }
                    if (
                        item.subTotalDiscount &&
                        Object.keys(item.subTotalDiscount).length
                    ) {
                        lineItems.specialDiscountId =
                            item.subTotalDiscount.specialDiscountId.storeSpecialDiscountId;
                        lineItems.specialDiscountPercent =
                            item.subTotalDiscount.discountDetail &&
                            item.subTotalDiscount.discountDetail.includes('%')
                                ? 'Y'
                                : 'N';
                        lineItems.specialDiscountDetail =
                            Math.abs(
                                parseFloat(
                                    item.subTotalDiscount.discountDetail,
                                ),
                            ) ||
                            item.subTotalDiscount.specialDiscountId
                                .specialDiscountDetail;
                        lineItems.specialDiscountSubtotal = true;
                        lineItems.subTotalId = item.subTotalDiscount.subTotalId;
                    }
                }

                storeQuoteLineItems.push(lineItems);
            });
        }
        return storeQuoteLineItems;
    } catch (e) {
        return [];
    }
};

export const getWebInventory = (data, barcode) => {
    if (data) {
        const prod = data.sizes;
        const current = prod.filter(
            (item) => item.productReference === barcode,
        )[0];
        return current.webInventory;
    } else {
        return 0;
    }
};

export const getDateFormatReq = (date, currentFormat) => {
    let newDate = '';
    if (date) {
        if (currentFormat === 'dd/mm/yyyy') {
            const dd = date.split('/')[0];
            const mm = date.split('/')[1];
            const yyyy = date.split('/')[2];
            newDate = `${yyyy}-${mm}-${dd}`;
        }
    } else {
        newDate = '';
    }
    return newDate;
};

export const xmlStringParser = (xmlString, fields = {}) => {
    try {
        const res = {};
        const { tags = [], attributes = {} } = fields;
        if (!xmlString || !(tags.length || Object.keys(attributes).length))
            return res;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
        tags.forEach((item) => {
            const el = xmlDoc.getElementsByTagName(item);
            if (el && el[0] && el[0].childNodes && el[0].childNodes[0]) {
                res[item] = el[0].childNodes[0].nodeValue;
            }
        });

        if (attributes) {
            for (let key in attributes) {
                if (Array.isArray(attributes[key])) {
                    const el = xmlDoc.getElementsByTagName(key);
                    if (el && el[0] && el[0].attributes) {
                        attributes[key].forEach((item) => {
                            if (el[0].attributes.getNamedItem(item)) {
                                res[item] =
                                    el[0].attributes.getNamedItem(item).value;
                            }
                        });
                    }
                }
            }
        }
        return res;
    } catch (e) {
        return {};
    }
};

// export const decodeBase64 = (str) => {
//     if(!str || typeof str !== 'string') return "";
//     return atob(str)
// };

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
};

export const getItemArrayOfObject = (arr = [], key, val) => {
    try {
        return arr.find((item) => item[key] === val) || {};
    } catch (error) {
        return {};
    }
};

export const getLocalFormData = () => {
    try {
        const data = localStorage.getItem(STORAGE_GET_FORM);
        return JSON.parse(data) || {};
    } catch (error) {
        return {};
    }
};

export const getLocalFormItem = (field, label, value) => {
    try {
        const data = getLocalFormData()[field];
        return data.find((item) => item[label] === value) || {};
    } catch (error) {
        return {};
    }
};

export const isLastDayMonth = (date = new Date()) => {
    const y = date.getFullYear();
    const m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);
    return (
        getDateTimestamp(date.getTime()) === getDateTimestamp(lastDay.getTime())
    );
};

export const islastWorkDayOfMonth = (date = new Date()) => {
    const y = date.getFullYear();
    const m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);

    if (lastDay.getDay() === 0) {
        lastDay.setDate(lastDay.getDate() - 1);
    }

    return (
        getDateTimestamp(date.getTime()) === getDateTimestamp(lastDay.getTime())
    );
};

export const textToBase64Barcode = (text, format) => {
    try {
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, text, { format });
        return canvas.toDataURL('image/png');
    } catch (e) {
        console.log(e);
    }
};

export const arrayGroupByCount = (arr, n) =>
    arr.reduce(
        (acc, current, idx) =>
            (idx % n
                ? acc[acc.length - 1].push(current)
                : acc.push([current])) && acc,
        [],
    );

export const arrayOfObjectGroupByKey = (arr, key) => {
    return arr.reduce((acc, curr) => {
        acc[curr[key]] = [...(acc[curr[key]] || []), curr];
        return acc;
    }, {});
};

export const getMaxItemArrayOfObject = (arr, field) => {
    try {
        return arr.reduce((acc, cur) =>
            (acc[field] || 0) > (cur[field] || 0) ? acc : cur,
        );
    } catch (e) {
        return {};
    }
};

export const sortArrayOfObject = (arr, field, direction = 'ascending') => {
    try {
        const data = [...arr].sort((a, b) => {
            if (direction === 'descending') {
                if (b[field] < a[field]) {
                    return -1;
                }
                if (b[field] > a[field]) {
                    return 1;
                }
            } else {
                if (a[field] < b[field]) {
                    return -1;
                }
                if (a[field] > b[field]) {
                    return 1;
                }
            }
            return 0;
        });
        return data;
    } catch (e) {
        return arr;
    }
};

export const readFileAsync = (file, readAs = 'readAsText') => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader[readAs](file);
    });
};

export const getDateFromString = (string, formatDate, timeFormat = false) => {
    try {
        let standartSring = '';
        const date = string.split('/');
        if (!date[1] || !date[2] || !date[0]) return new Date();
        if (formatDate === 'DD/MM/YYYY') {
            standartSring = date[1] + '/' + date[0] + '/' + date[2];
            standartSring += timeFormat ? ' 01:01' : '';
        }

        if (standartSring) {
            return new Date(standartSring);
        } else {
            return new Date();
        }
    } catch (e) {
        return new Date();
    }
};

export const isProductHaveSubsTotla = (selected, basket) => {
    let boolValue = false;
    const ids = selected.split('/'),
        position = getBasketProductsBySizes(basket)
            .sort((a, b) => sortBasketByDate(a, b))
            .findIndex(
                (item) =>
                    item.productId && item.productId.toString() === ids[1],
            ),
        subsTotal = basket.sousTotal || [];
    subsTotal.forEach((item) => {
        if (item.position === position) {
            boolValue = true;
        }
    });
    return boolValue;
};

// export const changeTransactionNumber  = (transactionNumber  = 0, name = getActiveBasketName()) => {
//     const activeBasket = getLocalStorageObject(ACTIVE_BASKET);
//     if(name && transactionNumber ){
//         const baskets = {...activeBasket, [name]: {...activeBasket[name],  transactionNumber }};
//         try {
//             localStorage.setItem(ACTIVE_BASKET, JSON.stringify(baskets));
//             return true
//         }catch (e) {
//             return false
//         }
//     }
// };

export const setDays = (day, d = Date.now()) => {
    try {
        const date = new Date(d);
        date.setDate(date.getDate() + day);

        if (!isValidDate(date)) throw new Error('error');

        return date.getTime();
    } catch (error) {
        return Date.now();
    }
};

export const multipleDuplicationOfArrayElements = (arr = [], count) => {
    if (typeof count !== 'number' || !Array.isArray(arr) || !arr.length)
        return [];
    return arr.reduce((acc, cur) => acc.concat(Array(+count).fill(cur)), []);
};

export const getBasketCoupon = () => {
    try {
        const { coupons = [] } = getLocalStorageObject(getActiveBasketName());
        return coupons;
    } catch (e) {
        return [];
    }
};

export const removeBasketCoupon = (discountCode) => {
    const basket = getLocalStorageObject(getActiveBasketName());
    try {
        if (discountCode && basket.coupons && basket.coupons.length > 1) {
            basket.coupons = basket.coupons.filter(
                (item) => item['discountCode'] !== discountCode,
            );
        } else {
            delete basket.coupons;
        }
        localStorage.setItem(getActiveBasketName(), JSON.stringify(basket));
        return true;
    } catch (e) {
        return false;
    }
};

export const str2bytes = (str) => {
    const bytes = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
    }
    return bytes;
};

export const customRoundByCountry = (number) => {
    try {
        if (typeof number !== 'number' || !isFloat(number)) {
            return number;
        }

        if (
            !(
                DEFAULT_CURRENCY === 'CHF' &&
                [STORE_COUNTRIES.ch].includes(STORE_COUNTRY)
            )
        ) {
            return number;
        }

        const num = +number.toFixed(2);
        const regexp = /^\d+(\.\d{2})?$/;

        if (num && regexp.test(Math.abs(num))) {
            const str = num.toString();
            if (str.split('.')[1] > 96) {
                return parseInt(num) + 1;
            }
            const res = +str.replace(/\.(\d{1})(\d{1})/, (match, n1, n2) => {
                if (n2 === '5' || n2 === '6') {
                    return `.${n1}5`;
                }

                if (n2 < 5) {
                    if (n1 === '0') return `.${n1}${n2}`;
                    return `.${n1}0`;
                }

                return `.${+n1 + 1}0`;
            });

            return res;
        }
        return num;
    } catch (e) {
        return number;
    }
};

export const getBasketProductsBarcodeList = (name = getActiveBasketName()) => {
    try {
        const basket = getLocalStorageObject(name);
        const products = getBasketProductsBySizes(basket);
        return products.map((item) => item.productReference);
    } catch (e) {
        return [];
    }
};

export const updateBasketAfterChangeCurrency = async (
    details,
    name = getActiveBasketName(),
) => {
    try {
        const barcodeList = getBasketProductsBarcodeList(name);
        if (!barcodeList || !barcodeList.length) return false;

        const data = await Promise.all(
            barcodeList.map((item) => getProductDataByBarcode(item, details)),
        );
        if (!data || !data.length) return false;

        const basket = getLocalStorageObject(getActiveBasketName());
        data.forEach((item) => {
            if (!item) return;
            const { sizes = [] } = basket[item.productId] || {};
            const product = item.sizes[0] || {};

            sizes.forEach((elem) => {
                if (+elem.productId === +product.productId) {
                    const price =
                        product.listPriceAfterDiscount || product.startingPrice;

                    elem.startingPrice = product.startingPrice;
                    elem.oldPrice = product.oldPrice || product.startingPrice;

                    if (elem.isPromo) {
                        elem.isPromo = false;
                        delete elem.promoDiscountDetail;
                        delete elem.listPriceAfterDiscount;
                        delete elem.promoListPriceAfterDiscount;
                    }

                    if (
                        product.discountDetail &&
                        product.listPriceAfterDiscount
                    ) {
                        elem.isPromo = true;
                        elem.promoDiscountDetail = product.discountDetail;
                        elem.listPriceAfterDiscount = price;
                        elem.promoListPriceAfterDiscount = price;
                    }

                    if (
                        elem.discountDetail &&
                        parseFloat(elem.discountDetail)
                    ) {
                        if (elem.discountDetail.includes('%')) {
                            if (!product.listPriceAfterDiscount) {
                                elem.listPriceAfterDiscount = fixNumber(
                                    price -
                                        price *
                                            (Math.abs(
                                                parseFloat(elem.discountDetail),
                                            ) /
                                                100),
                                );
                            }
                        } else {
                            elem.discountDetail =
                                parseFloat(elem.discountDetail) +
                                DEFAULT_CURRENCY_SYMBOL;
                        }
                    } else {
                        delete elem.discountDetail;
                    }

                    if (
                        elem.subTotalDiscount &&
                        elem.subTotalDiscount.discountDetail
                    ) {
                        if (
                            elem.subTotalDiscount.discountDetail.includes('%')
                        ) {
                            elem.subTotalDiscount.discountPrice = fixNumber(
                                price -
                                    price *
                                        (Math.abs(
                                            parseFloat(
                                                elem.subTotalDiscount
                                                    .discountDetail,
                                            ),
                                        ) /
                                            100),
                            );
                        } else {
                            elem.subTotalDiscount.discountDetail =
                                parseFloat(
                                    elem.subTotalDiscount.discountDetail,
                                ) + DEFAULT_CURRENCY_SYMBOL;
                        }
                    }
                }
            });
        });

        if (basket.sousTotal && basket.sousTotal.length) {
            basket.sousTotal.forEach((elem) => {
                if (elem.discountDetail && !elem.discountDetail.includes('%')) {
                    elem.discountDetail =
                        parseFloat(elem.discountDetail) +
                        DEFAULT_CURRENCY_SYMBOL;
                }
            });
        }

        localStorage.setItem(name, JSON.stringify(basket));
        changeSousTotal('update', 0);
        return Boolean(data[0]);
    } catch (e) {
        console.log(e);
        return true;
    }
};

export const getStockBarcodesList = (stocks = [], param) => {
    try {
        const lists = [];
        if (Array.isArray(stocks) && stocks.length) {
            stocks.forEach((item) => {
                item.barcodeList.forEach((product) => {
                    if (param === true) {
                        if (!product.stock || product.stock > 0) {
                            return;
                        }
                    }
                    if (param === false) {
                        if (!product.stock || product.stock < 0) {
                            return;
                        }
                    }
                    lists.push({ ...item, ...product });
                });
            });
        }

        return lists;
    } catch (error) {
        return [];
    }
};

export const textContainsString = (text, str) => {
    try {
        if (typeof text !== 'string' || typeof str !== 'string') return false;

        return text.toUpperCase().includes(str.toUpperCase());
    } catch (error) {
        return false;
    }
};

export const arrayOfObjectsFilterCaseInsensitive = (
    data = [],
    search = '',
    field = '',
) => {
    try {
        return data.filter((el) => textContainsString(el[field], search));
    } catch (e) {
        return data;
    }
};

export const isValidSousTotalDiscountValue = (basket, value, idx) => {
    try {
        const maxPrice = getBasketProductsBySizes(basket)
            .filter((el) => el.subTotalId === idx)
            .reduce((accPrice, cur) => {
                const curPrice =
                    (cur.promoListPriceAfterDiscount || cur.startingPrice) *
                    cur.quantity;
                if (accPrice > curPrice) {
                    return accPrice;
                }
                return curPrice;
            }, 0);

        return value <= maxPrice;
    } catch (error) {
        return false;
    }
};

export const countDuplicateElementsInArray = (array, includeEmpty = false) => {
    try {
        const result = {};

        array.forEach((item) => {
            if (!includeEmpty && !item) {
                return;
            }

            const key = item.padStart(13, 0);

            if (result[key]) {
                result[key] += 1;
                return;
            }
            result[key] = 1;
        });

        return result;
    } catch (error) {
        return {};
    }
};

export const scrollToElementByClassName = (element1, element2) => {
    const elem1 = document.getElementsByClassName(element1);
    const elem2 = document.getElementsByClassName(element2);
    if (elem1 && elem1[0] && elem2 && elem2[0]) {
        elem1[0].scrollTop = elem2[0].offsetTop || 0;
    }
};

export const isTaxesCountry = () =>
    [STORE_COUNTRIES.fr, STORE_COUNTRIES.be, STORE_COUNTRIES.mc].includes(
        STORE_COUNTRY,
    );

export const redirectClientPage = (clientId, menu) => {
    if (!clientId) return;
    history.push({
        pathname: getMenuItemCanonicalUrl('page', 'clients', menu),
        state: { clientId },
    });
};

export const getScannerMotorolaBarcode = (code, scanner) => {
    try {
        if (scanner !== 'motorola') return { barcode: code };

        const zone = code.substring(0, 4).replace(/^0+/, '');
        const barcode = code.substring(4, 17);
        const stockChecked = +code.substring(17);

        return { zone, barcode, stockChecked };
    } catch (error) {
        return { barcode: code };
    }
};

export const removeDuplicatesElementArrayOfObjects = (arr, field) => {
    try {
        return [...new Set(arr.map((el) => el[field]))].map((elem) => {
            return arr.find((item) => item[field] === elem);
        });
    } catch (error) {
        return [];
    }
};

export const getItClientMandatoryFields = (client) => {
    const { profile = {}, billingAddresses = [] } = client;
    const address = billingAddresses[0] || {};
    const { name, address1, city, country } = address;
    const { fiscalCode, pecEmail, sdiCode, vatNumber } = profile;
    const message = [];

    if (!name) {
        message.push(i18next.t(`Terminal.dashboards.name`));
    }
    if (!address1) {
        message.push(i18next.t(`Terminal.dashboards.address1`));
    }
    if (!city) {
        message.push(i18next.t(`Terminal.dashboards.city`));
    }
    if (!country) {
        message.push(i18next.t(`Terminal.dashboards.country`));
    }
    if (!(fiscalCode || vatNumber)) {
        message.push(
            i18next.t(
                `Terminal.dashboards.${
                    fiscalCode ? 'vatNumber' : 'fiscalCode'
                }`,
            ),
        );
    }
    if (!(pecEmail || sdiCode)) {
        message.push(
            i18next.t(
                `Terminal.dashboards.${pecEmail ? 'sdiCode' : 'pecEmail'}`,
            ),
        );
    }

    return message.join(', ');
};

export const checkIsActiveBasketNotEmpty = () => {
    const activeBasketName = getActiveBasketName();
    return !!Object.values(JSON.parse(localStorage.getItem(activeBasketName) || "{}")).length;
}

export const getCountryOptions = () => [
    {
        key: 'FR',
        text: i18next.t('Countrylist.FR'),
        value: i18next.t('Countrylist.FR'),

    },
    {
        key: 'BE',
        text: i18next.t('Countrylist.BE'),
        value: i18next.t('Countrylist.BE'),

    },
    {
        key: 'CH',
        text: i18next.t('Countrylist.CH'),
        value: i18next.t('Countrylist.CH'),

    },
    {
        key: 'LU',
        text: i18next.t('Countrylist.LU'),
        value: i18next.t('Countrylist.LU'),

    },
    {
        key: 'MC',
        text: i18next.t('Countrylist.MC'),
        value: i18next.t('Countrylist.MC'),

    },
    {
        key: 'IT',
        text: i18next.t('Countrylist.IT'),
        value: i18next.t('Countrylist.IT'),

    },
    {
        key: 'DE',
        text: i18next.t('Countrylist.DE'),
        value: i18next.t('Countrylist.DE'),

    },
    {
        key: 'AT',
        text: i18next.t('Countrylist.AT'),
        value: i18next.t('Countrylist.AT'),

    },
    {
        key: 'GR',
        text: i18next.t('Countrylist.GR'),
        value: i18next.t('Countrylist.GR'),

    },
    {
        key: 'SP',
        text: i18next.t('Countrylist.SP'),
        value: i18next.t('Countrylist.SP'),

    },
    {
        key: 'GB',
        text: i18next.t('Countrylist.GB'),
        value: i18next.t('Countrylist.GB'),

    },
    {
        key: 'UN',
        text: i18next.t('Countrylist.UN'),
        value: i18next.t('Countrylist.UN'),

    },
];

export const getLogisticStatusLabel = (code) => {
    const { logisticStatusLabels = [] } = getLocalFormData();
    const { label } = logisticStatusLabels.find((status) => status.code === code) || {};
    return label;
}

export const getLocalFormOptions = (itemName, value, text, key) => {
    const formData = getLocalFormData();
    const items = formData[itemName];
    return items.reduce((acc, item) => {
        const option = {
            key: item[key],
            text: item[text],
            value: item[value],
        }
        acc.push(option);
        return acc;
    }, [])
}

export const getStockProductImportOptions = (isService = false) => {
    const data = isService
        ? { id: 1, value: 'SERVICE', label: i18next.t('Terminal.dashboards.serviceName').toUpperCase()}
        : { id: 1, value: 'PRODUCT', label: i18next.t('Terminal.dashboards.2').toUpperCase()}
    return [
        data,
        { id: 2, value: 'BARCODE', label: i18next.t('Terminal.dashboards.barcode').toUpperCase()},
    ]
}

export const filterInputNumberValue = (value) => {
    let result = `${value}`.replace(/[^0-9.]+/g, '');
    if (result.includes('.')) {
        if (result.match(/[.]+/g).length > 1) {
            result = result.replace(/[.]+$/, '');
        }
        result = result.replace(/[.]+/g, '.');
        return result.charAt(result.indexOf('.') + 1)
            && result[result.length - 1] !== '0' ? +result : result;
    }
    return +result.replace(/^0+/, '');
}


export const getScheduleSlicedDay = (dayIndex, shedulData, addedshedulData) => {
    const cloneShedulData = {...shedulData};
    const cloneAddedshedulData = {...addedshedulData};
    let cloneRecurrenceRule = cloneShedulData.RecurrenceRule || cloneAddedshedulData.RecurrenceRule;

    const currentDeletedDay = ['SU','MO','TU','WE','TH','FR','SA'][dayIndex];
    const firstIndex = cloneRecurrenceRule.indexOf('BYDAY=') + 'BYDAY='.length;
    const lastIndex = cloneRecurrenceRule.indexOf('INTERVAL=1') - 1;
    let workingText = cloneRecurrenceRule.slice(firstIndex, lastIndex);
    const cloneWorkingText = workingText;
    const daysSlice = cloneRecurrenceRule.slice(firstIndex, lastIndex).split(',');

    if(daysSlice.length === 1) return [];

    let currentDeletedDayName = currentDeletedDay;
    if (cloneRecurrenceRule.includes('=' + currentDeletedDay)) {
        currentDeletedDayName = currentDeletedDay + ',';
    } else {
        currentDeletedDayName = ',' + currentDeletedDay
    };

    workingText = workingText.replace(currentDeletedDayName, '');
    cloneRecurrenceRule = cloneRecurrenceRule.replace(cloneWorkingText, workingText);
    cloneShedulData.RecurrenceRule = cloneRecurrenceRule;
    cloneShedulData.recurrenceRule = cloneRecurrenceRule;

    return [cloneShedulData];
};

export const encodeCalendarPayload = (data) => {
    const length = data.length;
    return data.reduce((acc, item, index) => {
        const isEmpty = item.empty;

        return acc + `${item.weekDay}:${isEmpty ? '' : item.start}${isEmpty ? '' : '-'}${isEmpty ? '' : item.end}${length - 1 === index ? '' : ';'}`;
    }, '')
}

export const getOptionSelection = (option) => {
    for(let key in option) {
        if (key.includes('selection')) {
            return option[key];
        }
    }
}

export const getCookieByKey = (selector) => {
    const cookies = document.cookie.split(";").reduce((acc, item) => {
        const [key, value] = item.split("=");
        acc[`${key}`.trim()] = `${value}`.trim();

        return acc;
    }, {});

    return cookies[selector];
}

export const getDomainFromUrl = (url) => {
    const [ domain ] = process.env.NODE_ENV === "development"
        ? ["localhost"]
        : (url.match(/\.[A-z]*\.[A-z]*$/g) || [""]);

    return domain;
}
