import React, {Component} from 'react';
import i18next from 'i18next';
import {Input} from 'semantic-ui-react'
import CustomBtn from '../../../common/customBtn';
import PopUp from '../../../popUp/popUp';
import {addCouponToBasket, getActiveBasketName, isExistCouponInBasket} from '../../../../helpers/helpers';
import {HomeActionCreators} from "../../redux/actions";
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import {getWebCouponLoading, getWebCouponStatus,getWebCoupon} from "../../redux/selectors";


interface Props {
    disabled?: boolean,
    details: any,
    amount: number,
    coupon: any,
    couponStatus: string,
    couponLoading: boolean,
}
interface State {
    couponValue: string,
    show: boolean,
    error:string,
}
class CouponWeb extends Component<Props & typeof HomeActionCreators, State> {
    state = {
        couponValue:"",
        show:false,
        error:"",
    }


    componentDidUpdate(prevProps,prevState): void {
        if(prevProps.couponStatus !== this.props.couponStatus){
            if(this.props.couponStatus ==="failed"){
                this.setState({error:i18next.t('Terminal.coupon.3')})
            }else if(this.props.couponStatus ==="success"){
                if(this.props.coupon.valid === false){
                    this.setState({error:i18next.t('Terminal.coupon.3')})
                }else {
                    if(isExistCouponInBasket(this.props.coupon.discountCode)) {
                        this.setState({error:i18next.t('Terminal.coupon.4')})
                    }else {
                        addCouponToBasket(this.props.coupon)
                        this.props.getBasketStart(getActiveBasketName())
                        this.handleClose()
                    }
                }
            }
        }
    }

    handleClose = () => {this.setState({show:false, couponValue:""})}

    handleClick =  () => {
        const {storeDetails: {brandId}} = this.props.details;
        const {couponValue}= this.state;
        const {amount}=this.props;
        this.props.getCouponWebStart(couponValue, amount, brandId)
    }
    changeValue = ({name,value}) => {
        value =value.replace(" ","")
        this.setState((prevState: State) => ({...prevState, [name]: value}));
    }
    render(){
        const {disabled,couponLoading}= this.props;
        const {show,couponValue,error} = this.state;
        return (
            <>
                <CustomBtn
                    classN={`btn_1 orange_btn giftBtnBox--btn`}
                    disabled={disabled}
                    handleClick={() => this.setState({show:true})}
                >
                    {i18next.t('Terminal.coupon.1')}
                </CustomBtn>
                {
                    show ?
                        <PopUp classN="w-50" handleClose={this.handleClose}>
                            <div className="">
                                <div className='dflex justify-content-between align-items-center'>
                                    <div>{i18next.t('Terminal.coupon.2')}</div>
                                    <Input name="couponValue" value={couponValue} onChange={({target}) => this.changeValue(target)} />
                                </div>
                                <CustomBtn
                                    disabled={false}
                                    classN="btn_1 w-50 blue_btn"
                                    handleClick={this.handleClick}
                                    loading={couponLoading}
                                >
                                    {i18next.t('buttons.38')}
                                </CustomBtn>
                                {
                                    error && <p className="errorMessage">{error}</p>
                                }
                            </div>
                        </PopUp>
                        : ""
                }

            </>
        );
    }

}

const mapStateToProps = (state: any) => ({
    coupon: getWebCoupon(state),
    couponStatus: getWebCouponStatus(state),
    couponLoading: getWebCouponLoading(state),
});

const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators(HomeActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponWeb);

