import React from 'react';
import {Switch, Route} from "react-router";
import Login from "./modules/login/container/login";
import MainLayout from "./layouts/main";
import {getLangPrefix} from "./helpers/helpers";

const Routes = () => (
    <div className="view-routes">
        <Switch>
            <Route  path={`${getLangPrefix()}/login`} component={Login}/>
            <Route path="/" component={MainLayout}/>
        </Switch>
    </div>
);

export default Routes;
