import {createActions} from 'reduxsauce';

export const {Types: TransfersTypes, Creators: TransfersActionCreators} = createActions ({
    transfersStart: null,
    transfersSuccess: ['data'],
    transfersFailure: ['error'],
    createTransferStart: ["data"],
    createTransferSuccess: ['data'],
    createTransferFailure: ['error'],
    updateTransferStart: ["data"],
    updateTransferSuccess: ['data'],
    updateTransferFailure: ['error'],
    storesStart: [null],
    storesFormStart: [null],
    barcodeProductStart: ['barcode'],
    resetUpdateStatus: [null],
    resetUpdateResponse: [null],
    createStoreTransferShipmentStart: ['data'],
    createStoreTransferShipmentSuccess: ['data'],
    createStoreTransferShipmentFailure: ['data'],
    createStoreTransferBookStart: ['data'],
    createStoreTransferBookSuccess: ['data'],
    createStoreTransferBookFailure: ['data'],
    getTransferShipmentsStart: ['transferId'],
    getTransferShipmentsSuccess: ['data'],
    getTransferShipmentsFailure: ['data'],
    emptyTransferShipmentErrorStart: null,
});
