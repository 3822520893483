import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import BarcodeReader from 'react-barcode-reader';
import { delay, fixNumber, getTotalSum } from '../../../../helpers/helpers';
import { Icon, Input, Table } from 'semantic-ui-react';
import InfoPopUp from '../../../common/infoPopUp';
import CustomBtn from '../../../common/customBtn';
import { TRANSFER_STATUS_CODE } from '../../../../utils/constants/variables';
import { getCreatedStatus, getCreateLoading } from '../../redux/selectors';
import { TransfersActionCreators } from '../../redux/actions';
import { bindActionCreators } from 'redux';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import { getAccountResource } from '../../../login/redux/selectors';
import { getProductDataByBarcode } from '../../../../utils/constants/service';
import SetTransferBtn from './setTransferBtn';
import SearchBox from '../../../common/searchBox';
import FermerBtn from '../../../common/fermerBtn';
import Pagination from '../../../common/pagination';
import PrintEanBarcodes from '../../../pdfGenerator/printEanBarcodes';

interface Props {
    data: any;
    createTransferStart: (data) => void;
    handleClosePopUp: () => void;
    createdStatus: string;
}

interface State {
    errorMessage: string;
    barcode: string;
    disabledBtns: boolean;
    disabled: boolean;
    body: {
        storeTransferId: null | number;
        storeCodeSend: string;
        storeCodeRecv: string;
        associateCodeRecv: string;
        commentSend: string;
        commentRecv: string;
        storeTransferStatusCode: string;
        storeTransferReasonCode: string;
        productQty: number;
        storeTransferProducts: any;
        totalBox: number | string;
    };
    showSaveMessage: boolean;
    saveMessage: string;
    showConfirmpopUp: boolean;
    transferStatus: string;
    loadingBarcodes: boolean;
    isQtySend: boolean;
    isQtyRecv: boolean;
    showResetPopUp: boolean;
    searchLoading: boolean;
    isSubmit: boolean;
    isTypeW: boolean;
    showPopUp: boolean;
    resetPagination: boolean;
    currentPage: number;
    transferProducts: any;
}
class TransferDetails extends Component<
    Props & typeof TransfersActionCreators
> {
    state = {
        errorMessage: '',
        barcode: '',
        disabled: false,
        disabledBtns: false,
        body: {
            storeTransferId: null,
            storeCodeSend: '',
            storeCodeRecv: '',
            associateCodeRecv: '',
            commentSend: '',
            commentRecv: '',
            storeTransferStatusCode: '',
            storeTransferReasonCode: '',
            productQty: 0,
            storeTransferProducts: [],
            totalBox: 0,
        },
        showSaveMessage: false,
        saveMessage: '',
        transferStatus: '',
        showConfirmpopUp: false,
        isQtySend: false,
        isQtyRecv: false,
        showResetPopUp: false,
        searchLoading: false,
        isSubmit: false,
        isTypeW: false,
        showPopUp: false,
        resetPagination: false,
        currentPage: 1,
        transferProducts: [],
    };

    barcodeInputRef: React.RefObject<any> = React.createRef();
    barcodesList: object[] = [];
    perPage = 20;

    componentDidMount(): void {
        const { data, account: { storeCode = '' } = {} } = this.props;

        if (data && data.storeTransferId) {
            const {
                storeTransferId,
                storeCodeSend = '',
                storeCodeRecv = '',
                associateCodeRecv = '',
                commentSend = '',
                commentRecv = '',
                storeTransferStatusCode = '',
                storeTransferReasonCode = '',
                products = [],
                totalBox,
            } = data;

            const storeTransferProducts = products.map((item) => {
                if (
                    [
                        TRANSFER_STATUS_CODE.PEN,
                        TRANSFER_STATUS_CODE.REQ,
                    ].includes(storeTransferStatusCode)
                ) {
                    return { ...item, qtyRecv: 0, qtySend: 0 };
                }

                return item;
            });

            const isQtySend =
                storeCode === storeCodeSend &&
                storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK;
            const isQtyRecv =
                storeCode === storeCodeRecv &&
                storeTransferStatusCode === TRANSFER_STATUS_CODE.SHI;
            const productQty = getTotalSum(products, 'quantity');

            this.setState({
                body: {
                    storeTransferId,
                    storeCodeSend,
                    storeCodeRecv,
                    associateCodeRecv,
                    commentSend,
                    commentRecv,
                    storeTransferStatusCode,
                    storeTransferReasonCode,
                    productQty,
                    storeTransferProducts,
                    totalBox,
                },
                isQtySend,
                isQtyRecv,
                disabled: storeTransferStatusCode === TRANSFER_STATUS_CODE.REC,
                disabledBtns: [
                    TRANSFER_STATUS_CODE.REC,
                    TRANSFER_STATUS_CODE.SHI,
                    TRANSFER_STATUS_CODE.REJ,
                ].includes(storeTransferStatusCode),
                transferProducts: storeTransferProducts,
            });
        }
    }

    componentDidUpdate(prevProps, prevState): void {
        if (!prevProps.createdStatus && this.props.createdStatus) {
            let saveMessage = i18next.t('alertMessage.1');
            if (this.props.createdStatus === 'success') {
                saveMessage = i18next.t('alertMessage.2');
            }

            this.setState({ showSaveMessage: true, saveMessage });
        }
        if (
            prevProps.data.storeTransferStatusCode !==
            this.props.data.storeTransferStatusCode
        ) {
            const { data = {}, account: { storeCode = '' } = {} } = this.props;
            const {
                storeCodeSend = '',
                storeCodeRecv = '',
                storeTransferStatusCode = '',
            } = data;
            const isQtySend =
                storeCode === storeCodeSend &&
                storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK;
            const isQtyRecv =
                storeCode === storeCodeRecv &&
                storeTransferStatusCode === TRANSFER_STATUS_CODE.SHI;

            this.setState({ isQtyRecv, isQtySend });
        }
    }

    handleSetBarcode = (e) => {
        const { value } = e.target;
        if (parseInt(value)) {
            this.setState({ barcode: value });
        }
    };

    handleChangeInput = (e) => {
        const { name, value } = e.target;
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                [name]: value,
            },
        }));
    };

    handleChange = (field, product?) => (e) => {
        const { name, value } = e.target;
        const { body } = this.state;
        const { storeTransferProducts, productQty } = body;

        // if (product && product.sendStoreStock < value) {
        //     this.setState({ errorMessage: i18next.t('Terminal.confirm.12') });
        //     return;
        // }

        const products = storeTransferProducts.map((item: any) => {
            if (item.barcode === name) {
                return { ...item, [field]: +value || 0 };
            }
            return item;
        });

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeTransferProducts: products,
                productQty: product
                    ? getTotalSum(products, 'quantity')
                    : productQty,
            },
            transferProducts: products,
        }));
    };

    handleInput =
        (field, onBlur = false) =>
        (e) => {
            const { name, value } = e.target;
            const { body } = this.state;
            const { storeTransferProducts } = body;

            if (+value) return;

            const products = storeTransferProducts.map((item: any) => {
                if (item.barcode === name) {
                    return { ...item, [field]: onBlur ? '0' : '' };
                }
                return item;
            });

            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeTransferProducts: products,
                },

                transferProducts: products,
            }));
        };

    handleAddProduct = (
        barcodeProduct,
        barcode = this.state.barcode,
        count?,
    ) => {
        const { body, isSubmit } = this.state;
        const { sizes = [], name } = barcodeProduct;
        const {
            storeTransferProducts,
            productQty,
            storeCodeRecv,
            storeCodeSend,
        } = body;
        const product = sizes.find((item) => item.productReference === barcode);
        if (product) {
            let recvStock: any = {};
            let sendStock: any = {};
            if (Array.isArray(product.storeInventories)) {
                recvStock =
                    product.storeInventories.find(
                        (item) => item.storeCode === storeCodeRecv,
                    ) || {};
                sendStock =
                    product.storeInventories.find(
                        (item) => item.storeCode === storeCodeSend,
                    ) || {};
            }

            if (sendStock.storeStock >= 1) {
                const products = storeTransferProducts.filter(
                    (item: any) => item.barcode,
                );
                const newProduct = {
                    name,
                    barcode: product.productReference,
                    size: product.size,
                    recvStoreStock: recvStock.storeStock || 0,
                    sendStoreStock: sendStock.storeStock || 0,
                    quantity: count || 1,
                };

                const transferProducts = [...products, newProduct];

                this.setState(
                    (prevState: State) => ({
                        body: {
                            ...prevState.body,
                            storeTransferProducts: transferProducts,
                            productQty: productQty + 1,
                        },
                        barcode: '',
                        transferProducts,
                    }),
                    () => isSubmit && this.handleSubmit(),
                );
            } else {
                if (count) return;
                this.setState({
                    errorMessage: i18next.t('Terminal.confirm.12'),
                    barcode: '',
                });
            }
        }
    };

    getProduct = (barcode, count?, end?, isSubmit?) => {
        if (!barcode) return;

        const { body, isQtyRecv, isQtySend } = this.state;
        const { storeTransferProducts } = body;
        const existingProduct = storeTransferProducts.findIndex(
            (item: any) => item.barcode === barcode,
        );

        if (existingProduct > -1) {
            const products = storeTransferProducts.map((item: any) => {
                if (item.barcode === barcode) {
                    return {
                        ...item,
                        quantity:
                            isQtySend || isQtyRecv
                                ? item.quantity
                                : item.quantity + (count || 1),
                        qtySend: isQtySend
                            ? (item.qtySend || 0) + (count || 1)
                            : item.qtySend,
                        qtyRecv: isQtyRecv
                            ? (item.qtyRecv || 0) + (count || 1)
                            : item.qtyRecv,
                    };
                }
                return item;
            });

            const productQty = getTotalSum(products, 'quantity');

            this.setState(
                (prevState: State) => ({
                    body: {
                        ...prevState.body,
                        storeTransferProducts: products,
                        productQty,
                    },
                    barcode: '',
                    transferProducts: count
                        ? products
                        : products.filter(
                              (item: any) => item.barcode === barcode,
                          ),
                }),
                () => isSubmit && this.handleSubmit(),
            );

            if (isSubmit || !count) return;
        } else {
            const { storeCodeRecv = '', storeTransferStatusCode } =
                this.props.data;
            if (
                !(
                    storeCodeRecv[0] === 'W' &&
                    storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK
                ) &&
                (isQtyRecv || isQtySend)
            ) {
                this.barcodesList.push(barcode);
            } else {
                getProductDataByBarcode(barcode, this.props.account)
                    .then((res) => {
                        if (res && res.productId) {
                            this.setState(
                                { barcode, isSubmit: !!isSubmit },
                                () =>
                                    this.handleAddProduct(res, barcode, count),
                            );
                        } else {
                            this.setState({
                                errorMessage: i18next.t(
                                    'Terminal.unknown.barcode',
                                ),
                            });
                        }
                    })
                    .catch((e) => console.log(e));

                if (count) return;
            }
        }
        if ((end || !count) && this.barcodesList.length) {
            this.setState({
                errorMessage: i18next.t('Terminal.confirm.22', {
                    barcodesList: this.barcodesList.join(', '),
                }),
            });
        }

        // if (!isSubmit && !end && !count) {
        //     this.handleAdd();
        // }
    };

    handleDelete = (barcode) => () => {
        const { body } = this.state;
        const { storeTransferProducts = [] }: { storeTransferProducts: any } =
            body;
        let products: object[] = [];

        if (
            !barcode &&
            storeTransferProducts[0] &&
            storeTransferProducts[0].newProd
        ) {
            products = storeTransferProducts.slice(1);
        } else {
            products = storeTransferProducts.map((item: any) =>
                item.barcode === barcode ? { ...item, quantity: 0 } : item,
            );
        }

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeTransferProducts: products,
                productQty: getTotalSum(products, 'quantity'),
            },
            transferProducts: products,
        }));
    };

    handleAdd = () => {
        const { body } = this.state;
        const { storeTransferProducts } = body;
        if (storeTransferProducts.find((item: any) => !item.barcode)) {
            return false;
        }
        const products = [
            {
                barcode: '',
                quantity: 1,
                newProd: `new_${storeTransferProducts.length}`,
            },
            ...storeTransferProducts,
        ];
        const productQty = getTotalSum(products, 'quantity');
        this.setState(
            (prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeTransferProducts: products,
                    productQty,
                },
                transferProducts: products,
            }),
            () =>
                this.barcodeInputRef.current &&
                this.barcodeInputRef.current.focus(),
        );
    };

    handleSubmitEnvoye = () => {
        const { storeCode } = this.props.account;
        const { storeCodeSend = '' } = this.props.data;
        const isTypeW = storeCodeSend[0] === 'W';
        let storeTransferStatusCode = TRANSFER_STATUS_CODE.REQ;

        if (storeCode === storeCodeSend || isTypeW) {
            storeTransferStatusCode = TRANSFER_STATUS_CODE.ACK;
        }

        this.setState(
            {
                body: {
                    ...this.state.body,
                    storeTransferStatusCode,
                },
                isTypeW,
            },
            this.handleSubmit,
        );
    };

    checkTotalBoxIsRequired = () => {
        const { body } = this.state;
        const { data, account: { storeDetails = {} } = {} } = this.props;
        return (
            data.storeTransferStatusCode !== TRANSFER_STATUS_CODE.PEN &&
            storeDetails.storeCode === data.storeCodeSend &&
            !body.totalBox
        );
    };

    handleSubmit = () => {
        const { body, isQtyRecv, barcode, isTypeW } = this.state;

        if (this.checkTotalBoxIsRequired()) {
            return;
        }

        if (barcode) {
            this.getProduct(barcode, undefined, undefined, true);
            return;
        }

        this.setState({ showSaveMessage: false });

        const storeTransferProducts = Object.values(body.storeTransferProducts.reduce((acc, item: any) => {
            if(item.barcode){
                acc[item.barcode] = {
                    barcode: item.barcode,
                    quantity: item.quantity,
                    qtyRecv: item.qtyRecv,
                    qtySend: item.qtySend,
                }
            }
            return acc
        }, {}))

        if (
            isQtyRecv &&
            !storeTransferProducts.find((el: any) => el.qtyRecv > 0)
        ) {
            this.setState({ errorMessage: i18next.t('alertMessage.10') });
        }

        this.props.createTransferStart({
            body: { ...body, storeTransferProducts },
            updateStatus: isTypeW ? TRANSFER_STATUS_CODE.ACK : undefined,
        });

        this.setState({ isTypeW: false, showPopUp: false });
    };

    handleUpdateStatus = () => {
        const { transferStatus } = this.state;
        if (!this.props.data || !this.props.data.storeTransferCode) {
            this.closeConfirmPopUp();
            return;
        }
        this.props.updateTransferStart({
            code: this.props.data.storeTransferCode,
            status: transferStatus,
        });
        this.closeConfirmPopUp();
    };

    isActiveBtn = (code, name = '') => {
        const { account } = this.props,
            { storeCodeRecv, storeCodeSend, storeTransferStatusCode } =
                this.state.body,
            storeCode = account && account.storeCode;

        if (code === 'ENVOYE') {
            if (
                [
                    TRANSFER_STATUS_CODE.ACK,
                    TRANSFER_STATUS_CODE.REJ,
                    TRANSFER_STATUS_CODE.REC,
                    TRANSFER_STATUS_CODE.REQ,
                ].includes(storeTransferStatusCode)
            ) {
                return true;
            }
            return false;
        }
        if (name === 'ACCEPTE') {
            if (TRANSFER_STATUS_CODE.PEN === storeTransferStatusCode) {
                return true;
            }
            return false;
        }
        if (
            storeTransferStatusCode === TRANSFER_STATUS_CODE.REQ &&
            storeCodeRecv === storeCode
        ) {
            if (code === TRANSFER_STATUS_CODE.ACK) {
                return true;
            } else if (code === TRANSFER_STATUS_CODE.REJ) {
                return false;
            } else if (code === 'SAUVEGARDER') {
                return false;
            } else if (code === 'AJOUTER') {
                return false;
            }
        } else if (
            storeTransferStatusCode === TRANSFER_STATUS_CODE.REQ &&
            storeCodeSend === storeCode
        ) {
            if (code === TRANSFER_STATUS_CODE.ACK) {
                return false;
            } else if (code === TRANSFER_STATUS_CODE.REJ) {
                return false;
            } else if (code === 'SAUVEGARDER') {
                return true;
            } else if (code === 'AJOUTER') {
                return true;
            }
        } else if (
            storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK &&
            storeCodeRecv === storeCode
        ) {
            if (code === TRANSFER_STATUS_CODE.ACK) {
                return true;
            } else if (code === TRANSFER_STATUS_CODE.REJ) {
                return true;
            } else if (code === 'SAUVEGARDER') {
                return false;
            } else if (code === 'AJOUTER') {
                return false;
            }
        } else if (
            storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK &&
            storeCodeSend === storeCode
        ) {
            if (code === TRANSFER_STATUS_CODE.ACK) {
                return false;
            } else if (code === TRANSFER_STATUS_CODE.REJ) {
                return false;
            } else if (code === 'SAUVEGARDER') {
                return true;
            } else if (code === 'AJOUTER') {
                return false;
            }
        } else if (
            storeTransferStatusCode === TRANSFER_STATUS_CODE.REC ||
            storeTransferStatusCode === TRANSFER_STATUS_CODE.SHI ||
            storeTransferStatusCode === TRANSFER_STATUS_CODE.REJ
        ) {
            return true;
        }
    };

    openConfirmPopUp = (status) => {
        this.setState({ showConfirmpopUp: true, transferStatus: status });
    };

    closeConfirmPopUp = () => {
        this.setState({ showConfirmpopUp: false, transferStatus: '' });
    };

    handleReset = () => {
        const { isQtyRecv, isQtySend } = this.state;

        if (isQtySend || isQtyRecv) {
            this.setState((prev: State) => ({
                body: {
                    ...prev.body,
                    storeTransferProducts: prev.body.storeTransferProducts.map(
                        (item) => ({
                            ...item,
                            [isQtySend ? 'qtySend' : 'qtyRecv']: 0,
                        }),
                    ),
                },
                showResetPopUp: false,
            }));
        }
    };

    inputRefs: any[] = [];
    setRefs = (ref) => this.inputRefs.push(ref);

    handleSearchBarcode = (value) => {
        if (value) {
            this.setState({ searchLoading: true }, async () => {
                await delay(100);
                const transferProducts =
                    this.state.body.storeTransferProducts.filter(
                        ({ barcode }) => barcode === value,
                    );
                this.setState({
                    searchLoading: false,
                    transferProducts,
                    currentPage: 1,
                    resetPagination: true,
                });
            });
        }
    };

    getEcartTotalSums = () => {
        const { transferProducts = [] } = this.state;
        let totalPositiveEcart = 0;
        let totalNegativeEcart = 0;
        let totalEcart = 0;

        transferProducts.forEach((item) => {
            const ecart = this.getItemEcart(item);
            if (ecart > 0) {
                totalPositiveEcart += ecart;
            }
            if (ecart < 0) {
                totalNegativeEcart += ecart;
            }
            totalEcart += ecart;
        }, 0);

        return {
            totalPositiveEcart: fixNumber(totalPositiveEcart),
            totalNegativeEcart: fixNumber(totalNegativeEcart),
            totalEcart: fixNumber(totalEcart),
        };
    };

    getItemEcart = ({ qtyRecv = 0, qtySend = 0 }) => qtyRecv - qtySend || 0;

    render() {
        const {
            errorMessage,
            showSaveMessage,
            saveMessage,
            showConfirmpopUp,
            showResetPopUp,
            body,
            barcode,
            disabled,
            disabledBtns,
            isQtySend,
            isQtyRecv,
            searchLoading,
            showPopUp,
            transferProducts,
            currentPage,
            resetPagination,
        } = this.state;
        const {
            createdStatus,
            data,
            account: { storeDetails = {} } = {},
        } = this.props;
        const totalCount = transferProducts.length;
        const products = transferProducts.slice(
            (currentPage - 1) * this.perPage,
            this.perPage * currentPage,
        );

        const { totalEcart, totalNegativeEcart, totalPositiveEcart } =
            this.getEcartTotalSums();

        return (
            <>
                <div className="card transferPopUp">
                    <div className="mb-2">
                        <div className="extra content">
                            <div className="ui two buttons">
                                <div></div>
                                <CustomBtn
                                    classN={`btn_1 w-100 blue_btn withoutHover`}
                                    handleClick={this.handleSubmit}
                                    disabled={!isQtyRecv && disabledBtns}
                                    loading={this.props.loading}
                                >
                                    {i18next.t('Terminal.dashboards.edit')}
                                </CustomBtn>

                                <FermerBtn
                                    handleClick={this.props.handleClosePopUp}
                                />
                            </div>
                            {showSaveMessage && (
                                <p
                                    className={`w-50 ${
                                        createdStatus === 'success'
                                            ? 'successMessage'
                                            : 'errorMessage'
                                    }`}
                                >
                                    {saveMessage}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="popUp--body--card--body">
                        <div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t('Terminal.dashboards.id')}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {data.storeTransferId}
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.storeNameSend',
                                    )}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {data.storeCodeSend
                                        ? `${data.storeNameSend} (${data.storeCodeSend})`
                                        : data.storeNameSend}
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.commentSend',
                                    )}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description input'
                                    }
                                >
                                    <Input
                                        value={body && body.commentSend}
                                        name="commentSend"
                                        onChange={(e) =>
                                            this.handleChangeInput(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.transferReasonCode',
                                    )}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {data.storeTransferReason}
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.qtyTotal',
                                    )}
                                </div>
                                <div className="popUp--body--card--body--between">
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {getTotalSum(data.products, 'quantity')}
                                    </div>
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t('Terminal.dashboards.ecart')}
                                    </div>
                                    <div
                                        className={`popUp--body--card--body--data--description input--div ${
                                            totalEcart > 0
                                                ? 'color--green'
                                                : 'color--darkred'
                                        }`}
                                    >
                                        <span>
                                            {totalEcart > 0
                                                ? '+' + totalEcart
                                                : totalEcart}
                                        </span>{' '}
                                        (
                                        <span className="color--green">
                                            {totalPositiveEcart
                                                ? '+' + totalPositiveEcart
                                                : 0}
                                        </span>
                                        /
                                        <span className="color--darkred">
                                            {totalNegativeEcart}
                                        </span>
                                        )
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t('Terminal.dashboards.code')}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {data.storeTransferCode}
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.storeNameRecv',
                                    )}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {data.storeCodeRecv
                                        ? `${data.storeNameRecv} (${data.storeCodeRecv})`
                                        : data.storeNameRecv}
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.commentRecv',
                                    )}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description input'
                                    }
                                >
                                    <Input
                                        value={body && body.commentRecv}
                                        name="commentRecv"
                                        onChange={(e) =>
                                            this.handleChangeInput(e)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Transfer.transferStatusCode',
                                    )}
                                </div>
                                <div
                                    className={
                                        'popUp--body--card--body--data--description disabled textBox'
                                    }
                                >
                                    {data.storeTransferStatusLabel}
                                </div>
                            </div>
                            <div className="popUp--body--card--body--data">
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Deliveries.cartons',
                                    )}
                                </div>
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description'
                                    }
                                    value={body.totalBox || ''}
                                    onChange={this.handleChangeInput}
                                    name="totalBox"
                                    type="number"
                                    error={this.checkTotalBoxIsRequired()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {body.storeTransferProducts &&
                body.storeTransferProducts.length ? (
                    <>
                        <div className="dflex justify-content-between align-items-center">
                            <SearchBox
                                onSubmit={this.handleSearchBarcode}
                                label={`${i18next.t(
                                    'Terminal.dashboards.barcode',
                                )}:`}
                                loading={searchLoading}
                                handleReset={() =>
                                    this.setState({
                                        resetPagination: true,
                                        transferProducts:
                                            body.storeTransferProducts,
                                    })
                                }
                            />
                            <div className="dflex align-items-center justify-content-end">
                                <CustomBtn
                                    classN="btn_1 w-100 red_btn maxWidth-150"
                                    handleClick={() =>
                                        this.setState({ showResetPopUp: true })
                                    }
                                    disabled={!(isQtyRecv || isQtySend)}
                                >
                                    {i18next.t('buttons.36')}
                                </CustomBtn>
                                <div className="maxWidth-150">
                                    <PrintEanBarcodes
                                        data={this.props.data}
                                        details={this.props.account}
                                        buttonStyles="ml-1"
                                    />
                                </div>
                                <SetTransferBtn
                                    handleUpload={this.getProduct}
                                    handleAjouter={
                                        barcode
                                            ? () => this.getProduct(barcode)
                                            : this.handleAdd
                                    }
                                    disableFilBtn={!isQtyRecv && disabledBtns}
                                    disabledAjouterBtn={
                                        !!this.isActiveBtn('AJOUTER')
                                    }
                                    storData={data}
                                    storeDetails={storeDetails}
                                    pdfData={body.storeTransferProducts}
                                />
                            </div>
                        </div>

                        <div className="transferspopUp--btnBox">
                            <CustomBtn
                                classN={`btn_1 w-100 green_btn ${
                                    !!this.isActiveBtn(
                                        TRANSFER_STATUS_CODE.ACK,
                                        'ACCEPTE',
                                    )
                                        ? 'disabled'
                                        : ''
                                } `}
                                disabled={
                                    !!this.isActiveBtn(TRANSFER_STATUS_CODE.ACK)
                                }
                                handleClick={() =>
                                    this.openConfirmPopUp(
                                        TRANSFER_STATUS_CODE.ACK,
                                    )
                                }
                            >
                                {i18next.t('buttons.8')}
                            </CustomBtn>
                            <CustomBtn
                                classN={`btn_1 w-100 red_btn ${
                                    !!this.isActiveBtn(
                                        TRANSFER_STATUS_CODE.REJ,
                                        'ACCEPTE',
                                    )
                                        ? 'disabled'
                                        : ''
                                }`}
                                disabled={
                                    !!this.isActiveBtn(TRANSFER_STATUS_CODE.REJ)
                                }
                                handleClick={() =>
                                    this.openConfirmPopUp(
                                        TRANSFER_STATUS_CODE.REJ,
                                    )
                                }
                            >
                                {i18next.t('buttons.14')}
                            </CustomBtn>
                            <CustomBtn
                                classN={`btn_1 green_btn w-100`}
                                handleClick={() =>
                                    this.setState({ showPopUp: true })
                                }
                                disabled={
                                    isQtyRecv || this.isActiveBtn('ENVOYE')
                                }
                            >
                                {i18next.t('Terminal.dashboards.apply')}
                            </CustomBtn>
                        </div>

                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Transfer.barcode',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.name')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Size')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Transfer.recvStoreStock',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Transfer.sendStoreStock',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Transfer.Qty',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Transfer.Expedited',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.Receipt.Transfer.Controlled',
                                        )}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.ecart')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title"></Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body className="transfers-list product---list--content">
                                {products.map((item: any, i) => {
                                    const {
                                        quantity = 0,
                                        qtyRecv = 0,
                                        qtySend = 0,
                                    } = item;
                                    if (!+quantity && +quantity !== 0) {
                                        return;
                                    }
                                    return (
                                        <Table.Row
                                            key={i}
                                            className={
                                                item.newProd
                                                    ? 'table--active--row'
                                                    : `${i % 2 === 0 ? "white-row" : "light-gray-row"}`
                                            }
                                        >
                                            <Table.Cell
                                                className={`productInfoBlock--infoBox--table--description${
                                                    item.newProd ? ' new' : ''
                                                }`}
                                            >
                                                {!item.newProd ? (
                                                    item.barcode
                                                ) : (
                                                    <Input
                                                        ref={
                                                            this.barcodeInputRef
                                                        }
                                                        className="barcode--input"
                                                        name={`new_${i}`}
                                                        value={barcode}
                                                        onChange={
                                                            this
                                                                .handleSetBarcode
                                                        }
                                                    />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.size}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.recvStoreStock}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.sendStoreStock}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                <Input
                                                    disabled={disabled}
                                                    value={quantity}
                                                    name={item.barcode}
                                                    onChange={this.handleChange(
                                                        'quantity',
                                                        item,
                                                    )}
                                                    ref={this.setRefs}
                                                />
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {qtySend}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                <Input
                                                    className={`focus-red-bg ${
                                                        quantity !== qtyRecv
                                                            ? 'input-color-red'
                                                            : ''
                                                    }`}
                                                    disabled={!isQtyRecv}
                                                    value={qtyRecv}
                                                    name={item.barcode}
                                                    onChange={this.handleChange(
                                                        'qtyRecv',
                                                    )}
                                                    onFocus={this.handleInput(
                                                        'qtyRecv',
                                                    )}
                                                    onBlur={this.handleInput(
                                                        'qtyRecv',
                                                        true,
                                                    )}
                                                />
                                            </Table.Cell>
                                            <Table.Cell
                                                className={`productInfoBlock--infoBox--table--description ${
                                                    this.getItemEcart(item) > 0
                                                        ? 'color--green'
                                                        : this.getItemEcart(
                                                              item,
                                                          ) < 0
                                                        ? 'color--red'
                                                        : ''
                                                }`}
                                            >
                                                {this.getItemEcart(item)}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description trash">
                                                {!disabledBtns && (
                                                    <Icon
                                                        name="trash alternate"
                                                        onClick={this.handleDelete(
                                                            item.barcode,
                                                        )}
                                                    ></Icon>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        {totalCount > this.perPage && (
                            <Pagination
                                onChangePage={(currentPage) =>
                                    this.setState({
                                        currentPage,
                                        resetPagination: false,
                                    })
                                }
                                totalCount={totalCount}
                                perPage={this.perPage}
                                resetDefault={resetPagination}
                            />
                        )}
                    </>
                ) : (
                    ''
                )}

                <BarcodeReader onScan={(barcode) => this.getProduct(barcode)} />

                {showConfirmpopUp && (
                    <ConfirmPopup
                        handleConfirm={this.handleUpdateStatus}
                        handleCancel={() => this.closeConfirmPopUp()}
                        text={i18next.t('Terminal.modal.2')}
                    />
                )}

                {showResetPopUp && (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showResetPopUp: false })
                        }
                        handleConfirm={this.handleReset}
                        text={i18next.t('Terminal.confirm.4')}
                    />
                )}

                {showPopUp && (
                    <ConfirmPopup
                        handleCancel={() => this.setState({ showPopUp: false })}
                        handleConfirm={this.handleSubmitEnvoye}
                        text={i18next.t('Terminal.transferBasket.5')}
                    />
                )}

                <InfoPopUp
                    open={!!errorMessage}
                    classN="message-error"
                    message={errorMessage}
                    handleClose={() => {
                        this.barcodesList = [];
                        this.setState({ errorMessage: '', barcode: '' });
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        createdStatus: getCreatedStatus(state),
        loading: getCreateLoading(state),
        account: getAccountResource(state),
    };
};

const mapDispatchToProps = (dispatch): typeof TransfersActionCreators => {
    return bindActionCreators(TransfersActionCreators, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(TransferDetails);
