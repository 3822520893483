import React from 'react';
import publicIp from 'public-ip';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import ErrorBoundary from './error-boundary';
import {encrypt, getLangPrefix} from '../../helpers/helpers'
import {connect} from "react-redux";
import {UserActionCreators} from '../../modules/login/redux/actions';
import {MenuActionCreators} from '../../layouts/main/header/redux/actions';
import {isAuthenticated, isPrivateAuthenticated} from "../../modules/login/redux/selectors";
import {getActiveMenuKey, getBreadcrumb} from "../../layouts/main/header/redux/selectors";


interface State {
    ip?: any,
}

interface Props extends RouteProps {
    canonicalUrl?: string,
    menuItem?: any,
    dispatch? :any,
    history? :any,
    privateAuthenticated?: boolean,
    location: any,
    activeMenuKey: string,
    authenticated: boolean,
    breadcrumb: any
}


class ErrorBoundaryRoute extends React.PureComponent <Props> {
    state: State = {
        ip: 0,
    };

    componentDidMount(): void {
        this.props.dispatch(UserActionCreators.tokenValidateStart());
        this.props.dispatch(MenuActionCreators.setBreadcrumbStart(this.props.menuItem || {}))
    }

    componentDidUpdate(prevProps, prevState) {
        const {location} = this.props
        if(prevProps.location.pathname && location.pathname){
            try {
                const pathArr = location.pathname.split("/");
                const activeMenuKey = pathArr.length && pathArr[2] ? pathArr[2].split("-").join("_") : "";

                if(this.props.activeMenuKey !== activeMenuKey) {
                    if (activeMenuKey) {
                        this.props.dispatch(MenuActionCreators.activeMenuStart(activeMenuKey))
                    } else {
                        this.props.dispatch(MenuActionCreators.activeMenuStart(""))
                    }
                }
            } catch (e) {
                this.props.dispatch(MenuActionCreators.activeMenuStart(""))
            }

        }

        if((this.props.menuItem.url !== this.props.breadcrumb.url) || (this.props.menuItem.id !== this.props.breadcrumb.id)){
            this.props.dispatch(MenuActionCreators.setBreadcrumbStart(this.props.menuItem || {}))
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(!localStorage.getItem("ip")){
            publicIp.v4()
                .then(res => {
                    encrypt("ip", res)
                })
                .catch(err => console.log(err))
        }


        let fingerprint = function (window, screen, navigator) {

            function checksum(str) {
                let hash = 5381,
                    i = str.length;

                while (i--) hash = (hash * 33) ^ str.charCodeAt(i);

                return hash >>> 0;
            }

            function map(arr, fn) {
                let i = 0, len = arr.length, ret = [] as any;
                while (i < len) {
                    ret[i] = fn(arr[i++]);
                }
                return ret;
            }

            return checksum([
                navigator.userAgent,
                [screen.height, screen.width, screen.colorDepth].join('x'),
                new Date().getTimezoneOffset(),
                map(navigator.plugins, (plugin) => {
                    return [
                        plugin.name,
                        plugin.description,
                        map(plugin, (mime) => {
                            return [mime.type, mime.suffixes].join('~');
                        }).join(',')
                    ].join("::");
                }).join(';')
            ].join('###'));

        };
        encrypt("browserId", JSON.stringify(fingerprint(this, screen, navigator)));
    };

    render() {
        const langPrefix = getLangPrefix();
        const {component: Component, ...rest} = this.props;
        if (!Component) throw new Error(`A component needs to be specified for path ${(rest).path}`);

        if(!this.props.privateAuthenticated && !sessionStorage.getItem("privateToken")) {
            return (<Redirect to={`${langPrefix}/login`} />)
        }

        if(!this.props.authenticated && !localStorage.getItem("token") && this.props.path !== langPrefix) {
            return (<Redirect to={`${langPrefix}`} />)
        }
        return (
            <Route  {...rest} render={(props: any) => {
                if (rest.canonicalUrl) {
                    props.canonicalUrl = rest.canonicalUrl;
                }
                if (rest.menuItem) {
                    props.menuItem = rest.menuItem;
                }
                return (<ErrorBoundary>
                    <Component {...props} />
                </ErrorBoundary>)
            }
            }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    privateAuthenticated: isPrivateAuthenticated(state),
    activeMenuKey: getActiveMenuKey(state),
    authenticated: isAuthenticated(state),
    breadcrumb: getBreadcrumb(state)
})

export default connect(mapStateToProps)(ErrorBoundaryRoute);
