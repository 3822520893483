import {inventoryTypes, InventoryActionCreators} from "./actions";
import {takeLatest, put, call,takeEvery} from "redux-saga/effects";
import axios from 'axios';
import {
    INVENTORY_URL,
    SINGLE_INVENTORY_URL,
    CREATE_INVENTORY_URL,
    UPDATE_INVENTORY_URL
} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers";
import {DEFAULT_CURRENCY} from  "./../../../utils/constants/variables";

export function* getInventoryData(params) {
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer  ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.get(`${INVENTORY_URL}?language=${getApiPrefix()}`, headers)
                .then(res => res.data)
        });
        yield put(InventoryActionCreators.inventorySuccess(data));
    } catch (e) {
        yield put(InventoryActionCreators.inventoryFailure(e.message));
    }
}

export function* getSingleInventoryData(params) {
    const { id } = params;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer  ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.get(`${SINGLE_INVENTORY_URL}${id}`, headers)
                .then(res => res.data)
        });
        yield put(InventoryActionCreators.singleInventorySuccess(data));
    } catch (e) {
        yield put(InventoryActionCreators.singleInventoryFailure(e.message));
    }
}

export function* createInventory(params) {
    const body= params.data;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer  ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.post(`${CREATE_INVENTORY_URL}?language=${getApiPrefix()}`,body, headers)
                .then(res => res.data)
        });
        yield put(InventoryActionCreators.createInventorySuccess(data));
        yield put(InventoryActionCreators.inventorySuccess(data));
        yield put(InventoryActionCreators.inventoryStart());
    } catch (e) {
        yield put(InventoryActionCreators.createInventoryFailure(e.message));
    }
}


export function* updateStoreInventory(params) {
    const {logisticStatus,storeInventoryId} = params
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer  ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency: DEFAULT_CURRENCY
            }
        };
        const data = yield call(() => {
            return axios.put(`${UPDATE_INVENTORY_URL}?storeInventoryRequestId=${storeInventoryId}&logisticStatus=${logisticStatus}`,  "",headers)
                .then(res => res.data)
        });
        yield put(InventoryActionCreators.updateStoreInventorySuccess(data));
    }catch (e) {
        yield put(InventoryActionCreators.updateStoreInventoryFailure(e.message));
    }
}


export function* inventorySaga() {
    yield takeLatest(inventoryTypes.INVENTORY_START, getInventoryData);
    yield takeLatest(inventoryTypes.SINGLE_INVENTORY_START, getSingleInventoryData);
    yield takeLatest(inventoryTypes.CREATE_INVENTORY_START, createInventory);
    yield takeLatest(inventoryTypes.UPDATE_STORE_INVENTORY_START, updateStoreInventory);
}

export default inventorySaga;
