import React from "react";
import {connect} from "react-redux";
import {AddToCartActionCreators} from "../redux/actions";
import {getBasket, isAddedToCart, getError, getMaxLimit} from "../redux/selectors";
import {bindActionCreators} from "redux";
import {addtocart, IAddToCartState} from "../redux/reducers";
import i18next from "i18next";

import "./addToCart.scss";
import {getActiveBasketName} from "../../../helpers/helpers";
import {Icon} from "semantic-ui-react";
import { LOCAL_STORAGE } from "../../../utils/constants/variables";

interface Details {
    quantity?: any,
    maxQuantity?: any,
    sizes?: string,
    productId?: any,
    price?: any,
    photo?: string,
    canonicallUrl?: string,
    name?: string,
}

interface Props extends IAddToCartState {
    //dispatch: (props: any) => any;
    buttonText?: string,
    data?: any,//Details[]
    handleAddedToCard?: any
    selectedSize?: any
}

interface State {
    visible: boolean,
    messages: string[],
    invalid: boolean
}

class AddToCart extends React.Component<typeof AddToCartActionCreators> {

    state: State = {
        visible: false,
        messages: [],
        invalid: false
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.isComplect && (nextProps.addedToCart || nextProps.maxLimit)) {
            let messages: string[] = [], invalid = false;
            if (nextProps.addedToCart) {
                messages.push(i18next.t("addToCart.1"));
                invalid = false;
            } else if (nextProps.maxLimit) {
                messages.push(i18next.t("addToCart.2"));
                invalid = true;
            }
            this.setState({invalid, messages})
        }

        const nextData = Object.keys(nextProps.data);
        const data = Object.keys(this.props.data)
        if(nextData && data && nextData.length && data.length && nextData[0] !== data[0]){
            this.setState({visible: false})
        }
    }

    handleChange = (e) => {
    };

    handleAddToCart = (basket=getActiveBasketName()) => {
        let {data, isComplect = false, selectedSize = {}} = this.props;
        let messages: string[] = [], invalid = false;
        for (let productId of Object.keys(data)) {
            if(data[productId] && data[productId].size) {
                data[productId].isClientDiscountAllow = data[productId].size.listPriceAfterDiscount
                    ? data[productId].size.outlet : true;
            }
            if (!isComplect) {
                if (data[productId].quantity === 0 || data[productId].quantity === "0") {
                    messages.push(i18next.t("addToCart.3"));
                    invalid = true;
                }
                if (!Object.entries(data[productId].size).length) {
                    messages.push(i18next.t("addToCart.4"));
                    invalid = true;
                }
            }
            if (!invalid) {
                data[productId].size.quantity = parseInt(data[productId].quantity);
                delete data[productId].quantity;
                if (data[productId].size.quantity > 0) {
                    if (selectedSize.sizes && selectedSize.sizes.length) {
                        data[productId].size.discountDetail = selectedSize.sizes[0].discountDetail;
                        data[productId].size.listPriceAfterDiscount = selectedSize.sizes[0].listPriceAfterDiscount;
                    }
                    this.props.addtocartStart(basket, productId, {...data[productId]})
                }
            }
        }

        this.setState({invalid, messages, visible: true});
        if (this.props.handleAddedToCard) {
            this.props.handleAddedToCard(basket)
        }
    };

    render(): React.ReactNode {
        const {buttonText,isEnable} = this.props;
        const {visible, invalid, messages} = this.state;
        return (
            <div className="addtocart">
                <div className="addtocart--btnBox">
                    <button className={`product-sell button-style ${isEnable ? "" : "disable"}`}
                            disabled={!isEnable} onClick={()=>this.handleAddToCart()}><span><Icon disabled name='shopping cart' />{buttonText}</span>
                    </button>
                    <button className={`button-style truckButton ${isEnable ? "" : "disable"}`}
                            disabled={!isEnable} onClick={()=>this.handleAddToCart(LOCAL_STORAGE.deliveryBasket)}><Icon disabled name='truck' />
                    </button>
                </div>
                {
                    visible && messages.length ? messages.map((item, i) => <div
                        className={`message ${invalid ? "error" : "success"}`} key={i}>{item}</div>) : ""

                }
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    basket: getBasket(state),
    addedToCart: isAddedToCart(state),
    error: getError(state),
    maxLimit: getMaxLimit(state)
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators(AddToCartActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);

