import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { Input, Table } from 'semantic-ui-react';
import { SalesActionCreators } from '../../redux/actions';
import { getSales } from '../../redux/selectors';

import PopUp from '../../../popUp/popUp';
import {
    RECEIPT_TYPE,
    TICKET_TYPE,
} from '../../../../utils/constants/variables';
import InfoPopUp from '../../../common/infoPopUp';
import { getItemArrayOfObject } from '../../../../helpers/helpers';

interface Props {
    data: any;
    singleSalesData: any;
    handleClose: (p?) => void;
    showProductsPanel: boolean;
}

interface State {
    open: boolean;
    message: string;
    productList: any;
}

class SalesProducts extends Component<
    Props & typeof SalesActionCreators,
    State
> {
    state: State = {
        open: false,
        message: '',
        productList: {},
    };

    componentDidMount(): void {
        const data = this.props.data || {};
        const { ticketNumber, currency, storeCode } = data;
        if (ticketNumber) {
            this.props.singleSalesStart(ticketNumber, currency, storeCode);
        }
    }

    componentDidUpdate(prevProps, prevState): void {
        if (
            prevProps.data &&
            this.props.data &&
            this.props.data.ticketNumber !== prevProps.data.ticketNumber
        ) {
            const { ticketNumber, currency, storeCode } = this.props.data;
            this.props.singleSalesStart(ticketNumber, currency, storeCode);
        }

        if (prevProps.isSingleLoading && !this.props.isSingleLoading) {
            const productList = {};
            this.props.singleSalesData &&
                this.props.singleSalesData.storeSaleLineItems &&
                this.props.singleSalesData.storeSaleLineItems.forEach(
                    (item) => {
                        productList[item.productId] = item.quantity;
                    },
                );

            this.setState({ productList });
        }
    }
    printTicket = () => {
        const { data: { ticketNumber = '' } = {}, printer } = this.props;
        if (!ticketNumber || !printer) return;
        this.props.printTicketStart({
            ticketNumber,
            receiptType: RECEIPT_TYPE.cadeau,
            type: TICKET_TYPE.gift,
            productsQty: this.state.productList,
        });
    };

    handleChange = (target) => {
        let { name, value } = target;
        const { quantity = 0 } = getItemArrayOfObject(
            this.props.singleSalesData.storeSaleLineItems,
            'productId',
            +name,
        );
        if (+value !== 0 && (value < 0 || value > quantity)) {
            value = quantity;
        }
        this.setState((prevState: State) => ({
            productList: {
                ...prevState.productList,
                [name]: value,
            },
        }));
    };
    render() {
        const { handleClose, singleSalesData, printer } = this.props;
        const { open, message, productList } = this.state;
        const { storeSaleLineItems } = singleSalesData;
        return (
            <PopUp handleClose={() => handleClose()}>
                <div>
                    {storeSaleLineItems && storeSaleLineItems.length ? (
                        <Table
                            celled
                            selectable
                            className="productInfoBlock--infoBox--table collaps"
                        >
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Name')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t(
                                            'Terminal.dashboards.quantity',
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {storeSaleLineItems.map((item, idx) => {
                                    return (
                                        <Table.Row key={idx}>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.productName}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description break-word">
                                                <Input
                                                    type="number"
                                                    name={item.productId}
                                                    value={
                                                        productList[
                                                            item.productId
                                                        ] || ''
                                                    }
                                                    onChange={({ target }) =>
                                                        this.handleChange(
                                                            target,
                                                        )
                                                    }
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    ) : (
                        ''
                    )}
                </div>
                <button
                    className={`btn_1 w-100 pink_btn${
                        printer ? '' : ' disabled'
                    }`}
                    disabled={!printer}
                    onClick={this.printTicket}
                >
                    {i18next.t('buttons.21')}
                </button>
                <InfoPopUp
                    open={open}
                    classN="message-error"
                    message={message}
                    handleClose={() => this.setState({ open: false })}
                />
            </PopUp>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { singleSalesData, isSingleLoading } = getSales(state);
    return {
        singleSalesData,
        isSingleLoading,
    };
};

const mapDispatchToProps = (dispatch): typeof SalesActionCreators => {
    return bindActionCreators(SalesActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesProducts);
