import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { DatatableActionCreators } from '../../datatable/redux/actions';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';
import CustomBtn from '../../common/customBtn';
import { ORDER_STATUS_CODE } from '../../../utils/constants/variables';

interface IProps {
    dispatch: any;
    data: any;
}
interface IState {
    showConfirmPopup: boolean;
    status: string;
    title: string;
}

class EnvoiClientBtn extends React.Component<IProps, IState> {
    state: IState = {
        showConfirmPopup: false,
        status: '',
        title: '',
    };

    startRequest = () => {
        const { status } = this.state;
        this.props.dispatch(
            DatatableActionCreators.updateOrderStatusStart(
                this.props.data.orderId,
                status,
            ),
        );
        this.setState({ showConfirmPopup: false });
    };

    handleClick = (status, title) => () => {
        this.setState({ showConfirmPopup: true, status, title });
    };

    render() {
        const { data: { orderStatus = {}, orderId = null } = {} } = this.props;
        const { showConfirmPopup, title } = this.state;

        return (
            <>
                {showConfirmPopup ? (
                    <ConfirmPopup
                        handleCancel={() =>
                            this.setState({ showConfirmPopup: false })
                        }
                        handleConfirm={this.startRequest}
                        text={i18next.t('Terminal.confirm.7', {
                            orderId: orderId,
                        })}
                        title={title}
                    />
                ) : (
                    ''
                )}
                <CustomBtn
                    classN="btn_1 w-100 blue_btn"
                    handleClick={this.handleClick(
                        ORDER_STATUS_CODE.REA,
                        i18next.t('buttons.2'),
                    )}
                    disabled={
                        !orderId || orderStatus.code === ORDER_STATUS_CODE.SHI
                    }
                >
                    {i18next.t('buttons.2')}
                </CustomBtn>
                {/* <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    handleClick={this.handleClick(
                        ORDER_STATUS_CODE.SHI,
                        i18next.t('buttons.3'),
                    )}
                    disabled={!orderId}
                >
                    {i18next.t('buttons.3')}
                </CustomBtn> */}
            </>
        );
    }
}
export default connect()(EnvoiClientBtn);
