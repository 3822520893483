import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { pdf } from '@react-pdf/renderer';
import { Dropdown } from 'semantic-ui-react';
import { TableGenerator } from './tableGenerator';
import { cloneObject, delay } from '../../helpers/helpers';
import CustomBtn from '../common/customBtn';
import PopUp from '../popUp/popUp';

interface IProps {
    disabled: boolean;
    name?: string;
    classN: string;
    options?: any;
    [rest: string]: any;
}

const PdfTable: React.FC<IProps> = ({
    disabled,
    name,
    classN,
    children,
    options,
    ...rest
}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState<any>('');

    const handlePdf = async (val?) => {
        try {
            let { data } = rest;
            //TODO remov this if
            if (val) {
                data = cloneObject(rest.data).filter((item) => {
                    if (item.zone) {
                        const zone = item.zone.split('/');
                        const idx = zone.findIndex((el) => el === val);

                        if (idx > -1 && zone.length === 1) {
                            return true;
                        }

                        if (idx > -1 && item.qtyInventory.split('/')[idx]) {
                            item.zone = val;
                            item.qtyInventory =
                                item.qtyInventory.split('/')[idx];
                            item.totalZone =
                                item.qtyInventory - (item.stockDefect || 0);
                            return true;
                        }
                    }
                });
            }

            setLoading(true);
            await delay(100);
            const blob = await pdf(
                <TableGenerator {...rest} data={data} />,
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setLoading(false);
            window.open(url, '_blank');
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const sortOptions = (prevItem, nextItem) => {
        const stringNumbers = {
            prevItemNumber: (prevItem.value.match(/[0-9]+/g) || [])[0],
            nextItemNumber: (nextItem.value.match(/[0-9]+/g) || [])[0]
        }

        return stringNumbers.prevItemNumber && stringNumbers.nextItemNumber
            ? stringNumbers.prevItemNumber - stringNumbers.nextItemNumber
            : prevItem.value - nextItem.value;
    }

    const handleClose = () => {
        setShow(false);
        setValue('');
    };

    return (
        <>
            <CustomBtn
                classN={classN}
                handleClick={options ? () => setShow(true) : () => handlePdf()}
                loading={loading}
                disabled={disabled}
            >
                {name || children}
            </CustomBtn>

            {options && show ? (
                <PopUp classN="zone-popup" handleClose={handleClose}>
                    <div>
                        <p>{i18next.t('Terminal.Receipt.Deliveries.zone')}</p>
                        <Dropdown
                            onChange={(e, { value }) => setValue(value)}
                            options={options.sort(sortOptions)}
                            selection
                            value={value}
                            className="w-100"
                        />
                        <CustomBtn
                            disabled={false}
                            classN="btn_1 w-100 green_btn"
                            handleClick={() => {
                                handlePdf(value), handleClose();
                            }}
                        >
                            {i18next.t('Terminal.Block3.2')}
                        </CustomBtn>
                    </div>
                </PopUp>
            ) : (
                ''
            )}
        </>
    );
};

export default PdfTable;
