import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import {
    getLoading,
    getStock,
    getStockBarcodeList,
    getStockCounters,
    getStockNegativeBarcodeList,
} from '../../redux/selectors';
import { bindActionCreators } from 'redux';
import { StockActionCreators } from './../../redux/actions';
import { Icon, Popup, Tab, Table } from 'semantic-ui-react';
import i18next from 'i18next';
import TableComponent from '../../../datatable/container/datatable';
import DeliveryBasketPopUp from '../../../deliveryBasketPopUp/container/deliveryBasketPopUp';
import { getStores } from '../../../storeFormSidePanel/redux/selectors';
import {
    addDeliveryStore,
    getActiveBasketName,
    getCurrency,
    replaceComma,
} from '../../../../helpers/helpers';
import MultiBasket from '../../../basketPopup/container/multiBasket';
import PdfTable from '../../../pdfGenerator/pdfTableBtn';
import { getAccountResource } from '../../../login/redux/selectors';
import { LOCAL_STORAGE } from '../../../../utils/constants/variables';
import SearchBox from '../../../common/searchBox';
import StocksDetails from './stockDetails';

interface Props {
    stockData: any;
    isLoading: boolean;
    handleClosePopUp?: () => void;
    stores: any;
    stockCounters: any;
    details: any;
    negativeBarcodesList: object[];
    barcodesList: object[];
}

interface State {
    showBasketDelivery: boolean;
    showBasket: boolean;
    selectedRow: any;
    activeIndex: string | number;
    stocksPopUp: boolean;
}

class ExportStocks extends Component<Props & typeof StockActionCreators> {
    state: State = {
        showBasketDelivery: false,
        showBasket: false,
        selectedRow: {},
        activeIndex: '',
        stocksPopUp: false,
    };
    columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '50%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.stock'),
            selector: 'stock',
            width: '50%',
        },
    ];

    columns = [
        {
            name: i18next.t('Terminal.dashboards.brand'),
            selector: 'brand',
            sortable: true,
            searchable: true,
            width: '11%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.collection || row.brand}
                        </div>
                    }
                    content={row.collection || row.brand}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.model'),
            selector: 'model',
            sortable: true,
            searchable: true,
            center: true,
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'productName',
            sortable: true,
            searchable: true,
            center: true,
            width: '23%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            searchable: true,
            sortable: true,
            center: true,
            width: '10%',
        },
        {
            name: i18next.t('product.labels.9'),
            selector: 'listPrice',
            sortable: true,
            searchable: true,
            center: true,
            width: '10%',
            cell: (row) => `${row.listPrice ? replaceComma(row.listPrice) + " " + getCurrency(row.currency) : ""}` 
        },
        {
            name: i18next.t('product.labels.5'),
            selector: 'outletPrice',
            sortable: true,
            searchable: true,
            center: true,
            width: '9%',
            cell: (row) => `${row.outletPrice ? replaceComma(row.outletPrice) + " " + getCurrency(row.currency) : ""}` 
        },
        {
            name: i18next.t('Terminal.dashboards.promotion'),
            selector: 'promoListPrice',
            sortable: true,
            center: true,
            width: '10%',
            cell: (row) => `${row.promoListPrice ? replaceComma(row.promoListPrice) + " " + getCurrency(row.currency) : ""}` 
        },
        {
            name: i18next.t('Terminal.dashboards.stock'),
            selector: 'stock',
            sortable: true,
            searchable: true,
            center: true,
            width: '6%',
            cell: (row) => (
                <div className="stock--btn--truck">
                    <div>{row.stock}</div>
                    {row.stock === 0 ? (
                        <div className="productInfoBlock--infoBox--table--description--btn">
                            <button
                                className="btnTruck color--blue"
                                onClick={() =>
                                    this.handleAddDeliveryBasket(
                                        row,
                                        LOCAL_STORAGE.deliveryBasket,
                                    )
                                }
                            >
                                <Icon name="truck" />
                            </button>
                        </div>
                    ) : row.stock > 0 ? (
                        <div className="productInfoBlock--infoBox--table--description--btn">
                            <button
                                className="btnTruck"
                                onClick={() =>
                                    this.handleAddDeliveryBasket(
                                        row,
                                        getActiveBasketName(),
                                    )
                                }
                            >
                                <Icon disabled name="shopping cart" />
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ),
        },
        {
            selector: 'barcode',
            notShow: true,
        },
        {
            selector: 'price',
            notShow: true,
        },
        {
            selector: 'productId',
            notShow: true,
            isUnique: true,
        },
        {
            selector: 'currency',
            notShow: true,
        },
        {
            selector: 'listPrice',
            notShow: true,
        },
        {
            selector: 'promotionDetails',
            notShow: true,
        },
        {
            selector: 'promoListPrice',
            notShow: true,
        },
        {
            selector: 'color',
            notShow: true,
        },
        {
            selector: 'collection',
            notShow: true,
        },
    ];

    negative = [
        {
            name: i18next.t('Terminal.dashboards.Category'),
            selector: 'category',
            sortable: true,
            searchable: true,
            width: '14%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.category}
                        </div>
                    }
                    content={row.category}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.model'),
            selector: 'model',
            sortable: true,
            searchable: true,
            center: true,
            width: '16%',
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'productName',
            sortable: true,
            searchable: true,
            center: true,
            width: '25%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            sortable: true,
            center: true,
            width: '15%',
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            sortable: true,
            center: true,
            width: '22%',
        },
        {
            name: i18next.t('Terminal.dashboards.stock'),
            selector: 'stock',
            sortable: true,
            searchable: true,
            center: true,
            width: '8%',
            cell: (row) => (
                <div className="stock--btn--truck">
                    <div>{row.stock}</div>
                    {row.stock === 0 ? (
                        <div className="productInfoBlock--infoBox--table--description--btn">
                            <button
                                className="btnTruck color--blue"
                                onClick={() =>
                                    this.handleAddDeliveryBasket(
                                        row,
                                        LOCAL_STORAGE.deliveryBasket,
                                    )
                                }
                            >
                                <Icon name="truck" />
                            </button>
                        </div>
                    ) : row.stock > 0 ? (
                        <div className="productInfoBlock--infoBox--table--description--btn">
                            <button
                                className="btnTruck"
                                onClick={() =>
                                    this.handleAddDeliveryBasket(
                                        row,
                                        getActiveBasketName(),
                                    )
                                }
                            >
                                <Icon disabled name="shopping cart" />
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            ),
        },
        {
            selector: 'productId',
            notShow: true,
            isUnique: true,
        },
    ];

    handleAddDeliveryBasket = (data, basket) => {
        const store =
            this.props.stores.find((item) => item.storeType === 'W') || {};
        this.props.getBarcodeProductStart({
            barcode: data.barcode,
            storeCode: store.storeCode,
            basket,
        });
        if (basket === getActiveBasketName()) {
            this.setState({ showBasket: true });
        } else {
            addDeliveryStore(store);
            this.setState({ showBasketDelivery: true });
        }
    };
    componentDidMount() {
        this.props.stockStart();
        this.props.storesStart();
        this.props.isStockDashboardStart(true);
        this.props.stockCountersStart();
    }
    componentWillUnmount() {
        this.props.isStockDashboardStart(false);
    }

    handleScan = (barcode, isSearch) => {
        const { barcodesList, negativeBarcodesList } = this.props;
        const barcodes =
            this.state.activeIndex === 3 ? barcodesList : negativeBarcodesList;
        const find = barcodes.find((i) => i.barcode === barcode);

        if (find && find.productId) {
            this.setState({
                selectedRow: {
                    id: find.productId,
                    fieald: 'productId',
                },
            });
            if (isSearch) {
                this.handleStockDetails(find.parentId);
            }
        }
    };

    handleStockDetails = (id) => {
        this.props.singleStockStart(id);
        this.props.productSoldStart(id);
        this.setState({ stocksPopUp: true });
    };

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.globalTab'),
            render: () => {
                const {
                    stockCounters: { brand, total },
                } = this.props;
                return (
                    <Tab.Pane attached={false}>
                        {Array.isArray(brand) && brand.length ? (
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table collaps"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.brand',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.stock',
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {brand.map((item, idx) => item.stock && (
                                        <Table.Row key={idx}>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.stock}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                        {total && Object.keys(total).length ? (
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table collaps"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.negativeStockBarcodeCount',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.totalBarcodeCount',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.positiveStockCount',
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {total.negativeStockBarcodeCount}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {total.positiveStockCount}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--description">
                                            {brand.reduce((acc, item) => acc + (+item.stock || 0), 0)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.categoryTab'),
            render: () => {
                const {
                    stockCounters: { category },
                    details: { storeDetails = {} } = {},
                } = this.props;
                const storeName =
                    Object.keys(storeDetails) && storeDetails.name;
                return (
                    <Tab.Pane attached={false}>
                        {category && Object.keys(category).length ? (
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table collaps"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell>
                                            <PdfTable
                                                disabled={false}
                                                classN="btn_1 w-100 blue_btn"
                                                columns={this.columnsPdf}
                                                data={
                                                    category &&
                                                    category.filter(
                                                        (elem) => elem.stock,
                                                    )
                                                }
                                                storeName={storeName}
                                            >
                                                {i18next.t('buttons.22')}
                                            </PdfTable>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.brand',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.stock',
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {category &&
                                        category
                                            .filter((elem) => elem.stock)
                                            .map((item) => (
                                                <Table.Row key={item.name}>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.name}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.stock}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.modelTab'),
            render: () => {
                const {
                    stockCounters: { model },
                    details: { storeDetails = {} } = {},
                } = this.props;
                const storeName =
                    Object.keys(storeDetails) && storeDetails.name;
                return (
                    <Tab.Pane attached={false}>
                        {model && Object.keys(model).length ? (
                            <Table
                                celled
                                selectable
                                className="productInfoBlock--infoBox--table collaps"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell></Table.Cell>
                                        <Table.Cell>
                                            <PdfTable
                                                disabled={false}
                                                classN="btn_1 w-100 blue_btn"
                                                columns={this.columnsPdf}
                                                data={
                                                    model &&
                                                    model.filter(
                                                        (elem) => elem.stock,
                                                    )
                                                }
                                                storeName={storeName}
                                            >
                                                {i18next.t('buttons.22')}
                                            </PdfTable>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.brand',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.stock',
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {model &&
                                        model
                                            .filter((elem) => elem.stock)
                                            .map((item) => (
                                                <Table.Row key={item.name}>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.name}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.stock}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.barcodeTab'),
            render: () => {
                const { isLoading, barcodesList } = this.props;
                const { selectedRow } = this.state;
                return (
                    <Tab.Pane attached={false}>
                        <TableComponent
                            tableData={barcodesList}
                            isLoading={isLoading}
                            openPopUp={() => {}}
                            columns={this.columns}
                            reloadDashboard={this.props.stockStart}
                            keyField={'exportStocks'}
                            selectedRow={selectedRow}
                            selectableRows={true}
                            buttons={['exportStockButtons', 'exportPDFbutton']}
                        >
                            <SearchBox
                                onSubmit={(barcode) =>
                                    this.handleScan(barcode, true)
                                }
                                label={`${i18next.t(
                                    'Terminal.dashboards.barcode',
                                )}:`}
                            />
                        </TableComponent>
                    </Tab.Pane>
                );
            },
        }
    ];

    render() {
        const { showBasket, showBasketDelivery, activeIndex, stocksPopUp } =
            this.state;

        return (
            <div>
                <Tab
                    menu={{ pointing: true }}
                    panes={this.panes}
                    onTabChange={(e, data) =>
                        this.setState({ activeIndex: data.activeIndex })
                    }
                />

                {(activeIndex === 3 || activeIndex === 4) && (
                    <BarcodeReader onScan={this.handleScan} />
                )}

                {showBasket && (
                    <MultiBasket
                        handleBasketToggle={() =>
                            this.setState({ showBasket: false })
                        }
                        history={this.props.history}
                    />
                )}
                {showBasketDelivery && (
                    <DeliveryBasketPopUp
                        handleBasketToggle={() =>
                            this.setState({ showBasketDelivery: false })
                        }
                    />
                )}

                {stocksPopUp && (
                    <StocksDetails
                        handleClosePopUp={(stocksPopUp) =>
                            this.setState({ stocksPopUp })
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    stockData: getStock(state),
    isLoading: getLoading(state),
    stores: getStores(state),
    stockCounters: getStockCounters(state),
    details: getAccountResource(state),
    negativeBarcodesList: getStockNegativeBarcodeList(state),
    barcodesList: getStockBarcodeList(state),
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportStocks);
