import i18next from 'i18next';
import React, { useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import CustomBtn from '../customBtn';
import './searchBox.scss';

interface IProps {
    onChange?: (e) => void;
    onSubmit?: (val, type?) => void;
    handleReset?: () => void;
    label?: string;
    loading?: boolean;
    type?: string;
    long?: boolean;
}

export default function SearchBox({
    onChange,
    onSubmit,
    handleReset,
    label = '',
    loading = false,
    type = '',
    long = false,
    ...rest
}: IProps) {
    const [value, setValue] = useState('');

    const handleChane = (e) => {
        setValue(e.target.value);
        onChange && onChange(e);
    };

    const handleKeyDown = (e) => {
        if (onSubmit && e.keyCode === 13) {
            type ? onSubmit(value, type) : onSubmit(value);
        }
    };

    const handleClick = () => {
        if (onSubmit) {
            type ? onSubmit(value, type) : onSubmit(value);
        }
    };

    return (
        <div className={`search-boxe-content ${long ? 'ml-10' : ''}`}>
            <div className={`ui icon input search-boxe-input ${long ? 'search-boxe-input-long' : ''}`}>
                {label && <label htmlFor="search">{label}</label>}
                <Input
                    id="search"
                    placeholder="Search..."
                    value={value}
                    onKeyDown={handleKeyDown}
                    onChange={handleChane}
                    loading={loading}
                    {...rest}
                />
                {!loading && (
                    <i
                        className="inverted circular search link icon iconSearch"
                        onClick={handleClick}
                    ></i>
                )}
            </div>
            {handleReset && (
                <CustomBtn
                    classN="btn_1 smallBtn reset-btn"
                    handleClick={() => {
                        setValue('');
                        handleReset();
                    }}
                >
                    {i18next.t('buttons.36')}
                </CustomBtn>
            )}
        </div>
    );
}
