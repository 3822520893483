import React from "react";
import {connect} from "react-redux";
import {Icon} from 'semantic-ui-react';
import i18next from "i18next";
import {Link} from "react-router-dom";
import Select from "../../common/select/select";
import {DEFAULT_CURRENCY_SYMBOL, PRODUCT_MAX_QUANTITY} from "../../../utils/constants/variables";
import {
    getBasketData,
    getLangPrefix,
    getActiveBasketName,
    getLocalStorageObject,
    changeActiveBasket,
    getBasketClientIdByName,
    getPercentFormat,
    priceThreeDigitSets,
    replaceDote,
    addNewBasket,
    removeBasketCoupon,
    getBasketProductsBySizes, sortBasketByDate, getTotalSum,
} from '../../../helpers/helpers';
import Photo from "../../common/product/photo";
import Price from "../../common/product/price";
import {getConnectedStatus, getMenu} from "../../../layouts/main/header/redux/selectors";
import history from "./../../../helpers/history";
import {AddToCartActionCreators} from "../../addToCart/redux/actions";
import {bindActionCreators} from "redux";
import BlackWindow from "../../common/blackWindow";
import {getBasket} from "../../addToCart/redux/selectors";
import {getStoreClient} from "../../clients/redux/selectors";
import {replaceComma} from "../../../helpers/helpers";
import closeIcon from "../../../assets/img/close.svg";
import shoppingCartICon from "../../../assets/img/shoppingCartICon_white.svg";
import "./basketPopup.scss";

interface Props {
    handleBasketToggle?: any,
    menuList: any,
    basketProps: any,
    basketName?: string,
    history? :any,
    storeClient?: any,
    isDisconnected: boolean
}

const options: any[] = [];
for (let i = 1; i <= PRODUCT_MAX_QUANTITY; i++) {
    options.push({value: i.toString(), text: i.toString()});
}

class BasketPopup extends React.Component<Props & typeof AddToCartActionCreators> {

    componentDidMount() {
        const {basketName} = this.props;
        const currentBasketName = basketName || getActiveBasketName()
        const clientId = getBasketClientIdByName(currentBasketName);
        if(clientId && !this.props.storeClient[currentBasketName]) {
            this.props.storeClientStart(clientId, currentBasketName)
        }
    }

    handleBasketToggle = (show,action="") => {
        if(action==="retour"){
           history.push("/");
        }
        this.props.handleBasketToggle(show);
    };
    handleChangeQuantity = (name, value) => {
        const ids = name.split("/");
        this.props.addtocartStart(this.props.basketName || getActiveBasketName(), ids[0], {size: {quantity: value, productId: ids[1]}}, true);
    };
    handleRemoveItem = (productId, sizeElementProductId) => {
        this.props.removeBasketStart(this.props.basketName || getActiveBasketName(), productId, sizeElementProductId)
    };

    handleChangeBasket = (name) => () => {
        if(name) {
            changeActiveBasket(name)
            this.handleBasketToggle(false);
            this.props.getBasketStart();
            this.props.basketStoreGiftsStart();
        }
    };

    handleRemoveClientFromBasket = (name) => () => {
        this.props.resetStoreClient(name);
    };

    handleAddNewBasket = () => {
        addNewBasket()
        this.props.getBasketStart();
        this.props.basketStoreGiftsStart();
        this.handleBasketToggle(false);
    }

    handleDestroyBasket = (name) => {
        this.props.resetStoreClient(name);
        localStorage.removeItem(name);
        this.props.getBasketStart();
    }
    removecoupons = (discountCode) => {
        removeBasketCoupon(discountCode)
        this.props.getBasketStart(getActiveBasketName())
    }

    render() {
        const { basketProps, basketName, storeClient, isDisconnected } = this.props;
        const currentBasketName = basketName || getActiveBasketName();
        const basketClient = storeClient[currentBasketName] && storeClient[currentBasketName].profile ? storeClient[currentBasketName].profile : {};
        const basket = basketName ? getLocalStorageObject(basketName) : basketProps
        const {totalQuantity, price, ticketNumber} = getBasketData(basket);

        return (
            <>
                {!basketName && <BlackWindow type="page" handleClick={() => this.handleBasketToggle(false)}/>}
                <div className={`basket-popup active ${!basketName || getActiveBasketName() === basketName ? "activeBasket" : ""}`}>
                    <div className="close" onClick={() => this.handleBasketToggle(false)}>
                        <img src={closeIcon}/>
                    </div>
                    <div className="basket-popup-content">
                        <div className="basket-popup-shop-title">
                            <div className="shoppingBox">
                                <img src={shoppingCartICon}/>
                                <h4 className="shop-title">
                                    {
                                        ticketNumber ?
                                            i18next.t('product.basket.28', {ticketNumber})
                                            :
                                            `${totalQuantity} ${totalQuantity < 2 ? i18next.t('product.basket.22') : i18next.t('product.basket.1')}`
                                    }
                                </h4>
                            </div>
                            {!basketName || getActiveBasketName() === basketName ? <img src={closeIcon} onClick={() => this.handleBasketToggle(false)}/>:""}
                        </div>

                        <h4 className="basket-popup-title">
                            {
                                basketClient.firstName || basketClient.lastName ?
                                    <>
                                        <span>{basketClient.firstName} {basketClient.lastName}</span>
                                        <Icon name="trash alternate"
                                              onClick={this.handleRemoveClientFromBasket(currentBasketName)}></Icon>
                                    </>
                                :
                                ""
                            }
                        </h4>

                        <div className="articles-content">

                            <div className="d-between">
                                <h4 className={`articles-content-title ${totalQuantity< 0 ? "revert":""}`}>{replaceDote(totalQuantity)} {totalQuantity < 2 ? i18next.t('product.basket.23') : i18next.t('product.basket.12')} </h4>
                                <h4 className={`articles-content-price ${price< 0 ? "revert":""}`}>{i18next.t('product.parure.5')} : <span>{replaceComma(price) + DEFAULT_CURRENCY_SYMBOL}</span>
                                </h4>
                            </div>

                            <button
                                className="articles-content-button"

                                onClick={this.handleChangeBasket(basketName)}
                            >
                                {i18next.t('product.basket.2')}
                            </button>
                            <div className="d-between ">
                                <Icon name="trash alternate" className='destory-basket-icon' onClick={() => this.handleDestroyBasket(currentBasketName)} />
                                <button
                                    onClick={() => this.handleBasketToggle(false,"retour")}
                                    className="articles-content-button  w-100"
                                >
                                    {i18next.t('buttons.23')}
                                </button>
                            </div>

                            {isDisconnected && <button
                                    onClick={this.handleAddNewBasket}
                                    className="articles-content-button  w-100"
                                >
                                    {i18next.t('buttons.24')}
                                </button>
                            }


                        </div>

                        <div className="product-content">
                            {
                                basket && basket.coupons && basket.coupons.length ?
                                    basket.coupons.map(({ discountCode, couponType, value, valid }) => {
                                        return (
                                            <div className="product-content-item" key={discountCode}>
                                                <div className="product-content-item-body">
                                                    <div className="product-content-item-body-title">
                                                        <div className="basketGift">
                                                            <span className="title">{discountCode}</span>
                                                            <span className={`price revert`}>
                                                                 {couponType.toUpperCase() === "PERCENT"
                                                                     ? getPercentFormat( -value)
                                                                     : priceThreeDigitSets( -value)}
                                                            </span>
                                                            <Icon name="trash alternate" onClick={() => this.removecoupons(discountCode)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                            {
                                basket && basket.storeGifts && basket.storeGifts.length ?
                                    basket.storeGifts.map(({ storeGiftCode, percent, amount, active }) => {
                                        return (
                                            <div className="product-content-item" key={storeGiftCode}>
                                                <div className="product-content-item-body">
                                                    <div className="product-content-item-body-title">
                                                        <div className="basketGift">
                                                            <span className="title">{storeGiftCode}</span>
                                                            <span className={`price ${active ? "revert" :""}`}>
                                                                {percent ?
                                                                    getPercentFormat(active ? -percent : percent)
                                                                    :
                                                                    priceThreeDigitSets( active ? -amount : amount)
                                                                }
                                                            </span>
                                                            <Icon name="trash alternate" onClick={() => this.props.removeBasketGiftsStart(storeGiftCode)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                            {getBasketProductsBySizes(basket).sort((a,b)=>sortBasketByDate(a,b)).map((item, i) => {
                                return (
                                    <div className="product-content-item" key={i}>
                                        <div className="product-content-item-img">

                                            <Link onClick={() => this.handleBasketToggle(false)}
                                                  to={`${getLangPrefix()}${basket[item.parentID].canonicalMenu}/${basket[item.parentID].canonicallUrl}`}>
                                                {basket[item.parentID].photo ? <Photo src={basket[item.parentID].photo} discountDetail={""}/> :""}
                                            </Link>
                                        </div>
                                        <div className="product-content-item-body">
                                            <div className="product-content-item-body-title">
                                                <h4 className="product-content-item-body-title-text">
                                                    <span className="title">{basket[item.parentID].name}</span>
                                                    <span>{item.size}</span>
                                                </h4>
                                                {!ticketNumber && <Icon name="trash alternate" onClick={() => this.handleRemoveItem(item.parentID, item.productId)}/>}
                                            </div>
                                            <div className="product-content-item-body-footer">
                                                <div className="product-content-item-body-footer-select">
                                                    <p className="text">{i18next.t('product.basket.summary.2')}</p>
                                                    <Select name={`${item.parentID}/${item.productId}`}
                                                            value={replaceDote(item.quantity || 0)}
                                                            options={options}
                                                            handleChange={this.handleChangeQuantity}/>
                                                </div>

                                                <div className="product-content-item-body-footer-price">
                                                    {
                                                        item.qtyDiscount ?
                                                            <Price
                                                                startP={item.startingPrice}
                                                                discount={item.discountDetail}
                                                                newP={getTotalSum(item.qtyDiscount, "listPriceAfterDiscount")}
                                                                quantity={1}
                                                                isPromo={item.isPromo}
                                                            />
                                                            :
                                                            <Price
                                                                startP={item.startingPrice}
                                                                discount={item.discountDetail}
                                                                newP={item.listPriceAfterDiscount}
                                                                quantity={item.quantity}
                                                                isPromo={item.isPromo}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )})
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
    basketProps: getBasket(state),
    storeClient: getStoreClient(state),
    isDisconnected: getConnectedStatus(state)
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators(AddToCartActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketPopup);
