import {livraisonClientTypes, livraisonClientActionCreators} from "./actions";
import {takeLatest, put, call, select} from "redux-saga/effects";
import axios from 'axios';
import {
    getAxiosHeaders,
    STORE_WEB_CLIENTS_URL,
    WEB_ORDER_API,
    CREATE_CLIENT_SHIPPING,
    CREATE_ORDER_SHIPMENT,
    CREATE_CLIENT_BOOK,
    setOrderStatus
} from '../../../utils/constants/service';
import { getApiPrefix } from "../../../helpers/helpers"
import { getAccountResource } from "../../login/redux/selectors";


export function* getILivraisonClientData() {
    try {
        const accountResource = yield select(getAccountResource);

        const brendId = (
            accountResource &&
            accountResource.storeDetails &&
            accountResource.storeDetails.brandId &&
            accountResource.storeDetails.brandId
        );

        const data = yield call(() => {
            return axios.get(`${WEB_ORDER_API}?language=${getApiPrefix()}${brendId !== undefined ? `&serviceBrandId=${brendId}` : ''}`, getAxiosHeaders())
                .then(res => res.data)
        });
        yield put(livraisonClientActionCreators.livraisonClientSuccess(data));
    } catch (e) {
        yield put(livraisonClientActionCreators.livraisonClientFailure(e.message));
    }
}
export function* getILivraisonClientSingleData(params) {
    const privateToken = sessionStorage.getItem("privateToken");
    const {webClientId,shipAddressXrefId,billAddressXrefId} =params.data;
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`
            }
        };
        const data = yield call(() => {
            return axios.get(`${STORE_WEB_CLIENTS_URL}/${webClientId}?billAddressXrefId=${billAddressXrefId}&shipAddressXrefId=${shipAddressXrefId}`, headers)
                .then(res => res.data)
        });
        yield put(livraisonClientActionCreators.livraisonClientSingleSuccess(data));
    } catch (e) {
        yield put(livraisonClientActionCreators.livraisonClientSingleFailure(e.message));
    }
}
export function* createShipping(params) {
    const {shipmentData, orderBody} = params.data;
    try {
        const data = yield call(() => {
            return axios.post(CREATE_CLIENT_SHIPPING, shipmentData, getAxiosHeaders())
                .then(res => res.data)
        });

        const { body = {} } = data;
        orderBody.carrierCode = body.TrackingCarrier;
        orderBody.labelUrl = body.LabelURL;
        orderBody.packageNumber = body.TrackingNumber;
        orderBody.shippingConfirmation = body.NewOrderID;
        let result = yield call(() => {
            return axios.post(CREATE_ORDER_SHIPMENT, orderBody, getAxiosHeaders())
            .then((res) => res);
        });
        if(result.status !== 200) {
            result = yield call(() =>
                setOrderStatus(body.NewOrderID, "REA")
            );
        }
        if(result.data || result) yield put(livraisonClientActionCreators.livraisonClientStart());

        yield put(livraisonClientActionCreators.livraisonClientShippingSuccess(data));
    } catch (e) {
        yield put(livraisonClientActionCreators.livraisonClientShippingFailure(e.message));
    }
}

export function* createBook(params) {
    try {
        const {
            data:
            { bookRequest, orderBody } = { bookRequest: {}, orderBody: {} }
        } = params;
        let result = yield call(() => {
            return axios.post(CREATE_CLIENT_BOOK, bookRequest, getAxiosHeaders())
                .then(res => res.data)
        });
        const { body } = result || { body: {} };
        if(body.Result === "OK") {
            orderBody.pickupConfirmation = +body.ConfirmationID;

            result = yield call(() => {
                return axios.post(CREATE_ORDER_SHIPMENT, orderBody, getAxiosHeaders())
                .then((res) => res.data);
            });
            if(result) yield put(livraisonClientActionCreators.livraisonClientStart());
        }
        yield put(livraisonClientActionCreators.livraisonClientBookSuccess(body));
    } catch (e) {
        yield put(livraisonClientActionCreators.livraisonClientBookFailure(e.message));
    }
}

export function* livraisonClientSaga() {
    yield takeLatest(livraisonClientTypes.LIVRAISON_CLIENT_START, getILivraisonClientData);
    yield takeLatest(livraisonClientTypes.LIVRAISON_CLIENT_SINGLE_START, getILivraisonClientSingleData);
    yield takeLatest(livraisonClientTypes.LIVRAISON_CLIENT_SHIPPING_START, createShipping);
    yield takeLatest(livraisonClientTypes.LIVRAISON_CLIENT_BOOK_START, createBook);
}

export default livraisonClientSaga;
