import i18next from "i18next";

const changeHours = () => {
    const date = new Date();
    const jan = new Date(date.getFullYear(), 0, 1);
    return date.getTimezoneOffset() < jan.getTimezoneOffset();
}

const getTimeOfLastWeekSunday = (name, year = new Date().getFullYear()) => {
    const month = name === 'oct' ? 9 : 2;
    const date = new Date(year, month + 1, 0);
    const day = date.getDate() - date.getDay();
    return new Date(year, month, day).getTime();
}

const isWinterTime = () => {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    return (
        currentMonth > 8 && currentTime > getTimeOfLastWeekSunday('oct', currentYear) ||
        currentMonth < 3 && currentTime <  getTimeOfLastWeekSunday('march', currentYear)
    );
}

export const changeFormatHourly = (param) => {
    if(!param) return '00:00'
    const num = isWinterTime() ? +param : +param + 1;
    const hour1 = num > 9 ? `${num}:00` : `0${num}:00`
    const hour2 = num + 1 > 9 ? `${num+1}:00` : `0${num+1}:00`
    return `${hour1}-${hour2}`
}

export const getDiscountDetail = (item, currencySymbol) => {
    try {
        const discountDetail = Math.abs(parseFloat(item.discountDetail))
        const specialDiscountDetail = Math.abs(parseFloat(item.specialDiscountDetail))

        if(!specialDiscountDetail && !discountDetail) return '';

        if(discountDetail) {
            if (item.discountDetail.includes("%")) {
                return discountDetail + '%'
            }else {
                return formatTwoDecimals(discountDetail) + ' ' + currencySymbol
            }
        }

        if (item.specialDiscountPercent === "Y") {
            return specialDiscountDetail + '%'
        }
        else {
            return formatTwoDecimals(specialDiscountDetail) + ' ' + currencySymbol
        }
    } catch (e) {
        return ""
    }

}

const formatTwoDecimals = (num: number) => {
    try {
        if(isNaN(Number(num))) throw new Error("")
        return Number(num).toFixed(2).replace(".", ",");
    } catch (e) {
        return "0,00";
    }
}

export const getDateByTimestamp = (timestamp, isHours = true) => {
    try {
        const date = new Date(timestamp)
        const hour = changeHours() ? date.getHours() : date.getHours() + 1 > 23 ? 0 : date.getHours() + 1;
        const year = date.getFullYear();
        const month = i18next.t(`Terminal.months.${date.getMonth()}`);
        const day = ('0' + date.getDate()).slice(-2);
        const dayName = i18next.t(`Terminal.days.${date.getDay()}`);
        const hours = ('0' + hour).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        if(isHours) {
            return `${dayName} ${day} ${month} ${year} ${hours}h${minutes}`;
        }

        return `${dayName} ${day} ${month} ${year}`;
    } catch (e) {
        return '';
    }
};