import React, { Component } from 'react';
import i18next from 'i18next';
import { Tab, Table } from 'semantic-ui-react';
import { Text } from '@react-pdf/renderer';
import {
    addDays,
    getCurrency,
    getDateTimestamp,
    getDateTimestampByTime,
    getUserFromToken,
    replaceComma,
} from '../../../../helpers/helpers';
import CustomBtn from '../../../common/customBtn';
import {
    DEFAULT_CURRENCY,
    GIFT_DURATION,
    LOGISTIC_STATUS_CODE,
} from '../../../../utils/constants/variables';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import PdfTable from '../../../pdfGenerator/pdfTableBtn';

interface Props {
    data: any;
    createGift: (b) => void;
    handleClosePopUp: () => void;
    details: any;
}

interface State {
    showConfirm: boolean;
    dataPdf: any[]
}

class ReturnDetails extends Component<Props> {
    state: State = {
        showConfirm: false,
        dataPdf: []
    };

    columnsPdf: Array<{
        name: string,
        selector: string,
        width: string,
    }> = [
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '30%',
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '18%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.quantity'),
            selector: 'quantity',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.stock'),
            selector: 'stock',
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.prix'),
            selector: 'prix',
            width: '13%',
        },
    ];

    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.1'),
            render: () => {
                const { data } = this.props;
                return (
                    <Tab.Pane attached={false}>
                        <div className="card">
                            <div className="popUp--body--card--body">
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.firstName',
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.contactName
                                            ? `${data.contactName}`
                                            : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t('Terminal.dashboards.email')}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.contactEmail
                                            ? data.contactEmail
                                            : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t('Terminal.dashboards.phone')}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.contactPhone
                                            ? data.contactPhone
                                            : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.clientCode',
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.clientCode
                                            ? `${data.clientCode}`
                                            : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.orderId',
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.orderId ? `${data.orderId}` : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.ticketStatus',
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.ticketStatus
                                            ? `${data.ticketStatus}`
                                            : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.appointmentDate',
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.appointmentDate
                                            ? `${data.appointmentDate}`
                                            : ''}
                                    </div>
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header">
                                        {i18next.t(
                                            'Terminal.dashboards.message',
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'popUp--body--card--body--data--description disabled textBox'
                                        }
                                    >
                                        {data.ticketMessage
                                            ? `${data.ticketMessage}`
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: i18next.t('Terminal.dashboards.productReturn'),
            render: () => {
                const { data, details } = this.props;
                const { products } = data;
                const { details: { storeDetails = {} } = {} } = this.props;
                const dataPdf = products.map((item) => ({
                    name: item.productRefLevel1,
                    barcode: item.barcode,
                    size: item.size,
                    quantity: item.quantity,
                    stock: item.stock,
                    prix: replaceComma(item.listPrice) + getCurrency(),
                }));
                return (
                    <Tab.Pane attached={false}>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.returnReason')}
                            </div>
                            <div
                                className={
                                    'popUp--body--card--body--data--description disabled textBox'
                                }
                            >
                                {data.returnReason
                                    ? `${data.returnReason}`
                                    : ''}
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.returnStatus')}
                            </div>
                            <div
                                className={
                                    'popUp--body--card--body--data--description disabled textBox'
                                }
                            >
                                {data.returnStatus
                                    ? `${data.returnStatus}`
                                    : ''}
                            </div>
                        </div>
                        {products && products.length ? (
                            <>
                                <Table
                                    celled
                                    selectable
                                    className="productInfoBlock--infoBox--table"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.Brand',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.name',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Transfer.barcode',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Transfer.Qty',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.dashboards.price',
                                                )}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--title">
                                                {i18next.t(
                                                    'Terminal.Receipt.Transfer.Total',
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {products.map((item: any, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell
                                                        className={`productInfoBlock--infoBox--table--description`}
                                                    >
                                                        {item.brandName}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.productRefLevel1}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.barcode}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {item.quantity}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {replaceComma(
                                                            item.listPrice,
                                                        ) +
                                                            getCurrency(
                                                                item.item,
                                                            )}
                                                    </Table.Cell>
                                                    <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                        {replaceComma(
                                                            item.quantity *
                                                                item.listPrice,
                                                        ) +
                                                            getCurrency(
                                                                item.item,
                                                            )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                                <div className="dflex justify-content-end">
                                    <PdfTable
                                        disabled={false}
                                        classN="btn_1 w-50 blue_btn"
                                        columns={this.columnsPdf}
                                        data={dataPdf}
                                        ID={data.orderId}
                                        currentDate={getDateTimestamp(
                                            data.ticketCreatedDate,
                                        )}
                                        storeName={storeDetails.name}
                                        associateFirstName={getUserFromToken().fname}
                                        header={this.getPdfHeader()}
                                    >
                                        {i18next.t('buttons.29')}
                                    </PdfTable>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </Tab.Pane>
                );
            },
        },
    ];

    handleCreateGift = () => {
        const { data: { products = [] } = {}, handleClosePopUp } = this.props;
        if (Array.isArray(products) && products.length) {
            const amount = this.props.data.products.reduce(
                (acc, item) => acc + item.quantity * item.listPrice,
                0,
            );

            const body = {
                amount,
                percent: null,
                active: true,
                barcode: '',
                storeClientId: null,
                credit: true,
                currency: products[0].currency || DEFAULT_CURRENCY,
                startDate: Date.now(),
                endDate: addDays(new Date(), GIFT_DURATION).getTime(),
                qrCode: null,
                redemptionDate: null,
                storeGiftId: null,
                ticketId: this.props.data && this.props.data.ticketId || 0
            };

            this.props.createGift(body);
            this.setState({ showConfirm: false });
            handleClosePopUp();
        }
    };

    getPdfHeader = () => {
        const { data, details: { storeDetails = {} } } = this.props;
        if (Object.keys(data).length) {
            const addressTitle = [];
            const address: object[] = [];
            ['city', 'zip', 'country', 'address', 'address2'].forEach(
                (item) => {
                    addressTitle.push(i18next.t(`Terminal.dashboards.${item === 'address' ? item + 1 : item}`));
                    address.push(storeDetails[item]);
                },
            );
            return [
                [
                    storeDetails.name,
                    <Text style={{ textAlign: 'right' }}>
                        {getUserFromToken().fname}
                    </Text>,
                ],
                [],
                [
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: 15,
                            fontWeight: 'bold',
                        }}
                    >
                        {`${i18next.t('Terminal.Receipt.Transfer.return')} ${
                            data.orderId
                        }`}
                    </Text>,
                ],
                [],
                [i18next.t('Terminal.dashboards.firstName'), data.contactName],
                [i18next.t('Terminal.dashboards.lastName'), data.lastName],
                [i18next.t('Terminal.dashboards.email'), data.contactEmail],
                [i18next.t('Terminal.dashboards.phone'), data.  contactPhone,],
                [],
                [],
                addressTitle,
                address,
                [],
                [],
                [
                    <Text
                        style={{
                            textTransform: 'capitalize',
                            fontSize: 13,
                            fontWeight: 'bold',
                        }}
                    >
                        {`${i18next
                            .t('Terminal.Receipt.Transfer.date')
                            .toUpperCase()} ${getDateTimestampByTime(
                            Date.now(),
                        )}`}
                    </Text>,
                ],
                [
                    <Text
                        style={{
                            textTransform: 'capitalize',
                            fontSize: 13,
                            fontWeight: 'bold',
                        }}
                    >
                        {i18next.t('Terminal.Receipt.Transfer.signature')}
                    </Text>,
                ],
                [],
                [],
            ];
        }
        return [];
    }

    render() {
        return (
            <>
                <Tab menu={{ pointing: true }} panes={this.panes} />
                {this.state.showConfirm && (
                    <ConfirmPopup
                        handleConfirm={this.handleCreateGift}
                        handleCancel={() =>
                            this.setState({ showConfirm: false })
                        }
                        text={i18next.t('Terminal.confirm.4')}
                    />
                )}
            </>
        );
    }
}

export default ReturnDetails;
