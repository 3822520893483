import { getLocalFormData } from "../../helpers/helpers";

export interface ShippingParcelInterface {
    length: number,
    width: number,
    height: number,
    weight: number,
}

export interface AddressInterface {
    name: string,
    company: string,
    street1: string,
    street2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    phone: string,
    email: string
}

export interface ShippmentParamsInterface {
    to_address: AddressInterface,
    from_address: AddressInterface,
    parcels: ShippingParcelInterface[],
    TotalValue: string,
    TransactionID: string,
    ContentDescription: string,
    Insurance: number,
    InsuranceCurrency: string,
    CashOnDelivery: number,
    CashOnDeliveryCurrency: number,
    CashOnDeliveryType: number,
    CarrierName: string,
    CarrierService: string,
    CarrierID: number,
    OrderID: string,
    RateID: string,
    Incoterm: string,
    BillAccountNumber: string,
    PaymentMethod: string,
    Note: string,
    Async: boolean,
}

export interface ShippingRequestInterface {
    Method: "Ship" | "BookPickup",
    Params: ShippmentParams | BookPickupParamsInterface
}

export interface ShipmentOrderInterface {
  carrierCode: string,
  carrierId: number,
  comment: string,
  labelUrl: string,
  orderId: number,
  packageNumber: string,
  pickupConfirmation: number,
  pickupTime: number,
  shipmentDate: number,
  shippedQuantity: number,
  shippingConfirmation: number,
  weight: number,
  storeTransferCode?: string,
}

export interface BookPickupParamsInterface {
    // OrderId: string,
    CarrierID: number,
    CarrierName: string,
    PickupMorningMintime: string,
    PickupMorningMaxtime: string,
    PickupAfternoonMintime: string,
    PickupAfternoonMaxtime: string,
    PickupTime: number,
    PickupNote: string,
    to_address: AddressInterface,
    from_address: AddressInterface,
    parcels: ShippingParcelInterface[],
    OrderIds: string,
}

export class ShippingParcel implements ShippingParcelInterface {
    constructor(
        public length: number = 39,
        public width: number = 29,
        public height: number = 10,
        public weight: number = 1,
    ){}
}

export class Address implements AddressInterface {
    constructor(
        public name: string = "",
        public company: string = "",
        public street1: string = "",
        public street2: string = "",
        public city: string = "",
        public state: string = "",
        public zip: string = "",
        public country: string = "",
        public phone: string = "",
        public email: string = ""
    ){}

    public static createAddressData(data: any = {}) {
        return new Address(
            data.name,
            data.company || data.brandName,
            data.address1 || data.address,
            data.address2,
            data.city,
            "",
            data.zip,
            Address.getValidCountry(data.country),
            data.phone,
            data.email
        );
    }

    public static getValidCountry (text: string) {
        const { countries = []} = getLocalFormData();
        const selectedCountry = countries.find((country) => country.code === text || country.name === text);
        return selectedCountry ? selectedCountry.code : text;
    }
}

export class ShippmentParams implements ShippmentParamsInterface {
    constructor(
       public to_address: AddressInterface = new Address(),
       public from_address: AddressInterface = new Address(),
       public parcels: ShippingParcelInterface[] = [],
       public TotalValue: string = "",
       public TransactionID: string = "",
       public ContentDescription: string = "",
       public Insurance: number = 0,
       public InsuranceCurrency: string = "",
       public CashOnDelivery: number = 0,
       public CashOnDeliveryCurrency: number = 0,
       public CashOnDeliveryType: number = 0,
       public CarrierName: string = "",
       public CarrierService: string = "",
       public CarrierID: number = 0,
       public OrderID: string = "",
       public RateID: string = "",
       public Incoterm: string = "",
       public BillAccountNumber: string = "",
       public PaymentMethod: string = "",
       public Note: string = "",
       public Async: boolean = false,
    ){}

    public static createParamsData(data: any) {
        return new ShippmentParams(
            data.toAddress,
            data.fromAddress,
            data.parcels,
            `${data.totalSales} ${data.currency}`,
            data.orderId,
            data.orderLines && data.orderLines.length && data.orderLines[0].productNameLevel1 || "",
            0,
            data.currency,
            0,
            data.currency,
            0,
            data.CarrierName,
            data.CarrierService,
            +data.CarrierID,
            data.OrderID,
            data.RateID,
            data.Incoterm,
            data.BillAccountNumber,
            data.PaymentMethod,
            data.Note,
            data.Async,
        );
    }
}

export class BookPickupParams implements BookPickupParamsInterface {
    constructor (
        // public OrderId: string = "",
        public CarrierID: number = 0,
        public CarrierName: string = "",
        public PickupMorningMintime: string = "",
        public PickupMorningMaxtime: string = "",
        public PickupAfternoonMintime: string = "",
        public PickupAfternoonMaxtime: string = "",
        public PickupTime: number = 0,
        public PickupNote: string = "",
        public to_address: AddressInterface = new Address(),
        public from_address: AddressInterface = new Address(),
        public parcels: ShippingParcelInterface[] = [],
        public OrderIds: string = ''
    ) {}
}

export class ShippingRequest implements ShippingRequestInterface {
    constructor(
       public Method: "Ship" | "BookPickup" = "Ship",
       public Params: ShippmentParamsInterface | BookPickupParamsInterface = new ShippmentParams()
    ){}
}

export class ShipmentOrder implements ShipmentOrderInterface {
    public storeTransferCode;
    constructor(
        public carrierCode: string = '',
        public carrierId: number = 0,
        public comment: string = '',
        public labelUrl: string = '',
        public orderId: number = 0,
        public packageNumber: string = '',
        public pickupConfirmation: number = 0,
        public pickupTime: number = 0,
        public shipmentDate: number = 0,
        public shippedQuantity: number = 0,
        public shippingConfirmation: number = 0,
        public weight: number = 0,
    ) {}
}