import React from "react";
import {connect} from "react-redux";
import {Accordion, Icon, Table} from 'semantic-ui-react';
import {getMenu} from "../../../../layouts/main/header/redux/selectors";
import {getHome} from "../../../home/redux/selectors";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import {HomeActionCreators} from "../../../home/redux/actions";
import BlackWindow from "../../../common/blackWindow";

import closeIcon from "./../../../../assets/img/close.svg";

interface Props {
    toggleStoreLocator?: any,
    menuList?: any,
    data: any,
    activeIndex?: any,
}

interface State {
    showStoreLocator: boolean,
    activeIndex?: any,

}


class SizeGuide extends React.Component<Props & typeof HomeActionCreators> {
    state: State = {
        showStoreLocator: false,
        activeIndex: -1,
    };

    componentDidMount() {
        this.props.dispatch(HomeActionCreators.homeStart(37))
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    render() {
        const {activeIndex} = this.state;
        const texts = this.props.data && this.props.data.length ? this.props.data.filter(item => item.type.toUpperCase() === "TEXT") : [];
        const photos = this.props.data && this.props.data.length ? this.props.data.filter(item => item.type.toUpperCase() === "PHOTO") : [];
        return (
            <div className="storeLocator">
                <BlackWindow type="page" handleClick={this.props.toggleStoreLocator}/>
                <div className="storeLocator-content container">
                    <div className="storeLocator-content-head">
                        <h4 className="storeLocator-content-head-title">Guide des tailles</h4>
                        <img src={closeIcon} onClick={this.props.toggleStoreLocator}  alt="PHOTO"/>
                    </div>
                    <div className="storeLocator-wrapper">
                        <div className="container">
                            <div className="mentions-page">
                                <div className="">
                                    {
                                        texts && texts.length ?
                                            texts.sort((a, b) => (a.section - b.section))
                                                .map((item, index) => {
                                                    return <Accordion key={index}>
                                                        <Accordion.Title active={activeIndex === index}
                                                                         onClick={(e) => this.handleClick(e, {index: index})}>{item.name}</Accordion.Title>
                                                        <Accordion.Content active={activeIndex === index}>
                                                            <div dangerouslySetInnerHTML={{__html: item.value}}></div>

                                                            {
                                                                photos && photos.length ? photos.map((itemP, index) => {
                                                                    if (itemP.name === item.name) {
                                                                        return <img key={index}
                                                                                    src={BASE_API_GATEWAY + itemP.value} alt={itemP.altText || "PHOTO"}/>
                                                                    }
                                                                }) : ""
                                                            }
                                                        </Accordion.Content>
                                                    </Accordion>
                                                }) : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state: any): Props => ({
    data: getHome(state),
    menuList: getMenu(state)
});

export default connect(mapStateToProps)(SizeGuide)