import React from 'react'
import i18next from "i18next";
import {Form} from "semantic-ui-react";


const FormStep1 =({handleSubmit, handleChange, user}) => {
    const {username, password} = user
    return (
        <Form className="validation validation-form" onSubmit={handleSubmit}>
            <p className='title'>{i18next.t('Terminal.login.1')}</p>
            <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder={i18next.t('Terminal.login.2')}
                name="username"
                value={username}
                onChange={handleChange}
                className="login-input"
            />
            <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder={i18next.t('Terminal.login.3')}
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                className="login-input"
            />
            <div className="input-wrapper checkbox-custom text-left">
                <Form.Checkbox
                    className='input-box'
                    inline
                    label={i18next.t("Terminal.login.4")}
                    name='rememberMe'
                    onChange={handleChange}
                />
            </div>

            <div className="col-xs-12 padding_none">
                <button className="login-btn">{i18next.t('Terminal.login.5')}</button>
            </div>
        </Form>
    )
}

export default FormStep1