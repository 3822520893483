import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { SalesActionCreators } from '../../redux/actions';
import {
    addPaperReceiptProduct,
    getEmptyBasketName,
} from '../../../../helpers/helpers';
import history from '../../../../helpers/history';
import {
    DEFAULT_CURRENCY,
    RECEIPT_TYPE,
    setStoreData,
    STORE_COUNTRIES,
    TICKET_TYPE,
} from '../../../../utils/constants/variables';
import SalesProducts from './salesProducts';
import InfoPopUp from '../../../common/infoPopUp';
import { bindActionCreators } from 'redux';
import { getLoadingPaperReceipt, getPaperReceipt } from '../../redux/selectors';
import { getAccountResource } from '../../../login/redux/selectors';
import { FatturaBtn } from '../../../pdfGenerator/fatturaPdf';
import CustomBtn from '../../../common/customBtn';
import { getMenu } from '../../../../layouts/main/header/redux/selectors';

interface Props {
    data: any;
    paperReceipt: any;
    isLoadingPaperReceipt: boolean;
    details: object;
}

interface State {
    open: boolean;
    message: string;
    showProducts: boolean;
    showProductsPanel: boolean;
    isClickedPaperReceipt: boolean;
}

class SalesButtons extends Component<
    Props & typeof SalesActionCreators,
    State
> {
    state: State = {
        open: false,
        message: '',
        showProducts: false,
        showProductsPanel: false,
        isClickedPaperReceipt: false,
    };
    componentDidMount(): void {
        const showProductsPanel =
            this.props &&
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.showProductsPanel;
        if (showProductsPanel) {
            this.setState({ showProductsPanel });
            this.handleProducts();
            this.props.history.replace({ state: { showProductsPanel: false } });
        }
    }

    componentDidUpdate(prevProps, prevState): void {
        if (
            this.state.isClickedPaperReceipt &&
            prevProps.isLoadingPaperReceipt &&
            !this.props.isLoadingPaperReceipt &&
            this.props.paperReceipt &&
            this.props.paperReceipt.ticketNumber
        ) {
            const isValid = addPaperReceiptProduct(this.props.paperReceipt);
            if (isValid && isValid !== "No storeSaleLineItemTicketList") {
                const { currency, currencySymbol } = this.props.paperReceipt;
                if (DEFAULT_CURRENCY !== currency) {
                    setStoreData(currency, currencySymbol);
                    this.props.getCurrentDaySaleStart();
                }
                history.push('/');
            } else {
                isValid !== "No storeSaleLineItemTicketList" && this.setState({
                    open: true,
                    message: i18next.t('Terminal.confirm.11'),
                });
            }
        }
    }

    handlePaperReceipt = () => {
        const { data = {} } = this.props;
        const { ticketNumber = '', currency } = data;
        const receiptType = RECEIPT_TYPE.ticket;
        const basketName = getEmptyBasketName();

        if (basketName) {
            if (ticketNumber) {
                this.setState({ isClickedPaperReceipt: true }, () =>
                    this.props.paperReceiptStart({
                        ticketNumber,
                        receiptType,
                        currency,
                    }),
                );
            }
        } else {
            this.setState({
                open: true,
                message: i18next.t('Terminal.confirm.11'),
            });
        }
    };

    printTicket = (receiptType = RECEIPT_TYPE.ticket, type = '') => {
        const { data = {}, details = {} } = this.props;

        if (receiptType === RECEIPT_TYPE.invoice && !data.storeClientId) {
            this.setState({
                open: true,
                message: i18next.t('Terminal.confirm.14'),
            });
            return;
        }

        const { printer } = details;
        const { ticketNumber = '' } = data;
        if (!ticketNumber || !printer) return;
        this.props.printTicketStart({ ticketNumber, receiptType, type });
    };

    handleProducts = (showProductsPanel = false) => {
        if (showProductsPanel) {
            this.setState({ showProductsPanel: false });
            return;
        }

        this.setState({ showProducts: !this.state.showProducts });
    };

    render() {
        const {
            isLoadingPaperReceipt,
            details = {},
            data = {},
            menu,
        } = this.props;
        const { printer, storeDetails = {} } = details;
        const { ticketNumber = '', storeClientId, zeroValueReceipt } = data;
        const {
            open,
            message,
            showProducts,
            showProductsPanel,
            isClickedPaperReceipt,
        } = this.state;
        return (
            <>
                {showProducts ? (
                    <SalesProducts
                        showProductsPanel={showProductsPanel}
                        handleClose={this.handleProducts}
                        data={data}
                        printer={printer}
                    />
                ) : (
                    ''
                )}
                <InfoPopUp
                    open={open}
                    classN="message-error"
                    message={message}
                    handleClose={() => this.setState({ open: false })}
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        paperReceipt: getPaperReceipt(state),
        isLoadingPaperReceipt: getLoadingPaperReceipt(state),
        details: getAccountResource(state),
        menu: getMenu(state),
    };
};

const mapDispatchToProps = (dispatch): typeof SalesActionCreators => {
    return bindActionCreators(SalesActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesButtons);
