export const getClients = (state) => state.clientContent.clientData;
export const getSingleClient = (state) => state.clientContent.singleClientData;
export const getLoading = (state) => state.clientContent.isLoading;
export const getLoadingClient = (state) => state.clientContent.isLoadingClient;
export const getClientUpdate = (state) => state.clientContent.setClient;
export const getStoreClient = (state) => state.clientContent.storeClient;
export const getcreateClientStatus = (state) =>
    state.clientContent.createClientStatus;
export const getSetClientLoading = (state) =>
    state.clientContent.setClientLoading;
export const getTopClientsData = (state) => ({
    data: state.clientContent.topClients,
    error: state.clientContent.topClientsError,
    loading: state.clientContent.topClientsLoading,
});
