import { BACKOFFICE_BASE_URL, MARKETPLACE_BASE_URL, PARTNER_BASE_URL, STORE_BASE_URL, WHOLESALE_BASE_URL } from "../utils/constants/navbarPaths";

export const saveTokenInCookies = async (brandApp: number, token: string) => {
	const url = getTerminalUrlByBrandApp(brandApp);
	const loginPath = '/fr/login';

	url && setSubdomainCookie(token, url);
	window.open(url + loginPath, "_self");
}

export const setSubdomainCookie = (token: string, url: string) => {
	const expiredDate = new Date(new Date().getTime() + 1000 * 1200).getTime();
	const [domain] = process.env.NODE_ENV === "development"
		? ["localhost"]
		: (url.match(/\.[A-z]*\.[A-z]*$/g) || [""]);

	document.cookie = `privateToken=${token};expires=${expiredDate};domain=${domain};path=/`;
}

export const getTerminalUrlByBrandApp = (brandApp: number) => {
	switch (brandApp) {
		case 5:
		case 26:
			return STORE_BASE_URL;
		case 9:
			return WHOLESALE_BASE_URL;
		case 12:
			return PARTNER_BASE_URL;
		case 22:
			return MARKETPLACE_BASE_URL;
		case 25:
			return BACKOFFICE_BASE_URL;
		default:
			return null;
	}
}
