import React, {Component} from 'react';
import {Provider} from "react-redux";
import axios from 'axios';
import ReactDOM from 'react-dom';
import i18next from 'i18next';
import {I18nextProvider, initReactI18next} from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import {any} from "prop-types";
import App from './app';
import * as serviceWorker from './serviceWorker';
import {configureStore} from "./store/configure-store";
import ErrorBoundary from "./shared/error/error-boundary";
import { UserActionCreators } from './modules/login/redux/actions';
import { MenuActionCreators } from './layouts/main/header/redux/actions';
import { PRINTER_URL } from './utils/constants/service';
import common_fr from './utils/translations/fr/common.json';
import common_en from './utils/translations/en/common.json';
import common_it from './utils/translations/it/common.json';
import common_de from './utils/translations/de/common.json';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

const store = configureStore();

const rootEl = document.getElementById('root');

axios.interceptors.response.use(response => response, function(err) {
    if(err.response && err.response.status === 401) {
        store.dispatch(UserActionCreators.logoutStart());
    }

    if(!err.response && err.message === "Network Error" && !err.config.url.startsWith(PRINTER_URL)){
        store.dispatch(MenuActionCreators.networkDetectorStart(true))
    }

    return Promise.reject(err);
});

i18next
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            fr: {common: common_fr},
            en: {common: common_en},
            it: {common: common_it},
            de: {common: common_de},
        },
        fallbackLng: 'fr',
        ns: ['common'],
        defaultNS: 'common',
        detection: {
            order: ['path'],
            lookupFromPathIndex: 0
        },
        whitelist: ['en', 'fr','it', 'de'],
        react: {
            wait: true,
            transEmptyNodeValue: '', // what to return for empty Trans
            transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        },
    });
const render = Component => ReactDOM.render(
    <ErrorBoundary>
        <I18nextProvider i18n={i18next}>
            <Provider store={store}>
                <div className={`language--${i18next.language}`}>
                    <Component/>
                </div>
            </Provider>
        </I18nextProvider>

    </ErrorBoundary>
    ,
    rootEl
);

window.onload = () => {
    render(App);
};

//sagaMiddleware.run(helloSaga)

//const action = type => store.dispatch({type:any})
store.dispatch({type: any});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
