import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Grid, Header, Segment, TransitionablePortal,} from 'semantic-ui-react'

interface Props {
    status: string,
    error: any,
}

interface State {
    open: boolean
}

class PrinterErrorMessage extends Component<Props, State> {
    state: State = {
        open: false
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.status !== this.props.status && this.props.status === 'error') {
            this.setState({open: true})
            setTimeout(() => this.setState({open: false}), 5000)
        }
    }

    render() {
        const { error } = this.props
        const { open } = this.state

        return (
            <Grid columns={2}>
                <TransitionablePortal
                    open={open}
                    transition={{ animation: 'fly left', duration: 1000 }}
                >
                    <Segment
                        padded
                        textAlign='center'
                        color='red'
                        style={{
                            left: '40%',
                            position: 'fixed',
                            top: '5%',
                            zIndex: 1000,
                            minWidth: 300
                        }}
                    >
                        <Header>Print Error</Header>
                    </Segment>
                </TransitionablePortal>
            </Grid>
        )
    }
}

const mapStateToProps = (state: any) => ({
})

export default connect(mapStateToProps, null)(PrinterErrorMessage)
