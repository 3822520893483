export const getFirstStep = state => state.account.firstStep;
export const getUser = state => state.account.user;
export const getUserInfo = state => state.account.userInfo;
export const isAuthenticated = state => state.account.authenticated;
export const isPrivateAuthenticated = state => state.account.privateAuthenticated;
export const getStep = state => state.account.step;
export const getPrivateLoginStatus= state => state.account.privateLoginStatus;
export const getLoginStatus= state => state.account.loginStatus;
export const getPrivateLoading= state => state.account.isPrivateLoading;
export const getAccountResource= state => state.account.accountResource;
export const getClientLoginError = state => state.account.clientLoginStatus;
export const getAccountResourceLoading = state => state.account.loading;
export const getVatNumber = state => state.account.vatNumber;
export const getBrandApp = state => state.account.brandApp;
export const isTerminalAdmin = state => state.account.isTerminalAdmin;
