import {ReportsTypes, ReportsActionCreators} from "./actions";
import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {REPORTS_XZ_URL, REPORTS_PAYMENTS} from "../../../utils/constants/service";
import { DEFAULT_CURRENCY, IS_FISCAL_PRINTER } from '../../../utils/constants/variables';

export function* printReport(params) {
    const { reportType, currency = DEFAULT_CURRENCY } = params;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency
            }
        };

        const data = yield call(() => {
            return axios.get(`${REPORTS_XZ_URL}/?reportType=${reportType}`, headers)
                .then(res => res.data)
        });

        yield put(ReportsActionCreators.printReportSuccess(data));
    } catch (e) {
        yield put(ReportsActionCreators.printReportFailure(e.message));
    }
}

export function* paymentsReport(params) {
    const {currency = DEFAULT_CURRENCY} = params;
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`,
                currency,
            }
        };

        const data = yield call(() => {
            return axios.get(`${REPORTS_PAYMENTS}`, headers)
                .then(res => res.data)
        });

        yield put(ReportsActionCreators.paymentsReportSuccess(data, currency));
    } catch (e) {
        yield put(ReportsActionCreators.paymentsReportFailure(e.message));
    }
}


export function* reportsSaga() {
    yield takeEvery(ReportsTypes.PRINT_REPORT_START, printReport);
    yield takeEvery(ReportsTypes.PAYMENTS_REPORT_START, paymentsReport);
}

export default reportsSaga;
