import React from "react";
import {connect} from "react-redux";
import BlackWindow from "./../common/blackWindow";
import Loading from "../common/loading/loading";
import closeIcon from "./../../assets/img/close_black.svg"
interface Props extends State {
    classN:string,
    handleClose: any,
    loading?: boolean
}

interface State {
}

class PopUp extends React.Component<Props & State> {
    state = {};

    closePopUp = () => {
        this.props.handleClose()
    };
    render(): React.ReactNode {
        const {classN} =this.props
        return (
            <>
                <BlackWindow type="page" handleClick={this.closePopUp}/>

                <div className={`${classN ? classN : "" } popUp`}>
                    <div className="popUp--header">
                        <img src={closeIcon} onClick={this.closePopUp} alt="PHOTO"/>
                    </div>
                    <div className="popUp--body">
                        {this.props.loading ? <Loading/> : this.props.children}
                    </div>

                </div>
            </>
        );
    }
}


export default connect()(PopUp);
