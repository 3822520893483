import React from "react"
import {fixedInHundredth, replaceComma, roundUpTreeDecimal} from "../../../helpers/helpers";
import {DEFAULT_CURRENCY_SYMBOL} from "../../../utils/constants/variables";

export default function Price({
        startP, discount = "", newP, quantity = 1, currency = DEFAULT_CURRENCY_SYMBOL,
        percent = "", isSale = false, isPromo = false, isOneQty = false
}) {
    const newPrice = `${replaceComma(fixedInHundredth(roundUpTreeDecimal(Number(newP) * (isOneQty ? 1 : quantity))))}${currency}`
    return (
        isSale ?
            <>
                {newP?
                    <>
                        <span className="new-price">{quantity < 0 ? "-" : "" }{newPrice}</span>
                        <span className="old-price">{quantity < 0 ? "-" : "" }{replaceComma(Number(startP) * quantity)}{currency}</span>
                    </>:
                    <span className="new-price">{quantity < 0 ? "-" : "" }{replaceComma(Number(startP) * quantity)}{currency}</span>}
                    {percent ? <span className="discountPercent">{replaceComma(parseFloat(percent))+percent[percent.length-1]}</span>:""}
            </> :
        percent && !isSale?
            <>
                <span className="new-price">{quantity < 0 ? "-" : "" }{replaceComma(Number(startP) * quantity)}{currency}</span>
                <span className="discountPercent">{percent.replace(".",",")}</span>
            </>
            :discount ?
            <>
                <span className="new-price">
                    {quantity < 0 ? "-" : "" }{newPrice}
                </span>
                <span className="old-price">{quantity < 0 ? "-" : "" }{replaceComma(Number(startP) * quantity)}{currency}</span>

            </>
            :
            <span className="new-price">{replaceComma(fixedInHundredth(roundUpTreeDecimal(Number(startP) * quantity)))}{currency}</span>
    );

}
