import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Modal } from 'semantic-ui-react';
import { getBarcodeProduct } from '../../redux/selectors';
import { HomeActionCreators } from '../../redux/actions';
import { bindActionCreators } from 'redux';
import { priceThreeDigitSets } from '../../../../helpers/helpers';
import { getAccountResource } from "../../../login/redux/selectors";

interface IProps {
    close?: any,
    barcodeProduct: any,
    barcodeProductVerifyStart: any,
    title?: string,
    account: any,
}

const VerifyBarcodeModal: React.FC<IProps> = ({
    close,
    barcodeProduct,
    barcodeProductVerifyStart,
    account,
    title = i18next.t('Terminal.modal.title'),
}) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        close();
        barcodeProductVerifyStart(false);
    };
    useEffect(() => {
        if (barcodeProduct) {
            setOpen(true);
        }
    }, [barcodeProduct]);

    const storeCode = account && account.storeDetails && account.storeDetails.storeCode;
    const sizes = (barcodeProduct.sizes && barcodeProduct.sizes[0]) || {};
    const stock = Array.isArray(sizes.storeInventories) ? sizes.storeInventories.find(item => item.storeCode === storeCode) || {} : {};
    const { listPriceAfterDiscount, currency, startingPrice, discountDetail, productReference = '', size = '' } = sizes;
    const price = listPriceAfterDiscount ?
        `${priceThreeDigitSets(listPriceAfterDiscount, currency)} ( ${priceThreeDigitSets(startingPrice, currency)} ${"  "} ${discountDetail} )`
        : priceThreeDigitSets(startingPrice, currency);

    return (
        <Modal
            className="_modal--content"
            size="small"
            closeIcon
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="barcode-modal">
                        {barcodeProduct.productId && barcodeProduct.productId !== -1 ? (
                            <>
                                <p>{barcodeProduct.name}</p>
                                <p>{`${productReference} ${size}`}</p>
                                <p>{`Stock: ${stock.storeStock || 0}`} </p>
                                <p>{price}</p>
                            </>
                        ) : (
                            <p>{i18next.t('Terminal.unknown.barcode')}</p>
                        )}
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div className="modal-actions">
                    <p className="close-button"></p>
                    <button
                        className="accept-button"
                        name="bouton" onClick={handleClose}>Ok
                        </button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        barcodeProduct: getBarcodeProduct(state),
        account: getAccountResource(state),
    };
};

const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators(HomeActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyBarcodeModal);
