import { StoresFormTypes, StoresFormActionCreators } from './actions';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
import {
    STORE_FORM,
    STORE_BY_COUNTRY,
    fetchStores,
} from '../../../utils/constants/service';
import { getApiPrefix, getLocalFormData, isValidClientToken } from '../../../helpers/helpers';
import { getConnectedStatus } from '../../../layouts/main/header/redux/selectors';
import { getAccountResource } from '../../login/redux/selectors';
import { STORAGE_GET_FORM } from '../../../utils/constants/variables';

export function* getStoresForm() {
    try {
        let data = {};
        const isDisconnected = yield select(getConnectedStatus);

        const headers = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    'privateToken',
                )}`,
                'Associate-Authorization': `${localStorage.getItem('token')}`,
            },
        };

        if (isDisconnected) {
            data = getLocalFormData();
        } else {
            data = yield call(() => {
                return axios
                    .get(`${STORE_FORM}?language=${getApiPrefix()}`, headers)
                    .then((res) => res.data);
            });
            localStorage.setItem(STORAGE_GET_FORM, JSON.stringify(data));
        }

        yield put(StoresFormActionCreators.storesFormSuccess(data));
    } catch (e) {
        yield put(StoresFormActionCreators.storesFormFailure(e.message));
    }
}

export function* getStores() {
    try {
        const { storeDetails: { country = 'FR' } = {} } = yield select(
            getAccountResource,
        );

        const data = yield call(() => fetchStores(country));

        yield put(StoresFormActionCreators.storesSuccess(data));
    } catch (e) {
        yield put(StoresFormActionCreators.storesFailure(e.message));
    }
}

export function* storesSaga() {
    yield takeLatest(StoresFormTypes.STORES_FORM_START, getStoresForm);
    yield takeLatest(StoresFormTypes.STORES_START, getStores);
}

export default storesSaga;
