import React from 'react';
import { connect } from 'react-redux';
import { StockActionCreators } from '../../redux/actions';

import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { Input } from 'semantic-ui-react';
import { getAccountResource } from '../../../login/redux/selectors';
import AutocompleteDropdown from '../../../common/autocompleteDropdown';
import { getcreateStockStatus, getLoading, getNewStocProduct } from '../../redux/selectors';
import { DEFAULT_CURRENCY } from '../../../../utils/constants/variables';
import {
    getActiveBasketName,
    replaceCommaToDecimal,
} from '../../../../helpers/helpers';
import { fetchBrandsName } from '../../../../utils/constants/service';
import CustomBtn from '../../../common/customBtn';

interface State {
    showPopUp: boolean;
    barcode: string;
    listPrice: number;
    selectedBrandId: string | number;
    unit: string;
    loading: boolean;
    status: string;
    brandOption: object[];
    brands: any;
}

interface Props {
    account?: any;
    createStockStatus: string;
    handleClosePopUp: any;
    value: string;
    newStockProduct: object;
    isLoading: boolean;
    getBarcodeProductStart?: ({}) => void;
}

class NewStocks extends React.Component<
    State & Props & typeof StockActionCreators
> {
    state: State = {
        showPopUp: false,
        barcode: '',
        listPrice: 0,
        selectedBrandId: '',
        unit: '',
        loading: false,
        status: '',
        brandOption: [],
        brands: [],
    };

    unitOption = [
        {
            key: '0',
            text: '',
            value: '',
        },
        {
            key: '1',
            text: i18next.t('Terminal.unit.1'),
            value: i18next.t('Terminal.unit.1'),
        },
        {
            key: '2',
            text: i18next.t('Terminal.unit.2'),
            value: i18next.t('Terminal.unit.2'),
        },
    ];

    nameInput;

    componentDidMount(): void {
        this.nameInput.focus();
        const { value: barcode, account = {} } = this.props;
        const {
            storeDetails: { brandId },
        } = account;
        this.setState({ selectedBrandId: brandId, barcode: barcode || '' });
        fetchBrandsName()
            .then((res) => {
                if (Array.isArray(res) && res.length) {
                    const brandOption = res.map((item) => {
                        return {
                            key: item.id,
                            text: item.name,
                            value: item.name,
                        };
                    });
                    this.setState({ brandOption, brands: res });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.createStockStatus !== this.props.createStockStatus) {
            this.setState({ status: this.props.createStockStatus });
            if (
                this.props.newStockProduct &&
                this.props.newStockProduct.productId
            ) {
                const { barcode } = this.state;

                this.props.getBarcodeProductStart({
                    barcode: barcode,
                    scanType: '',
                });
            }
        }

        if (prevState.status !== this.state.status) {
            if (this.state.status === 'success') {
                this.props.resetCreatedStockStatus();
                this.props.handleClosePopUp();
            }
        }
    }

    handleClose = () => {
        this.setState({
            barcode: '',
            listPrice: 0,
            selectedBrandId: this.state.brands[0]
                ? this.state.brands[0].id
                : '',
            unit: '',
        });
    };

    handleSubmitInfo = () => {
        const { barcode, listPrice, selectedBrandId, unit } = this.state;
        const stockInfo = !!(barcode && selectedBrandId && listPrice);
        const brand =
            this.state.brands.find((i) => i.id === selectedBrandId) || {};
        const body = {
            barcode,
            listPrice: replaceCommaToDecimal(listPrice),
            brandName: brand.name,
            currency: DEFAULT_CURRENCY,
        };

        if (unit.length) {
            body['unit'] = unit;
        }
        if (stockInfo) {
            this.props.createStockStart(body);
        } else {
            this.setState({ status: 'failed' });
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    onPriceInputIn = ({ name, value }) => {
        if (value === '0') {
            this.setState({ [name]: '' });
        }
    };
    onPriceInputOut = ({ name, value }) => {
        if (value === '') {
            this.setState({ [name]: '0' });
        }
    };

    render() {
        const { isLoading } = this.props;
        const {
            barcode,
            status,
            listPrice,
            selectedBrandId,
            unit,
            brandOption,
        } = this.state;

        return (
            <div className="parent-for-comment">
                <div className="popUp--body--card--body set-data-content">
                    <div className="content">
                        <form
                            autoComplete="off"
                            className="popUp--body--card--body--data"
                        >
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.barcode')}
                            </div>
                            <Input
                                error={!barcode.length}
                                className={`popUp--body--card--body--data--description  clienttab `}
                                value={barcode}
                                name="barcode"
                                onChange={this.handleChange}
                                ref={(input) => {
                                    this.nameInput = input;
                                }}
                                autoComplete="off"
                            />
                        </form>
                        <form
                            autoComplete="off"
                            className="popUp--body--card--body--data"
                        >
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.price')} ({DEFAULT_CURRENCY})
                            </div>
                            <Input
                                className={
                                    'popUp--body--card--body--data--description  clienttab'
                                }
                                value={listPrice}
                                name="listPrice"
                                onChange={this.handleChange}
                                onFocus={({ target }) =>
                                    this.onPriceInputIn(target)
                                }
                                onBlur={({ target }) =>
                                    this.onPriceInputOut(target)
                                }
                                autoComplete="off"
                            />
                        </form>
                        <form
                            autoComplete="off"
                            className="popUp--body--card--body--data"
                        >
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.brand')}
                            </div>
                            <AutocompleteDropdown
                                data={brandOption}
                                value={selectedBrandId}
                                search={true}
                                classN="popUp--body--card--body--data--description"
                                keyName="key"
                                textName={['text']}
                                valueName="key"
                                onChange={(val) =>
                                    this.setState({ selectedBrandId: val })
                                }
                            />
                        </form>
                        <form
                            autoComplete="off"
                            className="popUp--body--card--body--data"
                        >
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t('Terminal.dashboards.unit')}
                            </div>
                            <AutocompleteDropdown
                                data={this.unitOption}
                                value={unit}
                                search={true}
                                classN="popUp--body--card--body--data--description"
                                keyName="key"
                                textName={['text']}
                                valueName="text"
                                onChange={(val) => this.setState({ unit: val })}
                            />
                        </form>
                    </div>
                    <div className="extra content">
                        <div className="ui two buttons">
                            <CustomBtn
                                classN="btn_1 w-100 blue_btn withoutHover"
                                handleClick={this.handleSubmitInfo}
                                loading={isLoading}
                            >
                                {i18next.t('Terminal.dashboards.edit')}
                            </CustomBtn>
                            <div
                                className="btn_1 w-100"
                                onClick={this.props.handleClosePopUp}
                            >
                                {i18next.t('buttons.40')}
                            </div>
                        </div>
                    </div>
                    {status && (
                        <div
                            className={
                                status === 'success'
                                    ? 'successMessage'
                                    : 'errorMessage'
                            }
                        >
                            {status === 'success'
                                ? i18next.t('alertMessage.2')
                                : i18next.t('alertMessage.1')}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any): Props & typeof StockActionCreators => ({
    account: getAccountResource(state),
    createStockStatus: getcreateStockStatus(state),
    newStockProduct: getNewStocProduct(state),
    isLoading: getLoading(state)
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(NewStocks);
