import {createReducer} from 'reduxsauce';
import {StoreLocationTypes} from './actions';

export interface IStoreLocator{
   data:any,
}

export const locationStore: IStoreLocator = {
    data: {},
};

export const setStoreLocationStart = (state,action) => {
    return {...state, data: action.data};
};


export const handlers = {
    [StoreLocationTypes.STORE_LOCATION_START]: setStoreLocationStart,
};
export default createReducer(locationStore, handlers);


