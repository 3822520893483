import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import PopUp from '../../../popUp/popUp';
import CreateTransfer from './createTransfer';
import { TransfersActionCreators } from '../../redux/actions';
import { getUpdatedStatus, getUpdateLoading } from '../../redux/selectors';
import { getAccountResource } from '../../../login/redux/selectors';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import InfoPopUp from '../../../common/infoPopUp';
import CustomBtn from '../../../common/customBtn';
import { TRANSFER_STATUS_CODE } from '../../../../utils/constants/variables';
import { getTotalSum } from '../../../../helpers/helpers';
import { createStoreTransfer } from '../../../../utils/constants/service';

interface Props {
    data: any;
    updatedStatus: string;
    account: any;
}

interface State {
    isShowPopUp: boolean;
    showConfirmpopUp: boolean;
    showInfoPopup: boolean;
    InfoPopupMessage: string;
    transferStatus: string;
}
class TransferButtons extends Component<
    Props & typeof TransfersActionCreators,
    State
> {
    state: State = {
        isShowPopUp: false,
        showConfirmpopUp: false,
        showInfoPopup: false,
        InfoPopupMessage: '',
        transferStatus: '',
    };

    componentDidUpdate(prevProps) {
        if (prevProps.updatedStatus !== this.props.updatedStatus) {
            if (this.props.updatedStatus === 'success') {
                this.setState({
                    showInfoPopup: true,
                    InfoPopupMessage: i18next.t('alertMessage.2'),
                });
            } else if (this.props.updatedStatus === 'failed') {
                this.setState({
                    showInfoPopup: true,
                    InfoPopupMessage: i18next.t('alertMessage.1'),
                });
            }
        }
    }

    openPopUp = (e) => {
        this.setState({ isShowPopUp: true });
    };

    handleClosePopUp = () => {
        this.props.resetUpdateResponse();
        this.setState({ isShowPopUp: false });
    };

    transferCases = (transferStatus) => {
        const { storeCodeSend, storeTransferStatusCode, storeCodeRecv } =
            this.props.data;

        const isQtySend =
            transferStatus === TRANSFER_STATUS_CODE.SHI &&
            this.props.account.storeCode === storeCodeSend &&
            storeTransferStatusCode === TRANSFER_STATUS_CODE.ACK;

        const isQtyRecv =
            transferStatus === TRANSFER_STATUS_CODE.REC &&
            this.props.account.storeCode === storeCodeRecv &&
            storeTransferStatusCode === TRANSFER_STATUS_CODE.SHI;

        return { isQtyRecv, isQtySend };
    };

    handleUpdateStatus = async () => {
        try {
            const { transferStatus } = this.state;
            const {
                storeTransferId,
                products = [],
                storeCodeSend,
                storeTransferStatusCode,
                associateCodeRecv,
                storeTransferReasonCode,
                storeCodeRecv,
            } = this.props.data;
            if (!this.props.data || !this.props.data.storeTransferCode) {
                this.closeConfirmPopUp();
                return;
            }

            if (products.length && storeTransferId) {
                const { isQtyRecv, isQtySend } =
                    this.transferCases(transferStatus);

                if (isQtySend || isQtyRecv) {
                    const body: any = {
                        storeCodeSend,
                        storeCodeRecv,
                        storeTransferId,
                        associateCodeRecv,
                        storeTransferStatusCode,
                        storeTransferReasonCode,
                        productQty: getTotalSum(products, 'quantity'),
                        storeTransferProducts: products.map(
                            ({
                                barcode,
                                qtyRecv = 0,
                                qtySend = 0,
                                quantity,
                            }) => ({
                                barcode,
                                qtyRecv,
                                qtySend: isQtySend ? quantity : qtySend,
                                quantity: isQtyRecv ? qtyRecv : quantity,
                            }),
                        ),
                    };

                    await createStoreTransfer(body);
                }
            }

            this.props.updateTransferStart({
                code: this.props.data.storeTransferCode,
                status: transferStatus,
            });
            this.closeConfirmPopUp();
        } catch (error) {
            this.closeConfirmPopUp();
            this.setState({
                showInfoPopup: true,
                InfoPopupMessage: i18next.t('alertMessage.1'),
            });
        }
    };

    isActiveBtn = (code) => {
        const { data, account } = this.props;

        if (!data) return true;

        const storeCode =
            account && account.storeDetails && account.storeDetails.storeCode;
        const { storeCodeSend, storeCodeRecv, storeTransferStatusCode } = data;

        if (
            [TRANSFER_STATUS_CODE.REC, TRANSFER_STATUS_CODE.DIFF].includes(
                storeTransferStatusCode,
            )
        ) {
            return true;
        }

        if (code === TRANSFER_STATUS_CODE.SHI) {
            if (
                storeTransferStatusCode === code
                || storeTransferStatusCode === TRANSFER_STATUS_CODE.REJ
            ) {
                return true;
            }

            if (storeCodeRecv === storeCode) {
                return true;
            } else {
                return false;
            }
        } else if (code === TRANSFER_STATUS_CODE.REC) {
            if (
                (storeCode === storeCodeRecv &&
                    [
                        TRANSFER_STATUS_CODE.ACK,
                        TRANSFER_STATUS_CODE.REQ,
                    ].includes(storeTransferStatusCode)) ||
                [
                    code,
                    TRANSFER_STATUS_CODE.REJ,
                    TRANSFER_STATUS_CODE.PEN,
                ].includes(storeTransferStatusCode)
            ) {
                return true;
            }

            if (storeCodeSend === storeCode) {
                return true;
            } else {
                return false;
            }
        } else if (code === TRANSFER_STATUS_CODE.REJ) {
            return ![
                TRANSFER_STATUS_CODE.PEN,
                TRANSFER_STATUS_CODE.REQ,
                TRANSFER_STATUS_CODE.ACK
            ].includes(data.storeTransferStatusCode);
        }

        return false;
    };

    openConfirmPopUp = (status) => {
        const { isQtyRecv } = this.transferCases(status);
        const { products = [] } = this.props.data;
        let transferStatus = status;

        if (isQtyRecv && !products.find((el) => el.qtyRecv > 0)) {
            this.setState({
                showInfoPopup: true,
                InfoPopupMessage: i18next.t('alertMessage.10'),
            });
            return;
        }

        if (
            products.length &&
            status === TRANSFER_STATUS_CODE.REC &&
            products.find((el) => el.qtyRecv !== el.qtySend)
        ) {
            transferStatus = TRANSFER_STATUS_CODE.DIFF;
        }

        this.setState({ showConfirmpopUp: true, transferStatus });
    };

    closeConfirmPopUp = () => {
        this.setState({ showConfirmpopUp: false, transferStatus: '' });
    };

    render() {
        const { updatedStatus, data = {}, isUpdateLoading } = this.props;
        const {
            isShowPopUp,
            showConfirmpopUp,
            showInfoPopup,
            InfoPopupMessage,
        } = this.state;

        return (
            <>
                <button
                    className="btn_1 w-100 orange_btn"
                    onClick={this.openPopUp}
                >
                    {i18next.t('buttons.4')}
                </button>

                <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    disabled={this.isActiveBtn(TRANSFER_STATUS_CODE.SHI)}
                    loading={isUpdateLoading}
                    handleClick={() =>
                        this.openConfirmPopUp(TRANSFER_STATUS_CODE.SHI)
                    }
                >
                    {i18next.t('buttons.41')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 green_btn"
                    disabled={this.isActiveBtn(TRANSFER_STATUS_CODE.REC)}
                    loading={isUpdateLoading}
                    handleClick={() =>
                        this.openConfirmPopUp(TRANSFER_STATUS_CODE.REC)
                    }
                >
                    {i18next.t('buttons.26')}
                </CustomBtn>
                <CustomBtn
                    classN="btn_1 w-100 red_btn"
                    disabled={this.isActiveBtn(TRANSFER_STATUS_CODE.REJ)}
                    handleClick={() =>
                        this.openConfirmPopUp(TRANSFER_STATUS_CODE.REJ)
                    }
                >
                    {i18next.t('buttons.11')}
                </CustomBtn>

                {isShowPopUp && (
                    <PopUp
                        handleClose={this.handleClosePopUp}
                        classN="transferPopUp"
                    >
                        <CreateTransfer
                            handleClosePopUp={this.handleClosePopUp}
                        />
                    </PopUp>
                )}

                {showConfirmpopUp && (
                    <ConfirmPopup
                        handleConfirm={this.handleUpdateStatus}
                        handleCancel={() => this.closeConfirmPopUp()}
                        text={i18next.t('Terminal.modal.2')}
                    />
                )}
                <InfoPopUp
                    open={showInfoPopup}
                    message={InfoPopupMessage}
                    classN={
                        updatedStatus === 'success'
                            ? 'successMessage'
                            : 'errorMessage'
                    }
                    handleClose={() => {
                        this.setState({
                            showInfoPopup: false,
                            InfoPopupMessage: '',
                        });
                        this.props.resetUpdateStatus();
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        updatedStatus: getUpdatedStatus(state),
        account: getAccountResource(state),
        isUpdateLoading: getUpdateLoading(state),
    };
};

const mapDispatchToProps = (dispatch): typeof TransfersActionCreators => {
    return bindActionCreators(TransfersActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferButtons);
