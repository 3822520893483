import { combineReducers } from 'redux';
import account from '../modules/login/redux/reducers';
import product from '../modules/product/redux/reducers';
import geoLocation from '../modules/geolocation/redux/reducers';
import productsR from '../modules/products/redux/reducers';
import addToCart from '../modules/addToCart/redux/reducers';
import menuR from '../layouts/main/header/redux/reducers';
import homeContent from '../modules/home/redux/reducers';
import locationSearch from '../modules/locationSearchInput/redux/reducers';
import paymentR from '../modules/payment/redux/reducers';
import DownloadR from '../modules/recapCommande/redux/reducers';
import locationStore from '../modules/location/redux/reducers';
import terminalReducer from '../modules/fermeturePopUp/redux/reducers';
import stockContent from '../modules/stocks/redux/reducers';
import clientContent from './../modules/clients/redux/reducers';
import livraisonClientContent from './../modules/livraisonClient/redux/reducers';
import reservationMagasinContent from './../modules/reservationMagasin/redux/reducers';
import salesContent from './../modules/sales/redux/reducers';
import datatableContent from './../modules/datatable/redux/reducers';
import storesState from './../modules/storeFormSidePanel/redux/reducers';
import storeGiftsState from './../modules/bonsCadeaux/redux/reducers';
import transfersContent from '../modules/transfers/redux/reducers';
import deliveriesContent from '../modules/deliveries/redux/reducers';
import addToDeliveryCart from '../modules/deliveryBasketPopUp/redux/reducers';
import quoteR from './../modules/quotes/redux/reducers';
import returnContent from './../modules/return/redux/reducers';
import reportsContent from './../modules/reports/redux/reducers';
import inventoryContent from './../modules/inventory/redux/reducers';
import nullBeleg from './../modules/nullBelegBtn/redux/reducers';
import calendarR from '../modules/calendar/redux/reducers';
import adminR from '../modules/Admin/redux/reducers';
import appointmentR from '../modules/Appointments/redux/reducers';

const rootReducer = combineReducers({
    account,
    locationStore,
    product,
    geoLocation,
    productsR,
    addToCart,
    menuR,
    homeContent,
    locationSearch,
    paymentR,
    DownloadR,
    terminalReducer,
    stockContent,
    clientContent,
    livraisonClientContent,
    reservationMagasinContent,
    salesContent,
    datatableContent,
    storesState,
    storeGiftsState,
    transfersContent,
    deliveriesContent,
    addToDeliveryCart,
    quoteR,
    returnContent,
    reportsContent,
    inventoryContent,
    nullBeleg,
    calendarR,
    adminR,
    appointmentR
});

export default rootReducer;
