import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import i18next from "i18next";
import Loading from "../../../common/loading/loading";
import StoreFormSidePanel from "../../../storeFormSidePanel/container";
import { PaymentActionCreators } from "../../redux/actions";
import { getAccountResource } from "../../../login/redux/selectors";
import { getLoading, getStoresForm } from "../../../storeFormSidePanel/redux/selectors";
import { getStatus, getCreatedStore, getFiscalData, getFiscalError } from "../../redux/selectors";
import { getConnectedStatus, getMenu } from "../../../../layouts/main/header/redux/selectors";
import { getStoreClient } from "../../../clients/redux/selectors";
import {
    getBasketData,
    replaceComma,
    getCurrency,
    destroyActiveBasket,
    getActiveBasketClientId,
    cloneObject,
    getActiveBasketName,
    fixNumber,
    addDays,
    setOfflinePay,
    getOfflinePay,
    getTaxes,
    getTax,
    isEmptyBasket,
    getMenuItemCanonicalUrl,
    customRoundByCountry,
    isTaxesCountry,
    getItClientMandatoryFields,
    getLangPrefix
} from "../../../../helpers/helpers";
import history from '../../../../helpers/history';
import { DEFAULT_CURRENCY, GIFT_DURATION, PAYMENT_CODES, PAYMENT_TYPE, RECEIPT_TYPE, STORE_COUNTRIES, STORE_COUNTRY, TICKET_TYPE, } from '../../../../utils/constants/variables';
import InfoPopUp from "../../../common/infoPopUp";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { printerOpenCashDrawer, } from '../../../../utils/constants/service';
import { FatturaBtn } from "../../../pdfGenerator/fatturaPdf";
import { getprintTicketData } from "../../../sales/redux/selectors";
import CustomBtn from "../../../common/customBtn";
import { getGiftPtinterStatus } from "../../../bonsCadeaux/redux/selectors";

interface Props {
    isLoading: boolean,
    createLoading: boolean,
    paymentMethods: any[],
    paymentTerms: any[],
    handleClose: void,
    handleCloseModal: void,
    handleSetMethod: void,
    handleChangeUserPay: void,
    accountResource: any,
    basket: any,
    status: string,
    storeClient: any,
    createdData: any,
    webOrder: boolean,
    resetAction: void,
    isDisconnected: boolean,
    itPrinterError: string,
    fiscalData: any,
    menu: any,
    ticketData: object,
    paymentTermCode?: {}
}

interface State {
    selectedPaymentTerms: string,
    storeSalePayments: any[],
    isDisabledClick: boolean,
    activeMethod: string,
    value: string | number,
    taxes: number,
    price: number,
    sumAmount: number,
    showMessage: boolean,
    priceAfterTaxes: number,
    paymentStatus: string,
    isOpenInfoPopUp: boolean,
    infoPopupMessage: string,
    isShowClientBtn: boolean,
    subPayment: any | object,
    paymentMethodsNoType: any[],
    paymentMethodsTypeP: any[],
    isKeyDown: boolean,
    isBasketClient: boolean,
    showPrinterError: boolean,
    isFiscalBuono: boolean,
    isClientEmailOrAddressEmpty: boolean,
}

class PaymentSidePanel extends React.Component<Props & typeof PaymentActionCreators, State> {

    state: State = {
        selectedPaymentTerms: "",
        storeSalePayments: [],
        isDisabledClick: false,
        activeMethod: "",
        value: "",
        taxes: 0,
        price: 0,
        sumAmount: 0,
        showMessage: false,
        priceAfterTaxes: 0,
        paymentStatus: "",
        isOpenInfoPopUp: false,
        infoPopupMessage: "",
        isShowClientBtn: false,
        subPayment: {},
        paymentMethodsNoType: [],
        paymentMethodsTypeP: [],
        isKeyDown: false,
        isBasketClient: false,
        showPrinterError: false,
        isFiscalBuono: false,
        isClientEmailOrAddressEmpty: false,
    };

    componentDidMount(): void {
        const { basket, isDisconnected, paymentMethods = [], accountResource } = this.props;
        const { taxRate } = accountResource;
        const { price = 0 } = getBasketData(basket);
        const taxes = getTaxes(basket, taxRate);
        const clientCode = getActiveBasketClientId()
        if (clientCode) {
            this.props.storeClientStart(clientCode, getActiveBasketName())
        }
        this.setState({ taxes: +taxes.toFixed(2), price: +price.toFixed(2) })

        const { selectedPaymentTerms = "", storeSalePayments = [], subPayment = {} } = getOfflinePay();
        if (selectedPaymentTerms && storeSalePayments.length) {
            const sumAmount = this.getSumAmount(storeSalePayments)
            const isDisabledClick = storeSalePayments.length !== 1 || storeSalePayments[0].saleAmount !== price
            this.setState({ selectedPaymentTerms, storeSalePayments, subPayment, sumAmount, isDisabledClick })
        }

        if (isDisconnected && paymentMethods.length) {
            this.setPaymentMethods();
        }
    }

    componentDidUpdate(prevProps, prevState): void {

        if (
            this.props.basket.ticket && this.props.basket.ticket.ticketNumber &&
            (!prevProps.basket.ticket || prevProps.basket.ticket.ticketNumber !== this.props.basket.ticket.ticketNumber)
        ) {
            const { basket, accountResource } = this.props;
            const { taxRate } = accountResource;
            const { price = 0 } = getBasketData(basket);
            const taxes = getTaxes(basket, taxRate);
            const priceAfterTaxes = +price.toFixed(2)

            this.setState({ priceAfterTaxes, price: priceAfterTaxes, taxes: +taxes.toFixed(2) })
        }
        if (prevProps.isLoading && !this.props.isLoading && this.props.paymentMethods.length) {
            const { price = 0 } = getBasketData(this.props.basket);
            this.setPaymentMethods();

            if(this.props.paymentTermCode && this.props.paymentTermCode.paymentMethodCode) {
                const paymentMethodCode = this.props.paymentTermCode.paymentMethodCode;
                const method = this.props.paymentMethods.find((item) => item.code === paymentMethodCode);
                this.handleChangeMethod(method)
            }
            if (price === 0) {
                const method = this.props.paymentMethods.find(
                    ({ code, paymentMethodType }) => code === PAYMENT_CODES.c5 && paymentMethodType === PAYMENT_TYPE.p
                )
                if (method) {
                    this.handleChangeMethod(method)
                }
            }
        }

        if (this.props.isDisconnected && !prevProps.paymentMethods.length && this.props.paymentMethods.length) {
            this.setPaymentMethods();
        }

        if (!prevState.isDisabledClick && this.state.isDisabledClick && prevState.storeSalePayments.length) {
            this.setState({ storeSalePayments: [] })
        }

        if (this.state.isDisabledClick && Math.abs(prevState.sumAmount) > 0 && !this.state.sumAmount) {
            this.handleChangeMethod(prevState.activeMethod)
            this.setState({ isDisabledClick: false })
        }

        if (prevState.activeMethod && !this.state.activeMethod && this.state.isDisabledClick && !this.state.sumAmount && !prevState.sumAmount) {
            this.handleChangeMethod(prevState.activeMethod)
            this.setState({ isDisabledClick: false })
        }

        if (prevState.selectedPaymentTerms !== this.state.selectedPaymentTerms) {
            const { accountResource } = this.props
            const { taxRate } = accountResource;
            const { price, taxes } = this.state
            if (this.state.selectedPaymentTerms === PAYMENT_CODES.c4) {
                this.setState({ priceAfterTaxes: +(price - taxes).toFixed(2), taxes: 0 })
            } else {
                const taxes = getTaxes(this.props.basket, taxRate);
                this.setState({ priceAfterTaxes: price, taxes: +taxes.toFixed(2) })
            }
        }

        if (prevProps.status !== this.props.status && this.props.status) {
            this.setState({ paymentStatus: this.props.status });
            this.props.handleCloseModal();

            if (this.props.status === "SUCCESS") {
                const { createdData, accountResource } = this.props
                const { storeDetails = {} } = accountResource

                if (!createdData.clientEmail || [STORE_COUNTRIES.de, STORE_COUNTRIES.it, STORE_COUNTRIES.at].includes(storeDetails.country)) {
                    if(!this.state.isFiscalBuono) {
                        this.printTicket();
                    }
                    this.setState({ isShowClientBtn: false })
                } else {
                    this.setState({ isShowClientBtn: true })
                }
            }
        }

        if(this.state.isFiscalBuono && !prevProps.giftPrinterStatus && this.props.giftPrinterStatus === 'success') {
            this.setState({isFiscalBuono: false}, this.printTicket)
        }

        if (this.state.selectedPaymentTerms !== prevState.selectedPaymentTerms && (this.state.selectedPaymentTerms === PAYMENT_CODES.c4 || this.state.selectedPaymentTerms === PAYMENT_CODES.c2)) {
            const currentBasketName = getActiveBasketName();
            const clientData = this.props.storeClient[currentBasketName] || {};
            const clientCode = clientData.profile ? clientData.profile.clientCode : 0;
            if (!clientCode && !getActiveBasketClientId()) {
                this.setState({ isBasketClient: true });
            }
        }

        if (this.props.itPrinterError && prevProps.itPrinterError !== this.props.itPrinterError) {
            this.setState({ showPrinterError: true })
        }
    }

    setPaymentMethods = () => {
        const { paymentMethods, basket, webOrder } = this.props;
        const paymentMethodsNoType = paymentMethods.filter(item => this.filterCallback(item, ""));
        const paymentMethodsTypeP = paymentMethods.filter(item => this.filterCallback(item, PAYMENT_TYPE.p));
        let selectedPaymentTerms = webOrder ? PAYMENT_CODES.c5 : PAYMENT_CODES.c1;

        if(basket.ticket && basket.ticket.paymentTermCode === PAYMENT_CODES.c4){
            selectedPaymentTerms = PAYMENT_CODES.c4
        }

        this.setState({
            selectedPaymentTerms,
            paymentMethodsNoType,
            paymentMethodsTypeP
        })
    }

    getSumAmount = (salePay: any = []) => {
        const { storeSalePayments } = this.state;
        let sumAmount = salePay && salePay.length ?
            salePay.reduce((acc, item) => {
                return acc + item.saleAmount
            }, 0)
            :
            storeSalePayments.reduce((acc, item) => {
                return acc + item.saleAmount
            }, 0)

        return sumAmount
    }

    getTaxRate = (price: number) => {
        const { accountResource } = this.props;
        const { selectedPaymentTerms } = this.state;
        if (selectedPaymentTerms === PAYMENT_CODES.c4) {
            return 0;
        }
        if (!accountResource || !price) return 0;
        const { taxRate } = accountResource;
        const saleTax = getTax(taxRate, price);
        return +saleTax.toFixed(2)
    }

    getItemMethodAmount = (code) => {
        const { storeSalePayments } = this.state;
        const selectedMethod = storeSalePayments.find(item => item.paymentMethodCode === code)
        if (selectedMethod && selectedMethod.saleAmount) {
            return selectedMethod.saleAmount
        }
        return 0
    }

    filterCallback = (item, type) => {
        if (type === "") {
            return item.paymentMethodType !== PAYMENT_TYPE.p
        } else {
            return item.paymentMethodType === type
        }

    };

    isDisabledSubmitBtn = () => {
        const { selectedPaymentTerms, storeSalePayments, price, sumAmount, priceAfterTaxes, paymentStatus } = this.state;
        if(paymentStatus === "SUCCESS"){
            return false;
        }

        if (price < 0) {
            return sumAmount === priceAfterTaxes
        }

        if(priceAfterTaxes > 0 && storeSalePayments.length && [PAYMENT_CODES.c1, PAYMENT_CODES.c2].includes(storeSalePayments[0].paymentMethodCode) && sumAmount > priceAfterTaxes) {
            return false
        }

        return (selectedPaymentTerms === PAYMENT_CODES.c1 && sumAmount && price - sumAmount < 0.0001) ||
            (selectedPaymentTerms === PAYMENT_CODES.c2 && sumAmount && sumAmount < price) ||
            selectedPaymentTerms === PAYMENT_CODES.c3 ||
            (sumAmount && sumAmount >= priceAfterTaxes && selectedPaymentTerms !== PAYMENT_CODES.c2) || (price === 0 && !isEmptyBasket());
    }

    isDisabledPaymentMethods = (item) => {
        const { selectedPaymentTerms, price, storeSalePayments } = this.state;
        const { basket: { ticket } } = this.props;

        if (price < 0 && storeSalePayments.length) {
            if (this.isActiveMethod(PAYMENT_CODES.c9)) {
                return PAYMENT_CODES.c9 !== item.code
            }
            if (PAYMENT_CODES.c9 === item.code) {
                return true
            }
        }

        if (price === 0) {
            return item.code !== PAYMENT_CODES.c5
        }

        if(
            ticket && ticket.isTicketDelete && item.code === PAYMENT_CODES.c4
            || ticket && ticket.isTicketDelete && item.code === PAYMENT_CODES.c6
        ) {
            return false;
        }

        return selectedPaymentTerms === PAYMENT_CODES.c3
            || (price < 0 && [PAYMENT_CODES.c4, PAYMENT_CODES.c6, PAYMENT_CODES.c7, PAYMENT_CODES.c8, PAYMENT_CODES.c10].includes(item.code))
            || (price > 0 && (item.code === PAYMENT_CODES.c9 || item.code === PAYMENT_CODES.c8))
            || item.code === PAYMENT_CODES.c3 || (item.code === PAYMENT_CODES.c4 && STORE_COUNTRY === STORE_COUNTRIES.it)
    }

    isDisabledPaymentTerms = (code) => {
        const { ticket = {} } = this.props.basket;

        if(ticket.paymentTermCode === PAYMENT_CODES.c4) {
            return code !== PAYMENT_CODES.c4;
        };

        if (code === PAYMENT_CODES.c2 && +ticket.paymentDue) {
            return true;
        };

        if (this.props.webOrder) {
            return code !== PAYMENT_CODES.c5;
        };

        if (this.state.price < 0) {
            return code === PAYMENT_CODES.c2 || code === PAYMENT_CODES.c3;
        };

        if (!getActiveBasketClientId()) {
            return code === PAYMENT_CODES.c5 || code === PAYMENT_CODES.c7;
        ;}

        return false;
    }

    isActiveMethod = (code) => {
        const { storeSalePayments } = this.state;

        if (code) {
            return !!storeSalePayments.find(item => item.paymentMethodCode === code);
        }
        return false;
    }

    handleClose = () => {
        if (this.state.paymentStatus !== "SUCCESS") {
            this.props.handleClose()
        }
    };

    handleDoubleClick = (code, itemValue) => () => {
        const { storeSalePayments, sumAmount, priceAfterTaxes: price } = this.state;
        // if (price < 0 && storeSalePayments.length && code === PAYMENT_CODES.c9) {
        //     return;
        // }
        if (price < 0) {
            return;
        }

        this.redirectClientsPage(code);

        if (getBasketData(this.props.basket).price === 0) return;

        const findItem = storeSalePayments.find(item => item.paymentMethodCode === code);
        let value = findItem && findItem.saleAmount ? findItem.saleAmount : 0;

        if (storeSalePayments.length === 1) {
            if (price > 0) {
                value = sumAmount
            } else if (price < 0) {
                value = Math.abs(sumAmount) * -1
            }
        }

        if (price > 0 && price > sumAmount) {
            value = price - sumAmount
        } else if (price < 0 && Math.abs(price) > Math.abs(sumAmount)) {
            value = (Math.abs(price) - Math.abs(sumAmount)) * -1
        }

        if (price === sumAmount && !itemValue) {
            value = itemValue
        }
        if (price < sumAmount) {
            value = 0
        }

        this.setState({ isDisabledClick: true, activeMethod: code, value: fixNumber(value) })
    };

    handleKeyDown = (item) => (e) => {
        if (e.keyCode === 13) {
            this.handleSetMethod(item)(e)
        }
        if (!this.state.isKeyDown) {
            this.setState({ value: '', isKeyDown: true })
        }
    }

    handleChange = (e) => {
        this.setState({ value: e.target.value })
    }

    setStoreSalePayments = (salePayments) => {
        let storeSalePayments = cloneObject(salePayments)
        const { priceAfterTaxes, sumAmount, price } = this.state;
        const cashMethod = storeSalePayments.find(item => item.paymentMethodCode === PAYMENT_CODES.c5)

        if ((price < 0 && isTaxesCountry()) || !cashMethod) {
            return storeSalePayments;
        }

        const filteredPayments = storeSalePayments.filter(item => item.paymentMethodCode !== PAYMENT_CODES.c5)
        cashMethod.saleAmount = +(cashMethod.saleAmount - (sumAmount - priceAfterTaxes > 0 ? customRoundByCountry(sumAmount - priceAfterTaxes) : 0)).toFixed(2);
        cashMethod.saleTax = this.getTaxRate(cashMethod.saleAmount)
        storeSalePayments = [...filteredPayments, cashMethod]

        return storeSalePayments
    }

    handleChangeMethod = (item) => {
        if (!item.code) return;

        this.redirectClientsPage(item.code)

        const { priceAfterTaxes, taxes } = this.state

        const storeSalePayments = [{
            paymentMethodCode: item.code,
            paymentMethodType: item.paymentMethodType,
            saleAmount: priceAfterTaxes,
            saleTax: taxes
        }]
        this.setState({ storeSalePayments, subPayment: {}, sumAmount: this.getSumAmount(storeSalePayments) })
    };

    redirectClientsPage = (code) => {
        const currentBasketName = getActiveBasketName();
        const clientData = this.props.storeClient[currentBasketName] || {};
        const clientCode = clientData.profile ? clientData.profile.clientCode : 0;
        if (code === PAYMENT_CODES.c9 && !clientCode && !getActiveBasketClientId()) {
            // history.push(`${getMenuItemCanonicalUrl('page', 'clients', this.props.menu)}`);
            this.setState({ isBasketClient: true });
        }
    }

    handleSetMethod = (element) => (e) => {
        this.setState({ isKeyDown: false })
        let { name, value } = e.target;
        const { storeSalePayments, price } = this.state;
        let paymentMethod = storeSalePayments.filter(item => item.paymentMethodCode !== name);
        let saleAmount = Math.abs(value)
        if (price < 0) {
            saleAmount = Math.abs(value) * -1
        }
        paymentMethod = [...paymentMethod, ...[{
            paymentMethodCode: name,
            paymentMethodType: element.paymentMethodType,
            saleAmount: saleAmount ? +saleAmount.toFixed(2) : 0,
            saleTax: this.getTaxRate(saleAmount),
        }]];
        this.setState({
            storeSalePayments: paymentMethod.filter(item => +item.saleAmount !== 0),
            activeMethod: "",
            value: "",
            sumAmount: this.getSumAmount(paymentMethod),
            subPayment: {}
        });
    }

    handleChangePaymentTerms = (code) => {
        if (getBasketData(this.props.basket).price !== 0) {
            this.setState({ storeSalePayments: [] })
        }
        this.setState({
            selectedPaymentTerms: code,
            isDisabledClick: false,
            sumAmount: 0
        }, () => code === PAYMENT_CODES.c3 ? this.handleChangeMethod(code) : "")
    }

    validateClientData = (clientData) => {
        const billingAddresses = clientData && clientData.billingAddresses && clientData.billingAddresses[0];
        if(billingAddresses){
            return (
                billingAddresses.name &&
                billingAddresses.address1 &&
                billingAddresses.country &&
                billingAddresses.city &&
                billingAddresses.email &&
                billingAddresses.zip
            )
        }else{
            return false;
        }
    }

    handleSubmit = () => {
        if (!this.isDisabledSubmitBtn()) {
            return;
        }

        const { storeClient, basket, webOrder, accountResource } = this.props;
        const { selectedPaymentTerms, storeSalePayments, sumAmount, priceAfterTaxes, price, subPayment } = this.state;
        const currentBasketName = getActiveBasketName();
        const clientData = storeClient[currentBasketName] || {};

        if(
            webOrder === true && selectedPaymentTerms !== '005' ||
            !this.validateClientData(clientData) && selectedPaymentTerms === '005'
        ) {
            this.setState({
                isOpenInfoPopUp: true,
                infoPopupMessage: `${i18next.t(
                    'alertMessage.12',
                )}`,
                isClientEmailOrAddressEmpty: true,
            });
            return;
        }

        if (this.props.isDisconnected) {
            setOfflinePay({ selectedPaymentTerms, storeSalePayments, subPayment })
            this.props.handleClose()
            return;
        }

        if (accountResource.printer && STORE_COUNTRY !== STORE_COUNTRIES.it) {
            printerOpenCashDrawer()
        }

        if (
            STORE_COUNTRY === STORE_COUNTRIES.it &&
            selectedPaymentTerms === PAYMENT_CODES.c7
        ) {
            const message = getItClientMandatoryFields(clientData);

            if (message) {
                this.setState({
                    isOpenInfoPopUp: true,
                    infoPopupMessage: `${i18next.t(
                        'alertMessage.9',
                    )}: ${message}`,
                });
                return;
            }
        }

        const { totalQuantity } = getBasketData(basket);
        const clientCode = clientData.profile ? clientData.profile.clientCode : 0;
        const giftAmount = storeSalePayments.find(item => item.paymentMethodCode === PAYMENT_CODES.c9);
        let giftData: any = null;

        if (webOrder && !clientCode) {
            this.setState({ isOpenInfoPopUp: true, infoPopupMessage: i18next.t('alertMessage.6') })
            return;
        }

        const change = sumAmount > 0 && sumAmount - priceAfterTaxes > 0 ? customRoundByCountry(sumAmount - priceAfterTaxes) : 0;
        const cashData = storeSalePayments.find(item => item.paymentMethodCode === PAYMENT_CODES.c5);
        let cash = null
        if (cashData && change) {
            cash = cashData.saleAmount
        }
        this.props.handleChangeUserPay({
            sumAmount,
            priceAfterTaxes,
            totalQuantity,
            change,
            cash,
            isAvoir: !!giftAmount
        })

        if (giftAmount && giftAmount.saleAmount && !clientCode) {
            this.setState({ showMessage: true })
            return;
        }

        if (giftAmount && giftAmount.saleAmount && price < 0) {
            giftData = {
                active: true,
                qrCode: null,
                percent: null,
                barcode: null,
                storeGiftId: null,
                redemptionDate: null,
                startDate: new Date().getTime(),
                currency: DEFAULT_CURRENCY,
                endDate: addDays(new Date(), GIFT_DURATION).getTime(),
                amount: +Math.abs(giftAmount.saleAmount).toFixed(2),
                credit: true,
                clientCode
            }
            if(STORE_COUNTRIES.it === STORE_COUNTRY) {
                this.setState({isFiscalBuono: true})
            }
        }
        const storeSalePaymentsArr = storeSalePayments.map(item => {
            if (item.paymentMethodCode === subPayment.subPaymentMethodType) {
                return { ...item, ...subPayment }
            } else {
                return { ...item }
            }

        })
        this.props.handleSetMethod({ selectedPaymentTerms, storeSalePayments: this.setStoreSalePayments(storeSalePaymentsArr), giftData })

    }

    handleSubmitPayment = () => {
        this.props.getCurrentDaySaleStart()
        destroyActiveBasket()
        this.props.getBasketStart()
        this.props.basketStoreGiftsStart()
        this.props.resetStoreClient(getActiveBasketName())
        this.props.handleClose()
        this.props.resetAction("")
    }

    printTicket = (isBtn = false, facture = false) => {
        const { createdData, accountResource } = this.props;
        const { ticketNumber, paymentTermCode } = createdData;
        const { printer, storeDetails = {} } = accountResource

        if (!printer || !ticketNumber) return;

        const isPay = !!this.state.storeSalePayments.find(item => item.paymentMethodCode === PAYMENT_CODES.c9) && isBtn
        let type = TICKET_TYPE.ticket;
        let receiptType = RECEIPT_TYPE.ticket;

        if (paymentTermCode === PAYMENT_CODES.c2) {
            type = TICKET_TYPE.acompte;
            receiptType = RECEIPT_TYPE.advance
        }
        
        if (facture) {
            receiptType = RECEIPT_TYPE.invoice
            type = TICKET_TYPE.facture
        }

        if (![STORE_COUNTRIES.de, STORE_COUNTRIES.at].includes(storeDetails.country)) {
            this.props.printTicketStart({ ticketNumber, receiptType, type, isPay })
        }
    }

    handleSetSubPayment = (item: any = {}) => {
        const { subPayment } = this.state;
        const subPay: any = {}

        if (item.code && subPayment["subPaymentMethodCode"] !== item.code) {
            subPay.subPaymentMethodCode = item.code;
            subPay.subPaymentMethodType = item.paymentMethodType;
        }

        this.setState({ subPayment: subPay })
    }

    isActiveSubMethod = (code) => {
        const { subPayment } = this.state;

        if (subPayment["subPaymentMethodCode"] === code) {
            return true
        }

        return false
    }

    isActiveSubPay = (type) => {
        const { storeSalePayments } = this.state;
        if (!storeSalePayments.length || storeSalePayments.findIndex(item => item.paymentMethodCode === type) !== -1) {
            return false
        }
        return true

    }

    handleremoveNumber = (key) => {
        if (key === "backspace") {
            this.setState({ value: "" })
        }
    }

    handleProducts = () => {
        this.handleSubmitPayment();
        history.push({ pathname: `${getMenuItemCanonicalUrl('page', 'sales', this.props.menu)}`, state: { showProductsPanel: true }, });
    };

    handleCloseInfoPopUp = () => {
        const clientData = this.props.storeClient[getActiveBasketName()] || {};
        this.setState({ isOpenInfoPopUp: false })
        if (this.state.isClientEmailOrAddressEmpty) {
            this.setState({ isClientEmailOrAddressEmpty: false })
            localStorage.emptyEmailOrAddressClientId = clientData.profile.clientId;
            history.push(`${getLangPrefix()}/clients`);
        } else {
            localStorage.removeItem('emptyEmailOrAddressClientId');
        }
    }

    render(): React.ReactNode {
        const { 
            isLoading, paymentTerms = [], isGiftLoading, createdData, accountResource, 
            itPrinterError, fiscalData = {}, ticketData = {}, fiscalError, basket = {}
        } = this.props;
        const {
            selectedPaymentTerms, isDisabledClick, activeMethod, value, taxes, price, sumAmount, showMessage, showPrinterError,
            priceAfterTaxes, paymentStatus, isOpenInfoPopUp, isShowClientBtn, paymentMethodsNoType, paymentMethodsTypeP,
            isBasketClient, storeSalePayments
        } = this.state;
        const { clientEmail } = createdData;
        const { printer, storeDetails = {} } = accountResource;
        const country = storeDetails && storeDetails.country;
        const {profile: {clientId = null} = {}} = this.props.storeClient[getActiveBasketName()] || {};
        const { referenceTicket, paymentAmount } = ticketData;

        return (
            <StoreFormSidePanel handleClose={this.handleClose}>
                {
                    isLoading ?
                        <Loading />
                        :
                        <div className="paymentRightPanel">
                            <div className="paymentRightPanel--amountBox">
                                <div className={`paymentRightPanel--amountBox--row  ${price < 0 ? "revert" : ""}`}>
                                    <p className="paymentRightPanel--amountBox--row--title totalPrice">{i18next.t('Terminal.Block1.5')}</p>
                                    <p className="paymentRightPanel--amountBox--row--description totalPrice">{replaceComma(priceAfterTaxes)} {getCurrency()}</p>
                                </div>
                                <div className={`paymentRightPanel--amountBox--row ${taxes < 0 ? "revert" : ""}`}>
                                    <p className="paymentRightPanel--amountBox--row--title">{i18next.t('Terminal.Block1.4')}</p>
                                    <p className="paymentRightPanel--amountBox--row--description">{replaceComma(taxes)} {getCurrency()}</p>
                                </div>
                            </div>
                            <div className="paymentRightPanel--buttonsArea">
                                <div className="paymentRightPanel--buttonsArea--box">
                                    {
                                        !paymentStatus ? paymentTerms.filter(i => i.code !== PAYMENT_CODES.c3).map(item => {
                                            let buttonClassName = "btn_1 w-100";

                                            buttonClassName += selectedPaymentTerms === item.code ? ' active' : '';
                                            buttonClassName += item.code === PAYMENT_CODES.c5 ? ' blue_btn_opposite' : '';

                                            return (
                                                <CustomBtn
                                                    key={item.code}
                                                    classN={buttonClassName}
                                                    handleClick={() => this.handleChangePaymentTerms(item.code)}
                                                    disabled={this.isDisabledPaymentTerms(item.code)}
                                                >
                                                    {item.name}
                                                </CustomBtn>
                                            )
                                        }) : ""
                                    }
                                </div>
                                <div className="paymentRightPanel--buttonsArea--box">
                                    {!paymentStatus ? paymentMethodsTypeP.map(item => {
                                        const itemValue = this.getItemMethodAmount(item.code);
                                        const disabled = this.isDisabledPaymentMethods(item);
                                        return (
                                            <div key={item.code}>
                                                {activeMethod !== item.code ?
                                                    <CustomBtn
                                                        classN={`btn_1 w-100 ${this.isActiveMethod(item.code) ? " active" : ""}`}
                                                        handleClick={() => !isDisabledClick ? this.handleChangeMethod(item) : () => { }}
                                                        handleDoubleClick={this.handleDoubleClick(item.code, itemValue)}
                                                        disabled={disabled}
                                                    >
                                                        {item.name} {isDisabledClick && itemValue ? `(${replaceComma(itemValue)} ${getCurrency()})` : ""}
                                                    </CustomBtn>
                                                    :
                                                    <KeyboardEventHandler
                                                        handleKeys={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'backspace']}
                                                        onKeyEvent={(key) => this.handleremoveNumber(key)} >
                                                        <input
                                                            className={`btn_1 w-100 btn--to--input ${disabled ? "disabled" : ""}`}
                                                            type="number"
                                                            autoFocus={true}
                                                            name={item.code}
                                                            value={value}
                                                            onChange={this.handleChange}
                                                            onBlur={this.handleSetMethod(item)}
                                                            onClick={this.handleSetMethod(item)}
                                                            onKeyDown={this.handleKeyDown(item)}
                                                            disabled={disabled}
                                                        />
                                                    </KeyboardEventHandler>
                                                }
                                            </div>
                                        )
                                    }) : ""}

                                </div>
                                <div className="paymentRightPanel--buttonsArea--box">

                                    {!paymentStatus ?
                                        paymentMethodsNoType.map(item => {
                                            const disabled = this.isActiveSubPay(item.paymentMethodType) || !storeSalePayments.length;
                                            return (
                                                <CustomBtn
                                                    key={item.code}
                                                    classN={`btn_1 w-100 ${this.isActiveSubMethod(item.code) ? " active" : ""}`}
                                                    handleClick={() => this.handleSetSubPayment(item)}
                                                    disabled={disabled}
                                                >
                                                    {item.name}
                                                </CustomBtn>
                                            )
                                        }) : ""
                                    }

                                </div>
                            </div>

                            <div className="paymentRightPanel--amountBox">
                                <div className={`paymentRightPanel--amountBox--row ${sumAmount < 0 ? "revert" : ""}`}>
                                    <p className="paymentRightPanel--amountBox--row--title">
                                        {
                                            sumAmount < 0 ?
                                                this.state.storeSalePayments.find(item => item.paymentMethodCode === PAYMENT_CODES.c9) ?
                                                    `${i18next.t('Terminal.Receipt.Gift.Avoir')}:` : i18next.t('Terminal.payment.7')
                                                :
                                                i18next.t('Terminal.payment.1')
                                        }
                                    </p>
                                    <p className="paymentRightPanel--amountBox--row--description">{replaceComma(sumAmount)} {getCurrency()}</p>
                                </div>
                                <div className="paymentRightPanel--amountBox--row">
                                    <p className="paymentRightPanel--amountBox--row--title">{i18next.t('Terminal.payment.2')}</p>
                                    <p className="paymentRightPanel--amountBox--row--description">{replaceComma(sumAmount > 0 && sumAmount - priceAfterTaxes > 0 ? customRoundByCountry(sumAmount - priceAfterTaxes) : 0)} {getCurrency()}</p>
                                </div>
                            </div>

                            <CustomBtn
                                classN="btn_1 green_btn w-100"
                                loading={isGiftLoading}
                                disabled={this.props.createLoading || !this.isDisabledSubmitBtn()}
                                handleClick={this.handleSubmit}
                            >
                                {i18next.t('Terminal.Block3.2')}
                            </CustomBtn>

                            {paymentStatus === "SUCCESS" &&
                                <>
                                    <button className={`btn_1 red_btn w-100`} onClick={this.handleSubmitPayment}>
                                        {i18next.t('Terminal.Block3.4')}
                                    </button>
                                    <p className="success--message">
                                        {i18next.t('Terminal.payment.3')}
                                    </p>
                                    {
                                        storeDetails.country === STORE_COUNTRIES.it && showPrinterError ?
                                            <p className="error--message">
                                                {itPrinterError}
                                            </p>
                                            : ""
                                    }
                                    {STORE_COUNTRIES.it === country && selectedPaymentTerms === PAYMENT_CODES.c7 ?
                                        <FatturaBtn
                                            className={`btn_1 w-100 blue_btn`}
                                            details={accountResource}
                                            ticketNumber={createdData.ticketNumber}
                                            clientId={clientId}
                                        />
                                        :
                                        ''
                                    }
                                </>
                            }

                            {
                                paymentStatus === "FAILED" && <p className="error--message">{fiscalError ? fiscalError :  i18next.t('Terminal.payment.4')}</p>
                            }

                            {
                                showMessage && <p className="error--message">{i18next.t('Terminal.payment.5')}</p>
                            }

                            {
                                paymentStatus === "SUCCESS" && isShowClientBtn ?
                                    <>
                                        <p className="success--message">
                                            {i18next.t('Terminal.payment.6', { clientEmail })}
                                        </p>
                                        <button
                                            className={`btn_1 w-100 green_btn${printer ? "" : " disabled"}`}
                                            disabled={!printer}
                                            onClick={() => this.printTicket(true)}
                                        >
                                            {i18next.t('Terminal.Block3.5')}
                                        </button>
                                        {
                                            printer ? !(this.state.storeSalePayments.find(item => item.paymentMethodCode === PAYMENT_CODES.c9)) &&
                                                <button
                                                    className={`btn_1 w-100 blue_btn`}
                                                    onClick={() => this.printTicket(true, true)}
                                                >
                                                    {i18next.t('Terminal.Receipt.Sale.facture')}
                                                </button>
                                                : ''
                                        }

                                    </>
                                    :
                                    ""
                            }
                            {
                                fiscalData.statusCode === 'ERROR' && paymentStatus ?
                                    <>
                                        <p className="error--message">{`${fiscalData.statusCode} (${fiscalData.transactionStatusCode})`}</p>
                                        <p className="error--message">{fiscalData.message}</p>
                                    </>
                                    : ""
                            }
                            {paymentStatus === "SUCCESS" &&
                                <button
                                    className={`btn_1 w-100 pink_btn${printer ? "" : " disabled"}`}
                                    disabled={!printer}
                                    onClick={this.handleProducts}
                                >
                                    {i18next.t('buttons.21')}
                                </button>
                            }
                            {
                                paymentStatus === "SUCCESS" && printer && STORE_COUNTRIES.it === country
                                    && paymentAmount < 0 && !referenceTicket ?
                                    <p className="error--message">{i18next.t('Terminal.payment.9')}</p>
                                    : ''
                            }
                        </div>
                }
                <InfoPopUp
                    open={isOpenInfoPopUp}
                    message={this.state.infoPopupMessage}
                    classN="errorMessage"
                    handleClose={this.handleCloseInfoPopUp}
                />
                <InfoPopUp
                    open={isBasketClient}
                    message={i18next.t("Terminal.quote.1")}
                    handleClose={() => history.push(`${getMenuItemCanonicalUrl('page', 'clients', this.props.menu)}`)}
                />
            </StoreFormSidePanel>
        );
    }
}
const mapStateToProps = (state: any) => {
    const { paymentMethods = [], paymentTerms = [] } = getStoresForm(state)
    return {
        paymentMethods: paymentMethods || [],
        paymentTerms: paymentTerms || [],
        isLoading: getLoading(state),
        accountResource: getAccountResource(state),
        storeClient: getStoreClient(state),
        status: getStatus(state),
        createdData: getCreatedStore(state),
        isDisconnected: getConnectedStatus(state),
        fiscalData: getFiscalData(state),
        ticketData: getprintTicketData(state),
        menu: getMenu(state),
        fiscalError: getFiscalError(state),
        giftPrinterStatus: getGiftPtinterStatus(state)
    }
};

const mapDispatchToProps = (dispatch): typeof PaymentActionCreators => {
    return bindActionCreators(PaymentActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSidePanel);