import { clientTypes, ClientActionCreators } from './actions';
import { takeLatest, put, call, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
    CLIENT_URL,
    CREATE_STORE_CLIENT,
    CREATE_STORE_CLIENT_ADDRESS,
    fetchTop10Clients,
    getAxiosHeaders,
    SINGLE_CLIENT_URL,
    subscribeEmail,
} from '../../../utils/constants/service';
import { fixNumber, getApiPrefix, getLocalFormData, getLocalStorageObject } from '../../../helpers/helpers';
import { getAccountResource } from '../../login/redux/selectors';
import { getSingleClient, getStoreClient as getStoreClientSelector } from './selectors';
import { AddToCartActionCreators } from '../../addToCart/redux/actions';
import { DEFAULT_CURRENCY } from '../../../utils/constants/variables';

export function* getClientData(params) {
    const { data: { clientId = '', code = '' } = {} } = params;
    try {
        const { storeCode } = yield select(getAccountResource);

        const data = yield call(() => {
            return axios
                .get(
                    `${CLIENT_URL}?storeCode=${code || storeCode}`,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        yield put(ClientActionCreators.clientSuccess(data));

        if (clientId) {
            yield put(ClientActionCreators.setUniqueId(clientId));
        }
    } catch (e) {
        yield put(ClientActionCreators.clientFailure(e instanceof Error ? e.message : 'Unknown Error'));
    }
}
export function* getSingleClientData(params) {
    const clientId = params.id;
    try {
        const data = yield call(() => {
            return axios
                .get(
                    `${SINGLE_CLIENT_URL}${clientId}?language=${getApiPrefix()}`,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });
        yield put(ClientActionCreators.singleClientSuccess(data));
    } catch (e) {
        yield put(ClientActionCreators.singleClientFailure(e instanceof Error ? e.message : 'Unknown Error'));
    }
}

export function* clientEditNew(params) {
    try {
        const { currency = DEFAULT_CURRENCY } =
            yield select(getAccountResource);
        const body = {...params.body, currency};
        const data = yield call(() => {
            return axios
                .post(`${CREATE_STORE_CLIENT}`, body, getAxiosHeaders())
                .then((res) => res.data);
        });

        yield put(ClientActionCreators.clientNewEditSuccess(data));
    } catch (e) {
        yield put(ClientActionCreators.clientNewEditFailure(e instanceof Error ? e.message : 'Unknown Error'));
    }
}

export function* setClient(params) {
    try {
        let address = {};
        const { client = {} } = params;
        const { currency = DEFAULT_CURRENCY, mailingId, storeDetails: { brandId = null } = {} } =
            yield select(getAccountResource);
        const { profile = {} } = yield select(getSingleClient);
        client.currency = currency;
        const data = yield call(() => {
            return axios
                .post(`${CREATE_STORE_CLIENT}`, client, getAxiosHeaders())
                .then((res) => res.data);
        });

        const addressData = {
            clientAddressId: client.clientAddressId,
            storeClientId: client.storeClientId,
            address1: client.address1,
            address2: client.address2,
            address3: null,
            isDefault: false,
            city: client.city,
            firstName: client.firstName,
            lastName: client.lastName,
            zip: client.zip,
            country: client.country,
            email: client.email,
            phone: client.phone,
        };

        if (
            client.address1 &&
            client.city &&
            client.zip &&
            client.country &&
            (client.storeClientId || (data && data.storeClientId))
        ) {
            if (data && data.storeClientId) {
                addressData['storeClientId'] = data.storeClientId;
            }
            address = yield call(() => {
                return axios
                    .post(
                        `${CREATE_STORE_CLIENT_ADDRESS}`,
                        addressData,
                        getAxiosHeaders(),
                    )
                    .then((res) => res.data);
            });
        }

        if (client.storeClientId) {
            yield put(ClientActionCreators.setUniqueId(client.storeClientId));
        }
        yield put(ClientActionCreators.setClientSuccess({ address, data }));

        yield put(
            ClientActionCreators.clientStart({
                clientId: client.storeClientId ? '' : data.storeClientId,
                code: client.storeCode,
            }),
        );
        
        if (
            mailingId &&
            client.newsletterOptin &&
            client.email &&
            !profile.email
        ) {
            yield call(() =>
                subscribeEmail({
                    brandId,
                    email: client.email,
                    mailingID: mailingId,
                }),
            );
        }
    } catch (e) {
        yield put(ClientActionCreators.setClientFailure(e instanceof Error ? e.message : 'Unknown Error'));
    }
}

export function* getStoreClient(params) {
    const { clientId, basketName } = params;
    const { clientTypeLabels = [], storeSpecialDiscounts = [] } = getLocalFormData();
    const empLabel = clientTypeLabels.find(label => label.code === "EMP");
    try {
        const data = yield call(() => {
            return axios
                .get(
                    `${SINGLE_CLIENT_URL}${clientId}?language=${getApiPrefix()}`,
                    getAxiosHeaders(),
                )
                .then((res) => res.data);
        });

        const basket = getLocalStorageObject(basketName);
        const { ticket } = basket;
        const value = empLabel.discountRate * 100;
        const discountDetail = `-${fixNumber(value) || 0}%`;
        const isEmpUser =
            data && data.profile && data.profile.clientType === "EMP"
            && empLabel && empLabel.discountRate;
        const { storeSpecialDiscountId, specialDiscount, code } = storeSpecialDiscounts.find(
            specialDiscount => specialDiscount.code === "EMP"
        ) || { storeSpecialDiscountId: 0, specialDiscount: '', code: '' }
        let isChangeFromEmp = false;

        isEmpUser && basket.sousTotal && delete basket.sousTotal;
        Object.keys(basket).map((key: any) => {
            const isClientDiscountAllow = basket[key] && basket[key].isClientDiscountAllow;
            const length = basket[key] && basket[key].sizes && basket[key].sizes.length || 0;
            for (let i = 0; i < length; i++) {
                let isAlreadyEmpDiscount = basket[key].sizes[i] && basket[key].sizes[i].specialDiscountId
                    && basket[key].sizes[i].specialDiscountId.storeSpecialDiscountId === storeSpecialDiscountId
                    && basket[key].sizes[i].listPriceAfterDiscount ===
                        (basket[key].sizes[i].isPromo
                            ? basket[key].sizes[i].promoListPriceAfterDiscount - empLabel.discountRate * basket[key].sizes[i].promoListPriceAfterDiscount
                            : basket[key].sizes[i].startingPrice - empLabel.discountRate * basket[key].sizes[i].startingPrice);

                if(isEmpUser && isClientDiscountAllow) {
                    basket[key].sizes[i].discountDetail = discountDetail;
                    basket[key].sizes[i].listPriceAfterDiscount = basket[key].sizes[i].isPromo
                        ? basket[key].sizes[i].promoListPriceAfterDiscount - empLabel.discountRate * basket[key].sizes[i].promoListPriceAfterDiscount
                        : basket[key].sizes[i].startingPrice - empLabel.discountRate * basket[key].sizes[i].startingPrice;
                    basket[key].sizes[i].specialDiscountId = {
                        storeSpecialDiscountId,
                        specialDiscount,
                        code
                    }
                } else if(isAlreadyEmpDiscount && !(isEmpUser && isClientDiscountAllow)) {
                    isChangeFromEmp = true;
                    if(basket[key].sizes[i].isPromo) {
                        basket[key].sizes[i].listPriceAfterDiscount = basket[key].sizes[i].promoListPriceAfterDiscount;
                        basket[key].sizes[i].discountDetail = basket[key].sizes[i].promoDiscountDetail;
                    } else {
                        delete basket[key].sizes[i].discountDetail;
                        delete basket[key].sizes[i].listPriceAfterDiscount;
                    }
                    delete basket[key].sizes[i].specialDiscountId;
                }
            }
        })

        if((isChangeFromEmp || isEmpUser) && !ticket) {
            localStorage.setItem(basketName, JSON.stringify(basket));
            yield put(AddToCartActionCreators.addtocartSuccess({added: true, basket}));
        }
        yield put(
            ClientActionCreators.storeClientSuccess({ [basketName]: data }),
        );
    } catch (e) {
        console.log(e);
        yield put(ClientActionCreators.storeClientFailure(e instanceof Error ? e.message : 'Unknown Error'));
    }
}

export function* fetchTopClients(params) {
    try {
        const data = yield call(() => fetchTop10Clients(params.data));
        yield put(ClientActionCreators.topClientsSuccess(data));
    } catch (e) {
        yield put(ClientActionCreators.topClientsFailure(e instanceof Error ? e.message : 'Unknown Error'));
    }
}

export function* resetStoreClient(params) {
    const { basketName } = params;
    const { clientTypeLabels = [], storeSpecialDiscounts = [] } = getLocalFormData();
    const data = yield select(getStoreClientSelector);
    const basket = getLocalStorageObject(basketName);
    const empLabel = clientTypeLabels.find(label => label.code === "EMP");
    const isEmpUser = data && data[basketName] && data[basketName].profile
        && data[basketName].profile.clientType === "EMP" && empLabel && empLabel.discountRate;

    if(isEmpUser) {
        Object.keys(basket).map((key: any) => {
            const length = basket[key] && basket[key].sizes && basket[key].sizes.length || 0
            for (let i = 0; i < length; i++) {
                if(basket[key].sizes[i].isPromo) {
                    basket[key].sizes[i].listPriceAfterDiscount = basket[key].sizes[i].promoListPriceAfterDiscount;
                    basket[key].sizes[i].discountDetail = basket[key].sizes[i].promoDiscountDetail;
                } else {
                    delete basket[key].sizes[i].discountDetail;
                    delete basket[key].sizes[i].listPriceAfterDiscount;
                }
                delete basket[key].sizes[i].specialDiscountId;
            }
        })
        localStorage.setItem(basketName, JSON.stringify(basket));
    }
    yield put(ClientActionCreators.resetStoreClientSuccess(basketName));
}

export function* clientSaga() {
    yield takeLatest(clientTypes.CLIENT_START, getClientData);
    yield takeLatest(clientTypes.SINGLE_CLIENT_START, getSingleClientData);
    yield takeEvery(clientTypes.SET_CLIENT_START, setClient);
    yield takeEvery(clientTypes.STORE_CLIENT_START, getStoreClient);
    yield takeEvery(clientTypes.TOP_CLIENTS_START, fetchTopClients);
    yield takeEvery(clientTypes.RESET_STORE_CLIENT, resetStoreClient);
}

export default clientSaga;
