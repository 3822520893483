import React from 'react';
import {connect} from 'react-redux';
import i18next from "i18next";

import {getMenu} from "../main/header/redux/selectors";
import {MenuActionCreators} from "../main/header/redux/actions";
import {checkIsActiveBasketNotEmpty, destroyActiveBasket, findNodeByProperty, getActiveBasketName, getCatalogueParentMenuId, getCurrentBarcode, getLangPrefix, updateBasketAfterChangeCurrency} from "../../helpers/helpers";
import {getProducts, getSelectedMenuId} from "../../modules/products/redux/selectors";
import {getError, getHome} from "../../modules/home/redux/selectors";
import {HomeActionCreators} from "../../modules/home/redux/actions";
import CommonCurrentPage from './commonCurrentPage';
import './currentPage.scss'
import {getProduct} from "../../modules/product/redux/selectors";
import {DEFAULT_CURRENCY} from "../../utils/constants/variables";
import {getAccountResource} from "../../modules/login/redux/selectors";
import history from "../../helpers/history";
import { bindActionCreators } from 'redux';

interface Props {
    menuList: any,
    products: any,
    selectedMenuId: any,
    text?: any,
    data: any,
    error: any,
    showDescription?: boolean,
    showPageMetaData?: boolean,
    productData?: any,
    selectedParent?: any,
    account: any,
}

interface State {
    selectedMenuItem: any,
    styled: boolean,
    showPageMetaData: boolean,
    customTabs: any
}

let navTabs: any = [];

class CurrentPage extends React.Component<Props & typeof MenuActionCreators, typeof HomeActionCreators> {
    state: State = {
        selectedMenuItem: {},
        styled: false,
        showPageMetaData: typeof this.props.showPageMetaData === "undefined" ? true : this.props.showPageMetaData,
        customTabs: []
    };

    componentDidMount() {
        if (this.props.selectedItem) {
            if(this.props.selectedItem.customMenu){
                const customTabs: any = []
                customTabs.push(
                    {name: i18next.t("home.page"), url: getLangPrefix()},
                    {...this.props.selectedItem}
                )
                this.setState({customTabs})
            }else {
                this.getSelectedMenuAndTabs(this.props.selectedItem.menuId, this.props.selectedParent);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedItem,account} =this.props;
        if(Object.keys(prevProps.selectedItem).length && !Object.keys(selectedItem).length){
            const altCurrency = account && account.altCurrency;
            const altCurrencySymbol = account && account.altCurrencySymbol;
            const currency = account && account.currency;
            const defaultCurrencySymbol = account && account.currencySymbol;
            navTabs = [
                {
                    name: i18next.t("home.page"),
                    url: getLangPrefix(),
                    currency,
                    defaultCurrencySymbol,
                    altCurrency,
                    altCurrencySymbol
                }
                ]
            this.setState({customTabs: []});
        }
        if(this.props.selectedItem.customMenu && (!this.state.customTabs.length || this.props.selectedItem.name !== this.state.customTabs[1].name)){
            const customTabs:any = []
            customTabs.push(
                {name: i18next.t("home.page"), url: getLangPrefix(),currency: DEFAULT_CURRENCY},
                {...this.props.selectedItem}
            );
            this.setState({customTabs});
        }else {
            // if (prevProps.productData.productId !== this.props.productData.productId) {
            //     this.getSelectedMenuAndTabs(this.props.selectedItem.menuId);
            // }

            if (this.props.selectedItem.menuId && (prevProps.selectedItem.menuId !== this.props.selectedItem.menuId || prevProps.selectedParent !== this.props.selectedParent)) {
                this.getSelectedMenuAndTabs(this.props.selectedItem.menuId, this.props.selectedParent);
            }
        }
    }

    getSelectedMenuAndTabs = (selectedMenuId, selectedParent: any = "") => {
        const {menuList,account} =this.props;
        let {selectedMenuItem} = this.state;
        const menu = menuList.find(item => item.menuId === selectedMenuId && item.menuType.toUpperCase() === "CATALOGUE");
        let res = menuList;
        let styled = false;
        const altCurrency = account && account.altCurrency;
        const altCurrencySymbol = account && account.altCurrencySymbol;
        const currency = account && account.currency;
        const defaultCurrencySymbol = account && account.currencySymbol;
        navTabs = [
            {
                name: i18next.t("home.page"),
                url: getLangPrefix(),
                currency,
                defaultCurrencySymbol,
                altCurrency,
                altCurrencySymbol
            }
        ];

        const newSelectedMenuId = getCatalogueParentMenuId(menu, this.props.menuList, "styles_et_tendances") || selectedMenuId;

        while (res) {
            res = findNodeByProperty(res, newSelectedMenuId, "menuId");
            if (res) {
                navTabs.push({name: res.label, url: res.canonicalUrl ? getLangPrefix() + res.canonicalUrl : ""});
                if (res.children) {
                    res = res.children
                } else {
                    selectedMenuItem = res;
                    res = false;
                }
            }
        }

        // if (Object.keys(this.props.productData).length) {
        //     navTabs.push({
        //         name: this.props.productData.name,
        //         url: navTabs[navTabs.length - 1].url + "/" + this.props.productData.canonicalUrl
        //     });
        // }

        if (selectedParent && selectedParent.length) {
            selectedParent.map(item => navTabs.splice(1, 0, {
                name: item.label,
                url: getLangPrefix() + item.canonicalUrl,
                currency,
                defaultCurrencySymbol,
                altCurrency,
                altCurrencySymbol
            }))
        }

        this.setState({selectedMenuItem, styled})
    };
    isHompage = ()=> {
        if( history.location.pathname === "/fr" || history.location.pathname === "/"){
            return true
        }else{
            return false
        }
    }

    handleChangeCurrency = async () => {
        if(
            !await updateBasketAfterChangeCurrency(this.props.account)
            && checkIsActiveBasketNotEmpty()
        ) {
            this.props.openBarcodePanelStart({
                productId: -1,
                barcode: getCurrentBarcode(),
                scanType: ''
            });
            destroyActiveBasket();
            this.props.getBasketStart();
        } else {
            this.props.getBasketStart(getActiveBasketName())
        };
    }

    render() {
        const {selectedMenuItem, showPageMetaData, customTabs} = this.state;
        const {selectedItem, productData,account} = this.props;
        const isCustomNav = Object.keys(selectedItem).length && selectedItem.customMenu;
        const name = account && account.storeDetails && account.storeDetails.name;
        return (
            <CommonCurrentPage
                navTabs={isCustomNav ? customTabs : navTabs}
                selectedMenuItem={Object.keys(selectedMenuItem).length && !this.isHompage() ?selectedMenuItem :{name}}
                productData={ !this.isHompage() ?productData:{}}
                showPageMetaData={this.isHompage() || showPageMetaData}
                handleChangeCurrency={this.handleChangeCurrency}
            />
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    account: getAccountResource(state),
    data: getHome(state),
    error: getError(state),
    products: getProducts(state),
    menuList: getMenu(state),
    selectedMenuId: getSelectedMenuId(state),
    productData: getProduct(state),
});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators & typeof HomeActionCreators  => {
    return bindActionCreators(Object.assign({}, HomeActionCreators, MenuActionCreators), dispatch)
};


export default connect(mapStateToProps, mapDispatchToProps)(CurrentPage);
