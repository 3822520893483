import {reservationMagasinTypes, reservationMagasinActionCreators} from "./actions";
import {takeLatest, put, call, select} from "redux-saga/effects";
import axios from 'axios';
import {STORE_ORDERS_API, STORE_WEB_CLIENTS_URL} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers"
import { getAccountResource } from "../../login/redux/selectors";


export function* getReservationMagasinData(params) {
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const accountResource = yield select(getAccountResource);

        const brendId = (
            accountResource &&
            accountResource.storeDetails &&
            accountResource.storeDetails.brandId &&
            accountResource.storeDetails.brandId
        );

        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.get(`${STORE_ORDERS_API}sto-web-orders?language=${getApiPrefix()}${brendId !== undefined ? `&serviceBrandId=${brendId}` : ''}`, headers)
                .then(res => res.data)
        });
        yield put(reservationMagasinActionCreators.reservationMagasinSuccess(data));
    } catch (e) {
        yield put(reservationMagasinActionCreators.reservationMagasinFailure(e.message));
    }
}

export function* getReservationMagasinSingleData(params) {
    const privateToken = sessionStorage.getItem("privateToken");
    const {webClientId,shipAddressXrefId,billAddressXrefId} =params.data;
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`
            }
        };
        const queryParams = `?billAddressXrefId=${billAddressXrefId}${shipAddressXrefId ? '&shipAddressXrefId=' + shipAddressXrefId : ''}`
        const data = yield call(() => {
            return axios.get(`${STORE_WEB_CLIENTS_URL}/${webClientId}${queryParams}`, headers)
                .then(res => res.data)
        });
        yield put(reservationMagasinActionCreators.reservationMagasinSingleSuccess(data));
    } catch (e) {
        yield put(reservationMagasinActionCreators.reservationMagasinSingleFailure(e.message));
    }
}
export function* reservationMagasinSaga() {
    yield takeLatest(reservationMagasinTypes.RESERVATION_MAGASIN_START, getReservationMagasinData);
    yield takeLatest(reservationMagasinTypes.RESERVATION_MAGASIN_SINGLE_START, getReservationMagasinSingleData);
}

export default reservationMagasinSaga;
