import {createActions} from 'reduxsauce';

export const {Types: StoreGiftsTypes, Creators: StoreGiftsActionCreators} = createActions ({
    storeGiftsStart: null,
    storeGiftsSuccess: ['data'],
    storeGiftsFailure: ['error'],
    createStoreGiftStart: ["body"],
    createStoreGiftSuccess: ["data"],
    createStoreGiftFailure: ["error"],
    singleStoreGiftsStart:["gift"],
    singleStoreGiftsSuccess:["data"],
    singleStoreGiftsFailure:["error"],
    printStoreGiftsStart:["data"],
    printStoreGiftsSuccess:["data"],
    printStoreGiftsFailure:["error"],
    clientStart: null,
    getBasketStart: ["name"],
    basketStoreGiftsStart: null,
    removeBasketGiftsStart: ['storeGiftCode'],
    removeBasketGiftsSuccess: null,
});
