import { createActions } from 'reduxsauce';

export const { Types: CalendarTypes, Creators: CalendarActionCreators } = createActions({
    CalendarStart:   ['parmas'],
    CalendarSuccess: ['data'],
    CalendarUpdate:  ['body'],
    CalendarStatus:  ['data'],
    CalendarFailure: ['error'],
    CalendarLoadingAction: null
});
