import { createActions } from 'reduxsauce';

export const { Types: DatatableTypes, Creators: DatatableActionCreators } =
    createActions({
        updateOrderStatusStart: ['orderId', 'status', 'generateOrderFile', 'stroreTransferBody'],
        updateOrderStatusSuccess: ['data'],
        updateOrderStatusFailure: ['error'],
        reservationMagasinStart: null,
        livraisonClientStart: null,
        setUniqueId: ['id'],
    });
