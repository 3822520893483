export const MENU_TYPE = 'MENU';
export const PAGE_TYPE = 'PAGE';
export const CATALOGUE_TYPE = 'CATALOGUE';
export const SUB_MENU_STYLES = 'styles_et_tendances';
export const SERVICE_EDIT = 0;
export const PRODUCT_MAX_QUANTITY = 10;
export const STORE_DELIVERY = process.env.REACT_APP_STORE_DELIVERY;
export const SHIPPING = process.env.REACT_APP_SHIPPING;
export const TRANSFER_THRESHOLD = 10; //threshold for store-to-store transfers
export const SHIPMENT_THRESHOLD = 100; //threshold for web orders (home delivery)
export const GIFT_DURATION = 180; //6 months
export const QUOTE_DURATION = 30; //1 month
export const BROWSER_KEY = 'Wave2907$';
export const PERSONALIZATION = process.env.REACT_APP_PERSONALIZATION;
export const FISCAL_PRINTER_HTTPS = process.env.REACT_APP_FISCAL_PRINTER_HTTPS;
export const PROMOTION_RULES = process.env.REACT_APP_PROMOTION_RULES;
export const DEFAULT_BASKETS = ['basket_1', 'basket_2', 'basket_3'];
export { default as zipCodes } from '../dataJsons/zipCodes.json';
export const TICKET_TYPE = {
    ticket: 'ticket',
    acompte: 'acompte',
    duplicata: 'duplicata',
    facture: 'facture',
    gift: 'gift',
};
export const RECEIPT_TYPE = {
    ticket: 'TICKET',
    advance: 'ADVANCE',
    credit: 'CREDIT',
    quote: 'QUOTE',
    gift: 'GIFT',
    invoice: 'INVOICE',
    cadeau: 'CADEAU'
};
export const TRANSFER_STATUS_CODE = {
    REQ: 'REQ',
    REC: 'REC',
    ACK: 'ACK',
    SHI: 'SHI',
    REJ: 'REJ',
    PEN: 'PEN',
    DIFF: 'DIFF',
};
export const RETURN_STATUS_CODE = {
    ACC: 'ACC',
    REF: 'REF',
    PAI: 'PAI'
};
export const ORDER_STATUS_CODE = {
    PRE: 'PRE',
    PIC: 'PIC',
    CAN: 'CAN',
    PRO: 'PRO',
    REA: 'REA',
    SHI: 'SHI',
    PEN: 'PEN',
    WIT: 'WIT',
    STO: 'STO',
    DEP: 'DEP',
    DIS: 'DIS',
    PAI: 'PAI',
    SER: 'SER',
    RET: 'RET'
};
export const LOGISTIC_STATUS_CODE = {
    VAL: 'VAL',
    COM: 'COM',
    PRO: 'PRO',
    PEN: 'PEN',
    CLO: 'CLO',
    WAI: 'WAI'
};
export const ACTIVE_BASKET = 'activeBasket';
export const COUNTRIES = [['FR', 'BE', 'MC'], ['DE', 'AT', 'CH'], 'IT'];
export const STORE_COUNTRIES = {
    fr: 'FR',
    be: 'BE',
    mc: 'MC',
    de: 'DE',
    at: 'AT',
    ch: 'CH',
    it: 'IT',
};
export const PAYMENT_CODES = {
    c1: '001',
    c2: '002',
    c3: '003',
    c4: '004',
    c5: '005',
    c6: '006',
    c7: '007',
    c8: '008',
    c9: '009',
    c10: '010',
};
export const PAYMENT_TYPE = { p: 'P' };
export const PROCESS_TYPE = 'Kassenbeleg-V1'; //Deutsche Fiskal
export const TRANSACTION_TYPES = { beleg: 'Beleg', aVTraining: 'AVTraining' }; //Deutsche Fiskal: Beleg or AVTraining
export const CASHBOX_PREFIX = '_R1-AT1_'; //Austria fiscal
export const WAREHOUSE_CODE = 'W01';
export const NOTIFICATION_COUNTERS_TIME = 600000;
export const LABEL_COLUMN = 4;
export const LABEL_ROW = 10;
export const QUOTE_EXPIRATION = 30;
export const CURRENCY_SYMBOLS_VALUES = { '€': '€', CHF: 'F' };
export const PRODUCT_TYPES = {
    s: 'S',
    c: 'C',
    sc: 'SC',
};
export const PRODUCT_ITEM_TYPES = {
    GFC: 'GFC',
    EAN: 'EAN',
};

export const ALPHABET = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

export const SESSION_STORAGE = {
    terminalOpen: 'terminalOpen',
    privateKey: 'privateKey',
    privateToken: 'privateToken',
    terminalCode: 'terminalCode',
    storeCode: 'storeCode',
    offFiscal: 'offFiscal',
};

export const LOCAL_STORAGE = {
    barcodeList: 'barcodeList',
    clientCounter: 'clientCounter',
    deliveryBasket: 'deliveryBasket',
    menuList: 'menuList',
    selectedItem: 'selectedItem',
    isAssociateAdmin: 'isAssociateAdmin'
};

export const ONE_DAY_MILISEC = 86400000;

export let DEFAULT_CURRENCY = 'EUR';
export let DEFAULT_CURRENCY_SYMBOL = '€';
export let BRAND_ID = 12;
export let IS_FISCAL_PRINTER = false;
export let STORE_COUNTRY = STORE_COUNTRIES.fr;
export let IT_PRINTER_URL = '';
export const STORAGE_GET_FORM = 'getForm';
export let TERMINAL_DATE: any = new Date();
export let PROMOTION = null;
export let STORE_TYPE = '';

export const BONUS_PRICE_DROP_DOWN = [
    { text: `50,00 ${DEFAULT_CURRENCY_SYMBOL}`, value: 50 },
    { text: `100,00 ${DEFAULT_CURRENCY_SYMBOL}`, value: 100 },
    { text: `150,00 ${DEFAULT_CURRENCY_SYMBOL}`, value: 150 },
    { text: `200,00 ${DEFAULT_CURRENCY_SYMBOL}`, value: 200 },
];
export function setStoreData(
    currency = '',
    currencySymbol = '',
    terminalDate = TERMINAL_DATE,
) {
    if (currency) {
        DEFAULT_CURRENCY = currency;
    }
    if (currencySymbol) {
        DEFAULT_CURRENCY_SYMBOL = currencySymbol;
    }
    if (terminalDate) {
        TERMINAL_DATE = terminalDate;
    }
}

export function updateStoreVariables(details: any = {}) {
    try {
        const {
            storeDetails = {},
            currency,
            currencySymbol,
            fiscalPrinterIp,
            promotion,
            brandApp = 0,
        } = details;
        const { country, storeType } = storeDetails;

        STORE_TYPE = storeType

        if (brandApp) {
            BRAND_ID = brandApp;
        }
        if (currency) {
            DEFAULT_CURRENCY = currency;
        }
        if (currencySymbol) {
            DEFAULT_CURRENCY_SYMBOL = currencySymbol;
        }
        if (country) {
            STORE_COUNTRY = country.toUpperCase();
            if (fiscalPrinterIp) {
                IS_FISCAL_PRINTER = country === STORE_COUNTRIES.it;
                const fiscalIp = `http${
                    FISCAL_PRINTER_HTTPS === 'Y' ? 's' : ''
                }://${fiscalPrinterIp}`;
                IT_PRINTER_URL = `${fiscalIp}/cgi-bin/fpmate.cgi`;
            }
        }
        if (promotion) {
            PROMOTION = promotion;
        }
    } catch (e) {
        console.log(e);
    }
}

export const YEAR_DROPDOWN = [
    { key: 2025, text: '2025', value: 2025 },
    { key: 2024, text: '2024', value: 2024 },
    { key: 2023, text: '2023', value: 2023 },
    { key: 2022, text: '2022', value: 2022 },
    { key: 2021, text: '2021', value: 2021 },
    { key: 2020, text: '2020', value: 2020 },
];

export const MONTH_DROPDOWN = [
    { key: 1, text: '01', value: '01' },
    { key: 2, text: '02', value: '02' },
    { key: 3, text: '03', value: '03' },
    { key: 4, text: '04', value: '04' },
    { key: 5, text: '05', value: '05' },
    { key: 6, text: '06', value: '06' },
    { key: 7, text: '07', value: '07' },
    { key: 8, text: '08', value: '08' },
    { key: 9, text: '09', value: '09' },
    { key: 10, text: '10', value: '10' },
    { key: 11, text: '11', value: '11' },
    { key: 12, text: '12', value: '12' },
]

export const citiesZipcode = [
    { code: "75001" },
    { code: "75002" },
    { code: "75006" },
    { code: "75116" },
    { code: "75017" },
    { code: "69000" }
]


export const adminRoutesKeys = ['admin', 'sales']

export enum Statuses {
    success = 'SUCCESS',
    failure = 'FAILURE',
    pending = 'PANDING',
    initial = 'INITIAL'
}
