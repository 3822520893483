import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tab, TextArea } from 'semantic-ui-react';

import AutocompleteDropdown from '../../../common/autocompleteDropdown';
import FileInput from '../../../common/fileInput/fileInput';
import CustomBtn from '../../../common/customBtn';
import { StockActionCreators } from '../../redux/actions';
import { getImportLoading } from '../../redux/selectors';
import { getStockProductImportOptions } from '../../../../helpers/helpers';
import { PopupData } from '../../../../shared/models/popupData';
import InfoPopUp from '../../../common/infoPopUp';

interface Props {
	importStockProductsStart: (body: FormData) => void;
	isImportLoading: boolean;
	handleClose: () => void;
	isService: boolean;
}

const ImportProductAction: React.FC<Props> = ({
	importStockProductsStart,
	isImportLoading,
	handleClose,
	isService
}) => {
	const [productType, setProductType] = useState<'PRODUCT' | 'BARCODE' | 'SERVICE'>(isService ? 'SERVICE' : 'PRODUCT');
	const [comment, setComment] = useState<string>('');
	const [isImportStarted, setIsImportStarted] = useState<boolean>(false);
	const [infoPopupData, setInfoPopupData] = useState<PopupData>({
        isPopupVisible: false,
        message: ''
    });
	const fileRef = useRef<HTMLInputElement>(null);
	const panes = [{
		menuItem: i18next.t('Terminal.dashboards.upload'),
		render: () => {
			return (
				<div className="content">
						<div className="popUp--body--card--body--data">
							<div className="popUp--body--card--body--data--header">
								{i18next.t('Terminal.dashboards.fileType')}:
							</div>
							<div className={"popUp--body--card--body--data--description  clienttab input"}>
								<AutocompleteDropdown
									data={getStockProductImportOptions(isService)}
									search={false}
									value={productType}
									classN="popUp--body--card--body--data--description"
									keyName="id"
									textName={['label']}
									valueName="value"
									onChange={setProductType.bind(null)}
								/>
							</div>
						</div>
						<div className="popUp--body--card--body--data">
							<div className="popUp--body--card--body--data--header">
								{i18next.t('Terminal.dashboards.comment')}:
							</div>
							<div className={"popUp--body--card--body--data--description  clienttab input"}>
								<TextArea
									className="popUp--body--card--body--data--description textarea"
									style={{minHeight: 100}}
									name="comment"
									value={comment}
									onChange={(event, element) => setComment(element.value ? `${element.value}` : '')}
									autoComplete="off"
								/>
							</div>
						</div>
						<div className="popUp--body--card--body--data">
							<div className="popUp--body--card--body--data--header">
								{i18next.t('Terminal.dashboards.file')}:
							</div>
							<div className={"popUp--body--card--body--data--description  clienttab input"}>
								<FileInput
									name="file"
									id="fileInput"
									inputRef={fileRef}
								/>
							</div>
						</div>
						<div className="popUp--body--card--body--data--description d-flex justify-end">
							<CustomBtn
								disabled={false}
								classN="btn_1 w-100 maxWidth-150"
								handleClick={handleSubmitProductFile}
								loading={isImportLoading}
							>
								{i18next.t('Terminal.dashboards.apply')}
							</CustomBtn>
						</div>
					<InfoPopUp
						open={infoPopupData.isPopupVisible}
						classN="message-error"
						message={infoPopupData.message}
						handleClose={() => toggleInfoPopup()}
					/>
				</div>
			)
		}
	}];

	useEffect(() => {
		if (!isImportLoading && isImportStarted) handleClose();
	}, [isImportLoading]);

	const handleSubmitProductFile = () => {
		const file = fileRef.current && fileRef.current.files && fileRef.current.files[0]

		if (!file) {
			return;
		}

		const reader = new FileReader();
        reader.onload = onFileLoad;
        reader.readAsText(file);
	};

	const toggleInfoPopup = (message: string = '') => {
        setInfoPopupData({
            isPopupVisible: !infoPopupData.isPopupVisible,
            message,
        })
    };

	const onFileLoad = (event) => {
        const { result = '' } = event.target;

        if (result.match(/[<>]/g)) {
            toggleInfoPopup(i18next.t('Terminal.transferBasket.7'))
            return;
        }

        importFile();
    }

	const importFile = () => {
        const file = fileRef.current && fileRef.current.files && fileRef.current.files[0];
        setIsImportStarted(true);
		const formData = new FormData();

		formData.append("type", productType);
		formData.append("comment", comment);
		file && formData.append("file", file);

		importStockProductsStart(formData);
    }

	return (
		<div>
			<Tab menu={{pointing: true}} panes={panes} />
		</div>
	)
}

const mapStateToProps = (state: any): any => ({
  isImportLoading: getImportLoading(state)
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
  return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportProductAction);