import React, {useEffect, useState} from 'react';
import {getDateNow} from "../../../helpers/helpers";

interface IProps {
    terminalDate?: Date
}

const Clock: React.FC<IProps> = ({ terminalDate }) => {
    const [date, setDate] = useState(getDateNow());
    useEffect(() => {
        const timerID = setInterval( () => setDate(getDateNow()), 1000 );
        return () => clearInterval(timerID);
    }, []);

    if(terminalDate) {
        const date = getDateNow(terminalDate)
        return (
            <time>{`${date.day}/${date.month}/${date.year} ${date.hours}:${date.minutes}`}</time>
        );
    }

    return (
        <time>{`${date.day}/${date.month}/${date.year} ${date.hours}:${date.minutes}`}</time>
    );
}
export default Clock
