import React from 'react'
import i18next from "i18next";
import ClientsNew from "./clientsNew";
import PopUp from "../../../popUp/popUp";


interface State {
    showPopUp: boolean,
    showerror:string
}
interface Props {
    storeCode:string,
}
class Nouveaubtn extends React.Component<State & Props> {

    state: State = {
        showPopUp: false,
        showerror:""
    };

    popup = () => {
        this.setState({
            showPopUp: true,
        })
    };

    handleClosePopUp = () => {
        this.setState({
            showPopUp: false,
        });
    };
    render() {
        const {showPopUp}=this.state;
        const { storeCode } =this.props;
        return (
            <>
                <button className="btn_1 w-100 orange_btn" onClick={this.popup}>{i18next.t('buttons.4')}</button>
                {
                    showPopUp && <PopUp handleClose={this.handleClosePopUp}>
                       <ClientsNew handleClosePopUp={this.handleClosePopUp} isNew={true} storeCode={storeCode} />
                    </PopUp>
                }
            </>
        )
    }
}


export default Nouveaubtn;
