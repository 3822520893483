import React from 'react';
import { CSVLink } from "react-csv";
import i18next from 'i18next';

interface IProps {
    headers: object,
    data: object,
    name?: string
}

const ExportCsvData: React.FC<IProps>  = ({headers = [], data = [], name = 'file.csv'}) => {
    return (
        <CSVLink
            filename={name}
            className="btn_1 w-100"
            data={data}
            headers={headers}
            separator={';'}
        >
            {i18next.t('Terminal.dashboards.exporter')}
        </CSVLink>
    )
}

export default ExportCsvData