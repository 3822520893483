import {createActions} from 'reduxsauce';

export const {Types: inventoryTypes, Creators: InventoryActionCreators} = createActions ({
    inventoryStart: null,
    inventorySuccess: ['data'],
    inventoryFailure: ['error'],
    singleInventoryStart: ['id'],
    singleInventorySuccess: ['data'],
    singleInventoryFailure: ['error'],
    createInventoryStart:['data'],
    createInventorySuccess:['data'],
    createInventoryFailure:['error'],
    updateStoreInventoryStart:['logisticStatus','storeInventoryId'],
    updateStoreInventorySuccess:['data'],
    updateStoreInventoryFailure:['error'],
    resetStoreInventoryStatus: null,
    resetStoreInventoryCreateStatus: null
});
