import { createActions } from 'reduxsauce';

export const { Types: returnTypes, Creators: ReturnActionCreators } =
    createActions({
        returnStart: null,
        returnSuccess: ['data'],
        returnFailure: ['error'],
        getTicketsStart: null,
        getTicketsSuccess: ['data'],
        getTicketsFailure: ['error'],
        updateReturnStart: ['body'],
        updateReturnSuccess: ['data'],
        updateReturnFailure: ['error'],
        createStoreGiftStart: ['body', 'isPay'],
    });
