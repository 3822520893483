import React, {Component} from "react";
import {connect} from "react-redux";
import i18next from "i18next";
import {getLocationSearch, getError} from '../redux/selectors';
import {LocationSearchActionCreators} from '../redux/actions';
import {bindActionCreators} from "redux";
import {ILocationSearchState} from "../redux/reducers"
import "./locationSearch.scss"

interface Props extends ILocationSearchState {
    locationSearchData: any,
    error: any,
    onClick?: any,
    classN?: string
}

interface State {
    value: string
}

class LocationSearchInput extends Component<Props & typeof LocationSearchActionCreators, State> {
    state: State = {
        value: ""
    };

    handleClick = () => {
        const location = this.state.value || "Paris";
        const country = "FR";
        this.props.locationSearchStart(country, location);
        this.props.onClick && this.props.onClick();
    };
    handleKeyDown =(e) => {
        if (e.keyCode === 13) {
           this.handleClick();
        }
    };

    handleChange = (e) => {
        this.setState({value: e.target.value})
    };

    render() {
        const {classN, showButton} = this.props;
        const {value} = this.state;
        return (

            <>
            <label htmlFor="location"><p  className="locationSpan">{i18next.t('common.address')}</p> </label>
                <input value={value} id="location" type="text" placeholder={i18next.t('common.address')}
                       className="location-wrapper-item-search-input" onKeyDown={this.handleKeyDown} onChange={this.handleChange}/>

                {
                    !showButton && <div className={classN ? classN : "search-content-input-btn"}>
                        <div className="search-icon footerSearchIcon" onClick={this.handleClick}></div>
                    </div>
                }
                {showButton &&
                <button className={`location-wrapper-item-search-btn ${value.length > 3 ? "" : "disabled"}`}
                        disabled={value.length > 3 ? false : true}
                        onClick={this.handleClick}>{i18next.t("locator.2")}</button>}
            </>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        locationSearchData: getLocationSearch(state),
        error: getError(state)
    }
};

const mapDispatchToProps = (dispatch): typeof LocationSearchActionCreators => {
    return bindActionCreators(LocationSearchActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput);


