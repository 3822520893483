import React, {useState, useMemo, useEffect} from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import i18next from "i18next";
import {UserActionCreators} from "../../../../../modules/login/redux/actions";
import { getAccountResource, isPrivateAuthenticated, isTerminalAdmin } from '../../../../../modules/login/redux/selectors';
import { getUserFromToken, getLangPrefix } from "../../../../../helpers/helpers";
import { SESSION_STORAGE } from '../../../../../utils/constants/variables';
import ConfirmPopup from '../../../../common/confirmPopup/confirmPopup';
import { getIsTerminalOpen } from '../../../../fermeturePopUp/redux/selectors';
import { associateShiftsEnd } from '../../../../../utils/constants/service';
import './index.scss'
import { getFiscalTssDetails } from '../../../../../layouts/main/header/redux/selectors';

interface Props {
    clientLogoutStart: void,
    logoutStart: void,
    details: object,
    isConnectedPrinter: boolean,
    terminalOpen: boolean,
    isTerminalAdmin: boolean,
    tssDetails: any
}

const UserDropdown: React.FC<Props> = (props: any) => {
    const [isShowMessage, setShowMessage] = useState(false)
    const [offFiscal, setOffFiscal] = useState(false)
    const [show, setShow] = useState(false)
    const user = useMemo(()=>getUserFromToken(), props.account)

    useEffect(() => {
        if(props.details.printer) {
            props.printerStatusStart()
        }
        setOffFiscal(sessionStorage.getItem(SESSION_STORAGE.offFiscal) === "true")
    }, [])

    const clientLogout = () => {
        props.clientLogoutStart();
        props.history.push("/");
    }

    const terminalLogout = async () => {
        const data = await associateShiftsEnd()
        props.logoutStart(!props.terminalOpen);
        props.history.push(`${getLangPrefix()}/login`);
    }

    const createStoreSaleFiscal = (offFiscal) => () => {
        sessionStorage.setItem(SESSION_STORAGE.offFiscal, JSON.stringify(offFiscal));
        setOffFiscal(offFiscal);
        setShow(false);
    }

    return (
        <div>
            <div className="modal open">
                <div className="modal-inner user">
                    <div className="modal-input">
                        <div className="account-top">
                            <div className="account-top-leftbar">
                                <span>{user.fname ? user.fname[0] : ""}</span>
                            </div>
                            <div className="account-top-rightbar">
                                <h3>
                                    { `${user.fname || ""} 
                                       ${user.lname || ""} 
                                    `}

                                </h3>
                                <p>
                                    <span>
                                        <a>{i18next.t('Terminal.login.12')}</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-input pass">
                        <button className="modal-btn" onClick={clientLogout}>  {i18next.t('Terminal.login.11')}</button>
                        <button className="modal-btn logout" onClick={terminalLogout}> {i18next.t('Terminal.login.10')}</button>
                    </div>
                    {isShowMessage && <p className="message-error">{i18next.t('Terminal.login.14')}</p>}
                </div>
            </div>
            {show && (
                <ConfirmPopup
                    handleCancel={() => setShow(false)}
                    handleConfirm={createStoreSaleFiscal(!offFiscal)}
                    text={offFiscal ? i18next.t('Terminal.confirm.19') : i18next.t('Terminal.confirm.18')}
                />
            )}
        </div>

    )
}

const mapStateToProps = (state: any) => ({
    privateAuthenticated: isPrivateAuthenticated(state),
    details: getAccountResource(state),
    terminalOpen: getIsTerminalOpen(state),
    isTerminalAdmin: isTerminalAdmin(state),
    tssDetails: getFiscalTssDetails(state)
});

const mapDispatchToProps = (dispatch): typeof UserActionCreators => {
    return bindActionCreators(UserActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown)
