import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import i18next from 'i18next';
import { pdf } from '@react-pdf/renderer';
import { Input, Table, TextArea } from 'semantic-ui-react';
import { DeliveriesActionCreators } from '../../redux/actions';
import {
    getCreatedData,
    getCreatedStatus,
    getCreateLoading,
    getUpdateLoading
} from '../../redux/selectors';
import InfoPopUp from '../../../common/infoPopUp';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import {
    fixedInHundredth,
    fixNumber,
    getTotalSum,
    textToBase64Barcode,
    getDateTimestamp,
    delay,
    multipleDuplicationOfArrayElements,
    sortArrayOfObject,
    generateInputNumber,
    arrayOfObjectGroupByKey,
} from '../../../../helpers/helpers';
import DayPicker from '../../../common/dayPicker';
import CustomBtn from '../../../common/customBtn';
import { getProductDataByBarcode } from '../../../../utils/constants/service';
import { getAccountResource } from '../../../login/redux/selectors';
import { LOGISTIC_STATUS_CODE } from '../../../../utils/constants/variables';
import {
    BarcodePositionSidePanel,
    GeneretBarcodes,
} from '../../../pdfGenerator/barcodePositionSidePanel';
import PdfTable from '../../../pdfGenerator/pdfTableBtn';
import ImportBarcodesBtn from '../../../common/importBarcodesBtn';
import SearchBox from '../../../common/searchBox';
import FermerBtn from '../../../common/fermerBtn';
import Pagination from '../../../common/pagination';
import PrintEanBarcodes from '../../../pdfGenerator/printEanBarcodes';
import PopUp from '../../../popUp/popUp';
import ColisPopup from './colisPopupContent';

interface Props {
    isCreateLoading: boolean;
    isUpdateLoading: boolean;
    createdStatus: string;
    data?: object;
    handleClosePopUp?: any;
    updateDeliveryStatus?: (status: string) => void;
    details: any;
    createdData: any;
}

interface State {
    isNew: boolean;
    isOpen: boolean;
    showConfirmPopup: boolean;
    status: string;
    stocks: object;
    barcode: string;
    dublicateBarcode: string;
    body: {
        storeDeliveryId: number | null;
        slipNumber: string;
        slipDate: any;
        totalQtyDelivered: number | null | string;
        totalQtyChecked: number | null | string;
        totalBoxQuantity: number | null | string;
        totalBoxChecked: number | null | string;
        comment: string;
        storeDeliveryProducts: any[];
    };
    focusedQtyChecked: string;
    loadingBarcodes: boolean;
    dataPdf: any[];
    newBarcode: string;
    newBarcodeQty: string;
    isEcart: boolean;
    searchLoading: boolean;
    resetPagination: boolean;
    currentPage: number;
    deliveriesProducts: any;
    sorted: 'ascending' | 'descending' | undefined;
    sortedColumn: string;
    isCreated: boolean;
    sortDirection: 'ascending' | 'descending' | undefined;
    isColisPopUpVisible: boolean;
    importMessage: {
        message: string,
        error: boolean
    };
    importParcel: number;
}

class CreateDeliveries extends Component<
    Props & typeof DeliveriesActionCreators,
    State
> {
    state: State = {
        isNew: true,
        isOpen: false,
        showConfirmPopup: false,
        status: '',
        stocks: {},
        barcode: '',
        dublicateBarcode: '',
        body: {
            storeDeliveryId: null,
            slipNumber: '',
            slipDate: new Date().getTime(),
            totalQtyDelivered: 0,
            totalQtyChecked: 0,
            totalBoxQuantity: 0,
            totalBoxChecked: 0,
            comment: '',
            storeDeliveryProducts: [],
        },
        focusedQtyChecked: '',
        loadingBarcodes: false,
        dataPdf: [],
        newBarcode: '',
        newBarcodeQty: '',
        isEcart: false,
        searchLoading: false,
        resetPagination: false,
        currentPage: 1,
        deliveriesProducts: [],
        sorted: undefined,
        sortedColumn: '',
        isCreated: false,
        sortDirection: undefined,
        isColisPopUpVisible: false,
        importMessage: {
            message: '',
            error: false
        },
        importParcel: 0
    };

    barcodeInputRef: React.RefObject<any> = React.createRef();
    perPage = 20;

    columnsPdf: any = [
        {
            name: i18next.t('Terminal.dashboards.package'),
            selector: 'parcelNumber',
            width: '14%',
        },
        {
            name: i18next.t('Terminal.dashboards.barcode'),
            selector: 'barcode',
            width: '14%',
        },
        {
            name: i18next.t('Terminal.dashboards.code'),
            selector: 'altReference',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'name',
            width: '24%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            width: '8%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.qtyDelivered'),
            selector: 'qtyDelivered',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.qtyChecked'),
            selector: 'qtyChecked',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.dashboards.qtyDefect'),
            selector: 'qtyDefect',
            width: '4%',
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.stock'),
            selector: 'stocks',
            width: '7%',
        },
        {
            name: i18next.t('Terminal.dashboards.ecart'),
            selector: 'ecart',
            width: '7%',
        }
    ];
    componentDidMount(): void {
        if (this.props.data && this.props.data.storeDeliveryId) {
            const { data } = this.props;
            const {
                slipNumber,
                slipDate,
                totalQtyDelivered,
                totalQtyChecked,
                comment,
                storeDeliveryId,
                products,
                totalBoxQuantity,
                totalBoxChecked,
            } = data;

            const storeDeliveryProducts: any[] = [];
            const stocks: object = {};
            if (Array.isArray(products)) {
                products.forEach((item) => {
                    storeDeliveryProducts.push({
                        parcelNumber: item.parcelNumber,
                        barcode: item.barcode,
                        altReference: item.altReference,
                        qtyDelivered: item.totalQtyDelivered,
                        qtyChecked: item.totalQtyChecked || 0,
                        qtyDefect: item.totalQtyDefect || '',
                        comment: item.comment,
                        name: item.name,
                        size: item.size,
                    });
                    stocks[item.barcode] = item.stock;
                });
            }

            const body = {
                storeDeliveryId,
                slipNumber,
                slipDate,
                totalQtyDelivered,
                totalQtyChecked,
                totalBoxQuantity,
                totalBoxChecked,
                comment,
                storeDeliveryProducts,
            };
            const dataPdf: any = [];

            storeDeliveryProducts.forEach((item) => {
                const valueQty = fixedInHundredth(
                    Number(item.qtyChecked) - Number(item.qtyDelivered),
                );
                dataPdf.push({
                    parcelNumber: item.parcelNumber,
                    barcode: item.barcode,
                    altReference: item.altReference,
                    name: item.name,
                    size: item.size,
                    qtyDelivered: item.qtyDelivered,
                    qtyChecked: item.qtyChecked,
                    qtyDefect: item.qtyDefect,
                    stocks: stocks[item.barcode],
                    ecart:
                        valueQty && valueQty > 0
                            ? `+${valueQty}`
                            : valueQty && valueQty < 0
                            ? `${valueQty}`
                            : '',
                    comment: item.comment,
                });
            });

            this.setState({
                isNew: false,
                body,
                stocks,
                dataPdf,
                deliveriesProducts: storeDeliveryProducts
            });
        }
    }

    componentDidUpdate(prevProps, prevState): void {
        if (prevProps.createdStatus !== this.props.createdStatus) {
            this.setState({ status: this.props.createdStatus });
        }

        if (
            this.state.isNew &&
            prevProps.isCreateLoading &&
            !this.props.isCreateLoading &&
            this.props.createdData.storeDeliveryId
        ) {
            this.setState((prevState) => ({
                body: {
                    ...prevState.body,
                    storeDeliveryId: this.props.createdData.storeDeliveryId,
                },
                isNew: false,
                isCreated: true,
            }));
        }
    }

    handleDayChange = (selectedDay) => {
        this.handleChangeState('slipDate', Date.parse(selectedDay));
    };

    handleChangeState = (name, value) => {
        this.setState((prevState) => ({
            body: {
                ...prevState.body,
                [name]: value,
            },
            barcode: '',
        }));
    };

    handleChangeInput = (e) => {
        this.handleChangeState(e.target.name, e.target.value);
    };

    handleBlur = ({ target: { name } }) => {
        const productIndex = +name + (this.state.currentPage - 1) * this.perPage;
        const storeDeliveryProducts = this.state.body.storeDeliveryProducts.map(
            (item, index) => {
                if (index === productIndex) {
                    item.qtyChecked = +item.qtyChecked;
                }
                return item;
            },
        );

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts,
            },
        }));
    };

    onFocus = ({ target: { name, value } }) => {
        if (value === '0') {
            const productIndex = +name + (this.state.currentPage - 1) * this.perPage;
            const storeDeliveryProducts =
                this.state.body.storeDeliveryProducts.map((item, index) => {
                    if (index === productIndex) {
                        item.qtyChecked = '';
                    }
                    return item;
                });

            this.setState((prevState: State) => ({
                body: {
                    ...prevState.body,
                    storeDeliveryProducts,
                },
            }));
        }
        this.setState({ focusedQtyChecked: name });
    };

    handleChangeQty = ({ target }) => {
        const { body } = this.state;
        let { name, value } = target;
        const { key = '' } = target && target.dataset || {};
        // const productIndex = +name + (this.state.currentPage - 1) * this.perPage;
        const regexp = /^\d+(\.\d{1,2})?$/;
        if (value && !regexp.test(value)) {
            return;
        }

        if (value.length > 12) value = '';
        const storeDeliveryProducts = body.storeDeliveryProducts.map((item, index) => {
            if (key === `${item.barcode}${item.parcelNumber}`) {
                item.qtyChecked = value;
            }
            return item;
        });

        const totalQty = fixedInHundredth(
            getTotalSum(storeDeliveryProducts, 'qtyChecked'),
        );

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts,
                totalQtyChecked: +totalQty,
            },
            barcode: '',
        }));
    };

    handleChangeValue = (target, barcode) => {
        const { body } = this.state;
        let { name, value } = target;
        const regexp = /^\d+(\.\d{1,2})?$/;
        if (value && !regexp.test(value)) {
            return;
        }
        if (value.length > 12) value = '';

        const storeDeliveryProducts = body.storeDeliveryProducts.map((item) => {
            if (item.barcode === barcode) {
                item[name] = value;
            }
            return item;
        });

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts,
            },
        }));
    };
    handleChangeItemComment = (e) => {
        const storeDeliveryProducts = this.state.body.storeDeliveryProducts.map(
            (item) => {
                if (item.barcode === e.target.name) {
                    item.comment = e.target.value;
                }
                return item;
            },
        );

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts,
            },
            barcode: '',
        }));
    };

    handleScan = async (barcode, count?, end?, isSubmit?, isScan?, isImport?) => {
        if (!barcode) return;
        console.log('barcode: ', barcode);
        console.log('count: ', count);
        console.log('end: ', end);
        console.log('isSubmit: ', isSubmit);
        const { body, newBarcodeQty, importParcel } = this.state;
        const { storeDeliveryProducts } = body;
        const existingProduct = storeDeliveryProducts.find(
            (item) => importParcel
                ? (item.barcode === barcode && importParcel === item.parcelNumber)
                : item.barcode === barcode
        );
        const qtyChecked = count || +newBarcodeQty || 1;
        const foundItems = storeDeliveryProducts.filter((item) => item.barcode === barcode);
        const isScanActive = foundItems.length > 1 && isScan;
        const isImportActive = foundItems.length > 1 && isImport;
        let products: any = [];
        if (isScanActive || isImportActive && end) {
            this.setState({ isColisPopUpVisible: true, dublicateBarcode: barcode });
        }
        if(barcode.length > 13) {
            this.setState({ importParcel: barcode })
            return;
        }
        if (existingProduct) {
            products = storeDeliveryProducts.map((item) => {
                if (importParcel
                        ? (item.barcode === barcode && importParcel === item.parcelNumber)
                        : item.barcode === barcode
                ) {
                    item.qtyChecked += qtyChecked;
                }
                return item;
            });
        } else {
            try {
                const data = await getProductDataByBarcode(
                    barcode,
                    this.props.details,
                );
                if (data) {
                    products = [
                        {
                            barcode,
                            name: data.name,
                            qtyChecked,
                            comment: '',
                        },
                        ...storeDeliveryProducts,
                    ];
                } else {
                    throw new Error('');
                }
            } catch (error) {
                if (count) return;

                products = storeDeliveryProducts;
                this.setState({ isOpen: true });
                isImport && this.setState({
                    importMessage: {
                        message: i18next.t('alertMessage.1'),
                        error: false
                    }
                })
                if (isSubmit) return;
            }
        }

        const totalQty = fixedInHundredth(getTotalSum(products, 'qtyChecked'));

        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts: products,
                totalQtyChecked: totalQty,
            },
            barcode,
            newBarcodeQty: '',
            deliveriesProducts:
                !count && existingProduct
                    ? products.filter((item) => item.barcode === barcode)
                    : products,
        }));

        if(isImport && end) {
            this.setState({
                importMessage: {
                    message: i18next.t('alertMessage.14'),
                    error: false
                },
                importParcel: 0
            })
        }
        if (isSubmit) {
            return {
                storeDeliveryProducts: products,
                totalQtyChecked: totalQty,
            };
        }
    };

    updateQty = (code: number) => {
        const { body, dublicateBarcode } = this.state;
        const storeDeliveryProducts = body.storeDeliveryProducts.map((item) => {

            if (item.parcelNumber === code && item.barcode === dublicateBarcode ) {
                item.qtyChecked = item.qtyChecked + 1;
            }
            return item;
        });
        const totalQty = fixedInHundredth(
            getTotalSum(storeDeliveryProducts, 'qtyChecked'),
        );
        this.setState((prevState: State) => ({
            body: {
                ...prevState.body,
                storeDeliveryProducts,
                totalQtyChecked: +totalQty,
            },
            barcode: '',
            isColisPopUpVisible: false,
        }));
    }

    handleSubmit = async (logisticStatus, updatedStats?) => {
        this.setState({ status: '' });

        const { body, isNew, newBarcode } = this.state;
        const { updateDeliveryStatus = (status) => {} }  = this.props;
        const data: any = { ...body };
        if(!Number(body.totalBoxChecked) || Number(body.totalBoxChecked) === 0) {
            this.setState({ status: 'failure' });
            return;
        }
        if (newBarcode) {
            const res = await this.handleScan(newBarcode, undefined, undefined, true);

            if (!res || !Array.isArray(res.storeDeliveryProducts)) return;

            res.storeDeliveryProducts.shift();
            data.storeDeliveryProducts = res.storeDeliveryProducts;
            data.totalQtyChecked = res.totalQtyChecked;

            this.setState((prevState) => ({
                newBarcode: '',
                newBarcodeQty: '',
                body: {
                    ...prevState.body,
                    storeDeliveryProducts: res.storeDeliveryProducts,
                    totalQtyChecked: res.totalQtyChecked,
                },
            }));
        }

        let storeDeliveryProducts = data.storeDeliveryProducts.map(
            ({ barcode, qtyDelivered = 0, qtyChecked, comment, qtyDefect, parcelNumber }) => {
                if (qtyDefect && comment) {
                    return {
                        parcelNumber,
                        barcode,
                        qtyDelivered,
                        qtyChecked,
                        qtyDefect,
                        comment,
                    };
                } else if (qtyDefect) {
                    return { parcelNumber, barcode, qtyDelivered, qtyChecked, qtyDefect };
                } else if (comment) {
                    return { parcelNumber, barcode, qtyDelivered, qtyChecked, comment };
                }
                return { parcelNumber, barcode, qtyDelivered, qtyChecked };
            },
        );

        if (!isNew) {
            storeDeliveryProducts = storeDeliveryProducts.filter(
                (it) => !isNaN(+it.qtyChecked),
            );
        } else {
            data.slipDate = null;
            data.slipNumber = null;
            data.storeDeliveryId = null;
            data.totalBoxQuantity = Number(body.totalBoxChecked) || 0;
        }
        data.totalBoxChecked = Number(body.totalBoxChecked) || 0;
        delete data.totalQtyDelivered;
        updatedStats ? updateDeliveryStatus(updatedStats) : updateDeliveryStatus(logisticStatus);
        this.props.createDeliveriesStart({
            ...data,
            storeDeliveryProducts,
            logisticStatus,
            updatedStats,
        });
    };

    handleChangeQtyChecked = () => {
        const {
            body: { storeDeliveryProducts: storeDelivery },
            deliveriesProducts
        } = this.state;
        const storeDeliveryProducts = storeDelivery.map((item) => ({
            ...item,
            qtyChecked: item.qtyDelivered,
        }));
        const newDeliveriesProducts = deliveriesProducts.map((item) => ({
            ...item,
            qtyChecked: item.qtyDelivered,
        }))

        const totalQty = fixedInHundredth(
            getTotalSum(storeDeliveryProducts, 'qtyChecked'),
        );
        this.setState((prevStat) => ({
            body: {
                ...prevStat.body,
                storeDeliveryProducts,
                totalQtyChecked: +totalQty,
            },
            deliveriesProducts: newDeliveriesProducts
        }));
    };

    handleAjouter = () => {
        const { deliveriesProducts, newBarcode } = this.state;
        const arr = [...deliveriesProducts];

        if (arr[0] && arr[0].newItem) {
            arr.shift();

            this.setState(
                {
                    newBarcode: '',
                    deliveriesProducts: arr,
                },
                () => this.handleScan(newBarcode, 1),
            );
        } else {
            arr.unshift({ barcode: '', newItem: true });
            this.setState(
                { deliveriesProducts: arr, resetPagination: true },
                () =>
                    this.barcodeInputRef.current &&
                    this.barcodeInputRef.current.focus(),
            );
        }
    };

    inputRefs: any[] = [];
    setRefs = (ref) => this.inputRefs.push(ref);
    handleSearchBarcodeOrColis = (value, type) => {
        if (value) {
            this.setState({ searchLoading: true }, async () => {
                await delay(100);
                const deliveriesProducts =
                    this.state.body.storeDeliveryProducts.filter(
                        (item) => item[type] === value,
                    );
                this.setState({
                    searchLoading: false,
                    deliveriesProducts,
                    currentPage: 1,
                    resetPagination: true,
                });
            });
        }
    };

    changeDirection = () => {
        const { sortDirection } = this.state;
        const newDirection = sortDirection === undefined
            ? 'ascending'
            : sortDirection === 'ascending' ? 'descending' : undefined;

        this.setState({ sortDirection: newDirection });
    }

    getProductList = () => {
        const { sortDirection } = this.state;
        let list = this.state.deliveriesProducts.slice(
            (this.state.currentPage - 1) * this.perPage,
            this.perPage * this.state.currentPage,
        );
        if(!sortDirection) return list;
        return list.sort(
            (prevProduct, nextProduct) => sortDirection === 'ascending'
                ? Number(prevProduct.parcelNumber) - Number(nextProduct.parcelNumber)
                : Number(nextProduct.parcelNumber) - Number(prevProduct.parcelNumber)
        );
    }

    handleSort = (name) => () => {
        const { storeDeliveryProducts } = this.state.body;
        const sorted =
            this.state.sorted === 'ascending' ? 'descending' : 'ascending';
        this.setState({
            deliveriesProducts: sortArrayOfObject(
                storeDeliveryProducts,
                name,
                sorted,
            ),
            sortedColumn: name,
            sorted,
        });
    };

    closeColispopup = () => {
        this.setState({isColisPopUpVisible: false})
    };

    render() {
        const {
            isCreateLoading,
            isUpdateLoading,
            data: { logisticStatus = '', associateFirstName = '', parcels = [] } = {},
            details: { storeDetails = {} } = {},
        } = this.props;
        const storeName = Object.keys(storeDetails) && storeDetails.name;
        const {
            body,
            status,
            isNew,
            isOpen,
            stocks,
            newBarcode,
            showConfirmPopup,
            newBarcodeQty,
            dataPdf,
            isEcart,
            searchLoading,
            resetPagination,
            deliveriesProducts,
            sortedColumn,
            sorted,
            isCreated,
            isColisPopUpVisible,
            importMessage
        } = this.state;
        const isUpdate = !isNew && logisticStatus === LOGISTIC_STATUS_CODE.VAL || logisticStatus === LOGISTIC_STATUS_CODE.CLO;
        const isSave = !isNew && logisticStatus === LOGISTIC_STATUS_CODE.PRO;
        const isDisabled = isUpdate;
        const totalValue = Number(body.totalQtyChecked) - Number(body.totalQtyDelivered);
        const totalCount = deliveriesProducts.length;
        const products = this.getProductList();

        return (
            <div className="deliveriesPopUp">
                <div className="popUp--body set-data-content">
                    <div className="content">
                        <div className="mb-2">
                            <div className="extra content">
                                <div className="ui two buttons">
                                    <CustomBtn
                                        disabled={isDisabled}
                                        handleClick={() =>
                                            this.handleSubmit(
                                                LOGISTIC_STATUS_CODE.PRO,
                                            )
                                        }
                                        classN="btn_1 w-100 blue_btn"
                                        loading={isCreateLoading}
                                    >
                                        {i18next.t(
                                            'Terminal.dashboards.edit',
                                        )}
                                    </CustomBtn>
                                    <CustomBtn
                                        disabled={isDisabled}
                                        classN="btn_1 w-100 red_btn"
                                        handleClick={() =>
                                            this.setState({
                                                showConfirmPopup: true,
                                            })
                                        }
                                        loading={isUpdateLoading}
                                    >
                                        {i18next.t('Terminal.Block3.2')}
                                    </CustomBtn>
                                    <FermerBtn
                                        handleClick={
                                            this.props.handleClosePopUp
                                        }
                                    />
                                </div>
                            </div>
                                {status && (
                                    <div className='ui two buttons'>
                                        <div
                                            className={`w-33 pb-2 ${
                                                status === 'success'
                                                    ? 'successMessage'
                                                    : 'errorMessage'
                                            }`}
                                        >
                                            {isSave ? status === 'success'
                                                ? i18next.t('alertMessage.2')
                                                : status === 'failure'
                                                    ? i18next.t('alertMessage.13')
                                                    : i18next.t('alertMessage.1') : ''}
                                        </div>
                                        <div
                                            className={`w-33 pb-2 ${
                                                status === 'success'
                                                    ? 'successMessage'
                                                    : 'errorMessage'
                                            }`}
                                        >
                                            {isUpdate ? status === 'success'
                                                ? i18next.t('alertMessage.2')
                                                : status === 'failure'
                                                    ? i18next.t('alertMessage.13')
                                                    : i18next.t('alertMessage.1') : ''}
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.Receipt.Deliveries.slipDate',
                                )}
                            </div>
                            <div className="popUp--body--card--body--between">
                                <div className="popUp--body--card--body--data--description">
                                    <DayPicker
                                        value={body.slipDate}
                                        onChange={(date) =>
                                            this.handleDayChange(date)
                                        }
                                    />
                                </div>
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Deliveries.slipNumber',
                                    )}
                                </div>
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description'
                                    }
                                    type="text"
                                    name="slipNumber"
                                    value={body.slipNumber}
                                    onChange={this.handleChangeInput}
                                    autoComplete="off"
                                    disabled={isNew}
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.Receipt.Deliveries.totalQtyDelivered',
                                )}
                            </div>
                            <div className="popUp--body--card--body--between">
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description disabled color--darkgray'
                                    }
                                    value={body.totalQtyDelivered || ''}
                                    disabled={true}
                                    autoComplete="off"
                                />
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.Receipt.Deliveries.totalQtyChecked',
                                    )}
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <Input
                                        className={`popUp--body--card--body--data--description disabled ${
                                            body.totalQtyChecked !==
                                            body.totalQtyDelivered
                                                ? 'color--darkred'
                                                : ''
                                        }`}
                                        value={fixNumber(
                                            body.totalQtyChecked || '',
                                        )}
                                        disabled={true}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="popUp--body--card--body--data">
                                    <div className="popUp--body--card--body--data--header disabled">
                                        {i18next.t('Terminal.dashboards.ecart')}
                                    </div>
                                    <div
                                        className={`popUp--body--card--body--data--description input--div ${
                                            totalValue > 0
                                                ? 'color--green'
                                                : 'color--darkred'
                                        }`}
                                    >
                                        <span>
                                            {totalValue > 0
                                                ? '+' + totalValue
                                                : totalValue}
                                        </span>{' '}
                                        (
                                        <span className="color--green">
                                            {body.totalQtyChecked
                                                ? '+' + body.totalQtyChecked
                                                : 0}
                                        </span>
                                        /
                                        <span className="color--darkred">
                                            {body.totalQtyDelivered}
                                        </span>
                                        )
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.dashboards.totalBoxQuantity',
                                )}
                            </div>
                            <div className="popUp--body--card--body--between">
                                <Input
                                    className={
                                        'popUp--body--card--body--data--description disabled'
                                    }
                                    value={body.totalBoxQuantity || ''}
                                    disabled={true}
                                    autoComplete="off"
                                />
                                <div className="popUp--body--card--body--data--header">
                                    {i18next.t(
                                        'Terminal.dashboards.totalBoxChecked',
                                    )}
                                </div>
                                <Input
                                    error={!Number(body.totalBoxChecked) || Number(body.totalBoxChecked) === 0}
                                    className={
                                        `popUp--body--card--body--data--description ${
                                            Number(body.totalBoxChecked) && Number(body.totalBoxChecked) > 0
                                                ? 'color--green'
                                                : Number(body.totalBoxChecked) === 0
                                                    ? 'color--darken' : 'color--darkred'
                                        }`
                                    }
                                    value={generateInputNumber(body.totalBoxChecked || '')}
                                    name="totalBoxChecked"
                                    onChange={this.handleChangeInput}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="popUp--body--card--body--data">
                            <div className="popUp--body--card--body--data--header">
                                {i18next.t(
                                    'Terminal.Receipt.Deliveries.comment',
                                )}
                            </div>
                            <div className="popUp--body--card--body--between">
                                <TextArea
                                    className="popUp--body--card--body--data--description textarea"
                                    style={{ minHeight: 100 }}
                                    name="comment"
                                    autoComplete="off"
                                    value={body.comment}
                                    onChange={this.handleChangeInput}
                                />
                                <div>
                                    <ImportBarcodesBtn
                                        handleUpload={this.handleScan}
                                        disabled={
                                            !isNew &&
                                            logisticStatus &&
                                            ![
                                                LOGISTIC_STATUS_CODE.PRO,
                                            ].includes(logisticStatus)
                                        }
                                    />
                                    <span
                                        className={`import-info ${importMessage.error ? 'errorMessage' : 'successMessage'}`}
                                    >{importMessage.message}</span>
                                </div>
                                <div>
                                    <PdfTable
                                        disabled={false}
                                        classN="btn_1 w-100 blue_btn"
                                        columns={this.columnsPdf}
                                        data={dataPdf}
                                        ID={body.slipNumber}
                                        currentDate={getDateTimestamp(
                                            body.slipDate,
                                        )}
                                        storeName={storeName}
                                        info={[
                                            `${i18next.t(
                                                'Terminal.Receipt.Deliveries.totalQtyDelivered',
                                            )} ${body.totalQtyDelivered || ''}`,
                                            `${i18next.t(
                                                'Terminal.Receipt.Deliveries.totalQtyChecked',
                                            )} ${fixNumber(
                                                body.totalQtyChecked || '',
                                            )}`,
                                            `${i18next.t(
                                                'Terminal.dashboards.ecart',
                                            )} ${
                                                totalValue < 0 ? '-' : '+'
                                            }${fixNumber(
                                                Math.abs(totalValue),
                                            )}`,
                                        ]}
                                        associateFirstName={associateFirstName}
                                    >
                                        {i18next.t('buttons.29')}
                                    </PdfTable>
                                    <PrintEanBarcodes
                                        data={this.props.data}
                                        details={this.props.details}
                                        buttonStyles="w-100 ml-05"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="dflex justify-content-between">
                            <SearchBox
                                onSubmit={this.handleSearchBarcodeOrColis}
                                label={`${i18next.t(
                                    'Terminal.dashboards.barcode',
                                )}:`}
                                type='barcode'
                                loading={searchLoading}
                                handleReset={() =>
                                    this.setState({
                                        resetPagination: true,
                                        deliveriesProducts:
                                            body.storeDeliveryProducts,
                                    })
                                }
                            />
                            <SearchBox
                                onSubmit={this.handleSearchBarcodeOrColis}
                                label={`${i18next.t(
                                    'Terminal.dashboards.package',
                                )}:`}
                                type='parcelNumber'
                                loading={searchLoading}
                                handleReset={() =>
                                    this.setState({
                                        resetPagination: true,
                                        deliveriesProducts:
                                            body.storeDeliveryProducts,
                                    })
                                }
                                long
                            />
                            <div className="dflex justify-content-end">
                                <CustomBtn
                                    classN="btn_1 ml-1 maxWidth-150 minWidth-150 red_btn smallBtn"
                                    handleClick={this.handleChangeQtyChecked}
                                    disabled={
                                        logisticStatus !==
                                        LOGISTIC_STATUS_CODE.PRO
                                    }
                                >
                                    {i18next.t('buttons.25')}
                                </CustomBtn>
                                <CustomBtn
                                    classN={`btn_1 ml-1 maxWidth-150 minWidth-150 smallBtn ${products[0] && products[0].newItem ? 'active' : ''}`}
                                    handleClick={this.handleAjouter}
                                    disabled={
                                        logisticStatus !==
                                            LOGISTIC_STATUS_CODE.PRO &&
                                        !isCreated
                                    }
                                >
                                    {i18next.t('Terminal.dashboards.ajouter')}
                                </CustomBtn>
                                {!isNew && (
                                    <CustomBtn
                                        classN="btn_1 red_btn ml-1 maxWidth-150 minWidth-150 smallBtn"
                                        handleClick={() =>
                                            this.setState((prev) => ({
                                                isEcart: !prev.isEcart,
                                            }))
                                        }
                                    >
                                        {i18next.t('Terminal.dashboards.ecart')}
                                    </CustomBtn>
                                )}
                            </div>
                        </div>
                        <div className="deliveriesPopUp--body--data">
                            <BarcodeReader
                                onScan={(barcode) => this.handleScan(barcode, undefined, undefined, undefined, true)}
                            />
                        </div>
                    </div>
                    {totalCount ? (
                        <>
                            <Table
                                celled
                                sortable
                                selectable
                                className="productInfoBlock--infoBox--table deliverie"
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.Cell
                                            className="productInfoBlock--infoBox--table--title"
                                            sorted={this.state.sortDirection}
                                            onClick={() => this.changeDirection()}
                                        >
                                            {i18next.t(
                                                'Terminal.dashboards.package',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.barcode',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.code',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell width={4} className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.Name',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.Size',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.qtyDelivered',
                                            )}
                                        </Table.Cell>
                                        <Table.HeaderCell
                                            className="productInfoBlock--infoBox--table--title cursor"
                                            sorted={
                                                sortedColumn === 'qtyChecked'
                                                    ? sorted
                                                    : undefined
                                            }
                                            onClick={this.handleSort(
                                                'qtyChecked',
                                            )}
                                        >
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.qtyChecked',
                                            )}
                                        </Table.HeaderCell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.qtyDefect',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.dashboards.ecart',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.stock',
                                            )}
                                        </Table.Cell>
                                        <Table.Cell width={1} className="productInfoBlock--infoBox--table--title">
                                            {i18next.t(
                                                'Terminal.Receipt.Deliveries.comment',
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body className="deliveri-list product---list--content">
                                    {products.map((item, i) => {
                                        const valueQty = fixedInHundredth(
                                            Number(item.qtyChecked) -
                                                Number(item.qtyDelivered),
                                        );
                                        if (isEcart && !valueQty)
                                            return <React.Fragment key={i} />;

                                        return (
                                            <Table.Row
                                                key={i}
                                                className={
                                                    item.newItem
                                                        ? 'table--active--row'
                                                        : `${i % 2 === 0 ? "white-row" : "light-gray-row"}`
                                                }
                                            >
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.parcelNumber}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.newItem ? (
                                                        <Input
                                                            ref={
                                                                this
                                                                    .barcodeInputRef
                                                            }
                                                            value={newBarcode}
                                                            onChange={({
                                                                target,
                                                            }) =>
                                                                this.setState({
                                                                    newBarcode:
                                                                        target.value,
                                                                })
                                                            }
                                                        />
                                                    ) : (
                                                        item.barcode
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.altReference}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.name}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.size}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description text-bold">
                                                    {item.qtyDelivered}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {item.newItem ? (
                                                        <Input
                                                            type="number"
                                                            value={
                                                                newBarcodeQty
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) =>
                                                                this.setState({
                                                                    newBarcodeQty:
                                                                        target.value,
                                                                })
                                                            }
                                                        />
                                                    ) : (
                                                        <Input
                                                            type="number"
                                                            name={i}
                                                            value={
                                                                item.qtyChecked
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChangeQty
                                                            }
                                                            onBlur={
                                                                this.handleBlur
                                                            }
                                                            onFocus={
                                                                this.onFocus
                                                            }
                                                            ref={this.setRefs}
                                                        >
                                                            <input data-key={`${item.barcode}${item.parcelNumber}`} />
                                                        </Input>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {isNew || item.newItem ? (
                                                        item.qtyDefect
                                                    ) : (
                                                        <Input
                                                            type="number"
                                                            name="qtyDefect"
                                                            value={
                                                                item.qtyDefect ||
                                                                ''
                                                            }
                                                            onChange={({
                                                                target,
                                                            }) =>
                                                                this.handleChangeValue(
                                                                    target,
                                                                    item.barcode,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell
                                                    className={`productInfoBlock--infoBox--table--description ${
                                                        valueQty < 0
                                                            ? 'color--red'
                                                            : valueQty > 0
                                                            ? 'color--green'
                                                            : ''
                                                    }`}
                                                >
                                                    <span>
                                                        {valueQty > 0
                                                            ? '+' + valueQty
                                                            : valueQty}
                                                    </span>
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    {stocks[item.barcode]}
                                                </Table.Cell>
                                                <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                    <TextArea
                                                        name={item.barcode}
                                                        rows={1}
                                                        value={
                                                            item.comment || ''
                                                        }
                                                        onChange={
                                                            this
                                                                .handleChangeItemComment
                                                        }
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                            {totalCount > this.perPage && (
                                <Pagination
                                    onChangePage={(currentPage) =>
                                        this.setState({
                                            currentPage,
                                            resetPagination: false,
                                        })
                                    }
                                    totalCount={totalCount}
                                    perPage={this.perPage}
                                    resetDefault={resetPagination}
                                />
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </div>
                <InfoPopUp
                    open={isOpen}
                    message={i18next.t('Terminal.Receipt.Deliveries.PopUp')}
                    handleClose={() => this.setState({ isOpen: false })}
                />
                {showConfirmPopup && (
                    <ConfirmPopup
                        handleCancel={() => {
                            this.setState({ showConfirmPopup: false });
                        }}
                        handleConfirm={() => {
                            this.handleSubmit(
                                LOGISTIC_STATUS_CODE.PRO,
                                LOGISTIC_STATUS_CODE.VAL,
                            );
                            this.setState({ showConfirmPopup: false });
                        }}
                        text={i18next.t('Terminal.modal.7')}
                    />
                )}
                {isColisPopUpVisible && (
                    <PopUp handleClose={() => this.closeColispopup()}>
                        <ColisPopup
                            simpleValidation={true}
                            updateQty={this.updateQty}
                            closeColispopup={this.closeColispopup}
                            parcels={parcels}
                        />
                    </PopUp>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        isUpdateLoading: getUpdateLoading(state),
        isCreateLoading: getCreateLoading(state),
        createdStatus: getCreatedStatus(state),
        details: getAccountResource(state),
        createdData: getCreatedData(state),
    };
};

const mapDispatchToProps = (dispatch): typeof DeliveriesActionCreators => {
    return bindActionCreators(DeliveriesActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeliveries);
