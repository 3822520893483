import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {Icon} from "semantic-ui-react";
import i18next from "i18next";
import ExportCsvData from './exportCsv';
import {filterByDate} from "../../../../helpers/helpers";


interface State {
    from: any,
    to: any,
}

interface Props {
    filterData : any,
    filter:any,
    columns:any,
    csvData?: any
}
export default class TableDatePicker extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);

    }

    state: State = {
        from: new Date(),
        to: new Date(),
    };


    handleFromChange(from) {
        // Change the from date and focus the "to" input field
        this.setState({from});
    }

    handleToChange(to) {
        this.setState({to});
    }
     parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

     formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }
    filterStart=()=> {
        const {from, to} = this.state;
        const newData= filterByDate({from,to},this.props.filterData,this.props.columns);
        if(newData){
            this.props.filter(newData)
        }
    }
    render() {
        const {csvData = {}} = this.props
        const {headers, data, name} = csvData
        const {from, to} = this.state;
        const modifiers = {start: from, end: to};
        const FORMAT = 'dd/MM/yyyy';
        return (
            <div className="datapickerContent">
                <div className="datapickerContent--writeBox">
                    <DayPickerInput
                        value={from}
                        placeholder="From"
                        format={FORMAT}
                        formatDate={this.formatDate}
                        parseDate={this.parseDate}
                        dayPickerProps={{
                            selectedDays: [from, {from, to}],
                            disabledDays: {after: to},
                            toMonth: to,
                            modifiers,
                            numberOfMonths: 1,
                        }}
                        onDayChange={this.handleFromChange}
                    />
                    <Icon className="calendar alternate outline dateIcons" />
                </div>
                <div className="datapickerContent--writeBox">
                    <DayPickerInput
                        ref={el => (to == el)}
                        value={to}
                        placeholder="To"
                        format={FORMAT}
                        formatDate={this.formatDate}
                        parseDate={this.parseDate}
                        dayPickerProps={{
                            selectedDays: [from, {from, to}],
                            disabledDays: {before: from},
                            modifiers,
                            month: from,
                            fromMonth: from,
                            numberOfMonths: 1,
                        }}
                        onDayChange={this.handleToChange}
                    />
                    <Icon className="calendar alternate outline dateIcons" />
                </div>
                <button className="btn_1 w-100" onClick={this.filterStart}>{i18next.t('Terminal.dashboards.apply')}</button>
                {headers && data ? <ExportCsvData headers={headers} data={data} name={name} /> : ""}
            </div>
        );
    }
}
