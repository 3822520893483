import React from "react";
import {connect} from "react-redux";
import i18next from "i18next";
import {Accordion, Icon} from "semantic-ui-react";
import parse from "html-react-parser";
import {ProductActionCreators} from "../redux/actions";
import {getCare, getComposition, getProduct} from "../redux/selectors";
import {bindActionCreators} from "redux";

interface Props {
    data?: any,
    care: any,
    composition: any,
}

interface State {
    activeDetail: string,
    activeIndex: any,
    care?: any,
    composition?: any
}

class Description extends React.Component<Props & typeof ProductActionCreators> {
    state: State = {
        activeDetail: "description",
        activeIndex: -1
    };

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        this.setState({activeIndex: newIndex})
    };

    componentDidMount() {
        this.props.careStart(this.props.data.productId);
        this.props.compositionStart(this.props.data.productId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data && nextProps.data.productId !== this.props.data.productId) {
            this.props.careStart(nextProps.data.productId);
            this.props.compositionStart(nextProps.data.productId);
        }
    }

    handleDetailsClick(detail) {
        this.setState({activeDetail: detail});
    }

    getContent = (detail) => {
        if (detail === "description") {
            return (<div className="poure-products-category-content-text">
                <div
                    className="poure-products-category-text">{parse(this.props.data.description ? this.props.data.description : "")}</div>
            </div>);
        }
        if (detail === "entretien") {
            const {care} = this.props;
            return (<div className="poure-products-category-content-text">
                <div className="poure-products-category-text">{
                    care && Object.keys(care).length ?
                        Object.keys(care).map((el, index) => (
                            <div key={index}><h4>{el}</h4>
                                {care[el].length && care[el].map((item, i) => <p key={i}>{item}</p>)}
                            </div>
                        ))
                        : ""
                }</div>
            </div>);
        }
        if (detail === "composition") {
            const {composition} = this.props;
            return (<div className="poure-products-category-content-text">
                <div className="poure-products-category-text">{
                    composition && Object.keys(composition).length ?
                        Object.keys(composition).map((el, index) => (
                            <div key={index}><h4>{el}</h4>
                                {composition[el].length && composition[el].map((item, i) => item.startsWith("Material -") ?
                                    <p key={i}>{item.replace("Material - ", "")}</p> : "")}
                            </div>
                        ))
                        : ""
                }</div>
            </div>);
        }
    };

    render() {
        const {activeDetail, activeIndex} = this.state;
        const content = activeIndex === -1 ? this.getContent(activeDetail) : this.getContent(activeIndex);
        return (
            <React.Fragment>
                <div className="poure-products-category-content">
                    <div className={`poure-products-category-item ${activeDetail === "description" ? "active" : ""}`}
                         onClick={this.handleDetailsClick.bind(this, "description")}>
                        <h4>{i18next.t('product.details.2')}</h4>
                    </div>
                    <div className={`poure-products-category-item ${activeDetail === "entretien" ? "active" : ""}`}
                         onClick={this.handleDetailsClick.bind(this, "entretien")}>
                        <h4>{i18next.t('product.details.3')}</h4>
                    </div>
                    <div className={`poure-products-category-item ${activeDetail === "composition" ? "active" : ""}`}
                         onClick={this.handleDetailsClick.bind(this, "composition")}>
                        <h4>{i18next.t('product.details.4')}</h4>
                    </div>
                </div>
                {content}

                <Accordion className="pour-products-accordion">
                    <div>
                        <Accordion.Title active={activeIndex === "description"} index={"description"}
                                         onClick={this.handleClick}>
                            {i18next.t('product.details.2')}
                            <Icon name={activeIndex === "description" ? 'minus':'plus' } className="pull-right"/>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === "description"}>
                            {content}
                        </Accordion.Content>
                    </div>

                    <div>
                        <Accordion.Title active={activeIndex === "entretien"} index={"entretien"}
                                         onClick={this.handleClick}>
                            {i18next.t('product.details.3')}
                            <Icon name={activeIndex === "entretien" ? 'minus':'plus' } className="pull-right"/>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === "entretien"}>
                            {content}
                        </Accordion.Content>
                    </div>

                    <div>
                        <Accordion.Title active={activeIndex === "composition"} index={"composition"}
                                         onClick={this.handleClick}>
                            {i18next.t('product.details.4')}
                            <Icon name={activeIndex === "composition" ? 'minus':'plus' } className="pull-right"/>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === "composition"}>
                            {content}
                        </Accordion.Content>
                    </div>
                </Accordion>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        care: getCare(state),
        composition: getComposition(state)
    }
};

const mapDispatchToProps = (dispatch): typeof ProductActionCreators => {
    return bindActionCreators(ProductActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);