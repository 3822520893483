import { createReducer } from 'reduxsauce';
import { NullBelegTypes } from './actions';

export interface IPaymentState {
    error: string;
    status: boolean;
    loading: boolean;
}

export const nullBeleg: IPaymentState = {
    error: '',
    status: false,
    loading: false,
};

export const printNullBeleg = (state) => {
    return { ...state, status: false, loading: true };
};

export const printNullBelegStartSuccess = (state, action) => {
    return { ...state, status: true, loading: false };
};

export const printNullBelegStartFailure = (state, action) => {
    return { ...state, error: action.error, status: false, loading: false };
};

export const handlers = {
    [NullBelegTypes.PRINT_NULL_BELEG_START]: printNullBeleg,
    [NullBelegTypes.PRINT_NULL_BELEG_SUCCESS]: printNullBelegStartSuccess,
    [NullBelegTypes.PRINT_NULL_BELEG_FAILURE]: printNullBelegStartFailure,
};

export default createReducer(nullBeleg, handlers);
