import React from "react";
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';

import {getMenu} from "../../../layouts/main/header/redux/selectors";
import {BASE_API_GATEWAY, BASE_URL} from "../../../utils/constants/service";
import {getLangPrefix} from "../../../helpers/helpers";


interface Props {
    menuList: any,
    pageKey?: string,
    productData?: any,
    navTabs?: any,
    location?: any,
    selectedMenuItem?: any,
    dataFAQ?: any
}

interface State {
    selectedMenuItem: any,
    selectedItem: any,
    jsonLdData: any,
}

class PageMetaData extends React.Component<Props> {
    state: State = {
        selectedMenuItem: this.props.selectedMenuItem || {},
        selectedItem: this.props.productData && Object.keys(this.props.productData).length ? this.props.productData : this.props.selectedMenuItem ? this.props.selectedMenuItem : {},
        jsonLdData: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "url":  window.location.protocol + "//" + window.location.host,
            "logo": window.location.protocol + "//" + window.location.host +"/img/logo.png"
        })
    };

    componentDidMount() {
        if (this.props.dataFAQ && this.props.dataFAQ.length) {
            const faqData = this.generateFAQDataForJsonLd(this.props.dataFAQ);
            Object.keys(faqData).length ? this.setState({jsonLdData: JSON.stringify(faqData)}) : "";
        }

        if (window.caches) {
            caches.keys().then(cs => cs.forEach(c => caches.delete(c)));
        }

        if (this.props.pageKey && this.props.menuList && this.props.menuList.length) {
            this.setMenuItemByPageKey(this.props.pageKey, this.props.menuList)
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.dataFAQ && nextProps.dataFAQ.length && this.props.dataFAQ && this.props.dataFAQ.length !== nextProps.dataFAQ.length) {
            const faqData = this.generateFAQDataForJsonLd(nextProps.dataFAQ);
            Object.keys(faqData).length ? this.setState({jsonLdData: JSON.stringify(faqData)}) : "";
        }

        if (nextProps.menuList !== this.props.menuList && nextProps.menuList.length && this.props.pageKey) {
            this.setMenuItemByPageKey(this.props.pageKey, nextProps.menuList)
        }
        if (Array.isArray(nextProps.navTabs) && Array.isArray(this.props.navTabs) &&
            (nextProps.navTabs.length !== this.props.navTabs.length || nextProps.navTabs.length && this.props.navTabs.length && nextProps.navTabs[nextProps.navTabs.length-1].url !==this.props.navTabs[this.props.navTabs.length-1].url)) {


            if (nextProps.productData && Object.keys(nextProps.productData).length) {
                const {productData} = nextProps;
                const jsonLdData = {
                        "@context": "https://schema.org/",
                        "@type": "ItemPage",
                        "@graph": [
                            {
                                "@type": "Product",
                                "name": productData.name,
                                "image": [window.location.protocol + "//" + window.location.host + "/externalgateway" + productData.photo],
                                "description": productData.metaDescription || productData.description,
                                "brand": {
                                    "@type": "Thing",
                                    "name": productData.collection
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "url": window.location.protocol + "//" + window.location.host + window.location.pathname,
                                    "priceCurrency": productData.currency,
                                    "price": productData.discountDetail ? productData.listPriceAfterDiscount : productData.startingPrice,
                                    "availability": "InStock",
                                }
                            },
                            {
                                "@type": "BreadcrumbList",
                                "itemListElement": this.generateBreadCrumbsForJsonLd(nextProps.navTabs)
                            }
                        ]
                    }
                this.setState({jsonLdData: JSON.stringify(jsonLdData)})
            } else {
                const jsonLdData = {
                    "@context": "https://schema.org/",
                    "@type": "ItemPage",
                    "@graph":[
                        {
                            "@type": "Organization",
                            "url": window.location.protocol + "//" + window.location.host,
                            "logo": window.location.protocol + "//" + window.location.host + "/img/logo.png"
                        },
                        {
                            "@type": "BreadcrumbList",
                            "itemListElement": this.generateBreadCrumbsForJsonLd(nextProps.navTabs)
                        }
                    ]
                }
                this.setState({jsonLdData: JSON.stringify(jsonLdData)});
            }
        }

        if (nextProps.selectedMenuItem !== this.props.selectedMenuItem) {
            this.setState({
                selectedMenuItem: nextProps.selectedMenuItem,
                selectedItem: this.props.productData && Object.keys(this.props.productData).length ? this.props.productData : nextProps.selectedMenuItem
            });
        }


    }

    generateBreadCrumbsForJsonLd(navTabs) {
        const breadcrumbList: any = [];
        let breadcrumbData: any = {};
        let breadcrumbName = "";
        let position = 1;
        if (navTabs.length) {
            navTabs.map(item => {
                if (item.url) {
                    breadcrumbName += item.name;
                    breadcrumbList.push({
                        "@type": "ListItem",
                        "position": position++,
                        "name": breadcrumbName,
                        "item": window.location.protocol + "//" + window.location.host + item.url
                    })
                    breadcrumbName = "";
                } else {
                    breadcrumbName += breadcrumbName ? ` > ${item.name} > ` : item.name;
                }
            });
        }
        if (breadcrumbList.length) {
            // breadcrumbData = {
            //     "@context": "https://schema.org",
            //     "@type": "BreadcrumbList",
            //     "itemListElement": breadcrumbList
            // };
            return breadcrumbList;
        } else {
            return breadcrumbList.push({"@type":"ListItem","position":1,"name":"Home","item":window.location.protocol + "//" + window.location.host})
        }
    }

    generateFAQDataForJsonLd(data) {
        let response: any = [];
        data.map(item => {
            item.value = item.value.replace("<p>", "").replace(/<\/p>([^<\/p>]*)$/, '$1');
            const valueArray = item.value.split("<b>");
            // for(let i=1; i < valueArray.length; i++) {
            const length = valueArray.length > 3 ? 3 : valueArray.length;
            for (let i = 1; i < length; i++) {
                const el = valueArray[i].split("</b>");
                if (el.length) {
                    response.push({
                        "@type": "Question",
                        "name": el[0],
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": el[1]
                        }
                    })
                }
            }
        })

        response = response.length ? {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": response
        } : {};
        return response;
    }


    setMenuItemByPageKey = (pageKey, menuList) => {
        const selectedMenuItem = menuList.find((item) => item.key === pageKey);
        this.setState({
            selectedMenuItem,
            selectedItem: this.props.productData && Object.keys(this.props.productData).length ? this.props.productData : selectedMenuItem
        })

    };

    render() {
        const {selectedMenuItem, jsonLdData, selectedItem} = this.state;

        return (
            <>
                {selectedItem && Object.keys(selectedItem).length ?
                    <Helmet>
                        <title>{selectedItem.metaTitle ? selectedItem.metaTitle : selectedItem.name}</title>
                        <meta name="description" content={selectedItem.metaDescription ? selectedItem.metaDescription : selectedItem.description}/>
                        <meta name="keywords" content={selectedItem.keywords}/>
                        {this.props.productData && Object.keys(this.props.productData).length ?
                            <link
                                rel="canonical"
                                href={`${window.location.protocol + "//" + window.location.host}${getLangPrefix()}${this.props.productData.canonicalMenu || selectedMenuItem.canonicalUrl}/${this.props.productData.canonicalUrl}`}
                            />
                            :
                            selectedMenuItem && selectedMenuItem.canonicalUrl ?
                                <link
                                    rel="canonical"
                                    href={`${window.location.protocol + "//" + window.location.host}${getLangPrefix()}${selectedMenuItem.canonicalUrl}`}
                                />
                                :
                                ""
                        }
                        {
                            jsonLdData &&
                            <script type="application/ld+json">{`
                            ${jsonLdData}
                        `}</script>
                        }
                    </Helmet>
                    : ""
                }
            </>
        );

    }
}

const mapStateToProps = (state: any): Props => {
    return {
        menuList: getMenu(state),
    }
};

export default connect(mapStateToProps)(PageMetaData);7
