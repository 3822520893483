import { DatatableTypes, DatatableActionCreators } from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { CREATE_STORE_TRANSFER_URL, getAxiosHeaders, UPDATE_STATUS } from '../../../utils/constants/service';
import { DEFAULT_CURRENCY, TRANSFER_STATUS_CODE } from '../../../utils/constants/variables';
import { fetchStores } from '../../../utils/constants/service';
import { StoresFormActionCreators } from '../../storeFormSidePanel/redux/actions';
import { TransfersActionCreators } from '../../transfers/redux/actions';
import { updateStoreTransfer } from '../../../utils/constants/service';
import axios from 'axios';

 const createStoreTransfer = async (body) =>
    await axios
        .post(
            CREATE_STORE_TRANSFER_URL,
            body,
            getAxiosHeaders(DEFAULT_CURRENCY),
        )
        .then((res) => res);

const setOrderStatus = (orderId, status, generateOrderFile = false) =>
    axios
    .put(
        `${UPDATE_STATUS}/${orderId}/update-status?orderStatus=${status}&generateOrderFile=${generateOrderFile}`,
        {},
        getAxiosHeaders(),
    )
    .then((res) => res);

export function* updatedOrderStatus(params) {
    const { orderId, status, generateOrderFile, stroreTransferBody = "" } = params;
    try {
        let result = yield call(() => setOrderStatus(orderId, status, generateOrderFile));

        // if(stroreTransferBody){
        //     if(result.status === 200) {
        //         let data = yield call(() => fetchStores());
        //         yield put(StoresFormActionCreators.storesSuccess(data));

        //         const selectedStore = data.find((item) => item.storeType === 'E') || {};
        //         stroreTransferBody.storeCodeRecv = selectedStore.storeCode;

        //         let storeTransfer = yield call(() => createStoreTransfer(stroreTransferBody))
        //         yield put(TransfersActionCreators.createTransferSuccess(storeTransfer.data))

        //         if(storeTransfer.status === 200){
        //             let transferCode = storeTransfer.data.storeTransferCode;
        //             let createStoreTransfer = yield call(() => updateStoreTransfer(transferCode, TRANSFER_STATUS_CODE.REC))
        //             yield put(TransfersActionCreators.updateTransferSuccess(createStoreTransfer.data));
        //         }
        //     }
        // }

        yield put(DatatableActionCreators.updateOrderStatusSuccess(result.data));
    } catch (e) {
        yield put(DatatableActionCreators.updateOrderStatusFailure(e.message));
    }
}

export function* datatableSaga() {
    yield takeLatest(
        DatatableTypes.UPDATE_ORDER_STATUS_START,
        updatedOrderStatus,
    );
}

export default datatableSaga;