export const getMenu = state => state.menuR.menu;
export const getError = state => state.menuR.error;
export const getLanguage = state => state.menuR.language;
export const getActiveMenuKey = state => state.menuR.activeMenuKey;
export const getBreadcrumb = state => state.menuR.breadcrumb;
export const clientCount = state => state.menuR.clientCounter;
export const getnotificationCounters = state => state.menuR.notificationCounters;
export const getConnectedStatus = state => state.menuR.isDisconnected;
export const getFiscalTssDetails = state => state.menuR.tssDetails;
export const getClockDate = state => state.menuR.clockDate;
