import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import './multiSelectDropdown.scss';

type OptionType = {
    key: number | string,
    value: number | string,
    text: number | string | undefined
}

interface Props {
    options: Array<OptionType>;
    value: Array<number | string>;
    onChange: (event: any) => void;
    inputValidation: {
        regex: RegExp;
        message: string
    }
    placeholder?: string;
}

const MultiDropdown: React.FC<Props> = ({
    options,
    placeholder = "",
    value,
    onChange,
    inputValidation
}) => {
    const [dynamicOptions, setDynamicOptions] = useState<OptionType[]>([])
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const [newItemValue, setNewItemValue] = useState<string>('');

    useEffect(() => {
        setDynamicOptions(options);
    }, [options])

    const addNewOption = (event) => {
        if (event.keyCode === 13) {
            const newOption = {
                key: new Date().getTime(),
                value: newItemValue.padStart(5, '0'),
                text: newItemValue.padStart(5, '0')
            }

            setDynamicOptions([newOption, ...dynamicOptions]);
            setNewItemValue('');
        }
    }

    const deleteItem = (index) => {
        const data = [...value];
        data.splice(index, 1);
        onChange({value: data});
    }

    const showDropDown = ({ target }) => {
        if (
            !target.classList.contains('selected-item')
            && !target.closest('.selected-item')
        ) {
            toggleDropdown();
        }
    }

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    }

    const onNewInputChange = ({ target }) => {
        const { value } = target;
        const { regex } = inputValidation;
        const newValue = value.padStart(5, '0');

        if (regex.test(newValue)) {
            setNewItemValue(value)
        }

    }

    const selectOption = (option) => {
        const list = [...value, option.value];

        onChange({ value: list });
    }

    return (
        <div className='multi-container'>
            <div
                className='selected-items'
                onClick={showDropDown}
            >
                {value.map((value, index) => {
                    const selectedOption = (dynamicOptions.find((option) => option.value === value) || { text: '' });
                    if (value) {
                        return (
                            <div
                                key={index}
                                className='selected-item'
                            >
                                <span>{selectedOption.text}</span>
                                <Icon
                                    name="close"
                                    onClick={() => deleteItem(index)}
                                ></Icon>
                            </div>
                        )
                    }
                })}
            </div>
            <Icon onClick={toggleDropdown} className='dropdown-icon' name="caret down"></Icon>
            {isDropdownVisible && (
                <div className='list-container'>
                    <input
                        type="text"
                        onKeyDown={addNewOption}
                        onChange={onNewInputChange}
                        value={newItemValue}
                        placeholder={placeholder}
                    />
                    <div className='options-list'>
                        {dynamicOptions.map((option) => {
                            const selected = value.find((item) => item === option.value);
                            if (!selected) {
                                return (
                                    <div
                                        key={option.key}
                                        onClick={() => selectOption(option)}
                                    >{option.text}</div>
                                )
                            }
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default MultiDropdown;