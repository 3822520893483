import { pdf } from '@react-pdf/renderer';
import i18next from 'i18next';
import React, { useState } from 'react';
import {
    delay,
    textToBase64Barcode,
    multipleDuplicationOfArrayElements,
} from '../../helpers/helpers';
import CustomBtn from '../common/customBtn';
import {
    BarcodePositionSidePanel,
    GeneretBarcodes,
} from './barcodePositionSidePanel';

interface Props {
    data: any;
    details: any;
    showCount?: boolean;
    buttonStyles?: string;
}

export default function PrintEanBarcodes({
    data = {},
    details,
    showCount = false,
    buttonStyles = '',
}: Props) {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleBarcodePdf = async (value = 1, count = 1) => {
        try {
            setLoading(true);
            await delay(100);
            const { products = [] } = data;
            const position = Array(+value - 1 || 0).fill({});
            let pdfData: any = [];

            products.forEach((item) => {
                for (
                    let i = 0;
                    i < (item.totalQtyChecked || item.quantity);
                    i++
                ) {
                    pdfData.push({
                        barcode: textToBase64Barcode(item.barcode, 'EAN13'),
                        productName: item.name,
                        size: item.size,
                        price: item.price || item.listPrice,
                        listPrice: item.listPrice,
                        outletPrice: item.outletPrice,
                        promoListPrice: item.promoListPrice,
                        color: item.color,
                        currency: item.currency,
                        promotionDetails: item.promotionDetails,
                    });
                }
            });

            if (count > 1) {
                pdfData = multipleDuplicationOfArrayElements(pdfData, +count);
            }

            const blob = await pdf(
                <GeneretBarcodes
                    data={[...position, ...pdfData]}
                    details={details}
                />,
            ).toBlob();
            const url = URL.createObjectURL(blob);
            setLoading(false);
            setShow(false);
            window.open(url, '_blank');
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <CustomBtn
                handleClick={() => setShow(true)}
                classN={`btn_1 w-100 orange_btn smallBtn ${buttonStyles}`}
            >
                {i18next.t('buttons.37')}
            </CustomBtn>
            {show && (
                <BarcodePositionSidePanel
                    handleClose={() => setShow(true)}
                    handleClick={handleBarcodePdf}
                    loading={loading}
                    showCount={showCount}
                />
            )}
        </>
    );
}
