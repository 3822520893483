import {createReducer} from 'reduxsauce';
import {AddToCartTypes} from './actions';

export interface IAddToCartState {
    basket: any,
    addedToCart: boolean,
    error: string,
    maxLimit: boolean,
}

export const addToCart: IAddToCartState = {
    basket: {},
    addedToCart: false,
    error: "",
    maxLimit: false,
};

export const addtocart = (state) => {
    return {...state, addedToCart: false, maxLimit: false};
};

export const addtocartSuccess = (state, action) => {
    const {data} = action;
    return {...state, addedToCart: data.added, maxLimit: !data.added, basket: data.basket};
};

export const addtocartFailure = (state, action) => {
    return {...state, error: action.error, addedToCart: false, maxLimit: false};
};

export const basketStart = (state) => {
    return {...state};
};

export const basketSuccess = (state, action) => {
    return {...state, addedToCart: false, maxLimit: false, basket: action.data};
};

export const removeBasket = (state) => {
    return {...state};
};

export const removeBasketSuccess = (state, action) => {
    return {...state, addedToCart: false, maxLimit: false, basket: action.data};
};

export const handlers = {
    [AddToCartTypes.ADDTOCART_START]: addtocart,
    [AddToCartTypes.ADDTOCART_SUCCESS]: addtocartSuccess,
    [AddToCartTypes.ADDTOCART_FAILURE]: addtocartFailure,
    [AddToCartTypes.GET_BASKET_START]: basketStart,
    [AddToCartTypes.GET_BASKET_SUCCESS]: basketSuccess,
    [AddToCartTypes.REMOVE_BASKET_START]: removeBasket,
    [AddToCartTypes.REMOVE_BASKET_SUCCESS]: removeBasketSuccess,
};

export default createReducer(addToCart, handlers);
