import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppointmentsActionCreators } from '../redux/actions';
import { getStoreClient } from '../redux/selectors';
import i18next from 'i18next';
import { Input, Message } from 'semantic-ui-react';
import AutocompleteDropdown from '../../common/autocompleteDropdown';
import {
    getCountryOptions,
    getLocalFormOptions,
} from '../../../helpers/helpers';
import { getAccountResource } from '../../login/redux/selectors';

interface Props {
    closePopup: () => void;
    selectedAppointment: any;
    getStoreClientStart: (id) => void;
    storeClient: any;
    account: any;
}

const AppointmentClient: React.FC<Props> = ({
    closePopup,
    selectedAppointment,
    getStoreClientStart,
    storeClient,
    account,
}) => {
    const [appointmentClientFormData, setAppointmentClientFormData] =
        useState<any>({
            address1: '',
            address2: '',
            city: '',
            zip: '',
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            gender: '',
            countryUn: '',
            dropdownOptions: {
                country: [],
            },
        });

    const {
        address1,
        address2,
        city,
        zip,
        email,
        firstName,
        lastName,
        phone,
        gender,
        countryUn,
        country,
        dropdownOptions: { country: countryOptions },
    } = appointmentClientFormData;

    useEffect(() => {
        getStoreClientStart(selectedAppointment.storeClientId);
    }, []);

    useEffect(() => {
        if (storeClient) {
            const { profile = {}, billingAddresses = [] } = storeClient || {};
            const [billAddress = {}] = billingAddresses;
            const {
                address1 = '',
                address2 = '',
                city = '',
                zip = '',
            } = billAddress;
            let { country = '' } = billAddress;
            let countryUn = '';
            const {
                email = '',
                firstName = '',
                lastName = '',
                phone = '',
                gender = '',
            } = profile;

            const countryOptions = getLocalFormOptions(
                'countries',
                'name',
                'name',
                'code',
            );

            if (!country) {
                const storeCountry =
                    (account.storeDetails && account.storeDetails.country) ||
                    '';
                const findCountry = countryOptions.find(
                    (item) => item.key === storeCountry,
                );
                if (findCountry && findCountry.value) {
                    country = findCountry.value;
                }
            }

            if (
                country &&
                !getCountryOptions().find(
                    (item) =>
                        item.value.toUpperCase() === country.toUpperCase(),
                )
            ) {
                countryUn = country;
                country = country;
            }

            if (storeClient.profile) {
                setAppointmentClientFormData({
                    countryUn,
                    country,
                    address1,
                    address2,
                    city,
                    zip,
                    firstName,
                    lastName,
                    phone,
                    gender,
                    email,
                    dropdownOptions: {
                        country: countryOptions,
                    },
                });
            }
        }
    }, [storeClient]);

    return (
        <div className="popUp--body--card--body set-data-content">
            <div className="popUp--body--card--body--data">
                <div className="popUp--body--card--body--data--header w-16-panel-city">
                    {i18next.t('Terminal.dashboards.gender.title')}
                </div>
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data--description clienttab ">
                        <label className="radioLabel">
                            {i18next.t('Terminal.dashboards.gender.male')}
                            <Input
                                type="radio"
                                value="M"
                                name="gender"
                                checked={gender === 'M'}
                                autoComplete="off"
                            />
                        </label>
                        <label className="radioLabel">
                            {i18next.t('Terminal.dashboards.gender.female')}
                            <Input
                                type="radio"
                                value="F"
                                name="gender"
                                checked={gender === 'F'}
                                autoComplete="off"
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.firstName')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab `}
                            value={firstName}
                            name="firstName"
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.lastName')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab `}
                            value={lastName}
                            name="lastName"
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.email')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  email `}
                            value={email}
                            name="email"
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.phone')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab `}
                            value={phone}
                            name="phone"
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header  w-16-panel">
                            {i18next.t('Terminal.dashboards.address1')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab input`}
                            value={address1}
                            name="address1"
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header w-16-panel">
                            {i18next.t('Terminal.dashboards.address2')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab input`}
                            name="address2"
                            value={address2}
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.zip')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab input`}
                            value={zip}
                            name="zip"
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.city')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab input`}
                            name="city"
                            value={city}
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header w-16-panel-city">
                        {i18next.t('Terminal.dashboards.country')}
                    </div>
                    <div
                        className={
                            'popUp--body--card--body--data--description  clienttab input'
                        }
                    >
                        <Input
                            className={`popUp--body--card--body--data--description  clienttab input`}
                            name="city"
                            value={country || ''}
                            autoComplete="off"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
            <div className="btn_1 w-100" onClick={closePopup}>
                {i18next.t('buttons.40')}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): any => ({
    account: getAccountResource(state),
    storeClient: getStoreClient(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(AppointmentsActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentClient);
