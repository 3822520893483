import {returnTypes, ReturnActionCreators} from "./actions";
import {takeLatest, put, call, select,} from "redux-saga/effects";
import axios from 'axios';
import {fetchTickets, STORE_RETURN_URL, UPDATE_STORE_RETURN_URL} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers"
import { getAccountResource } from "../../login/redux/selectors";


export function* getreturnData(params) {
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.get(`${STORE_RETURN_URL}?language=${getApiPrefix()}`, headers)
                .then(res => res.data)
        });
        yield put(ReturnActionCreators.returnSuccess(data));
    } catch (e) {
        yield put(ReturnActionCreators.returnFailure(e.message));
    }
}


export function* updateReturn(params) {
    const {body} = params
    const privateToken = sessionStorage.getItem("privateToken");
    const token = localStorage.getItem("token");
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${privateToken}`,
                "Associate-Authorization": `${token}`
            }
        };
        const data = yield call(() => {
            return axios.post(`${UPDATE_STORE_RETURN_URL}`, body, headers)
                .then(res => res.data)
        });
        yield put(ReturnActionCreators.updateReturnSuccess(data));
        if(data) {
            yield put(ReturnActionCreators.returnStart());
        }
    } catch (e) {
        yield put(ReturnActionCreators.updateReturnFailure(e.message));
    }
}

export function* getTickets() {
    try {
        const { storeCode } = yield select(getAccountResource);
        const data = yield call(() => fetchTickets(storeCode));
        yield put(ReturnActionCreators.getTicketsSuccess(data));
    } catch (e) {
        yield put(ReturnActionCreators.getTicketsFailure(e.message));
    }
}

export function* returnSaga() {
    yield takeLatest(returnTypes.RETURN_START, getreturnData);
    yield takeLatest(returnTypes.GET_TICKETS_START, getTickets);
    yield takeLatest(returnTypes.UPDATE_RETURN_START, updateReturn);
}

export default returnSaga;
