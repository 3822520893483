import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { STORE_COUNTRIES } from '../../../utils/constants/variables';
import CustomBtn from '../../common/customBtn';
import { getAccountResource } from '../../login/redux/selectors';
import { NullBelegActionCreators } from '../redux/actions';
import { getNullBelegState } from '../redux/selectors';

interface Props {
    visible: boolean;
    account: any;
    loading: boolean;
    printNullBelegStart: () => void;
}

const PrintNullBelegBtn = (props: Props) => {
    const { account: { storeDetails = {} } = [], loading, visible } = props;
    if (storeDetails.country === STORE_COUNTRIES.at && visible) {
        return (
            <div>
                <CustomBtn
                    classN="btn_1 w-50 red_btn"
                    handleClick={() => props.printNullBelegStart()}
                    loading={loading}
                >
                    {i18next.t('buttons.44')}
                </CustomBtn>
            </div>
        );
    }

    return null;
};

const mapStateToProps = (state: any) => ({
    account: getAccountResource(state),
    loading: getNullBelegState(state).loading,
});

const mapDispatchToProps = (dispatch): typeof NullBelegActionCreators => {
    return bindActionCreators(NullBelegActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintNullBelegBtn);
