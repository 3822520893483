import React from 'react';
import i18next from "i18next";
import {connect} from 'react-redux';
import 'react-day-picker/lib/style.css';
import { bindActionCreators } from "redux";
import Cleave from 'cleave.js/react';
import { ClientActionCreators } from "../../redux/actions";
import { getClients, getClientUpdate, getcreateClientStatus, getSingleClient,getSetClientLoading } from "../../redux/selectors";
import { TextArea, Input, Message, Icon, Tab, Table, Checkbox, Popup } from "semantic-ui-react";
import { getAccountResource } from "../../../login/redux/selectors";
import {
    priceThreeDigitSets,
    getDateTimestampByTime,
    replaceComma,
    getCurrency,
    getDateFormatReq,
    isFloat,
    addDays,
    getMenuItemCanonicalUrl,
    getLocalFormData,
    getCountryOptions,
    getOfflinePay,
    addGiftToBasket,
    getActiveBasketName,
    changeBasketClientId,
    getLocalFormOptions
} from "../../../../helpers/helpers";
import history from '../../../../helpers/history'
import InfoPopUp from "../../../common/infoPopUp";
import AutocompleteDropdown from "../../../common/autocompleteDropdown";
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_SYMBOL, GIFT_DURATION } from '../../../../utils/constants/variables';
import { redeemGiftStart } from '../../../../utils/constants/service';
import { getMenu } from '../../../../layouts/main/header/redux/selectors';
import TableComponent from "../../../datatable/container/datatable";
import CustomBtn from '../../../common/customBtn';
import { HomeActionCreators } from '../../../home/redux/actions';

interface State {
    showPopUp: boolean,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    address1: string,
    address2: string,
    zip: string,
    country:string,
    city: string,
    loading:boolean,
    storeClientId:any,
    comment: string,
    birthdate: any,
    id:string,
    gender:string,
    newsletterOptin: boolean,
    status:string,
    company:string,
    vatNumber: string,
    showInfoPopup: boolean,
    InfoPopupMessage: string,
    fiscalCode: string,
    pecEmail: string,
    sdiCode: string,
    orderDetails: any,
    showConfirmPopup: boolean,
    clientType: string,
    countryUn: string,
    acceptedExistingUser: boolean,
    lotteryCode: string,
    invalidField :  any,
    selectedPaymentTerms: string,
    activeAction: string,
    additionText: string;
    dropdownOptions: Record<string, {
        key: string;
        text: string;
        value: string;
    }[]>
}

interface Props {
    dispatch: any,
    data?: any,
    clientData?: any,
    singleClientData?: any,
    createClientStatus:string,
    setClientLoading:boolean,
    handleClosePopUp: any,
    isNew:boolean,
    storeCode: string,
    menu: any
}

class ClientsNew extends React.Component <State & Props & typeof ClientActionCreators>{
    state: State = {
        loading:false,
        showPopUp: false,
        email: "",
        firstName: "",
        id:"",
        storeClientId:null,
        comment: "",
        lastName: "",
        phone: "",
        address1:"",
        address2:"",
        city: "",
        zip:"",
        country: "",
        birthdate:"",
        status:"",
        company: "",
        vatNumber: "",
        showInfoPopup: false,
        InfoPopupMessage:"",
        fiscalCode: "",
        pecEmail: "",
        sdiCode: "",
        gender:"F",
        newsletterOptin: true,
        orderDetails: [],
        showConfirmPopup: false,
        clientType:"",
        countryUn: "",
        acceptedExistingUser: false,
        lotteryCode:"",
        invalidField:{
            birthdate:false
        },
        selectedPaymentTerms: "",
        activeAction: "",
        dropdownOptions: {
            country: []
        },
        additionText: ""
    };
    inputRef =  React.createRef<HTMLInputElement>();
    focus = ()=> {
        const node = this.inputRef.current
        if(node)node.focus()
    }
    handleClose = () => {
        this.setState({
            email: "",
            firstName: "",
            comment:"",
            lastName: "",
            phone: "",
            address1:"",
            address2:"",
            city: "",
            zip:"",
            country: "",
            birthdate:"",
            company: "",
            vatNumber: "",
            fiscalCode: "",
            pecEmail: "",
            sdiCode: "",
            gender:"F",
            orderDetails: [],
            clientType:"",
            acceptedExistingUser: false,
            lotteryCode:"",
            selectedPaymentTerms: "",
            activeAction: "",
            additionText: ""
        });
    };

    componentDidMount(): void {
        const { data = {}, account = {},isNew } = this.props;
        const { profile = {}, billingAddresses = [] } = data;
        const [billAddress = {}] = billingAddresses;
        const { address1 = "", address2 = "", city = "", zip = "" } = billAddress;
        const countryOptions = getLocalFormOptions('countries', 'name', 'name', 'code');

        let { country = "" } = billAddress;

        const {
            comment = "", email = "", firstName = "", lastName = "", phone = "", clientCode = "", clientId = "",newsletterOptin = true,
            birthdate, company = "", vatNumber = "", fiscalCode = "", pecEmail = "", sdiCode = "", gender = "",clientType="",lotteryCode=""
        } = profile;
        this.setState({
            selectedPaymentTerms: (getOfflinePay() || {}).selectedPaymentTerms,
            activeAction: localStorage.activeAction,
            dropdownOptions: {
                ...this.state.dropdownOptions,
                country: countryOptions
            }
        })

        if(!country) {
            const storeCountry = account.storeDetails && account.storeDetails.country || "";
            const findCountry = countryOptions.find((item) => item.key === storeCountry);
            if(findCountry && findCountry.value){
                country = findCountry.value;
                this.setState({ country });
            }
        }

        if (country && !getCountryOptions().find(item=>item.value.toUpperCase() === country.toUpperCase())){
            this.setState({ countryUn: country })
            country = i18next.t('Countrylist.UN')
        }

        if (this.props.data && this.props.data.profile && !isNew) {
            this.setState({
                comment,
                email,
                firstName,
                lastName,
                phone,
                address1,
                address2,
                city,
                zip,
                country,
                clientAddressId: clientCode,
                storeClientId: clientId,
                birthdate: birthdate ? getDateTimestampByTime(data.profile.birthdate,"dd/mm/yy") : "",
                company,
                vatNumber,
                fiscalCode,
                pecEmail,
                sdiCode,
                gender,
                newsletterOptin,
                clientType,
                lotteryCode,
            })
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (prevProps.createClientStatus !== this.props.createClientStatus) {
            if(this.props.createClientStatus==="success"){
                this.setState({status: "success",showInfoPopup: true,InfoPopupMessage:i18next.t('alertMessage.2') })
            }else if(this.props.createClientStatus==="failed"){
                this.setState({status: "failed",showInfoPopup: true,InfoPopupMessage:i18next.t('alertMessage.1') })
            }
        }

        if(this.props.data && this.props.data.profile && prevProps.data && prevProps.data.profile && this.props.data.profile.clientCode !== prevProps.data.profile.clientCode ){
            const {data,account} = this.props;
            const country = account && account.storeDetails && account.storeDetails.country || "";
            const findCountry = getCountryOptions().filter(item=>item.key.toUpperCase() === country.toUpperCase())
            const {email,firstName,lastName,phone} = this.state;
            this.setState({
                comment:this.state.comment,
                email: data.profile && data.profile.email ? data.profile.email : email,
                firstName: data.profile && data.profile.firstName ? data.profile.firstName : firstName ,
                lastName: data.profile && data.profile.lastName ? data.profile.lastName :lastName,
                phone: data.profile && data.profile.phone ? data.profile.phone : phone,
                storeClientId:null,
                address1:data.billingAddresses && data.billingAddresses[0].address1  ? data.billingAddresses[0].address1 : "",
                address2:data.billingAddresses && data.billingAddresses[0].address2  ? data.billingAddresses[0].address2 : "",
                city:data.billingAddresses && data.billingAddresses[0].city  ? data.billingAddresses[0].city : "",
                zip:data.billingAddresses && data.billingAddresses[0].zip ? data.billingAddresses[0].zip  : "",
                country:data.billingAddresses && data.billingAddresses[0].country ? data.billingAddresses[0].country :findCountry && Object.keys(findCountry).length ? findCountry[0]['text']:"",
                clientAddressId:data.profile && data.profile.clientCode? data.profile.clientCode:"",
                birthdate: data.profile && data.profile.birthdate ? getDateTimestampByTime(data.profile.birthdate,"dd/mm/yy") :"",
                company: data.profile && data.profile.company ? data.profile.company :"",
                vatNumber: data.profile && data.profile.vatNumber ? data.profile.vatNumber :"",
                fiscalCode: data.profile && data.profile.fiscalCode ? data.profile.fiscalCode :"",
                pecEmail: data.profile && data.profile.pecEmail ? data.profile.pecEmail :"",
                sdiCode: data.profile && data.profile.sdiCode ? data.profile.sdiCode :"",
                gender: data.profile && data.profile.gender ? data.profile.gender :"",
                newsletterOptin: data.profile && data.profile.newsletterOptin,
                clientType: data.profile.clientType ? data.profile.clientType :"",
                lotteryCode : data.profile.lotteryCode  ? data.profile.lotteryCode  :"",
            })
        }

        if(this.state.country !== prevState.country &&  this.state.country === i18next.t('Countrylist.UN' )){
            this.focus()
        }
    }

    handleSubmitInfo = () =>{
        const {
            email, firstName, lastName, storeClientId, comment, gender, phone, address1, address2, city, zip, country, newsletterOptin,
            birthdate, company, vatNumber, fiscalCode, pecEmail, sdiCode, clientType, countryUn, acceptedExistingUser, lotteryCode, invalidField
        } = this.state;
        const {singleClient, clientData, storeCode, isNew} = this.props;
        const {billingAddresses = []} = singleClient;
        const clientInfo = firstName && lastName && !invalidField.birthdate && (newsletterOptin ? !!(phone || email) : true);
        const allowEmptyEmailOrAddress = this.state.selectedPaymentTerms === '005' || this.state.activeAction === 'webOrder' ?
            (email && address1 && city && zip && country) : true;
        let isValidCountry = true;
        const isExistingClient =(!storeClientId && clientData.find(item =>
            item.firstName && item.firstName.toLowerCase() === firstName.toLowerCase()
            && item.lastName && item.lastName.toLowerCase() === lastName.toLowerCase())
        );

        const allbody = {
            storeClientId,
            firstName,
            lastName,
            phone,
            email,
            city,
            address2,
            address1,
            zip,
            comment,
            birthdate: getDateFormatReq(birthdate,"dd/mm/yyyy"),
            company,
            vatNumber,
            fiscalCode,
            pecEmail,
            sdiCode,
            storeCode,
            country: country.length ? countryUn || country : this.props.account.storeDetails.country,
            gender,
            newsletterOptin,
            clientAddressId: isNew ? null : billingAddresses && billingAddresses[0] && billingAddresses[0].clientAddressId ? billingAddresses[0].clientAddressId : null,
            clientType,
            lotteryCode,
        };

        if (country === i18next.t('Countrylist.UN' ) && !countryUn) isValidCountry = false;
        if (
            clientInfo && (!isExistingClient || acceptedExistingUser)
            && (storeCode && storeCode.length)
            && isValidCountry && allowEmptyEmailOrAddress
        ) {
            this.props.setClientStart(allbody)
            this.setState({acceptedExistingUser:false})
        } else {
            let InfoPopupMessage;
            let additionText
            if(isExistingClient) {
                InfoPopupMessage = i18next.t('alertMessage.8');
            } else {
                const invalidItemsList = [];

                InfoPopupMessage = `${i18next.t('alertMessage.1')}`;
                additionText = `${i18next.t('Terminal.dashboards.missingItems')}: `;

                !firstName && invalidItemsList.push(i18next.t('Terminal.dashboards.firstName'));
                (
                    newsletterOptin
                    || this.state.selectedPaymentTerms === '005'
                    || this.state.activeAction === 'webOrder'
                ) && !email && invalidItemsList.push(i18next.t('Terminal.dashboards.email'));
                newsletterOptin && !email && !phone && invalidItemsList.push(i18next.t('Terminal.dashboards.phone'));
                additionText += invalidItemsList.join(", ");
            }
            this.setState({
                status: "failed",
                showInfoPopup: true,
                InfoPopupMessage,
                additionText,
                acceptedExistingUser:false
            })
        }
    };

    handleChange = (e) => {
        this.props.resetCreateClientStatusStart()
        this.setState({[e.target.name]: e.target.value});
    };

    clientChoice = () =>{
        const {status} = this.state;
        this.setState({
            showInfoPopup:false
        })
        if(status==="success"){
            this.props.handleClosePopUp()
        }
        this.props.resetCreateClientStatusStart();
    }

    handleDayChange =(e)=> {
        const {value} = e.target,
            deitals = value.split('/')
        if(value.length <10 || (deitals[2] && Number(deitals[2]) < 1900)){
            this.setState(()=>{return {invalidField:{...this.state.invalidField,birthdate:true}}})
        }else {
            this.setState(()=>{return {invalidField:{...this.state.invalidField,birthdate:false}}})
        }
        this.setState({birthdate:value})
    };

    handleAddressError = () => {

        const {address1,city,zip,country} = this.state;
        if(address1 && city && zip && country){
            return false
        }
        if(address1 || city || zip || country ){
            return true
        }
    }

    handleConfirm = () => {
        
        const {data = {}} = this.props;
        const {profile = {}, storeClientLoyalty : {loyaltyValue = 0} = {}} = data

        const body = {
            active: true,
            amount: isFloat(loyaltyValue) ? loyaltyValue.toFixed(2) : loyaltyValue,
            barcode: "",
            storeClientId: profile.clientId,
            credit: false,
            currency: DEFAULT_CURRENCY,
            startDate: Date.now(),
            endDate: addDays(new Date(),GIFT_DURATION).getTime(),
            percent: "",
            qrCode: null,
            redemptionDate: null,
            storeGiftId: null,
        }

        redeemGiftStart(body, profile.clientId)
            .then((data) => {
                this.props.clientStart();
                this.setState({
                    InfoPopupMessage: i18next.t('alertMessage.2'),
                    showConfirmPopup: false,
                    showInfoPopup: true,
                    status: "success"
                });
                addGiftToBasket(data);
                this.props.dispatch(HomeActionCreators.getBasketStart());
                this.props.storeClientStart(data.storeClientId, getActiveBasketName());
                changeBasketClientId(data.storeClientId);
                history.push('/');
            })
            .catch(e => this.setState({ status: "failed", showInfoPopup: true, InfoPopupMessage:i18next.t('alertMessage.1'), showConfirmPopup: false }))
    }

    handleRedirectVentes = ({orderNumber, storeCode, currency}) => () => {
        if(!orderNumber) return
        history.push({
            pathname: getMenuItemCanonicalUrl('page', 'sales', this.props.menu), 
            state: { ticketNumber: orderNumber, storeCode, currency }
        })
        this.props.handleClosePopUp()
    }

    handleRedirectQuotes = ({quoteNumber, storeCode}) => () => {
        if(!quoteNumber) return
        history.push({
            pathname: getMenuItemCanonicalUrl('page', 'quotes', this.props.menu), 
            state: { quoteNumber, storeCode }
        })
        this.props.handleClosePopUp()
    }

    columns: any = [

        {
            name: i18next.t('Terminal.dashboards.endDate'),
            selector: 'endDate',
            sortable: true,
            width: "20%",
            format: 'current',
            cell: row => getDateTimestampByTime(row.endDate)
        },
        {
            name: i18next.t('Terminal.dashboards.storeGiftCode'),
            selector: 'storeGiftCode',
            sortable: true,
            width: "19%",
            center: true,
            searchable: true,
            isUnique: true,
        },
        {
            name: i18next.t('Terminal.dashboards.credit'),
            selector: 'credit',
            width: "10%",
            searchable: true,
            notShow:false,
            cell: row =>
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {
                                row.credit ?
                                    i18next.t('Terminal.dashboards.toHave')
                                    :
                                    i18next.t('Terminal.dashboards.nicePresent')
                            }
                        </div>
                    }
                    content={
                        row.credit ?
                            i18next.t('Terminal.dashboards.toHave')
                            :
                            i18next.t('Terminal.dashboards.nicePresent')
                    }
                    position='bottom center'
                />
        },
        {
            name: i18next.t('Terminal.dashboards.percent'),
            selector: 'percent',
            sortable: true,
            width: "14%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.Price',{DEFAULT_CURRENCY_SYMBOL}),
            selector: 'amount',
            sortable: true,
            width: "14%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.actif'),
            selector: 'redemptionDate',
            width: "8%",
            sortable: true,
            center: true,
            cell: row =>
                <div data-tag="___react-data-table-allow-propagation___" className={`text-center ${row.active ? 'color--green' : 'color--red'}`}>
                    {
                        row.active ?
                            "Y"
                            :
                            "N"
                    }
                </div>,
        },
        {
            name: i18next.t('Terminal.dashboards.valide'),
            selector: 'redemptionDate',
            width: "6%",
            sortable: true,
            center: true,
            cell: row =>
                    <div data-tag="___react-data-table-allow-propagation___" className={`text-center ${row.redemptionDate  ? 'color--red' : 'color--green'}`}>
                        {
                            row.redemptionDate  ?
                                "N"
                                :
                                "Y"
                        }
                    </div>,

        },
        {
            name: i18next.t('Terminal.dashboards.id'),
            selector: 'storeGiftId',
            notShow:true,
            isUnique: true,
            cell: row =><></>,
        },
        {
            selector: 'active',
            notShow:true,
        },
        {
            selector: 'barcode',
            notShow:true,
        },
        {
            selector: 'clientCode',
            notShow:true,
        },
        {
            selector: 'currency',
            notShow:true,
        },
        {
            selector: 'qrCode',
            notShow:true,
        },
        {
            selector: 'startDate',
            notShow:true,
        },
        {
            selector: 'expired',
            notShow:true,
        },
    ];
    panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.profile'),
            render: () =>{
                const { email, firstName, lastName, phone, birthdate, gender, clientType, newsletterOptin, invalidField} = this.state;
                const { clientTypeLabels = [] } = getLocalFormData();
                const { data } = this.props;

                return (
                    <Tab.Pane attached={false}>
                        <div className="parent-for-comment">
                            <div className="popUp--body--card--body set-data-content">
                                <div className="content">
                                    <div className="popUp--body--card--body--between">
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header disabled">
                                            {i18next.t('Terminal.dashboards.id')}
                                        </div>
                                        <Input
                                            className={"popUp--body--card--body--data--description disabled"}
                                            defaultValue={ data && data.profile ? data.profile.clientId:""}
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data ml-1">
                                        <div className="popUp--body--card--body--data--header disabled">
                                            {i18next.t('Terminal.dashboards.web')}
                                        </div>
                                        <Input
                                            className={"popUp--body--card--body--data--description disabled"}
                                            defaultValue={ data && data.profile ? data.profile.webClientId:""}
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header disabled">
                                            {i18next.t('Terminal.dashboards.clientCode')}
                                        </div>
                                        <Input
                                            className={"popUp--body--card--body--data--description disabled"}
                                            defaultValue={ data && data.profile ? data.profile.clientCode:""}
                                            disabled={true}
                                            autoComplete="off"/>
                                    </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.type')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                            <AutocompleteDropdown
                                                data={[{code: null, label: ''}, ...clientTypeLabels]}
                                                search={true}
                                                value={clientType}
                                                classN="popUp--body--card--body--data--description"
                                                keyName="code"
                                                textName={['label']}
                                                valueName="code"
                                                onChange={(val)=>this.setState({clientType: val})}
                                            />
                                        </div>
                                        <div className="popUp--body--card--body--data--header disabled ml-05">
                                            {i18next.t('Terminal.dashboards.storeName')}
                                        </div>
                                        <Input
                                            className={"popUp--body--card--body--data--description blue"}
                                            defaultValue={ data && data.profile ? data.profile.storeShortName : "" }
                                            disabled={true}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.gender.title')}
                                        </div>
                                        <div className='popUp--body--card--body--between'>
                                            <div className="popUp--body--card--body--data--description clienttab ">
                                                <label className="radioLabel">
                                                    {i18next.t('Terminal.dashboards.gender.male')}
                                                    <Input  type="radio"
                                                            value="M" name="gender"
                                                            checked={gender ==="M"}
                                                            onChange={this.handleChange}
                                                            autoComplete="off"/>
                                                </label>
                                                <label className="radioLabel">
                                                    {i18next.t('Terminal.dashboards.gender.female')}
                                                    <Input  type="radio"
                                                            value="F" name="gender"
                                                            checked={gender ==="F"}
                                                            onChange={this.handleChange}
                                                            autoComplete="off"/>
                                                </label>
                                            </div>
                                            <div className="popUp--body--card--body--data--description">
                                                <Checkbox
                                                    label={i18next.t('Terminal.dashboards.newsletter')}
                                                    onChange={(e, { checked }: any) => this.setState({newsletterOptin: checked})}
                                                    checked={newsletterOptin}
                                                    className="radioLabel"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.firstName')}
                                        </div>
                                        <Input  error={!firstName.length}
                                                className={`popUp--body--card--body--data--description  clienttab `}
                                                value={firstName}
                                                name="firstName"
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                        />

                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.lastName')}
                                        </div>
                                        <Input error={!lastName.length} className={"popUp--body--card--body--data--description  clienttab"}
                                               value={lastName} name="lastName"
                                               onChange={this.handleChange}
                                               autoComplete="off"
                                        />
                                    </div>

                                    <div className="dflex">
                                        <div className="popUp--body--card--body--data" style={{width: '67%'}}>
                                            <div className="popUp--body--card--body--data--header w-50">
                                                {i18next.t('Terminal.dashboards.birthday')}
                                            </div>
                                            <div className={`popUp--body--card--body--data--description clienttab w-50 ${invalidField.birthdate ? "error invalid" : ""}`}>
                                                <div className={`day--picker-content ${invalidField.birthdate ? "error invalid" : ""}`}>
                                                    <Cleave placeholder="dd/mm/yyy"
                                                            value={birthdate}
                                                            options={{
                                                                date: true,
                                                                delimiter: '/',
                                                                datePattern: ['d', 'm', 'Y'],
                                                                dateMin: '1900-01-01'
                                                            }}
                                                            onChange={(e)=>this.handleDayChange(e)}
                                                    />
                                                    <Icon className="calendar alternate outline dateIcons"/>
                                                </div>
                                                {
                                                    invalidField.birthdate ?
                                                        <Message size='mini' color='red' className='popUp--body--card--body--data--description--errorMessage'>
                                                            {i18next.t('date.1')}
                                                        </Message>:""
                                                }
                                            </div>
                                        </div>
                                        <div className="popUp--body--card--body--data w-50">
                                            <div className="popUp--body--card--body--data--header disabled w-50">
                                                {i18next.t('date.2')}
                                            </div>
                                            <Input
                                                className={"popUp--body--card--body--data--description w-50"}
                                                defaultValue={ data && data.profile ? getDateTimestampByTime(data.profile.creationDate, "dd/mm/yy") : ""}
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>

                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.email')}
                                        </div>
                                        <Input  
                                            error={
                                                newsletterOptin && !email && !phone ||
                                                this.state.selectedPaymentTerms === '005' && !email ||
                                                this.state.activeAction === 'webOrder' && !email
                                            }
                                            className={"popUp--body--card--body--data--description  email"}
                                            value={email}  name="email"
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.phone')}
                                        </div>
                                        <Input
                                            error={newsletterOptin && !email && !phone} 
                                            className={"popUp--body--card--body--data--description  clienttab"}
                                            value={phone} name="phone"
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="dflex">
                                        <div className="popUp--body--card--body--data">
                                            <div className="popUp--body--card--body--data--header disabled w-50">
                                                {i18next.t('Terminal.dashboards.totalQuantity')}
                                            </div>
                                            <Input
                                                className={"popUp--body--card--body--data--description w-50"}
                                                defaultValue={ data && data.totalQuantity ? data.totalQuantity : "" }
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="popUp--body--card--body--data">
                                            <div className="popUp--body--card--body--data--header disabled w-50">
                                                {i18next.t('Terminal.dashboards.totalSales')}
                                            </div>
                                            <Input
                                                className={"popUp--body--card--body--data--description w-50"}
                                                defaultValue={ data && data.totalSales ? priceThreeDigitSets(data.totalSales) : ""}
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="dflex">
                                        <div className="popUp--body--card--body--data">
                                            <div className="popUp--body--card--body--data--header disabled w-50">
                                                {i18next.t('Terminal.dashboards.loyalty_points')}
                                            </div>
                                            <Input
                                                className={"popUp--body--card--body--data--description w-50"}
                                                defaultValue={ data && data.storeClientLoyalty ? data.storeClientLoyalty.loyaltyPoints:""}
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="popUp--body--card--body--data">
                                            <div className="popUp--body--card--body--data--header disabled w-50">
                                                {i18next.t('Terminal.dashboards.end_loyalty_date')}
                                            </div>
                                            <Input
                                                className={"popUp--body--card--body--data--description w-50"}
                                                defaultValue={  data && data.storeClientLoyalty ? getDateTimestampByTime(data.storeClientLoyalty.expirationDate):""}
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>

                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header  disabled">
                                            {i18next.t('Terminal.dashboards.cycle')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description blue dflex"}>
                                            <Input
                                                className={"popUp--body--card--body--data--description blue"}
                                                defaultValue={
                                                    data && data.storeClientLoyalty && data.storeClientLoyalty.loyaltyCycle
                                                }
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                        </div>
                                        <div className="popUp--body--card--body--data--header  disabled">
                                            {i18next.t('Terminal.dashboards.loyalty_value')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description blue dflex"}>
                                            <Input
                                                className={"popUp--body--card--body--data--description blue"}
                                                defaultValue={data && data.storeClientLoyalty ? replaceComma(data.storeClientLoyalty.loyaltyValue) + getCurrency():""  }
                                                disabled={true}
                                                autoComplete="off"
                                            />
                                            {
                                                data && data.storeClientLoyalty && data.storeClientLoyalty.loyaltyValue ?
                                                <button 
                                                    type='button' 
                                                    className="btn_1 red_btn withoutHover"
                                                    onClick={() => this.setState({showConfirmPopup: true})}
                                                >
                                                    {i18next.t('Terminal.dashboards.redeem')}
                                                </button> : ""
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                )
            }
        },
        {
            menuItem: i18next.t('Terminal.dashboards.storeAddress'),
            render: () =>{
                const { address1, address2, city, zip, country, countryUn, comment, dropdownOptions: { country: countryOptions } } = this.state;
                return (
                    <Tab.Pane attached={false}>
                        <div className="parent-for-comment">
                            <div className="popUp--body--card--body set-data-content">
                                <div className="content">
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.address1')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                            <Input
                                                value={address1} name="address1"
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                            {
                                                this.handleAddressError() && !address1 ?
                                                    <Message size='mini' color='red' className='popUp--body--card--body--data--description--errorMessage'>
                                                        {i18next.t('alertMessage.7')}
                                                    </Message>:""
                                            }
                                        </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.address2')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                            <Input className={"popUp--body--card--body--data--description  clienttab"}
                                                   value={address2} name="address2" onChange={this.handleChange}
                                                   autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.zip')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                            <Input
                                                value={zip} name="zip"
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                            {
                                                this.handleAddressError() && !zip ?
                                                    <Message size='mini' color='red' className='popUp--body--card--body--data--description--errorMessage'>
                                                        {i18next.t('alertMessage.7')}
                                                    </Message>:""
                                            }
                                        </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.city')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                            <Input
                                                value={city} name="city"
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                            {
                                                this.handleAddressError() && !city ?
                                                    <Message size='mini' color='red' className='popUp--body--card--body--data--description--errorMessage'>
                                                        {i18next.t('alertMessage.7')}
                                                    </Message>:""
                                            }
                                        </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.country')}
                                        </div>
                                        <div className={"popUp--body--card--body--data--description  clienttab input"}>
                                            <AutocompleteDropdown
                                                data={countryOptions}
                                                search={false}
                                                value={country}
                                                classN="popUp--body--card--body--data--description"
                                                keyName="key"
                                                textName={['text']}
                                                valueName="text"
                                                onChange={(val)=>this.setState({country:val, countryUn: ""})}
                                            />
                                            {
                                                this.handleAddressError() && !country ?
                                                    <Message size='mini' color='red' className='popUp--body--card--body--data--description--errorMessage'>
                                                        {i18next.t('alertMessage.7')}
                                                    </Message>:""   
                                            }
                                            {
                                                country === i18next.t('Countrylist.UN') &&
                                                <>
                                                    <input
                                                        className={`popUp--body--card--body--data--description  textBox ${ !countryUn ? "error" :""}`}
                                                        ref={this.inputRef }
                                                        value={countryUn} name="countryUn"
                                                        onChange={this.handleChange}
                                                        autoComplete="off"
                                                    />
                                                    {
                                                        !countryUn ?
                                                            <Message size='mini' color='red' className='popUp--body--card--body--data--description--errorMessage'>
                                                                {i18next.t('alertMessage.7')}
                                                            </Message>:""
                                                    }
                                                 </>
                                            }
                                        </div>
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header ">
                                            {i18next.t('Terminal.Receipt.Deliveries.comment')}
                                        </div>
                                        <TextArea  className="popUp--body--card--body--data--description textarea"
                                                  style={{ minHeight: 100 }}
                                                  name="comment"
                                                  value={comment}
                                                  onChange={this.handleChange}
                                                  autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                )
            }
        },
        {
            menuItem: i18next.t('Terminal.dashboards.facturation'),
            render: () =>{
                const {company,vatNumber,fiscalCode,pecEmail,sdiCode,lotteryCode } = this.state;
                return (
                    <Tab.Pane attached={false}>
                        <div className="parent-for-comment">
                            <div className="popUp--body--card--body set-data-content">
                                <div className="content">
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.company')}
                                        </div>
                                        <Input className={"popUp--body--card--body--data--description  clienttab"}
                                               value={company} name="company" onChange={this.handleChange}
                                               autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.vatNumber')}
                                        </div>
                                        <Input 
                                            className={"popUp--body--card--body--data--description  clienttab"}
                                            value={vatNumber}
                                            disabled={!!fiscalCode}
                                            name="vatNumber" 
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.fiscalCode')}
                                        </div>
                                        <Input 
                                            className={"popUp--body--card--body--data--description  clienttab"}
                                            value={fiscalCode}
                                            disabled={!!vatNumber}
                                            name="fiscalCode"
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.pecEmail')}
                                        </div>
                                        <Input
                                            className={"popUp--body--card--body--data--description  clienttab"}
                                            value={pecEmail}
                                            disabled={!!sdiCode}
                                            name="pecEmail"
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.sdiCode')}
                                        </div>
                                        <Input
                                            className={"popUp--body--card--body--data--description  clienttab"}
                                            value={sdiCode}
                                            disabled={!!pecEmail}
                                            name="sdiCode"
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="popUp--body--card--body--data">
                                        <div className="popUp--body--card--body--data--header">
                                            {i18next.t('Terminal.dashboards.lotteryCode')}
                                        </div>
                                        <Input className={"popUp--body--card--body--data--description  clienttab"}
                                               value={lotteryCode } name="lotteryCode" onChange={this.handleChange}
                                               autoComplete="off"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                )
            }
        },
        !this.props.isNew  ?
            {
                menuItem: i18next.t('Terminal.dashboards.ventes'),
                render: () => <Tab.Pane attached={false}>
                {
                    this.props.data.clientOrders && this.props.data.clientOrders.length ?
                        <Table celled selectable className="productInfoBlock--infoBox--table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.storeName')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.ticketNumber')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.date')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.sale')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.quantity')}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.data.clientOrders.map(item => {
                                    return (
                                        <Table.Row key={item.orderNumber}
                                                   onClick={() => this.setState({orderDetails: item.clientOrderDetails})}>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description">
                                                {item.storeShortName}
                                            </Table.Cell>           
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description cursor"
                                                onClick={this.handleRedirectVentes(item)}
                                            >
                                                {item.orderNumber}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description">
                                                {getDateTimestampByTime(item.orderDate, "dd/mm/yy")}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description">
                                                {priceThreeDigitSets(item.sales,item.currency)}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description">
                                                {item.quantity}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                                }
                            </Table.Body>
                        </Table> : ""
                }
                {this.state.orderDetails && this.state.orderDetails.length ?
                    <div>
                        <h1>{i18next.t('Terminal.dashboards.orders')} </h1>
                        <Table celled selectable className="productInfoBlock--infoBox--table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Category')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.product')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.Size')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.sale')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.quantity')}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.orderDetails.map((item, i) => (

                                        <Table.Row key={i}>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.category}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.productName }
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.size}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {priceThreeDigitSets(item.sales,item.currency)}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {item.quantity}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </div> : ""
                }
            </Tab.Pane>,
            }:{},
        !this.props.isNew  ?
            {
                menuItem: i18next.t('Terminal.dashboards.2'),
                render: () => <Tab.Pane attached={false}>
            {
                this.props.data.clientOrders && this.props.data.clientOrders.length ?
                    <Table celled selectable className="productInfoBlock--infoBox--table">
                        <Table.Header>
                            <Table.Row>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t('Terminal.dashboards.date')}
                                </Table.Cell>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t('Terminal.dashboards.Category')}
                                </Table.Cell>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t('Terminal.dashboards.Name')}
                                </Table.Cell>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t('Terminal.dashboards.Size')}
                                </Table.Cell>
                                <Table.Cell className="productInfoBlock--infoBox--table--title">
                                    {i18next.t('Terminal.dashboards.quantity')}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.data.clientOrders.map(item => {
                                return item.clientOrderDetails && item.clientOrderDetails.map((elem, i)=> {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {getDateTimestampByTime(elem.orderDate, "dd/mm/yy")}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {elem.category}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {elem.productName}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {elem.size}
                                            </Table.Cell>
                                            <Table.Cell className="productInfoBlock--infoBox--table--description">
                                                {elem.quantity}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            })
                            }
                        </Table.Body>
                    </Table> : ""
            }
        </Tab.Pane>,
            } :{},
        !this.props.isNew  ?
            {
                menuItem:  i18next.t('Terminal.Receipt.zReport.storeGiftAmountTotal'),
                render: () => <Tab.Pane attached={false}>
                    <TableComponent
                        tableData={this.props.singleClient.storeGifts || []}
                        openPopUp={()=>{}}
                        buttons={['clientGift']}
                        columns={this.columns}
                        reloadDashboard={() => {}}
                        keyField={"bonsCadeaux"}
                        hideSearch={true}
                    />
                </Tab.Pane>,
            } :{},
        !this.props.isNew  ?
            {
                menuItem: i18next.t('Terminal.dashboards.quote'),
                render: () => <Tab.Pane attached={false}>
                {
                    this.props.data.storeQuotes && this.props.data.storeQuotes.length ?
                        <Table celled selectable className="productInfoBlock--infoBox--table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.storeName')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.quote')}
                                    </Table.Cell>
                                    <Table.Cell className="productInfoBlock--infoBox--table--title">
                                        {i18next.t('Terminal.dashboards.date')}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.data.storeQuotes.map(item => {
                                    return (
                                        <Table.Row 
                                            key={item.quoteNumber}
                                            className="cursor"
                                            onClick={this.handleRedirectQuotes(item)}
                                        >
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description">
                                                {item.storeShortName}
                                            </Table.Cell>           
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description"
                                            >
                                                {item.quoteNumber}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="productInfoBlock--infoBox--table--description">
                                                {getDateTimestampByTime(item.quoteDate, "dd/mm/yy")}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                                }
                            </Table.Body>
                        </Table> : ""
                }
                </Tab.Pane>
            } : {}
    ];

    render(){
        const { status, showInfoPopup, InfoPopupMessage, showConfirmPopup, acceptedExistingUser, additionText } = this.state;
        const {setClientLoading} = this.props;
        
        return (
            <div className="parent-for-comment">
                <div className="popUp--body--card--body set-data-content">
                    <Tab menu={{pointing: true}} panes={this.panes} />
                    <div className="extra content">
                        <div className="ui two buttons">
                            <CustomBtn
                                classN="btn_1 w-100 blue_btn withoutHover"
                                handleClick={this.handleSubmitInfo}
                                loading={setClientLoading}
                            >
                                {i18next.t('Terminal.dashboards.edit')}
                            </CustomBtn>
                            <div 
                                className="btn_1 w-100" 
                                onClick={this.props.handleClosePopUp}
                            >
                                {i18next.t('buttons.40')}
                            </div>
                        </div>
                    </div>
                    {
                        status === "success" ?
                            <InfoPopUp
                                open={showInfoPopup}
                                message={InfoPopupMessage}
                                classN={"successMessage" }
                                handleClose={()=> {this.clientChoice()}}
                            />
                            : status==="failed" && !acceptedExistingUser ?
                            <ConfirmPopup
                                classN={"errorMessage" }
                                handleCancel={()=> {this.setState({status:"", additionText: ""})}}
                                handleConfirm={() => {this.setState({additionText: "", acceptedExistingUser:true},()=>this.handleSubmitInfo())}}
                                text={InfoPopupMessage}
                                additionText={additionText}
                            />:""
                    }
                    {showConfirmPopup && (
                        <ConfirmPopup
                            handleCancel={() => this.setState({showConfirmPopup: false})}
                            handleConfirm={this.handleConfirm}
                            text={i18next.t('Terminal.modal.2')}
                        />
                    )}
                </div>

            </div>
        )
    }

}
const mapStateToProps = (state: any): Props & typeof ClientActionCreators => ({
    account: getAccountResource(state),
    clientData: getClients(state),
    clientUpdate:getClientUpdate(state),
    singleClient:getSingleClient(state),
    createClientStatus:getcreateClientStatus(state),
    setClientLoading : getSetClientLoading(state),
    menu: getMenu(state),
});

const mapDispatchToProps = (dispatch): typeof ClientActionCreators => {
    return bindActionCreators({...ClientActionCreators, dispatch}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientsNew);
