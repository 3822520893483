import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {getDeliveries, getLoading} from "../redux/selectors";
import {DeliveriesActionCreators} from "../redux/actions";
import TableComponent from "../../datatable/container/datatable"
import PopUp from "../../popUp/popUp";
import CreateDeliveries from "./components/createDeliveries";
import './deliveries.scss';
import { getItemArrayOfObject, getLocalFormData } from '../../../helpers/helpers';
interface Props {
    deliveries: any,
    isLoading: boolean,
}

interface State {
    isShowPopUp: boolean,
    singleDeliveries: object,
    logisticStatusLabels: any
}

class Deliveries extends Component<Props & typeof DeliveriesActionCreators> {
    state: State = {
        isShowPopUp: false,
        singleDeliveries: {},
        logisticStatusLabels: []
    };

    columns: any = [
        {
            name: i18next.t('Terminal.Receipt.Deliveries.slipDate'),
            selector: 'slipDate',
            sortable: true,
            format: 'dd/mm/yy',
            width: "17%"
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.slipNumber'),
            selector: 'slipNumber',
            sortable: true,
            searchable: true,
            width: "18%",
            center: true,
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.qtyDelivered'),
            selector: 'totalQtyDelivered',
            sortable: true,
            width: "14%",
            center: true,
        },
        {
            name: i18next.t('Terminal.Receipt.Deliveries.qtyChecked'),
            selector: 'totalQtyChecked',
            sortable: true,
            width: "14%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.totalBoxQuantity'),
            selector: 'totalBoxQuantity',
            sortable: true,
            width: "11%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.totalBoxChecked'),
            selector: 'totalBoxChecked',
            sortable: true,
            width: "11%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.status'),
            selector: 'logisticStatus',
            sortable: true,
            width: "15%",
            center: true,
            cell: row => <span>{getItemArrayOfObject(this.state.logisticStatusLabels, 'code',  row.logisticStatus).label}</span>
        },
        {
            name: "storeDeliveryId",
            selector: 'storeDeliveryId',
            notShow:true,
            isUnique: true,
            cell: row =><></>,
        },

        {
            selector: 'logisticStatus',
            notShow:true,
        },
    ];

    conditionalRowStyles: any[] = [
       
        {
            when: row => row.logisticStatus === "PRO",
            style: {
                backgroundColor: "orange",
            },
            
        },
        {
            when: row => row.logisticStatus === "COM",
            style: {
                backgroundColor: "#2196F3",
            },
            
        },
        {
            when: row => row.logisticStatus === "VAL",
            style: {
                backgroundColor: "green",
            },
            
        },
    ];

    componentDidMount() {
        const {logisticStatusLabels = []} = getLocalFormData()
        this.setState({logisticStatusLabels})
        this.props.deliveriesStart();
    }
    openPopUp = (e) => {
        const singleDeliveries = this.props.deliveries.find(item => item.storeDeliveryId === e.storeDeliveryId) || {}
        this.setState({isShowPopUp: true, singleDeliveries});
    }
    handleClosePopUp = () => {
        this.setState({isShowPopUp: false});
    }

    updateDeliveryStatus = (logisticStatus: string) => {
        this.setState({ singleDeliveries: {
            ...this.state.singleDeliveries,
            logisticStatus
        }})
    }

    render() {
        const {deliveries, isLoading} = this.props;
        const {isShowPopUp, singleDeliveries} = this.state;

        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={deliveries}
                        isLoading={isLoading}
                        openPopUp={this.openPopUp}
                        columns={this.columns}
                        buttons={["newDeliveries"]}
                        reloadDashboard={this.props.deliveriesStart}
                        conditionalRowStyles={this.conditionalRowStyles}
                        keyField={"dileveries"}
                    />
                </div>
                {isShowPopUp ? <PopUp handleClose={this.handleClosePopUp} classN="deliveriePopUp">
                    <CreateDeliveries updateDeliveryStatus={this.updateDeliveryStatus} data={singleDeliveries} handleClosePopUp={this.handleClosePopUp}/>
                </PopUp> : ""}

            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    deliveries: getDeliveries(state),
    isLoading: getLoading(state)
});

const mapDispatchToProps = (dispatch): typeof DeliveriesActionCreators => {
    return bindActionCreators(DeliveriesActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Deliveries);
