import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import i18next from "i18next";
import Loading from "../../common/loading/loading";
import {getMenu} from "../../products/redux/selectors";
import {getProduct, getLoading} from "../redux/selectors";
import {ProductActionCreators} from "../redux/actions";
import {IProductState} from "../redux/reducers";
import { getLangPrefix} from "../../../helpers/helpers"

import ProductS from "./components/productS";
import ProductC from "./components/productC";

import BasketPopup from "../../basketPopup/container/basketPopup";
import {MenuActionCreators} from "../../../layouts/main/header/redux/actions";
import {Link} from "react-router-dom";
import {getSelectedSizeProduct, getWebInventory} from "../../home/redux/selectors";
import DeliveryBasketPopUp from "../../deliveryBasketPopUp/container/deliveryBasketPopUp";
import {getAccountResource} from "../../login/redux/selectors";
import { HomeActionCreators } from "../../home/redux/actions";

interface Props extends IProductState {
    match?: any,
    menuList: any,
    product: any,
    menuItem?: any,
    webInventory:number,
    account?:any,
    selectedSize?: any,
}

interface State {
    showBasket: boolean,
    showDelivery: boolean,
    product: any
}

class Product extends React.Component<Props & typeof MenuActionCreators & typeof ProductActionCreators & typeof HomeActionCreators, State> {
    state: State = {
        showBasket: false,
        showDelivery: false,
        product: {}
    };

    handleBasketToggle = () => {
        this.setState({showBasket: !this.state.showBasket})
    };

    handleDeliverytToggle = () => {
        this.setState({showDelivery: !this.state.showDelivery})
    };

    componentDidMount() {
        const {canonicalUrl,location,history,productStart} = this.props
        window.scrollTo(0, 0);
        canonicalUrl && location.pathname !== canonicalUrl ? history.push(canonicalUrl) : "";
        const {match: {params: {productId = ""}}} = this.props;
        productStart(productId);
    };

    componentDidUpdate(prevProps, prevState) {
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.product !== this.props.product) {
            this.setState({product: nextProps.product})
        }

        if (nextProps.product && this.props.product && nextProps.product.productId !== this.props.product.productId) {
            const {match: {params: {productId = ""}}} = nextProps;
          this.props.menuItem.id, productId, nextProps.product.productId
        }
        if (nextProps.location !== this.props.location) {
            const {match: {params: {productId = ""}}} = nextProps;
            this.props.productStart(productId);
        }
    };

    handleGetWebStock =(size) => {
        if(size && size.productReference){
            this.props.getBarcodeProductStart({ barcode: size.productReference,webStock:true });
        }

    }

    render() {

        const {showBasket, product,showDelivery} = this.state;
        const {menuItem, loading,webInventory,account} = this.props;
        return (
            Object.entries(product).length ?
                <div className="product">
                    {product.productType == "S" &&
                    <ProductS handleBasketToggle={this.handleBasketToggle}
                              handleDeliverytToggle={this.handleDeliverytToggle}
                              data={product}
                              handleGetWebStock={this.handleGetWebStock}
                              webInventory={webInventory}
                              account={account}
                              selectedSize={this.props.selectedSize}
                    />
                    }

                    {product.productType == "C" &&
                    <ProductC handleBasketToggle={this.handleBasketToggle}
                              handleDeliverytToggle={this.handleDeliverytToggle}
                              data={product} menuItem={menuItem}
                              handleGetWebStock={this.handleGetWebStock}
                              webInventory={webInventory}
                    />
                    }
                    {showBasket &&
                    <BasketPopup handleBasketToggle={this.handleBasketToggle}/>
                    }
                    {showDelivery &&
                    <DeliveryBasketPopUp handleBasketToggle={this.handleDeliverytToggle}/>
                    }

                </div>
                : loading ? <Loading/> :
                (<div className="errorBox">
                    <h1 className="errorBox--Message">{i18next.t("Error.5")}</h1>
                    {this.props.history.location.state ? <Link className="errorBox--Btn main-btn"
                                                               to={`${getLangPrefix()}${this.props.history.location.state.backLink}`}  >{i18next.t("Error.6")}</Link> : " "}
                </div>)
        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        product: getProduct(state),
        loading: getLoading(state),
        menuList: getMenu(state),
        webInventory: getWebInventory(state),
        account: getAccountResource(state),
        selectedSize: getSelectedSizeProduct(state),
    };
};

const mapDispatchToProps = (dispatch): typeof ProductActionCreators & typeof HomeActionCreators => {
    return bindActionCreators({...ProductActionCreators, ...MenuActionCreators, ...HomeActionCreators}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

