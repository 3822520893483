import React, { Component } from 'react';
import BarcodeReader from 'react-barcode-reader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Popup } from 'semantic-ui-react';
import {
    getStock,
    getLoading,
    getIsStockDashboard,
} from './../redux/selectors';
import { StockActionCreators } from './../redux/actions';
import TableComponent from '../../datatable/container/datatable';
import InfoPopUp from '../../common/infoPopUp';
import StocksDetails from './components/stockDetails';
import './stocks.scss';
import SearchBox from '../../common/searchBox';

interface Props {
    stockData?: any;
    isLoading: boolean;
    isStocksDashboard: boolean;
}

interface State {
    stocksPopUp: boolean;
    filteredProduct: any;
    storeData: any;
    activeStoreID: number;
    activeStoreSize: string;
    isDoubleClick: boolean;
    showInfo: boolean;
}

class Stocks extends Component<Props & typeof StockActionCreators> {
    state: State = {
        stocksPopUp: false,
        filteredProduct: [],
        storeData: [],
        activeStoreID: NaN,
        activeStoreSize: '',
        isDoubleClick: false,
        showInfo: false,
    };

    columns = [
        {
            name: i18next.t('Terminal.dashboards.Brand'),
            selector: 'brand',
            sortable: true,
            searchable: true,
            width: '17%',
            cell: (row) => row.collection || row.brand,
        },
        {
            name: i18next.t('Terminal.dashboards.Name'),
            selector: 'productName',
            sortable: true,
            searchable: true,
            width: '21%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.productName}
                        </div>
                    }
                    content={row.productName}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.Category'),
            selector: 'category',
            sortable: true,
            searchable: true,
            width: '17%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.category}
                        </div>
                    }
                    content={row.category}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.model'),
            selector: 'model',
            sortable: true,
            searchable: true,
            width: '13%',
            cell: (row) => (
                <Popup
                    inverted
                    trigger={
                        <div data-tag="___react-data-table-allow-propagation___">
                            {row.model}
                        </div>
                    }
                    content={row.model}
                    position="bottom center"
                />
            ),
        },
        {
            name: i18next.t('Terminal.dashboards.color'),
            selector: 'color',
            sortable: true,
            searchable: true,
            center: true,
            width: '13%',
        },
        {
            name: i18next.t('Terminal.dashboards.Size'),
            selector: 'size',
            sortable: true,
            searchable: true,
            center: true,
            width: '11%',
        },
        {
            name: i18next.t('Terminal.dashboards.promotion'),
            selector: 'promotion',
            sortable: true,
            center: true,
            cell: (row) => (
                <div
                    className={`${
                        row.promotion === 'Y' ? 'color--green' : 'color--red'
                    }`}
                >
                    {row.promotion}
                </div>
            ),
            width: '8%',
        },
        {
            selector: 'productId',
            notShow: true,
            isUnique: true,
        },
        {
            selector: 'collection',
            notShow: true,
        },
    ];

    componentDidMount() {
        this.props.stockStart();
    }

    handleClosePopUp = () => {
        this.setState({ stocksPopUp: false, storeData: [] });
        this.props.getProductDeliveryFailure("");
    };

    handleStockDetials = (e) => {
        this.props.singleStockStart(e.productId);
        this.props.productSoldStart(e.productId);
        this.setState({ stocksPopUp: true });
    };

    handleScan = (barcode) => {
        if (this.props.isStocksDashboard) return;
        const find = this.props.stockData.find(
            (item) => !!item.barcodeList.find((i) => i.barcode === barcode),
        );
        if (find && find.productId) {
            this.handleStockDetials({ productId: find.productId });
        } else {
            this.setState({ showInfo: true });
        }
    };

    render() {
        const { stockData, isLoading } = this.props;
        const { stocksPopUp } = this.state;

        const filteredStockData = (stockData && stockData.length && stockData.filter((item) => item.service === false)) || [];

        return (
            <div className="container">
                <BarcodeReader onScan={this.handleScan} />
                <div className="stockContent">
                    <TableComponent
                        tableData={filteredStockData}
                        isLoading={isLoading}
                        openPopUp={this.handleStockDetials}
                        columns={this.columns}
                        reloadDashboard={this.props.stockStart}
                        buttons={['stockButtons']}
                        keyField={'stocks'}
                    >
                        <SearchBox
                            onSubmit={this.handleScan}
                            label={`${i18next.t(
                                'Terminal.dashboards.barcode',
                            )}:`}
                        />
                    </TableComponent>
                </div>

                {stocksPopUp && (
                    <StocksDetails handleClosePopUp={this.handleClosePopUp} />
                )}

                <InfoPopUp
                    open={this.state.showInfo}
                    classN="message-error"
                    message={i18next.t('Terminal.unknown.barcode')}
                    handleClose={() => this.setState({ showInfo: false })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    stockData: getStock(state),
    isLoading: getLoading(state),
    isStocksDashboard: getIsStockDashboard(state),
});

const mapDispatchToProps = (dispatch): typeof StockActionCreators => {
    return bindActionCreators(StockActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);
