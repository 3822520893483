import {createActions} from 'reduxsauce';

export const {Types: MenuTypes, Creators: MenuActionCreators} = createActions ({
    menuStart: ['callback'],
    menuSuccess: ['data'],
    menuFailure: ['error'],
    clientLoginStart: ['user'],
    logoutStart: ['param'],
    searchingStart: ['data'],
    clickPopUpStart: [],
    userInfoStart: [null],
    languageStart: ["lang"],
    clientLogoutStart: [null],
    accountResourceStart: [null],
    activeMenuStart: ['key'],
    setBreadcrumbStart: ["data"],
    clientCounterStart:["count"],
    getDeliveryBasketStart: ['name'],
    getBasketStart: ['name'],
    getCurrentDaySaleStart: null,
    notificationCountersStart: null,
    notificationCountersSuccess: ['data'],
    notificationCountersFailure: ['error'],
    networkDetectorStart: ['status'],
    fiscalTssDetailsStart: null,
    fiscalTssDetailsSuccess: ["data"],
    fiscalTssDetailsFailure: ['error'],
    storesFormStart: [null],
    clockStart: ["data"],
});
