import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import Menu from './menu/container/menu';
import CurrentPage from '../../../common/currentPage';
import ClientLogin from '../../../../modules/login/container/clientLogin';
import TerminalInfo from '../../../../modules/common/terminalInfo';
import XReportSidePane from '../../../../modules/xReportSidePanel';
import { getHome } from '../../../../modules/home/redux/selectors';
import { getSelectedMenuId } from '../../../../modules/products/redux/selectors';
import {
    getAccountResource,
    getClientLoginError,
    isAuthenticated,
    isPrivateAuthenticated,
} from '../../../../modules/login/redux/selectors';
import {
    getBreadcrumb,
    getClockDate,
    getConnectedStatus,
    getnotificationCounters,
} from '../redux/selectors';
import { MenuActionCreators } from '../redux/actions';
import { ReportsActionCreators } from "./../../../../modules/reports/redux/actions";
import { getPaymentsReportData } from '../../../../modules/reports/redux/selectors';
import { getCurrentDaySale } from '../../../../modules/payment/redux/selectors';
import { getLangPrefix, replaceComma } from '../../../../helpers/helpers';
import history from './../../../../helpers/history';
import {
    STORE_COUNTRIES,
    STORE_COUNTRY,
} from '../../../../utils/constants/variables';
import './header.scss';

interface Props {
    account: object;
    authenticated: boolean;
    isPrivateAuthenticated: boolean;
    breadcrumb: any;
    currentDaySale: any;
    notificationCounters: object;
    isDisconnected: boolean;
    clientLoginStatus: string;
    paymentsReportData: any;
    clockDate: boolean;
}

interface State {
    showPopUp: boolean;
}

class Header extends React.Component<Props & typeof MenuActionCreators, State> {
    state: State = {
        showPopUp: false,
    };
    componentDidMount() {
        this.props.accountResourceStart();

        window.addEventListener('online', () =>
            this.props.networkDetectorStart(false),
        );
        window.addEventListener('offline', () =>
            this.props.networkDetectorStart(true),
        );
    }

    componentWillUnmount(): void {
        window.removeEventListener('online', () =>
            this.props.networkDetectorStart(false),
        );
        window.removeEventListener('offline', () =>
            this.props.networkDetectorStart(false),
        );
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.authenticated &&
            prevProps.authenticated !== this.props.authenticated
        ) {
            this.props.getCurrentDaySaleStart();
            this.props.notificationCountersStart();
            if (
                [STORE_COUNTRIES.de, STORE_COUNTRIES.at].includes(STORE_COUNTRY)
            ) {
                this.props.fiscalTssDetailsStart();
            }
        }

        if (!prevProps.isDisconnected && this.props.isDisconnected) {
            history.push('/');
        }

        if (prevProps.isDisconnected && !this.props.isDisconnected) {
            try {
                console.clear();
            } catch (e) {
                console.log(e);
            }
        }
    }

    handleShowPopUp = () => {
        this.props.storesFormStart();
        this.setState((prev) => ({ showPopUp: !prev.showPopUp }));
    };

    render(): React.ReactNode {
        const {
            account = {},
            authenticated,
            breadcrumb,
            currentDaySale,
            clientLoginStatus,
            clockDate,
            isDisconnected
        } = this.props;
        const {
            storeDetails: { country = '' } = {},
            currencySymbol,
            terminalCode,
        } = account;

        const showTerminalInfo =
            authenticated &&
            (history.location.pathname === getLangPrefix() ||
                history.location.pathname === '/');
        const {
            previousYearPaymentNetTotalPercentag,
            paymentNetTotal,
            saleQuantityTotal,
        } = currentDaySale;
        if (!terminalCode) {
            return <></>;
        }
        return (
            <header>
                <Menu {...this.props} />
                <div className="page-sub-menu-section">
                    <div className="page-sub-menu-section--content container">
                        <CurrentPage selectedItem={breadcrumb} />
                        {(history.location.pathname === getLangPrefix() ||
                            history.location.pathname === '/') &&
                        authenticated ? (
                            <ul className="terminal-info cursor">
                                {isDisconnected ? (
                                    <li className='message-error'>
                                        {i18next.t('Terminal.dashboards.noInternetConnection')}
                                    </li>
                                ) : ![
                                    STORE_COUNTRIES.de,
                                    STORE_COUNTRIES.at,
                                ].includes(country) ? (
                                    <li onClick={this.handleShowPopUp}>
                                        {paymentNetTotal
                                            ? ` ${replaceComma(
                                                  paymentNetTotal,
                                              )}  ${currencySymbol}  (  `
                                            : ' 0 ' + currencySymbol + ' ( '}
                                        <span
                                            className={` ${
                                                previousYearPaymentNetTotalPercentag >=
                                                0
                                                    ? 'color_lightGreen'
                                                    : isNaN(
                                                          previousYearPaymentNetTotalPercentag,
                                                      )
                                                    ? ''
                                                    : 'color_lightReg'
                                            } `}
                                        >
                                            {previousYearPaymentNetTotalPercentag >
                                            0
                                                ? ` + `
                                                : isNaN(
                                                      previousYearPaymentNetTotalPercentag,
                                                  )
                                                ? `  `
                                                : `  `}
                                            {previousYearPaymentNetTotalPercentag
                                                ? `${Math.round(
                                                      previousYearPaymentNetTotalPercentag,
                                                  )}   %`
                                                : ` 0  %`}
                                        </span>
                                        {` )  |  ${
                                            saleQuantityTotal
                                                ? saleQuantityTotal
                                                : ' 0 '
                                        }  ${i18next.t('product.basket.12')} `}
                                    </li>
                                ) : (
                                    <li onClick={this.handleShowPopUp}>
                                        {i18next.t('product.basket.29')}
                                    </li>
                                )}
                            </ul>
                        ) : (
                            ''
                        )}

                        <ul className="terminal-info">
                            <TerminalInfo
                                account={account}
                                clientLogout={this.props.clientLogoutStart}
                                clientLogin={this.props.clientLoginStart}
                                clientLoginStatus={clientLoginStatus}
                                showTerminalInfo={showTerminalInfo}
                                authenticated={authenticated}
                                clock={clockDate}
                                clockStart={this.props.clockStart}
                            />
                            <li>
                                <ClientLogin />
                            </li>
                        </ul>
                    </div>
                </div>
                {this.state.showPopUp && (
                    <XReportSidePane
                        handleClose={this.handleShowPopUp}
                        details={this.props.account}
                        paymentsReportData={this.props.paymentsReportData}
                        getPaymentReports={this.props.paymentsReportStart}
                    />
                )}
            </header>
        );
    }
}

const mapStateToProps = (state: any) => ({
    data: getHome(state),
    selectedMenuId: getSelectedMenuId(state),
    account: getAccountResource(state),
    breadcrumb: getBreadcrumb(state),
    authenticated: isAuthenticated(state),
    isPrivateAuthenticated: isPrivateAuthenticated(state),
    currentDaySale: getCurrentDaySale(state),
    notificationCounters: getnotificationCounters(state),
    isDisconnected: getConnectedStatus(state),
    clientLoginStatus: getClientLoginError(state),
    paymentsReportData: getPaymentsReportData(state),
    clockDate: getClockDate(state),
});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators & typeof ReportsActionCreators => {
    return bindActionCreators(Object.assign({}, ReportsActionCreators, MenuActionCreators), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
