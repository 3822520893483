import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import i18next from "i18next";
import {getCreatedStatus, getInventory, getLoading, getSingleInventory, getSingleLoading} from "./../redux/selectors";
import {InventoryActionCreators} from "./../redux/actions";
import TableComponent from "../../datatable/container/datatable"
import PopUp from "../../popUp/popUp";
import CreateInventory from "./components/createInventory";
import './inventory.scss';
import { getItemArrayOfObject, getLocalFormData } from '../../../helpers/helpers';

interface Props {
    inventory: any,
    isLoading: boolean,
    isSingleLoading:boolean,
    singleInventory: any,
    createdStatus: any
}

interface State {
    isShowPopUp: boolean,
    activeRowData: any,
    logisticStatusLabels: any
}

class Inventory extends Component<Props & typeof InventoryActionCreators> {
    state: State = {
        isShowPopUp: false,
        activeRowData:[],
        logisticStatusLabels: []
    };
    
    columns: any = [
        {
            name: i18next.t('Terminal.dashboards.id'),
            selector: 'storeInventoryRequestId',
            sortable: true,
            isUnique: true,
            searchable: true,
            width: "10%"
        },
        {
            name: i18next.t('Terminal.dashboards.inventoryRequestCode'),
            selector: 'inventoryRequestCode',
            sortable: true,
            searchable: true,
            width: "15%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.date'),
            selector: 'requestDate',
            sortable: true,
            searchable: true,
            width: "22%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.references'),
            selector: 'barcodeTotal',
            sortable: true,
            searchable: true,
            width: "12%",
            center: true,
        },
        {
            name: i18next.t('Terminal.dashboards.AssociateFirstName'),
            selector: 'associateFirstName',
            sortable: true,
            searchable: true,
            center: true,
            width: "15%",
        },
        {
            name: i18next.t('Terminal.dashboards.status'),
            selector: 'logisticStatus',
            sortable: true,
            searchable: true,
            center: true,
            width: "14%",
            cell: row => <span>{getItemArrayOfObject(this.state.logisticStatusLabels, 'code',  row.logisticStatus).label}</span>
        },
        {
            name: i18next.t('Terminal.dashboards.fullInventory'),
            selector: 'fullInventory',
            sortable: true,
            center: true,
            width: "12%",
            cell: row =>
                <div className={`${row.fullInventory  ? 'color--green' : 'color--red'}`}>
                    {
                        row.fullInventory ? "Y" : "N"
                    }
                </div>
        },
        {
            selector: 'comment',
            notShow:true,
        },
    ];

    conditionalRowStyles: any[] = [
        {
            when: row => row.logisticStatus === "PEN",
            style: {
                backgroundColor: "red",
            },
            
        },
        {
            when: row => row.logisticStatus === "PRO",
            style: {
                backgroundColor: "orange",
            },
            
        },
        {
            when: row => row.logisticStatus === "COM",
            style: {
                backgroundColor: "#2196F3",
            },
            
        },
        {
            when: row => row.logisticStatus === "VAL",
            style: {
                backgroundColor: "green",
            },
            
        },
    ];

    componentDidMount() {
        const {logisticStatusLabels = []} = getLocalFormData()
        this.props.inventoryStart();
        this.setState({logisticStatusLabels})
    }

    openPopUp = (e) => {
        const id = e.storeInventoryRequestId
        this.props.singleInventoryStart(id)
        this.setState({isShowPopUp: true , activeRowData : e});
    }
    handleClosePopUp = () => {
        this.setState({isShowPopUp: false , activeRowData : []});
        this.props.createdStatus && this.props.resetStoreInventoryCreateStatus();
    }

    render() {
        const {inventory, isLoading,isSingleLoading} = this.props;
        const {isShowPopUp,activeRowData} = this.state;
        return (
            <div className="container">
                <div className="stockContent">
                    <TableComponent
                        tableData={inventory && inventory.length && inventory.filter( item => item.logisticStatus !== "CLO" )}
                        isLoading={isLoading}
                        openPopUp={this.openPopUp}
                        columns={this.columns}
                        buttons={["newInventory"]}
                        reloadDashboard={this.props.inventoryStart}
                        conditionalRowStyles={this.conditionalRowStyles}
                        keyField={"inventory"}
                    /> 
                </div>
                {isShowPopUp    ? <PopUp handleClose={this.handleClosePopUp} loading={isSingleLoading} classN="invertoryPopUp">
                    <CreateInventory  inventory={activeRowData} handleClosePopUp={this.handleClosePopUp} />
                </PopUp> : ""}

            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    singleInventory: getSingleInventory(state),
    isSingleLoading: getSingleLoading(state),
    inventory: getInventory(state),
    isLoading: getLoading(state),
    createdStatus: getCreatedStatus(state),
});

const mapDispatchToProps = (dispatch): typeof InventoryActionCreators => {
    return bindActionCreators(InventoryActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
