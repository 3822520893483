import {createReducer} from 'reduxsauce';
import {salesTypes} from './actions';

export interface ISalesState {
    salesData: object[],
    singleSalesData:any,
    isLoading: boolean,
    paperReceipt: any,
    isLoadingPaperReceipt: boolean,
    isLoadingPrintTicket: boolean,
    printTicket: any,
    printError: string,
    isSingleLoading: boolean
}

export const salesContent: ISalesState = {
    salesData: [],
    singleSalesData:{},
    isLoading: false,
    paperReceipt: {},
    isLoadingPaperReceipt: false,
    isLoadingPrintTicket: false,
    printTicket: {},
    printError: '',
    isSingleLoading: false,
};

export const getSalesData = (state) => {
    return {...state, isLoading: true};
};

export const salesSuccess = (state, action) => {
    return {...state, salesData: action.data, isLoading: false};
};

export const SalesFailure = (state, action) => {
    return {...state, error: action.error, salesData: [], isLoading: false};
};

export const getSingleSalesData = (state) => {
    return {...state, isSingleLoading: true};
};

export const singleSalesSuccess = (state, action) => {
    return {...state, singleSalesData: action.data, isSingleLoading: false};
};

export const singleSalesFailure = (state, action) => {
    return {...state, error: action.error, singleSalesData: {}, isSingleLoading: false};
};

export const singleSalesAnnuller = (state) => {
    return {...state, singleSalesData: {}, isSingleLoading: false};
};

export const paperReceiptStart = (state) => {
    return {...state, isLoadingPaperReceipt: true};
};

export const paperReceiptSuccess = (state, action) => {
    return {...state, paperReceipt: action.data, isLoadingPaperReceipt: false};
};

export const paperReceiptFailure = (state, action) => {
    return {...state, error: action.error, paperReceipt: {}, isLoadingPaperReceipt: false};
};

export const printTicketStart = (state) => {
    return {...state, isLoadingPrintTicket: true};
};

export const printTicketSuccess = (state, action) => {
    return {...state, printTicket: action.data, isLoadingPrintTicket: false};
};

export const printTicketFailure = (state, action) => {
    return {...state, printError: action.error, printTicket: {}, isLoadingPrintTicket: false};
};

export const handlers = {
    [salesTypes.SALES_START]: getSalesData,
    [salesTypes.SALES_SUCCESS]: salesSuccess,
    [salesTypes.SALES_FAILURE]: SalesFailure,
    [salesTypes.SINGLE_SALES_START]: getSingleSalesData,
    [salesTypes.SINGLE_SALES_SUCCESS]: singleSalesSuccess,
    [salesTypes.SINGLE_SALES_FAILURE]: singleSalesFailure,
    [salesTypes.SINGLE_SALES_ANNULLER]: singleSalesAnnuller,
    [salesTypes.PAPER_RECEIPT_START]: paperReceiptStart,
    [salesTypes.PAPER_RECEIPT_SUCCESS]: paperReceiptSuccess,
    [salesTypes.PAPER_RECEIPT_FAILURE]: paperReceiptFailure,
    [salesTypes.PRINT_TICKET_START]: printTicketStart,
    [salesTypes.PRINT_TICKET_SUCCESS]: printTicketSuccess,
    [salesTypes.PRINT_TICKET_FAILURE]: printTicketFailure,
};

export default createReducer(salesContent,handlers);
