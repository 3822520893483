import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input, Table, Button } from 'semantic-ui-react';
import PopUp from '../../../popUp/popUp';
import { ClientActionCreators } from '../../redux/actions';
import { getTopClientsData } from '../../redux/selectors';
import {
    changeBasketClientId,
    debounced200,
    getActiveBasketName,
    getLangPrefix,
    sortArrayOfObject,
} from '../../../../helpers/helpers';
import history from '../../../../helpers/history';
import InfoPopUp from '../../../common/infoPopUp';

interface Props {
    handleClose: () => void;
    data: any;
    loading: boolean;
    topClientsStart: (v) => void;
    storeClientStart: (a, b) => void;
}

const TopClientsSidePanel: React.FC<Props> = (props: Props) => {
    const { handleClose, loading } = props;
    const [value, setValue] = useState<string>('');
    const [column, setColumn] = useState<string>('');
    const [direction, setDirection] = useState<
        'ascending' | 'descending' | undefined
    >();
    const [clientId, setClientId] = useState<string | number>('');
    const [data, setData] = useState<any>([]);
    const [isSubmited, setSubmited] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmited) {
            setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (loading) {
            setSubmited(true);
        }
    }, [loading]);

    useEffect(() => {
        let timeId;
        if (clientId) {
            handleAddClient(clientId);
            timeId = setTimeout(() => {
                history.push(getLangPrefix());
            }, 1500);
        }
        return () => {
            clearInterval(timeId);
        };
    }, [clientId]);

    const handleChange = ({ target: { value } }) => {
        setValue(value);
        if (value.length > 2) {
            debounced200(() => props.topClientsStart(value));
        }
    };

    const handleAddClient = (clientId) => {
        if (clientId) {
            props.storeClientStart(clientId, getActiveBasketName());
            changeBasketClientId(clientId);
        }
    };

    const handleSort = (name) => () => {
        const sorted = direction === 'ascending' ? 'descending' : 'ascending';
        setData(sortArrayOfObject(props.data, name, sorted));
        setColumn(name);
        setDirection(sorted);
    };
    const handleReset = () => {
        setValue('');
        setData([]);
        setSubmited(false)
    };

    return (
        <PopUp classN="top-client-side-panel" handleClose={handleClose}>
            <div className="top-client-side-panel-content">
                <div className="title">{i18next.t('searching.2')}</div>
                <div className="top-client-side-panel-search">
                    <Input
                        name="client"
                        value={value}
                        onChange={handleChange}
                        loading={loading}
                    />
                    <button className="btn_1" onClick={handleReset}>
                        {i18next.t('buttons.36')}
                    </button>
                </div>
                <div className="top-10-client-tabel">
                    {Array.isArray(data) && data.length ? (
                        <Table sortable celled>
                            <Table.Header>
                                <Table.Row>
                                    {[
                                        'storeShortName',
                                        'firstName',
                                        'lastName',
                                        'email',
                                        'phone',
                                        'storeCity'
                                    ].map((el) => {
                                        return (
                                            <Table.HeaderCell
                                                key={el}
                                                sorted={
                                                    column === el
                                                        ? direction
                                                        : column === 'city' && el === 'storeCity'
                                                            ? direction
                                                            : undefined
                                                }
                                                onClick={handleSort(el === 'storeCity' ? 'city' : el)}
                                            >
                                                {i18next.t(
                                                    `Terminal.dashboards.${
                                                        el === 'storeShortName'
                                                            ? 'storeName'
                                                            : el
                                                    }`,
                                                )}
                                            </Table.HeaderCell>
                                        );
                                    })}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {data.map((item) => {
                                    return (
                                        <Table.Row
                                            key={item.clientId}
                                            onClick={() =>
                                                setClientId(item.clientId)
                                            }
                                        >
                                            <Table.Cell>
                                                {item.storeShortName}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.firstName}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.lastName}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.email}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.phone}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.city}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    ) : isSubmited && !loading ? (
                        <div className="errorMessage">
                            {i18next.t('searching.1')}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <InfoPopUp
                open={!!clientId}
                message={i18next.t('Terminal.confirm.25')}
                handleClose={() => history.push(getLangPrefix())}
            />
        </PopUp>
    );
};

const mapStateToProps = (state: any) => getTopClientsData(state);

const mapDispatchToProps = (dispatch): typeof ClientActionCreators => {
    return bindActionCreators(ClientActionCreators, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopClientsSidePanel);
