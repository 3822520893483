import React from "react";
import i18next from "i18next";
import Description from "../description";
import {Link} from "react-router-dom";
import {getProductFullUrl} from "../../../../helpers/helpers";
import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";

interface Props {
    data: any,
    refDescription?: any,
}

export default class Details extends React.Component<Props> {

    render() {
        const {data, refDescription} = this.props;
        return (
            <>
                {data ?
                    (<section className="pour-products">
                        <div className="container">
                            {
                                data && data.assortments ?
                                    <h4 className="pour-products-title">{i18next.t('product.details.1')}</h4> : ""
                            }
                            <div className="pour-products-content">
                                {data && data.assortments ?
                                    data.assortments.map((item, i) => (
                                        <div className="pour-products-item" key={i}>
                                            <Link to={getProductFullUrl(location, item.canonicalUrl)}>
                                                <div className="pour-products-item-img">
                                                    <div className="pour-products-item-img-hover-info">
                                                        <h4>{item.name}</h4>
                                                        <p>{item.collection}</p>
                                                        <p>{i18next.t('catalogue.product.3')}
                                                            <Price startP={item.startingPrice}
                                                                   discount={item.discountDetail}
                                                                   newP={item.listPriceAfterDiscount}
                                                            />
                                                        </p>
                                                    </div>
                                                    {item.photo ?
                                                        <Photo src={item.photo}
                                                               thumbnail={item.thumbnail}
                                                               discountDetail={item.discountDetail}
                                                               onSpecial={item.onSpecial}
                                                               alt={item.name}
                                                        />
                                                        :""}

                                                </div>
                                                <div className="pour-products-item-text">
                                                    <div className="pour-products-item-hover-text">
                                                        <p className="link">{i18next.t('catalogue.product.5')}</p>
                                                    </div>
                                                    <h4 className="pour-products-item-title">{item.name}</h4>
                                                    <h4 className="pour-products-item-category">{item.collection}</h4>
                                                    <p>{i18next.t('product.product.7')}
                                                        <Price startP={item.startingPrice}
                                                               discount={item.discountDetail}
                                                               newP={item.listPriceAfterDiscount}
                                                        />
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                    : ""
                                }
                            </div>
                            <div ref={refDescription}>
                                <Description data={data}/>
                            </div>

                        </div>
                    </section>) : ""}
            </>
        );
    }
}


