import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import SubMenu from './submenu/container/submenu';
import MenuMobile from './mobile/menu';
import { Icon } from 'semantic-ui-react';
import MultiBasket from '../../../../../../modules/basketPopup/container/multiBasket';
import { MenuActionCreators } from '../../../redux/actions';
import { AddToCartActionCreators } from '../../../../../../modules/addToCart/redux/actions';
import { IMenuState } from '../../../redux/reducers';
import {
    getError,
    getMenu,
    getActiveMenuKey,
    clientCount,
    getnotificationCounters,
} from '../../../redux/selectors';
import {
    getAccountResource,
    getAccountResourceLoading,
    isAuthenticated,
    isPrivateAuthenticated,
} from '../../../../../../modules/login/redux/selectors';
import { getBasket } from '../../../../../../modules/addToCart/redux/selectors';
import { getDeliveryBasket } from '../../../../../../modules/deliveryBasketPopUp/redux/selectors';
import {
    getLang,
    getLangPrefix,
    getBasketCount,
    isTerminalAdmin,
} from '../../../../../../helpers/helpers';
import {
    LOCAL_STORAGE,
    CATALOGUE_TYPE,
    NOTIFICATION_COUNTERS_TIME,
    adminRoutesKeys,
} from '../../../../../../utils/constants/variables';
import DeliveryBasketPopUp from '../../../../../../modules/deliveryBasketPopUp/container/deliveryBasketPopUp';
import BlackWindow from '../../../../../../modules/common/blackWindow';
import './menu.scss';

interface Props extends IMenuState {
    handleReceiveMenu: any;
    basket: any;
    activeMenuKey: string;
    isAuthenticated: boolean;
    privateAuthenticated: boolean;
    clientCount: any;
    deliveryBasket: any;
    notificationCounters: object;
    isDisconnected: boolean;
}

interface State {
    menu?: any;
    error?: any;
    routeList?: any;
    showBasket?: any;
    showMenuMobile?: boolean;
    basket?: any;
    showBasketDelivery?: boolean;
    activeMenuItem: number;
    isMobile: boolean;
}

class Menu extends React.Component<
    Props & typeof MenuActionCreators & typeof AddToCartActionCreators,
    State
> {
    state: State = {
        menu: {},
        error: {},
        routeList: [],
        showBasket: false,
        showMenuMobile: false,
        basket: {},
        showBasketDelivery: false,
        activeMenuItem: -1,
        isMobile: false,
    };

    interval;

    componentDidMount() {
        if (
            this.props.account.storeDetails &&
            this.props.account.storeDetails.language
        ) {
            const [lang] = this.props.account.storeDetails.language.split('_');
            if (lang !== getLang()) {
                i18next.changeLanguage(lang);
                this.props.history.push(`/${lang}`);
                this.props.languageStart(lang);
            }
        }

        this.props.menuStart();
        this.props.getBasketStart();
        this.props.getDeliveryBasketStart(LOCAL_STORAGE.deliveryBasket);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.authenticated &&
            prevProps.authenticated !== this.props.authenticated
        ) {
            this.interval = setInterval(() => {
                this.props.notificationCountersStart();
            }, NOTIFICATION_COUNTERS_TIME);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleMenuClick = (index, isMobile: boolean = false) => {
        if (this.state.activeMenuItem !== index) {
            this.setState({ activeMenuItem: index, isMobile });
        } else {
            this.handleMenuLeave();
        }
    };

    handleMenuLeave = () => {
        this.setState({ activeMenuItem: -1 });
    };

    handleActive =
        (i, isMobile: boolean = false) =>
        () => {
            if (this.props.isDisconnected) return;
            this.handleMenuClick(i, isMobile);
        };

    toggleMenu = () => {
        this.setState({ showMenuMobile: !this.state.showMenuMobile });
        this.handleActive(-1, true);
    };

    handleBasketToggle = (show) => {
        this.setState({ showBasket: show });
    };
    handleBasketDeliveryToggle = (show) => {
        this.setState({ showBasketDelivery: show });
    };

    getMenuItemNotification = (key: string) => {
        const { notificationCounters } = this.props;
        switch(key) {
            case "transfers":
                return notificationCounters.storeTransfers;
            case "click_and_collect":
                return notificationCounters.webReservation;
            case "ship_from_store":
                return notificationCounters.shipFromStore;
            case "tickets":
                return notificationCounters.openTicketCounter;
            default:
                return false;
        }
    }

    render(): React.ReactNode {
        const LANGUAGE_PREFIX = getLangPrefix();
        const {
            showBasket,
            showMenuMobile,
            showBasketDelivery,
            activeMenuItem,
        } = this.state;
        let {
            menu,
            activeMenuKey,
            isAuthenticated,
            isDisconnected,
            account,
            notificationCounters = {},
        } = this.props;
        const storeType =
            account && account.storeDetails
                ? account.storeDetails.storeType
                : '';
        const brandId =
            account && account.brandId ? account.storeDetails.brandId : '';
        const basketCount = getBasketCount();
        let clinetMenuItems: any = {};
        if (menu.length) {
            menu = menu
                .sort((a, b) => parseInt(a.position) - parseInt(b.position))
                .filter((item) => {
                    if(['click_and_collect', 'ship_from_store'].includes(item.key)) {
                        const key = item.key.split("_")[0];
                        clinetMenuItems[key] = item;
                    }
                    return item.menuType.toUpperCase() === "PAGE"
                });
        }
        const validKey = (activeMenuKey || "").replaceAll("_", "-");

        return (
            <>
                {activeMenuItem >= 0 ? (
                    <BlackWindow
                        type="menu"
                        handleClick={this.handleMenuLeave}
                    />
                ) : (
                    ''
                )}
                <div
                    className={`header-box ${
                        isAuthenticated ? 'logged-in' : 'guest'
                    }`}
                >
                    <div className="header-logo container">
                        <div className="d-center menu-btn-content">
                            <div className="menu-btn" onClick={this.toggleMenu}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <nav className="header-navbar">
                        <div className="header-menu container">
                            <ul className={`header-navbar-menu d-center `}>
                                {Boolean(menu.length) && menu.map((item) => {
                                    const count = this.getMenuItemNotification(item.key);
                                    const isVisible = adminRoutesKeys.some((key) => key === item.key) ? isTerminalAdmin() : true;
                                    return isVisible ? (
                                        <li
                                            key={item.menuId}
                                            className={
                                                `basket-nav-link ${isDisconnected ? 'disabled' : ''}
                                                ${item.canonicalUrl.includes(validKey) && validKey ? ' active' : ''}`
                                            }
                                        >
                                            {item.canonicalUrl && (<Link
                                                to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`}
                                            >
                                                {item.label}
                                            </Link>)}
                                            {count !== false && (
                                                <span className="basketNumber greenBg">
                                                    {
                                                        count
                                                    }
                                                </span>
                                            )}
                                        </li>
                                    ) : null
                                })}
                            </ul>
                        </div>

                        {showBasket && (
                            <MultiBasket
                                handleBasketToggle={this.handleBasketToggle}
                                history={this.props.history}
                            />
                        )}
                        {showBasketDelivery && (
                            <DeliveryBasketPopUp
                                handleBasketToggle={
                                    this.handleBasketDeliveryToggle
                                }
                            />
                        )}

                        {showMenuMobile && (
                            <MenuMobile
                                menu={menu}
                                activeMenuItem={activeMenuItem}
                                handleActive={this.handleActive}
                                handleToggleMenu={this.toggleMenu}
                            />
                        )}
                    </nav>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    menu: getMenu(state),
    error: getError(state),
    basket: getBasket(state),
    activeMenuKey: getActiveMenuKey(state),
    isAuthenticated: isAuthenticated(state),
    privateAuthenticated: isPrivateAuthenticated(state),
    loadingAccount: getAccountResourceLoading(state),
    account: getAccountResource(state),
    clientCount: clientCount(state),
    deliveryBasket: getDeliveryBasket(state),
    notificationCounters: getnotificationCounters(state),
});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators(
        { ...MenuActionCreators, ...AddToCartActionCreators },
        dispatch,
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
