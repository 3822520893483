import React, { useState } from "react";
import {connect} from "react-redux";
import i18next from 'i18next';
import {getLoading, getStoresForm} from "../../../storeFormSidePanel/redux/selectors";
import Loading from "../../../common/loading/loading";
import StoreFormSidePanel from "../../../storeFormSidePanel/container";
import {changeSousTotal, getActiveBasketName} from '../../../../helpers/helpers';

interface Props  {
    isLoading: boolean,
    storeForm: any,
    handleClose: ()=>void,
    addToCart: (n, i, d) => void,
    value: string | number,
    activeField: string
}

const ReturnSidePanel: React.FC<Props> = ({isLoading, storeForm, handleClose, addToCart, value, activeField}) => {
    const [selected, setSelected] = useState<any>({})

    const handleClick = (item) => () => {
        setSelected(item)
    }

    const handleDoubleClick = (item) => () => {
        setSelected(item)
        handleSubmit()
    }
    const handleSubmit = () => {
        const {id, code, reason} = selected;
        const ids = activeField.split('/');
        const currentlyReturned = true;
        addToCart(getActiveBasketName(), ids[0], {
            size: {
                startingPrice: value,
                productId: ids[1],
                returnReasonStore: {id, code, reason, currentlyReturned}
            },
        });
        changeSousTotal('update', 0);
        handleClose()
    }
    return (
        <StoreFormSidePanel handleClose={handleClose}>
            {
                isLoading ?
                    <Loading/>
                    :
                    <>
                        {
                            Array.isArray(storeForm.returnReasonStoreList) && storeForm.returnReasonStoreList.map(item => {
                                return (
                                    <button
                                        key={item.code}
                                        className={`btn_1 ${selected.code === item.code ? 'active' : ''}`}
                                        onClick={handleClick(item)}
                                        onDoubleClick={handleDoubleClick(item)}
                                    >
                                        {item.reason}
                                    </button>
                                )
                            })
                        }
                        <button className="btn_1 green_btn" onClick={handleSubmit}>{i18next.t('Terminal.Block3.2')}</button>
                    </>
            }
        </StoreFormSidePanel>
    );
}
const mapStateToProps = (state: any) => ({
    storeForm: getStoresForm(state),
    isLoading: getLoading(state),
});

export default connect(mapStateToProps, null)(ReturnSidePanel);
