import { createReducer } from 'reduxsauce';
import { getUserFromToken } from '../../../helpers/helpers';
import { CalendarTypes } from './actions';

const sheduleName = 'scheduleInfo';

export interface ICalendarState {
    calendar: any;
    calendarStatus: string;
    calendarLoading: boolean;
}

export const calendarR: ICalendarState = {
    calendar: JSON.parse(localStorage.getItem(sheduleName) || "{}"),
    calendarStatus: '',
    calendarLoading: false,
};

export const calendar = (state) => {
    return {
        ...state,
        calendarStatus: 'pending',
        calendarLoading: true,
    };
};


export const calendarSuccess = (state, action) => {
    const { data: { data, brandId, terminalCode } } = action;

    const cloneData = Object.keys(data).reduce((acc, key) => {
        acc[key] = (data[key] || []).map((item) => {
            return {
                ...item,
                EndTime: new Date(item.endTime).toISOString(),
                StartTime: new Date(item.startTime).toISOString(),
                Id: Number(item.brandId),
                IsAllDay: item.allDay,
                Subject: item.subject,
                brandId,
                terminalCode,
                ...(item.location && {Location: item.location}),
                ...(item.description && {Description: item.description}),
                ...(item.recurrenceRule && {RecurrenceRule: item.recurrenceRule}),
            };
        });
        return acc;
    }, {});

    localStorage.setItem(sheduleName, JSON.stringify(cloneData));

    return {
        ...state,
        calendar: cloneData,
        calendarStatus: 'success',
        calendarLoading: false,
    };
};

export const calendarFailure = (state, action) => {
    return {
        ...state,
        calendarStatus: 'failed',
        calendarLoading: false,
    };
};

export const calendarStatus = (state, action) => {
    return {
        ...state,
        calendarStatus: 'success',
        calendarLoading: false,
    };
};

export const handlers = {
    [CalendarTypes.CALENDAR_SUCCESS]: calendarSuccess,
    [CalendarTypes.CALENDAR_STATUS ]: calendarStatus,
    [CalendarTypes.CALENDAR_FAILURE]: calendarFailure,
    [CalendarTypes.CALENDAR_LOADING_ACTION]: calendar
};

export default createReducer(calendarR, handlers);
