import {createReducer} from 'reduxsauce';
import {ProductTypes} from './actions';

export interface IProductState {
    product?: any,
    care?: any,
    composition?: any,
    loading?: boolean
}

export const product: IProductState = {
    product: {},
    care: {},
    composition: {},
    loading:true,
};

export const productGet = (state) => {
    return {...state,loading: true};
};

export const productSuccess = (state, action) => {
    return {...state, product: action.product , loading: false};
};

export const productFailure = (state, action) => {
    return {...state, error: action.error, product: {} ,loading: false};
};
export const productComposite = (state) => {
    return {...state};
};

export const productCompositeSuccess = (state, action) => {
    const newProduct = {...state.product};
    newProduct.compositeData = action.data;
    return {...state, product: newProduct};
};

export const productCompositeFailure = (state, action) => {
    const newProduct = {...state.product};
    newProduct.compositeData ?  newProduct.compositeData = "" : "";
    return {...state, error: action.error, product: newProduct};
};

export const productCare = (state) => {
    return {...state};
};

export const productCareSuccess = (state, action) => {
    return {...state, care: action.data};
};

export const productCareFailure = (state, action) => {
    return {...state, careError: action.error, care: {}};
};

export const productComposition = (state) => {
    return {...state};
};

export const productCompositionSuccess = (state, action) => {
    return {...state, composition: action.data};
};

export const productCompositionFailure = (state, action) => {
    return {...state, compositionError: action.error, composition: {}};
};

export const handlers = {
    [ProductTypes.PRODUCT_START]: productGet,
    [ProductTypes.PRODUCT_SUCCESS]: productSuccess,
    [ProductTypes.PRODUCT_FAILURE]: productFailure,
    [ProductTypes.PRODUCT_COMPOSITE_START]: productComposite,
    [ProductTypes.PRODUCT_COMPOSITE_SUCCESS]: productCompositeSuccess,
    [ProductTypes.PRODUCT_COMPOSITE_FAILURE]: productCompositeFailure,
    [ProductTypes.CARE_START]: productCare,
    [ProductTypes.CARE_SUCCESS]: productCareSuccess,
    [ProductTypes.CARE_FAILURE]: productCareFailure,
    [ProductTypes.COMPOSITION_START]: productComposition,
    [ProductTypes.COMPOSITION_SUCCESS]: productCompositionSuccess,
    [ProductTypes.COMPOSITION_FAILURE]: productCompositionFailure,
};

export default createReducer(product, handlers);