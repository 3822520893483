import React, { useState } from 'react';
import i18next from 'i18next';
import ConfirmPopup from '../confirmPopup/confirmPopup';

interface IProps {
    handleClick: () => void;
}

export default function FermerBtn({ handleClick }: IProps) {
    const [show, setShow] = useState(false);
    const handleConfirm = () => {
        handleClick();
        setShow(false);
    };

    return (
        <>
            <div className="btn_1 w-100" onClick={() => setShow(true)}>
                {i18next.t('buttons.40')}
            </div>
            {show && (
                <ConfirmPopup
                    handleCancel={() => setShow(false)}
                    handleConfirm={handleConfirm}
                    text={i18next.t('Terminal.confirm.23')}
                />
            )}
        </>
    );
}
