import {createReducer} from 'reduxsauce';
import {LocationSearchTypes} from './actions';

export interface ILocationSearchState {
    locationSearchData: any,
    error: string
}

export const locationSearch: ILocationSearchState = {
    locationSearchData: [],
    error: ""
};

export const getLocationSearch = (state) => {
    return {...state};
};

export const locationSearchSuccess = (state, action) => {
    return {...state, locationSearchData: action.data};
};

export const locationSearchFailure = (state, action) => {
    return {...state, error: action.error, locationSearchData: []};
};

export const handlers = {
    [LocationSearchTypes.LOCATION_SEARCH_START]: getLocationSearch,
    [LocationSearchTypes.LOCATION_SEARCH_SUCCESS]: locationSearchSuccess,
    [LocationSearchTypes.LOCATION_SEARCH_FAILURE]: locationSearchFailure
};

export default createReducer(locationSearch, handlers);