import {GeoLocationTypes, GeoLocationActionCreators} from "./actions";
import {takeLatest, put, call, all} from "redux-saga/effects";
import axios from 'axios';

export function* geoLocationData(publicIp) {
    try {
        const { ip } = publicIp;
        const data = yield call(() => {
            return axios.get(`http://api.ipstack.com/${ip}?access_key=394e9e344620b84a4fa35b9ae737b519&format=1`)
                .then(res => res.data)
        });
        yield put(GeoLocationActionCreators.geolocationSuccess(data));
    } catch (e) {
        yield put(GeoLocationActionCreators.geolocationFailure(e.message));
    }
}

export function* geoLocationSaga() {
    yield takeLatest(GeoLocationTypes.GEOLOCATION_START, geoLocationData);
}

export default geoLocationSaga;