import { TerminalControllerTypes, TerminalControllerActionCreators } from "./actions";
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import axios from 'axios';
import { closeFiskalTransactionDe, getAxiosHeaders, STORE_FORM, TERMINAL_CONTROLLER_URL } from "../../../utils/constants/service";
import { STORAGE_GET_FORM, STORE_COUNTRIES } from './../../../utils/constants/variables';
import { getApiPrefix, isValidClientToken } from "../../../helpers/helpers";
import { UserActionCreators } from "../../login/redux/actions";
import { getAccountResource } from '../../login/redux/selectors';


export function* getTerminalOpen(params) {
    const {body} = params;
    try {
        const data = yield call(() => {
            return axios.post(`${TERMINAL_CONTROLLER_URL}open`, body, getAxiosHeaders())
                .then(res => res.data)
        });

        yield put(TerminalControllerActionCreators.isTerminalOpenStart(true));
        yield put(TerminalControllerActionCreators.terminalOpenSuccess(data));
        yield put(UserActionCreators.accountResourceStart());
        const details = yield select(getAccountResource);
        const {storeDetails: {country = ""} = {}} = details;
        if(country !== STORE_COUNTRIES.it) {
            yield put(TerminalControllerActionCreators.printDataStart(body.drawers, 'openTerminal', body.commentOpening));
        }

        const getForm = yield call(() => {
            return axios.get(`${STORE_FORM}?language=${getApiPrefix()}`, getAxiosHeaders())
                .then(res => res.data)
        });
        localStorage.setItem(STORAGE_GET_FORM,JSON.stringify(getForm))

    } catch (e) {
        yield put(TerminalControllerActionCreators.isTerminalOpenStart(false));
        yield put(UserActionCreators.accountResourceStart());
        yield put(TerminalControllerActionCreators.terminalOpenFailure(e.response.data));
    }
}

export function* getTerminalClose(params) {
    const {body} = params;
    try {
        const {currency, altCurrency, storeDetails: {country = ""} = {}} = yield select(getAccountResource);
        if(country === STORE_COUNTRIES.de) {
            yield call(closeFiskalTransactionDe);
        }
        
        const data = yield call(() => {
            return axios.post(`${TERMINAL_CONTROLLER_URL}close`, body, getAxiosHeaders())
                .then(res => res.data)
        });

        yield put(TerminalControllerActionCreators.isTerminalOpenStart(false));
        yield put(TerminalControllerActionCreators.clientCounterStart(0));
        yield put(TerminalControllerActionCreators.terminalCloseSuccess(data));

        if(country !== STORE_COUNTRIES.it) {
            yield put(TerminalControllerActionCreators.printDataStart(body.drawers, 'closeTerminal', body.commentClosing));
        }

        yield put(TerminalControllerActionCreators.printReportStart("Z", currency));
        if(altCurrency) {
            yield put(TerminalControllerActionCreators.printReportStart("Z", altCurrency));
        }

        yield put(UserActionCreators.accountResourceStart());
    } catch (e) {
        yield put(TerminalControllerActionCreators.terminalCloseFailure(e.message));
    }
}


export function* terminalSaga() {
    yield takeLatest(TerminalControllerTypes.TERMINAL_OPEN_START, getTerminalOpen);
    yield takeLatest(TerminalControllerTypes.TERMINAL_CLOSE_START, getTerminalClose);
}

export default terminalSaga;
