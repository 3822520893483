import {createReducer} from 'reduxsauce';
import {StoresFormTypes} from './actions';

export interface  IStoresState {
    storesForm:any,
    isLoading: boolean,
    stores:any,
    isLoadingStores: boolean
}

export const storesState: IStoresState = {
    storesForm: {},
    isLoading: false,
    stores: [],
    isLoadingStores: false
};

export  const storesFormStart = (state) => {
    return {...state, isLoading: true};
};

export const storesFormSuccess = (state, action) => {
    return {...state, storesForm: action.data, isLoading: false};
};

export const storesFormFailure = (state, action) => {
    return {...state, error: action.error, storesForm: {}, isLoading: false};
};

export  const storesStart = (state) => {
    return {...state, isLoadingStores: true};
};

export const storesSuccess = (state, action) => {
    return {...state, stores: action.data, isLoadingStores: false};
};

export const storesFailure = (state, action) => {
    return {...state, error: action.error, stores: [], isLoadingStores: false};
};

export const handlers = {
    [StoresFormTypes.STORES_FORM_START]: storesFormStart,
    [StoresFormTypes.STORES_FORM_SUCCESS]:storesFormSuccess,
    [StoresFormTypes.STORES_FORM_FAILURE]: storesFormFailure,
    [StoresFormTypes.STORES_START]: storesStart,
    [StoresFormTypes.STORES_SUCCESS]:storesSuccess,
    [StoresFormTypes.STORES_FAILURE]: storesFailure,

};

export default createReducer(storesState, handlers);
