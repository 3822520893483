import {createReducer} from 'reduxsauce';
import {reservationMagasinTypes} from './actions';

export interface IReservationMagasinTypesState {
    reservationMagasinData?: any,
    reservationMagasinSingleData?: any,
    isLoading: boolean
}

export const reservationMagasinContent: IReservationMagasinTypesState = {
    reservationMagasinData: [],
    reservationMagasinSingleData:{},
    isLoading: false,
};

export const getReservationMagasinData = (state) => {
    return {...state, isLoading: true};
};

export const ReservationMagasinSuccess = (state, action) => {
    return {...state, reservationMagasinData: action.data, isLoading: false};
};

export const ReservationMagasinFailure = (state, action) => {
    return {...state, error: action.error, reservationMagasinData: [], isLoading: false};
};

export const getReservationMagasinSingleData = (state) => {
    return {...state};
};

export const ReservationMagasinSingleSuccess = (state, action) => {
    return {...state, reservationMagasinSingleData: action.data};
};

export const ReservationMagasinSingleFailure = (state, action) => {
    return {...state, error: action.error, reservationMagasinSingleData: {}};
};

export const handlers = {
    [reservationMagasinTypes.RESERVATION_MAGASIN_START]: getReservationMagasinData,
    [reservationMagasinTypes.RESERVATION_MAGASIN_SUCCESS]: ReservationMagasinSuccess,
    [reservationMagasinTypes.RESERVATION_MAGASIN_FAILURE]: ReservationMagasinFailure,
    [reservationMagasinTypes.RESERVATION_MAGASIN_SINGLE_START]: getReservationMagasinSingleData,
    [reservationMagasinTypes.RESERVATION_MAGASIN_SINGLE_SUCCESS]: ReservationMagasinSingleSuccess,
    [reservationMagasinTypes.RESERVATION_MAGASIN_SINGLE_FAILURE]: ReservationMagasinSingleFailure,
};

export default createReducer(reservationMagasinContent,handlers);
