import { createReducer } from 'reduxsauce';
import { clientTypes } from './actions';
import { changeBasketClientId } from '../../../helpers/helpers';

export interface IClientState {
    clientData?: any;
    singleClientData: any;
    clientnew: any;
    clientAddress: any;
    isLoading: boolean;
    isLoadingClient: boolean;
    storeClient: any;
    createClientStatus: string;
    setClientLoading: boolean;
    topClients: any;
    topClientsLoading: boolean;
    topClientsError: string;
}

export const clientContent: IClientState = {
    clientData: [],
    singleClientData: {},
    clientnew: {},
    clientAddress: {},
    isLoading: false,
    isLoadingClient: false,
    storeClient: {},
    createClientStatus: '',
    setClientLoading: false,
    topClients: [],
    topClientsLoading: false,
    topClientsError: '',
};

export const getClientData = (state) => {
    return { ...state, isLoading: true };
};

export const clientSuccess = (state, action) => {
    return { ...state, clientData: action.data, isLoading: false };
};

export const clientFailure = (state, action) => {
    return { ...state, error: action.error, data: [], isLoading: false };
};

export const getSingleClientData = (state) => {
    return { ...state, isLoadingClient: true };
};

export const singleClientSuccess = (state, action) => {
    return { ...state, singleClientData: action.data, isLoadingClient: false };
};

export const singleClientFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        singleClientData: {},
        isLoadingClient: false,
    };
};
export const clientNewEditStart = (state) => {
    return { ...state, clientnew: {} };
};

export const clientNewEditSuccess = (state, action) => {
    return { ...state, clientnew: action.data };
};

export const clientNewEditFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        clientnew: {},
    };
};
export const clientUpdateStart = (state) => {
    return { ...state, setClient: {}, setClientLoading: true };
};

export const clientUpdateSuccess = (state, action) => {
    return {
        ...state,
        setClient: action.data,
        createClientStatus: 'success',
        setClientLoading: false,
    };
};

export const clientUpdateFailure = (state, action) => {
    return {
        ...state,
        error: action.error,
        setClient: {},
        createClientStatus: 'failed',
        setClientLoading: false,
    };
};

export const storeClient = (state) => {
    return { ...state };
};

export const storeClientSuccess = (state, action) => {
    const { data } = action;
    return { ...state, storeClient: { ...state.storeClient, ...data } };
};

export const storeClientFailure = (state, action) => {
    return { ...state, error: action.error, storeClient: {} };
};

export const resetStoreClientSuccess = (state, action) => {
    const { basketName } = action;
    const { storeClient } = state;
    const newData = { ...storeClient };
    delete newData[basketName];
    changeBasketClientId(0, basketName);
    return { ...state, storeClient: newData };
};
export const resetCreateClientStatus = (state) => {
    return { ...state, createClientStatus: '' };
};

export const topClients = (state) => {
    return { ...state, topClientsLoading: true, topClientsError: '' };
};

export const topClientsSuccess = (state, action) => {
    return { ...state, topClients: action.data, topClientsLoading: false };
};

export const topClientsFailure = (state, action) => {
    return {
        ...state,
        topClientsError: action.error,
        topClients: [],
        topClientsLoading: false,
    };
};

export const handlers = {
    [clientTypes.CLIENT_NEW_EDIT_START]: clientNewEditStart,
    [clientTypes.CLIENT_NEW_EDIT_SUCCESS]: clientNewEditSuccess,
    [clientTypes.CLIENT_NEW_EDIT_FAILURE]: clientNewEditFailure,
    [clientTypes.SET_CLIENT_START]: clientUpdateStart,
    [clientTypes.SET_CLIENT_SUCCESS]: clientUpdateSuccess,
    [clientTypes.SET_CLIENT_FAILURE]: clientUpdateFailure,
    [clientTypes.CLIENT_START]: getClientData,
    [clientTypes.CLIENT_SUCCESS]: clientSuccess,
    [clientTypes.CLIENT_FAILURE]: clientFailure,
    [clientTypes.SINGLE_CLIENT_START]: getSingleClientData,
    [clientTypes.SINGLE_CLIENT_SUCCESS]: singleClientSuccess,
    [clientTypes.SINGLE_CLIENT_FAILURE]: singleClientFailure,
    [clientTypes.STORE_CLIENT_START]: storeClient,
    [clientTypes.STORE_CLIENT_SUCCESS]: storeClientSuccess,
    [clientTypes.STORE_CLIENT_FAILURE]: storeClientFailure,
    [clientTypes.RESET_STORE_CLIENT_SUCCESS]: resetStoreClientSuccess,
    [clientTypes.RESET_CREATE_CLIENT_STATUS_START]: resetCreateClientStatus,
    [clientTypes.TOP_CLIENTS_START]: topClients,
    [clientTypes.TOP_CLIENTS_SUCCESS]: topClientsSuccess,
    [clientTypes.TOP_CLIENTS_FAILURE]: topClientsFailure,
};

export default createReducer(clientContent, handlers);
