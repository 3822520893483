import React, { useState } from  'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';

import CustomBtn from '../../common/customBtn';
import InfoPopUp from '../../common/infoPopUp';
import { setOrderStatus } from '../../../utils/constants/service';
import { ORDER_STATUS_CODE } from '../../../utils/constants/variables';
import { AppointmentsActionCreators } from '../redux/actions';

const AppointmentsButtons: React.FC<any> = ({
    data,
    storeCode,
    selectedRows,
    getServiceCalendarStart
}) => {
    const [infoPopupOptions, setInfoPopupOptions] = useState({
        isVisible: false,
        message: '',
        className: '',
        callback: () => {},
    })

    const updateStatus = () => {
        setInfoPopupOptions({
            ...infoPopupOptions,
            isVisible: false
        })

        const { orderNumber } = data;
        console.log(data, storeCode, selectedRows)

        setOrderStatus(orderNumber, ORDER_STATUS_CODE.SER).then((result) => {
            getServiceCalendarStart()
        })
        .catch(() => {})
    }

    const toggleInfoPopup = ({ message, callback = () => {}, className = '' }) => {
        setInfoPopupOptions({
            isVisible: !infoPopupOptions.isVisible,
            message,
            callback,
            className
        })
    }

    const isServiceDeliveredDisabled = () => {
        if (!data) return true

        const { orderNumber, orderStatus: { code } = { code: '' }, deleted } = data;

        return !orderNumber || deleted
            ? true
            : [
                ORDER_STATUS_CODE.SER,
                ORDER_STATUS_CODE.CAN,
                ORDER_STATUS_CODE.RET
            ].includes(code)
    }

    return (
        <>
            <CustomBtn
                classN="btn_1 w-100 green_btn"
                disabled={isServiceDeliveredDisabled()}
                handleClick={() => toggleInfoPopup({
                    message: i18next.t('Terminal.modal.2'),
                    callback: updateStatus
                })}
            >
                {i18next.t('buttons.58')}
            </CustomBtn>

            <InfoPopUp
                open={infoPopupOptions.isVisible}
                message={infoPopupOptions.message}
                classN={infoPopupOptions.className}
                handleClose={infoPopupOptions.callback}
            />
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(AppointmentsActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsButtons);