import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next';
import { DeliveriesActionCreators } from '../../redux/actions';
import PopUp from '../../../popUp/popUp';
import CreateDeliveries from './createDeliveries';
import ConfirmPopup from '../../../common/confirmPopup/confirmPopup';
import ColisPopup from './colisPopupContent';

interface Prorps {
    data: any;
    openDashboardPopup: Function
}

interface State {
    isShowPopUp: boolean;
    isShowColisPopup: boolean;
    showConfirm: boolean;
    confirmationText: string;
}
class NewDeliveries extends Component<
    Prorps & typeof DeliveriesActionCreators,
    State
> {
    state: State = {
        isShowPopUp: false,
        isShowColisPopup: false,
        showConfirm: false,
        confirmationText: i18next.t('Terminal.modal.2'),
    };

    openPopUp = (e) => {
        this.setState({ isShowPopUp: true });
    };
    openColisPopUp = (e) => {
        this.setState({ isShowColisPopup: true });
    };
    handleClosePopUp = () => {
        this.setState({ isShowPopUp: false });
    };
    handleCloseColisPopUp = () => {
        this.props.emptyBarcodeByColisSuccess();
        this.setState({ isShowColisPopup: false });
    }
    handleConfirm = () => {
        this.props.updateStoreDeliveriesStart(
            'CLO',
            this.props.data.storeDeliveryId,
        );
        this.setState({ showConfirm: false });
    };
    addSlipNumber = (value) => {
        if(value === i18next.t('Terminal.modal.6')) {
            return value.replace(/([?]$)/, ` ${this.props.data.slipNumber}$1`);
        }
        return value;
    }

    render() {
        const { isShowPopUp, isShowColisPopup, showConfirm } = this.state;

        return (
            <>
                <button
                    className="btn_1 w-100 orange_btn"
                    onClick={this.openPopUp}
                >
                    {i18next.t('Terminal.dashboards.new')}
                </button>
                <button
                    className="btn_1 w-100 blue_btn"
                    onClick={this.openColisPopUp}
                >
                    {i18next.t('buttons.46')}
                </button>
                <button
                    className="btn_1 w-100 red_btn "
                    onClick={() => this.setState({
                        showConfirm: true,
                        confirmationText: i18next.t('Terminal.modal.6')
                    })}
                    style={{ order: 10 }}
                >
                    {i18next.t('buttons.50')}
                </button>
                {isShowPopUp ? (
                    <PopUp handleClose={this.handleClosePopUp}>
                        <CreateDeliveries
                            handleClosePopUp={this.handleClosePopUp}
                        />
                    </PopUp>
                ) : (
                    ''
                )}
                {isShowColisPopup && (
                    <PopUp handleClose={this.handleCloseColisPopUp}>
                        <ColisPopup openDeliveryDetails={this.props.openDashboardPopup}/>
                    </PopUp>
                )}
                {showConfirm && (
                    <ConfirmPopup
                        handleCancel={() => {
                            this.setState({ showConfirm: false });
                        }}
                        handleConfirm={this.handleConfirm}
                        text={this.addSlipNumber(this.state.confirmationText)}
                    />
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch): typeof DeliveriesActionCreators => {
    return bindActionCreators(DeliveriesActionCreators, dispatch);
};
export default connect(null, mapDispatchToProps)(NewDeliveries);
