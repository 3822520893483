export const getTransfers = state => state.transfersContent.transfersData;
export const getLoading = state => state.transfersContent.isLoading;
export const getCreateLoading = state => state.transfersContent.isCreateLoading;
export const getUpdateLoading = state => state.transfersContent.isUpdateLoading;
export const getShipmentLoading = state => state.transfersContent.isShipmentLoading;
export const getBookLoading = state => state.transfersContent.isTransferShipmentLoading
export const getCreatedStatus = state => state.transfersContent.createdStatus;
export const getCreatedResponse = state => state.transfersContent.createdResponse;
export const getUpdatedStatus = state => state.transfersContent.updatedStatus;
export const getTransferShipments = state => state.transfersContent.transferShipments;
export const getTransferBookResult = state => state.transfersContent.transferBookResult;
export const getTransferShipmentError = state => state.transfersContent.shipmentError;
