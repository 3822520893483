import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { getTableData } from '../../../helpers/helpers';
import history from '../../../helpers/history'
import Loading from '../../common/loading/loading';
import TableSearch from './components/search';
import TableController from './components/tablecontroller';
import TableDatePicker from './components/tabledatepicker';
import SetClientBtn from '../../clients/container/components/setClientBtn';
import EnvoiClientBtn from '../../livraisonClient/container/envoiClientBtn';
import MagasinBtn from '../../reservationMagasin/container/magasinButtons';
import Nouveaubtn from '../../clients/container/components/nouveaubtn';
import SetStoreGift from '../../bonsCadeaux/container/components/setStoreGift';
import TransferButtons from '../../transfers/container/components/transferButtons';
import NewDeliveries from '../../deliveries/container/components/newDeliveries';
import StockButtons from '../../stocks/container/components/stockButtons';
import ExportStockButtons from '../../stocks/container/components/exportStockButtons';
import SalesButtons from './../../sales/container/compinents/salesButtons';
import QuoteButtons from './../../quotes/container/components/quoteButtons';
import ReturnButtons from './../../return/container/components/returnButtons';
import ExportPDFButtons from './../../stocks/container/components/exportPDFbutton';
import AdminButtons from '../../Admin/container/adminButtons';
import NewInventory from '../../inventory/container/components/newInventory';
import ClientGift from "./../../clients/container/components/clientGift";
import AppointmentsButtons from '../../Appointments/container/AppointmentsButtons';

import 'react-day-picker/lib/style.css';
import './datatable.scss';

const perPage = 15;
const components = {
    setClientBtn: SetClientBtn,
    envoiclientbtn: EnvoiClientBtn,
    magasinbtn: MagasinBtn,
    nouveaubtn: Nouveaubtn,
    setStoreGift: SetStoreGift,
    transferButtons: TransferButtons,
    newDeliveries: NewDeliveries,
    stockButtons: StockButtons,
    exportStockButtons: ExportStockButtons,
    admin: ExportStockButtons,
    salesButtons: SalesButtons,
    quoteButtons: QuoteButtons,
    returnButtons: ReturnButtons,
    newInventory: NewInventory,
    exportPDFbutton: ExportPDFButtons,
    clientGift:ClientGift,
    adminButtons: AdminButtons,
    appointmentsButtons: AppointmentsButtons
};

interface Props {
    tableData: any,
    openPopUp: any,
    isLoading?: boolean,
    columns: any,
    buttons?: any,
    datepicker?: boolean,
    searchResult?: any,
    conditionalRowStyles?: any,
    reloadDashboard: () => void,
    classN?: string,
    csvData?: object,
    selectedRow?: any,
    storeCode?: string,
    keyField?: string,
    selectableRows?: boolean,
    onSelectedRowsChange?: (param) =>void,
    hideSearch?: boolean
    isService?: boolean
    searchPosition?: string;
}

interface State {
    searchResult: any,
    activeRow: number,
    currentPage: any,
    date: any,
    searchValue: string,
    paginationDefaultPage: number,
    isScan: boolean,
    selectedRows: any[]
}

class TableComponent extends Component<Props, State> {
    state: State = {
        searchResult: [],
        activeRow: 0,
        currentPage: 1,
        date: [new Date(), new Date()],
        searchValue: '',
        paginationDefaultPage: 1,
        isScan: false,
        selectedRows: []
    };

    componentDidMount() {
        const { tableData, columns } = this.props;
        this.setState({ searchResult: getTableData(tableData, columns) });
    };

    componentDidUpdate(prevProps, prevState): void {
        if (prevState.searchResult.length !== this.state.searchResult.length) {
            this.resetCurrentActiveRow();
        }

        if (prevProps.isLoading && !this.props.isLoading) {
            const { tableData, columns } = this.props;
            this.setState({ searchResult: getTableData(tableData, columns) });
            this.changeCurrentActiveRow(this.state.activeRow);
        }

        if(this.props.selectedRow && this.props.selectedRow.id && this.props.selectedRow.id !== prevProps.selectedRow.id) {
            const index = this.props.tableData.findIndex(item => item[this.props.selectedRow.fieald] === this.props.selectedRow.id)
            if(index > -1) {
                const page = Math.ceil(index / perPage) || 1
                this.setState({ currentPage: page, paginationDefaultPage: page, isScan: true }, () => this.changeCurrentActiveRow(index % perPage));

            }
        }

        if(this.props.keyField === "exportStocks" && prevProps.tableData && this.props.tableData.length !== prevProps.tableData.length){
            const { tableData, columns } = this.props;
            this.setState({ searchResult: getTableData(tableData, columns) });
            this.resetCurrentActiveRow();
        }
    };

    getRowData = (activeRow, currentPage) => {
        return this.state.searchResult[(perPage * (currentPage - 1)) + activeRow];
    };

    handleSearch = (data, value) => {
        this.setState({ searchValue: value, searchResult: data });
    };

    handleResetSearch = () => {
        this.props.reloadDashboard();
        this.setState({ searchValue: '' });
    };

    handleUpdatePage = (activeRowVal, currentPage) => {
        if (!this.getRowData(activeRowVal, currentPage)) {
            return;
        }
        this.changeCurrentActiveRow(activeRowVal);
        this.setState({
            activeRow: activeRowVal <= 0 ? 0 : activeRowVal,
            currentPage: currentPage <= 0 ? 0 : currentPage,
        });
    };

    resetCurrentActiveRow = () =>{
        const {keyField} = this.props
        const parent = document.querySelector(`.${keyField}`);
        const item = parent && parent.querySelector(`#row-${0}`);
        item && item.classList.add('active-row');
        this.setState({ activeRow: 0 });
    };

    changeCurrentActiveRow = (index) => {
        if (index >= 0 && index < perPage && this.getRowData(index, this.state.currentPage)) {
            const {keyField} = this.props
            const parent = document.querySelector(`.${keyField}`);
            const activeRow = parent && parent.querySelector('.rdt_TableRow.active-row');
            if(activeRow) { activeRow.classList.remove('active-row')};
            const item = parent && parent.querySelector(`#row-${index}`);
            item && item.classList.add('active-row');
            this.setState({ activeRow: index });
        }
    };

    handleClickRow(e) {
        const uniqueColumn = this.props.columns.find(item => item.isUnique);
        if (uniqueColumn) {
            const index = this.state.searchResult.findIndex(item => item[uniqueColumn.selector] == e[uniqueColumn.selector]) - (perPage * (this.state.currentPage - 1));
            if (index > -1 && this.state.activeRow !== index) {
                this.changeCurrentActiveRow(index);
            }
        }
    };

    handleChangePage = (currentPage) => {
        this.setState({ currentPage });
        if(!this.state.isScan){
            this.changeCurrentActiveRow(0);
        }else {
            this.setState({ isScan: false });
        }
        const {keyField} = this.props
        const parent = document.querySelector(`.${keyField}`);
        const selected = parent && parent.querySelector('.rdt_TableRow.green');
        if (selected) {
            selected.classList.remove('green');
        }
    };

    filterByDate = (newData) => {
        this.setState({ searchResult: getTableData(newData, this.props.columns) });
    };
    
    onSelectedRowsChange = ({  selectedRows }) => {
        this.setState({selectedRows})
    };

    render() {
        const { 
            tableData, buttons = [], columns, datepicker, isLoading = false, classN = '', 
            csvData, storeCode , keyField, selectableRows, isService = "",  searchPosition = 'default',
        } = this.props;
        const { selectedRows } = this.state;

        const { searchResult, activeRow, currentPage } = this.state;
        return (
            <div className={`container ${isService ? 'containerFluid' : ''}`}>
                <div className={`stockContent ${keyField ? keyField : ""}`}>
                    { this.props.hideSearch ? "" : (
                        <TableSearch
                            tableData={tableData}
                            columns={columns}
                            handleSearch={this.handleSearch}
                            handleResetSearch={this.handleResetSearch}
                            searchPosition={searchPosition}
                        />
                    )}
                    { datepicker ?
                        <TableDatePicker
                            filterData={tableData}
                            filter={this.filterByDate}
                            columns={columns}
                            csvData={csvData}
                        /> : ''
                    }

                    {this.props.children}

                    <div className="dataTable">
                        <DataTable
                            columns={this.props.columns.filter(item => !item.notShow)}
                            data={searchResult}
                            keyField={"data"}
                            theme="solarized"
                            pagination={true}
                            paginationPerPage={perPage}
                            paginationDefaultPage={this.state.paginationDefaultPage}
                            sortIcon={<i className="sort icon"></i>}
                            onRowClicked={(event) => this.handleClickRow(event)}
                            onRowDoubleClicked={(event) => this.props.openPopUp(event)}
                            onChangePage={(currentPage) => this.handleChangePage(currentPage)}
                            conditionalRowStyles={this.props.conditionalRowStyles || []}
                            className={`data-table ${classN}`}
                            noHeader={true}
                            progressPending={isLoading}
                            progressComponent={<Loading/>}
                            selectableRows={!!selectableRows && !isService}
                            onSelectedRowsChange={this.onSelectedRowsChange}
                        />

                        <div className="dataTable--controller">
                            { buttons.length ? 
                                buttons.map((item, i) => {
                                    const ComponentBtn = components[item];
                                    if (ComponentBtn){
                                        return (
                                            <ComponentBtn 
                                                key={i}
                                                history={history}
                                                data={this.getRowData(activeRow, currentPage)}
                                                storeCode={storeCode ? storeCode:""}
                                                selectedRows={selectableRows ? selectedRows : null}
                                                openDashboardPopup={this.props.openPopUp}
                                                isService={this.props.isService}
                                            />
                                        )
                                    }
                                }) : ''
                            }
                            <TableController
                                currentPage={currentPage}
                                activeRow={activeRow}
                                handleUpdatePage={this.handleUpdatePage}
                                changeCurrentActiveRow={this.changeCurrentActiveRow}
                                keyField={keyField}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


export default TableComponent;
