import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import i18next from 'i18next';

import { usePrevious } from '../../../../shared/hooks/usePrevious';
import CustomBtn from '../../../common/customBtn';
import '../stocks.scss';
import { filterInputNumberValue } from '../../../../helpers/helpers';

interface Props {
	stock: number;
    barcode: string;
    updateStockAction: (size, barcode, id) => void;
    closePanel: () => void;
    singleStockId: number;
    loading: boolean;
}

const UpdateStock: React.FC<Props> = ({
    stock,
    barcode,
    updateStockAction,
    closePanel,
    singleStockId,
    loading
}) => {
    const [stockSize, setStockSize] = useState<number | string>(stock);
    const prevLoading = usePrevious<boolean>(loading);

    useEffect(() => {
        if (!loading && prevLoading) {
            closePanel();
        }
    }, [loading])

    const changeStockSize = ({ target }) => {
        const { value } = target;
        setStockSize(filterInputNumberValue(value));
    }

    const handleChangeStockSize = () => {
        updateStockAction(barcode, stockSize, singleStockId);
    }

	return (
		<div className="popUp--body stock-size-container">
            <div className="content">
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Terminal.dashboards.stockSize')}
                    </div>
                    <div className="ui error input popUp--body--card--body--data--description  email">
                        <Input
                            placeholder='Colis'
                            value={stockSize}
                            onChange={changeStockSize}
                            autoFocus
                            className='ml-stock'
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--data">
                    <CustomBtn
                        classN="btn_1 green_btn w-100"
                        loading={loading}
                        disabled={false}
                        handleClick={handleChangeStockSize}
                    >
                        {i18next.t('Terminal.dashboards.edit')}
                    </CustomBtn>
                </div>
            </div>
        </div>
	)
}

export default UpdateStock;