import {createActions} from 'reduxsauce';

export const {Types: livraisonClientTypes, Creators: livraisonClientActionCreators} = createActions ({
    livraisonClientStart: null,
    livraisonClientSuccess: ['data'],
    livraisonClientFailure: ['error'],
    livraisonClientSingleStart:['data'],
    livraisonClientSingleSuccess:['data'],
    livraisonClientSingleFailure:['error'],
    livraisonClientShippingStart:['data'],
    livraisonClientShippingSuccess:['data'],
    livraisonClientShippingFailure:['error'],
    livraisonClientBookStart:['data'],
    livraisonClientBookSuccess:['data'],
    livraisonClientBookFailure:['error'],
    annulerErrorSuccess: null,
    livraisonClientEmptyBookStart: null,
});
