import {createReducer} from 'reduxsauce';
import {UserTypes} from './actions';
import {LOCAL_STORAGE, SESSION_STORAGE, setStoreData, updateStoreVariables} from '../../../utils/constants/variables'
import {destroyAllBaskets, encrypt, getUserFromToken, isValidClientToken, isValidPrivateToken} from "../../../helpers/helpers";

export interface IAccountState {
    authenticated: boolean,
    privateAuthenticated: boolean,
    isPrivateLoading: boolean,
    firstStep: any,
    step: number,
    userInfo: any,
    user: any,
    loginStatus: string,
    privateLoginStatus: string,
    clientLoginStatus: string,
    accountResource: object,
    loading: boolean,
    vatNumber: string,
    brandApp: string,
    isTerminalAdmin: boolean
}

export const account: IAccountState = {
    authenticated: false,
    privateAuthenticated: false,
    isPrivateLoading: false,
    firstStep: {},
    step: 1,
    userInfo: {},
    user: {},
    loginStatus: "",
    privateLoginStatus: "",
    clientLoginStatus: "",
    accountResource: {},
    loading: false,
    vatNumber: "",
    brandApp: "",
    isTerminalAdmin: false
};

export const logout = (state, action) => {
    const { param } = action
    if(param) {
        localStorage.clear();
    }
    sessionStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem(LOCAL_STORAGE.clientCounter);
    destroyAllBaskets();
    setStoreData("","", undefined);
    return {...state, privateAuthenticated: false, authenticated: false, step:1, accountResource: {}};
};

export const clientLogout = (state) => {
    return {...state};
};

export const clientLogoutSuccess = (state) => {
    localStorage.removeItem('token');
    localStorage.removeItem(LOCAL_STORAGE.isAssociateAdmin);
    return {...state, authenticated: false};
};

export const clientLogoutFailure = (state, action) => {
    return {...state, error: action.error};
};

export const login = (state) => {
    return {...state, loginStatus: ""};
};

export const loginSuccess = (state, action) => {
    const {data} = action;

    return {...state, firstStep: data, step: 2, loginStatus: "SUCCESS"};
};

export const loginFailure = (state, action) => {
    return {...state, error: action.error, firstStep: {}, loginStatus: "FAILED"};
};

export const privateLogin = (state) => {
    return {...state, privateLoginStatus: ""};
};

export const privateLoginSuccess = (state, action) => {
    const { data } = action;
    const { privateToken } = data

    sessionStorage.setItem(SESSION_STORAGE.privateToken, privateToken);
    return {...state, privateAuthenticated: true, privateLoginStatus: "SUCCESS"};
};

export const privateLoginFailure = (state, action) => {
    return {...state, error: action.error, privateAuthenticated: false, privateLoginStatus: "FAILED"};
};

export const isPrivateLoading = (state, action) => {
    return {...state, isPrivateLoading: action.load}
}

export const clientLogin = (state) => {
    return {...state, clientLoginStatus: ""};
};

export const clientLoginSuccess = (state, action) => {
    const {data = {}} = action;
    localStorage.setItem('token', data.idToken);
    encrypt(LOCAL_STORAGE.isAssociateAdmin, data.terminalAdmin);
    return {...state, authenticated: true, user: data, clientLoginStatus: "SUCCESS", isTerminalAdmin: !!data.terminalAdmin};
};

export const clientLoginFailure = (state, action) => {
    return {...state, error: action.error, user: {}, clientLoginStatus: "FAILED"};
};

export const userInfo = (state) => {
    return {...state};
};

export const userInfoSuccess = (state, action) => {
    return {...state, userInfo: action.data};
};

export const userInfoFailure = (state, action) => {
    return {...state, error: action.error, userInfo: {}};
};

export const accountResource = (state) => {
    return {...state, loading: true}
}
export const accountResourceSuccess = (state, action) => {
    const {terminalCode, storeCode, storeDetails, storeAssociates } = action.data
    let isTerminalAdmin = false
    sessionStorage.setItem(SESSION_STORAGE.terminalCode, terminalCode);
    sessionStorage.setItem(SESSION_STORAGE.storeCode, storeCode);
    const { brandApp = 0 } = storeDetails;
    updateStoreVariables(action.data)
    if(Array.isArray(storeAssociates)) {
        const currentUser = getUserFromToken()
        const user = storeAssociates.find(item => item.associateFirstName === currentUser.fname && item.associateCode === currentUser.code)
        if(user && user.terminalAdmin) {
            isTerminalAdmin = user.terminalAdmin
        }
    }
    return {
        ...state,
        loading:false,
        accountResource: action.data,
        brandApp,
        vatNumber: storeDetails ? storeDetails.vatNumber : "",
        isTerminalAdmin
    }
}
export const accountResourceFailure = (state, action) => {
    return {...state, error: action.error, accountResource: {}, loading: false}
}

export const tokenValidateStart = (state) => {

    return {
        ...state,
        privateAuthenticated: isValidPrivateToken(),
        authenticated: isValidClientToken()
    };
};

export const handlers = {
    [UserTypes.LOGIN_START]: login,
    [UserTypes.LOGIN_SUCCESS]: loginSuccess,
    [UserTypes.LOGIN_FAILURE]: loginFailure,
    [UserTypes.LOGOUT_START]: logout,
    [UserTypes.TOKEN_VALIDATE_START]: tokenValidateStart,
    [UserTypes.USER_INFO_START]: userInfo,
    [UserTypes.USER_INFO_SUCCESS]: userInfoSuccess,
    [UserTypes.USER_INFO_FAILURE]: userInfoFailure,
    [UserTypes.PRIVATE_LOGIN_START]: privateLogin,
    [UserTypes.PRIVATE_LOGIN_SUCCESS]: privateLoginSuccess,
    [UserTypes.PRIVATE_LOGIN_SUCCESS]: privateLoginSuccess,
    [UserTypes.PRIVATE_LOGIN_FAILURE]: privateLoginFailure,
    [UserTypes.CLIENT_LOGIN_START]: clientLogin,
    [UserTypes.CLIENT_LOGIN_SUCCESS]: clientLoginSuccess,
    [UserTypes.CLIENT_LOGIN_FAILURE]: clientLoginFailure,
    [UserTypes.CLIENT_LOGOUT_START]: clientLogout,
    [UserTypes.CLIENT_LOGOUT_SUCCESS]: clientLogoutSuccess,
    [UserTypes.CLIENT_LOGOUT_FAILURE]: clientLogoutFailure,
    [UserTypes.IS_PRIVATE_LOADING_START]: isPrivateLoading,
    [UserTypes.ACCOUNT_RESOURCE_START]: accountResource,
    [UserTypes.ACCOUNT_RESOURCE_SUCCESS]: accountResourceSuccess,
    [UserTypes.ACCOUNT_RESOURCE_FAILURE]: accountResourceFailure,
};

export default createReducer(account, handlers);
