import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Checkbox, Dropdown, Icon, Input } from 'semantic-ui-react';
import CustomBtn from '../../common/customBtn';
import { AdminActionCreators } from '../redux/actions';
import { bindActionCreators } from 'redux';
import { getAssociateloading } from '../redux/selectors';
import { associateCreateValidationFields, associateUpdateValidationFields, assoicateFormData } from '../../../utils/constants/formsState';
import { StockActionCreators } from '../../stocks/redux/actions';
import { BASE_URL, createAssociatePhoto } from '../../../utils/constants/service';
import { getStoreAssociatesSelector } from '../../stocks/redux/selectors';

interface Props {
    createAssociateStart: (...args) => void;
    adminPageLoadingStart: (status: boolean) => void;
    closePopup: () => void;
    selectedAssociate: any;
    isLoading: boolean;
    storeAssociates: any[]
}

const NewAssociate: React.FC<Props> = ({
    isLoading,
    createAssociateStart,
    closePopup,
    selectedAssociate = null,
    storeAssociates
}) => {
    const isEdit = selectedAssociate !== null;
    const [formData, setFormData] = useState<Record<string, any>>(assoicateFormData(selectedAssociate));
    const [formErrors, setFormErrors] = useState<Record<string, any>>({});
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [imgWidth, setImgWidth] = useState<number>(0);

    const activeOptions = [{
        key: 1,
        value: 'true',
        text: i18next.t('Terminal.confirm.1')
    }, {
        key: 2,
        value: 'false',
        text: i18next.t('Terminal.confirm.2')
    }]

    const fileRef = useRef<HTMLInputElement>(null);

    const changeFormData = (_, event) => {
        const { name, value } = event;

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const getFormDataByName = (name) => {
        const result = formData[name] || formData[name] === false ? formData[name] : "";

        // case for date if more case change function
        if (name === 'createDate') {
            const parts = result.split(" ");

            return parts[0] ? parts[0] : "";
        }

        return result;
    }

    const validateFormData = () => {
        const { isValid, validationResult } = Object.keys(!isEdit
            ? associateCreateValidationFields
            : associateUpdateValidationFields
        ).reduce((acc, key) => {
            if (!formData[key]) {
                acc.validationResult[key] = true;
                acc.isValid = false;
            }
            return acc;
        }, { isValid: true, validationResult: {} })

        setFormErrors(validationResult);
        return { isValid }
    }

    const handleSubmitFormData = () => {
        if (!validateFormData().isValid) return;

        if (formData.associateFirstName) formData.associateFirstName = formData.associateFirstName.toUpperCase();
        if (formData.associateLastName) formData.associateLastName = formData.associateLastName.toUpperCase();

        if (previewUrl) {
            const file = fileRef.current && fileRef.current.files && fileRef.current.files[0];
            const data = new FormData();
            file && data.append('file', file);
            delete formData.photoUrl;
            data.append('associateCode', formData.associateCode);

            createAssociatePhoto(data)
                .then(() => {
                    createAssociateStart(formData, closePopup);
                    closePopup();
                })
                .catch((error) => console.log(error))
        } else {
            createAssociateStart(formData, closePopup);
        }
    }

    const changePhotoUrl = () => {
        const file = fileRef.current && fileRef.current.files && fileRef.current.files[0];
        if (file) {
            const previewBlobUrl = URL.createObjectURL(file);
            setPreviewUrl(previewBlobUrl);
        }
    }

    const getAvilabPriorities = () => {
        return new Array(20).fill(1).reduce((acc, item, index) => {
            const associate = storeAssociates.find((associate) => associate.priority === item + index);
            if (!associate) {
                acc.push({
                    key: index,
                    value: index + item,
                    text: index + item
                });
            }
            return acc;
        }, selectedAssociate ? [{
            key: -1,
            value: selectedAssociate.priority,
            text: selectedAssociate.priority
        }] : []);
    }

    return (
        <div className="parent-for-comment">
            <div className="popUp--body--card--body set-data-content">
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.idToken')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description`}
                            name="id"
                            value={getFormDataByName("id")}
                            autoComplete="off"
                            disabled
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.active')}
                        </div>
                        <Dropdown
                            onChange={(_, event) => changeFormData(_, {name: 'active', value: event.value === 'true'})}
                            options={activeOptions}
                            selection
                            value={`${getFormDataByName("active")}`}
                            className={`popUp--body--card--body--data--description bold`}
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.dashboards.clientCode')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description`}
                            name="associateCode"
                            value={getFormDataByName("associateCode")}
                            onChange={changeFormData}
                            autoComplete="off"
                            disabled
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Terminal.Receipt.Transfer.date')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description`}
                            name="createDate"
                            value={getFormDataByName("createDate")}
                            autoComplete="off"
                            disabled
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.associateFirstName')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description ${formErrors.associateFirstName ? 'error' : ''}`}
                            name="associateFirstName"
                            value={getFormDataByName("associateFirstName")}
                            onChange={changeFormData}
                            autoComplete="off"
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.associateLastName')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description ${formErrors.associateLastName ? 'error' : ''}`}
                            name="associateLastName"
                            value={getFormDataByName("associateLastName")}
                            onChange={changeFormData}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.associateEmail')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description ${formErrors.associateEmail ? 'error' : ''}`}
                            name="associateEmail"
                            value={getFormDataByName("associateEmail")}
                            onChange={changeFormData}
                            autoComplete="off"
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.associatePhone')}
                        </div>
                        <Input
                            className={`popUp--body--card--body--data--description ${formErrors.associatePhone ? 'error' : ''}`}
                            name="associatePhone"
                            value={getFormDataByName("associatePhone")}
                            onChange={changeFormData}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--between">
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('Associate.priority')}
                        </div>
                        <Dropdown
                            onChange={(_, event) => changeFormData(_, {name: 'priority', value: event.value})}
                            options={getAvilabPriorities()}
                            selection
                            value={getFormDataByName("priority")}
                            className={`popUp--body--card--body--data--description bold`}
                        />
                    </div>
                    <div className="popUp--body--card--body--data">
                        <div className="popUp--body--card--body--data--header">
                            {i18next.t('AssociateService.admin')}
                        </div>
                        <Checkbox
                            onChange={() => changeFormData(null, { name: 'terminalAdmin', value: !getFormDataByName("terminalAdmin") })}
                            checked={getFormDataByName("terminalAdmin") || false}
                            className="radioLabel popUp--body--card--body--data--description h-40"
                        />
                    </div>
                </div>
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Associate.password')}
                    </div>
                    <Input
                        className={`popUp--body--card--body--data--description ${formErrors.password ? 'error' : ''}`}
                        name="password"
                        value={getFormDataByName("password")}
                        onChange={changeFormData}
                        autoComplete="off"
                    />
                </div>
                <div className="popUp--body--card--body--data">
                    <div className="popUp--body--card--body--data--header">
                        {i18next.t('Associate.photoUrl')}
                    </div>
                    <div className='popUp--body--card--body--data--description d-flex'>
                        <img
                            width={imgWidth}
                            style={{marginRight: imgWidth ? '10px' : '0'}}
                            className='edit-image'
                            src={previewUrl ? previewUrl :BASE_URL + getFormDataByName('photoUrl')}
                            alt=""
                            onLoad={() => setImgWidth(250)}
                            onError={() => setImgWidth(0)}
                        />
                        <div className='d-flex'>
                            <Icon
                                style={{margin: 0}}
                                onClick={() => {
                                    fileRef.current && fileRef.current.click();
                                }}
                                name='edit'
                                className='large align-self-e edit-img-icon'
                            />
                        </div>
                        <input
                            onChange={changePhotoUrl}
                            ref={fileRef} type="file"
                            hidden
                        />
                    </div>
                </div>
                <div className="extra content">
                    <div className="ui two buttons">
                        <CustomBtn
                            classN="btn_1 w-100 blue_btn withoutHover"
                            handleClick={handleSubmitFormData}
                            loading={isLoading}
                        >
                            {i18next.t('Terminal.dashboards.edit')}
                        </CustomBtn>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any): any => {
    return {
        isLoading: getAssociateloading(state),
        storeAssociates: getStoreAssociatesSelector(state),
    }
};

const mapDispatchToProps = (dispatch): typeof AdminActionCreators  => {
    return bindActionCreators(Object.assign({}, AdminActionCreators, {
        adminPageLoadingStart: StockActionCreators.adminPageLoadingStart
    }), dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAssociate);
