import React, {useRef} from 'react'
import CustomBtn from '../common/customBtn'

const FileUploader = ({onFileSelect, loading = false, label,disabled = false, ...rest}) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
        // handle validations
        onFileSelect(e.target.files[0])
    }

    return (
        <div className="file-uploader">
            <input ref={fileInput} type="file" onChange={handleFileInput} className="d-none" {...rest}/>
            <CustomBtn
                disabled={disabled}
                classN="btn_1 w-100 green_btn"
                handleClick={e => fileInput.current && fileInput.current.click()}
                loading={loading}
            >
                {label}
            </CustomBtn>
        </div>
    )
}

export default FileUploader;
