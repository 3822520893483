import {createActions} from 'reduxsauce';

export const {Types: StoresFormTypes, Creators: StoresFormActionCreators} = createActions ({
    storesFormStart: [null],
    storesFormSuccess: ['data'],
    storesFormFailure: ['error'],
    storesStart: [null],
    storesSuccess: ['data'],
    storesFailure: ['error'],

});