import React from 'react';
import i18next from 'i18next';
import { Table } from 'semantic-ui-react';

import { getDateTimestamp } from '../../../../helpers/helpers';

interface Props {
    data: any;
}

const ReturnMessages: React.FC<Props> = ({
    data
}) => {
    const getColor = (code) => {
        switch(code) {
            case 'A':
                return 'color--green'
            case 'C':
                return 'color--blue'
            default:
                return '';
        }
    }

    return (
        <div>
            <Table
                celled
                selectable
                className="productInfoBlock--infoBox--table collaps"
            >
                <Table.Header>
                    <Table.Row>
                        <Table.Cell
                            className="productInfoBlock--infoBox--table--title font-30 text-bold"
                        >
                            {i18next.t('Terminal.dashboards.date')}
                        </Table.Cell>
                        <Table.Cell
                            className="productInfoBlock--infoBox--table--title font-30 text-bold"
                        >
                            {i18next.t('Terminal.dashboards.textMessage')}
                        </Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.ticketMessages.map((message) => (
                        <Table.Row key={message.id}
                            className={getColor(message.msgFrom)}
                        >
                            <Table.Cell collapsing className="productInfoBlock--infoBox--table--title font-22 text-bold">
                                {getDateTimestamp(message.createdAt)}
                            </Table.Cell>
                            <Table.Cell className="productInfoBlock--infoBox--table--title font-22 text-bold">
                                {message.message}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export default ReturnMessages;