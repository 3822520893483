import {createReducer} from 'reduxsauce';
import {TerminalControllerTypes} from './actions';
import {LOCAL_STORAGE, SESSION_STORAGE} from '../../../utils/constants/variables';

export interface ITerminalControllerState {
    terminalOpen: object,
    isLoading: boolean,
    isOpen: boolean,
    isClosed: boolean,
    isTerminalOpen: boolean,
    terminalClose: object,
    error: string,
    status: string

}
export  const terminalReducer: ITerminalControllerState = {
    terminalOpen: {},
    isLoading: false,
    isOpen: false,
    isClosed: false,
    isTerminalOpen: false,
    terminalClose: {},
    error: "",
    status: ""
};

export  const terminalOpenstart = (state) => {
    return {...state, isOpen:false, error: "", status: "", isLoading: true};
};

export const terminalOpenSuccess = (state, action) => {
    localStorage.setItem(LOCAL_STORAGE.barcodeList, JSON.stringify(action.data.barcodeList));
    return {...state, terminalOpen: action.data, isOpen:true, isLoading: false, status: "success"};
};

export const terminalOpenFailure = (state, action) => {
    return {...state, error: action.error.description, terminalOpen: {}, isOpen:false, isLoading: false, status: "error"};
};

export  const terminalCloseStart = (state) => {
    return {...state, isClosed: false, error: "", isLoading: true, status: ""};
};

export const terminalCloseSuccess = (state, action) => {
    localStorage.removeItem("expenseTypes")
    return {...state, terminalClose: action.data, isClosed: true, isLoading: false, status: "success"};
};

export const terminalCloseFailure = (state, action) => {
    return {...state, error: action.error, terminalClose: {}, isClosed: false, isLoading: false, status: "error"};
};

export const isTerminalOpenStart = (state, action) => {
    if(!action.param) {
        sessionStorage.removeItem(SESSION_STORAGE.terminalOpen)
    }else {
        sessionStorage.setItem(SESSION_STORAGE.terminalOpen, "success");
    }

    return {...state, isTerminalOpen: action.param};
};


export const handlers = {
    [TerminalControllerTypes.TERMINAL_OPEN_START]: terminalOpenstart,
    [TerminalControllerTypes.TERMINAL_OPEN_SUCCESS]:terminalOpenSuccess,
    [TerminalControllerTypes.TERMINAL_OPEN_FAILURE]: terminalOpenFailure,
    [TerminalControllerTypes.TERMINAL_CLOSE_START]: terminalCloseStart,
    [TerminalControllerTypes.TERMINAL_CLOSE_SUCCESS]:terminalCloseSuccess,
    [TerminalControllerTypes.TERMINAL_CLOSE_FAILURE]: terminalCloseFailure,
    [TerminalControllerTypes.IS_TERMINAL_OPEN_START]: isTerminalOpenStart,

};

export default createReducer(terminalReducer,handlers);
