import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Tab } from 'semantic-ui-react';
import { getLangPrefix, isTerminalAdmin } from '../../../helpers/helpers';
import NewAssociate from './NewAssociate';
import NewService from './NewService';
import history from '../../../helpers/history';
import { Redirect } from 'react-router-dom';

interface Props {
    closePopup: () => void;
    selectedAssociate: any;
}

const AdminContent: React.FC<Props> = ({
    closePopup,
    selectedAssociate
}) => {
    const panes = [
        {
            menuItem: i18next.t('Terminal.dashboards.profile'),
            render: () => (
                <NewAssociate
                    closePopup={closePopup}
                    selectedAssociate={selectedAssociate}
                />
            )
        },
        {
            menuItem: i18next.t('Terminal.dashboards.service'),
            render: () => (
                <NewService
                    closePopup={closePopup}
                    selectedAssociate={selectedAssociate}
                />
            )
        }
    ]

    return (
        <div>
            <Tab menu={{pointing: true}} panes={panes} />
        </div>
    )
}

export default AdminContent;