import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';

import CustomBtn from '../../common/customBtn';
import PopUp from '../../popUp/popUp';
import NewAssociate from './NewAssociate';
import ConfirmPopup from '../../common/confirmPopup/confirmPopup';
import { bindActionCreators } from 'redux';
import { AdminActionCreators } from '../redux/actions';
import { assoicateFormData } from '../../../utils/constants/formsState';

interface Props {
    data: any;
    createAssociateStart: (body, callback) => void;
}

const AdminButtons: React.FC<Props> = ({
    data,
    createAssociateStart
}) => {
    const [isNewAssociateVisible, setIsNewAssociateVisible] = useState<boolean>(false);
    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState<boolean>(false);

    const toggleAssociateNew = () => {
        setIsNewAssociateVisible(!isNewAssociateVisible);
    }

    const toggleDeleteAssociate = () => {
        setIsDeleteConfirmationVisible(!isDeleteConfirmationVisible);
    }

    const deleteAssociate = () => {
        const body = assoicateFormData(data);
        createAssociateStart({
            ...body,
            deleted: true
        }, () => {
            toggleDeleteAssociate();
        })
    }

    return (
        <>
            <CustomBtn
                classN="btn_1 w-100 orange_btn"
                handleClick={toggleAssociateNew}
                disabled={false}
                order={0}
            >
                {i18next.t('Terminal.dashboards.new')}
            </CustomBtn>
            <CustomBtn
                classN="btn_1 w-100 red_btn"
                handleClick={toggleDeleteAssociate}
                disabled={false}
                order={1}
            >
                {i18next.t('buttons.12')}
            </CustomBtn>
            {isNewAssociateVisible && (
                <PopUp handleClose={toggleAssociateNew}>
                    <NewAssociate
                        closePopup={toggleAssociateNew}
                    />
                </PopUp>
            )}
            {isDeleteConfirmationVisible && (
                <ConfirmPopup
                    handleCancel={toggleDeleteAssociate}
                    handleConfirm={deleteAssociate}
                    text={i18next.t('Terminal.modal.1')}
                />
            )}
        </>
    )
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(Object.assign({
        createAssociateStart: AdminActionCreators.createAssociateStart
    }), dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminButtons);
